import { ObjectID } from '../../objectStore/asyncObject';

interface ApiAppVersion {
  id: number;
  version: string;
}
export interface ApiAppStagingTier {
  id: ObjectID;
  current_applicationversion: ApiAppVersion;
  name: string;
}
export class AppStagingTier {
  static parse(apiItem: ApiAppStagingTier): AppStagingTier {
    return new AppStagingTier(apiItem);
  }
  public constructor(apiAppStagingTier: ApiAppStagingTier) {
    this.id = apiAppStagingTier.id;
    this.name = apiAppStagingTier.name;
    this.currentVersion = apiAppStagingTier.current_applicationversion?.version;
    this.currentVersionId = apiAppStagingTier.current_applicationversion?.id;
  }
  public id: ObjectID;
  public name: string;
  public currentVersion: string;
  public currentVersionId: number;
}
