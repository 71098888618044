import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import { action, computed, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../../requestQueue/requestQueue';
import { ObjectStore } from '../../objectStore/objectStore';
import { ApiAppStagingTier, AppStagingTier } from './appStagingtier';

export class AppStagingTierStore extends ObjectStore<AppStagingTier, ApiAppStagingTier> {
  public constructor(queue: RequestQueue, url: string) {
    super(queue, url);
    makeObservable(this);
  }

  protected createAndLoadItem(x: ApiAppStagingTier): AppStagingTier {
    return AppStagingTier.parse(x);
  }

  @computed
  public get groupedStagingTiers(): AppStagingTier[][] {
    // group stagingTiers
    const stagingTiers = groupBy(this.items, (x) => {
      let stagingTier: AppStagingTier;
      forEach(this.items, (y) => {
        if (x.currentVersionId === y.currentVersionId) {
          stagingTier = x;
          return;
        }
      });
      return stagingTier?.currentVersionId || 0;
    });
    delete stagingTiers[0];
    return Object.values(stagingTiers);
  }

  @computed
  public get defaultVersion(): number {
    let defaultVersion =
      this.items.find((stagingTier) => {
        return stagingTier.name === 'Live';
      })?.currentVersionId || undefined;
    if (!defaultVersion) defaultVersion = this.items[0]?.currentVersionId || 0;
    return defaultVersion;
  }

  @observable
  private _version = 0;
  @computed
  public get versionId(): number {
    if (this._version) return this._version;
    else return this.defaultVersion;
  }
  @action
  public setVersionId(version: number): void {
    this._version = version;
  }
}
