/* URLs */
export const platformURL = process.env['NX_PLATFORM_URL'] || 'https://api.utomik.com';
export const cloudApi = 'https://cloudapi.utomik.com/api/v1.0';

export const websiteProtocol = 'https';

export const websiteDomain = 'www.utomik.com';

export const websiteUrl = `${websiteProtocol}://${websiteDomain}`;
export const supportUrl = `${websiteDomain.replace('www.', 'support.')}`;
export const privacyPolicyUrl = `static.utomik.com/privacypolicy.pdf`;
export const myUrl = `${websiteProtocol}://${websiteDomain.replace('www.', 'my.')}`;

// max log size (5 MiB)
export const LOG_MAX_SIZE = 5 * 1024 * 1024;
// max log age, in milliseconds (1 day)
export const LOG_MAX_AGE = 24 * 60 * 60 * 1000;
// max number of log files in the log directory (50)
export const LOG_MAX_FILES_COUNT = 50;

/* Carousel settings */
// The number here is just a guestimate about the amount of recommended channels that should be (pre)loaded.
export const HOME_CHANNEL_PAGECOUNT = 99;
// The minimum amount of carousel items in view. At the smallest screen size, you should see the following amount of items:
export const MIN_ITEMS_IN_VIEW = 8;
export const ITEMS_PRELOAD = 16;
//The maximum amount of carousel items
export const MAX_ITEMS_IN_CAROUSEL = 7;
// The number of carousels we add to the home page next to the recommended channel carousels.
export const NUMBER_OF_ADDED_CAROUSELS = 5;
// The number of carousels between the spotlight tiles
export const CAROUSELS_PER_SPOTLIGHT_INTERVAL = 3;
/* Dialog min width */
export const DIALOG_MIN_WIDTH = 600;

// Lower this value to scale the carousel tiles more aggressively (i.e. less tiles in view).
export const DEFAULT_CONTAINERTILE_SCALE_INTENSITY = 0.85;

/* Carousel list settings */
export const CAROUSEL_LIST_HEIGHT_MARGIN = 8;

/* External URLs */
export const NINJA_URL = `${websiteUrl}/ninja/`;
export const FAQ_URL = `${supportUrl}/hc/en-us/`;
export const FAQ_VIRUSSCANNER_URL = `${supportUrl}/hc/en-us//articles/115001038189-What-should-I-do-if-I-get-a-missing-files-error-`;
export const CUSTOMER_SUPPORT_URL = `${supportUrl}/hc/en-us/requests/new/`;
export const RATE_UTOMIK_URL = `https://utomik.typeform.com/to/JdED6H`;
export const EDIT_PROFILE_URL = `${myUrl}/profile/`;
export const REGISTER_URL = `${myUrl}/register/`;
export const URL_SUBSCRIPTION = `${myUrl}/subscription/`;
export const URL_BILLING = `${myUrl}/billing/`;

// Window settings
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_STARTPATH = '/';
export const DEFAULT_STARTUP_MAXIMIZED = false; // Always start up unmaximized, unless the user has a certain resolution or lower, defined in the main process.

// If the user's primary display's resolution is below or equal to these values, start up in fullscreen mode.
export const DEFAULT_STARTUP_SIZE_WIDTH = 1920;
export const DEFAULT_STARTUP_SIZE_HEIGHT = 1080;

// The minimum size the client can be reduced to.
export const CLIENT_MINIMUM_WIDTH = 1024;
export const CLIENT_MINIMUM_HEIGHT = 720;

// The dimensions of the toaster in the bottom right corner when running an app.
export const TOASTER_MINIMUM_WIDTH = 324;
export const TOASTER_MINIMUM_HEIGHT = 144;

// Ninja page
export const NINJA_FOCUS_CAROUSEL_SLUG = 'ninja_focus';
export const NINJA_EXCLUSIVE_CAROUSEL_SLUG = 'ninja_exclusives';
export const SPOTLIGHT_CAROUSEL_SLUG = 'spotlight';
export const NEW_CAROUSEL_SLUG = 'new';
export const COMING_SOON_CAROUSEL_SLUG = 'coming_soon';
export const HOME_CAROUSEL_SLUG1 = 'home_carousel_1';
export const HOME_CAROUSEL_SLUG2 = 'home_carousel_2';
export const HOME_CAROUSEL_SLUG3 = 'home_carousel_3';
export const NINJA_EMAIL = 'ninja@utomik.com';
export const NINJA_GUIDE_PDF_LINK = 'https://d3u5f77lwq6cfn.cloudfront.net/email/Utomik-NinjaGuide.pdf';
export const NINJA_FACEBOOK_GROUP_LINK = 'https://www.facebook.com/groups/utomikninja';
export const NINJA_DISCORD_LINK = 'https://discord.gg/vvVFeq9';

// Login QR
export const LOGIN_QR_TV_FLOW_SUBSCRIPTION = 'tv_flow_subscription';

// Channel page
export const CHANNEL_MIN_SPOTLIGHT_ENTRIES = 1;
export const PLACEHOLDER_TILEGRID_HEIGHT_FACTOR = 2;

// FEEDBACK FORM
export const TEXT_LIMIT_64_KB = 65535;

export const enableRecCaching = true;

/*
  Default retries for api requests. It can be either a number or an array of numbers.
  When it's a number, the request will retry n times immediately.
  When it's an array, the request will retry until the end of the array is reached. Each entry in the array is the axios-timeout value in seconds.
*/
const DEFAULT_RETRIES: number | number[] = [5, 10];

export { DEFAULT_RETRIES };

/** Webserver variables */
export const LOCAL_SOCKETSERVER_HOST = 'ws://localhost';
export const TOKENTRANSFER_URL = `https://clientoauth.utomik.com`; // This is the next URL.

/** LocalStorage variables */
export const LOCALSTORAGE_ALC = 'alc';
export const LOCALSTORAGE_LG_TOKEN = 'lgToken';
export const LOCALSTORAGE_AUTH_CLOUD_API_TOKEN = 'ucat'; // ucat - utomik-cloud-api-token

//time between changing current item on home page and channel page
export const TIME_DEBOUNCE_TILES = 1600;

//time to resolve gateway promise automatically when sorting gateways
export const TIME_GATEWAY_RESOLVE = 5000;

//key presses throttling
export const THROTTLING_KEYPRESSES_TIME = 350;

//internal back event name
export const INTERNAL_BACK_EVENT_NAME = 'internalback';
export const INTERNAL_RB_EVENT_NAME = 'internalgamepadrb';
export const INTERNAL_LB_EVENT_NAME = 'internalgamepadlb';
export const INTERNAL_X_EVENT_NAME = 'internalgamepadx';
export const INTERNAL_B_EVENT_NAME = 'internalgamepadb';

//affiliate code for getting the pairing code
export const AFFILIATE_CODE_SAMSUNG = 'SAMSUNGH';
export const AFFILIATE_CODE_LG = 'R8aMDYG8';
export const AFFILIATE_CODE_VIDAA = 'jAZXjWP6';

export const IDEAL_PING_MS = 250;

export const TIME_MEDIA_WAIT_DATA = 10000;

export const TIME_SHOW_NOTIFICATION = 3000;

export const TIME_START_BUTTON_ACTIVATE = 1000;

export const GA_SESSION_ID = '_ga-sid';
export const GA_CLIENT_ID = '_ga-cid';
export const LOCAL_STORAGE_STREAM_FEEDBACK_KEY = 'STREAM_FEEDBACK_DATA';
export const TIME_TO_SHOW_AFTER_GAME_MODAL = 60 * 60; //60 minutes

export const JANUS_DISPOSE_TIMEOUT = 20000;
export const NETWORK_TEST_TIMEOUT = 60000;

export const TIME_TO_CHECK_UPDATES = 3.6e6; //1 hour// 3.6e6

export const LOCAL_STORAGE_STREAM_NOTIFICATION_KEY = 'stream-notification-minimized';

export const customClearButtonKey = 'CustomClearKey';
export const customVirtualEnterKey = 'CustomEnterKey';
export const customVirtualBackspaceKey = 'CustomBackspaceKey';

export const MAX_TIME_TO_LOGIN = 30_000; //in milliseconds

export const POTENTIALLY_RELOADED_STREAM_PAGE_KEY = 'potentiallyReloadedStreamPage';
