import { MIN_ITEMS_IN_VIEW } from '@utomik-app-monorepo/constants';
import { getDeviceTag } from '@utomik-app-monorepo/utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';

import { AppList } from '../../stores/applicationStore/appList';
import { ApiAppListEntry, AppListEntry } from '../../stores/applicationStore/appListEntry';
import { ApplicationStore } from '../../stores/applicationStore/applicationStore';
import { Application } from '../applicationStore/application';
import { AsyncObjectState } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';

/**
 * A store to manage the recently played games.
 */
export class RecentlyPlayedStore extends ObjectStore<AppListEntry, ApiAppListEntry> {
  private readonly _applicationStore: ApplicationStore;
  private _initialized = false;
  @observable
  private readonly _appList: AppList;

  /**
   * @param applicationStore - Is here mostly for the RequestQueue.
   * @param url - Api endpoint url.
   * @param retries - Amount of retries before failing request.
   */
  public constructor(applicationStore: ApplicationStore, url = 'v2/users/me/applications/lastplayed', retries = null) {
    super(
      applicationStore.requestQueue,
      `${url}?permissionfilter=country,age&cloud_platform=${getDeviceTag()}`,
      retries
    );
    makeObservable(this);
    this._applicationStore = applicationStore;
    this._appList = new AppList(this._applicationStore, 'recently_played', url);
  }

  public get appList() {
    return this._appList;
  }

  public get initialized(): boolean {
    return this._initialized;
  }

  @observable
  private _recentlyPlayedApps: Application[] = [];

  /**
   * Prepend a recently played app in front of the list. Filter it out of the list first if it's already in there.
   *
   * @param app - The app to prepend.
   */
  @action
  public addRecentlyPlayedApp(app: Application): void {
    const appIndex = this._recentlyPlayedApps.findIndex((application) => application.id === app.id);

    // Only do this when app index is not -1, otherwise, it slices the last entry of the array (because it starts at -1 and removes 1).
    appIndex !== -1 && this._recentlyPlayedApps.splice(appIndex, 1);

    this._recentlyPlayedApps.unshift(app);
  }

  /**
   * Initialize function because we can't await in the constructor.
   */
  public initialize = flow(function* (this: RecentlyPlayedStore) {
    if (this._initialized) return;

    this._appList.setState(AsyncObjectState.Pending);

    yield this.fetch();
    // Fill the recently played apps when we initialize this store.
    this._recentlyPlayedApps = this.items.map((appListEntry): Application => {
      // we're setting with both the ID and slug, so both maps will have it
      const app1 = this._applicationStore.getItem(appListEntry.id, appListEntry.name, appListEntry.permissions);
      app1.setSlug(appListEntry.slug);
      const app2 = this._applicationStore.getItem(appListEntry.slug, appListEntry.name, appListEntry.permissions);
      app2.setId(appListEntry.id);
      return app2;
    });

    /**
     * Please, don't comment this part.
     * We need this data for the Samsung GameHub
     * [RecentlyPlayedGameHubController.generateAndSend] send it to the OS and we need to know recently played after
     * user authorization
     */
    yield Promise.all(
      this._recentlyPlayedApps.map((app, idx) => {
        if (idx < MIN_ITEMS_IN_VIEW) {
          return app.fetch();
        }
        return null;
      })
    );

    this._initialized = true;
    this._appList.setState(AsyncObjectState.Done);
  });

  @computed
  public get recentlyPlayedApps(): Application[] {
    return this._recentlyPlayedApps;
  }

  public createAndLoadItem(apiAppListEntry: ApiAppListEntry): AppListEntry {
    return AppListEntry.parse(apiAppListEntry);
  }

  public override unload() {
    super.unload();
    this._appList.unload();
    this._recentlyPlayedApps = [];
    this._initialized = false;
  }
}
