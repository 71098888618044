import { RequestMethod, RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';

export interface IStreamingFeedbackBody {
  playsession: ObjectID;
  stream_positive_experience: boolean;
  stream_working: boolean;
  stream_low_image_quality: boolean;
  stream_low_audio_quality: boolean;
  stream_input_lag: boolean;
  stream_micro_stutters: boolean;
  stream_long_stutters: boolean;
}
export class StreamingFeedback {
  private readonly _requestQueue: RequestQueue;

  public constructor(requestQueue: RequestQueue) {
    this._requestQueue = requestQueue;
  }

  public sendStreamingFeedback(body: IStreamingFeedbackBody) {
    this._requestQueue.add<void>('/v2/feedback/cloudstreaming', body, RequestPriority.High, RequestMethod.Post);
  }
}
