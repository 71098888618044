import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { AchievementStore } from '@utomik-app-monorepo/store';
import { AsyncObjectState } from '@utomik-app-monorepo/store';
import { DialogFactoryContext } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent, isNullOrUndefined, repeatFnCall } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo } from 'react';
import './achievement-tiles-carousel.scss';
type AchievementTileProps = {
  seeAll?: boolean;
  index?: number;
  isNew?: boolean;
  hasMouseOver?: boolean;
};
const AchievementTile = withFocusable({
  autoRestoreFocus: true
})<AchievementTileProps>(observer(function AchievementTile({
  children,
  focused,
  seeAll,
  isNew,
  setFocus,
  hasMouseOver
}) {
  const handleMouseOver = () => {
    if (hasMouseOver) {
      setFocus();
    }
  };
  const handleClick = () => {
    setFocus();
    dispatchEnterEvent();
  };
  return <div className={cx('achievement-tile', {
    'achievement-tile--focused': focused,
    'achievement-tile--see-all': seeAll
  })} onMouseOver={handleMouseOver} onClick={handleClick}>
        {children}
        {isNew && <div className={'recently-achieved-label'}>{t({
        message: 'New'
      })}</div>}
      </div>;
}));
type Props = {
  achievementStore: AchievementStore;
  name?: string;
  hasMouseOver?: boolean;
  maxTiles?: number;
  onChildFocused?: (props: any, itemProps: any) => void;
  containerRef?: React.Ref<HTMLDivElement>;
  scrollType?: 'native' | 'transform';
};
export const AchievementTilesCarousel = withFocusable()<Props>(observer(function AchievementTilesCarousel({
  achievementStore,
  focused,
  setFocus,
  hasMouseOver,
  maxTiles = 6,
  onChildFocused,
  containerRef,
  scrollType = 'transform'
}) {
  const dialogFactory = useContext(DialogFactoryContext);
  useEffect(() => {
    achievementStore?.updateProgress();
  }, [achievementStore]);
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (focused && achievementStore?.state === AsyncObjectState.Done) {
      intervalId = repeatFnCall(setFocus);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [focused, achievementStore?.state]);
  const sortedAchievements = achievementStore?.achievementGroupSortedByUnlockedRecently.achievements || [];
  const unlockedAchievementsCount = achievementStore?.achievementsUnlockedCount;
  const allAchievementsCount = achievementStore?.achievementsCount;
  const hiddenAchievements = useMemo(() => achievementStore?.items.filter(x => {
    return x?.hidden && isNullOrUndefined(x?.values.unlockedAt);
  }), [achievementStore?.items]);
  const handleTilePress = ({
    index
  }) => {
    dialogFactory.showAchievementsDialog(achievementStore, index, scrollType);
  };
  const handleSeeAllPress = () => {
    dialogFactory.showAchievementsDialog(achievementStore, 0, scrollType);
  };
  const tiles = sortedAchievements?.slice(0, maxTiles).map((item, index) => {
    if (index === maxTiles - 1) {
      return <AchievementTile onBecameFocused={onChildFocused} hasMouseOver={hasMouseOver} key={item.id} onEnterPress={handleSeeAllPress} seeAll>
            <p className={'achievements-qty'}>+ {allAchievementsCount - (maxTiles - 1)}</p>
            <p className={'achievements-see-all'}>See all</p>
          </AchievementTile>;
    }
    return <AchievementTile onBecameFocused={onChildFocused} hasMouseOver={hasMouseOver} key={item.id} onEnterPress={handleTilePress} index={index} isNew={achievementStore?.newAchievements.has(item.id)}>
          <img decoding={'sync'} alt={item?.name} src={item?.values.unlockedAt ? item?.images.unlocked : item?.images.locked} />
        </AchievementTile>;
  });
  if (tiles?.length < maxTiles && hiddenAchievements?.length > 0) {
    tiles.push(<AchievementTile onBecameFocused={onChildFocused} hasMouseOver={hasMouseOver} key={'hidden-achievements-count-tile'} onEnterPress={handleSeeAllPress} seeAll>
          <p className={'achievements-qty'}>+ {hiddenAchievements.length}</p>
          <p className={'achievements-see-all'}>Hidden</p>
        </AchievementTile>);
  }
  return <section className={'achievements-carousel__container'} ref={containerRef}>
        <p className={'carousel-title'}>
          {t({
        message: 'Achievements',
        context: 'A carousel title'
      })} {unlockedAchievementsCount}/
          {allAchievementsCount}
        </p>
        {tiles}
        {!allAchievementsCount && achievementStore?.state === AsyncObjectState.Done && <div className={cx('no-achievements__container', {
      'no-achievements__container--focus': focused
    })}>
            <FontAwesomeIcon icon={faTrophy} />
            <p className={'no-achievements-title'}>
              {t({
          context: 'No achievements title',
          message: 'No achievements'
        })}
            </p>
            <p className={'no-achievements-text'}>
              {t({
          context: 'No achievements title',
          message: "This game doesn't have any achievements."
        })}
            </p>
          </div>}
      </section>;
}));