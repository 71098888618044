import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton } from '@utomik-app-monorepo/components';
import { NavigationControllerContext } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import styles from './main-menu-item.module.scss';
type Props = {
  icon?: string;
  isActive?: boolean;
  onEnterPress?: (props: any) => void;
  id?: string;
};
export const MainMenuItem = withFocusable()(observer(({
  icon,
  isActive,
  onEnterPress,
  id
}: Props) => {
  const navigationController = useContext(NavigationControllerContext);
  return <div data-id={id}>
        <FocusableButton id={id} onEnterPress={onEnterPress} showOutline={navigationController.isGamepadInteraction} className={cx(styles.button, {
      [styles.buttonActive]: isActive
    })} type={'transparent'}>
          <img className={styles.icon} alt={icon} src={icon} />
        </FocusableButton>
      </div>;
}));