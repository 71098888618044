import * as Yup from 'yup';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton } from '@utomik-app-monorepo/components';
import { TextInputField } from '@utomik-app-monorepo/components';
import { useLoginErrorHandler } from '@utomik-app-monorepo/hooks';
import { LoginControllerContext, LoginLocation, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { FormEventHandler, useContext, useEffect } from 'react';
import styles from './authorization.module.scss';
export const AuthorizationForm = withFocusable()(observer(({
  setFocus
}) => {
  const navigationController = useContext(NavigationControllerContext);
  const loginController = useContext(LoginControllerContext);
  const [errorHandler, errorMessage] = useLoginErrorHandler();
  const buttonNamesMap = {
    [LoginLocation.Authorization]: t({
      context: 'Button - Continue',
      message: 'Continue'
    }),
    [LoginLocation.Login]: t({
      context: 'Button - Log in action',
      message: 'Log in'
    })
  };
  const initialValues = {
    email: '',
    password: ''
  };
  const validationShape = {
    email: Yup.string().email().required(),
    password: Yup.string().test('password', '', val => loginController.location === LoginLocation.Authorization || val?.length >= 3)
  };
  const validationSchema = Yup.object().shape(validationShape);
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async ({
      email,
      password
    }, {
      setSubmitting,
      setErrors,
      validateForm
    }): Promise<void> => {
      setSubmitting(true);
      try {
        await loginController.login(email, password);
      } catch (e) {
        errorHandler(e);
      } finally {
        setSubmitting(false);
      }
    }
  });
  useEffect(() => {
    //autofocus on a field
    if (loginController.location === LoginLocation.Authorization) {
      setFocus('email-field');
    } else if (loginController.location === LoginLocation.Login) {
      setFocus('password-field');
    }
    formik.validateForm();
  }, [loginController.location]);
  useEffect(() => {
    loginController.clearError();
  }, [loginController.location, formik.values]);
  const handleSubmit = () => {
    if (loginController.location === LoginLocation.Login) {
      formik.submitForm();
    } else {
      setFocus('password-field');
      loginController.setLocation(LoginLocation.Login);
    }
  };
  const handleForm: FormEventHandler = e => {
    e.preventDefault();
    handleSubmit();
  };
  return <form onSubmit={handleForm} className={styles.form}>
        <TextInputField showOutline={navigationController.isGamepadInteraction} placeholder={t({
      message: 'Email'
    })} autofocus width={'fullwidth'} type={'email'} name={'email'} focusKey={'email-field'} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
        {loginController.location === LoginLocation.Login && <TextInputField showOutline={navigationController.isGamepadInteraction} placeholder={t({
      message: 'Password'
    })} autofocus width={'fullwidth'} type={'password'} name={'password'} focusKey={'password-field'} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} errorMessage={<Trans comment={'Error message'} id={errorMessage} message={errorMessage} values={{
      minutes: loginController.errorValue
    }} />} />}
        {/*Trick to submit the form on Enter press*/}
        <button type={'submit'} style={{
      display: 'none'
    }}></button>
        <FocusableButton showOutline={navigationController.isGamepadInteraction} type={'primary'} size={'xlarge'} onEnterPress={handleSubmit} focusable={formik.isValid} isLoading={formik.isSubmitting} className={styles.submitButton}>
          {buttonNamesMap[loginController.location]}
        </FocusableButton>
      </form>;
}));