import { pathTv } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../focusable-button/focusable-button';
import './nothing-found.scss';
type Props = {
  title?: string;
  subtitle?: string;
  className?: string;
  withButton?: boolean;
  onButtonPress?: () => void;
};
export const NothingFound: React.FC<Props> = observer(function NothingFound({
  title,
  subtitle,
  className,
  withButton,
  onButtonPress
}) {
  return <div className={cx('nothing-found__wrapper', {
    [className]: !!className
  })}>
      <img decoding={'sync'} src={pathTv + require('images/svg/nothing-found.svg')} className={'image'} />

      <h1 className={'title'}>{title}</h1>
      <p className={'subtitle'}>{subtitle}</p>
      {withButton && <FocusableButton onEnterPress={onButtonPress} type={'secondary'}>
          Reset search
        </FocusableButton>}
    </div>;
});