import { log } from '@utomik-app-monorepo/logger';

import { ClientController } from '../../../app/global/clientController/clientController';
import { PlatformController } from '../../../app/global/platformController/platformController';
import { RequestQueue } from '../../requestQueue/requestQueue';
import { IssueTypes } from '../issueTypes/issueTypes';
import { AsyncObjectState } from '../objectStore/asyncObject';
import { LogsStore } from './logsStore';

export class TizenLogsStore extends LogsStore {
  private _suffix = '_logfile.txt';
  private _writeFileHandler;
  constructor(
    requestQueue: RequestQueue,
    platformController: PlatformController,
    issueTypes: IssueTypes,
    clientController: ClientController
  ) {
    super(requestQueue, platformController, issueTypes, clientController);
  }

  private _getSortedDirList = async (): Promise<string[]> => {
    const dirList = await new Promise<string[]>((resolve, reject) => {
      //window.tizen.filesystem.listDirectory('wgt-private/logs', resolve, reject);

      function onSuccess(dir) {
        dir.listFiles(
          function (files) {
            const fileNames = [];
            for (let i = 0; i < files.length; i++) {
              if (files[i].isFile) {
                fileNames.push(files[i].name);
              }
            }

            resolve(fileNames);
          },
          function (error) {
            reject(error);
          }
        );
      }

      function onError(error) {
        reject(error);
      }

      window.tizen.filesystem.resolve('wgt-private/logs', onSuccess, onError, 'r');
    });

    if (!dirList) return [];

    //sort file names by date in ASC order
    return dirList.sort((a, b) => {
      if (a.endsWith(this._suffix) && b.endsWith(this._suffix)) {
        const dateA = a.split(this._suffix)[0];
        const dateB = b.split(this._suffix)[0];

        return new Date(dateA).valueOf() - new Date(dateB).valueOf();
      }
      return 1;
    });
  };

  private _removeOlderFiles = async (list: string[]) => {
    const MAX_LOG_FILES = 5;
    let counter = list.length - MAX_LOG_FILES;

    //remove older files if the files qty is more than MAX_LOG_FILES
    while (counter > 0) {
      try {
        await new Promise((resolve, reject) => {
          window.tizen.filesystem.deleteFile('wgt-private/logs/' + list[0], resolve, reject);
        });
        list.shift();
        counter--;
      } catch (e) {
        counter = 0;
      }
    }
  };

  protected createLogsFile = async () => {
    const fileName = new Date().toISOString() + this._suffix;

    try {
      //create a new file and write an initial string to it
      this._writeFileHandler = window.tizen.filesystem.openFile('wgt-private/logs/' + fileName, 'w');

      this._writeFileHandler.writeString(
        '*****************************START OF THE LOG FILE******************************* \r\n'
      );

      const sortedDirListASC = await this._getSortedDirList();

      await this._removeOlderFiles(sortedDirListASC);

      return true;
    } catch (e) {
      return false;
    }
  };

  public addLogToFile = async (log: string) => {
    const MAX_FILE_SIZE = 1000000;

    if (!this._writeFileHandler) return;

    this._writeFileHandler.writeString(log + '\r\n');

    const readFileHandler = window.tizen.filesystem.openFile(this._writeFileHandler.path, 'r');
    const { size } = readFileHandler.readBlob();

    if (size > MAX_FILE_SIZE) {
      readFileHandler.close();
      this._writeFileHandler.close();
      this._writeFileHandler = null;
      //don't need to await here
      try {
        this.createLogsFile();
      } catch (e: any) {
        console.log(e.message);
      }
    }
  };

  public async deleteLogsFolder() {
    window.tizen.filesystem.deleteDirectory(
      'wgt-private/logs/',
      true,
      () => {
        log('Successfully deleted');
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public deleteLogFile(name: string) {
    window.tizen.filesystem.deleteFile(
      'wgt-private/logs/' + name,
      () => {
        log('Successfully deleted');
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public sendLogs = async () => {
    this.state = AsyncObjectState.Pending;

    try {
      const fileNames = await this._getSortedDirList();

      const fileStrings = fileNames.map((name) => {
        const handler = window.tizen.filesystem.openFile('wgt-private/logs/' + name, 'r');

        const fileString = handler.readString();

        handler.close();

        return fileString;
      });

      await this.sendLogFiles(fileStrings);

      this.state = AsyncObjectState.Done;
    } catch (e) {
      this.state = AsyncObjectState.Error;
    }
  };

  public dispose() {
    this._writeFileHandler.close();
    this._writeFileHandler = null;
  }
}
