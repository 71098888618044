import { LOCAL_STORAGE_STREAM_FEEDBACK_KEY } from '@utomik-app-monorepo/constants';
import { randomGaussian } from '@utomik-app-monorepo/utils';
import isEmpty from 'lodash/isEmpty';
import { action, computed, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../../dataStore/requestQueue/requestQueue';
import { ServiceSettingStore } from '../../../dataStore/stores/ninjaSummary/serviceSettingStore';
import { IStreamingFeedbackBody, StreamingFeedback } from '../../../dataStore/stores/streamStore/streamingFeedback';
import { ClientController } from '../clientController/clientController';
import { StreamViewUtilsService } from '../streamViewUtilsService/streamViewUtilsService';

interface ISessionsData {
  counter: number;
  showAgain: boolean;
}

export class StreamingFeedbackController {
  private _DEFAULT_CHANCE = 0.75;
  private _streamingFeedbackStore: StreamingFeedback;
  private _streamViewUtilsService: StreamViewUtilsService;
  private _serviceSettingsStore: ServiceSettingStore;
  private _clientController: ClientController;
  private _sessionData: ISessionsData;
  @observable
  private _showAgain = true;
  @observable
  private _isLoading: boolean;
  private _timeoutId: ReturnType<typeof setTimeout>;
  constructor(
    requestQueue: RequestQueue,
    streamViewUtilsService: StreamViewUtilsService,
    serviceSettingsStore: ServiceSettingStore,
    clientController: ClientController
  ) {
    this._streamViewUtilsService = streamViewUtilsService;
    this._serviceSettingsStore = serviceSettingsStore;
    this._clientController = clientController;
    this._streamingFeedbackStore = new StreamingFeedback(requestQueue);

    makeObservable(this);
  }

  public sendStreamingFeedback(body: Omit<IStreamingFeedbackBody, 'playsession'>) {
    if (!this._streamViewUtilsService.lastPlaySessionId) return;

    const newBody = { ...body, playsession: this._streamViewUtilsService.lastPlaySessionId };

    this._streamingFeedbackStore.sendStreamingFeedback(newBody);
  }

  public init = () => {
    /**
     * After game stream feedback flow
     * */
    const json = localStorage.getItem(LOCAL_STORAGE_STREAM_FEEDBACK_KEY + this._clientController.user?.id);

    let data: ISessionsData;

    if (json) {
      /**
       * If there is some data in the localStorage using our key -> increment the sessions counter
       * */
      data = JSON.parse(json) as ISessionsData;
      localStorage.setItem(
        LOCAL_STORAGE_STREAM_FEEDBACK_KEY + this._clientController.user?.id,
        JSON.stringify({ ...data, counter: data.counter + 1 } as ISessionsData)
      );
    } else {
      /**
       * If not -> create a new data using our key
       * */
      data = { counter: 0, showAgain: true };

      localStorage.setItem(LOCAL_STORAGE_STREAM_FEEDBACK_KEY + this._clientController.user?.id, JSON.stringify(data));
    }

    this._sessionData = data;
  };
  @computed
  public get isLoading() {
    return this._isLoading;
  }
  @action
  private setIsLoading(value: boolean) {
    this._isLoading = value;
  }

  public get sessionData() {
    return this._sessionData;
  }
  @computed
  public get showAgain() {
    return this._showAgain;
  }

  public get shouldShowFeedbackDialog() {
    const data = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_STREAM_FEEDBACK_KEY + this._clientController.user?.id)
    ) as ISessionsData;

    this._showAgain = data?.showAgain || false;

    if (!data?.showAgain) return false;

    const { setting } = this._serviceSettingsStore.getServiceSetting('stream-feedback-percentage');
    const chanceToShow = !isEmpty(setting.value) ? 1 - setting.value : this._DEFAULT_CHANCE;

    return randomGaussian(0, 1) > chanceToShow;
  }
  @action
  public setDontShowAgain = ({ value }) => {
    this.setIsLoading(true);

    this._timeoutId = setTimeout(() => {
      this.setIsLoading(false);
    }, 500);

    const data = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_STREAM_FEEDBACK_KEY + this._clientController.user?.id)
    ) as ISessionsData;

    localStorage.setItem(
      LOCAL_STORAGE_STREAM_FEEDBACK_KEY + this._clientController.user?.id,
      JSON.stringify({ counter: data?.counter || 0, showAgain: !value } as ISessionsData)
    );

    this._showAgain = !value;
  };

  public unload() {
    clearTimeout(this._timeoutId);
    localStorage.removeItem(LOCAL_STORAGE_STREAM_FEEDBACK_KEY + this._clientController.user?.id);
  }
}
