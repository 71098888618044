import { observer } from 'mobx-react';
export const FPSLineChart = observer(() => {
  //TODO: Add chart provider if needed
  //
  // const ref1 = createRef<HTMLDivElement>();
  // const ref2 = createRef<HTMLDivElement>();
  // const ref3 = createRef<HTMLDivElement>();
  // const ref4 = createRef<HTMLDivElement>();
  // const { chartProvider } = useContext(StreamControllerContext);
  // useEffect(() => {
  //   if (chartProvider.fpsMLChart) {
  //     ref1.current?.replaceChildren(chartProvider.fpsMLChart.diffFrameDecoded);
  //     ref2.current?.replaceChildren(chartProvider.fpsMLChart.diffFrameReceived);
  //     ref3.current?.replaceChildren(chartProvider.fpsMLChart.realBitrate);
  //     ref4.current?.replaceChildren(chartProvider.fpsMLChart.lossPacket);
  //   }
  // }, [chartProvider.fpsMLChart, ref1, ref2, ref3, ref4]);
  //
  // return (
  //   <>
  //     {!!chartProvider.fpsMLChart && (
  //       <div className="d3-container" style={{ position: 'absolute', top: 0, backgroundColor: 'rgba(0,0,0,0.25)' }}>
  //         <div className="d3-chart" ref={ref1}></div>
  //         <div className="d3-chart" ref={ref2}></div>
  //         <div className="d3-chart" ref={ref3}></div>
  //         <div className="d3-chart" ref={ref4}></div>
  //       </div>
  //     )}
  //   </>
  // );
  return null;
});