import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, makeObservable, observable } from 'mobx';

import { ObjectID } from '../objectStore/asyncObject';

export interface ApiUserStatApplication {
  id: number;
}

export interface UserStatApplication {
  id: number;
}

export interface ApiUserStat {
  id: ObjectID;
  last_played: string;
  total_seconds_played: number;
  application: ApiUserStatApplication;
}

export class UserStat {
  public static parse(apiItem: ApiUserStat): UserStat {
    return new UserStat(apiItem);
  }
  @observable
  public id: number;
  @observable
  public userStatId: ObjectID;
  @observable
  public lastPlayed: Date;
  @observable
  public secondsPlayed: number;
  @observable
  public application: UserStatApplication;

  public constructor(x: ApiUserStat) {
    makeObservable(this);
    this.initialize(x);
  }

  @action
  private initialize(apiUserStat: ApiUserStat): void {
    // We want to key UserStat by applicationId.
    this.id = apiUserStat.application.id;
    // Just in case we'll store the userStatId.
    this.userStatId = apiUserStat.id;
    this.lastPlayed = isNullOrUndefined(apiUserStat.last_played) ? null : new Date(apiUserStat.last_played);
    this.secondsPlayed = isNullOrUndefined(apiUserStat.total_seconds_played) ? 0 : apiUserStat.total_seconds_played;
    this.application = apiUserStat.application;
  }
}
