import { Vector2, useGesture } from '@use-gesture/react';
import { useEffect, useState } from 'react';
const MIN_ZOOM_LIMIT = 1;
const MAX_ZOOM_LIMIT = 2;
type Props = {
  element: HTMLVideoElement;
  container: HTMLDivElement;
  onMove: (x: number, y: number) => void;
  onDragStart: (x: number, y: number) => void;
  onDragEnd: (x: number, y: number) => void;
  enabled?: boolean;
};
export const useStreamGestures = ({
  element,
  container,
  onMove,
  onDragStart,
  onDragEnd,
  enabled = true
}: Props) => {
  const [zoomData, setZoomData] = useState<{
    zoom: number;
    origin: Vector2;
  }>({
    zoom: MIN_ZOOM_LIMIT,
    origin: [0, 0]
  });
  const isZoomedIn = zoomData.zoom > MIN_ZOOM_LIMIT;
  const screen = window.screen;
  const windowWidth = screen.orientation.type === 'portrait-primary' ? screen.width : screen.height;
  const windowHeight = screen.orientation.type === 'portrait-primary' ? screen.height : screen.width;
  const calculateDisplayedVideoSize = (): {
    width: number;
    height: number;
    deltaWidth: number;
    deltaHeight: number;
  } => {
    if (element && element.videoWidth && element.videoHeight) {
      const videoWidth = element.videoWidth;
      const videoHeight = element.videoHeight;
      const elementWidth = element.clientWidth;
      const elementHeight = element.clientHeight;
      const videoAspectRatio = videoWidth / videoHeight;
      const elementAspectRatio = elementWidth / elementHeight;
      let displayedWidth: number, displayedHeight: number;
      if (elementAspectRatio > videoAspectRatio) {
        // Element is wider than video content
        displayedHeight = elementHeight;
        displayedWidth = elementHeight * videoAspectRatio;
      } else {
        // Element is taller than video content
        displayedWidth = elementWidth;
        displayedHeight = elementWidth / videoAspectRatio;
      }
      const deltaWidth = elementWidth - displayedWidth;
      const deltaHeight = elementHeight - displayedHeight;
      return {
        width: displayedWidth,
        height: displayedHeight,
        deltaWidth: deltaWidth,
        deltaHeight: deltaHeight
      };
    } else {
      // Handle cases where element or video dimensions are not available
      return {
        width: 0,
        height: 0,
        deltaWidth: 0,
        deltaHeight: 0
      };
    }
  };
  const getRelativePosition = (state): Vector2 => {
    const videSize = calculateDisplayedVideoSize();
    const relativeX = (state.event.clientX - videSize.deltaWidth / 2) / videSize.width;
    const relativeY = (state.event.clientY - videSize.deltaHeight / 2) / videSize.height;
    const relativeOriginX = (zoomData.origin[0] - videSize.deltaWidth / 2) / videSize.width;
    const relativeOriginY = (zoomData.origin[1] - videSize.deltaHeight / 2) / videSize.height;

    // Adjust the coordinates considering both scaling and transform-origin
    const correctedX = (relativeX - relativeOriginX) / zoomData.zoom + relativeOriginX;
    const correctedY = (relativeY - relativeOriginY) / zoomData.zoom + relativeOriginY;
    return [correctedX, correctedY];
  };
  useGesture({
    onPinch: pinchGestureState => {
      setZoomData(prev => {
        let newZoom = prev.zoom + pinchGestureState.delta[0];
        let newOrigin: Vector2 = [...prev.origin];
        if (newZoom > MAX_ZOOM_LIMIT) {
          newZoom = MAX_ZOOM_LIMIT;
          pinchGestureState.cancel();
        } else if (newZoom < MIN_ZOOM_LIMIT) {
          newZoom = MIN_ZOOM_LIMIT;
          pinchGestureState.cancel();
        }
        if (!isZoomedIn) {
          newOrigin = pinchGestureState.origin;
        }
        return {
          zoom: newZoom,
          origin: newOrigin
        };
      });
    },
    onClick: state => {
      const [x, y] = getRelativePosition(state);
      onMove && onMove(x, y);
      onDragStart(x, y);
      onDragEnd(x, y);
    },
    onDragStart: state => {
      if (isZoomedIn) return;
      onDragStart(state.xy[0], state.xy[1]);
    },
    onDragEnd: state => {
      if (isZoomedIn) return;
      onDragEnd(state.xy[0], state.xy[1]);
    },
    onDrag: dragGestureState => {
      setZoomData(prev => {
        let newXPosition = prev.origin[0] + dragGestureState.delta[0] * -1;
        let newYPosition = prev.origin[1] + dragGestureState.delta[1] * -1;
        if (newXPosition < 0) {
          newXPosition = 0;
        } else if (newXPosition > windowWidth) {
          newXPosition = windowWidth;
        }
        if (newYPosition < 0) {
          newYPosition = 0;
        } else if (newYPosition > windowHeight) {
          newYPosition = windowHeight;
        }
        const newOrigin: Vector2 = [newXPosition, newYPosition];
        return {
          ...prev,
          origin: newOrigin
        };
      });
      if (!isZoomedIn) {
        const [x, y] = getRelativePosition(dragGestureState);
        onMove && onMove(x, y);
      }
    }
  }, {
    target: container,
    enabled
  });
  useEffect(() => {
    if (!element?.style) return;
    element.style.transform = `scale(${zoomData.zoom})`;
    element.style.transformOrigin = `${zoomData.origin[0]}px ${zoomData.origin[1]}px`;
  }, [zoomData, element?.style]);
};