import { useOnScreenGamepad } from '@utomik-app-monorepo/hooks';
import { OnScreenGamepadButtonsConfig, OnScreenGamepadSticksConfig, OnScreenGamepadUi } from '@utomik-app-monorepo/react-on-screen-gamepad';
import { StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { sizeRemToPx } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
export const OnScreenStreamingGamepad: React.FC = observer(() => {
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  useOnScreenGamepad();
  const handleButtonPress = (code: number) => {
    streamViewUtilsService.onScreenGamepad?.setButton(code, true, 1);
  };
  const handleButtonRelease = (code: number) => {
    streamViewUtilsService.onScreenGamepad?.setButton(code, false, 0);
  };
  const handleLeftStickMove = ({
    x,
    y
  }: IJoystickUpdateEvent) => {
    streamViewUtilsService.onScreenGamepad?.setAxes('left', {
      x: x || 0,
      y: y || 0
    });
  };
  const handleRightStickMove = ({
    x,
    y
  }: IJoystickUpdateEvent) => {
    streamViewUtilsService.onScreenGamepad?.setAxes('right', {
      x: x || 0,
      y: y || 0
    });
  };
  const sticksConfig: OnScreenGamepadSticksConfig = {
    right: {
      size: sizeRemToPx(12),
      stickSize: sizeRemToPx(12),
      baseColor: '#1e1933',
      stickImage: require('images/svg/gamepad-stick.svg'),
      stickColor: 'white',
      move: handleRightStickMove,
      stop: handleRightStickMove
    },
    left: {
      size: sizeRemToPx(12),
      stickSize: sizeRemToPx(12),
      baseColor: '#1e1933',
      stickImage: require('images/svg/gamepad-stick.svg'),
      stickColor: 'white',
      move: handleLeftStickMove,
      stop: handleLeftStickMove
    }
  };
  const buttonsConfig: OnScreenGamepadButtonsConfig = {
    onButtonPress: handleButtonPress,
    onButtonRelease: handleButtonRelease
  };
  return <OnScreenGamepadUi buttons={buttonsConfig} sticks={sticksConfig} show={!!streamViewUtilsService.onScreenGamepad} setShow={val => streamViewUtilsService.setOnScreenGamepadVisible(val)} />;
});