import { faCheck, faCircleExclamation, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { log } from '@utomik-app-monorepo/logger';
import { observer } from 'mobx-react';
import React from 'react';
import { NotificationStack } from '../notificationStack/notificationStack';
export class NotificationFactory {
  private notificationStack: NotificationStack;
  constructor(stack: NotificationStack) {
    this.notificationStack = stack;
  }
  public showRequestFailedNotification = (): void => {
    const Content: React.FC<unknown> = observer(function Content() {
      return <>
          <FontAwesomeIcon className={'thrash-icon'} icon={faCircleExclamation} />
          {t({
          message: 'Some data could not be loaded. Please check your internet connection and try again'
        })}
        </>;
    });
    this.notificationStack.add('request_failed', ['error-notification'], 0, Content, () => {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      log('request_failed notification closed');
    });
  };
  public showAddToMyListNotification(): void {
    const Content: React.FC<unknown> = observer(function Content() {
      return <>
          <FontAwesomeIcon className={'check-icon'} icon={faCheck} />
          {t({
          message: 'Successfully added to list'
        })}
        </>;
    });
    this.notificationStack.remove('remove_from_my_list');
    this.notificationStack.add('add_to_my_list', ['success-notification'], 0, Content, () => {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      log('add_to_my_list notification closed');
    });
  }
  public showRemoveFromMyListNotification(): void {
    const Content: React.FC<unknown> = observer(function Content() {
      return <>
          <FontAwesomeIcon className={'thrash-icon'} icon={faTrash} />
          {t({
          message: 'You removed from the list'
        })}
        </>;
    });
    this.notificationStack.remove('add_to_my_list');
    this.notificationStack.add('remove_from_my_list', ['error-notification'], 0, Content, () => {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      log('remove_from_my_list notification closed');
    });
  }
  public showGameRatedNotification = (): void => {
    const Content: React.FC<unknown> = observer(function Content() {
      return <>
          <FontAwesomeIcon className={'check-icon'} icon={faCheck} />
          {t({
          message: 'You have successfully rated the game'
        })}
        </>;
    });
    this.notificationStack.add('game_rated', ['success-notification'], 0, Content, () => {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      log('game_rated notification closed');
    });
  };
}