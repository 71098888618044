/**
 * Gets data from a URL
 * Supports response status 200, 201.
 * All others will respond with statusText as exception.
 * @param url URL get request from
 * @param token (Optional) JWT token that will be used in the header.
 * @param method (Optional) Request method "GET" or "POST", defaults to GET.
 * @param postData (Optional) PostData to send in your request as json, defaults to never.
 * @returns ReturnType, defaults to never.
 */

export default async function resolveItem<ReturnType = never, PostDataType = never>(
  url: string,
  token?: string,
  cloudToken?: string,
  method: 'GET' | 'POST' = 'GET',
  postData?: PostDataType
): Promise<ReturnType> {
  /**
   * Supply JWT token if present.
   */
  const headers = new Headers();
  if (token) headers.append('Authorization', `JWT ${token}`);
  if (cloudToken) headers.append('x-auth-token', cloudToken);
  if (postData) headers.append('Content-Type', 'application/json');

  const res = await fetch(url, {
    method,
    headers,
    body: postData ? JSON.stringify(postData) : undefined,
  });

  if ([200, 201].indexOf(res.status) > -1) {
    const body = await res.text();
    const data = (body.length ? JSON.parse(body) : '') as ReturnType;
    return data;
  } else {
    console.log(`${token ? 'AUTHORIZED' : 'ANONYMOUS'} ${method} STATUS ${res.status} "${res.statusText}" ${res.url}`);
    throw res.statusText;
  }
}
