import { t } from '@lingui/macro';
import { ConnectionNotificator, Loading, LongPressButtonProcedure } from '@utomik-app-monorepo/components';
import { Routes } from '@utomik-app-monorepo/constants';
import { useConfirmOnPageExit, useHistoryChangeBlocker, useQueueDialogs, useResetVideoPlayer, useStreamGestures, useStreamNetworkTest, useVisibilityChange } from '@utomik-app-monorepo/hooks';
import { AnalyticControllerContext, Application, ApplicationStoreContext, ClientControllerContext, DialogFactoryContext, DialogQueueContext, DialogResult, LogEventAction, LogEventType, LogsControllerContext, RecentlyPlayedStoreContext, StreamViewUtilsContext, TokenManagerContext } from '@utomik-app-monorepo/store';
import StreamView, { IStreamView, LogData, StreamErrorType } from '@utomik-app-monorepo/stream-view';
import { getBrowserData, isSafari } from '@utomik-app-monorepo/utils';
import debounce from 'lodash/debounce';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { OnScreenStreamingGamepad } from '../../../components/on-screen-gamepad/on-screen-streaming-gamepad';
import { StreamSideMenu } from '../../../components/stream-side-menu/stream-side-menu';
import './stream.scss';
const HiddenInput = observer(() => {
  return <input tabIndex={-1} id={'stream-text-input'} data-id={'INPUT'} style={{
    position: 'absolute',
    top: '-10rem'
  }} />;
});
const VIDEO_ELEMENT_ID = 'main_video';
const useDisableContextMenu = () => {
  const clientController = useContext(ClientControllerContext);
  useEffect(() => {
    const handleContextMenu = e => {
      if (!clientController.user?.isTesterOrAdmin) {
        e.preventDefault();
      }
    };
    window.addEventListener('contextmenu', handleContextMenu);
    return () => window.addEventListener('contextmenu', handleContextMenu);
  }, [clientController.user?.isTesterOrAdmin]);
};
const useFullscreen = (ref: React.MutableRefObject<IStreamView>, handleBack: (systemInitiated: boolean) => void, app: Application) => {
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const dialogQueue = useContext(DialogQueueContext);
  const analyticController = useContext(AnalyticControllerContext);
  const handleFullscreenChange = () => {
    if (document.fullscreenElement !== document.body && dialogQueue.currentDialog?.analyticName !== 'StreamFullscreenDialog') {
      dialogFactory.showStreamFullscreenDialog().then(res => {
        if (res === DialogResult.OK) {
          if (!document.pointerLockElement && !isSafari) {
            ref.current?.lockPointer();
          }
          ref.current.toggleFullscreen();
        } else if (res === DialogResult.Cancel) {
          ref.current.disposeStream();
        }
      });
    }
  };
  useEffect(() => {
    const disposer = reaction(() => streamViewUtilsService.state, state => {
      if (state === 'Closed') {
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
        if (document.fullscreenElement) {
          document.exitFullscreen();
          document.exitPointerLock();
        }
        handleBack(true);
      } else if (state === 'Initialized') {
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        analyticController.gameStarted({
          item_name: app.slug,
          location_on_page: Routes.GameRun
        });
      }
    });
    return () => {
      disposer();
    };
  }, []);
  useEffect(() => {
    if (streamViewUtilsService.state === 'Initialized') {
      dialogFactory.showStartGameDialog().then(res => {
        if (res === DialogResult.OK) {
          const videoEl = document.getElementById(VIDEO_ELEMENT_ID) as HTMLVideoElement;
          videoEl.play();
          if (!document.pointerLockElement && !isSafari) {
            ref.current?.lockPointer();
          }
          ref.current.toggleFullscreen();
        }
      });
    }
  }, [streamViewUtilsService.state]);
  useEffect(() => {
    if (dialogQueue.hasDialogs) {
      if (document.pointerLockElement) {
        document.exitPointerLock();
      }
    } else {
      if (!document.pointerLockElement && !isSafari) {
        ref.current?.lockPointer();
      }
    }
  }, [dialogQueue.hasDialogs]);
};
export const Stream = observer(() => {
  const appController = useContext(ApplicationStoreContext);
  const tokenManager = useContext(TokenManagerContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const dialogQueue = useContext(DialogQueueContext);
  const analyticController = useContext(AnalyticControllerContext);
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const clientController = useContext(ClientControllerContext);
  const recentlyPlayedStore = useContext(RecentlyPlayedStoreContext);
  const logsController = useContext(LogsControllerContext);
  const {
    id
  } = useParams();
  const app = appController.getItem(id);
  const navigate = useNavigate();
  const ref = useRef<IStreamView>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const loadingTexts = {
    Initializing: t({
      context: 'In process of loading data',
      message: 'Loading...'
    }),
    Closed: t({
      context: 'In process of loading data',
      message: 'Loading...'
    }),
    Queued: t({
      context: 'In process of waiting in line',
      message: 'Waiting in line...'
    }),
    Closing: t({
      context: 'In process of updating user stats',
      message: 'Updating user stats...'
    }),
    Initialized: ''
  };
  const debouncedRef = useRef(debounce((enabled: boolean) => {
    ref.current?.toggleInputCapture(enabled);
  }, 500));
  const image = useMemo(() => app.images?.find(item => item.tag === 'LB')?.url, [app.images]);
  const handleBack = async (systemInitiated: boolean) => {
    analyticController.stopGame({
      location_on_page: Routes.GameRun,
      item_name: app.slug
    });
    analyticController.gameEnded({
      item_name: app.slug,
      location_on_page: Routes.GameRun
    });
    await streamViewUtilsService.updateUserStats();
    streamViewUtilsService.dispose();

    // The systemInitiated flag is used for detecting ether redirect is initiated by system or not
    navigate(`${Routes.AppInfo}/${app.id}`, {
      replace: true,
      state: {
        systemInitiated
      }
    });
  };
  useVisibilityChange(VIDEO_ELEMENT_ID);
  useConfirmOnPageExit(streamViewUtilsService.isPreFetched);
  useDisableContextMenu();
  useFullscreen(ref, handleBack, app);
  useStreamNetworkTest();
  useQueueDialogs(app, ref.current);
  useResetVideoPlayer();
  useStreamGestures({
    enabled: !streamViewUtilsService.onScreenGamepad,
    element: document.getElementById(VIDEO_ELEMENT_ID) as HTMLVideoElement,
    container: containerRef.current,
    onMove(x, y) {
      window.dispatchEvent(new CustomEvent('touchscreenmove', {
        detail: {
          x: x,
          y: y
        }
      }));
    },
    onDragStart() {
      window.dispatchEvent(new CustomEvent('touchscreendown', {
        detail: {
          button: 0
        }
      }));
    },
    onDragEnd() {
      window.dispatchEvent(new CustomEvent('touchscreenup', {
        detail: {
          button: 0
        }
      }));
    }
  });
  useEffect(() => {
    if (ref.current) {
      streamViewUtilsService.setRef(ref.current);
    }
  });
  useEffect(() => {
    const shouldSend = !dialogQueue.hasDialogs;
    if (!shouldSend) {
      ref.current?.toggleInputCapture(false);
    } else {
      //delay
      debouncedRef.current(true);
    }
  }, [dialogQueue.hasDialogs]);
  useEffect(() => {
    (async () => {
      await streamViewUtilsService.prefetch(id);
      app.setInitialPlayTimeSeconds();
      recentlyPlayedStore.addRecentlyPlayedApp(app);
    })();
    recentlyPlayedStore.addRecentlyPlayedApp(app);
    return () => {
      streamViewUtilsService.dispose();
      dialogQueue.clear();
      clientController.cameFrom = Routes.GameRun;
    };
  }, []);
  const handleExitStream = async () => {
    const res = await dialogFactory.showCloseStreamDialog(app.id);
    if (res === DialogResult.OK) {
      ref.current?.disposeStream();
      streamViewUtilsService.dispose();
    }
  };
  useHistoryChangeBlocker({
    onBlocked: handleExitStream
  });
  const handleStreamError = async (errorType: StreamErrorType, errorCode?: number) => {
    await dialogFactory.showStreamMessageDialog(0, errorType);
    handleBack(true);
  };
  const handleInactivity = async (time: number) => {
    const res = await dialogFactory.showInactivityDialog(time, 0);
    return res === DialogResult.OK;
  };
  const handleLog = (ev: LogData) => {
    logsController.addNewLogEvent({
      type: ev.type as LogEventType,
      action: ev.action as LogEventAction,
      verbosity: ev.verbosity,
      value: ev.value
    });
  };
  const browserData = getBrowserData();
  return <div ref={containerRef} className={'stream-view__container'}>
      {streamViewUtilsService.state !== 'Initialized' && <div className={'game-loading-screen__container'} style={{
      backgroundImage: `url("${image}")`
    }}>
          {!streamViewUtilsService.queueState?.isQueue && <div className={'loader-container'}>
              <Loading classNames={['loading-stream']} size={'small'} loadingText={loadingTexts[streamViewUtilsService.state]} />
            </div>}
        </div>}
      {streamViewUtilsService.isPreFetched && <>
          <ConnectionNotificator />
          <StreamView elementId={VIDEO_ELEMENT_ID} ref={ref} applicationId={+id} gatewayAddress={streamViewUtilsService.currentGateway.gateway.address} gatewayName={streamViewUtilsService.currentGateway.gateway.name} applicationVersionId={streamViewUtilsService.currentAppVersion.id} token={tokenManager.token.value.token} cloudToken={streamViewUtilsService.cloudToken} deviceInfo={browserData} onStateChanged={streamViewUtilsService.setStreamState} onConnectionQualityChanged={streamViewUtilsService.setConnectionQuality} onStreamError={handleStreamError} onInactivity={handleInactivity} onLog={handleLog} />
        </>}

      <HiddenInput />
      <StreamSideMenu show={streamViewUtilsService.state === 'Initialized'} onExitStream={handleExitStream} />
      <LongPressButtonProcedure />
      <ConnectionNotificator />
      <OnScreenStreamingGamepad />
    </div>;
});