import { RequestMethod, RequestPriority } from '../../requestQueue/requestQueue';
import { AsyncList } from '../../stores/objectStore/asyncList';
import { StreamStore } from '../../stores/streamStore/streamStore';
import { ObjectID } from '../objectStore/asyncObject';

interface IPlaySessionStatisticBody {
  playsession: string;
  gateway: string;
  user_client_version: string;
  device: IDeviceInfo;
  os: IOSInfo;
}

interface IDeviceInfo {
  type: string;
  model: string;
  brand: string;
}

interface IOSInfo {
  type: string;
  version: string;
}

export class PlaySessionStatistic extends AsyncList<void> {
  private readonly streamStore: StreamStore;

  public constructor(streamStore: StreamStore, id: ObjectID, url?: string) {
    super(streamStore.requestQueue, id, url);

    this.streamStore = streamStore;
  }

  public async playSessionStatistic(body: IPlaySessionStatisticBody): Promise<void> {
    this.requestQueue.add<void>(this.url, body, RequestPriority.High, RequestMethod.Post);
  }
}
