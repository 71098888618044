import React from 'react';
type Props = {
  steps: React.ReactElement[];
  currentStep: number;
};
export const Stepper: React.FC<Props> = React.memo(function Stepper({
  steps,
  currentStep
}) {
  return steps[currentStep] || null;
});