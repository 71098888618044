import { log } from '@utomik-app-monorepo/logger';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { flow, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { RequestMethod, RequestPriority, RequestQueue, RequestStatus } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';
import { ApiUserRating, UserRating } from './userRating';

export class UserRatingStore extends ObjectStore<UserRating, ApiUserRating> {
  public constructor(queue: RequestQueue, url = 'v1/users/me/applicationratings') {
    super(queue, url);
    makeObservable(this);
  }

  protected createAndLoadItem(apiUserStat: ApiUserRating): UserRating {
    return UserRating.parse(apiUserStat);
  }

  public getItemByApplicationId = computedFn((applicationId: ObjectID): UserRating => {
    return this.itemsMap.get(applicationId);
  });

  public userRateApplication = flow(function* (this: UserRatingStore, applicationId: ObjectID, rating: number) {
    let userRating = this.itemsMap.get(applicationId);
    if (isNullOrUndefined(userRating)) {
      const apiUserRating: ApiUserRating = {
        application: {
          id: applicationId,
        },
        rating: 0,
      };
      this.itemsMap.set(applicationId, this.createAndLoadItem(apiUserRating));
      userRating = this.itemsMap.get(applicationId);
    }
    userRating.setRating(rating);

    // userRatingId corresponds to applicationId
    const url = `v1/applications/${userRating.id}/do_rate`;
    const onStatusChanged = (status: RequestStatus): void => {
      switch (status) {
        case RequestStatus.QueuedForRetry:
          break;
        case RequestStatus.RetryingFailedRequest:
          userRating.setRating(rating);
          break;
      }
    };

    try {
      yield this.requestQueue.add<void, { rating: number }>(
        url,
        {
          rating: rating,
        },
        RequestPriority.High,
        RequestMethod.Post,
        undefined,
        undefined,
        undefined,
        onStatusChanged
      ).promise;
    } catch (error) {
      log(`rate(${rating}) - Error: ${error}`);
      throw error;
    }
  });
}
