import { I18nProvider } from '@lingui/react';
//By importing this file we initialize the Lingui i18n
import { i18n } from '@utomik-app-monorepo/locales';
import { initGlobals } from '@utomik-app-monorepo/store';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import ErrorBoundary from './error-boundary';
import './index.scss';
import './initFontAwesome';
import { router } from './router';
const {
  detectLocale
} = require('../../../localeManager');
const locale = detectLocale();
i18n.activate(locale);
initGlobals();
const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
root.render(<I18nProvider i18n={i18n}>
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </I18nProvider>);