import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './request-failed-dialog.scss';
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
export const RequestFailedDialog: React.FC<Props> = observer(function RequestFailedDialog({
  action
}) {
  return <div className={'request-failed-dialog__container'}>
      <p>{t({
        message: 'Some data could not be loaded. Please check your internet connection and try again'
      })}</p>
      <div className={'buttons-container'}>
        <FocusableButton autofocus onEnterPress={() => action(DialogResult.OK)} type={'primary'}>
          {t({
          message: 'Retry'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default RequestFailedDialog;