import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './stream-ping-too-high-dialog.scss';
export const StreamPingTooHighDialog = observer(function StreamPingTooHighDialog({
  action
}) {
  return <div className={'stream-ping__container'}>
      <h1 className={'title'}>
        <>{t({
          message: 'Game performance'
        })}</>
      </h1>
      <p className={'place-text'}>
        {t({
        message: 'Your connection to our servers is currently not good enough.\n Utomik cannot guarantee a good experience. '
      })}
      </p>
      <div className={'button-container'}>
        <FocusableButton autofocus type={'primary'} onEnterPress={() => action(DialogResult.OK)}>
          {t({
          message: 'Continue'
        })}
        </FocusableButton>
        <FocusableButton type={'secondary'} onEnterPress={() => action(DialogResult.Cancel)}>
          {t({
          message: 'Cancel'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default StreamPingTooHighDialog;