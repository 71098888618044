import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useSetTestId } from '@utomik-app-monorepo/hooks';
import { LogsControllerContext } from '@utomik-app-monorepo/store';
import { LogEventAction, LogEventType } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent, repeatFnCall } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { CSSProperties, useContext, useEffect, useRef } from 'react';
import { Loading } from '../loading/loading';
import './focusable-button.scss';
enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  dark = 'dark',
  back = 'back',
  forward = 'forward',
  close = 'close',
  delete = 'delete',
  'tab-button' = 'tab-button',
  'tab-button-rect' = 'tab-button-rect',
  transparent = 'transparent',
  clickable = 'clickable',
}
type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onEnterPress?: (props: any) => void;
  id?: string;
  focused?: boolean;
  setFocus?: (id?: string) => void;
  type: keyof typeof ButtonTypes;
  role?: 'submit' | 'button' | 'reset';
  isActive?: boolean;
  autofocus?: boolean;
  focusKey?: string;
  focusable?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  absoluteElement?: React.ReactNode;
  pulsating?: boolean;
  isLoading?: boolean;
  onVisibilityChange?: (isVisible: boolean) => void;
  showOutline?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
};
export const FocusableButton = withFocusable()<Props>(observer(function FocusableButton({
  children,
  className,
  style,
  focused,
  setFocus,
  isActive,
  type = ButtonTypes.primary,
  role = 'button' as const,
  autofocus,
  size = 'large',
  absoluteElement,
  pulsating,
  isLoading,
  onVisibilityChange,
  id,
  focusable,
  showOutline = true,
  onClick,
  icon = null
}: Props) {
  const logsController = useContext(LogsControllerContext);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useSetTestId(buttonRef, type);
  useEffect(() => {
    let observer: IntersectionObserver;
    let intervalId: ReturnType<typeof setInterval>;
    if (autofocus) {
      intervalId = repeatFnCall(setFocus);
    }
    if (onVisibilityChange) {
      const handleIntersect = ([entry]: IntersectionObserverEntry[]) => {
        onVisibilityChange(entry.isIntersecting);
      };
      observer = new IntersectionObserver(handleIntersect, {
        root: null,
        rootMargin: '1000px 0px 1000px 0px',
        threshold: 0
      });
      buttonRef.current && observer.observe(buttonRef.current);
    }
    return () => {
      buttonRef.current && observer.unobserve(buttonRef.current);
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    //send the button press log events
    const keyDownHandler = e => {
      if (focused && e.key === 'Enter') {
        logsController.addNewLogEvent({
          type: LogEventType.Button,
          action: LogEventAction.Press,
          value: buttonRef.current?.innerText || type
        });
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
  }, [focused]);
  useEffect(() => {
    if (focused) {
      buttonRef.current.focus();
    }
  }, [focused]);
  const classes = cx(`focusable-button focusable-button--${type} focusable-button--${size} ${className ? className : ''}`, {
    'focusable-button--focus': focused,
    'focusable-button--outline': focused && showOutline && type !== 'clickable',
    'focusable-button--active': isActive,
    'focusable-button--disabled': focusable === false,
    'focusable-button--pulsating': pulsating
  });
  let element = icon;
  if (type === 'back') {
    element = <FontAwesomeIcon icon={faArrowLeft as IconProp} />;
  }
  if (type === 'forward') {
    element = <FontAwesomeIcon icon={faArrowRight as IconProp} />;
  }
  if (type === 'close') {
    element = <FontAwesomeIcon icon={faTimes as IconProp} />;
  }
  const handleClick = () => {
    onClick && onClick();
    if (focusable === false) return;
    setFocus();
    dispatchEnterEvent();
  };
  const handleMouseOver = () => {
    if (focusable === false) return;
    setFocus();
  };
  return <button className={classes} style={style} type={role} id={id} ref={buttonRef} onClick={handleClick} onMouseDown={() => setFocus()} onMouseOver={handleMouseOver} disabled={(focusable === false || isLoading === true) && type !== ButtonTypes.clickable}>
        {element}
        {children}
        {isLoading && <Loading classNames={['button-loading']} />}
        {absoluteElement}
      </button>;
}));