import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Routes } from '@utomik-app-monorepo/constants';
import { Gamepad } from '@utomik-app-monorepo/gamepad';
import { useBackButton } from '@utomik-app-monorepo/hooks';
import { usePlayButton } from '@utomik-app-monorepo/hooks';
import { ControllerActions, DialogQueueContext, InteractionType, NavigationContext, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent, dispatchLBEvent, dispatchRBEvent } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
export const WebNavigationControls = withFocusable()(observer(function NavigationControls({
  children,
  navigateByDirection,
  resumeSpatialNavigation,
  pauseSpatialNavigation
}) {
  const navigationController = React.useContext(NavigationControllerContext);
  const dialogQueue = React.useContext(DialogQueueContext);
  const redirect = useNavigate();
  const location = useLocation();
  const repeaterIdRef = useRef<ReturnType<typeof setInterval>>(null);
  const isStickPressedRef = useRef(false);
  const [backHandler] = useBackButton();
  const [playHandler] = usePlayButton();
  const STICK_THRESHOLD = 0.5;
  useEffect(() => {
    const handleDisconnectGamepad = () => {
      clearInterval(repeaterIdRef.current);
    };
    window.addEventListener('gamepaddisconnected', handleDisconnectGamepad);
    return () => {
      window.removeEventListener('gamepaddisconnected', handleDisconnectGamepad);
    };
  }, []);
  const handleInput = useCallback(direction => {
    if (document.activeElement.tagName === 'INPUT' && (document.activeElement as HTMLInputElement).type !== 'checkbox') {
      pauseSpatialNavigation();
      const element = document.activeElement as HTMLInputElement;
      switch (direction) {
        case ControllerActions.Up:
          {
            resumeSpatialNavigation();
            break;
          }
        case ControllerActions.Down:
          {
            resumeSpatialNavigation();
            break;
          }
        case ControllerActions.Right:
          {
            // input of type 'email' doesn't support selection
            if (element.type === 'email') {
              resumeSpatialNavigation();
              break;
            }
            if (element.selectionEnd === element.value.length) {
              resumeSpatialNavigation();
              break;
            }
            element.selectionEnd += 1;
            element.selectionStart = element.selectionEnd;
            return;
          }
        case ControllerActions.Left:
          {
            // input of type 'email' doesn't support selection
            if (element.type === 'email') {
              resumeSpatialNavigation();
              break;
            }
            if (element.selectionEnd === 0) {
              resumeSpatialNavigation();
              break;
            }
            element.selectionEnd -= 1;
            return;
          }
      }
    }
    navigateByDirection(direction);
  }, []);
  const navigate = (direction: ControllerActions) => {
    if (!direction) return;
    //if input element is focused
    handleInput(direction);
    window.dispatchEvent(new CustomEvent(`gamepad-${direction}`));
  };
  const repeatDirection = useCallback((direction: ControllerActions, isPressed: boolean) => {
    clearInterval(repeaterIdRef.current);
    const TIME_TO_WAIT_UNTIL_REPEAT = 500;
    const REPEAT_INTERVAL = 200;
    if (isPressed) {
      repeaterIdRef.current = setTimeout(() => {
        clearTimeout(repeaterIdRef.current);
        repeaterIdRef.current = setInterval(navigate.bind(null, direction), REPEAT_INTERVAL);
      }, TIME_TO_WAIT_UNTIL_REPEAT);
      return;
    }
    clearTimeout(repeaterIdRef.current);
    clearInterval(repeaterIdRef.current);
  }, [repeaterIdRef.current]);
  const buttonsHandler = (button, pressed) => {
    //set gamepad interaction mode
    navigationController.setInteractionMode(InteractionType.Gamepad);
    switch (button) {
      case 'Start':
        {
          navigationController.longPressButtonService.handleButtonPress(pressed, 7);
          break;
        }
      case 'DPadLeft':
        {
          return repeatDirection(ControllerActions.Left, pressed);
        }
      case 'DPadRight':
        {
          return repeatDirection(ControllerActions.Right, pressed);
        }
      case 'DPadUp':
        {
          return repeatDirection(ControllerActions.Up, pressed);
        }
      case 'DPadDown':
        {
          return repeatDirection(ControllerActions.Down, pressed);
        }
      case 'LeftStickX':
        {
          if (pressed < -STICK_THRESHOLD && !isStickPressedRef.current) {
            isStickPressedRef.current = true;
            return repeatDirection(ControllerActions.Left, true);
          } else if (pressed > STICK_THRESHOLD && !isStickPressedRef.current) {
            isStickPressedRef.current = true;
            return repeatDirection(ControllerActions.Right, true);
          } else if (pressed <= STICK_THRESHOLD || pressed >= -STICK_THRESHOLD) {
            isStickPressedRef.current = false;
            return clearInterval(repeaterIdRef.current);
          } else {
            return;
          }
        }
      case 'LeftStickY':
        {
          if (pressed < -STICK_THRESHOLD && !isStickPressedRef.current) {
            isStickPressedRef.current = true;
            return repeatDirection(ControllerActions.Down, true);
          } else if (pressed > STICK_THRESHOLD && !isStickPressedRef.current) {
            isStickPressedRef.current = true;
            return repeatDirection(ControllerActions.Up, true);
          } else if (pressed <= STICK_THRESHOLD || pressed >= -STICK_THRESHOLD) {
            isStickPressedRef.current = false;
            return clearInterval(repeaterIdRef.current);
          }
        }
    }
  };
  const handleAPress = () => {
    if (dialogQueue.hasDialogs || !location.pathname.startsWith(Routes.GameRun)) {
      dispatchEnterEvent();
    }
  };
  return <NavigationContext.Provider value={{
    backHandler,
    playHandler
  }}>
        <Gamepad onRB={dispatchRBEvent} onLB={dispatchLBEvent} onUp={navigate.bind(null, ControllerActions.Up)} onDown={navigate.bind(null, ControllerActions.Down)} onLeft={navigate.bind(null, ControllerActions.Left)} onRight={navigate.bind(null, ControllerActions.Right)} onA={handleAPress} onB={backHandler as any} onX={() => navigationController.playGame(redirect)} onAxisChange={buttonsHandler} onButtonChange={buttonsHandler}>
          {children}
        </Gamepad>
      </NavigationContext.Provider>;
}));