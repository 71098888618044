import moment from 'moment';

import { AccountSummary, SubscriptionStatus } from '../../../dataStore/stores/accountSummary/accountSummary';
import { TokenManager } from '../tokenManager/tokenManager';

export class EntitlementDataGamingHubController {
  private readonly accountSummary: AccountSummary;
  private readonly tokenManager: TokenManager;

  constructor(accountSummary: AccountSummary, tokenManager: TokenManager) {
    this.accountSummary = accountSummary;
    this.tokenManager = tokenManager;
  }

  public generateAndSend() {
    const entitlementData = {
      userSignedIn: this.tokenManager.isAuthorized,
      subscribed:
        this.accountSummary.subscription?.status == SubscriptionStatus.Active ||
        moment(this.accountSummary.subscription?.expire_due).valueOf() > Date.now(),
    };

    if (window.saveEntitlementData) {
      window.saveEntitlementData(entitlementData);
    }
  }
}
