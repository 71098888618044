import { RequestMethod, RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { TokenAuthResponse } from '../authorizationStore/authorizationStore';

//Response to a call to rssTokenAPI
export interface ApiLGTokenRSSResponse {
  token: string;
}

/**
 * Class that handles LG stuff
 */
export class LGStore {
  private readonly requestQueue: RequestQueue;

  public constructor(requestQueue: RequestQueue) {
    this.requestQueue = requestQueue;
  }

  public async rssTokenAPI(jwt: TokenAuthResponse): Promise<ApiLGTokenRSSResponse> {
    const config = { headers: { Authorization: `JWT ${jwt.token}` } as any };

    const apiRSSTokenResponse = await this.requestQueue.add<ApiLGTokenRSSResponse>(
      '/v2/partnerships/LG/rss-token-auth',
      {},
      RequestPriority.High,
      RequestMethod.Post,
      config
    ).promise;
    return apiRSSTokenResponse.data;
  }
}
