import { faCheck, faGamepad, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Routes, myUrl } from '@utomik-app-monorepo/constants';
import { ClientControllerContext, DialogFactoryContext, LoginControllerContext, MyListStoreContext, NavigationControllerContext, PlatformControllerContext, TokenManagerContext } from '@utomik-app-monorepo/store';
import { Application } from '@utomik-app-monorepo/store';
import { AsyncObjectState } from '@utomik-app-monorepo/store';
import { NavigationContext } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { FocusableBackButton } from '../focusable-back-button/focusable-back-button';
import { FocusableButton } from '../focusable-button/focusable-button';
import { SessionInfo } from '../session-info/session-info';
import { StarRating } from '../star-rating/star-rating';
import { TagsList } from '../tags-list/tags-list';
import './game-info-section-rd.scss';
type FocusablePlayButtonsProps = {
  item: Application;
  show?: boolean;
  viewTitle?: string;
  className?: string;
  showOutline?: boolean;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  showMoreInfoButton?: boolean;
  autofocusOnMainButton?: boolean;
};
export const FocusablePlayButtons: React.FC<FocusablePlayButtonsProps> = observer(function FocusablePlayButtons({
  item,
  show,
  className,
  showOutline = true,
  size,
  showMoreInfoButton,
  autofocusOnMainButton
}) {
  const loginController = useContext(LoginControllerContext);
  const platformController = useContext(PlatformControllerContext);
  const tokenManager = useContext(TokenManagerContext);
  const myListStore = useContext(MyListStoreContext);
  const clientController = useContext(ClientControllerContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const navigationController = useContext(NavigationControllerContext);
  const navigate = useNavigate();
  const isInMyList = item && myListStore.contains(item);
  const handleChangeMyList = useCallback(() => {
    if (myListStore.state === AsyncObjectState.Pending) return;
    if (isInMyList) {
      myListStore.remove(item);
    } else {
      myListStore.add(item);
    }
  }, [isInMyList, item]);
  const handlePlayNow = useCallback(async () => {
    const isValidToken = await tokenManager.checkToken();
    if (!isValidToken) return tokenManager.logout();
    navigationController.setCurrentId(item.id);
    navigationController.setAllowedPlayPress(true);
    navigationController.playGame(navigate).catch(e => console.log(e));
  }, [navigationController.isGamepadConnected, item?.id]);
  const handleMoreInfo = () => {
    navigate(`${Routes.AppInfo}/${item.id}`);
  };
  const handleSubscription = () => {
    if (platformController.isWeb) {
      return dialogFactory.showFinishExternalActionsDialog(`${myUrl}/subscription`);
    }
    loginController.subscriptionTVFlow();
  };
  if (!show) return null;
  let playButton: React.ReactElement;
  if (item?.isComingSoonRestricted) {
    playButton = <FocusableButton size={size} showOutline={showOutline} focusable={false} disabled type={'primary'}>
        {t({
        context: 'Button title which is disabled for a regular user',
        message: 'Coming soon'
      })}
      </FocusableButton>;
  } else if (clientController.user.canPlay) {
    playButton = <FocusableButton autofocus={autofocusOnMainButton} showOutline={showOutline} focusable={item.isAllowedToPlay} focusKey={'main-button'} type={'primary'} onEnterPress={handlePlayNow} size={size}>
        <span className={'check-container'}>
          <FontAwesomeIcon icon={faGamepad} />
        </span>
        {t({
        context: 'Play game button, if it is pressed - user redirected to the stream page and start playing',
        message: 'Play now'
      })}
      </FocusableButton>;
  } else if (clientController.shouldUpgradeSubscription) {
    playButton = <FocusableButton autofocus={autofocusOnMainButton} size={size} showOutline={showOutline} focusKey={'main-button'} type={'primary'} onEnterPress={handleSubscription}>
        {t({
        context: 'Button title - shows is user does not have active Cloud subscription',
        message: 'Upgrade'
      })}
      </FocusableButton>;
  } else {
    playButton = <FocusableButton autofocus={autofocusOnMainButton} size={size} showOutline={showOutline} focusKey={'main-button'} type={'primary'} onEnterPress={handleSubscription}>
        {t({
        context: 'Button title - shows is user does not have active subscription',
        message: 'Get subscription'
      })}
      </FocusableButton>;
  }
  return <div className={cx('play-buttons__container', {
    [className]: !!className
  })}>
      {playButton}

      {showMoreInfoButton && <FocusableButton icon={<FontAwesomeIcon icon={'circle-info'} />} size={size} showOutline={showOutline} type={'secondary'} onEnterPress={handleMoreInfo}>
          {t({
        context: 'Button title - If user pressed on that button, they redirected to the game info page with detailed description',
        message: 'More info'
      })}
        </FocusableButton>}

      <FocusableButton size={size} showOutline={showOutline} type={'secondary'} onEnterPress={handleChangeMyList} isLoading={myListStore.state === AsyncObjectState.Pending}>
        <span className={'check-container'}>
          {isInMyList ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faPlus} />}
        </span>
        {isInMyList ? t({
        context: 'Button - Remove game from My list',
        message: 'Remove from list'
      }) : t({
        context: 'Button - Add game to My list',
        message: 'Add to list'
      })}
      </FocusableButton>
    </div>;
});
type Props = {
  isLoading?: boolean;
  showPlayButtons?: boolean;
  showBackButton?: boolean;
  app?: Application;
  name?: string;
  classes?: string[];
  title?: string;
  showEvent?: boolean;
  analyticViewTitle?: string;
  event?: {
    description?: string;
    smallText?: string;
    buttonTitle?: React.ReactNode;
    backgroundImage?: string;
    onButtonClick?: () => void;
  };
  videoLoop?: boolean;
};
export const GameInfoSectionRd = withFocusable({
  trackChildren: true
})<Props>(observer(function GameInfoSectionRd({
  showBackButton,
  app: externApp,
  showPlayButtons,
  classes = [],
  title,
  analyticViewTitle
}) {
  const clientController = useContext(ClientControllerContext);
  const {
    currentApp
  } = useContext(NavigationControllerContext);
  const {
    backHandler
  } = useContext(NavigationContext);
  const app: Application = externApp || currentApp;
  useEffect(() => {
    app?.fetch();
  }, [app]);
  const isAppLoading = !app || app?.state === AsyncObjectState.Pending;
  const showNotAvailable = showPlayButtons && !app?.isSupportedOnTv && !clientController.user.isAdmin;
  return <section className={['game-info-section-rd__container', ...classes].join(' ')}>
        <div className={'info__container'}>
          <div className={'back-button__wrapper'}>
            {showBackButton && <FocusableBackButton className={'back-button__container'} onEnterPress={backHandler} />}
            {!!title && <p className={'view-title'}>{title}</p>}
          </div>

          <div>
            <StarRating rating={app?.rating.global} isLoading={isAppLoading} />
            <h1 className={cx('title', {
          'title--loading': isAppLoading
        })}>{app?.name}</h1>
            <p className={cx('description', {
          'description--loading': isAppLoading
        })}>{app?.excerpt}</p>
            <TagsList tags={app?.genres} isLoading={isAppLoading} />
            <FocusablePlayButtons show={showPlayButtons && !showNotAvailable} item={app} viewTitle={analyticViewTitle} />
            {showNotAvailable && <div>
                <p className={'text-not-available'}>{t({
              message: 'This game is not available on TV'
            })}</p>
              </div>}
            {showPlayButtons && !showNotAvailable && <SessionInfo app={app} />}
          </div>
        </div>
      </section>;
}));