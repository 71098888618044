import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Application } from '@utomik-app-monorepo/store';
import { AsyncObjectState } from '@utomik-app-monorepo/store';
import { NotificationFactoryContext } from '@utomik-app-monorepo/store';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { TileCarousel } from '../tile-carousel/tile-carousel';
export const InfoTab = withFocusable()<{
  app: Application;
  onFocus: (props: any, itemProps: any) => void;
}>(observer(function InfoTab({
  app,
  onFocus,
  onEnterPress
}) {
  const notificationFactory = useContext(NotificationFactoryContext);
  useEffect(() => {
    const disposer = reaction(() => app.rating.user, () => {
      notificationFactory.showGameRatedNotification();
    });
    return () => disposer();
  }, []);
  return <TileCarousel isLoading={app.relatedAppList.state === AsyncObjectState.Pending || app.relatedAppList.state === AsyncObjectState.Error} focusable={!!app.relatedAppList.length} list={app.relatedAppList} name={t({
    context: 'Name of the carousel with game tiles',
    message: 'Related games'
  })} onEnterPress={onEnterPress} onBecameFocused={onFocus} showSeeAllTile={false} />;
}));