import { makeObservable } from 'mobx';

import { RequestQueue } from '../../../requestQueue/requestQueue';
import { ObjectStore } from '../../objectStore/objectStore';

export interface ApiAchievementProgress {
  id: number;
  unlocked_at: string;
  statistic_value: number;
}
export class AchievementProgress {
  static parse(apiItem: ApiAchievementProgress): AchievementProgress {
    return new AchievementProgress(apiItem);
  }
  public constructor(x: ApiAchievementProgress) {
    this.id = x.id;
    this.unlockedAt = x.unlocked_at ? new Date(x.unlocked_at) : null;
    this.statisticValue = x.statistic_value;
  }

  public id: number;
  public unlockedAt: Date;
  public statisticValue: number;
}

export class AchievementProgressStore extends ObjectStore<AchievementProgress, ApiAchievementProgress> {
  public constructor(queue: RequestQueue, url: string) {
    super(queue, url);
    makeObservable(this);
  }
  protected createAndLoadItem(x: ApiAchievementProgress): AchievementProgress {
    return AchievementProgress.parse(x);
  }
}
