import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Countries, Country } from '@utomik-app-monorepo/store';
import { ServiceSettingStoreContext } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { FocusableSelect } from '../focusable-select/focusable-select';
import './focusable-country-select.scss';
type Props = {
  onChange: (country: Country) => void;
  countries: Countries;
  errorMessage?: string;
  defaultCountry?: number;
};
export const FocusableCountrySelect = withFocusable()<Props>(observer(function FocusableCountrySelect({
  onChange,
  countries,
  errorMessage,
  defaultCountry,
  focusable
}) {
  const [country, setCountry] = useState<Country>(null);
  const [cloudCountries, setCloudCountries] = useState<Country[]>([]);
  const serviceSettingStore = useContext(ServiceSettingStoreContext);
  useEffect(() => {
    //fetch countries and set default value
    Promise.all([countries.fetch()]).then(() => {
      const supportedCountryCodes = serviceSettingStore.items.find(x => x.name === 'cloud_supported_country_codes')?.value as number[] ?? undefined;
      if (supportedCountryCodes !== undefined) setCloudCountries(countries.items.filter(item => {
        return supportedCountryCodes.includes(item.code);
      }));else setCloudCountries(countries.items);
      const initCountry = countries.items.find(item => item.id === defaultCountry);
      initCountry && setCountry(initCountry);
    });
  }, []);
  const handleChange = val => {
    setCountry(val);
    onChange(val);
  };
  return <div className={'focusable-date-picker__container'}>
        <FocusableSelect width={'wide'} values={cloudCountries} selectedValue={country?.name || 'Select your country'} onChange={handleChange} disabled={!focusable} />
        <div className="focusable-input-error">{errorMessage}</div>
      </div>;
}));