import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { NewsItem } from '@utomik-app-monorepo/store';
import { AsyncObjectState } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import Markdown from '../markdown/markdown';
import './single-news.scss';
type Props = {
  setFocus?: (props?: any) => void;
  news?: NewsItem;
  focused?: boolean;
  focusKey?: string;
  style?: CSSProperties;
  onBecameFocused?: (props?: any, itemProps?: any) => void;
  onEnterPress?: (props?: any, itemProps?: any) => void;
  index?: number;
  hasMouseOver?: boolean;
  layout?: 'horizontal' | 'vertical';
  showOutline?: boolean;
  idx?: number;
};
export const SingleNews = withFocusable()<Props>(observer(function SingleNews({
  focused,
  news,
  style,
  setFocus,
  hasMouseOver = true,
  layout = 'horizontal',
  showOutline
}: Props) {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const innerRef = useRef<HTMLDivElement>(null);
  const mainImageRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    news?.article.fetch();
  }, [news?.article]);
  const shortDesc = useMemo(() => {
    return <Markdown text={news?.article?.body?.substring(0, 800)} />;
  }, [news?.article?.body]);
  useEffect(() => {
    const ref = innerRef.current;
    //configuring intersection observer
    const handleIntersect = ([entry]: IntersectionObserverEntry[]) => {
      setIsEnabled(entry.isIntersecting);
    };
    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '1000px 0px 1000px 0px',
      threshold: 0
    });
    ref && observer.observe(ref);
    return () => ref && observer.unobserve(ref);
  }, []);
  const handleClick = () => {
    setFocus();
    dispatchEnterEvent();
  };
  const handleMouseOver = () => {
    if (hasMouseOver) {
      setFocus();
    }
  };
  return <div style={style} className={`single-news-view__container single-news-view__container--${layout}`} ref={innerRef} onMouseOver={handleMouseOver} onClick={handleClick}>
        {isEnabled && <div className={cx('single-news-view__wrapper', {
      'single-news-view__wrapper--new': news && !news?.read,
      //this class added for AQA testing
      'single-news-view__wrapper--focus': focused,
      'single-news-view__wrapper--focused': focused && showOutline
    })}>
            {news?.article.state !== AsyncObjectState.Done ? <>
                <div className={'news-skeleton-item__image'} />
                <div className={'news-skeleton-item__text-container'}>
                  <div className={'news-skeleton-item__tile1'} />
                  <div className={'news-skeleton-item__tile2'} />
                  <div className={'news-skeleton-item__description'} />
                </div>
              </> : <>
                <div className={'image'}>
                  <img decoding={'sync'} src={news.article.image} ref={mainImageRef} />
                </div>
                <div className={'description-container'}>
                  <h1 className={'news-title'}>{news.article.title}</h1>
                  <div className={'description-short'}>{shortDesc}</div>
                  <span className={'bottom-container'}>
                    <p className={'news-date'}>{moment(news.article.publishDate).format('L')}</p>
                  </span>
                </div>
              </>}
          </div>}
      </div>;
}));