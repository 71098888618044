import * as React from 'react';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Routes } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext } from '@utomik-app-monorepo/store';
import { Application } from '@utomik-app-monorepo/store';
import { sizeRemToPx } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { GameRating } from '../focusable-rating/game-rating';
import './app-rating.scss';
type Props = {
  app: Application;
  analyticOrigin?: string[];
};
export const AppRating = withFocusable()<Props>(observer(function AppRating({
  app
}: Props) {
  const analyticController = useContext(AnalyticControllerContext);
  const CONTAINER_HEIGHT = 5;
  const handleRate = rating => {
    analyticController.rateGame({
      rating: String(rating),
      item_name: app.slug,
      location_on_page: Routes.AppInfo
    });
    app.rating.setUser(rating);
  };
  return <div className={'app-rating__container'}>
        <span style={{
      height: sizeRemToPx(CONTAINER_HEIGHT) + 'px'
    }}>
          {t({
        context: 'Title - User`s rating',
        message: 'My rating'
      })}: <strong>{app.rating.user || '-'}</strong>
        </span>
        <GameRating focusKeyPrefix={'game_page_star_'} value={app.rating.user} onChange={handleRate} />
      </div>;
}));