import { action, makeObservable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';
import { ApiClientStagingTier, ClientStagingTier } from './clientStagingtier';

export class ClientStagingTierStore extends ObjectStore<ClientStagingTier, ApiClientStagingTier> {
  constructor(requestQueue: RequestQueue, url = 'v1/components/client/stagingtiers?fields=name,id') {
    super(requestQueue, url);
    makeObservable(this);
  }

  @action
  public getItem(name: ObjectID): ClientStagingTier {
    return this.itemsMap.get(name) as ClientStagingTier;
  }

  // Create a object and then load it.
  protected createAndLoadItem(x: ApiClientStagingTier): ClientStagingTier {
    const item = new ClientStagingTier(this, x.id, x.name);
    item.load(x);
    return item;
  }
}
