import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Stepper } from '@utomik-app-monorepo/components';
import { Routes } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, AnalyticItemTypes, ChannelStoreContext, ChannelsControllerContext, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { joinLocation } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { TileCarousel } from '../../../components/tile-carousel/tile-carousel';
import { Channel } from '../channel/channel';
import styles from './channels.module.scss';
export const Channels = withFocusable()(observer(({
  setFocus
}) => {
  const navigate = useNavigate();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const channelsController = useContext(ChannelsControllerContext);
  const channelStore = useContext(ChannelStoreContext);
  const navigationController = useContext(NavigationControllerContext);
  const analyticController = useContext(AnalyticControllerContext);
  useEffect(() => {
    channelsController.init();
  }, []);
  useEffect(() => {
    const focusKey = !channelsController.currentProvider ? channelsController.pageScrollState.currentFocusKey : undefined;
    setFocus(focusKey);
  }, [channelsController.allNotEmptyChannels, channelsController.currentProvider]);
  const handleTilePress = (props, provider) => {
    const breadcrumbs = analyticController.getBreadCrumbs({
      currentSection: props.carouselName,
      isCarousel: true
    });
    if (props.isSeeAll) {
      analyticController.navigate({
        item_name: props.key,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.SeeAll,
        location_on_page: joinLocation([...breadcrumbs, 'SeeAll'])
      });
      return channelsController.setCurrentProvider(provider);
    }
    analyticController.navigate({
      item_name: props.item.slug,
      location_index: String(props.idx),
      item_type: AnalyticItemTypes.Channel,
      location_on_page: joinLocation(breadcrumbs)
    });
    navigate(`${Routes.Channels}/${props.item.id}`);
  };
  const handleTileFocus = (_, itemProps) => {
    channelStore.clearPageScrollState();
    channelsController.pageScrollState.currentFocusKey = itemProps.focusKey;
  };
  const handleCarouselFocus = props => {
    const topOffset = parseInt(window.getComputedStyle(props.node.parentNode).paddingTop);
    scrollableContainerRef.current.scroll({
      behavior: 'smooth',
      top: props.y - topOffset
    });
  };
  return <Stepper steps={[<div className={styles.container}>
            <div className={styles.titleContainer}>
              <div className={styles.pageTitle}>{t({
          message: 'Channels'
        })}</div>
            </div>
            <div className={styles.scrollableContainer} ref={scrollableContainerRef}>
              {channelsController.allNotEmptyChannels?.map(provider => <TileCarousel showOutline={navigationController.isGamepadInteraction} key={provider.slug} onChildPress={handleTilePress} onChildFocused={handleTileFocus} onBecameFocused={handleCarouselFocus} provider={provider} name={provider.name} />)}
            </div>
          </div>, <Channel provider={channelsController.currentProvider} type={'seeall'} />]} currentStep={Number(!!channelsController.currentProvider)} />;
}));