import { normalizeArray } from '@utomik-app-monorepo/utils';
import { action, makeObservable, observable } from 'mobx';
import slug from 'slug';

import { AppList } from '../applicationStore/appList';
import { ApplicationMedia } from '../applicationStore/application';
import { AsyncObject, ObjectID } from '../objectStore/asyncObject';
import { ChannelStore } from './channelStore';

export interface ApiChannelMedia {
  id: number;
  download_url: string;
  tag: string;
}

export interface ChannelMedia {
  id: number;
  url: string;
  tag: string;
}
export interface ApiChannel {
  id: ObjectID;
  slug: string;
  name: string;
  description: string;
  images: ApiChannelMedia[];
  applications_list: string;
  spotlightitems_list: string;
  query_filters: string;
}

export class Channel extends AsyncObject<ApiChannel> {
  public store: ChannelStore;

  @observable
  public appList: AppList;

  @observable
  public spotlightList: AppList;

  @observable
  public name: string;

  @observable
  public slug: string;

  @observable
  public description: string;

  @observable
  public analyticsName: string;

  private _logoUrl: string;

  private _discardUrlQueryParams: boolean;

  /**
   * List of images used for the gallery component
   * TODO: Make ChannelMedia generic with ApplicationMedia.
   */
  @observable
  public images: { [key: string]: ChannelMedia };

  public constructor(
    store: ChannelStore,
    id: ObjectID,
    name?: string,
    retries = null,
    discardUrlQueryParams?: boolean
  ) {
    let analyticsName: string;
    if (id?.toString().startsWith('generated')) {
      // For generated channels this is of the form: generated,{genre/partner},{genre_id/partner_id},{name}
      const info = id?.toString().split(',');
      analyticsName = `${info[0]}-${info[1]}-${slug(info[3], { lower: true })}`;
      id = `all?${info[1]}=${info[2]}`;
      name = info[3];
    }

    super(store.requestQueue, id, `${store.getBaseUrl()}/${id}`, null, retries);

    this._discardUrlQueryParams = discardUrlQueryParams;

    makeObservable(this);
    this.initialize(store, name, analyticsName);
  }

  public set logoUrl(url: string) {
    this._logoUrl = url;
  }

  public get logoUrl() {
    return this._logoUrl;
  }

  @action
  private initialize(store: ChannelStore, name?: string, analyticsName?: string): void {
    this.name = name;
    this.analyticsName = analyticsName;
    this.store = store;
  }

  @action
  public load(apiChannel: ApiChannel): void {
    try {
      this.name = this.name ? this.name : apiChannel.name;
      this.slug = this.slug ? this.slug : apiChannel.slug;
      this.analyticsName = this.analyticsName ? this.analyticsName : this.slug;
      this.description = apiChannel.description;
      this.appList = new AppList(
        this.store.appStore,
        apiChannel.id,
        apiChannel.applications_list,
        this._discardUrlQueryParams
      );
      this.spotlightList = new AppList(
        this.store.appStore,
        apiChannel.id + ':spotlight',
        apiChannel.spotlightitems_list,
        this._discardUrlQueryParams
      );

      this.images = normalizeArray(
        apiChannel.images?.map(({ id, tag, download_url }): ApplicationMedia => {
          return {
            id,
            tag,
            url: download_url,
          };
        }),
        'tag'
      );
      this.store.mapItem(this);
    } catch (e) {
      throw new Error('Error in loading channel data from API: ' + e);
    }
  }
}
