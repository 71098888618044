/**
 * Because typescript won't allow you to type exceptions from try / catch
 * to anything other than unknown or any, this helper will handle exceptions
 * for you and returns them as a string.
 * ```ts
 * try {
 *  // Potentially unstable code
 * }
 * catch (e) {
 *  const error: string = exceptionToString(e);
 *  // Handle error message.
 * }
 * ```
 * @param exception unknown string or object
 * @returns Exception message
 */
export function exceptionToString(exception: unknown): string {
  if (typeof exception === 'string') {
    return exception.toUpperCase(); // `e` narrowed to string
  } else if (exception instanceof Error) {
    return `${exception?.message} cause: '${exception?.cause}'. Stack: '${exception?.stack}'`; // `e` narrowed to Error
  } else {
    return 'UNKNOWN_EXCEPTION_SHAPE';
  }
}

export function isFullscreen(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doc: any = document;
  return (
    doc.fullscreenElement /* Standard syntax */ ||
    doc.webkitFullscreenElement /* Safari and Opera syntax */ ||
    doc.mozFullscreenElement /* Firefox syntax */
  );
}

export function isKeyboardLockSupportedByBrowser(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nav: any = navigator;
  const supportsKeyboardLock = 'keyboard' in navigator && 'lock' in nav.keyboard;
  return supportsKeyboardLock;
}

export function isPointerLockSupportedByBrowser(): boolean {
  const supportsPointerLock =
    'pointerLockElement' in document || 'mozPointerLockElement' in document || 'webkitPointerLockElement' in document;
  return supportsPointerLock;
}
