import { Platform } from '@utomik-app-monorepo/utils';

export default {
  [Platform.WebOS]: {
    48: { code: 48, isShiftPressed: false, char: '0' },
    49: { code: 49, isShiftPressed: false, char: '1' },
    50: { code: 50, isShiftPressed: false, char: '2' },
    51: { code: 51, isShiftPressed: false, char: '3' },
    52: { code: 52, isShiftPressed: false, char: '4' },
    53: { code: 53, isShiftPressed: false, char: '5' },
    54: { code: 54, isShiftPressed: false, char: '6' },
    55: { code: 55, isShiftPressed: false, char: '7' },
    56: { code: 56, isShiftPressed: false, char: '8' },
    57: { code: 57, isShiftPressed: false, char: '9' },
    65: { code: 65, isShiftPressed: true, char: 'A' },
    66: { code: 66, isShiftPressed: true, char: 'B' },
    67: { code: 67, isShiftPressed: true, char: 'C' },
    68: { code: 68, isShiftPressed: true, char: 'D' },
    69: { code: 69, isShiftPressed: true, char: 'E' },
    70: { code: 70, isShiftPressed: true, char: 'F' },
    71: { code: 71, isShiftPressed: true, char: 'G' },
    72: { code: 72, isShiftPressed: true, char: 'H' },
    73: { code: 73, isShiftPressed: true, char: 'I' },
    74: { code: 74, isShiftPressed: true, char: 'J' },
    75: { code: 75, isShiftPressed: true, char: 'K' },
    76: { code: 76, isShiftPressed: true, char: 'L' },
    77: { code: 77, isShiftPressed: true, char: 'M' },
    78: { code: 78, isShiftPressed: true, char: 'N' },
    79: { code: 79, isShiftPressed: true, char: 'O' },
    80: { code: 80, isShiftPressed: true, char: 'P' },
    81: { code: 81, isShiftPressed: true, char: 'Q' },
    82: { code: 82, isShiftPressed: true, char: 'R' },
    83: { code: 83, isShiftPressed: true, char: 'S' },
    84: { code: 84, isShiftPressed: true, char: 'T' },
    85: { code: 85, isShiftPressed: true, char: 'U' },
    86: { code: 86, isShiftPressed: true, char: 'V' },
    87: { code: 87, isShiftPressed: true, char: 'W' },
    88: { code: 88, isShiftPressed: true, char: 'X' },
    89: { code: 89, isShiftPressed: true, char: 'Y' },
    90: { code: 90, isShiftPressed: true, char: 'Z' },
    97: { code: 65, isShiftPressed: false, char: 'a' },
    98: { code: 66, isShiftPressed: false, char: 'b' },
    99: { code: 67, isShiftPressed: false, char: 'c' },
    100: { code: 68, isShiftPressed: false, char: 'd' },
    101: { code: 69, isShiftPressed: false, char: 'e' },
    102: { code: 70, isShiftPressed: false, char: 'f' },
    103: { code: 71, isShiftPressed: false, char: 'g' },
    104: { code: 72, isShiftPressed: false, char: 'h' },
    105: { code: 73, isShiftPressed: false, char: 'i' },
    106: { code: 74, isShiftPressed: false, char: 'j' },
    107: { code: 75, isShiftPressed: false, char: 'k' },
    108: { code: 76, isShiftPressed: false, char: 'l' },
    109: { code: 77, isShiftPressed: false, char: 'm' },
    110: { code: 78, isShiftPressed: false, char: 'n' },
    111: { code: 79, isShiftPressed: false, char: 'o' },
    112: { code: 80, isShiftPressed: false, char: 'p' },
    113: { code: 81, isShiftPressed: false, char: 'q' },
    114: { code: 82, isShiftPressed: false, char: 'r' },
    115: { code: 83, isShiftPressed: false, char: 's' },
    116: { code: 84, isShiftPressed: false, char: 't' },
    117: { code: 85, isShiftPressed: false, char: 'u' },
    118: { code: 86, isShiftPressed: false, char: 'v' },
    119: { code: 87, isShiftPressed: false, char: 'w' },
    120: { code: 88, isShiftPressed: false, char: 'x' },
    121: { code: 89, isShiftPressed: false, char: 'y' },
    122: { code: 90, isShiftPressed: false, char: 'z' },
    32: { code: 32, isShiftPressed: false, char: ' ' },
    33: { code: 49, isShiftPressed: true, char: '!' },
    64: { code: 50, isShiftPressed: true, char: '@' },
    35: { code: 51, isShiftPressed: true, char: '#' },
    36: { code: 52, isShiftPressed: true, char: '$' },
    37: { code: 53, isShiftPressed: true, char: '%' },
    94: { code: 54, isShiftPressed: true, char: '^' },
    38: { code: 55, isShiftPressed: true, char: '&' },
    42: { code: 56, isShiftPressed: true, char: '*' },
    40: { code: 57, isShiftPressed: true, char: '(' },
    41: { code: 48, isShiftPressed: true, char: ')' },
    45: { code: 189, isShiftPressed: false, char: '-' },
    95: { code: 189, isShiftPressed: true, char: '_' },
    61: { code: 187, isShiftPressed: false, char: '=' },
    43: { code: 187, isShiftPressed: true, char: '+' },
    91: { code: 219, isShiftPressed: false, char: '[' },
    123: { code: 219, isShiftPressed: true, char: '{' },
    93: { code: 221, isShiftPressed: false, char: ']' },
    125: { code: 221, isShiftPressed: true, char: '}' },
    92: { code: 220, isShiftPressed: false, char: '\\' },
    124: { code: 220, isShiftPressed: true, char: '|' },
    59: { code: 186, isShiftPressed: false, char: ';' },
    58: { code: 186, isShiftPressed: true, char: ':' },
    39: { code: 222, isShiftPressed: false, char: "'" },
    34: { code: 222, isShiftPressed: true, char: '"' },
    44: { code: 188, isShiftPressed: false, char: ',' },
    60: { code: 188, isShiftPressed: true, char: '<' },
    46: { code: 190, isShiftPressed: false, char: '.' },
    62: { code: 190, isShiftPressed: true, char: '>' },
    47: { code: 191, isShiftPressed: false, char: '/' },
    63: { code: 191, isShiftPressed: true, char: '?' },
  },
  [Platform.Tizen]: {},
};

export const keyMap = {
  48: { shift: { pressed: ')', released: '0' } },
  49: { shift: { pressed: '!', released: '1' } },
  50: { shift: { pressed: '@', released: '2' } },
  51: { shift: { pressed: '#', released: '3' } },
  52: { shift: { pressed: '$', released: '4' } },
  53: { shift: { pressed: '%', released: '5' } },
  54: { shift: { pressed: '^', released: '6' } },
  55: { shift: { pressed: '&', released: '7' } },
  56: { shift: { pressed: '*', released: '8' } },
  57: { shift: { pressed: '(', released: '9' } },
  65: { shift: { pressed: 'A', released: 'a' } },
  66: { shift: { pressed: 'B', released: 'b' } },
  67: { shift: { pressed: 'C', released: 'c' } },
  68: { shift: { pressed: 'D', released: 'd' } },
  69: { shift: { pressed: 'E', released: 'e' } },
  70: { shift: { pressed: 'F', released: 'f' } },
  71: { shift: { pressed: 'G', released: 'g' } },
  72: { shift: { pressed: 'H', released: 'h' } },
  73: { shift: { pressed: 'I', released: 'i' } },
  74: { shift: { pressed: 'J', released: 'j' } },
  75: { shift: { pressed: 'K', released: 'k' } },
  76: { shift: { pressed: 'L', released: 'l' } },
  77: { shift: { pressed: 'M', released: 'm' } },
  78: { shift: { pressed: 'N', released: 'n' } },
  79: { shift: { pressed: 'O', released: 'o' } },
  80: { shift: { pressed: 'P', released: 'p' } },
  81: { shift: { pressed: 'Q', released: 'q' } },
  82: { shift: { pressed: 'R', released: 'r' } },
  83: { shift: { pressed: 'S', released: 's' } },
  84: { shift: { pressed: 'T', released: 't' } },
  85: { shift: { pressed: 'U', released: 'u' } },
  86: { shift: { pressed: 'V', released: 'v' } },
  87: { shift: { pressed: 'W', released: 'w' } },
  88: { shift: { pressed: 'X', released: 'x' } },
  89: { shift: { pressed: 'Y', released: 'y' } },
  90: { shift: { pressed: 'Z', released: 'z' } },
  32: { shift: { pressed: ' ', released: ' ' } },
  189: { shift: { pressed: '_', released: '-' } },
  187: { shift: { pressed: '+', released: '=' } },
  219: { shift: { pressed: '{', released: '[' } },
  221: { shift: { pressed: '}', released: ']' } },
  220: { shift: { pressed: '|', released: '\\' } },
  186: { shift: { pressed: ':', released: ';' } },
  222: { shift: { pressed: '"', released: "'" } },
  188: { shift: { pressed: '<', released: ',' } },
  190: { shift: { pressed: '>', released: '.' } },
  191: { shift: { pressed: '?', released: '/' } },
  192: { shift: { pressed: '~', released: '`' } },
};

export const reversedKeyMap = {
  '0': 48,
  '1': 49,
  '2': 50,
  '3': 51,
  '4': 52,
  '5': 53,
  '6': 54,
  '7': 55,
  '8': 56,
  '9': 57,

  // Uppercase alphabets
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,

  // Lowercase alphabets
  a: 97,
  b: 98,
  c: 99,
  d: 100,
  e: 101,
  f: 102,
  g: 103,
  h: 104,
  i: 105,
  j: 106,
  k: 107,
  l: 108,
  m: 109,
  n: 110,
  o: 111,
  p: 112,
  q: 113,
  r: 114,
  s: 115,
  t: 116,
  u: 117,
  v: 118,
  w: 119,
  x: 120,
  y: 121,
  z: 122,

  // Special characters
  ' ': 32, // Space
  '!': 33, // Shift + 1
  '@': 64, // Shift + 2
  '#': 35, // Shift + 3
  $: 36, // Shift + 4
  '%': 37, // Shift + 5
  '^': 94, // Shift + 6
  '&': 38, // Shift + 7
  '*': 42, // Shift + 8
  '(': 40, // Shift + 9
  ')': 41, // Shift + 0
  '-': 45, // Minus
  _: 95, // Shift + Minus
  '=': 61, // Equals
  '+': 43, // Shift + Equals
  '[': 91, // Open Bracket
  '{': 123, // Shift + Open Bracket
  ']': 93, // Close Bracket
  '}': 125, // Shift + Close Bracket
  '\\': 92, // Backslash
  '|': 124, // Shift + Backslash
  ';': 59, // Semicolon
  ':': 58, // Shift + Semicolon
  "'": 39, // Single Quote
  '"': 34, // Shift + Single Quote
  ',': 44, // Comma
  '<': 60, // Shift + Comma
  '.': 46, // Period
  '>': 62, // Shift + Period
  '/': 47, // Forward Slash
  '?': 63, // Shift + Forward Slash
};
