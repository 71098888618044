import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Routes } from '@utomik-app-monorepo/constants';
import { useMouseScroll, useSetAllowedPlayPress } from '@utomik-app-monorepo/hooks';
import { AnalyticControllerContext, AnalyticItemTypes, AppListEntry, Application, ApplicationStoreContext, AsyncObjectState, Channel, ChannelStoreContext, ClientControllerContext, HomeControllerContext, NavigationControllerContext, SearchResult, SearchResultStoreContext, TileType, VideoPlayerControllerContext } from '@utomik-app-monorepo/store';
import { joinLocation, repeatFnCall, sizeRemToPx } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { forwardRef, useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Loading } from '../loading/loading';
import { NothingFound } from '../nothing-found/nothing-found';
import { TileCarousel } from '../tile-carousel/tile-carousel';
import { TileItem } from '../tile-item/tile-item';
import { TileSelector } from '../tileSelector/tileSelector';
import './focusable-grid-search-list.scss';
type TilesGridListProps = {
  element: React.ReactNode;
  showSelector?: boolean;
  title?: string;
  ref: React.ForwardedRef<HTMLDivElement>;
  selectorType?: TileType;
  isLoading?: boolean;
};
export const TilesGridList: React.FC<TilesGridListProps> = forwardRef(function TilesGridList({
  element,
  showSelector,
  title,
  selectorType,
  isLoading
}: TilesGridListProps, ref: React.ForwardedRef<HTMLDivElement>) {
  return <section className={'items-section__wrapper'}>
      <TileSelector ref={ref} className={'tile-selector'} show={showSelector} type={selectorType} />
      <div className={'items-title'}>{!!title && <h1>{title}</h1>}</div>
      <div className={cx('items-container', {
      'items-container--loading': isLoading
    })}>
        {element}
      </div>
    </section>;
});
type GridListProps = {
  items: AppListEntry[] | SearchResult[];
  title?: string;
  onChildFocus?: (props: any) => void;
  isLoading?: boolean;
};
export const FocusableGridSearchList = withFocusable({
  trackChildren: true
})<GridListProps>(observer(function FocusableGridSearchList({
  items,
  title,
  hasFocusedChild,
  onChildFocus,
  navigateByDirection,
  focused,
  setFocus,
  isLoading
}) {
  const channelController = useContext(ChannelStoreContext);
  const homeController = useContext(HomeControllerContext);
  const searchResultStore = useContext(SearchResultStoreContext);
  const appStore = useContext(ApplicationStoreContext);
  const analyticController = useContext(AnalyticControllerContext);
  const channelStore = useContext(ChannelStoreContext);
  const clientController = useContext(ClientControllerContext);
  const navigationController = useContext(NavigationControllerContext);
  const videoPlayerController = useContext(VideoPlayerControllerContext);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const selectorRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const isCursorVisible = useMouseScroll(scrollableContainerRef, navigateByDirection, true);
  const state = searchResultStore?.searchResultList?.state;
  const notFound = state === AsyncObjectState.Done && !items?.length;
  const innerOffset = sizeRemToPx(1.5);
  useEffect(() => {
    if (notFound) {
      navigationController.reset();
    }
    videoPlayerController.setFrozen(notFound);
    return () => videoPlayerController.setFrozen(false);
  }, [notFound]);
  useEffect(() => {
    return () => {
      navigationController.reset();
    };
  }, []);
  useEffect(() => {
    if (state !== AsyncObjectState.Pending) {
      navigationController.reset();
    }
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.style.transform = `translateY(0)`;
    }
  }, [state]);
  useSetAllowedPlayPress(hasFocusedChild);
  const handleFocus = useCallback((props, itemProps) => {
    if (!selectorRef.current || !scrollableContainerRef.current) return;
    //vertical centering
    navigationController.setCurrentId(itemProps.item.id, itemProps.type);
    navigationController.setCurrentIdx(itemProps.idx);
    scrollableContainerRef.current.style.transform = `translateY(${-props.y + innerOffset}px)`;
    selectorRef.current.style.transform = `translateX(${props.x - innerOffset}px)`;
    onChildFocus && onChildFocus(itemProps);
  }, []);
  const handleEnterPress = props => {
    const breadcrumbs = analyticController.getBreadCrumbs({
      currentSection: !state ? 'Recommended' : undefined,
      isGrid: true
    });
    if (props.item instanceof Application) {
      analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Game,
        location_on_page: joinLocation(breadcrumbs)
      });
      return navigate(`${Routes.AppInfo}/${props.item.id}`);
    }
    if (props.item instanceof Channel) {
      analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Channel,
        location_on_page: joinLocation(breadcrumbs)
      });
      channelController.clearPageScrollState();
      return navigate(`${Routes.Channels}/${props.item.id}`);
    }
  };
  const tiles = items?.map((currApiItem, idx) => {
    if (!currApiItem) return null;
    let currItem: Application | Channel;
    let tileType: TileType;
    if ('type' in currApiItem && currApiItem?.type === 'application') {
      tileType = TileType.Regular;
      currItem = appStore.getItem(currApiItem.item.id, undefined, currApiItem.item.permissions);
    } else if ('type' in currApiItem && currApiItem?.type === 'channel') {
      tileType = TileType.ChannelSearch;
      currItem = channelStore.getItem(currApiItem.item.slug);
    } else {
      currItem = appStore.getItem((currApiItem as AppListEntry).id);
    }
    return <TileItem focusKey={`TILE_ITEM_${currItem.id}`} isSmall key={currItem.id} item={currItem} onBecameFocused={handleFocus} onEnterPress={!(currItem as Application)?.isAgeRestricted ? handleEnterPress : undefined} showPlatformIcons={clientController.user.isAdmin} idx={idx} type={tileType} hasMouseOver={isCursorVisible} />;
  });
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (focused) {
      intervalId = repeatFnCall(setFocus);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [focused, tiles, isLoading]);
  let element;
  if (state === AsyncObjectState.Pending) {
    element = <div className={'loading-container'}>
          <Loading loadingText={t({
        message: 'Searching...'
      })} />
        </div>;
  } else if (notFound) {
    element = <div className={'nothing-found__container'}>
          <NothingFound title={t({
        message: 'Sorry, we could not find anything'
      })} subtitle={t({
        message: 'Try change request'
      })} />
          <div className={'carousel__wrapper'}>
            <TileSelector show={hasFocusedChild} className={'recommended-tile-selector'} type={TileType.Regular} />
            <TileCarousel list={homeController.providers.recommendedApplications?.appList} name={t({
          context: 'Title of a game set with games that are recommended for user',
          message: 'Recommended for you'
        })} showSeeAllTile={false} onEnterPress={handleEnterPress} onChildFocused={(props, itemProps) => {
          navigationController.setCurrentId(itemProps.item.id);
        }} />
          </div>
        </div>;
  } else if (state === AsyncObjectState.Error) {
    element = <div className={'loading-container'}>
          {t({
        message: 'Some data could not be loaded. Please check your internet connection and try again'
      })}
        </div>;
  } else {
    element = <div className={'tiles-grid'} ref={scrollableContainerRef}>
          {tiles}
        </div>;
  }
  return <TilesGridList element={element} ref={selectorRef} showSelector={hasFocusedChild && !notFound} title={title} isLoading={isLoading} />;
}));