import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment-duration-format';
import React, { useEffect, useRef, useState } from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import { ProgressCircle } from '../../progress-circle/progress-circle';
import './start-stream-queue-dialog.scss';
export const StartStreamQueueDialog = observer(function StartStreamQueueDialog({
  action
}) {
  const initTime = 60 * 5 * 1000; // 5min
  const [time, setTime] = useState<number>(initTime);
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime(prev => prev - 1000);
    }, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  useEffect(() => {
    if (time <= 0) {
      clearInterval(intervalRef.current);
      action(DialogResult.Action1);
    }
  }, [time]);
  const progress = 1 - time / initTime;
  const timeFormatted = (moment.duration(time, 'milliseconds') as any).format('mm:ss');
  return <div className={'start-stream-queue__container'}>
      <h1 className={'title'}>
        <>{t({
          context: 'Prompts a user that they can start game stream',
          message: 'You can start the stream'
        })}</>
      </h1>
      <p className={'subtitle'}>
        {t({
        context: 'Estimated time of a queue before stream start',
        message: 'Estimated waiting time'
      })}
      </p>
      <ProgressCircle progress={progress}>
        <span className={'time-progress'}>{timeFormatted}</span>
      </ProgressCircle>
      <FocusableButton autofocus type={'primary'} onEnterPress={() => action(DialogResult.OK)}>
        {t({
        context: 'Button title - Starts the game stream',
        message: 'Start'
      })}
      </FocusableButton>
    </div>;
});
export default StartStreamQueueDialog;