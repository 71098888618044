import { IGetLoggedInUserImages, IUploadData, IUploadResponse } from '../../../app/global/uploadService/types';
import UploadService from '../../../app/global/uploadService/uploadService';
import { RequestQueue } from '../../requestQueue/requestQueue';

export class UserAvatarStore {
  private readonly _uploadService: UploadService;
  private readonly _requestQueue: RequestQueue;
  constructor(requestQueue: RequestQueue, uploadService: UploadService) {
    this._uploadService = uploadService;
    this._requestQueue = requestQueue;
  }

  public isAcceptableImageType(file: File): boolean {
    const imageRegex = new RegExp(/image\/(bmp|gif|jpeg|jpg|png)/i);
    const isValidFormat = file.type.match(imageRegex);
    return !!isValidFormat;
  }

  public async uploadImage(uploadData: IUploadData): Promise<IUploadResponse | IGetLoggedInUserImages> {
    const newImage = await this._uploadService.uploadFile('/v1/users/me/images', uploadData);
    if (!newImage) {
      return null;
    }
    let newUserImage: IUploadResponse | IGetLoggedInUserImages = newImage;
    const deletePromises: Promise<void>[] = [];

    const userImages = await this._requestQueue.add<IGetLoggedInUserImages[]>('/v1/users/me/images', {
      method: 'GET',
    }).promise;

    for (const image of userImages.data) {
      if (image.tag === 'AV' && image.id !== newUserImage.id) {
        deletePromises.push(this._uploadService.deleteMedia(`/v1/users/me/images/${image.id}`));
      } else if (image.id === newUserImage.id) {
        newUserImage = image;
      }
    }

    if (deletePromises.length) {
      try {
        await Promise.all(deletePromises);
      } catch (e) {
        console.error(e, 'delete old avatars');
      }
    }

    return newUserImage;
  }
}
