import { log } from '@utomik-app-monorepo/logger';
import { getDeviceTag, isNullOrUndefined } from '@utomik-app-monorepo/utils';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';

import { PageScrollState } from '../../../app/global/pageScrollState/pageScrollState';
import { RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncList } from '../objectStore/asyncList';
import { ApiSearchResult, SearchResult } from './searchResult';

export interface SearchResultGroup {
  type: string;
  searchResults: SearchResult[];
}

export class SearchResultList extends AsyncList<ApiSearchResult> {
  public constructor(requestQueue: RequestQueue, searchTerm: string, showAll: boolean) {
    super(
      requestQueue,
      1,
      `v2/search?q=${encodeURIComponent(searchTerm)}${!showAll ? `&cloud_platform=${getDeviceTag()}` : ''}`
    );
    makeObservable(this);
    log(`constructor - searchTerm: ${searchTerm}`);
  }

  @observable
  private _searchResults: SearchResult[] = [];

  @action
  public setSearchResults(searchResults: SearchResult[]): void {
    this._searchResults = searchResults;
  }

  @computed
  public get searchResults(): SearchResultGroup[] {
    const groups = groupBy(this._searchResults, 'type');
    return Object.keys(groups).map((key: string): SearchResultGroup => {
      return {
        type: key,
        searchResults: groups[key],
      };
    });
  }

  @computed
  public get searchResultsUngrouped(): SearchResult[] {
    return this.searchResults.reduce<SearchResult[]>(
      (searchResults: SearchResult[], searchResultGroup: SearchResultGroup): SearchResult[] => {
        return searchResults.concat(searchResultGroup.searchResults);
      },
      []
    );
  }

  public async getSearchResult(index: number, count: number): Promise<void> {
    try {
      const searchResults = await super.getItems(index, count, RequestPriority.High);
      this.setSearchResults(
        searchResults.items.map((apiSearchResult): SearchResult => {
          return SearchResult.parse(apiSearchResult);
        })
      );
    } catch (error: any) {
      const message = error?.message ? error.message : error;
      if (message === 'aborted') {
        log('getSearchResult: aborted');
      } else {
        console.error(`getSearchResult: ${message}`);
      }
    }
  }
}

export class SearchResultStore {
  private readonly _requestQueue: RequestQueue;
  private _scrollState: PageScrollState;

  private _debounced: ReturnType<typeof debounce>;

  @observable
  private _searchTerm = '';
  @observable
  private _searchResult: SearchResultList = null;

  // Clear the search field (i.e. when logging out).
  @action
  public clear(): void {
    this.setSearchTerm(() => '');
  }

  @action
  public setSearchTerm = (cb: React.SetStateAction<string>, showAll?: boolean): void => {
    if (typeof cb !== 'function') return;

    // Cancel debounce
    this._debounced.cancel();
    // Set term
    this._searchTerm = cb(this._searchTerm);
    // Abort previous fetch
    if (!isNullOrUndefined(this._searchResult)) {
      this._searchResult.abortFetch();
    }
    if (this._searchTerm.length > 0) {
      // Set new search and fetch
      this._searchResult = new SearchResultList(this._requestQueue, this._searchTerm, showAll);
      this._debounced();
    } else {
      // Clear if searchTerm is empty
      this._searchResult = null;
    }
  };

  @computed
  public get searchTerm(): string {
    return this._searchTerm;
  }

  @computed
  public get searchResultList(): SearchResultList {
    return this._searchResult;
  }

  public get scrollState() {
    return this._scrollState;
  }

  public constructor(requestQueue: RequestQueue) {
    makeObservable(this);
    this._requestQueue = requestQueue;
    this._scrollState = new PageScrollState();
    this._debounced = debounce((): void => {
      if (!isNullOrUndefined(this._searchResult)) {
        this._searchResult.getSearchResult(0, 50);
      }
    }, 250);
  }
}
