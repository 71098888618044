import { NavigationControllerContext, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
export const LongPressButtonProcedure = observer(function CentralButtonProcedure() {
  const navigationController = useContext(NavigationControllerContext);
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  useEffect(() => {
    if (streamViewUtilsService.streamViewRef?.disposeStream) {
      navigationController.longPressButtonService.init(streamViewUtilsService.streamViewRef?.disposeStream);
    }
  }, [streamViewUtilsService.streamViewRef?.disposeStream]);
  useEffect(() => {
    return () => {
      navigationController.longPressButtonService.dispose();
    };
  }, []);
  return <div className={cx('start-button-procedure__wrapper', {
    'start-button-procedure__wrapper--show': navigationController.longPressButtonService.shouldShowNotification
  })}>
      <p className={'start-button-procedure__text'}>
        {navigationController.longPressButtonService.notificationData.message}
      </p>
      <div className={cx('progress-line', {
      'progress-line--show': navigationController.longPressButtonService.shouldShowNotification
    })} />
    </div>;
});