import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { observer } from 'mobx-react';
import React, { CSSProperties, useEffect } from 'react';
import './connection-modal.scss';
const ConnectionLostImage_1 = _compiledBlock(_props => <svg style={_props.v0} viewBox="0 0 2560 1017" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1719.43 576.729C1708.54 576.729 1696.83 573.181 1684.57 566.084L1548.68 487.474C1527.72 475.191 1510.56 445.713 1510.56 421.42V307.054C1510.56 299.138 1506.47 292.041 1499.67 288.22C1492.86 284.399 1484.69 284.399 1477.88 288.22L1430.22 315.788C1427.5 317.426 1424.23 316.334 1422.87 313.878C1421.24 311.148 1422.33 307.873 1424.78 306.508L1472.43 278.94C1482.51 272.935 1494.76 272.935 1505.11 278.94C1515.19 284.945 1521.45 295.317 1521.45 307.327V421.693C1521.45 442.165 1536.43 468.095 1554.13 478.467L1690.02 557.077C1707.45 567.176 1723.52 568.814 1735.5 561.99C1747.48 555.166 1754.02 540.154 1754.02 520.228V374.2C1754.02 349.361 1769.81 320.974 1789.96 309.238L2569.18 1.02021C2571.91 -0.6175 2575.17 0.474295 2576.53 2.93086C2578.17 5.66037 2577.08 8.93577 2574.63 10.3005L1795.41 318.518C1778.8 328.071 1764.91 353.456 1764.91 373.927V519.955C1764.91 544.248 1756.47 562.263 1740.95 571.27C1734.41 574.819 1727.33 576.729 1719.43 576.729Z" fill="#37474F" />
    <path d="M1484.42 323.704V332.711C1484.42 346.905 1474.34 364.374 1462.09 371.47L1379.57 419.237L1295.7 370.651V297.773L1377.12 260.106C1390.19 254.101 1410.62 254.92 1422.87 262.29L1462.09 284.945C1474.34 292.041 1484.42 309.237 1484.42 323.704Z" fill="#37474F" />
    <path d="M1377.12 260.106C1390.19 254.101 1410.62 254.92 1422.87 262.29L1462.09 284.945C1474.34 292.041 1474.07 302.687 1461 308.692L1379.57 346.359L1295.7 297.773L1377.12 260.106Z" fill="#455A64" />
    <path opacity="0.15" d="M1387.47 328.89C1393.73 332.438 1398.63 341.173 1398.63 348.269V408.046L1379.57 418.964L1295.7 370.379V297.501L1316.94 287.674L1387.47 328.89Z" fill="black" />
    <path d="M1288.62 285.218C1290.52 284.126 1293.52 284.126 1296.51 286.037L1379.03 333.803C1385.29 337.351 1390.19 346.086 1390.19 353.183V424.423C1390.19 427.971 1388.83 430.428 1386.92 431.52C1385.02 432.611 1378.21 436.433 1376.3 437.525C1374.4 438.616 1371.4 438.616 1368.41 436.706L1285.89 388.939C1279.63 385.391 1274.73 376.656 1274.73 369.56V298.319C1274.73 294.771 1276.09 292.314 1277.99 290.95C1279.9 290.131 1286.71 286.31 1288.62 285.218Z" fill="#37474F" />
    <path d="M1368.41 339.808L1285.89 292.041C1279.63 288.493 1274.73 291.496 1274.73 298.592V369.833C1274.73 376.929 1279.63 385.664 1285.89 389.212L1368.41 436.978C1374.67 440.527 1379.57 437.524 1379.57 430.428V359.187C1379.57 352.091 1374.67 343.356 1368.41 339.808Z" fill="#455A64" />
    <path d="M1270.64 422.785V435.068C1270.64 439.981 1272.28 443.529 1275.27 445.167C1276.64 445.986 1281.54 448.716 1282.63 449.534C1285.35 451.172 1289.43 450.899 1293.79 448.443L1361.87 408.865V360.279L1354.52 355.912L1286.44 395.49C1277.72 400.403 1270.64 412.686 1270.64 422.785ZM1301.69 407.227C1301.96 408.046 1301.96 409.138 1301.96 410.229C1301.96 417.053 1297.33 424.969 1291.61 428.517C1290.8 429.063 1289.71 429.336 1288.89 429.609C1288.62 428.79 1288.62 427.698 1288.62 426.606C1288.62 419.783 1293.25 411.867 1298.97 408.319C1299.78 408.046 1300.87 407.5 1301.69 407.227Z" fill="#23B24B" />
    <path opacity="0.2" d="M1282.35 448.989C1282.63 448.989 1282.63 449.261 1282.9 449.261C1282.63 449.261 1282.63 449.262 1282.35 448.989C1280.99 448.17 1276.36 445.713 1275.27 444.894C1272.28 443.257 1270.64 439.708 1270.64 434.795V422.512C1270.64 417.599 1272.28 411.867 1275.27 406.954L1282.63 411.321C1279.9 416.234 1278 421.693 1278 426.879V439.162C1278 442.984 1279.09 445.986 1280.99 447.897C1281.54 448.443 1281.81 448.716 1282.35 448.989Z" fill="black" />
    <path opacity="0.5" d="M1361.87 360.279L1293.79 399.857C1289.43 402.314 1285.62 406.681 1282.63 411.594L1275.27 407.227C1278 402.314 1282.08 397.947 1286.44 395.49L1354.79 355.912L1361.87 360.279Z" fill="#F1F1F1" />
    <path opacity="0.2" d="M1301.96 410.229C1301.96 409.138 1301.69 408.046 1301.69 407.227C1306.05 405.862 1309.59 408.865 1309.59 414.324C1309.59 421.147 1304.96 429.063 1299.24 432.611C1294.34 435.341 1290.25 433.976 1289.16 429.336C1289.98 429.063 1290.8 428.79 1291.89 428.244C1297.33 424.969 1301.96 417.053 1301.96 410.229Z" fill="black" />
    <path d="M1207.74 386.21V398.493C1207.74 403.406 1209.37 406.954 1212.37 408.592C1213.73 409.411 1218.63 412.14 1219.72 412.959C1222.44 414.597 1226.53 414.324 1230.88 411.867L1298.97 372.289V323.704L1291.61 319.337L1223.53 358.915C1214.82 364.101 1207.74 376.384 1207.74 386.21ZM1238.78 370.924C1239.05 371.743 1239.05 372.835 1239.05 373.927C1239.05 380.751 1234.42 388.666 1228.71 392.215C1227.89 392.761 1226.8 393.034 1225.98 393.306C1225.71 392.488 1225.71 391.396 1225.71 390.304C1225.71 383.48 1230.34 375.565 1236.06 372.016C1236.88 371.47 1237.96 371.197 1238.78 370.924Z" fill="#23B24B" />
    <path opacity="0.2" d="M1220.26 413.232C1219.99 413.232 1219.99 413.232 1220.26 413.232C1219.99 413.232 1219.99 412.959 1219.99 412.959C1218.63 412.14 1213.72 409.411 1212.64 408.592C1209.91 406.954 1208.01 403.406 1208.01 398.493V386.21C1208.01 383.753 1208.55 381.024 1209.1 378.567C1209.91 375.838 1211 373.381 1212.36 370.925L1219.72 375.292C1218.9 376.384 1218.35 377.748 1217.81 379.113C1216.18 382.934 1215.09 387.029 1215.09 390.85V403.133C1215.09 406.954 1216.18 409.957 1218.08 411.867C1218.63 412.14 1219.44 412.686 1220.26 413.232Z" fill="black" />
    <path opacity="0.5" d="M1298.96 323.977L1230.88 363.555C1226.53 366.011 1222.71 370.379 1219.72 375.292L1212.37 370.924C1215.09 366.011 1219.17 361.644 1223.53 359.188L1291.61 319.61L1298.96 323.977Z" fill="#F1F1F1" />
    <path opacity="0.2" d="M1239.06 373.927C1239.06 372.835 1238.78 371.743 1238.78 370.924C1243.14 369.56 1246.68 372.562 1246.68 378.021C1246.68 384.845 1242.05 392.761 1236.33 396.309C1231.43 399.038 1227.35 397.674 1226.26 393.034C1227.07 392.761 1227.89 392.488 1228.98 391.942C1234.43 388.666 1239.06 380.478 1239.06 373.927Z" fill="black" />
    <path d="M1228.43 336.533L1217.54 293.679" stroke="#E0E0E0" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1197.93 354.547L1157.36 322.339" stroke="#E0E0E0" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1189.49 390.031L1141.83 393.579" stroke="#E0E0E0" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1043.52 850.5L1253.76 972.236V1017L1043.52 895.264V850.5Z" fill="#23B24B" />
    <path opacity="0.35" d="M1043.52 850.5L1253.76 972.236V1017L1043.52 895.264V850.5Z" fill="black" />
    <path d="M1463.99 850.5L1253.76 972.236V1017L1463.99 895.264V850.5Z" fill="#23B24B" />
    <path opacity="0.2" d="M1463.99 850.5L1253.76 972.236V1017L1463.99 895.264V850.5Z" fill="black" />
    <path d="M1463.99 850.5L1253.76 729.036L1043.52 850.5L1253.76 972.236L1463.99 850.5Z" fill="#23B24B" />
    <path opacity="0.15" d="M1463.99 850.5L1253.76 729.036L1043.52 850.5L1253.76 972.236L1463.99 850.5Z" fill="black" />
    <path opacity="0.1" d="M1253.76 972.236V959.407L1065.85 850.5H1043.52L1253.76 972.236Z" fill="black" />
    <path opacity="0.1" d="M1253.76 729.036V741.865L1441.93 850.5H1463.99L1253.76 729.036Z" fill="black" />
    <path d="M1463.99 850.5H1441.93L1253.76 959.407V972.236L1463.99 850.5Z" fill="#23B24B" />
    <path d="M1253.76 729.036V741.865L1065.85 850.5H1043.52L1253.76 729.036Z" fill="#23B24B" />
    <path d="M1254.57 797.82L1344.99 849.954C1351.79 853.775 1351.79 860.326 1344.99 864.147L1277.72 903.179C1270.91 907.001 1260.02 907.001 1253.21 903.179L1162.8 851.046C1155.99 847.224 1155.99 840.674 1162.8 836.852L1230.07 797.82C1236.87 793.999 1247.77 793.999 1254.57 797.82Z" fill="#37474F" />
    <path d="M1277.45 903.18L1342.54 865.512L1254.58 814.47C1247.77 810.649 1236.87 810.649 1230.07 814.47L1164.98 852.138L1252.94 903.18C1259.75 907.274 1270.64 907.274 1277.45 903.18Z" fill="#455A64" />
    <path d="M1200.11 850.773L1239.59 827.845C1240.96 827.026 1243.13 827.026 1244.22 827.845L1249.13 830.574C1250.49 831.393 1250.49 832.485 1249.13 833.304L1209.64 856.232C1208.28 857.051 1206.1 857.051 1205.01 856.232L1200.11 853.502C1198.75 852.683 1198.75 851.319 1200.11 850.773Z" fill="#263238" />
    <path d="M1258.93 884.892L1298.42 861.964C1299.78 861.145 1301.96 861.145 1303.05 861.964L1307.95 864.693C1309.31 865.512 1309.31 866.604 1307.95 867.423L1268.46 890.351C1267.1 891.169 1264.92 891.169 1263.83 890.351L1258.93 887.621C1257.84 886.802 1257.84 885.711 1258.93 884.892Z" fill="#263238" />
    <path d="M1162.53 939.209C1162.53 931.566 1157.08 922.286 1150.55 918.465C1147.28 916.554 1144.28 916.281 1142.11 917.646C1141.56 917.919 1137.48 920.375 1136.93 920.648C1134.75 922.013 1133.39 924.47 1133.39 928.291C1133.39 935.934 1138.84 945.214 1145.37 949.035C1148.64 950.946 1151.64 951.219 1153.82 949.854C1154.36 949.581 1158.44 947.125 1158.99 946.852C1161.17 945.487 1162.53 942.757 1162.53 939.209Z" fill="#23B24B" />
    <path opacity="0.1" d="M1145.37 921.467C1151.91 925.288 1157.36 934.569 1157.36 942.211C1157.36 949.854 1151.91 952.857 1145.37 949.035C1138.84 945.214 1133.39 935.934 1133.39 928.291C1133.39 920.648 1138.84 917.646 1145.37 921.467Z" fill="black" />
    <path d="M1007.3 1008.81C993.687 1008.81 979.798 1005.81 969.177 999.531L829.747 918.738C818.582 912.187 812.318 903.179 812.318 893.626C812.318 883.8 818.582 874.792 829.747 868.515L991.236 774.892C1008.94 764.52 1023.91 738.59 1023.91 718.118V205.789C1023.91 202.786 1026.37 200.33 1029.36 200.33C1032.36 200.33 1034.81 202.786 1034.81 205.789V718.118C1034.81 742.957 1018.2 771.89 996.682 784.173L835.194 877.795C827.569 882.162 823.211 887.894 823.211 893.626C823.211 899.358 827.569 904.817 835.194 909.457L974.624 990.251C992.597 1000.62 1022.01 1000.62 1039.98 990.251L1142.92 930.747C1145.64 929.11 1148.91 930.201 1150.27 932.658C1151.91 935.388 1150.82 938.663 1148.37 940.028L1045.43 999.531C1034.54 1005.54 1020.92 1008.81 1007.3 1008.81Z" fill="#37474F" />
    <path d="M-9.51172 200.274L1033.21 200.274C1034.04 200.274 1034.71 200.945 1034.71 201.774V208.898C1034.71 209.727 1034.04 210.398 1033.21 210.398H-9.51172V200.274Z" fill="#37474F" />
  </svg>, {
  name: "ConnectionLostImage_1",
  svg: true
});
const ConnectionLostImage = ({
  style
}: {
  style: CSSProperties;
}) => /*@million jsx-skip*/<ConnectionLostImage_1 v0={style} />;
export const ConnectionModal = withFocusable()(observer(function ConnectionModal({
  pauseSpatialNavigation,
  resumeSpatialNavigation
}) {
  useEffect(() => {
    pauseSpatialNavigation();
    return () => {
      resumeSpatialNavigation();
    };
  }, []);
  return <div className={'connection-model__wrapper'}>
        <ConnectionLostImage style={{
      width: '100%',
      height: 'auto',
      maxHeight: '75vh'
    }} />
        <div className={'no-internet-title__container'}>
          <span>{t({
          message: 'Please check your internet connection'
        })}</span>
        </div>
      </div>;
}));
export default ConnectionModal;