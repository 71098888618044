import * as React from 'react';
import { DialogQueueContext, DialogResult } from '@utomik-app-monorepo/store';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import './dialog-host.scss';
import DialogRenderer from './dialog-renderer';

/**
 * The dialog host spawns the dialog element in the GUI when a top dialog is defined.
 */
export const DialogHost = observer(function DialogHost(): React.ReactElement {
  const dialogQueue = React.useContext(DialogQueueContext);
  const handleClose = e => {
    e.preventDefault();
    e.stopPropagation();
    if (dialogQueue.currentDialog?.isClosable) {
      dialogQueue.currentDialog.action(DialogResult.Close);
    }
  };
  return !isNullOrUndefined(dialogQueue.currentDialog) && <div className={'dialog-overlay'} onClick={handleClose}>
        {dialogQueue.currentDialog && <DialogRenderer dialog={dialogQueue.currentDialog} />}
      </div>;
});