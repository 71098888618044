import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { RefAttributes } from 'react';
import './simple-button.scss';
type Props = {
  kind?: 'forward' | 'back';
} & React.HTMLProps<HTMLButtonElement> & RefAttributes<HTMLButtonElement>;
export const SimpleButton: React.ForwardRefExoticComponent<Props> = observer(React.forwardRef(({
  children,
  kind,
  onClick,
  className,
  onMouseDown
}, ref) => {
  let element: React.ReactNode;
  if (kind === 'back') {
    element = <FontAwesomeIcon icon={faArrowLeft as IconProp} />;
  }
  if (kind === 'forward') {
    element = <FontAwesomeIcon icon={faArrowRight as IconProp} />;
  }
  return <button className={cx('simple-button', {
    [className]: !!className
  })} onClick={onClick} onMouseDown={onMouseDown} ref={ref}>
        {element || children}
      </button>;
}));