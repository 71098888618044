import { t } from '@lingui/macro';
import { Application } from '../../../dataStore/stores/applicationStore/application';
import { Countries } from '../../../dataStore/stores/countries/countries';
import { User } from '../../../dataStore/stores/userStore/user';
import { AnalyticController } from '../analyticController/analyticController';
import { Dialog, DialogOnResult, DialogQueue, DialogResult } from '../dialogQueue/dialogQueue';

/**
 * IMPORTANT! The dialog analyticName should have the same name as a React dialog components
 * */

export class DialogFactory {
  private queue: DialogQueue;
  private _analyticController: AnalyticController;
  public constructor(queue: DialogQueue, analyticController: AnalyticController) {
    this.queue = queue;
    this._analyticController = analyticController;
  }

  /**
   * The close stream page dialog.
   */

  public async showCloseStreamDialog(appId) {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'CloseStreamDialog',
        isClosable: true,
        enableAnalytic: true,
        analyticValue: appId,
        onTop: true,
        onResult: result => {
          resolve(result);
        },
        props: {
          appId
        }
      });
    });
  }

  /**
   * The close stream page dialog.
   */

  public showCheckConnectionDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        isClosable: true,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'CheckConnectionDialog',
        enableAnalytic: true,
        showHeader: false,
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The after game dialog
   * */

  public showAfterGameDialog(app: Application) {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'FocusableAfterGameModal',
        enableAnalytic: true,
        onTop: true,
        analyticValue: Number(app.id),
        title: app.name,
        showHeader: true,
        onResult: result => {
          resolve(result);
        },
        props: {
          app
        }
      });
    });
  }

  /**
   * The gateways selection dialog
   * */

  public showGatewaysSelectionDialog(app: Application) {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'GatewaysSelectDialog',
        enableAnalytic: true,
        analyticValue: Number(app.id),
        onTop: true,
        onResult: result => {
          resolve(result);
        },
        props: {
          item: app
        }
      });
    });
  }

  /**
   * The exit app dialog
   * */

  public showExitAppDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'ExitAppDialog',
        enableAnalytic: true,
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The secret dialog
   * */

  public showSecretDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        showHeader: true,
        onTop: true,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'SecretDialog',
        enableAnalytic: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The stream message dialog
   * */

  public showStreamMessageDialog(analyticValue: number, text: string) {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        isClosable: false,
        onTop: true,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'StreamMessageDialog',
        enableAnalytic: true,
        analyticValue,
        props: {
          text
        },
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The connection dialog
   * */

  public showConnectionDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        onTop: true,
        isClosable: false,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'ConnectionModal',
        enableAnalytic: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The expire pairing code dialog.
   */

  public showPairingCodeExpiredDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'PairingCodeExpiringDialog',
        enableAnalytic: true,
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The stream queue dialog.
   */

  public showStreamQueueDialog(analyticValue: number) {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        showHeader: false,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'StreamQueueDialog',
        enableAnalytic: true,
        onTop: true,
        analyticValue,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The leave queue dialog.
   */

  public showLeaveQueueDialog(analyticValue: number) {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        showHeader: false,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        analyticName: 'LeaveQueueDialog',
        enableAnalytic: true,
        onTop: true,
        analyticValue,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The start stream from the queue dialog.
   */

  public showStartStreamQueueDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        showHeader: true,
        isClosable: false,
        analyticName: 'StartStreamQueueDialog',
        enableAnalytic: true,
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The inactive dialog.
   */

  public showInactivityDialog(time: number, analyticValue: number) {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        isClosable: false,
        analyticName: 'InactivityDialog',
        enableAnalytic: true,
        onTop: true,
        analyticValue,
        onResult: result => {
          resolve(result);
        },
        props: {
          time
        }
      });
    });
  }

  /**
   * The complete profile dialog.
   */
  public showCompleteProfileDialog(user: User, countries: Countries): Promise<DialogResult> {
    const promise = new Promise<DialogResult>(resolve => {
      const onResult: DialogOnResult = (result: DialogResult): void => {
        resolve(result);
      };
      this.queue.add({
        outsideClickClose: false,
        onTop: true,
        onResult,
        enableAnalytic: true,
        analyticName: 'CompleteProfileDialog',
        withGamepadControls: false,
        showHeader: true,
        showClose: true,
        title: t({
          message: 'Welcome to Utomik'
        }),
        props: {
          user,
          countries
        }
      });
    });
    return promise;
  }

  /**
   * The get subscription dialog.
   */
  public showGetSubscriptionDialog(): Promise<DialogResult> {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'GetSubscriptionDialog',
        enableAnalytic: true,
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The game not available dialog.
   */
  public showGameNotAvailableDialog(analyticValue: number): Promise<DialogResult> {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'GameNotAvailableDialog',
        enableAnalytic: true,
        analyticValue: analyticValue,
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The get ninja dialog.
   */
  public showNinjaDialog(): Promise<DialogResult> {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        showHeader: true,
        analyticName: 'NinjaRedirectDialog',
        enableAnalytic: true,
        title: t({
          message: 'NINJA'
        }),
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The require gamepad dialog.
   */
  public showRequireGamepadDialog(analyticValue: number): Promise<DialogResult> {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'RequireGamepadDialog',
        enableAnalytic: true,
        showHeader: true,
        onTop: true,
        analyticValue,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The logging out dialog.
   */

  public showLoggingOutDialog(): void {
    const onResult = (): void => {
      /*there is no onResult*/
    };
    this.queue.add({
      onResult,
      analyticName: 'LoggingOutDialog',
      enableAnalytic: true,
      onTop: true
    });
  }

  /**
   * The Too high ping dialog.
   */

  public showTooHighPingDialog(analyticValue: number): Promise<DialogResult> {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        isClosable: false,
        analyticName: 'StreamPingTooHighDialog',
        enableAnalytic: true,
        onTop: true,
        analyticValue,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }

  /**
   * The logout submit dialog.
   */

  public showSubmitLogoutDialog(): Promise<DialogResult> {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        isClosable: true,
        analyticName: 'SubmitLogoutDialog',
        enableAnalytic: true,
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showSendBugreportDialog(): Promise<DialogResult> {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        isClosable: false,
        analyticName: 'SendBugReportDialog',
        enableAnalytic: true,
        showHeader: true,
        onTop: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showAchievementsDialog(achievementsStore, index = 0, scrollType: 'native' | 'transform' = 'transform'): Dialog {
    return this.queue.add({
      isClosable: true,
      analyticName: 'AchievementsDialog',
      enableAnalytic: true,
      onResult: null,
      title: t({
        context: 'A dialog name',
        message: 'Achievements'
      }),
      showHeader: true,
      onTop: true,
      props: {
        achievementsStore,
        index,
        scrollType
      }
    });
  }
  public showMediaPlayerDialog(): Dialog {
    return this.queue.add({
      isClosable: true,
      analyticName: 'MediaViewerDialog',
      enableAnalytic: true,
      onResult: null,
      onTop: true,
      props: {
        preferredChildFocusKey: 'arrow-right-button'
      }
    });
  }
  public showStreamingFeedbackDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'StreamingFeedbackDialog',
        enableAnalytic: true,
        onTop: true,
        showHeader: true,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showAllowedKeyboardDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'AllowedKeyboardDialog',
        enableAnalytic: true,
        onTop: true,
        showHeader: false,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showNewUpdateAvailableDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'NewUpdateAvailableDialog',
        enableAnalytic: true,
        onTop: false,
        showHeader: true,
        title: 'New version available',
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showStreamOverlayDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'StreamOverlayDialog',
        enableAnalytic: true,
        onTop: true,
        showHeader: false,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showFailedRequestsDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'FailedRequestsDialog',
        enableAnalytic: true,
        onTop: true,
        showHeader: false,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showStreamFullscreenDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'StreamFullscreenDialog',
        enableAnalytic: true,
        isClosable: false,
        onTop: true,
        showHeader: false,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showStartGameDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'StartGameDialog',
        enableAnalytic: true,
        isClosable: false,
        onTop: true,
        showHeader: false,
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showEditProfileDialog() {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'EditProfileDialog',
        enableAnalytic: true,
        isClosable: true,
        showHeader: true,
        title: 'Edit profile',
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
  public showFinishExternalActionsDialog(nextUrl: string) {
    return new Promise<DialogResult>(resolve => {
      this.queue.add({
        analyticName: 'FinishExternalActionsDialog',
        enableAnalytic: true,
        isClosable: true,
        showHeader: true,
        title: 'External action',
        props: {
          url: nextUrl
        },
        onResult: result => {
          resolve(result);
        }
      });
    });
  }
}