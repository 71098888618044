import { Trans, t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton, Progress, StepProgress } from '@utomik-app-monorepo/components';
import { Routes } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, AnalyticItemTypes, AsyncObjectState, DialogQueueContext, NavigationControllerContext, NinjaControllerContext } from '@utomik-app-monorepo/store';
import { joinLocation } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { TileCarousel } from '../../../components/tile-carousel/tile-carousel';
import styles from './ninja.module.scss';
export const Ninja = withFocusable()(observer(({
  setFocus
}) => {
  const navigate = useNavigate();
  const ninjaController = useContext(NinjaControllerContext);
  const analyticController = useContext(AnalyticControllerContext);
  const navigationController = useContext(NavigationControllerContext);
  const dialogQueue = useContext(DialogQueueContext);
  useEffect(() => {
    ninjaController?.loadIfNeeded();
  }, [ninjaController]);
  useEffect(() => {
    if (ninjaController.state === AsyncObjectState.Done && !dialogQueue.hasDialogs) {
      setFocus();
    }
  }, [ninjaController.state, dialogQueue.hasDialogs]);
  const playedGames = ninjaController?.summary.ninjaAppsPlayed;
  const requiredGames = ninjaController?.summary.ninjaAppsRequired;
  const summary = ninjaController?.summary;
  const level = summary?.highestUnlockedNinjaLevel?.name.split(' ')[1] || 0;
  const maxValueProgress = summary?.nextNinjaLevel?.max;
  const minValueProgress = summary?.nextNinjaLevel?.min;
  const isEmpty = ninjaController.allNotEmptyProviders.length === 0;
  const carouselNames = [t({
    context: 'Title of a carousel with game tiles',
    message: 'Focus games'
  }), t({
    context: 'Title of a carousel with game tiles',
    message: 'Ninja exclusives'
  })];
  const userXP = ninjaController?.summary?.experience.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const handleEnterPress = props => {
    const breadcrumbs = analyticController.getBreadCrumbs({
      currentSection: props.carouselName,
      isCarousel: true
    });
    analyticController.navigate({
      item_name: props.item.slug,
      location_index: String(props.idx),
      item_type: AnalyticItemTypes.Game,
      location_on_page: joinLocation(breadcrumbs)
    });
    navigate(`${Routes.AppInfo}/${props.item.id}`);
  };
  let component: React.ReactNode;
  if (isEmpty) {
    component = <div className={styles.emptyStateContainer}>
          <div>
            <img decoding={'async'} className={styles.sadEmptyImg} src={require('images/svg/sad_empty.svg')} />
            <strong className={styles.emptyStateTitle}>{t({
            message: 'No games available'
          })}</strong>
            <FocusableButton autofocus onEnterPress={navigate.bind(null, Routes.Home)} type={'secondary'}>
              {t({
            context: 'Button title - Redirect to the Home page',
            message: 'Home page'
          })}
            </FocusableButton>
          </div>
        </div>;
  } else {
    component = <div className={styles.carouselsContainer}>
          {ninjaController.allNotEmptyProviders.map((provider, index) => <TileCarousel key={provider.slug} showOutline={navigationController.isGamepadInteraction} onChildPress={handleEnterPress} provider={provider} name={carouselNames[index]} />)}
        </div>;
  }
  const appsRequired = ninjaController?.summary?.ninjaAppsRequired;
  return <div className={styles.container}>
        <div className={styles.backTitleContainer}>
          <p className={styles.pageTitle}>{t({
          message: 'Ninja'
        })}</p>
        </div>
        <div className={styles.content}>
          <section className={styles.ninjaTilesSection}>
            <div className={styles.levelContainer}>
              <div className={styles.levelLabelContainer}>{level}</div>
              <div className={styles.levelLabelName}>{ninjaController?.summary?.highestUnlockedNinjaRank?.name}</div>
              <p className={styles.ninjaExperience}>{`${userXP} XP`}</p>
              <Progress valuePosition={'top'} showCurrentValue showStartEndValues minValue={minValueProgress} maxValue={maxValueProgress} value={ninjaController?.summary?.experience} className={styles.ninjaProgressBar} />
            </div>
            <div className={styles.progressContainer}>
              <img decoding={'async'} className={styles.logoGlow} src={require('images/svg/small-logo.svg')} />
              <span className={styles.ninjaEarnText}>{t({
              message: 'Earn a free month'
            })}</span>
              <span className={styles.ninjaPlayFocusText}>
                <Trans context={'Number of games should be played to unlock a new level'}>
                  Play {appsRequired} focus games to unlock.
                </Trans>
              </span>
              <StepProgress maxValue={requiredGames} value={playedGames} />
            </div>
          </section>
          {component}
        </div>
      </div>;
}));