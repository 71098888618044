import { log } from '@utomik-app-monorepo/logger';

import { WindowData } from '../tizen/interfaces/application';

export class WebOSWindowData extends WindowData {
  private _deviceId: string;
  private _appInfo: Parameters<Parameters<Window['webOS']['fetchAppInfo']>[0]>[0];
  private _deviceInfo: Parameters<Parameters<Window['webOS']['deviceInfo']>[0]>[0];

  constructor() {
    super();
    if (window.webOS) {
      window.webOS.service.request('luna://com.webos.service.sm', {
        method: 'deviceid/getIDs',
        parameters: {
          idType: ['LGUDID'],
        },
        onSuccess: (inResponse) => {
          this._deviceId = inResponse.idList[0].idValue;
          log('Result UNIQUE ID: ' + JSON.stringify(inResponse));
          return true;
        },
        onFailure: (inError) => {
          console.log('Failed to get system ID information');
          console.log('[' + inError.errorCode + ']: ' + inError.errorText);
          return false;
        },
      });

      this.instance.fetchAppInfo((info) => {
        this._appInfo = info;
      });

      this.instance.deviceInfo((info) => {
        this._deviceInfo = info;
      });
    }
  }

  public get hasBluetoothSupport(): boolean {
    //TODO: add check bluetooth support for webos
    return true;
  }

  public get instance() {
    return window.webOS;
  }

  public get deviceId() {
    return this._deviceId;
  }

  public get getAppInfo() {
    return this._appInfo;
  }

  public get getDeviceInfo() {
    return {
      version: this._deviceInfo.version,
      model: this._deviceInfo.modelName,
      brand: 'LG',
    };
  }

  public get allowedToPlayMainVideo(): boolean {
    return true;
  }
}
