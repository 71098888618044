import { CountryByIp, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
export const useNetworkTest = () => {
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const networkTestController = streamViewUtilsService.utils.networkTestController;
  const runNetworkTest = async () => {
    const countryByIp = new CountryByIp();
    await countryByIp.fetch();
    streamViewUtilsService.startNetworkTest();
  };
  useEffect(() => {
    runNetworkTest();
    return () => {
      networkTestController.finishTest();
      networkTestController.resetData();
    };
  }, []);
  return {
    data: networkTestController.data,
    state: networkTestController.state
  };
};