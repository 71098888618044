import { faCheck, faDesktop, faMobileScreen, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Application } from '@utomik-app-monorepo/store';
import { Channel } from '@utomik-app-monorepo/store';
import { AsyncObjectState } from '@utomik-app-monorepo/store';
import { TileType } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent, pathTv } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef } from 'react';
import './tile-item.scss';
const smallLogoImage = pathTv + require('images/svg/small-logo.svg');
type AppTileItemProps = {
  item: Application | Channel;
  focused?: boolean;
  style?: CSSProperties;
  showChecker?: boolean;
  isChecked?: boolean;
  showPlatformIcons?: boolean;
  idx?: number;
  carouselName?: string;
  onCheckClick?: (props: any) => void;
  isSmall?: boolean;
  showFocusOutline?: boolean;
  forciblyFocused?: boolean;
  tagId?: string;
  hasMouseOver?: boolean;
  showAgeRestricted?: boolean;
  isSpotlight?: boolean;
  channelsView?: boolean;
  seeAllChannelsView?: boolean;
  type: TileType;
  isLong?: boolean;
};
export const TileItem = withFocusable()<AppTileItemProps>(observer(function TileItem(props) {
  const {
    item,
    focused,
    style,
    showChecker,
    isChecked,
    showPlatformIcons,
    setFocus,
    isSmall,
    showFocusOutline = true,
    forciblyFocused,
    tagId,
    hasMouseOver = true,
    showAgeRestricted,
    isSpotlight,
    channelsView,
    seeAllChannelsView,
    isLong
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const mainImageRef = useRef<HTMLImageElement>(null);
  const focusImageRef = useRef<HTMLImageElement>(null);
  const smallLogoImageRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    focused && containerRef.current.focus({
      preventScroll: true
    });
  }, [focused]);
  const isChannel = item instanceof Channel;
  const images: {
    main: string;
    focus: string;
  } = useMemo(() => {
    if (isChannel) {
      return {
        main: item.logoUrl || item?.images?.FS?.url,
        focus: item.logoUrl || item?.images?.BI?.url
      };
    } else if (isSpotlight) {
      return {
        main: item?.images?.find(item => item.tag === 'B3')?.url,
        focus: item?.images?.find(item => item.tag === 'B3')?.url
      };
    }
    return {
      main: item?.images?.find(item => item.tag === 'B2')?.url,
      focus: item.boxImage?.url
    };
  }, [item?.images]);
  const checker = useMemo(() => {
    return showChecker && <div className={'checker-container'}>
            {isChecked && <FontAwesomeIcon className={'delete-icon'} icon={faCheck} />}
          </div>;
  }, [isChecked, showChecker]);
  const ageRestricted = useMemo(() => {
    if (item instanceof Application) {
      return item.isAgeRestricted && <div className={'age-restricted-label'}>
              {t({
          context: 'The text shows if a game is age restricted',
          message: 'Age restricted'
        })}
            </div>;
    }
    return null;
  }, [item]);
  const noImage = useMemo(() => {
    return !images.main && item?.state === AsyncObjectState.Done && <div className={'no-image'}>
            <img decoding={'sync'} ref={smallLogoImageRef} src={smallLogoImage} />
            <p>{item?.name}</p>
          </div>;
  }, [images, item?.state, item?.name]);
  const mainImage = useMemo(() => images.main && <>
            <img decoding={'sync'} alt={item.name + '1'} src={images.main} ref={mainImageRef} />
            <img decoding={'sync'} alt={item.name + '2'} src={images.focus} ref={focusImageRef} />
          </>, [images]);
  const platformIcons = useMemo(() => {
    return showPlatformIcons && !isChannel && <div className={'platform-icons__container'}>
            {item.platforms?.includes('tv') && <FontAwesomeIcon icon={faTv} />}
            {item.platforms?.includes('mobile') && <FontAwesomeIcon icon={faMobileScreen} />}
            {item.platforms?.includes('desktop') && <FontAwesomeIcon icon={faDesktop} />}
          </div>;
  }, [showPlatformIcons, isChannel, !isChannel && item?.platforms]);
  useEffect(() => {
    item?.fetch();
    const image = mainImageRef.current || focusImageRef.current;
    const handleLoad = () => {
      if (!containerRef.current) return;
      containerRef.current.style.backgroundImage = 'none';
    };
    image && image.addEventListener('load', handleLoad);
    return () => {
      item?.abortFetch();
      image && image.removeEventListener('load', handleLoad);
    };
  }, []);
  const handleClick = useCallback(() => {
    if (showAgeRestricted) return;
    dispatchEnterEvent();
  }, [showAgeRestricted]);
  const handleMouseOver = useCallback(() => {
    if (!hasMouseOver) return;
    setFocus();
  }, [hasMouseOver]);
  const content = useMemo(() => {
    const channelTitle = isChannel && <p>{item?.name}</p>;
    return <>
          {ageRestricted}
          {checker}
          <div className={cx('app-tile-item', {
        'app-tile-item--channel': isChannel,
        'app-tile-item--unsupported': item.state !== AsyncObjectState.Pending && !isChannel && !item?.isSupportedOnTv,
        'app-tile-item--top-shadow': showPlatformIcons,
        'app-tile-item--bottom-shadow': isChannel
      })} tabIndex={0} ref={containerRef}>
            {platformIcons}

            {noImage}
            {mainImage}
            {channelTitle}
          </div>
        </>;
  }, [ageRestricted, checker, containerRef, platformIcons, noImage, mainImage, isChannel, showPlatformIcons, item]);
  return <div style={{
    ...style,
    boxShadow: showFocusOutline ? undefined : 'none'
  }} className={cx('tile-item-container', {
    'tile-item-container--focus': focused && !isSmall && !isSpotlight && !seeAllChannelsView,
    'tile-item-container--focus-small': isSmall && focused || forciblyFocused,
    'tile-item-container--small': isSmall,
    'tile-item-container--long': isLong,
    'tile-item-container--spotlight': isSpotlight,
    'tile-item-container--spotlight--focus': isSpotlight && focused,
    'tile-item-container--channel-view': channelsView,
    'tile-item-container--channel-view--focus': channelsView && focused,
    'tile-item-container--all-channels-view': seeAllChannelsView,
    'tile-item-container--all-channels-view--focus': seeAllChannelsView && focused
  })} onClick={handleClick} onMouseOver={handleMouseOver} id={tagId}>
        {content}
      </div>;
}));