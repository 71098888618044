import { Trans, t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './pairing-code-expired-dialog.scss';
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
export const PairingCodeExpiringDialog = observer(function PairingCodeExpiringDialog({
  action
}: Props) {
  return <div className={'pairing-code-expired-modal__container'}>
      <p className={'title'}>
        <Trans context={'Expired pairing code message'}>Pairing code is expired</Trans>
      </p>
      <FocusableButton autofocus type={'primary'} onEnterPress={() => action(DialogResult.OK)}>
        {t({
        context: 'Button - Refresh pairing code',
        message: 'Refresh'
      })}
      </FocusableButton>
    </div>;
});
export default PairingCodeExpiringDialog;