import { Platform, checkCurrentPlatform } from '@utomik-app-monorepo/utils';
import { useEffect } from 'react';

/**
 * To trigger this hook, call the following example.
 * ```ts
 * window.dispatchEvent(new CustomEvent<{ appId: string | number }>('utomikDeepLinkShow', { detail: { appId: '5' } }));
 * ```
 * or in js
 * ```js
 * window.dispatchEvent(new CustomEvent('utomikDeepLinkShow', { detail: { appId: '5' } }));
 * ```
 * @param onDeepLinkShow Callback that is called on utomikDeepLinkShow and provides the appId
 */
export function useDeepLink(onDeepLinkShow: (appId: number | null) => void) {
  /**
   * utomikDeepLinkShow Event Handler
   * @param event CustomEvent with appId
   */
  function onDeepLinkTizen(event) {
    const splitArr = window.location.href.split('/');
    const currentAppId = +splitArr[splitArr.length - 1];
    try {
      const appId = Number(event.detail.appId);
      if (currentAppId === appId) return;

      // Check if appId appears valid
      if (!isFinite(appId)) throw new Error(`appId not finite`);
      if (appId <= 0) {
        onDeepLinkShow(null);
        return;
      }
      // Report the appId with onDeepLinkShow callback.
      onDeepLinkShow(appId);
    } catch (ex) {
      console.error(`Deeplinking failed: ${ex}`);
    }
  }
  function onDeepLinkWebos() {
    const global = window as any;
    const splitArr = window.location.href.split('/');
    const currentAppId = +splitArr[splitArr.length - 1];
    const launchParams = global.launchParams ? global.launchParams : global.PalmSystem && global.PalmSystem.launchParams ? global.PalmSystem.launchParams : '';
    if (launchParams) {
      const {
        contentTarget: appId
      } = JSON.parse(launchParams);
      if (currentAppId === +appId || !appId) return;
      if (+appId) {
        onDeepLinkShow(+appId);
      } else {
        onDeepLinkShow(null);
      }
    }
  }
  useEffect(() => {
    const platform = checkCurrentPlatform();
    if (platform === Platform.Tizen) {
      window.addEventListener('utomikDeepLinkShow', onDeepLinkTizen);
    } else if (platform === Platform.WebOS) {
      document.addEventListener('webOSLaunch', onDeepLinkWebos);
      document.addEventListener('webOSRelaunch', onDeepLinkWebos);
    }
    return () => {
      // Cleanup
      window.removeEventListener('utomikDeepLinkShow', onDeepLinkTizen);
      document.removeEventListener('webOSLaunch', onDeepLinkWebos);
      document.removeEventListener('webOSRelaunch', onDeepLinkWebos);
    };
  }, []);
}