import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton, FocusableTextSection } from '@utomik-app-monorepo/components';
import { Application, ApplicationStoreContext, NavigationContext, NewsItem, NewsItemStoreContext } from '@utomik-app-monorepo/store';
import { sizeRemToPx } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import styles from './news-article.module.scss';
const FocusableMainImage = withFocusable()<{
  app: Application;
  newsItem: NewsItem;
}>(observer(({
  app,
  newsItem
}) => {
  return <img decoding={'async'} className={styles.image} src={app?.spotlightImages?.horizontalImage?.url || app?.spotlightImages?.deluxeImage?.url || newsItem?.article?.image} />;
}));
export const NewsArticle = withFocusable()(observer(() => {
  const {
    id
  } = useParams();
  const newsItemStore = useContext(NewsItemStoreContext);
  const appController = useContext(ApplicationStoreContext);
  const {
    backHandler
  } = useContext(NavigationContext);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const newsItem = newsItemStore.items.find(item => String(item.article.id) === id);
  const app = newsItem?.article?.applicationId && appController.getItem(newsItem?.article?.applicationId);
  useEffect(() => {
    app?.fetch();
  }, [app]);
  useEffect(() => {
    newsItem?.article?.fetch();
  }, [newsItem]);
  const handlePartialFocus = props => {
    scrollableContainerRef.current?.scroll({
      behavior: 'smooth',
      top: props.top - sizeRemToPx(15)
    });
  };
  const handleImageFocused = props => {
    scrollableContainerRef.current?.scroll({
      behavior: 'smooth',
      top: props.y - sizeRemToPx(7)
    });
  };
  return <div className={styles.container}>
        <div className={styles.backTitleContainer}>
          <FocusableButton autofocus onEnterPress={backHandler} type={'back'} className={styles.backButton} />
        </div>
        <div className={styles.contentContainer} ref={scrollableContainerRef}>
          <FocusableMainImage onBecameFocused={handleImageFocused} newsItem={newsItem} app={app} />
          <FocusableTextSection title={newsItem?.article.title} description={newsItem?.article.body} onBecamePartialFocused={handlePartialFocus} analyticOrigin={['NewsItem', String(newsItem.article.id)]} />
        </div>
      </div>;
}));