import { getEncodedAppQuery, isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncObject, AsyncObjectState, ObjectID } from '../objectStore/asyncObject';

export enum DisallowedReason {
  NoCountry = 'NO_USER_COUNTRY',
  NoBirthdate = 'NO_USER_BIRTHDATE',
  CountryRestricted = 'COUNTRY_RESTRICTED',
  AgeRestricted = 'AGE_RESTRICTED',
  ComingSoon = 'IS_COMING_SOON',
  NoRights = 'NO_PLAY_RIGHTS',
}

export enum reverseReasonMap {
  NO_USER_COUNTRY = DisallowedReason.NoCountry,
  NO_USER_BIRTHDATE = DisallowedReason.NoBirthdate,
  COUNTRY_RESTRICTED = DisallowedReason.CountryRestricted,
  AGE_RESTRICTED = DisallowedReason.AgeRestricted,
  IS_COMING_SOON = DisallowedReason.ComingSoon,
  NO_PLAY_RIGHTS = DisallowedReason.NoRights,
}

export interface ApiAppPermissionsEntry {
  permissions: ApiAppPermissions;
}

export interface ApiAppPlayPermission {
  allowed: boolean;
  reasons?: string[];
}

export interface ApiAppPermissions {
  play: ApiAppPlayPermission;
}

export class AppPlayPermission extends AsyncObject<ApiAppPermissionsEntry[]> {
  @observable
  public classified = false;

  @observable
  public allowed: boolean;

  @observable
  public reasons: DisallowedReason[] = [];

  public constructor(requestQueue: RequestQueue, id: ObjectID, permissions?: ApiAppPermissions) {
    super(requestQueue, id, `v2/users/me/permissions/applications?q=${getEncodedAppQuery(id)}`);
    makeObservable(this);

    if (!isNullOrUndefined(permissions)) {
      this.load([{ permissions: permissions }]);
      this.setState(AsyncObjectState.Done);
    }
  }

  @action
  public load = (apiItems: ApiAppPermissionsEntry[]) => {
    // apiItems is an array, because the endpoint returns an array.
    // Here we will always have only 1 item in the array, so we can just take this item.
    if (apiItems.length == 0) {
      this.classified = true;
      throw new Error(
        `ApiAppPermissionsEntry array has 0 items, which means we don't have viewing rights for this game.`
      );
    } else if (apiItems.length !== 1) {
      throw new Error(`ApiAppPermissionsEntry array has ${apiItems.length} items, but it should have only 1.`);
    }
    this.allowed = apiItems[0].permissions.play.allowed;

    if (apiItems[0].permissions?.play?.reasons?.length > 0) {
      apiItems[0].permissions.play.reasons.forEach((reason) => {
        this.reasons.push(reverseReasonMap[reason]);
      });
    }
  };
}
