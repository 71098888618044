import * as React from 'react';
import { faArrowRotateForward, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { Application } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Progress } from '../progress/progress';
import './achievements-stats-panel.scss';
type Props = {
  app: Application;
};
export const AchievementsStatsPanel: React.FC<Props> = observer(function AchievementsStatsPanel({
  app
}) {
  const unlockedAchievementsCount = app?.appVersion?.achievements?.achievementsUnlockedCount;
  const achievementsCount = app?.appVersion?.achievements?.achievementsCount;
  return <div className={'achievements-stats__wrapper'}>
      <Progress title={`${t({
      context: 'Title - Achievements count',
      message: 'Achievements'
    })} ${unlockedAchievementsCount || 0} / ${achievementsCount || 0}`} value={app?.appVersion?.achievements?.achievementsProgress} maxValue={100} />
      <div className={'last-played-container'}>
        <FontAwesomeIcon icon={faArrowRotateForward} className={'arrow-icon'} />
        <div className={'time-container'}>
          <p>{t({
            context: 'How long was prev session',
            message: 'Last session'
          })}</p>
          <strong>{moment(app.userStat?.lastPlayed).format('llll')}</strong>
        </div>
      </div>
      <div className={'total-playtime-container'}>
        <FontAwesomeIcon className={'clock-icon'} icon={faClock} />
        <div className={'time-container'}>
          <p>{t({
            message: 'Total play time'
          })}</p>
          <strong>{(moment.duration(app.userStat?.secondsPlayed, 'seconds') as any).format('h[h] m[m]')}</strong>
        </div>
      </div>
    </div>;
});