import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { AnalyticControllerContext, AnalyticItemTypes } from '@utomik-app-monorepo/store';
import { joinLocation, sizeRemToPx } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Markdown from '../markdown/markdown';
import './focusable-text-section.scss';
type FocusableDummyProps = {
  height: string;
  onBecameFocused: (props: any, itemProps?: any) => void;
  focusable?: boolean;
  focusKey?: string;
};
const FocusableDummy = withFocusable()<FocusableDummyProps>(({
  height,
  focused
}) => {
  //add class for autotests
  return <div className={focused ? 'dummy-description--focus' : undefined} style={{
    width: '80%',
    height: '1px',
    marginBottom: height,
    backgroundColor: 'transparent'
  }} />;
});
type FocusableTextSectionProps = {
  title?: string;
  description: string;
  onBecamePartialFocused: (props: any, itemProps: any) => void;
  sectionHeight?: number;
  makeLinksFocusable?: boolean;
  analyticOrigin?: string[];
  focusable?: boolean;
};
export const FocusableTextSection: React.FC<FocusableTextSectionProps> = observer(function FocusableTextSection({
  title = '',
  description,
  onBecamePartialFocused,
  sectionHeight = 25,
  makeLinksFocusable = true,
  analyticOrigin,
  focusable = true
}) {
  const analyticController = useContext(AnalyticControllerContext);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const [textHeight, setTextHeight] = useState<number>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const containerHeight = textContainerRef.current.clientHeight;
    setTextHeight(containerHeight);
    if (!textContainerRef.current) return;
    //set negative margin of text container to place it under the focusable sections
    textContainerRef.current.style.marginTop = -containerHeight + 'px';
  }, [description]);
  const handleLinkPress = (href: string) => {
    analyticController.navigate({
      location_on_page: joinLocation(analyticOrigin),
      item_type: AnalyticItemTypes.Game,
      item_name: href
    });
    navigate(href);
  };
  const dummies = (maxItemHeight: number = sectionHeight) => {
    let hgh = textHeight;
    //calculate max partial height based on font size
    const maxPartialHeight = sizeRemToPx(maxItemHeight);
    const minPartialHeight = maxPartialHeight / 2;
    //calculate the number of focusable segments
    const partialsCount = Math.floor(textHeight / maxPartialHeight) + (textHeight % maxPartialHeight > minPartialHeight ? 1 : 0) || 1;
    //create an array of the focusable dummy segments
    return Array.from({
      length: partialsCount
    }).map((_, idx) => {
      //subtract the maxPartialHeight value from hgh value on every iteration
      hgh -= maxPartialHeight;
      //get height for every segment. The last segment may have different height
      const height = idx === partialsCount - 1 ? maxPartialHeight + hgh : maxPartialHeight;
      return <FocusableDummy key={idx} focusKey={String(idx + 'dummy')} height={height + 'px'} onBecameFocused={onBecamePartialFocused} focusable={focusable} />;
    });
  };
  return <div style={{
    overflow: 'hidden'
  }}>
      {textHeight && dummies()}
      <div className={'focusable-description__wrapper'} ref={textContainerRef}>
        {!!title && <h2 ref={titleContainerRef} className={'focusable-text__title'}>
            {title}
          </h2>}
        <div className={'description'}>
          {description && <Markdown callback={handleLinkPress} hasFocusableLinks={makeLinksFocusable} onFocusLink={onBecamePartialFocused} text={description} />}
        </div>
      </div>
    </div>;
});