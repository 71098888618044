import { DEFAULT_RETRIES } from '@utomik-app-monorepo/constants';
import { getDeviceTag, joinLocation } from '@utomik-app-monorepo/utils';
import { computed, flow, makeObservable, observable, override, when } from 'mobx';
import { computedFn } from 'mobx-utils';

import { AnalyticController } from '../../../app/global/analyticController/analyticController';
import { NotificationFactory } from '../../../app/global/notificationFactory/notificationFactory';
import { RequestMethod, RequestPriority } from '../../requestQueue/requestQueue';
import { ApiAppListEntry, AppListEntry } from '../applicationStore/appListEntry';
import { Application } from '../applicationStore/application';
import { ApplicationStore } from '../applicationStore/applicationStore';
import { ChannelStore } from '../channelStore/channelStore';
import { AsyncObjectState, ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';

export enum MyListState {
  none,
  adding,
  removing,
}

/**
 * A store to manage the user's "my list" games.
 */
export class MyListStore extends ObjectStore<AppListEntry, ApiAppListEntry> {
  private _applicationStore: ApplicationStore;
  private readonly _notificationFactory: NotificationFactory;
  private readonly _channelStore: ChannelStore;
  private readonly _analyticController: AnalyticController;
  private _initialized = false;
  private _baseUrl: string;

  /**
   * @param applicationStore - Is here mostly for the RequestQueue.
   * @param url - Api endpoint url.
   * @param retries - Amount of retries before failing request.
   */
  public constructor(
    applicationStore: ApplicationStore,
    notificationFactory: NotificationFactory,
    channelStore: ChannelStore,
    analyticController: AnalyticController,
    url = 'v2/users/me/applications/mylist',
    retries = null
  ) {
    super(
      applicationStore.requestQueue,
      `${url}?permissionfilter=country,age&cloud_platform=${getDeviceTag()}`,
      retries
    );
    makeObservable(this);
    this._baseUrl = url;
    this._applicationStore = applicationStore;
    this._notificationFactory = notificationFactory;
    this._channelStore = channelStore;
    this._analyticController = analyticController;
  }

  public get initialized(): boolean {
    return this._initialized;
  }
  @observable
  private _myListApps: Application[] = [];

  // Indicates the given objectID is being added/removed from the list
  @observable
  private _processing = new Map<ObjectID, MyListState>();

  /**
   * Prepend an app in front of the list.
   *
   * @param app - The app to prepend.
   */
  public add = flow(function* (this: MyListStore, app: Application) {
    this.state = AsyncObjectState.Pending;
    // wait for the application to be added/removed in a previous call

    yield when(() => !this._processing.get(app.id) || this._processing.get(app.id) === MyListState.none);

    // mark this application as being added to the list
    this._processing.set(app.id, MyListState.adding);

    const appIndex = this._myListApps.findIndex((application) => application.id === app.id);

    // if it's already in the list, "adding" succeeded
    if (appIndex >= 0) {
      this._processing.delete(app.id);
      return true;
    }

    const breadCrumbs = this._analyticController.getBreadCrumbs({ currentSection: app.slug });

    this._applicationStore.analyticController.addToMyList({
      item_name: app.slug,
      location_on_page: joinLocation(breadCrumbs),
    });

    // send an add request to the platform
    try {
      const response = yield this.requestQueue.add<void, { application_id: ObjectID }>(
        this._baseUrl,
        { application_id: app.id },
        RequestPriority.Medium,
        RequestMethod.Post,
        null,
        DEFAULT_RETRIES,
        true
      ).promise;

      // only add the app locally if it was successfully added on the platform
      if ([200, 201, 204].indexOf(response.status) !== -1) {
        this._myListApps.unshift(app);
        this.state = AsyncObjectState.Done;
        return true;
      }

      return false;
    } catch (error) {
      this.state = AsyncObjectState.Error;
      console.error(`Error trying to add game to My List on platform!`);
      return false;
    } finally {
      this._processing.delete(app.id);
    }
  });

  /**
   * Remove an app from the list.
   *
   * @param app - The app to remove.
   */
  public remove = flow(function* (this: MyListStore, app: Application) {
    this.state = AsyncObjectState.Pending;
    // wait for the application to be added/removed in a previous call

    yield when(() => !this._processing.get(app.id) || this._processing.get(app.id) === MyListState.none);

    // mark this application as being removed from the list
    this._processing.set(app.id, MyListState.removing);

    const appIndex = this._myListApps.findIndex((application) => application.id === app.id);

    // if it's not in the list, "removing" succeeded
    if (appIndex === -1) {
      this._processing.delete(app.id);
      return true;
    }

    const breadCrumbs = this._analyticController.getBreadCrumbs({ currentSection: app.slug });

    this._applicationStore.analyticController.removeFromMyList({
      item_name: app.slug,
      location_on_page: joinLocation(breadCrumbs),
    });

    // send a remove request to the platform
    try {
      const response = yield this.requestQueue.add<void>(
        `${this._baseUrl}/${Number(app.id)}`,
        {},
        RequestPriority.High,
        RequestMethod.Delete,
        null,
        DEFAULT_RETRIES,
        true
      ).promise;
      // only remove the app locally if it was successfully removed on the platform
      if ([200, 201, 204].indexOf(response.status) !== -1) {
        this._myListApps.splice(appIndex, 1);
        this.state = AsyncObjectState.Done;
        return true;
      }

      return false;
    } catch (error) {
      this.state = AsyncObjectState.Error;
      console.error(`Error trying to remove game from My List on platform!`);
      return false;
    } finally {
      this._processing.delete(app.id);
    }
  });

  public multipleRemove = flow(function* (this: MyListStore, ids: ObjectID[]) {
    const promises = ids.map(
      (id) =>
        this.requestQueue.add<void>(
          `${this._baseUrl}/${Number(id)}`,
          {},
          RequestPriority.High,
          RequestMethod.Delete,
          null,
          DEFAULT_RETRIES,
          true
        ).promise
    );

    try {
      //this.setState(AsyncObjectState.Pending);
      //optimistic
      this._myListApps = this._myListApps.filter((app) => {
        if (ids.includes(app.id)) {
          const breadCrumbs = this._analyticController.getBreadCrumbs({ currentSection: app.slug });

          this._applicationStore.analyticController.removeFromMyList({
            item_name: app.slug,
            location_on_page: joinLocation(breadCrumbs),
          });
          return false;
        } else {
          return true;
        }
      });

      //this.setState(AsyncObjectState.Done);

      yield Promise.all(promises);
      return true;
    } catch (e: any) {
      console.error(`Error trying to remove game from My List on platform!`, e);

      switch (e.statusCode) {
        case 408: {
          this.setState(AsyncObjectState.Error);
          return this._notificationFactory.showRequestFailedNotification();
        }
        case 400: {
          this.setState(AsyncObjectState.Done);
          return true;
        }
      }
      this.setState(AsyncObjectState.Error);
      return false;
    }
  });

  /**
   * Check whether an app is in the list or not
   */
  public contains = computedFn((app: Application): boolean => {
    return this._myListApps.findIndex((application) => application.id === app.id) !== -1;
  });

  /**
   * Indicate if the given application is being added/removed from the list.
   */
  public isModifying = computedFn((app: Application): boolean => {
    return this._processing.get(app.id) && this._processing.get(app.id) !== MyListState.none;
  });

  /**
   * Initialize function because we can't await in the constructor.
   */
  public initialize = flow(function* (this: MyListStore) {
    if (this._initialized) return;

    try {
      yield this.fetch();
      // Fill the my-list apps when we initialize this store.
      this._myListApps = this.items.map((appListEntry): Application => {
        // we're setting with both the ID and slug, so both maps will have it
        const app1 = this._applicationStore.getItem(appListEntry.id, appListEntry.name, appListEntry.permissions);
        app1.setSlug(appListEntry.slug);
        const app2 = this._applicationStore.getItem(appListEntry.slug, appListEntry.name, appListEntry.permissions);
        app2.setId(appListEntry.id);
        return app2;
      });

      this._initialized = true;
    } catch (error: any) {
      if (error?.message !== 'aborted') {
        console.error(`Error fetching My List from platform, assuming it's empty!`);
      }
      this._initialized = true;
    }
  });

  @computed
  public get myListApps(): Application[] {
    return this._myListApps;
  }

  public createAndLoadItem(apiAppListEntry: ApiAppListEntry): AppListEntry {
    return AppListEntry.parse(apiAppListEntry);
  }

  @override
  public override unload(): void {
    this._myListApps = [];
    this._initialized = false;
    super.unload();
  }
}
