import { log } from '@utomik-app-monorepo/logger';
import { IReactionDisposer, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { AccountSummary, SubscriptionStatus } from '../../../dataStore/stores/accountSummary/accountSummary';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';
import { AnalyticController } from '../analyticController/analyticController';
import { NotificationFactory } from '../notificationFactory/notificationFactory';

export class AccountSummaryController {
  private _intervalId: ReturnType<typeof setInterval>;
  private _intervalCounter = 0;
  @observable
  private readonly _accountSummary: AccountSummary;
  private readonly _notificationFactory: NotificationFactory;
  private readonly _analyticController: AnalyticController;
  private readonly _handleSubscriptionNotificationDisposer: IReactionDisposer;

  public constructor(
    accountSummary: AccountSummary,
    notificationFactory: NotificationFactory,
    analyticController: AnalyticController
  ) {
    this._accountSummary = accountSummary;
    this._notificationFactory = notificationFactory;
    this._analyticController = analyticController;

    makeObservable(this);

    this._handleSubscriptionNotificationDisposer = autorun((): void => {
      if (
        this._accountSummary.state === AsyncObjectState.Done &&
        (!this._accountSummary.subscription ||
          [SubscriptionStatus.Deactivated, SubscriptionStatus.Expired, SubscriptionStatus.None].indexOf(
            this._accountSummary.subscription.status
          ) > -1)
      ) {
        if (this._accountSummary.canStartTrial) {
          log('Can start trial');
        }
      }
    });
  }

  /**
   * Ensures that required data is fetched.
   */
  public async loadIfNeeded(): Promise<void> {
    if (this._accountSummary.state === AsyncObjectState.None) {
      log(`Loading account summary`);
      await this._accountSummary.fetch();
    }
  }

  public async refreshData(): Promise<void> {
    log(`Reloading account summary`);
    await this._accountSummary.reFetch();
  }

  public runDataCheckInterval() {
    if (this._intervalId) return;

    this.refreshData();

    this._intervalId = setInterval(() => {
      this._intervalCounter++;

      this.refreshData();

      if (this._intervalCounter > 6) {
        this._intervalCounter = 0;
        clearInterval(this._intervalId);
        this._intervalId = null;
      }
    }, 5000);

    reaction(
      () => this._accountSummary,
      () => {
        this._intervalCounter = 0;
        clearInterval(this._intervalCounter);
        this._intervalId = null;
      }
    );
  }

  @computed
  public get summary(): AccountSummary {
    return this._accountSummary;
  }

  public dispose = () => {
    this._handleSubscriptionNotificationDisposer();
  };
}
