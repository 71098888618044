import { NavigationControllerContext } from '@utomik-app-monorepo/store';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
export const usePlayButton = () => {
  const navigationController = React.useContext(NavigationControllerContext);
  const navigate = useNavigate();
  const playButtonHandler = useCallback(() => {
    navigationController.playGame(navigate).catch(e => console.log(e));
  }, []);
  return [playButtonHandler];
};