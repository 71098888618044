import { action, makeObservable } from 'mobx';

import { AsyncObject, ObjectID } from '../../objectStore/asyncObject';
import { AchievementStore } from '../achievementStore/achievementStore';
import { ApplicationVersionStore } from './applicationversionStore';

export interface ApiApplicationVersion {
  /**
   * ApplicationVersion ID
   */
  id: number;
  /**
   * API URL
   */
  url: string;
  /**
   * Version
   */
  version: string;
}

/**
 * When starting a game, you can chooses from a list of application versions. Play sessions and other things are based on these and not on the actual application itself.
 */
export class ApplicationVersion extends AsyncObject<ApiApplicationVersion> {
  public readonly achievements: AchievementStore;

  public constructor(store: ApplicationVersionStore, applicationId: ObjectID, id: ObjectID) {
    super(store.requestQueue, id, `${store.getBaseUrl()}/${id}`);

    this.id = id;
    this.achievements = new AchievementStore(applicationId, this.id, store.requestQueue);
    makeObservable(this);
  }

  @action
  public load(apiApplicationVersion: ApiApplicationVersion): void {
    this.id = apiApplicationVersion.id;
  }
}
