import { RequestMethod, RequestPriority } from '../../requestQueue/requestQueue';
import { AsyncList } from '../../stores/objectStore/asyncList';
import { StreamStore } from '../../stores/streamStore/streamStore';
import { ObjectID } from '../objectStore/asyncObject';
import { IPlaySessionResponse } from './playSession';

export class PlaySessionDoEnd extends AsyncList<IPlaySessionResponse> {
  private readonly streamStore: StreamStore;

  public constructor(streamStore: StreamStore, id: ObjectID, url?: string) {
    super(streamStore.requestQueue, id, url);

    this.streamStore = streamStore;
  }

  public playSessionDoEnd = async (playSessionId: ObjectID): Promise<IPlaySessionResponse> => {
    const response = await this.requestQueue.add<IPlaySessionResponse>(
      this.url + `/${playSessionId}/do_end`,
      {},
      RequestPriority.High,
      RequestMethod.Post,
      null,
      null,
      true
    ).promise;

    return response.data;
  };
}
