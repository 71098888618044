export enum LogEventType {
  Request = 'REQUEST',
  Dialog = 'DIALOG',
  Response = 'RESPONSE',
  Page = 'PAGE',
  Sidebar = 'SIDEBAR',
  Button = 'BUTTON',
  Device = 'DEVICE',
  Server = 'SERVER',
  GameSession = 'GAME_SESSION',
  Notification = 'NOTIFICATION',
  Gamepad = 'GAMEPAD',
  Keyboard = 'KEYBOARD',
  Video = 'VIDEO',
  Audio = 'AUDIO',
}

export enum LogEventAction {
  Send = 'SEND',
  Error = 'ERROR',
  Open = 'OPEN',
  Close = 'CLOSE',
  Press = 'PRESS',
  Info = 'INFO',
  Select = 'SELECT',
  Show = 'SHOW',
  Hide = 'HIDE',
  Connect = 'CONNECT',
  Disconnect = 'DISCONNECT',
  Initialize = 'INITIALIZE',
}
export interface ILogEvent {
  type: LogEventType;
  action?: LogEventAction;
  verbosity?: 'INFO' | 'ERROR' | 'WARN';
  value: string;
}

export interface IAttachmentResponse {
  id: string;
  fileName: string;
  upload_action: {
    config: {
      AWSAccessKeyId: string;
      acl: string;
      bucket: string;
      key: string;
      policy: string;
      signature: string;
      url: string;
      'x-amz-storage-class': string;
    };
    mechanism: string;
    meta: {
      valid_until: string;
    };
  };
}
