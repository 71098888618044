import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable } from 'mobx';

import { ControllerTypes } from '../../../app/global/keyboardController/interfaces';
import { RequestPriority } from '../../requestQueue/requestQueue';
import { AgeRating, AgeRatingSystem, ContentDescriptor } from '../ageRatingSystemStore/ageRatingSystem';
import { ApiAppPermissions, AppPlayPermission, DisallowedReason } from '../appPermission/appPermission';
import { ChannelLinkList, ChannelMeta, ChannelType } from '../channelLinkList/channelLinkList';
import { Genre } from '../genreStore/genre';
import { AsyncObject, AsyncObjectState, ObjectID } from '../objectStore/asyncObject';
import { UserStat } from '../userStatsStore/userStat';
import { AppList } from './appList';
import { AppStagingTierList } from './appStagingTierList';
import { AppStagingTierStore } from './appStagingtierStore/appStagingtierStore';
import { ApplicationStore } from './applicationStore';
import { ApplicationVersion } from './applicationversionStore/applicationversion';
import { ApplicationVersionStore } from './applicationversionStore/applicationversionStore';
import { ApiAppSystemRequirements, SystemRequirements } from './systemRequirements';

/**
 * The flags to display several flags/labels like 'new' or 'day one release'
 */
export class ApplicationFlags implements ApplicationFlags {
  public constructor(flags: ApiAppFlags) {
    this.isComingSoon = flags.is_coming_soon;
    this.hasAchievements = flags.has_achievements;
    this.ninjaFocus = flags.ninja_focus;
    this.largeStartupSize = flags.large_startup_size;
    this.dayOneRelease = flags.day_one_release;
    this.earlyAccess = flags.early_access;
  }

  /**
   * Boolean used to display 'coming soon' flag
   */
  public isComingSoon: boolean;
  /**
   * Boolean used to display 'achievements' flag
   */
  public hasAchievements: boolean;
  /**
   * Boolean used to display 'new' flag
   */
  public ninjaFocus: boolean;
  /**
   * Boolean used to display 'large startup size' flag
   */
  public largeStartupSize: boolean;
  /**
   * Boolean used to display 'day one release' flag
   */
  public dayOneRelease: boolean;
  /**
   * Boolean used to display 'early access' flag
   */
  public earlyAccess: boolean;
}

export interface ApiApplicationAgeRating {
  ageratingsystem: ApiMinimalFields;
  agerating: ApiAgeRatingMinimalFields;
  contentdescriptors: ApiMinimalFields[];
}

export interface ApiInfoLanguage {
  code: string;
  id: ObjectID;
  url: string;
}

export interface ApiApplication {
  id: number;
  name: string;
  slug: string;
  excerpt: string;
  description: string;
  userrating: ApiAppRating;
  genres: ApiAppGenre[];
  images: ApiAppMedia[];
  application_ageratings: ApiApplicationAgeRating[];
  videos: ApiAppMedia[];
  flags: ApiAppFlags;
  publishers: ApiPartner[];
  developers: ApiPartner[];
  systemrequirements: ApiAppSystemRequirements[];
  original_release_date: Date;
  published_date: Date;
  unpublished_date: Date | null;
  cloud: ApplicationCloudPlatform | undefined;
  info_languages: ApiInfoLanguage[];
  supported_languages: ApiSupportedLanguages[];
  interface: ControllerTypes[];
}

export interface ApiAppGenre {
  id: number;
}

export interface ApiAppRating {
  count: number;
  avg: number;
}

export interface ApiSupportedLanguages {
  id: ObjectID;
  url: string;
  language: ApiInfoLanguage;
  interface: boolean;
  audio: boolean;
  subtitles: boolean;
}

export class AppRating {
  private readonly applicationStore: ApplicationStore;
  private readonly application: Application;

  @observable
  public global: number;

  @observable
  public voteCount: number;

  @computed
  public get user(): number {
    const userRating = this.applicationStore.userRatingStore.getItemByApplicationId(this.application.id);
    return isNullOrUndefined(userRating) ? 0 : userRating.rating;
  }

  public constructor(application: Application, applicationStore: ApplicationStore) {
    makeObservable(this);
    this.applicationStore = applicationStore;
    this.application = application;
  }

  @action
  setGlobal(n: number, voteCount: number): void {
    this.global = n;
    this.voteCount = voteCount;
  }

  public async setUser(rating: number): Promise<void> {
    return this.applicationStore.userRatingStore.userRateApplication(this.application.id, rating);
  }

  @computed
  public get state(): AsyncObjectState {
    if (this.application.state !== AsyncObjectState.Done) return this.application.state;

    return this.applicationStore.userRatingStore.state;
  }
}

export class AppAgeRatingList {
  private readonly applicationStore: ApplicationStore;
  private readonly application: Application;
  private _apiApplicationAgeRating: ApiApplicationAgeRating[];

  public constructor(application: Application, applicationStore: ApplicationStore) {
    makeObservable(this);
    this.application = application;
    this.applicationStore = applicationStore;
  }

  public load(ageRatings: ApiApplicationAgeRating[]): void {
    this._apiApplicationAgeRating = ageRatings;
  }

  @computed
  public get items(): ApplicationAgeRating[] {
    return this.applicationStore.ageRatingSystemStore.getAppAgeRatingSystems(this._apiApplicationAgeRating);
  }

  @computed
  public get state(): AsyncObjectState {
    if (this.application.state !== AsyncObjectState.Done) return this.application.state;

    return this.applicationStore.ageRatingSystemStore.fetchAllState;
  }
}

export class AppGenreList {
  private readonly applicationStore: ApplicationStore;
  private readonly application: Application;
  @observable
  private _apiApplicationGenres: ApiAppGenre[];

  public constructor(application: Application, applicationStore: ApplicationStore) {
    makeObservable(this);
    this.application = application;
    this.applicationStore = applicationStore;
  }
  @action
  public load(genres: ApiAppGenre[]): void {
    this._apiApplicationGenres = genres;
  }

  @computed
  public get items(): Genre[] {
    // Map ApiAppGenre to the fetched ones in the genre store
    return this._apiApplicationGenres?.reduce<Genre[]>((result: Genre[], genre: ApiAppGenre) => {
      const res = this.applicationStore.genreStore.items.find((item) => {
        return item.id === genre.id;
      });
      if (!isNullOrUndefined(res)) result.push(res);
      return result;
    }, []);
  }

  @computed
  public get state(): AsyncObjectState {
    if (this.application.state !== AsyncObjectState.Done) return this.application.state;

    return this.applicationStore.genreStore.state;
  }
}

export class Partner {
  private _channelLinkList: ChannelLinkList;
  public id: number;
  public logo: {
    id: number;
    url: string;
  };
  public name: string;
  public site: string;

  public constructor(apiPartner: ApiPartner, channelLinkList: ChannelLinkList) {
    makeObservable(this);
    this.id = apiPartner.id;
    this.logo = apiPartner.logo;
    this.name = apiPartner.name;
    this.site = apiPartner.site;
    this._channelLinkList = channelLinkList;
  }

  /**
   * Link to the channel slug of the channel related to this partner
   */
  @computed
  public get channelMeta(): ChannelMeta {
    return this._channelLinkList.getChannelMeta(this.id, ChannelType.partner, this.name);
  }
}

export interface ApiMinimalFields {
  id: number;
  url: string;
}
export interface ApiAgeRatingMinimalFields {
  id: number;
  url: string;
  image_url: string;
}

export interface ApiAppMedia {
  id: number;
  download_url: string;
  tag: string;
}

export interface ApiAppFlags {
  is_coming_soon: boolean;
  has_achievements: boolean;
  ninja_focus: boolean;
  large_startup_size: boolean;
  day_one_release: boolean;
  early_access: boolean;
}

export interface ApiPartner {
  id: number;
  logo: {
    id: number;
    url: string;
  };
  name: string;
  site: string;
}

/*
 * Used to display the apptile
 */
export class Application extends AsyncObject<ApiApplication> implements Application {
  private applicationStore: ApplicationStore;

  @observable
  private _initialPlayTimeSeconds = 0;

  public constructor(store: ApplicationStore, id: ObjectID, name?: string, permissions?: ApiAppPermissions) {
    super(store.requestQueue, id, `${store.getBaseUrl()}/${id}`);
    makeObservable(this);
    this.initialize(store, name, permissions);
  }

  @action
  private initialize(store: ApplicationStore, name?: string, permissions?: ApiAppPermissions): void {
    try {
      this.lastKnownInstallSize = 0;
      this.lastKnownDrive = '';
      this.name = !isNullOrUndefined(name) ? name : '';
      this.installedName = !isNullOrUndefined(name) ? name : '';
      this.rating = new AppRating(this, store);
      this.genres = new AppGenreList(this, store);
      this.ageRatings = new AppAgeRatingList(this, store);
      this.applicationStore = store;
      if (isNullOrUndefined(this.playPermission)) {
        this.playPermission = new AppPlayPermission(store.requestQueue, this.id, permissions);
      }
      this.stagingTierList = new AppStagingTierList(
        store.requestQueue,
        this.id,
        `${store.getBaseUrl()}/${this.id}/stagingtiers`
      );
      this.stagingTiers = new AppStagingTierStore(store.requestQueue, `${store.getBaseUrl()}/${this.id}/stagingtiers`);
    } catch (e) {
      throw new Error('Error in initializing application: ' + e);
    }
  }

  @computed
  public get appVersion(): ApplicationVersion {
    return this.applicationVersions?.items.find((x) => x.id === this.stagingTiers.versionId);
  }

  @computed
  public get platforms(): string[] {
    return this.cloud?.platform_support || [];
  }

  @computed
  public get displayName(): string {
    return isNullOrUndefined(this.name) || this.name.length == 0 || this.installedName == this.name
      ? this.installedName
      : `${this.installedName} (${this.name})`;
  }

  @computed
  public get initialPlayTimeSeconds() {
    return this._initialPlayTimeSeconds;
  }
  @action
  public setInitialPlayTimeSeconds(playedTime?: number) {
    this._initialPlayTimeSeconds = playedTime || this.userStat?.secondsPlayed || 0;
  }

  public override async fetch(priority = RequestPriority.High): Promise<void> {
    if (this.state === AsyncObjectState.Done) return;

    try {
      await Promise.all([super.fetch(priority), this.playPermission.fetch(priority)]);
      return;
    } catch (error: any) {
      this.setError(error);
      this.setState(AsyncObjectState.Error);
      throw error;
    }
  }

  @action
  public load(apiApplication: ApiApplication): void {
    try {
      this.id = apiApplication.id;
      this.name = apiApplication.name;
      if (this.installedName === 'MY_UTOMIK_UNKNOWN_APP') {
        this.installedName = apiApplication.name; // Replace placeholder
      }
      this.slug = apiApplication.slug;
      this.excerpt = apiApplication.excerpt;
      this.description = apiApplication.description;
      this.rating.setGlobal(apiApplication.userrating.avg, apiApplication.userrating.count);
      this.infoLanguages = apiApplication.info_languages.map(
        (l) => this.applicationStore.languagesStore.getLanguageByCode(l.code)?.name
      );
      this.supportedLanguages = apiApplication.supported_languages.map(
        (l) => this.applicationStore.languagesStore.getLanguageByCode(l.language.code)?.name
      );
      this.interface = apiApplication.interface;
      this.relatedAppList = new AppList(
        this.applicationStore,
        apiApplication.id,
        `v2/applications/${apiApplication.slug}/related`
      );
      this.genres.load(apiApplication.genres);
      this.ageRatings.load(apiApplication.application_ageratings);

      this.images = apiApplication.images.map(({ id, tag, download_url }): ApplicationMedia => {
        return {
          id,
          tag,
          url: download_url,
        };
      });

      this.videos = apiApplication.videos.map(({ id, tag, download_url }): ApplicationMedia => {
        return {
          id,
          tag,
          url: download_url,
        };
      });

      this.flags = new ApplicationFlags(apiApplication.flags);
      this.systemRequirements = apiApplication.systemrequirements.map((sysReq) => new SystemRequirements(sysReq));
      this.publishers = apiApplication.publishers?.map(
        (publisher) => new Partner(publisher, this.applicationStore.channelLinkList)
      );
      this.developers = apiApplication.developers?.map(
        (developer) => new Partner(developer, this.applicationStore.channelLinkList)
      );
      this.originalReleaseDate = apiApplication.original_release_date;
      this.publishedDate = apiApplication.published_date;
      this.unpublishedDate = apiApplication.unpublished_date;
      this.cloud = apiApplication.cloud;

      this.applicationVersions = new ApplicationVersionStore(
        this.applicationStore.requestQueue,
        this.id,
        `${this.applicationStore.getBaseUrl()}/${this.id}/versions`
      );
      this.applicationStore.mapItem(this);
    } catch (e) {
      throw new Error('Error in loading application data from API: ' + e);
    }
  }

  @computed
  public get boxImage(): ApplicationMedia {
    return isNullOrUndefined(this.images) ? null : this.images.find((item) => item.tag === 'BI');
  }

  @computed
  public get spotlightImages(): SpotlightImages {
    const fh = isNullOrUndefined(this.images) ? null : this.images.find((item) => item.tag === 'FH');
    const fd = isNullOrUndefined(this.images) ? null : this.images.find((item) => item.tag === 'FD');
    return { horizontalImage: fh, deluxeImage: fd };
  }

  @computed
  public get spotlightVideo(): ApplicationMedia {
    //small set, so just search the list twice ¯\_(ツ)_/¯
    let video = this.videos?.find((video: ApplicationMedia) => {
      return video.tag === 'BS';
    });
    if (!video) {
      video = this.videos?.find((video: ApplicationMedia) => {
        return video.tag === 'CM';
      });
    }
    return video;
  }

  @computed
  public get loadingBackground(): ApplicationMedia {
    return this.images.find((item) => item.tag === 'LB');
  }

  @computed
  public get gameScreenshots(): ApplicationMedia[] {
    return isNullOrUndefined(this.images)
      ? []
      : this.images.filter((image: ApplicationMedia) => {
          return ['SS'].indexOf(image.tag) > -1;
        });
  }

  @computed
  public get gameVideos(): ApplicationMedia[] {
    return isNullOrUndefined(this.videos)
      ? []
      : this.videos.filter((videos: ApplicationMedia) => {
          return ['CM'].indexOf(videos.tag) > -1;
        });
  }

  @computed
  public get gameMedia(): ApplicationMedia[] {
    return [].concat(this.gameVideos, this.gameScreenshots);
  }

  @computed
  public get SpotlightMediaItems(): SpotlightMediaItems {
    return { video: this.spotlightVideo, images: this.spotlightImages };
  }

  @computed
  public get isAgeRestricted() {
    return this.playPermission.reasons.includes(DisallowedReason.AgeRestricted);
  }

  @computed
  public get isComingSoonRestricted() {
    return this.playPermission.reasons.includes(DisallowedReason.ComingSoon);
  }

  @computed
  public get isAllowedToPlay(): boolean {
    return this.playPermission.allowed;
  }

  @computed
  public get isAllowedToView(): boolean {
    return !this.isAgeRestricted;
  }
  @computed
  public get isSupportedOnTv() {
    const platformToLowerCase: string[] = this.cloud?.platform_support?.reduce((acc, cur) => {
      return [...acc, cur.toLowerCase()];
    }, []);

    return platformToLowerCase?.includes('tv');
  }

  /**
   * Name of the application, as stored in the registry
   */
  @observable
  public installedName: string;
  /**
   * Name of the application
   */
  @observable
  public name: string;
  /**
   * Slug of the application
   */
  @observable
  public slug: string;

  @action
  public setSlug(slug: string): void {
    this.slug = slug;
  }
  /**
   * Short description of the application
   */
  @observable
  public excerpt: string;
  /**
   * Full description of the application
   */
  @observable
  public description: string;
  /**
   * global application rating.
   */
  @observable
  public rating: AppRating;
  /**
   * list of genre id's used to display genres
   */
  @observable
  public genres: AppGenreList;
  /**
   * Userstat. It's possible the application is constructed before userstats are fetched.
   */
  @computed
  public get userStat(): UserStat {
    return this.applicationStore.userStatsStore.items.find((x) => {
      return x.application.id === this.id;
    });
  }
  /**
   * Play Permissions
   */
  @observable
  public playPermission: AppPlayPermission;
  /**
   * Related applist
   */
  @observable
  public relatedAppList: AppList;
  /**
   * List of application staging tiers
   */
  @observable
  public stagingTierList: AppStagingTierList;
  /**
   * stagingTiers
   */
  @observable
  public stagingTiers: AppStagingTierStore;
  /**
   * applicationVersions
   */
  @observable
  public applicationVersions: ApplicationVersionStore;
  /**
   * List of images used for the gallery component
   */
  @observable
  public images: ApplicationMedia[];
  /**
   * List of videos used for the gallery component
   */
  @observable
  public videos: ApplicationMedia[];
  /**
   * These are flags used to display several flags/labels like 'new' or 'day one release'
   */
  @observable
  public flags: ApplicationFlags;
  /**
   * Application age ratings
   */
  @observable
  public ageRatings: AppAgeRatingList;

  /**
   * Publishers
   */
  @observable
  public publishers: Partner[];
  /**
   * Content Descriptors
   */
  @observable
  public developers: Partner[];
  /*
   * System requirements
   */
  @observable
  public systemRequirements: SystemRequirements[];
  /**
   * Original release date
   */
  @observable
  public originalReleaseDate: Date;
  /**
   * Publish date
   */
  @observable
  public publishedDate: Date;

  @observable
  public infoLanguages: string[];

  @observable
  public supportedLanguages: string[];

  @observable
  public interface: ControllerTypes[];
  /**
   * Unpublished date
   */
  @observable
  public unpublishedDate: Date | null;
  /**
   * Platform support
   */
  @observable
  public cloud: ApplicationCloudPlatform | undefined;
  /**
   * Last-known install size (used to keep sorting-by-install-size intact after an app is uninstalled)
   * This value is updated when the game's install size changes, but not when it's uninstalled
   */
  @observable
  public lastKnownInstallSize: number;
  /**
   * Last-known install drive (used to keep sorting-by-install-size intact after an app is uninstalled)
   * This value is updated when the game's install size changes, but not when it's uninstalled
   */
  @observable
  public lastKnownDrive: string;
}

export interface ApplicationMedia {
  id: number;
  tag: string;
  url: string;
}

export interface ApplicationAgeRating {
  ageRatingSystem: AgeRatingSystem;
  ageRating: AgeRating;
  contentDescriptors: ContentDescriptor[];
}

export interface SpotlightImages {
  horizontalImage: ApplicationMedia;
  deluxeImage: ApplicationMedia;
}

export interface SpotlightMediaItems {
  video: ApplicationMedia;
  images: SpotlightImages;
}

export interface ApplicationCloudPlatform {
  platform_support: string[];
}
