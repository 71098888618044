import { DialogQueueContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
import { useBlocker, useLocation } from 'react-router';
type Props = {
  onBlocked?: (blocker?: ReturnType<typeof useBlocker>) => void;
  initiallyBlocked?: boolean;
};
export const useHistoryChangeBlocker = ({
  onBlocked,
  initiallyBlocked = true
}: Props = {}) => {
  const dialogQueue = useContext(DialogQueueContext);
  const blocker = useBlocker(initiallyBlocked);
  const location = useLocation();
  useEffect(() => {
    if (blocker.location && location.pathname !== blocker.location.pathname && blocker.state === 'blocked') {
      if (dialogQueue.hasDialogs) return;
      if (blocker.location.state?.systemInitiated) {
        return blocker.proceed();
      }
      onBlocked && onBlocked(blocker);
    }
  }, [blocker, location]);
  return blocker;
};