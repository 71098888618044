import { ControllerActions } from '@utomik-app-monorepo/store';
import { MouseControllerContext } from '@utomik-app-monorepo/store';
import { MouseController } from '@utomik-app-monorepo/store';
import { RefObject, useContext, useEffect, useRef } from 'react';

/**
 * This hook adds mouse scroll functionality to the scrollableRef element
 * @param scrollableRef Ref object of the element to scroll
 * @param navigate navigateByDirection method from withFocusable HOC
 * @param focusScrolling enables scroll by pixels or scroll by focusing
 * @param isEnabled enables scroll
 * @returns {boolean} isCursorVisible
 * */

export const useMouseScroll = (scrollableRef: RefObject<HTMLElement>, navigate: (direction: ControllerActions) => void, focusScrolling = false, isEnabled = true): boolean => {
  const mouseController = useContext(MouseControllerContext);
  const throttledRefFocus = useRef((() => {
    let isAllowed = true;
    let timeoutId: ReturnType<typeof setTimeout>;

    //closure

    return (delta: number) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        isAllowed = true;
      }, 70);
      if (isAllowed) {
        isAllowed = false;
        if (delta > 0) {
          navigate(ControllerActions.Down);
        } else {
          navigate(ControllerActions.Up);
        }
      }
    };
  })());
  const throttledRefScroll = useRef((() => {
    let isAllowed = true;
    let timeoutId: ReturnType<typeof setTimeout>;

    //closure

    return (delta: number, mouseController: MouseController) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        isAllowed = true;
      }, 70);
      if (isAllowed) {
        isAllowed = false;
        const computedStylesSplit = getComputedStyle(scrollableRef.current).transform.match(/matrix.*\((.+)\)/);
        let yOffset = computedStylesSplit ? +computedStylesSplit[1]?.split(',')[5] : 0;
        const containerHeight = scrollableRef.current.scrollHeight - window.innerHeight;

        /**
         * Disable onwheel handler if cursor is visible
         * */
        if (!mouseController.isCursorVisible || !scrollableRef.current) return;
        if (delta > 0) {
          yOffset -= scrollableRef.current.parentElement.clientHeight / 2;
        } else {
          yOffset += scrollableRef.current.parentElement.clientHeight / 2;
        }
        const scrollHeight = -containerHeight - scrollableRef.current.offsetTop;
        /**
         * Set scroll limits
         * */
        if (yOffset >= 0) {
          yOffset = 0;
        } else if (yOffset <= scrollHeight) {
          yOffset = scrollHeight;
        }
        scrollableRef.current.style.transform = `translateY(${yOffset}px)`;
      }
    };
  })());
  useEffect(() => {
    const scrollableRefScoped = scrollableRef.current;
    if (!scrollableRefScoped || !isEnabled) return;
    const transitionStartHandler = () => {
      if (!scrollableRefScoped || !isEnabled) return;
      scrollableRefScoped.style.pointerEvents = 'none';
    };
    scrollableRefScoped.addEventListener('transitionstart', transitionStartHandler, {
      passive: true
    });
    const transitionEndHandler = () => {
      if (!scrollableRefScoped) return;
      scrollableRefScoped.style.pointerEvents = 'auto';
    };
    scrollableRefScoped.addEventListener('transitionend', transitionEndHandler, {
      passive: true
    });
    return () => {
      if (scrollableRefScoped) {
        scrollableRefScoped.removeEventListener('transitionstart', transitionStartHandler);
        scrollableRefScoped.removeEventListener('transitionend', transitionEndHandler);
      }
    };
  }, [isEnabled]);
  useEffect(() => {
    const scrollableRefScoped = scrollableRef.current;
    if (!scrollableRefScoped || !isEnabled) return;

    /**
     * Add onwheel handler
     * */

    const wheelHandler = e => {
      if (!focusScrolling) {
        throttledRefScroll.current(e.deltaY, mouseController);
      } else {
        throttledRefFocus.current(e.deltaY);
      }
    };
    scrollableRefScoped.addEventListener('wheel', wheelHandler, {
      passive: true
    });
    /**
     * Cancel throttle on unmount
     * */
    return () => {
      if (scrollableRefScoped) {
        scrollableRefScoped.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [scrollableRef.current, mouseController.isCursorVisible, isEnabled]);
  return mouseController.isCursorVisible;
};