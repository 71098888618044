import { t } from '@lingui/macro';
import { POTENTIALLY_RELOADED_STREAM_PAGE_KEY } from '@utomik-app-monorepo/constants';
import { useEffect } from 'react';
export function useConfirmOnPageExit(isEnabled: boolean) {
  useEffect(() => {
    const handleHide = () => {
      //set potentiallyReloaded flag if user reloaded the stream page
      window.sessionStorage.setItem(POTENTIALLY_RELOADED_STREAM_PAGE_KEY, 'true');
      window.removeEventListener('pagehide', handleHide);
    };
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      window.removeEventListener('pagehide', handleHide);
      e.preventDefault();
      e.stopImmediatePropagation();
      window.addEventListener('pagehide', handleHide);
      const message = t({
        message: 'The game will be closed if you press OK. Make sure you have saved your progress first!'
      });
      e.returnValue = message; // For legacy browsers
      return message; // For most modern browsers
    };
    if (isEnabled) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handleHide);
    };
  }, [isEnabled]);
}