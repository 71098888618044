import { action, autorun, computed, makeObservable, observable, reaction, toJS } from 'mobx';
import { AppList } from '../../../dataStore/stores/applicationStore/appList';
import { ApplicationStore } from '../../../dataStore/stores/applicationStore/applicationStore';
import { ChannelStore } from '../../../dataStore/stores/channelStore/channelStore';
import { MyListStore } from '../../../dataStore/stores/myListStore/myListStore';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';
import { RecentlyPlayedStore } from '../../../dataStore/stores/recentlyPlayedStore/recentlyPlayedStore';
import { AppTileProvider } from '../../global/tile-provider/app-tile-provider';
import { ChannelTileProvider } from '../../global/tile-provider/channel-tile-provider';
import { ChannelsController } from '../channelsController/channelsController';
import { PageScrollState } from '../pageScrollState/pageScrollState';
import { HomeProviders } from './homeProviders';

/**
 * The controller for the home page.
 */
export class HomeController {
  private readonly _autorunDisposer: ReturnType<typeof autorun>;
  private readonly _reactionDisposer: ReturnType<typeof reaction>;
  @observable
  private _providers: HomeProviders;
  @observable
  private _currentProvider: AppTileProvider | ChannelTileProvider;
  @observable
  private _initialized = false;
  @observable
  private _allNotEmptyProviders: (AppTileProvider | ChannelTileProvider)[] = [];
  private readonly _pageScrollState: PageScrollState = null;
  private _applicationStore: ApplicationStore;
  private _channelsController: ChannelsController;
  private _myListStore: MyListStore;
  @computed
  public get allNotEmptyProviders() {
    return this._allNotEmptyProviders;
  }
  @action
  private _setAllNotEmptyProviders(providers: (AppTileProvider | ChannelTileProvider)[]) {
    this._allNotEmptyProviders = providers;
  }
  @action
  private _setIsInitialized(value: boolean) {
    this._initialized = value;
  }
  @computed
  public get currentProvider(): AppTileProvider | ChannelTileProvider {
    return this._currentProvider;
  }
  public get pageScrollState() {
    return this._pageScrollState;
  }
  public clearPageScrollState() {
    this._pageScrollState.clearState();
  }
  @action
  public setCurrentProvider = (provider: AppTileProvider | ChannelTileProvider) => {
    this._currentProvider = provider;
  };
  @action
  public unload(): void {
    this._applicationStore.abortFetch();
    this._setIsInitialized(false);
    this._currentProvider = undefined;
    this._setAllNotEmptyProviders([]);
    this.clearPageScrollState();
    this.providers.resetProviders();
  }

  /**
   * Initialize the providers.
   */
  public async init(): Promise<void> {
    if (!this._initialized) {
      this.clearPageScrollState();
      const provPromise = this._providers.init();
      // Wait for all the home providers to initialize.
      await provPromise;
      this._setIsInitialized(true);
    }
  }
  public async refresh(): Promise<void> {
    if (!this._initialized) throw new Error('Home Controller not initialized');
    this.providers.refresh();
  }
  public get isInitialized() {
    return this._initialized;
  }
  @computed
  public get providers(): HomeProviders {
    return this._providers;
  }
  @action
  private setProviders(homeProviders: HomeProviders): void {
    this._providers = homeProviders;
  }
  public constructor(channelStore: ChannelStore, recentlyPlayedStore: RecentlyPlayedStore, myListStore: MyListStore, recommendedApps: AppList, channelsController: ChannelsController, applicationStore: ApplicationStore) {
    this._channelsController = channelsController;
    this._applicationStore = applicationStore;
    this._myListStore = myListStore;
    this._pageScrollState = new PageScrollState();
    this.setProviders(new HomeProviders(channelStore, recentlyPlayedStore, myListStore, recommendedApps));
    this._autorunDisposer = autorun(() => {
      const providers: (AppTileProvider | ChannelTileProvider)[] = [this._providers.spotlight, this._providers.recommendedApplications, this._providers.recentlyPlayed, this._providers.myList, this._channelsController.featuredChannelsProvider, ...(this._providers.recommendedChannels || [null]), this._providers.new, this._providers.comingSoon];
      const filteredProviders = providers.reduce((acc, curr) => {
        if (curr && !(curr.state === AsyncObjectState.Done && !curr.length || curr.state === AsyncObjectState.Error)) {
          return [...acc, curr];
        }
        return acc;
      }, [] as (AppTileProvider | ChannelTileProvider)[]);
      this._setAllNotEmptyProviders(filteredProviders);
    });
    this._reactionDisposer = reaction(() => [this._myListStore.myListApps, this._myListStore.myListApps.length], (curr, prev) => {
      //check if current focus key is myList item
      if (!this.pageScrollState?.currentFocusKey?.startsWith('my_list_')) return;

      //if removed an item/s from myList
      if (curr[1] < prev[1]) {
        const current = toJS(curr[0]);
        const firstItemId = current[0]?.id;
        //if there are any elements in the myList array
        if (firstItemId) {
          //change focus item value if the item was removed / it prevents focus lost
          this.pageScrollState.currentFocusKey = `my_list_${firstItemId}`;
        } else {
          //clear focus key if there are no items in the myList array
          this.clearPageScrollState();
        }
      }
    });
    makeObservable(this);
  }
  public dispose = () => {
    this._reactionDisposer();
    this._autorunDisposer();
    this._providers.dispose();
  };
}