import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';
export const useViewOrientation = (): OrientationType => {
  const throttleRef = useRef(debounce(() => {
    setOrientation(window.screen.orientation.type);
  }, 100));
  const [orientation, setOrientation] = useState<OrientationType>();
  const handleOrientation = () => {
    throttleRef.current();
  };
  useEffect(() => {
    throttleRef.current();
    window.addEventListener('resize', handleOrientation);
    window.addEventListener('orientationchange', handleOrientation);
    window.addEventListener('load', handleOrientation);
    return () => {
      window.removeEventListener('resize', handleOrientation);
      window.removeEventListener('orientationchange', handleOrientation);
      window.removeEventListener('load', handleOrientation);
    };
  }, []);
  return orientation;
};