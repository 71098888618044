export default class GamePadState {
  private readonly _name: string;
  private readonly _id: number;
  private readonly _padAxis: number[];
  private readonly _pressedButton: Set<number>;
  private readonly _dPadDiagonals: Set<number>;
  private _diagonalInteractionStart: boolean;

  constructor(name: string, id: number) {
    this._name = name;
    this._id = id;
    this._padAxis = [0, 0, 0, 0];
    this._pressedButton = new Set();
    this._dPadDiagonals = new Set();
    this._diagonalInteractionStart = false;
  }

  public get name(): string {
    return this._name;
  }

  public get id(): number {
    return this._id;
  }

  public get getPressedButton(): Set<number> {
    return this._pressedButton;
  }

  public get getDPadDiagonals(): Set<number> {
    return this._dPadDiagonals;
  }

  public get getDiagonalInteraction(): boolean {
    return this._diagonalInteractionStart;
  }

  public setDiagonalInteraction(value: boolean): void {
    this._diagonalInteractionStart = value;
  }

  public setPressedButton(value: number): void {
    this._pressedButton.add(value);
  }

  public removePressedButton(value: number): void {
    this._pressedButton.delete(value);
  }

  public setDPadDiagonals(value: number): void {
    this._dPadDiagonals.add(value);
  }

  public removeDPadDiagonals(value: number): void {
    this._dPadDiagonals.delete(value);
  }

  public getPadAxis(index: number): number {
    return this._padAxis[index];
  }

  public setPadAxis(index: number, value: number) {
    this._padAxis[index] = value;
  }
}
