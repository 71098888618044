import { log } from '@utomik-app-monorepo/logger';

import { IApplicationInformation, WindowData } from './interfaces/application';
import { QuickPanel } from './quickPanel';

export class TizenWindowData extends WindowData {
  private readonly _quickPanel: QuickPanel;

  constructor() {
    super();
    this._quickPanel = new QuickPanel(this.instance);
  }

  public get instance() {
    return window.tizen;
  }

  public get getAppInfo(): IApplicationInformation {
    return this.instance.application.getAppInfo();
  }

  public get deviceId(): string {
    return window.tizen?.systeminfo?.getCapability('http://tizen.org/system/tizenid');
  }

  public get quickPanel() {
    return this._quickPanel;
  }

  public get hasBluetoothSupport() {
    try {
      /* Checks if a device supports Bluetooth API. */
      const bluetooth = this.instance.systeminfo.getCapability('http://tizen.org/feature/network.bluetooth');
      log('Bluetooth support = ' + bluetooth);
      return true;
    } catch (error) {
      log('No Bluetooth support');
      return false;
    }
  }

  public get getDeviceInfo() {
    return {
      version: window.tizen?.systeminfo?.getCapability('http://tizen.org/feature/platform.version'),
      model: window.tizen?.systeminfo?.getCapability('http://tizen.org/system/model_name'),
      brand: window.tizen?.systeminfo?.getCapability('http://tizen.org/system/manufacturer'),
    };
  }

  public get allowedToPlayMainVideo(): boolean {
    /**
     * Check the TV's firmware version
     * */
    try {
      const is2020Model =
        window.webapis?.productinfo?.getSmartTVServerVersion()?.split('T-INFOLINK')[1].split('-')[0] === '2020';
      const isCodeOf2020Model = window.webapis?.productinfo?.getModelCode()?.indexOf('KANTSU2') !== -1;

      return !(is2020Model && isCodeOf2020Model);
    } catch (e) {
      return true;
    }
  }
}
