import { log } from '@utomik-app-monorepo/logger';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncObject, ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';
import { PlaySession } from './playSession';
import { PlaySessionDoEnd } from './playSessionDoEnd';
import { PlaySessionStatistic } from './playSessionStatistic';

export interface ApiStreamActivity {
  id: ObjectID;
}

export class StreamActivity extends AsyncObject<ApiStreamActivity> {
  protected load(): void {
    log('StreamActivity');
  }
}

export class StreamStore extends ObjectStore<StreamActivity, ApiStreamActivity> {
  private readonly _playSession: PlaySession;
  private readonly _playSessionDoEnd: PlaySessionDoEnd;
  private readonly _playSessionStatistic: PlaySessionStatistic;

  constructor(queue: RequestQueue, url = '') {
    super(queue, url);

    this._playSession = new PlaySession(this, 'playSession', 'v2/playsessions');
    this._playSessionDoEnd = new PlaySessionDoEnd(this, 'playSessionDoEnd', 'v2/playsessions');
    this._playSessionStatistic = new PlaySessionStatistic(this, 'playSessionStatistics', 'v2/statistics/playsessions');
  }

  public get playSession(): PlaySession {
    return this._playSession;
  }

  public get playSessionDoEnd(): PlaySessionDoEnd {
    return this._playSessionDoEnd;
  }

  public get playSessionStatistic(): PlaySessionStatistic {
    return this._playSessionStatistic;
  }

  protected createAndLoadItem(): StreamActivity {
    return undefined;
  }
}
