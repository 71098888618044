import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './game-not-available-dialog.scss';
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
export const GameNotAvailableDialog: React.FC<Props> = observer(function GameNotAvailableDialog({
  action
}) {
  return <div className={'game-not-available-dialog__container'}>
      <p className={'game-not-available-dialog__title'}>{t({
        message: 'This game is no longer available.'
      })}</p>
      <div className={'buttons-container'}>
        <div>&nbsp;</div>
        <FocusableButton onEnterPress={() => action(DialogResult.OK)} autofocus type={'primary'}>
          {t({
          message: 'OK'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default GameNotAvailableDialog;