import { ApiLoginKeyResponse } from '../../../../dataStore/stores/authorizationStore/authorizationStore';

export enum PairingCodeAction {
  LOG_IN = 'LOG_IN',
  SIGN_UP = 'SIGN_UP',
}

export enum PairingCodeStatus {
  UNUSED = 'UNUSED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  USED = 'USED',
}

export interface IApiPairingCode {
  code: number;
  url: string;
  expires_at: string;
  action: PairingCodeAction;
}

export interface IGetPairingCodePayload {
  type: 'TV';
  action: PairingCodeAction;
  device_id: string;
  device_name: string;
  affiliate_code: string;
}

export interface ICheckPairingCodePayload {
  code: number[];
  device_id: string;
}

export interface ICheckPairingCodeResponse {
  code: number;
  status: PairingCodeStatus;
  alc_token: ApiLoginKeyResponse;
  expires_at: string;
}

export interface IInvalidatePairingCodesPayload {
  device_id: string;
}
