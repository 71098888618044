import { t } from '@lingui/macro';
import { NINJA_URL } from '@utomik-app-monorepo/constants';
import { observer } from 'mobx-react';
import React from 'react';
import { QRCodeWithLink } from '../../utomik-q-r-code';
import './ninja-redirect-dialog.scss';
export const NinjaRedirectDialog: React.FC = observer(function NinjaRedirectDialog() {
  return <div className={'ninja-redirect-dialog__container'}>
      <QRCodeWithLink qrCodeTitle={t({
      message: 'Scan QR-code'
    })} qrCodeDescription={t({
      message: 'To become a ninja user'
    })} url={NINJA_URL} linkTitle={t({
      message: 'Follow this link on your device'
    })} />
    </div>;
});
export default NinjaRedirectDialog;