import { t } from '@lingui/macro';
import { HOME_CHANNEL_PAGECOUNT, MIN_ITEMS_IN_VIEW } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';

import { RequestPriority as RP, RequestPriority } from '../../../dataStore/requestQueue/requestQueue';
import { AppList } from '../../../dataStore/stores/applicationStore/appList';
import { Channel } from '../../../dataStore/stores/channelStore/channel';
import { ChannelStore } from '../../../dataStore/stores/channelStore/channelStore';
import { MyListStore } from '../../../dataStore/stores/myListStore/myListStore';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';
import { RecentlyPlayedStore } from '../../../dataStore/stores/recentlyPlayedStore/recentlyPlayedStore';
import { AppTileProvider } from '../../global/tile-provider/app-tile-provider';
import { MyListAppTileProvider } from '../../global/tile-provider/my-list-tile-provider';
import { RecentlyPlayedAppTileProvider } from '../../global/tile-provider/recently-played-tile-provider';

/**
 * All the app tile providers for the home page. The preload does a full load of the channels and applications, but the
 * home providers only load the essentials. The rest is handled by the carousels themselves.
 */
export class HomeProviders {
  @observable
  private _isInitialized = false;
  @observable
  private _recommendedProvider: AppTileProvider;
  @observable
  private _newProvider: AppTileProvider;
  @observable
  private _comingSoonProvider: AppTileProvider;
  @observable
  private _recentlyPlayedProvider: RecentlyPlayedAppTileProvider;
  @observable
  private _myListProvider: MyListAppTileProvider;
  @observable
  private _spotlightProvider: AppTileProvider;

  // These are multiple providers instead of a single provider.
  @observable
  private _recommendedChannelProviders: AppTileProvider[];

  // Used for channel tile providers and recommended channel providers.
  private _channelStore: ChannelStore;

  // Used for channel tile providers and recommended channel providers.
  private _recentlyPlayedStore: RecentlyPlayedStore;

  // Used for channel tile providers and recommended channel providers.
  private _myListStore: MyListStore;

  private _recommendedApps: AppList;

  @computed
  public get new(): AppTileProvider {
    return this._newProvider;
  }
  @computed
  public get recentlyPlayed(): RecentlyPlayedAppTileProvider {
    return this._recentlyPlayedProvider;
  }
  @computed
  public get myList(): MyListAppTileProvider {
    return this._myListProvider;
  }
  @computed
  public get recommendedApplications(): AppTileProvider {
    return this._recommendedProvider;
  }
  @computed
  public get recommendedChannels(): AppTileProvider[] {
    return this._recommendedChannelProviders;
  }
  @computed
  public get comingSoon(): AppTileProvider {
    return this._comingSoonProvider;
  }

  @computed
  public get spotlight(): AppTileProvider {
    return this._spotlightProvider;
  }

  public constructor(
    channelStore: ChannelStore,
    recentlyPlayedStore: RecentlyPlayedStore,
    myListStore: MyListStore,
    recommendedApps: AppList
  ) {
    makeObservable(this);
    this._channelStore = channelStore;
    this._recentlyPlayedStore = recentlyPlayedStore;
    this._myListStore = myListStore;
    this._recommendedApps = recommendedApps;
  }

  @action
  public refresh(): void {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    log('Refreshing home providers...');
    //For now, we just unload everything.
    this._isInitialized = false;

    for (const provider of this._recommendedChannelProviders) {
      provider.unload();
    }
    this._recommendedProvider.unload();
    this._comingSoonProvider.unload();
    this._newProvider.unload();
    this._spotlightProvider.unload();
  }

  /**
   * Reset the providers, otherwise the providers from the previous login (from a different account) might still be stored here.
   */
  @action
  public resetProviders(): void {
    this._isInitialized = false;
    this._recommendedProvider = null;
    this._newProvider = null;
    this._recentlyPlayedProvider = null;
    this._myListProvider = null;
    this._recommendedChannelProviders = null;
    this._comingSoonProvider = null;
    this._spotlightProvider = null;
  }

  /**
   * Fill the providers. Home should still be filled even if preload is skipped.
   * Order based on carousel array in homeController.
   */
  @action
  public async init(): Promise<void> {
    if (this._isInitialized) return;
    // Reset all the providers on logging in.
    this.resetProviders();
    try {
      await Promise.all([
        this.initSpotlight(),
        this.initRecommendedApplications(),
        this.initRecentlyPlayed(),
        this.initMyList(),
        this.initRecommendedChannels(),
        this.initNew(),
        this.initComingSoon(),
      ]);

      this._isInitialized = true;
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error(`Failed to initialize` + !isNullOrUndefined(error) ? ` Error: ${error}` : '');
    }
  }

  /**
   * Initialize the Recommended applications Carousel.
   */
  private initRecommendedApplications = flow(function* (this: HomeProviders) {
    const provider = yield this.createRecommendedProvider();
    this._recommendedProvider = provider;
  });

  /**
   * Initialize the New Carousel.
   */
  private initNew = flow(function* (this: HomeProviders) {
    const provider = yield this.createChannelAppTileProvider('new');
    // This hides the new if it's not preloaded or has no items.
    if (provider.state !== AsyncObjectState.None || !isNullOrUndefined(provider.appList)) {
      this._newProvider = provider;
    }
  });

  /**
   * Initialize the Recently Played Carousel.
   */
  private initRecentlyPlayed = flow(function* (this: HomeProviders) {
    const provider = yield this.createRecentlyPlayedAppTileProvider();
    this._recentlyPlayedProvider = provider;
  });

  /**
   * Initialize the My List Carousel.
   */
  private initMyList = flow(function* (this: HomeProviders) {
    const provider = yield this.createMyListAppTileProvider();
    this._myListProvider = provider;
  });

  /**
   * Initialize the Coming Soon Carousel.
   */
  private initComingSoon = flow(function* (this: HomeProviders) {
    const provider = yield this.createChannelAppTileProvider('coming_soon');
    this._comingSoonProvider = provider;
  });

  /**
   * Initialize the Recommended Channel Carousel.
   */
  private initRecommendedChannels = flow(function* (this: HomeProviders) {
    const channelSet = yield this._channelStore.recommendedChannels.getChannels(0, HOME_CHANNEL_PAGECOUNT);
    const providers = yield this.createRecommendedChannelProviders(channelSet);

    this._recommendedChannelProviders = providers;
    yield this.loadRecommendedChannels(providers, channelSet);
  });

  private initSpotlight = flow(function* (this: HomeProviders) {
    const provider = yield this.createSpotlightTileProvider();
    this._spotlightProvider = provider;
  });

  /**
   * The recently played provider is special because it doesn't use pagination.
   */
  private async createRecentlyPlayedAppTileProvider(): Promise<RecentlyPlayedAppTileProvider> {
    return new RecentlyPlayedAppTileProvider(
      'recently_played', // slug
      t({ context: 'Carousel name', message: 'Recently played' }),
      // eslint-disable-next-line lingui/no-unlocalized-strings
      ['HomePage'],
      this._recentlyPlayedStore
    );
  }

  /**
   * The recommended application provider is special because it only has 30 entries, and isn't a channel.
   */
  private async createRecommendedProvider(): Promise<AppTileProvider> {
    return new AppTileProvider(
      'recommended_applications', // slug
      t({ context: 'Title of a game set with games that are recommended for user', message: 'Recommended for you' }),
      // eslint-disable-next-line lingui/no-unlocalized-strings
      ['HomePage'],
      this._recommendedApps
    );
  }

  /**
   * The 'My List' provider is special because items can be removed and added.
   */
  private async createMyListAppTileProvider(): Promise<MyListAppTileProvider> {
    if (this._myListStore.state !== AsyncObjectState.Done) await this._myListStore.initialize();

    return new MyListAppTileProvider(
      'my_list', // slug
      t({ message: 'My list' }),
      // eslint-disable-next-line lingui/no-unlocalized-strings
      ['HomePage'],
      this._myListStore
    );
  }

  /**
   * Create a regular app tile provider, which is used for most channels.
   *
   * @param slug - The slug of the channel to wrap in the app tile provider.
   */
  private async createChannelAppTileProvider(slug: string, name?: string): Promise<AppTileProvider> {
    const channel = this._channelStore.getItem(slug, name);
    if (channel.state != AsyncObjectState.Done) await channel.fetch(RequestPriority.High);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return new AppTileProvider(channel.slug, channel.name, ['HomePage', `${channel.analyticsName}`], channel.appList);
  }

  private async createSpotlightTileProvider(): Promise<AppTileProvider> {
    const channel = this._channelStore.getItem('spotlight');
    if (channel.state != AsyncObjectState.Done) await channel.fetch(RequestPriority.Critical);

    //await this.loadApplications(channel.spotlightList);

    return new AppTileProvider(
      channel.id.toString(),
      t({ context: 'Page section name', message: 'Featured' }),
      // eslint-disable-next-line lingui/no-unlocalized-strings
      ['HomePage'],
      channel.spotlightList
    );
  }

  /**
   * Load the applications from the provided AppList (necessary for the recommended carousels to work without preloading).
   * @param appList - The app list of the channel, of which the apps get fetched here.
   * @param appListPrio - Priority of the AppList.
   */
  private async loadApplications(appList: AppList, appListPrio: RP = RequestPriority.High): Promise<void> {
    const promises = [];
    const { items } = await appList.getApps(0, MIN_ITEMS_IN_VIEW, appListPrio);
    items.forEach((app) => {
      promises.push(app.fetch(RequestPriority.High));
    });
    await Promise.all(promises);
  }

  /**
   * Create the initial channels without fetching. We do this to get the placeholders for the home page.
   *
   * @param channels - An object with channels as items, and an atEnd boolean.
   */
  private async createRecommendedChannelProviders(channels: {
    items: Channel[];
    atEnd: boolean;
  }): Promise<AppTileProvider[]> {
    const providers: AppTileProvider[] = [];
    try {
      // We push the appTileProviders with an empty applist. We want to create the recommended array as soon as possible so
      // we can show the placeholders on the homepage.
      for (const channel of channels.items) {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        providers.push(new AppTileProvider(channel.slug, channel.name, ['HomePage', `${channel.analyticsName}`], null));
      }
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error(`Error: ${error}`);
    }
    return providers;
  }

  /**
   * Fetch the channels and the apps for those channels.
   *
   * @param providers - The app tile providers for the multiple recommended carousels.
   * @param channels - An object with channels as items, and an atEnd boolean.
   */
  private async loadRecommendedChannels(providers: AppTileProvider[], channels): Promise<void> {
    try {
      for (let i = 0; i < providers.length; i++) {
        const channel = channels.items[i];
        if (channel.state !== AsyncObjectState.Done) await channel.fetch(RequestPriority.High);

        // Load applications. If we don't do this and the preload is disabled, the placeholders will never change into carousels.
        await this.loadApplications(channel.appList, RP.High);
        providers[i].setAppList(channel.appList);
      }
    } catch (error: any) {
      if (error?.message !== 'aborted') {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        console.error(`Error: ${error}`);
      }
    }
  }
  @action
  public dispose = () => {
    this._isInitialized = false;
    this._myListProvider.dispose();
    this._recentlyPlayedProvider.dispose();
  };
}
