import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getCurrentVersion, isDevelopment, isTest } from '@utomik-app-monorepo/utils';
import { useEffect } from 'react';
export const useSentryInit = ({
  dsn
}: {
  dsn: string;
}) => {
  useEffect(() => {
    if (isDevelopment || isTest) {
      // to do
      // Maybe we should add some stage env and track errors from there
    } else {
      // production code
      Sentry.init({
        dsn,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        release: getCurrentVersion()
      });
    }
  }, []);
};