import { action, makeObservable } from 'mobx';

import { ClientController } from '../../../app/global/clientController/clientController';
import { PlatformController } from '../../../app/global/platformController/platformController';
import { RequestQueue } from '../../requestQueue/requestQueue';
import { IssueTypes } from '../issueTypes/issueTypes';
import { AsyncObjectState } from '../objectStore/asyncObject';
import { LogsStore } from './logsStore';

export class VidaLogsStore extends LogsStore {
  private readonly _logs: string[];
  private _MAX_LENGTH = 500;

  constructor(
    requestQueue: RequestQueue,
    platformController: PlatformController,
    issueTypes: IssueTypes,
    clientController: ClientController
  ) {
    super(requestQueue, platformController, issueTypes, clientController);

    this._logs = [];

    makeObservable(this);
  }

  protected createLogsFile = async () => {
    //not used
    return false;
  };

  public addLogToFile = async (log: string) => {
    this._logs.push(log);

    if (this._logs.length > this._MAX_LENGTH) {
      this._logs.shift();
    }
  };

  public async deleteLogsFolder() {
    //not used
    return false;
  }

  public deleteLogFile() {
    //not used
    return false;
  }
  @action
  public sendLogs = async () => {
    this.state = AsyncObjectState.Pending;

    try {
      await this.sendLogFiles([this._logs.join('\n')]);

      this.state = AsyncObjectState.Done;
    } catch (e) {
      this.state = AsyncObjectState.Error;
    }
  };

  public dispose() {
    //not used
    return false;
  }
}
