import { log } from '@utomik-app-monorepo/logger';
import { TokenAuthResponse, TokenManagerContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
export const useAuthWatcher = () => {
  const tokenManager = useContext(TokenManagerContext);
  const location = useLocation();
  const navigate = useNavigate();
  const handleQueryChange = async () => {
    if (location.search) {
      const parsedQuery = new URLSearchParams(location.search);
      switch (parsedQuery.get('action')) {
        case 'login':
          {
            const fromPage = parsedQuery.get('frompage');
            const token = parsedQuery.get('token');
            log(new TokenAuthResponse(token));
            tokenManager.setTokenValueAndStartSession(new TokenAuthResponse(token));
            tokenManager.loginWithJWT();
            tokenManager.loginCloudAPI();
            fromPage && navigate(fromPage, {
              replace: true
            });
            break;
          }
        case 'reauth':
          {
            log('REAUTH IS NOT IMPLEMENTED YET');
            break;
          }
        default:
          break;
      }
    }
  };
  useEffect(() => {
    handleQueryChange();
  }, [location]);
};