export interface ApiAppSystemRequirements {
  id: number;
  url: string;
  type: string;
  cpu: string;
  required_disk_space: number;
  application_size: number;
  memory_capacity: number;
  os: string;
  graphics: string;
  architecture_32_bit: boolean;
  architecture_64_bit: boolean;
  sound: string;
  directx: string;
  opengl: string;
  other: string;
}

export class SystemRequirements {
  constructor(systemRequirements: ApiAppSystemRequirements) {
    try {
      this.id = systemRequirements.id;
      this.type = systemRequirements.type;
      this.cpu = systemRequirements.cpu;
      this.required_disk_space = systemRequirements.required_disk_space;
      this.application_size = systemRequirements.application_size;
      this.memory_capacity = systemRequirements.memory_capacity;
      this.os = systemRequirements.os;
      this.graphics = systemRequirements.graphics;
      this.architecture_32_bit = systemRequirements.architecture_32_bit;
      this.architecture_64_bit = systemRequirements.architecture_64_bit;
      this.sound = systemRequirements.sound;
      this.directx = systemRequirements.directx;
      this.opengl = systemRequirements.opengl;
      this.other = systemRequirements.other;
    } catch (e) {
      throw new Error('Error in setting up system requirements' + e);
    }
  }

  public id: number;
  public type: string;
  public cpu: string;
  public required_disk_space: number;
  public application_size: number;
  public memory_capacity: number;
  public os: string;
  public graphics: string;
  public architecture_32_bit: boolean;
  public architecture_64_bit: boolean;
  public sound: string;
  public directx: string;
  public opengl: string;
  public other: string;

  /**
   * Build non-translated string for 'architecture' system requirement
   */
  public get architectureText(): string {
    if (this.architecture_32_bit && this.architecture_64_bit) {
      return 'Available for 32-bit and 64-bit systems';
    } else if (!this.architecture_32_bit && !this.architecture_64_bit) {
      return 'Not available for 32-bit or 64-bit systems';
    } else if (!this.architecture_32_bit && this.architecture_64_bit) {
      return 'Available for 64-bit systems';
    } else if (this.architecture_32_bit && !this.architecture_64_bit) {
      return 'Available for 32-bit systems';
    }
    throw new Error('Unknown case get architectureText');
  }

  /**
   * Build non-translated string for 'graphics' system requirement
   */
  public get graphicsTexts(): string {
    let graphicsTexts = '';
    if (this.graphics) {
      graphicsTexts += this.graphics + ' ';
    }
    if (this.opengl) {
      graphicsTexts += 'OpenGL: ' + this.opengl + ' ';
    }
    if (this.directx) {
      graphicsTexts += 'DirectX: ' + this.directx;
    }
    return graphicsTexts;
  }
}
