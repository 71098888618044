import { computed, makeObservable } from 'mobx';

import { RequestPriority } from '../../requestQueue/requestQueue';
import { AsyncList } from '../objectStore/asyncList';
import { ObjectID } from '../objectStore/asyncObject';
import { ApiChannel, Channel } from './channel';
import { ChannelStore } from './channelStore';

/**
 * A list of channels from one of the many channel endpoints, like the one for genre channels, or publisher channels etc.
 */
export class ChannelList extends AsyncList<ApiChannel> {
  private readonly channelStore: ChannelStore;

  /**
   * @param channelStore - The Channel store.
   * @param id - This can be both an integer as well as a string.
   * @param url - The url of the channel endpoint.
   */
  public constructor(channelStore: ChannelStore, id: ObjectID, url?: string) {
    super(channelStore.requestQueue, id, url);
    this.channelStore = channelStore;
    makeObservable(this);
  }

  @computed
  public get length(): number {
    return this.itemSet?.length || 0;
  }

  /**
   * Get channels, starting from index, getting the amount of items defined by count.
   *
   * @param index - Current index or starting point.
   * @param count - Amount of items to return.
   * @param priority - The priority of the current list. Some lists have to be retrieved before others.
   */
  public async getChannels(
    index: number,
    count: number,
    loops = false,
    priority = RequestPriority.High
  ): Promise<{ items: Channel[]; atEnd: boolean; newIndex: number; endIndex: number }> {
    try {
      if (loops && this.itemSet?.length > 0) {
        if (index < 0) index += this.itemSet?.length;
        if (index >= this.itemSet?.length) index -= this.itemSet?.length;
      }

      // if we can't fill the current list with what we have, and we're looping, get the rest from the start, and concatenate the two
      // The channels from the api endpoint.
      const apiChannels1 = await super.getItems(index, count, priority);
      let endIndex = index + apiChannels1.items?.length;
      let apiChannels: ApiChannel[] = apiChannels1.items ? apiChannels1.items : undefined;
      if (loops && apiChannels?.length != 0 && index + count > this.itemSet?.length) {
        const newIndex = Math.max(index - this.itemSet?.length, 0);
        endIndex = Math.max(index + count - this.itemSet?.length, 0);
        const newCount = Math.min(count, index + count - this.itemSet?.length);
        const apiChannels2 = await super.getItems(newIndex, newCount, priority);
        if (apiChannels2.items) apiChannels = apiChannels.concat(apiChannels2.items);
      }

      return {
        items: apiChannels?.map((apiChannel: ApiChannel): Channel => {
          return this.channelStore.getItem(apiChannel.slug, apiChannel.name);
        }),
        atEnd: apiChannels1.atEnd,
        newIndex: index,
        endIndex: endIndex,
      };
    } catch (error: any) {
      if (error.message ? error.message !== 'aborted' : error !== 'aborted') {
        console.error(`Error: ${error}`);
      }
      throw error;
    }
  }
}
