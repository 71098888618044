import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncList } from '../../stores/objectStore/asyncList';
import {
  IStreamGetGatewayList,
  IStreamGetGatewayListAvailableGateway,
} from '../../stores/streamStore/streamGetGatewayList';
import { ObjectID } from '../objectStore/asyncObject';

export interface IHasGatewayResource {
  has: boolean;
}

export class HasGatewayResource extends AsyncList<IHasGatewayResource> {
  public constructor(requestQueue: RequestQueue, id: ObjectID, url?: string) {
    super(requestQueue, id, url);
  }

  public async getHasGatewayResource(
    gateway: IStreamGetGatewayList,
    cloudToken: string,
    appId: ObjectID
  ): Promise<IStreamGetGatewayListAvailableGateway> {
    const options = {
      method: 'GET',
      headers: { 'x-auth-token': cloudToken },
    };

    return new Promise<IStreamGetGatewayListAvailableGateway>((resolve, reject) => {
      setTimeout(() => {
        reject({ ...gateway, hasResources: false });
      }, 10000);

      fetch(gateway.address + this.url + (appId ? `?appId=${appId}` : ''), options)
        .then((response) => response.json())
        .then((result) => resolve({ ...gateway, hasResources: result.has }))
        .catch(() => reject({ ...gateway, hasResources: false }));
    });
  }
}
