export interface ApiAgeRating {
  id: number;
  rating: string;
  image_url: string;
}
export class AgeRating {
  public id: number;
  public rating: string;
  public imageUrl: string;

  public constructor(apiAgeRating: ApiAgeRating) {
    this.id = apiAgeRating.id;
    this.rating = apiAgeRating.rating;

    this.imageUrl = apiAgeRating.image_url;
  }
}

export interface ApiContentDescriptor {
  id: number;
  name: string;
  url: string;
  description: string;
}

export class ContentDescriptor {
  public id: number;
  public name: string;
  public url: string;
  public description: string;
  public constructor(apiContentDescriptor: ApiContentDescriptor) {
    this.id = apiContentDescriptor.id;
    this.name = apiContentDescriptor.name;
    this.url = apiContentDescriptor.url;
    this.description = apiContentDescriptor.description;
  }
}

export interface ApiAgeRatingSystem {
  id: number;
  system: string;
  site: string;
  ageratings: ApiAgeRating[];
  contentdescriptors: ApiContentDescriptor[];
}

export class AgeRatingSystem {
  /**
   * Creates a new age rating system (why is this called parse?)
   *
   * @param apiAgeRatingSystem
   */
  public static parse(apiAgeRatingSystem: ApiAgeRatingSystem): AgeRatingSystem {
    return new AgeRatingSystem(apiAgeRatingSystem);
  }

  public id: number;
  public system: string;
  public site: string;
  public ageRating: AgeRating[];
  public contentDescriptors: ContentDescriptor[];

  public constructor(apiAgeRatingSystem: ApiAgeRatingSystem) {
    this.id = apiAgeRatingSystem.id;
    this.system = apiAgeRatingSystem.system;
    this.site = apiAgeRatingSystem.site;
    this.ageRating = apiAgeRatingSystem.ageratings
      ? apiAgeRatingSystem.ageratings.map((apiAgeRating) => new AgeRating(apiAgeRating))
      : [];
    this.contentDescriptors = apiAgeRatingSystem.contentdescriptors
      ? apiAgeRatingSystem.contentdescriptors.map((contentDescriptor) => new ContentDescriptor(contentDescriptor))
      : [];
  }
}
