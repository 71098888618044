import { isNullOrUndefined, pathTv } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import './star-rating.scss';
type Props = {
  rating: number;
  isLoading?: boolean;
  className?: string;
};
export const StarRating: React.FC<Props> = observer(function StarRating({
  rating,
  isLoading,
  className
}) {
  return rating ? <span className={cx('star-rating__container', {
    'star-rating__container--loading': isLoading,
    [className]: !!className
  })}>
      {!isNullOrUndefined(rating) && <img decoding={'sync'} src={pathTv + require('images/svg/Star.svg')} alt={'rating star'} />}
      <p>{rating?.toFixed(1)}</p>
    </span> : null;
});