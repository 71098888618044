import { computed, makeObservable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { BaseObject, ObjectStore } from '../objectStore/objectStore';
import { GlobalUnlockedAchievement, GlobalUnlockedAchievementStore } from './globalUnlockedAchievementStore';

export interface ApiGlobalAchievement {
  id: ObjectID;
  name: string;
  min_value: number;
  max_value: number;
  group: string;
}

export class GlobalAchievement implements BaseObject {
  private readonly _globalUnlockedAchievementStore: GlobalUnlockedAchievementStore;

  //Make this observable if you ever need to "set" anything :P
  private readonly _apiObj: Readonly<ApiGlobalAchievement>;

  public constructor(globalUnlockedAchievementStore: GlobalUnlockedAchievementStore, x: ApiGlobalAchievement) {
    this._apiObj = x;
    this._globalUnlockedAchievementStore = globalUnlockedAchievementStore;
    makeObservable(this);
  }

  @computed
  private get _unlockedAchievement(): GlobalUnlockedAchievement {
    return this._globalUnlockedAchievementStore.items.find((x) => x.id === this.id);
  }

  public get name(): string {
    return this._apiObj.name;
  }
  public get min(): number {
    return this._apiObj.min_value;
  }
  public get max(): number {
    return this._apiObj.max_value;
  }
  public get group(): string {
    return this._apiObj.group;
  }

  @computed
  public get unlockedAt(): Date {
    return this._unlockedAchievement?.unlockedAt;
  }
  public get id(): ObjectID {
    return this._apiObj.id;
  }
}

export class GlobalAchievementStore extends ObjectStore<GlobalAchievement, ApiGlobalAchievement> {
  private readonly _globalUnlockedAchievementStore: GlobalUnlockedAchievementStore;

  public constructor(
    requestQueue: RequestQueue,
    globalUnlockedAchievementStore: GlobalUnlockedAchievementStore,
    url = 'v2/global/achievements',
    retries = null
  ) {
    super(requestQueue, url, retries);
    this._globalUnlockedAchievementStore = globalUnlockedAchievementStore;
  }

  protected createAndLoadItem(x: ApiGlobalAchievement): GlobalAchievement {
    return new GlobalAchievement(this._globalUnlockedAchievementStore, x);
  }
}
