import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './submit-logout-dialog.scss';
export const SubmitLogoutDialog = observer(function StreamPingTooHighDialog({
  action
}) {
  return <div className={'stream-ping__container'}>
      <h1 className={'title'}>
        <>{t({
          context: 'Asks user if they want to log out',
          message: 'Are you sure you want to logout?'
        })}</>
      </h1>
      <div className={'button-container'}>
        <FocusableButton autofocus type={'primary'} onEnterPress={() => action(DialogResult.OK)}>
          {t({
          message: 'OK'
        })}
        </FocusableButton>
        <FocusableButton type={'secondary'} onEnterPress={() => action(DialogResult.Cancel)}>
          {t({
          message: 'Cancel'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default SubmitLogoutDialog;