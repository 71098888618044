import { pathTv, sizeRemToPx } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { Loading } from '../loading/loading';
import './qr-code.scss';
type Props = {
  url: string;
  code: string | number;
  isLoading: boolean;
  message: string;
};
export const QRCode: React.FC<Props> = observer(function QRCode({
  url,
  code,
  isLoading,
  message
}) {
  if (code) {
    url = url + `?pairingcode=${code}`;
  }
  return <div className={'qr-code-container'}>
      {!isLoading ? <>
          <QRCodeSVG fgColor={'black'} bgColor={'white'} includeMargin className={'qr-code-svg'} size={sizeRemToPx(33)} value={url} level={'H'} imageSettings={{
        src: pathTv + require('images/img/qr-code-icon.png'),
        height: sizeRemToPx(8),
        width: sizeRemToPx(8),
        excavate: true
      }} />
          {!!message && <div className={'qr-code-status-message'}>{message}</div>}
        </> : <Loading loadingText={'Creating QR code...'} />}
    </div>;
});