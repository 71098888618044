import { ApiAppPermissions } from '../appPermission/appPermission';

export interface ApiSearchResultItem {
  id: number;
  name: string;
  slug: string;
  permissions?: ApiAppPermissions;
}

export interface ApiSearchResult {
  type: 'application' | 'channel';
  match: number;
  item: ApiSearchResultItem;
}

export class SearchResultItem {
  public id: number;
  public name: string;
  public slug: string;
  public permissions?: ApiAppPermissions;

  public constructor(x: ApiSearchResultItem) {
    this.id = x.id;
    this.name = x.name;
    this.permissions = x.permissions;
    this.slug = x.slug;
  }
}

export class SearchResult {
  public type: 'application' | 'channel';
  public match: number;
  public item: ApiSearchResultItem;
  public constructor(x: ApiSearchResult) {
    this.type = x.type;
    this.match = x.match;
    this.item = new SearchResultItem(x.item);
  }

  public static parse(apiItem: ApiSearchResult): SearchResult {
    return new SearchResult(apiItem);
  }
}
