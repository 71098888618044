import { faDesktop, faMobileScreen, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Application, AsyncObjectState, Channel } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styles from './tile.module.scss';
type Props = {
  item?: Application | Channel;
  isSeeAll?: boolean;
  showOutline?: boolean;
  type?: 'application' | 'channel';
  showPlatformIcons?: boolean;
  className?: string;
  showCheck?: boolean;
  isChecked?: boolean;
  idx?: number;
  carouselName: string;
};
export const Tile = withFocusable()<Props>(observer(({
  item,
  setFocus,
  focused,
  isSeeAll = false,
  resumeSpatialNavigation,
  showOutline,
  showPlatformIcons,
  className,
  showCheck,
  isChecked
}) => {
  const [state, setState] = useState<'done' | 'loading' | 'error'>();
  const isChannel = item instanceof Channel;
  let tileType: Props['type'];
  useEffect(() => {
    !isSeeAll && item?.fetch();
  }, [item, isSeeAll]);
  const handleClick = () => {
    resumeSpatialNavigation();
    setFocus();
    dispatchEnterEvent();
  };
  let img: string;
  if (item instanceof Application) {
    img = item?.images?.find(i => i.tag === 'B2')?.url;
    tileType = 'application';
  }
  if (item instanceof Channel) {
    img = item?.images?.FS?.url || item?.images?.BI?.url;
    tileType = 'channel';
  }
  useEffect(() => {
    const image = new Image();
    image.src = img;
    image.onloadstart = () => {
      setState('loading');
    };
    image.onload = () => {
      setState('done');
    };
    image.onerror = () => {
      if (item?.state !== AsyncObjectState.Done) return;
      setState('error');
    };
  }, [img, item?.state]);
  const platformIcons = showPlatformIcons && !isChannel && item.platforms?.length > 0 && <div className={styles.platformIcons}>
          {item.platforms?.includes('tv') && <FontAwesomeIcon icon={faTv} />}
          {item.platforms?.includes('mobile') && <FontAwesomeIcon icon={faMobileScreen} />}
          {item.platforms?.includes('desktop') && <FontAwesomeIcon icon={faDesktop} />}
        </div>;
  let element: React.ReactNode;
  if (isSeeAll) {
    element = <div className={styles.seeAllContainer}>
            <h2>
              <Trans context={'Text on a tile. If user click on it - open extended list of games'}>See all</Trans>
            </h2>
          </div>;
  } else if (item?.state === AsyncObjectState.Done && state === 'error') {
    element = <div className={styles.placeholderErrorContainer} />;
  } else if (state !== 'done') {
    element = <div className={styles.placeholderContainer} />;
  } else {
    element = <img src={img} onError={setState.bind(null, 'error')} onLoad={setState.bind(null, 'done')} loading={'lazy'} decoding={'async'} draggable={false} />;
  }
  return <div className={cx(styles.wrapper, {
    [className]: !!className
  })} onClick={handleClick}>
          {showCheck && <div className={styles.checkContainer}>
              <FontAwesomeIcon className={cx(styles.check, {
        [styles.checkShow]: isChecked
      })} icon={'check'} />
            </div>}
          <div className={cx(styles.container, {
      [styles.containerFocused]: focused && showOutline
    })}>
            {platformIcons}
            {element}
          </div>
          {!isSeeAll && <p className={styles[`title--${tileType}`]}>{item?.name}</p>}
        </div>;
}));