export default {
  buttons: [
    'A',
    'B',
    'X',
    'Y',
    'LB',
    'RB',
    'LT',
    'RT',
    'Back',
    'Start',
    'LS',
    'RS',
    'DPadUp',
    'DPadDown',
    'DPadLeft',
    'DPadRight',
  ],
  axis: ['LeftStickX', '-LeftStickY', 'RightStickX', '-RightStickY'],
  buttonAxis: [null, null, null, null, null, null, 'LeftTrigger', 'RightTrigger'],
};
