import { MIN_ITEMS_IN_VIEW } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { autorun, computed, makeObservable, override } from 'mobx';
import { Application } from '../../../dataStore/stores/applicationStore/application';
import { MyListStore } from '../../../dataStore/stores/myListStore/myListStore';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';
import { AppTileProvider } from './app-tile-provider';
export class MyListAppTileProvider extends AppTileProvider {
  private readonly _autorunDisposer: ReturnType<typeof autorun>;
  private _myListStore: MyListStore;
  @override
  public override get state(): AsyncObjectState {
    return this._myListStore.state;
  }
  @override
  public override get length(): number {
    return this._myListStore.myListApps.length;
  }
  @override
  public override get confirmedEmpty(): boolean {
    return this.length == 0 && this._myListStore.initialized;
  }

  /**
   * Initialize and set local apps.
   *
   * @param slug
   * @param name
   * @param analyticOrigin
   * @param applications
   */
  public constructor(slug: string, name: string, analyticOrigin: string[], myListStore: MyListStore) {
    // This extends the original app tile provider but doesn't need an app list because it doesn't use pagination.
    super(slug, name, [...analyticOrigin, 'MyList'], null);
    this._myListStore = myListStore;
    makeObservable(this);
    this._autorunDisposer = autorun(() => {
      log(`MyListAppTileProvider.myListApps changed, flipping updateBit. Length: ${this._myListStore.myListApps.length}`);
      this.flipBit();
    });
  }
  @computed
  public get myListApps(): {
    items: Application[];
    atEnd: boolean;
  } {
    return {
      items: this._myListStore.myListApps.slice(0, MIN_ITEMS_IN_VIEW),
      atEnd: this._myListStore.myListApps.length <= MIN_ITEMS_IN_VIEW
    };
  }
  public dispose = () => {
    this._autorunDisposer();
  };
}