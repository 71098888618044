import { Platform, getCurrentVersion } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable } from 'mobx';

import { ClientController } from '../../../app/global/clientController/clientController';
import { PlatformController } from '../../../app/global/platformController/platformController';
import { RequestQueue } from '../../requestQueue/requestQueue';
import { AxiosTransport } from '../../transports/axiosTransport/axiosTransport';
import { IssueTypes } from '../issueTypes/issueTypes';
import { AndroidTvLogsStore } from './androidTvLogsStore';
import { ILogEvent, LogEventAction, LogEventType } from './interfaces';
import { TizenLogsStore } from './tizenLogsStore';
import { VidaLogsStore } from './vidaLogsStore';
import { WebosLogsStore } from './webosLogsStore';

const StoresMap = {
  [Platform.Tizen]: TizenLogsStore,
  [Platform.WebOS]: WebosLogsStore,
  [Platform.Vidaa]: VidaLogsStore,
  [Platform.AndroidTV]: AndroidTvLogsStore,
  ['localhost']: VidaLogsStore,
};

export class LogsController {
  private _platformController: PlatformController;

  @observable
  private _isInitialized;
  private readonly _logsStore: WebosLogsStore | TizenLogsStore | VidaLogsStore | AndroidTvLogsStore;
  private readonly _transport: AxiosTransport;

  constructor(
    platformController: PlatformController,
    httpTransport: AxiosTransport,
    requestQueue: RequestQueue,
    issueTypes: IssueTypes,
    clientController: ClientController
  ) {
    this._transport = httpTransport;
    this._platformController = platformController;

    const CurrentStore = StoresMap[platformController.currentPlatform];

    if (CurrentStore) {
      this._logsStore = new CurrentStore(requestQueue, platformController, issueTypes, clientController);
    }

    this._transport.api.interceptors.request.use((config) => {
      this.addNewLogEvent({
        type: LogEventType.Request,
        action: LogEventAction.Send,
        value: config.url,
      });
      return config;
    });

    this._transport.api.interceptors.response.use(
      (config) => config,
      (error) => {
        this.addNewLogEvent({
          type: LogEventType.Response,
          action: LogEventAction.Error,
          value: `Status: ${error?.response?.status || 'Unknown'} ${
            error.response?.config?.baseURL
              ? ' | ' + error.response?.config?.baseURL + '/' + error.response?.config?.url
              : ''
          } | ${error.message}`,
        });
        return Promise.reject(error);
      }
    );

    makeObservable(this);
  }

  public get logsStore() {
    return this._logsStore;
  }
  @computed
  public get isInitialized() {
    return this._isInitialized;
  }
  @action
  public setIsInitialized(val: boolean) {
    this._isInitialized = val;
  }

  public init = async () => {
    if (!this._logsStore || this.isInitialized) return;

    await this.logsStore.initialize();

    this.setIsInitialized(true);

    //log device info
    this.addNewLogEvent({
      type: LogEventType.Device,
      action: LogEventAction.Info,
      value: `Brand: ${this._platformController.deviceBrand || 'Not specified'}, Model: ${
        this._platformController.deviceModel || 'Not specified'
      } OS: ${this._platformController.osVersion || 'Not specified'}, AppVersion: ${
        getCurrentVersion() || 'Not specified'
      }`,
    });
  };

  public addNewLogEvent = (event: ILogEvent) => {
    if (!this._logsStore || !this.isInitialized) return;

    const logString =
      `==> [${new Date().toISOString()}] - [type: ${event.type}] - [action: ${event.action}] - [value: ${
        event.value
      }]` + (event.verbosity ? `[verbosity: ${event.verbosity}]` : '');

    this._logsStore?.addLogToFile(logString);
  };

  public generateAndSendBugReport = async () => {
    if (!this._logsStore || !this.isInitialized) return;
    await this._logsStore?.sendLogs();
  };

  public stop() {
    this.setIsInitialized(false);
    this._logsStore?.dispose();
  }
}
