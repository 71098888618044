import { faBackward, faForward, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { ConnectionMonitorContext, MediaPlayerControllerContext, PlatformControllerContext } from '@utomik-app-monorepo/store';
import { ISlideItem } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import { Loading } from '../../loading/loading';
import { Progress } from '../../progress/progress';
import './media-viewer-dialog.scss';
type MediaViewerProps = {
  currentItem?: ISlideItem;
  blockNavigationOut?: boolean;
  handleChangeSlide?: (delta: number) => void;
  setFocus?: (id: string) => void;
  onBecameFocused?: (props: any) => void;
  action: (action: DialogResult, value?: unknown) => void;
};
type VideoNavigationProps = {
  videoRef: React.MutableRefObject<HTMLVideoElement>;
};
const VideoNavigation = withFocusable({
  forgetLastFocusedChild: true
})<VideoNavigationProps>(observer(function VideoNavigation({
  videoRef
}) {
  const platformController = useContext(PlatformControllerContext);
  const connectionMonitor = useContext(ConnectionMonitorContext);
  const [played, setPlayed] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  useEffect(() => {
    const videoRefScoped = videoRef.current;
    if (!videoRefScoped) return;
    const handleWebosKeyPress = e => {
      const actionsMap = {
        get 415() {
          return videoRefScoped.play().catch(e => console.log(e.message));
        },
        get 19() {
          return videoRefScoped.pause();
        },
        get 417() {
          return handleForward();
        },
        get 412() {
          return handleBackward();
        }
      };
      return actionsMap[e.key];
    };
    const handleVidaaKeyPress = e => {
      const actionsMap = {
        get MediaPlay() {
          return videoRefScoped.play();
        },
        get Pause() {
          return videoRefScoped.pause();
        },
        get MediaFastForward() {
          return handleForward();
        },
        get MediaRewind() {
          return handleBackward();
        },
        get MediaStop() {
          videoRefScoped.pause();
          videoRefScoped.currentTime = 0;
          return true;
        }
      };
      return actionsMap[e.key];
    };

    //the remote buttons handler for Tizen
    const handleTizenKeyPress = key => {
      const actionsMap = {
        get MEDIA_PLAY() {
          return videoRefScoped.play().catch(e => console.log(e.message));
        },
        get MEDIA_PAUSE() {
          return videoRefScoped.pause();
        },
        get MEDIA_NEXT() {
          return handleForward();
        },
        get MEDIA_REWIND() {
          return handleBackward();
        },
        get 10252() {
          if (isPlaying) {
            videoRefScoped.pause();
          } else {
            videoRefScoped.play().catch(e => console.log(e.message));
          }
          return !isPlaying;
        }
      };
      return actionsMap[key.keyCode || key];
    };
    const timeUpdateHandler = e => {
      if (videoRefScoped?.readyState === 4 && !isSeeking) {
        setIsPlaying(true);
        setPlayed((e.target as HTMLVideoElement).currentTime);
      }
    };
    videoRefScoped.addEventListener('timeupdate', timeUpdateHandler);
    const playingHandler = () => {
      setIsPlaying(true);
      setIsLoaded(true);
    };
    videoRefScoped.addEventListener('playing', playingHandler);
    const pauseHandler = () => {
      setIsPlaying(false);
    };
    videoRefScoped.addEventListener('pause', pauseHandler);
    const waitingHandler = () => {
      setIsPlaying(false);
    };
    videoRefScoped.addEventListener('waiting', waitingHandler);
    const seekingHandler = () => {
      setIsSeeking(true);
    };
    videoRefScoped.addEventListener('seeking', seekingHandler);
    const endedHandler = () => {
      setIsPlaying(false);
      if (!videoRefScoped?.duration) return;
      setPlayed(videoRefScoped.duration);
    };
    videoRefScoped.addEventListener('ended', endedHandler);
    const seekedHandler = () => {
      const timer = setInterval(() => {
        if (videoRefScoped?.readyState === 4) {
          setIsSeeking(false);
          setPlayed(videoRefScoped.currentTime);
          clearTimeout(timer);
        }
      }, 50);
    };
    videoRefScoped.addEventListener('seeked', seekedHandler);
    const loadHandler = () => {
      setIsLoaded(false);
    };
    videoRefScoped.addEventListener('load', loadHandler);
    if (platformController.isTizen) {
      window.tizen.mediakey.setMediaKeyEventListener({
        onpressed: handleTizenKeyPress
      });
      document.body.addEventListener('keydown', handleTizenKeyPress);
    }
    if (platformController.isVidaa) {
      document.addEventListener('keydown', handleVidaaKeyPress);
    }
    if (platformController.isWebOS) {
      document.addEventListener('keydown', handleWebosKeyPress);
    }
    return () => {
      if (platformController.isTizen) {
        window.tizen.mediakey.unsetMediaKeyEventListener();
        document.body.removeEventListener('keydown', handleTizenKeyPress);
      }
      if (platformController.isVidaa) {
        document.removeEventListener('keydown', handleVidaaKeyPress);
      }
      if (platformController.isWebOS) {
        document.removeEventListener('keydown', handleWebosKeyPress);
      }
      if (videoRefScoped) {
        videoRefScoped.removeEventListener('timeupdate', timeUpdateHandler);
        videoRefScoped.removeEventListener('playing', playingHandler);
        videoRefScoped.removeEventListener('waiting', waitingHandler);
        videoRefScoped.removeEventListener('seeking', seekingHandler);
        videoRefScoped.removeEventListener('ended', endedHandler);
        videoRefScoped.removeEventListener('seeked', seekedHandler);
        videoRefScoped.removeEventListener('load', loadHandler);
      }
    };
  }, [videoRef.current, isSeeking, isPlaying]);
  useEffect(() => {
    const videoRefScoped = videoRef.current;
    //stop/play video in connection status change
    const disposer = reaction(() => connectionMonitor.connected, curr => {
      if (curr && videoRefScoped.currentTime < videoRefScoped?.duration) {
        videoRefScoped.play().catch(e => console.log(e));
      } else {
        videoRefScoped.pause();
      }
    });
    return () => disposer();
  }, [isPlaying]);
  if (!videoRef.current) return null;
  const handlePlayPress = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play().catch(e => console.log(e.message));
    }
  };
  const handleForward = () => {
    if (videoRef.current.currentTime + 10 < videoRef.current?.duration) {
      videoRef.current.currentTime += 10;
    } else {
      videoRef.current.currentTime = videoRef.current?.duration;
      videoRef.current.play().catch(e => console.log(e.message));
    }
  };
  const handleBackward = () => {
    videoRef.current.currentTime -= 10;
    if (videoRef.current.currentTime - 10 > 0) {
      videoRef.current.currentTime -= 10;
    } else {
      videoRef.current.currentTime = 0;
    }
  };
  return <div className={cx('navigation__container', {
    'navigation__container--show': isLoaded
  })}>
        <div className={'buttons-container'}>
          <FocusableButton type={'dark'} onEnterPress={handleBackward}>
            <FontAwesomeIcon icon={faBackward} />
          </FocusableButton>
          <FocusableButton onEnterPress={handlePlayPress} focusKey={'play-pause-button'} type={'dark'}>
            {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
          </FocusableButton>
          <FocusableButton type={'dark'} onEnterPress={handleForward}>
            <FontAwesomeIcon icon={faForward} />
          </FocusableButton>
        </div>
        <Progress type={'time'} className={'video-progress'} showStartEndValues value={played} minValue={0} maxValue={videoRef.current?.duration} />
      </div>;
}));
export const MediaViewerDialog = withFocusable()<MediaViewerProps>(observer(function MediaViewer({
  setFocus,
  action
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [shouldShowPlayButton, setShouldShowPlayButton] = useState(false);
  const mediaPlayerController = useContext(MediaPlayerControllerContext);
  const videoRef = useRef<HTMLVideoElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const isScreenshot = mediaPlayerController.currentItem?.tag === 'SS';
  useEffect(() => {
    if (!mediaPlayerController.currentItem) return;
    if (mediaPlayerController.currentItem.hasNext && !mediaPlayerController.currentItem.hasPrev) {
      setFocus('arrow-right-button');
    } else if (mediaPlayerController.currentItem.hasPrev && !mediaPlayerController.currentItem.hasNext) {
      setFocus('arrow-left-button');
    } else if (!mediaPlayerController.currentItem.hasNext && !mediaPlayerController.currentItem.hasPrev) {
      setFocus('close-button');
    }
    if (videoRef.current) {
      videoRef.current.style.width = '100vw';
      videoRef.current.style.height = '100vh';
      videoRef.current.style.display = 'none';
      videoRef.current.src = mediaPlayerController.currentItem.url;
      videoRef.current.play().catch(handleError);
    }
    setShouldShowPlayButton(false);
    setIsVideoEnded(false);
  }, [mediaPlayerController.currentItem]);
  useEffect(() => {
    setFocus();
  }, []);
  const handleVideoEnded = useCallback(() => {
    setIsVideoEnded(true);
  }, []);
  const handleWait = () => {
    setIsLoaded(false);
  };
  const handleTimeUpdate = () => {
    videoRef.current.style.display = 'block';
  };
  const handlePlay = () => {
    setErrorMessage(null);
    setIsLoaded(true);
    setIsVideoEnded(false);
  };
  const handleError = e => {
    if (e.name === 'NotAllowedError') {
      setErrorMessage(null);
      setIsLoaded(true);
      setIsVideoEnded(false);
      setShouldShowPlayButton(true);
      return;
    }
    setErrorMessage(t({
      context: 'Error in media player - shows if media failed to load',
      message: 'Error while trying to load media'
    }));
  };
  const handlePlayButtonClick = () => {
    videoRef.current.play().catch(handleError);
    setShouldShowPlayButton(false);
  };
  return <div className={'media-viewer__wrapper'}>
        {/*Prev button*/}
        {mediaPlayerController.currentItem?.hasPrev && <FocusableButton focusKey={'arrow-left-button'} onEnterPress={() => mediaPlayerController.changeSlide(-1)} type={'back'} style={{
      position: 'absolute',
      top: '48%',
      left: '5rem'
    }} />}
        {isScreenshot ? <img decoding={'sync'} className={'viewer-image'} ref={imageRef} src={mediaPlayerController.currentItem?.url} height={'100%'} width={'100%'} onLoad={handlePlay} onError={handleError} /> : <video key={'viewer-video'} preload={'none'} width={1920} height={1080} ref={videoRef} playsInline controls={false} onWaiting={handleWait} onPlaying={handlePlay} onError={handleError} onTimeUpdate={handleTimeUpdate} onEnded={handleVideoEnded} />}
        {!isLoaded && <div className={'loader-container'}>
            {!errorMessage ? <Loading loadingText={''} /> : <div className={'error-message'}>{errorMessage}</div>}
          </div>}
        {shouldShowPlayButton && <div className={'loader-container'}>
            <FocusableButton focusable={false} type={'clickable'} onClick={handlePlayButtonClick}>
              Play
            </FocusableButton>
          </div>}
        {/*Next button*/}
        {mediaPlayerController.currentItem?.hasNext && <FocusableButton pulsating={isVideoEnded} focusKey={'arrow-right-button'} type={'forward'} onEnterPress={() => mediaPlayerController.changeSlide(1)} style={{
      position: 'absolute',
      top: '48%',
      right: '5rem'
    }} />}
        {/*Close button*/}
        <FocusableButton focusKey={'close-button'} type={'close'} style={{
      position: 'absolute',
      top: '5rem',
      right: '5rem'
    }} onEnterPress={() => action(DialogResult.Close)} />
        {!isScreenshot && <VideoNavigation focusable={isLoaded && !shouldShowPlayButton} videoRef={videoRef} preferredChildFocusKey={'play-pause-button'} />}
      </div>;
}));
export default MediaViewerDialog;