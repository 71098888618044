import { AnalyticControllerContext, LogsControllerContext } from '@utomik-app-monorepo/store';
import { LogEventAction, LogEventType } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const useAnalyticsSendView = () => {
  const analyticController = useContext(AnalyticControllerContext);
  const logsController = useContext(LogsControllerContext);
  const location = useLocation();
  useEffect(() => {
    analyticController.sendPageView(location.pathname);
    logsController.addNewLogEvent({
      type: LogEventType.Page,
      action: LogEventAction.Open,
      value: location.pathname
    });
  }, [location]);
};