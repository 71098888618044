import { action, makeObservable, observable } from 'mobx';

import { AsyncObject, ObjectID } from '../objectStore/asyncObject';
import { ClientStagingTierStore } from './clientStagingtierStore';

export interface ApiClientStagingTier {
  id: ObjectID;
  name: string;
}

export class ClientStagingTier extends AsyncObject<ApiClientStagingTier> {
  @observable
  public name: string;

  @action
  private setName(name: string): void {
    this.name = name;
  }

  public constructor(store: ClientStagingTierStore, id: ObjectID, name: string) {
    super(store.requestQueue, id, `${store.getBaseUrl()}/${id}`);
    makeObservable(this);
    this.setName(name);
  }

  @action
  public load(ac: ApiClientStagingTier): void {
    this.id = ac.id;
    this.name = ac.name;
  }
}
