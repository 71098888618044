import * as React from 'react';
import { Application } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import './age-ratings.scss';
type Props = {
  app: Application;
};
export const AgeRatings: React.FC<Props> = observer(function AgeRatings({
  app
}) {
  return <div className={'age-ratings__container'}>
      {app?.ageRatings?.items?.map(item => <div key={item.ageRating.id} className={'rating__container'}>
          <img decoding={'sync'} src={item?.ageRating?.imageUrl} />
          <div>
            {item?.contentDescriptors?.map(item => <p className={'descriptor'} key={item?.id}>
                {item?.name}
              </p>)}
          </div>
        </div>)}
    </div>;
});