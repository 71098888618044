import { DeviceInfo } from '../components/streamViewProps';
import resolveItem from '../streamViewUtils/resolveItem';

/**
 * ApiPlaySession
 */
export interface ApiPlaySession {
  id: number;
}
/**
 * Starts a playsession.
 * @returns ApiPlaySession
 */
export async function postPlaySession(
  baseUrl: string,
  applicationVersionId: number,
  token?: string
): Promise<ApiPlaySession> {
  const playsessionUrl = new URL('/v2/playsessions', baseUrl).toString();
  return resolveItem(playsessionUrl, token, undefined, 'POST', {
    applicationversion: applicationVersionId,
    type: 'cloud',
  });
}

/**
 * Ends a playsession.
 * @returns Never
 */
export async function postPlaySessionEnd(baseUrl: string, playSession: ApiPlaySession, token?: string): Promise<never> {
  const playsessionEndUrl = new URL(`v2/playsessions/${playSession.id}/do_end`, baseUrl).toString();
  return resolveItem(playsessionEndUrl, token, undefined, 'POST');
}

/**
 * Sends statistics for a playsession.
 * @returns Never
 */
export async function postPlaySessionStatistics(
  baseUrl: string,
  playSessionId: number,
  gatewayName: string,
  deviceInfo: DeviceInfo,
  token?: string
): Promise<never> {
  const playsessionStatsUrl = new URL(`v2/statistics/playsessions`, baseUrl).toString();
  return resolveItem(playsessionStatsUrl, token, undefined, 'POST', {
    playsession: playSessionId,
    gateway: gatewayName,
    user_client_version: deviceInfo.clientVersion,
    device: { type: deviceInfo.type, model: deviceInfo.model },
    os: { type: deviceInfo.os, version: deviceInfo.osVersion },
  });
}
