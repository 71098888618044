import { AccountSummaryController } from '@utomik-app-monorepo/store';
import { LoginController } from '@utomik-app-monorepo/store';
import { SubscriptionStatus } from '@utomik-app-monorepo/store';
import moment from 'moment';
import { useState } from 'react';

export const useSubscriptionStatus = (
  accountController: AccountSummaryController,
  loginController: LoginController
) => {
  const [isStatusRefresh, setStatusRefresh] = useState(false);
  const isActiveSubscription =
    accountController.summary.status === SubscriptionStatus.Active ||
    moment(accountController?.summary?.subscription?.expire_due).valueOf() > Date.now();

  const handleRefresh = async () => {
    if (isStatusRefresh) return;

    setStatusRefresh(true);
    await accountController.refreshData();
    setStatusRefresh(false);
  };

  const handleSubscriptionTVFlow = async () => {
    if (!isActiveSubscription) {
      await loginController.subscriptionTVFlow();
    }
  };

  return {
    isStatusRefresh,
    isActiveSubscription,
    handleRefresh: handleRefresh,
    handleSubscriptionTVFlow: handleSubscriptionTVFlow,
  };
};
