import { action, computed, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncObject, ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';

export interface ApiNewsArticleImage {
  id: ObjectID;
  download_url: string;
  tag: string;
}

export interface ApiNewsArticle {
  id: ObjectID;
  title: string;
  body: string;
  author: {
    first_name: string;
  };
  images: ApiNewsArticleImage[];
  is_published: boolean;
  publish_date: string;
  application: {
    id: ObjectID;
  } | null;
  channel: {
    id: ObjectID;
  } | null;
  partner: {
    id: ObjectID;
  } | null;
  external_link: string | null;
}
export class NewsArticle extends AsyncObject<ApiNewsArticle> {
  @observable
  private _x: ApiNewsArticle;

  public constructor(newsArticleStore: NewsArticleStore, id: ObjectID) {
    super(newsArticleStore.requestQueue, id, `v2/news/${id}`);
    makeObservable(this);
  }

  private parseBody(body: string): string {
    const regex = /\((?:https?:\/\/)?(?:www\.)?(?:utomik\.com\/games\/)([-\w]*)(?:\/?\?[^)\s]+)?\)\*\*/gm;
    return body.replace(regex, '(/app-info/$1)**');
  }

  @action
  public load(x: ApiNewsArticle): void {
    x.body = this.parseBody(x.body);
    this._x = x;
  }

  @computed
  public get title(): string {
    return this._x?.title;
  }

  @computed
  public get body(): string {
    return this._x?.body;
  }

  @computed
  public get author(): string {
    return this._x?.author.first_name;
  }

  @computed
  public get image(): string {
    return this._x?.images.find((x) => x.tag === 'NW')?.download_url;
  }

  @computed
  public get publishDate(): string {
    return this._x?.publish_date;
  }

  @computed
  public get applicationId(): ObjectID {
    return this._x?.application?.id;
  }

  @computed
  public get channelId(): ObjectID {
    return this._x?.channel?.id;
  }

  @computed
  public get partner(): ObjectID {
    return this._x?.partner?.id;
  }

  @computed
  public get externalLink(): string {
    return this._x?.external_link;
  }
}

export class NewsArticleStore extends ObjectStore<NewsArticle, ApiNewsArticle> {
  public constructor(requestQueue: RequestQueue, url = 'v2/news') {
    super(requestQueue, url);
    makeObservable(this);
  }

  @action
  public getItem(id: ObjectID): NewsArticle {
    const item = this.itemsMap.get(id) as NewsArticle;
    if (item) {
      return item;
    }

    const newItem = new NewsArticle(this, id);
    this.itemsMap.set(id, newItem);
    return newItem;
  }

  protected createAndLoadItem(x: ApiNewsArticle): NewsArticle {
    const news = new NewsArticle(this, x.id);
    news.load(x);
    return news;
  }
}
