import { faCircleCheck, faServer, faTriangleExclamation, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { useNetworkTest } from '@utomik-app-monorepo/hooks';
import { DialogQueueContext } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import { bpsToMbps } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import './check-connection-dialog.scss';
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
export const CheckConnectionDialog: React.FC<Props> = observer(function CheckConnectionDialog() {
  const dialogQueue = useContext(DialogQueueContext);
  const {
    data,
    state
  } = useNetworkTest();
  useEffect(() => {
    dialogQueue.currentDialog.setShowHeader(state === 'Done' || state === 'Error');
  }, [state]);
  return <div className={'check-connection-dialog__container'}>
      <div className={'animation__container'}>
        <FontAwesomeIcon icon={faTv} className={'computer-icon'} />
        {state === 'Initial' || state === 'TestingPing' || state === 'TestingBandwidth' ? <div className={'info-container'}>
            <div className={'dots-container'}>
              <div className={'dot-pulse'} />
            </div>
          </div> : <div className={'wire-line'}>
            {Object.values(data).every(item => item.status === 'Good') && state !== 'Error' ? <FontAwesomeIcon icon={faCircleCheck} className={'connection-status-good'} /> : <FontAwesomeIcon icon={faTriangleExclamation} className={'connection-status-bad'} />}
          </div>}
        <FontAwesomeIcon icon={faServer} className={'server-icon'} />
      </div>
      {state === 'Done' && <div className={'connection-status__container'}>
          {Object.values(data).every(item => item.status === 'Good') ? <span>
              {t({
          context: 'The message shows to a user if internet connection is good',
          message: 'You are all set to stream'
        })}
            </span> : <>
              <FontAwesomeIcon icon={faTriangleExclamation} className={'connection-status-bad'} />
              {(data.ping.status === 'Bad' || data.packetLoss.status === 'Bad') && <span>
                  {t({
            context: 'The message shows to a user if internet connection is bad',
            message: 'You may experience high latency or input lag'
          })}
                </span>}
              {data.bandwidth.status === 'Bad' && <span>
                  {t({
            context: 'The message shows to a user if internet connection is bad',
            message: 'You may experience stutter or reduced video quality'
          })}
                </span>}
            </>}
        </div>}
      {state === 'Done' && <div className={'additional-info__container'}>
          <table className={'info-table'}>
            <thead>
              <tr>
                <th></th>
                <th>{t({
                context: 'Measured value of the network test',
                message: 'Measured'
              })}</th>
                <th>{t({
                context: 'Required value of the network test',
                message: 'Required'
              })}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t({
                context: 'Bandwidth value in Megabits per second',
                message: 'Bandwidth (Mbps)'
              })}</td>
                <td className={data.bandwidth.status === 'Bad' ? 'error' : ''}>{bpsToMbps(data.bandwidth.value)}</td>
                <td>{`> ${bpsToMbps(data.bandwidth.required)}`}</td>
              </tr>
              <tr>
                <td>{t({
                context: 'Ping value in milliseconds',
                message: 'Ping (ms)'
              })}</td>
                <td className={data.ping.status === 'Bad' ? 'error' : ''}>{data.ping.value}</td>
                <td>{`< ${data.ping.required}`}</td>
              </tr>

              <tr>
                <td>{t({
                context: 'Packet loss value in percents',
                message: 'Packet loss (%)'
              })}</td>
                <td className={data.packetLoss.status === 'Bad' ? 'error' : ''}>{data.packetLoss.value}</td>
                <td>{`< ${data.packetLoss.required}`}</td>
              </tr>
            </tbody>
          </table>
        </div>}
      {state === 'Error' && <div className={'additional-info__container error'}>
          <p>
            {t({
          context: 'Error message, shows if the network test is failed',
          message: 'Request failed. Please check your connection.'
        })}
          </p>
        </div>}
    </div>;
});
export default CheckConnectionDialog;