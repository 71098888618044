import { getDeviceTag } from '@utomik-app-monorepo/utils';
import { computed, makeObservable } from 'mobx';

import { RequestPriority } from '../../requestQueue/requestQueue';
import { AsyncList } from '../objectStore/asyncList';
import { ObjectID } from '../objectStore/asyncObject';
import { ApiAppListEntry } from './appListEntry';
import { Application } from './application';
import { ApplicationStore } from './applicationStore';

/**
 * A list of applications from one of the many application endpoints, like the regular application endpoint, recently played,
 * channel applications and related apps.
 */
export class AppList extends AsyncList<ApiAppListEntry> {
  private readonly appStore: ApplicationStore;

  /**
   * @param appStore - The Application store.
   * @param id - This can be both an integer as well as a string like 'RecentlyPlayedID'.
   * @param url - The url of the application endpoint.
   * @param discardQueryParams - Discard query params.
   */
  public constructor(appStore: ApplicationStore, id: ObjectID, url?: string, discardQueryParams?: boolean) {
    let urlString = `${url}${
      url?.includes('?') ? '&' : '?'
    }permissionfilter=country,age&cloud_platform=${getDeviceTag()}`;

    if (discardQueryParams) {
      urlString = url;
    }

    super(appStore.requestQueue, id, urlString);
    this.appStore = appStore;
    makeObservable(this);
  }

  @computed
  public get length(): number {
    return this.itemSet?.length || 0;
  }

  public get reloadCounter() {
    return this.reloadCount;
  }

  /**
   * Get apps, starting from index, getting the amount of items defined by count.
   *
   * @param index - Current index or starting point.
   * @param count - Amount of items to return.
   * @param priority - The priority of the current list. Some lists have to be retrieved before others.
   */
  public async getApps(
    index: number,
    count: number,
    priority = RequestPriority.High,
    limitRequest = false,
    reload = false
  ): Promise<{ items: Application[]; atEnd: boolean }> {
    try {
      // The apps from the api endpoint.
      const apiApps = await super.getItems(index, count, priority, limitRequest, reload);

      const apps = apiApps.items?.map((apiApp): Application => {
        // we're setting with both the ID and slug, so both maps will have it
        const app1 = this.appStore.getItem(apiApp.id, apiApp.name, apiApp.permissions);
        app1.setSlug(apiApp.slug);
        const app2 = this.appStore.getItem(apiApp.slug, apiApp.name, apiApp.permissions);
        app2.setId(apiApp.id);
        return app2;
      });

      return {
        items: apps,
        atEnd: apiApps.atEnd,
      };
    } catch (error: any) {
      if (error.message ? error.message !== 'aborted' : error !== 'aborted') {
        console.warn(error);
      }
      throw error;
    }
  }
}
