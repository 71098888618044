import { Application, AsyncObjectState, MyListStoreContext, NotificationFactoryContext } from '@utomik-app-monorepo/store';
import { reaction } from 'mobx';
import { useContext, useEffect } from 'react';
export const useMyListNotifications = (app: Application) => {
  const notificationFactory = useContext(NotificationFactoryContext);
  const myListStore = useContext(MyListStoreContext);
  useEffect(() => {
    const disposer = reaction(() => myListStore.contains(app), () => {
      if (myListStore.contains(app) && myListStore.state === AsyncObjectState.Done) {
        notificationFactory.showAddToMyListNotification();
      } else if (!myListStore.contains(app) && myListStore.state === AsyncObjectState.Done) {
        notificationFactory.showRemoveFromMyListNotification();
      } else if (myListStore.state === AsyncObjectState.Error) {
        notificationFactory.showRequestFailedNotification();
      }
    });
    return () => disposer();
  }, []);
};