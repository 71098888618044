import { myUrl } from '@utomik-app-monorepo/constants';
import { AccountSummaryControllerContext, AnalyticControllerContext, DialogResult, TokenManagerContext } from '@utomik-app-monorepo/store';
import { joinLocation } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './finish-external-actions-dialog.scss';
export const FinishExternalActionsDialog = observer(({
  action,
  url
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const tokenManager = useContext(TokenManagerContext);
  const accountSummaryController = useContext(AccountSummaryControllerContext);
  const analyticController = useContext(AnalyticControllerContext);
  const handleOpenExternal = () => {
    const link = `${myUrl}/import-session?token=${tokenManager.token.value.token}&next=${url}`;
    const breadcrumbs = analyticController.getBreadCrumbs();
    analyticController.clickExternalLink({
      url: link,
      location_on_page: joinLocation(breadcrumbs)
    });
    const res = window.open(link, '_blank');
    if (res) {
      setIsOpened(true);
    }
  };
  const handleDone = () => {
    accountSummaryController.runDataCheckInterval();
    action(DialogResult.OK);
  };
  return <div className={'finish-external-actions-dialog__container'}>
      <p className={'finish-external-actions-dialog--text'}>
        You are now being redirected to <strong>{url.replace(/^https?:\/\//, '')}</strong>. After you have completed all
        actions on the site, return to this page and click <strong>"I'm done"</strong> to check your updates
      </p>
      {!isOpened ? <FocusableButton onEnterPress={handleOpenExternal} className={'finish-external-actions-dialog--button'} type={'primary'}>
          Open {url.replace(/^https?:\/\//, '')}
        </FocusableButton> : <FocusableButton onEnterPress={handleDone} className={'finish-external-actions-dialog--button'} type={'primary'}>
          I'm done!
        </FocusableButton>}
    </div>;
});
export default FinishExternalActionsDialog;