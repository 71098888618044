import { t } from '@lingui/macro';
import { log } from '@utomik-app-monorepo/logger';

import { AppList } from '../../../dataStore/stores/applicationStore/appList';
import { Application } from '../../../dataStore/stores/applicationStore/application';
import { RecentlyPlayedStore } from '../../../dataStore/stores/recentlyPlayedStore/recentlyPlayedStore';
import { EdenPreviewType, IEdenPreviewSectionsData, IEdenPreviewTileData, IEdenPreviewTilesData } from './interfaces';

const NUMBER_APPS_TO_SEND = 8;

/**
 * This class is responsible for the displaying Eden Preview(EP) on Tizen TV 2021 and earlier models
 *
 * [generateAndSend] method calls when:
 * 1. The app is loaded => action => we're pushing to EP the recommended games
 * 2. The user is log in => action => we're pushing to EP the recently games
 * 3. The user start the stream => action => we're pushing to EP the recently games with the new one
 * 4. The user is log out => action => we're pushing to EP the recommended games
 *
 * The class is creating file on the TV and then the background application push this data to EP
 */

export class EdenPreviewGamingHubController {
  private readonly recentlyPlayedStore: RecentlyPlayedStore;
  private readonly recommendedApps: AppList;

  constructor(recentlyPlayed: RecentlyPlayedStore, recommendedApps: AppList) {
    this.recentlyPlayedStore = recentlyPlayed;
    this.recommendedApps = recommendedApps;
  }

  public generateAndSend = async (type: EdenPreviewType) => {
    const edenPreview: IEdenPreviewSectionsData = { sections: [] };
    const applicationsRecentlyPlayed: Application[] = this._recentlyPlayedApplications();
    const applicationsRecommendedGames: Application[] = await this._recommendedGamesApplications();

    if (!applicationsRecentlyPlayed) return;

    const edenPreviewRecentlyPlayedTiles: IEdenPreviewTileData[] = applicationsRecentlyPlayed.reduce(
      (prev, curr, idx) => {
        if (idx >= 1) return prev;

        prev.push({
          title: curr.name,
          action_data: `show:${curr.id}`,
          image_ratio: '1by1',
          image_url: curr?.images?.find((item) => item.tag === 'B2')?.url,
        });

        return prev;
      },
      [] as IEdenPreviewTileData[]
    );

    const edenPreviewRecommendedGamesTiles: IEdenPreviewTileData[] = applicationsRecommendedGames.reduce(
      (prev, curr, idx) => {
        if (idx >= NUMBER_APPS_TO_SEND) return prev;

        prev.push({
          title: curr.name,
          action_data: `show:${curr.id}`,
          image_ratio: '1by1',
          image_url: curr?.images?.find((item) => item.tag === 'B2')?.url,
        });

        return prev;
      },
      [] as IEdenPreviewTileData[]
    );
    const edenPreviewRecentlyPlayedTilesData: IEdenPreviewTilesData = {
      position: 1,
      title: t({ context: 'Title of a game set with games user can continue to play', message: 'Continue playing' }),
      tiles: edenPreviewRecentlyPlayedTiles,
    };
    const edenPreviewRecommendedTilesData: IEdenPreviewTilesData = {
      position: 2,
      title: t({
        context: 'Title of a game set with games that are recommended for user',
        message: 'Recommended for you',
      }),
      tiles: edenPreviewRecommendedGamesTiles,
    };

    if (edenPreviewRecentlyPlayedTilesData.tiles.length && type === EdenPreviewType.all) {
      edenPreview.sections.push(edenPreviewRecentlyPlayedTilesData);
    }

    if (edenPreviewRecommendedTilesData.tiles.length) {
      edenPreview.sections.push(edenPreviewRecommendedTilesData);
    }

    log(edenPreview);

    if (window.saveEdenPreviewData) {
      window.saveEdenPreviewData(edenPreview);
    }
  };

  private _recommendedGamesApplications = async (): Promise<Application[]> => {
    try {
      const promises = [];
      const recommendedApps = await this.recommendedApps.getApps(0, NUMBER_APPS_TO_SEND);
      recommendedApps.items.forEach((app) => {
        promises.push(app.fetch());
      });
      await Promise.all(promises);

      return recommendedApps.items;
    } catch (e) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.log('_recommendedGamesTiles error: ' + e);
      return [];
    }
  };

  private _recentlyPlayedApplications = (): Application[] => {
    return this.recentlyPlayedStore?.recentlyPlayedApps;
  };
}
