import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import text from 'dependencies.md';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FocusableTextSection } from '../focusable-text-section/focusable-text-section';
import { PaginationButtons } from '../pagination-buttons/pagination-buttons';
import './licenses-tab.scss';
type Props = {
  handlePartialFocus?: (props: any, itemProps?: any) => void;
  layout?: 'horizontal' | 'vertical';
  focusablePartials?: boolean;
};
const ROWS_IN_VIEW = 9;
const START_TABLE_IDX = 2;
export const LicensesTab = withFocusable()<Props>(observer(function LicensesTab({
  layout = 'horizontal',
  focusablePartials = false,
  handlePartialFocus
}: Props) {
  const initialValues = {
    start: START_TABLE_IDX,
    end: START_TABLE_IDX + ROWS_IN_VIEW
  };
  const [page, setPage] = useState(0);
  const tableHeader = text.split('\n').slice(0, START_TABLE_IDX);
  const tableRows = text.split('\n').slice(0, -2);
  const overallPagesNumber = Math.ceil(tableRows.length / ROWS_IN_VIEW);
  const tableRowsPaginated = tableRows.slice(initialValues.start + ROWS_IN_VIEW * page, initialValues.end + ROWS_IN_VIEW * page);
  const mergedTable = [...tableHeader, ...tableRowsPaginated].join('\n');
  return <div className={`table__wrapper table__wrapper--${layout}`}>
        <PaginationButtons prev={{
      onPress: () => {
        setPage(prev => {
          if (prev - 1 < 0) return prev;
          return prev - 1;
        });
      },
      isPulsating: page + 1 === overallPagesNumber
    }} next={{
      onPress: () => {
        setPage(prev => {
          if (prev + 1 >= overallPagesNumber) return prev;
          return prev + 1;
        });
      },
      isPulsating: page <= 0
    }} currPage={page + 1} pagesCount={overallPagesNumber} preferredChildFocusKey={'pagination-button-2'} className={'pagination-buttons__wrapper'} />

        <FocusableTextSection description={mergedTable} onBecamePartialFocused={handlePartialFocus} makeLinksFocusable={false} sectionHeight={35} focusable={focusablePartials} />
      </div>;
}));