import { Action } from '@remix-run/router';
import { NavigationControllerContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
export const useCustomHistory = () => {
  const navigationController = useContext(NavigationControllerContext);
  const navType = useNavigationType();
  const location = useLocation();
  useEffect(() => {
    if (navType === Action.Push) {
      navigationController.history.push(location.pathname);
    } else if (navType === Action.Pop) {
      navigationController.history.pop();
    }
  }, [navType, location.pathname]);
};