import * as Yup from 'yup';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { StreamingFeedbackControllerContext } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { FocusableCheckbox } from '../../FocusableCheckbox/FocusableCheckbox';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './streaming-feedback-dialog.scss';
type FocusableMarkProps = {
  element: React.ReactElement;
  name?: string;
  value?: boolean;
  isActive?: boolean;
};
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
const FocusableMark = withFocusable()<FocusableMarkProps>(observer(function FocusableMark({
  element,
  focused,
  isActive,
  setFocus
}) {
  const handleClick = () => {
    setFocus();
    dispatchEnterEvent();
  };
  return <div onClick={handleClick} className={cx('focusable-mark', {
    'focusable-mark--focus': focused,
    'focusable-mark--active': isActive
  })}>
        {element}
      </div>;
}));
const FocusableMarksSet = withFocusable()<{
  onChildPress?: (props: any) => void;
  value?: boolean;
}>(observer(function FocusableMarksSet({
  onChildPress,
  value,
  setFocus
}) {
  return <div className={'ratings__container'}>
        <FocusableMark name={'stream_positive_experience'} value={true} onEnterPress={onChildPress} element={<FontAwesomeIcon icon={faThumbsUp} />} isActive={value === true} />
        <FocusableMark name={'stream_positive_experience'} value={false} onEnterPress={onChildPress} element={<FontAwesomeIcon icon={faThumbsDown} />} isActive={value === false} />
      </div>;
}));
export const StreamingFeedbackDialog: React.FC<Props> = observer(function StreamingFeedbackDialog({
  action
}) {
  const streamingFeedbackController = useContext(StreamingFeedbackControllerContext);
  const initialValues = {
    stream_positive_experience: null,
    stream_working: false,
    stream_low_image_quality: false,
    stream_low_audio_quality: false,
    stream_input_lag: false,
    stream_micro_stutters: false,
    stream_long_stutters: false
  };
  const validationShape = {
    stream_positive_experience: Yup.boolean().required(),
    stream_working: Yup.boolean().nullable(true),
    stream_low_image_quality: Yup.boolean().nullable(true),
    stream_low_audio_quality: Yup.boolean().nullable(true),
    stream_input_lag: Yup.boolean().nullable(true),
    stream_micro_stutters: Yup.boolean().nullable(true),
    stream_long_stutters: Yup.boolean().nullable(true)
  };
  const validationSchema = Yup.object().shape({
    ...validationShape,
    stream_positive_experience: validationShape.stream_positive_experience.test('at-least-one-field', 'At least one field must be selected', function () {
      if (this.parent.stream_positive_experience === false && !Object.values(this.parent).some(v => v)) {
        return false;
      }
      return true;
    })
  });
  const formik = useFormik({
    onSubmit: values => {
      streamingFeedbackController.sendStreamingFeedback(values);
      action(DialogResult.OK);
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    initialValues
  });
  const handleChange = ({
    name,
    value
  }: {
    value: boolean;
    name?: string;
  }) => {
    formik.setFieldValue(name, value);
    const values = {
      ...initialValues
    };
    if (name === 'stream_positive_experience') {
      values.stream_positive_experience = value;
      //reset all values to null if positive feedback has been set
      if (value === true) {
        values.stream_long_stutters = null;
        values.stream_input_lag = null;
        values.stream_working = null;
        values.stream_low_image_quality = null;
        values.stream_micro_stutters = null;
        values.stream_low_audio_quality = null;
      }
      formik.setValues(values);
    }
    if (name === 'stream_working') {
      values.stream_positive_experience = formik.values.stream_positive_experience;
      values.stream_working = value;
      formik.setValues(values);
    }
  };
  return <div className={'feedback-dialog__container'}>
      <h1>
        <>
          {t({
          context: 'Asks a user if they had a positive experience during the last play session',
          message: 'Did you have a positive experience using cloud streaming?'
        })}
        </>
      </h1>
      <form>
        <FocusableMarksSet value={formik.values.stream_positive_experience} onChildPress={handleChange} />

        {formik.values.stream_positive_experience === false && <div className={'checkboxes__container'}>
            <h2 className={'feedback-issues__title'}>
              <>
                {t({
              context: 'Questionnaire message after a play session',
              message: 'What issues did you experience?'
            })}
              </>
            </h2>
            <FocusableCheckbox name={'stream_working'} label={t({
          context: 'Cloud stream issue',
          message: "The cloud stream didn't work at all!"
        })} onChangeValue={handleChange} value={formik.values.stream_working} />
            <FocusableCheckbox name={'stream_low_image_quality'} label={t({
          context: 'Cloud stream issue',
          message: 'Low image quality'
        })} onChangeValue={handleChange} disabled={formik.values.stream_working} value={formik.values.stream_low_image_quality} />
            <FocusableCheckbox name={'stream_low_audio_quality'} label={t({
          context: 'Cloud stream issue',
          message: 'Low audio quality'
        })} onChangeValue={handleChange} disabled={formik.values.stream_working} value={formik.values.stream_low_audio_quality} />
            <FocusableCheckbox name={'stream_input_lag'} label={t({
          context: 'Cloud stream issue',
          message: 'Input lag'
        })} onChangeValue={handleChange} disabled={formik.values.stream_working} value={formik.values.stream_input_lag} />
            <FocusableCheckbox name={'stream_micro_stutters'} label={t({
          context: 'Cloud stream issue',
          message: 'Short stutters'
        })} onChangeValue={handleChange} disabled={formik.values.stream_working} value={formik.values.stream_micro_stutters} />
            <FocusableCheckbox name={'stream_long_stutters'} label={t({
          context: 'Cloud stream issue',
          message: 'Long stutters'
        })} onChangeValue={handleChange} disabled={formik.values.stream_working} value={formik.values.stream_long_stutters} />
          </div>}

        <FocusableButton onEnterPress={formik.handleSubmit} focusable={formik.isValid} disabled={!formik.isValid} type={'primary'}>
          {t({
          message: 'Submit'
        })}
        </FocusableButton>

        <FocusableCheckbox name={'do_not_show_again'} label={t({
        context: 'Checkbox text',
        message: 'Do not show this pop-up again'
      })} onChangeValue={streamingFeedbackController.setDontShowAgain} value={!streamingFeedbackController.showAgain} />
      </form>
    </div>;
});
export default StreamingFeedbackDialog;