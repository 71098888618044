import { StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect, useRef } from 'react';
export const useOnScreenGamepad = () => {
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const getGamepadsRef = useRef(navigator.getGamepads);
  useEffect(() => {
    const refScoped = getGamepadsRef.current;
    if (streamViewUtilsService.isOnScreenGamepadVisible) {
      navigator.getGamepads = function () {
        const virtualGamepads: Gamepad[] = [];

        // Add on-screen gamepad at first position
        virtualGamepads[0] = streamViewUtilsService.onScreenGamepad;
        return virtualGamepads;
      };
    }
    return () => {
      navigator.getGamepads = refScoped;
    };
  }, [streamViewUtilsService.isOnScreenGamepadVisible]);
};