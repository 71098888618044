import {
  ApiApplicationVersion,
  ApiCloudTokenAuth,
  ApiGateway,
  ApiGatewayHasResource,
  ApiGatewayQueue,
  ApiServerGroup,
  ApiStagingTier,
} from '../streamTypes';
import resolveItem from './resolveItem';

/**
 * GatewayWithData is a struct that contains both the gateway information, queue information and ping.
 */
export type GatewayWithData = {
  /**
   * ApiGateway
   */
  gateway: ApiGateway;
  /**
   * @deprecated This value does not take into account busy/available VMs. Use `predictedQueue` for calculations instead!
   * Api gateway queue length
   */
  queueLength: number;
  /**
   * Api gateway predicted queue length if a user is queued up.
   */
  predictedQueue: number;
  /**
   * Ping
   */
  ping: number;
};

/**
 * GatewayWithWeight is a struct that contains GatewayWithData and weight.
 */
export type GatewayWithWeight = {
  /**
   * Utomik caculated weight for server selection purposes.
   * FIXME: Explain what this value means.
   */
  weight: number;
} & GatewayWithData;

/**
 * The StreamViewUtil class offers utility to run StreamView in your application.
 *
 * Example use case, which works for most use cases:
 * ```ts
 * const apiToken : string = ...; // Get login token from utomik platform.
 * const cloudToken = await utils.getCloudToken(apiToken);
 * const applicationVersion = await utils.getRecommendedApplicationVersion(slugOrID, apiToken);
 * const gateway = await utils.getRecommendedGateway(cloudToken.token, apiToken);
 * ```
 * There are also some functions to check whether certain browser features are supported.
 *
 * If you want additional control, you can look at the other functions in this file.
 */
export class StreamViewUtils {
  /**
   * Ideal ping in miliseconds
   */
  private readonly _IDEAL_PING_MS = 250;
  /**
   * Worst ping in miliseconds
   * Fallback for undefined values.
   */
  private readonly _WORST_PING_MS = 999;
  /**
   * Ideal amount of users in the queue.
   * FIMXE: Explain what this means.
   */
  private readonly _IDEAL_QUEUE = 10;
  /**
   * Base URL that will be used across the class.
   */
  private readonly _baseURL: URL;
  /**
   * The StreamViewUtils class offers functionality to help you with creating a streamview.
   * @param baseURL Base url to use for all API requests, which will default to `https://api.utomik.com/`
   */
  public constructor(baseURL = `https://api.utomik.com/`) {
    this._baseURL = new URL(baseURL);
  }

  /**
   * Concaternates baseURL and pathname.
   * @param pathname pathname
   * @returns Valid url
   */
  private _getApiUrl(pathname: string) {
    const url = new URL(this._baseURL);
    url.pathname = pathname;
    return url.toString();
  }

  /**
   * Concaternates address and pathname.
   * @param address address
   * @param pathname pathname
   * @returns Valid url
   */
  private _getGatewayUrl(address: string, pathname: string) {
    const url = new URL(address);
    url.pathname = pathname;
    return url.toString();
  }

  /**
   * Returns a cloud authorization token.
   * @param apiToken API Authorization Token
   * @returns ApiCloudTokenAuth
   */
  public async getCloudToken(apiToken: string) {
    return await resolveItem<ApiCloudTokenAuth>(
      this._getApiUrl('/v2/cloud/api-token-auth'),
      apiToken,
      undefined,
      'POST'
    );
  }

  /**
   * Returns an applications staging tiers.
   * @param apiToken API Authorization Token
   * @param slugOrID Application slug or id, i.e. 'utomik-test-app' or 5 for Utomik Test Application.
   * @returns ApiStagingTier[]
   */
  public async getApplicationStagingtiers(apiToken: string, slugOrID: string | number) {
    return await resolveItem<ApiStagingTier[], never>(
      this._getApiUrl(`/v2/applications/${slugOrID}/stagingtiers`),
      apiToken
    );
  }

  /**
   * Returns the default application version out of a set of stagingtiers.
   * It will seach for 'Live', if that is not available, the first item in the array is returned, which has an application version assigned to it.
   * May return undefined if no default application version could be determined.
   * @param stagingTiers ApiStagingTier[] (see `getApplicationStagingtiers()`)
   * @returns ApiApplicationVersion
   */
  public getDefaultApplicationVersion(stagingTiers: ApiStagingTier[]): ApiApplicationVersion | undefined {
    // Select default application tier, 'Live'.
    const defaultVersion = stagingTiers.find((stagingTier) => {
      return stagingTier.name === 'Live';
    })?.current_applicationversion;
    // Make sure it has an application version.
    if (defaultVersion) return defaultVersion;
    // Otherwise we iterate through the available staging tiers in order, and select the first available application version.
    for (let i = 0; i < stagingTiers.length; i++) {
      const current = stagingTiers[i].current_applicationversion;
      if (current) return current;
    }
    // Verbose case if no application version could be determined.
    // This should only happen on development titles, if that were not the case, contact support.
    return undefined;
  }

  /**
   * Get recommmended application version, usually from the live staging tier.
   *
   * Note: If you want to use a specific staging tier, you can use `getDefaultApplicationVersion()`, with that staging tier.
   * @param slugOrID The application ID or slug
   * @param apiToken The API token
   * @returns        The recommended application version.
   */
  public async getRecommendedApplicationVersion(
    slugOrID: string | number,
    apiToken: string
  ): Promise<ApiApplicationVersion | undefined> {
    const _stagingTiers = (await this.getApplicationStagingtiers(apiToken, slugOrID)) || [];

    // Proceeds through the default application version selection.
    return this.getDefaultApplicationVersion(_stagingTiers);
  }

  /**
   * Returns a server group.
   * @param apiToken API Authorization Token
   * @returns ApiServerGroup[]
   */
  public async getServerGroups(apiToken: string) {
    return await resolveItem<ApiServerGroup[], never>(this._getApiUrl(`/v2/cloud/servergroups`), apiToken);
  }

  /**
   * Returns a gateway has resource.
   * @param cloudToken Cloud Authorization Token (See `getCloudToken()`)
   * @param gateway ApiGateway
   * @returns ApiGatewayHasResource
   */
  public async getGatewayResource(cloudToken: string, gateway: ApiGateway) {
    return await resolveItem<ApiGatewayHasResource>(
      this._getGatewayUrl(gateway.address, '/mgtm/server/has-resource'),
      undefined,
      cloudToken
    );
  }

  /**
   * Returns a gateway queue length.
   * @param cloudToken Cloud Authorization Token (See `getCloudToken()`)
   * @param gateway ApiGateway
   * @returns The length of the queue
   */
  public async getGatewayQueueLen(cloudToken: string, gateway: ApiGateway): Promise<number> {
    const queue = await resolveItem<ApiGatewayQueue>(
      this._getGatewayUrl(gateway.address, '/queue/length'),
      undefined,
      cloudToken
    );
    return queue.predictedQueue;
  }

  /**
   * With the provided gateway a websocket session is created, and a message is send 5 times.
   * 5 times we repeat sending messages and calculate the difference between the current time and the time when user starts pinging.
   * When this process takes too long a timeout (2s) will resolve the promise with the (_WORST_PING) value.
   * @param cloudToken Cloud Authorization Token. (See `getCloudToken()`)
   * @param gateway ApiGateway
   * @returns Ping in ms
   */
  public async calculateGatewayPing(cloudToken: string, gateway: ApiGateway): Promise<number> {
    const currentWss = new WebSocket(`wss://${gateway.address.split('//')[1]}/?${cloudToken}&ping=1`);
    type WssMessage = {
      type: string;
      action: string;
      value: number;
    };
    return new Promise<number>((resolve, reject) => {
      let sendCount = 0;
      let wssMessage: WssMessage | undefined;
      const timeout = setTimeout(() => {
        console.log(`Gateway ${gateway.name} timeout occurred, reporting ping: ${this._WORST_PING_MS}ms`);
        // If the request is too long we return "HI" ping value to not remove that gateway from the list
        resolve(this._WORST_PING_MS);
        currentWss.close();
      }, 2000);

      currentWss.onopen = () => {
        wssMessage = {
          type: 'settings',
          action: 'ping',
          value: new Date().getTime(),
        };
        currentWss.send(JSON.stringify(wssMessage));
      };

      currentWss.onmessage = (evt) => {
        if (sendCount < 4) {
          currentWss.send(JSON.stringify(wssMessage));
          sendCount++;
        } else {
          const delta = Math.ceil((new Date().getTime() - JSON.parse(evt.data).value) / 5);
          clearTimeout(timeout);
          currentWss.close();
          resolve(delta);
        }
      };

      currentWss.onerror = () => {
        reject(-1);
      };
    });
  }

  /**
   * Reduces ApiServerGroup[] to an array of their ApiGateway[]
   * @param serverGroups ApiServerGroup[]
   * @returns ApiGateway[]
   */
  public reduceToGateways(serverGroups: ApiServerGroup[]) {
    return serverGroups.reduce<ApiGateway[]>((acc, serverGroup) => {
      return [...acc, ...serverGroup.gateways];
    }, []);
  }

  /**
   * Filters out gateways without resources.
   * @param cloudToken Cloud Authorization Token (See `getCloudToken()`)
   * @param gateways ApiGateway[]
   * @returns ApiGateway[]
   */
  public async filterGatewaysWithoutResources(cloudToken: string, gateways: ApiGateway[]): Promise<ApiGateway[]> {
    const hasResources = await Promise.allSettled(
      gateways.map(async (gateway) => {
        const resource = await this.getGatewayResource(cloudToken, gateway);
        return { gateway, resource };
      })
    );

    type GatewayResourceResult = {
      status: 'fulfilled';
      value: { gateway: ApiGateway; resource: ApiGatewayHasResource };
    };

    return hasResources
      .filter((result): result is GatewayResourceResult => result.status === 'fulfilled' && result.value.resource.has)
      .map((result) => result.value.gateway);
  }

  /**
   * Returns a struct which includes the queue and ping, calculating those in parallel. If any promise fails for one gateway, that gateway will be excluded from this list.
   * @param cloudToken Cloud Authorization Token (See `getCloudToken()`)
   * @param gateways ApiGateway[]
   * @returns ApiGateway, ApiGatewayQueue and ping.
   */
  public async getGatewaysData(cloudToken: string, gateways: ApiGateway[]) {
    type GatewayDataResult = {
      status: 'fulfilled';
      value: GatewayWithData;
    };

    const gatewaysWithData: PromiseSettledResult<Awaited<Promise<GatewayWithData>>>[] = await Promise.allSettled(
      gateways.map(async (gateway) => {
        const [predictedQueue, ping] = await Promise.all([
          this.getGatewayQueueLen(cloudToken, gateway),
          this.calculateGatewayPing(cloudToken, gateway),
        ]);
        /**
         * Left the queueLength for compatibility due to deprecation (See ApiGatewayQueue type)
         * */
        return { gateway, queueLength: predictedQueue, predictedQueue, ping };
      })
    );

    return gatewaysWithData
      .filter((result): result is GatewayDataResult => result.status === 'fulfilled')
      .map((result) => result.value);
  }

  /**
   * Calculates and adds the weight value to GatewaysWithData, then sorts it.
   * @param gatewaysWithData GatewaysWithData can be obtained using getGatewaysData.
   * @returns GatewayWithWeight[]
   */
  private _calculateGatewayWeightAndSort(gatewaysWithData: GatewayWithData[]) {
    const result = gatewaysWithData.reduce<GatewayWithWeight[]>((acc, cur) => {
      return [
        ...acc,
        {
          ...cur,
          weight:
            Math.exp((cur.ping ?? this._WORST_PING_MS) / this._IDEAL_PING_MS) +
            Math.exp(cur.predictedQueue / this._IDEAL_QUEUE),
        },
      ];
    }, []);

    return result.sort((a, b) => (a.weight ?? 0) - (b.weight ?? 0));
  }

  /**
   * Returns GatewayWithWeight. If no list of gateways is provided, it will try to fetch them if you also provide an apiToken. Otherwise, you'll get an empty array.
   * @param cloudToken Cloud Authorization Token (See `getCloudToken()`)
   * @param apiToken (Optional) API Authorization Token
   * @param gateways (Optional) ApiGateway[]
   * @returns GatewayWithWeight[]
   */
  public async getWeightedGateways(cloudToken: string, apiToken?: string, gateways?: ApiGateway[]) {
    return this._calculateGatewayWeightAndSort(
      await this.getGatewaysData(
        cloudToken,
        Array.isArray(gateways) ? gateways : apiToken ? this.reduceToGateways(await this.getServerGroups(apiToken)) : []
      )
    );
  }

  /**
   * Extends getWeightedGateways, if the gateways list ends up being empty then it will throw error 'CANNOT_RECOMMEND_GATEWAY'
   * @param cloudToken Cloud Authorization Token (See `getCloudToken()`)
   * @param apiToken (Optional) API Authorization Token
   * @param gateways (Optional) ApiGateway[]
   * @returns GatewayWithWeight
   */
  public async getRecommendedGateway(cloudToken: string, apiToken?: string, gateways?: ApiGateway[]) {
    const weightedGateways = await this.getWeightedGateways(cloudToken, apiToken, gateways);
    if (weightedGateways.length > 0) return weightedGateways[0];
    else throw new Error('CANNOT_RECOMMEND_GATEWAY');
  }

  /**
   * Detects wheter or not the Keyboard Lock API is supported by the Browser.
   * https://developer.mozilla.org/en-US/docs/Web/API/Keyboard/lock
   *
   * This API allows us to change the default unlock gesture of the Pointer Lock API into
   * a long press where the 'Escape' key must be pressed and held for a few seconds to
   * unlock the pointer lock.
   * @returns true when Pointer Lock API is supported, otherwise false is returned.
   */
  public isKeyboardLockSupportedByBrowser(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nav: any = navigator;
    const supportsKeyboardLock = 'keyboard' in navigator && 'lock' in nav.keyboard;
    return supportsKeyboardLock;
  }

  /**
   * Detects wheter or not the Pointer Lock API is supported by the Browser.
   * https://developer.mozilla.org/en-US/docs/Web/API/Pointer_Lock_API
   *
   * The Pointer Lock API allows us to capture the cursor.
   * @returns true when Pointer Lock API is supported, otherwise false is returned.
   */
  public isPointerLockSupportedByBrowser(): boolean {
    const supportsPointerLock =
      'pointerLockElement' in document || 'mozPointerLockElement' in document || 'webkitPointerLockElement' in document;
    return supportsPointerLock;
  }

  /**
   * Detects wheter or not Browser APIs used by the stream-view component are supported by the Browser.
   * @returns true when all APIs are supported, otherwise false is returned.
   * @see `isKeyboardLockSupportedByBrowser()` and `isPointerLockSupportedByBrowser()` for more specific checks.
   */
  public isSupportedByBrowser(): boolean {
    return this.isKeyboardLockSupportedByBrowser() && this.isPointerLockSupportedByBrowser();
  }
}
