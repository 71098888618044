import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { MyListStoreContext } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { Channel } from '../channel/channel';
export const MyList = withFocusable()(observer(() => {
  const myListStore = useContext(MyListStoreContext);
  useEffect(() => {
    myListStore.initialize();
  }, []);
  return <Channel name={t({
    message: 'My list'
  })} type={'mylist'} />;
}));