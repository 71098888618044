import * as React from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Application, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { NavigationControllerContext, TokenManagerContext } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import { ApiApplicationVersion, GatewayWithWeight } from '@utomik-app-monorepo/stream-view';
import { setLimitedTransformValues, sizeRemToPx } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { FocusableButton } from '../../focusable-button/focusable-button';
import { Loading } from '../../loading/loading';
import './gateways-select-dialog.scss';
type FocusableVersionSelectorProps = {
  item: Application;
};
const FocusableVersionSelector = withFocusable()<FocusableVersionSelectorProps>(observer(function FocusableVersionSelector() {
  const containerRef = useRef<HTMLDivElement>(null);
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const selectedVersionIdx = streamViewUtilsService.stagingTiers?.findIndex(tier => streamViewUtilsService.currentAppVersion ? tier.current_applicationversion.version === streamViewUtilsService.currentAppVersion.version : tier.current_applicationversion.version === 'Live');
  const handleSelectVersion = (appVersion: ApiApplicationVersion) => {
    streamViewUtilsService.setCurrentAppVersion(appVersion);
  };
  const handleFocus = props => {
    setLimitedTransformValues(containerRef, props, true, 5, 3);
  };
  return <div className={'versions-container'}>
        <div className={'versions-container--scrollable'} ref={containerRef}>
          {streamViewUtilsService.isPreFetched && streamViewUtilsService.stagingTiers.map((tier, idx) => <FocusableButton onEnterPress={() => handleSelectVersion(tier.current_applicationversion)} isActive={idx === selectedVersionIdx} key={tier.id} type={'tab-button'} onBecameFocused={handleFocus}>
                {`${tier.name} - ${tier.current_applicationversion.version}`}
              </FocusableButton>)}
        </div>
      </div>;
}));
const FocusableListItem = withFocusable()<{
  onClick: () => void;
}>(observer(function FocusableListItem({
  children,
  focused,
  setFocus,
  onClick
}) {
  const handleMouseOver = () => {
    setFocus();
  };
  return <div onMouseOver={handleMouseOver} onClick={onClick} className={cx('gateways-scrollable-list-item', {
    'gateways-scrollable-list-item--focus': focused
  })}>
        <p>{children}</p>
      </div>;
}));
type Props = {
  item?: Application;
  setFocus?: (key: string) => void;
  action: (action: DialogResult, value?: unknown) => void;
};
export const GatewaysSelectDialog: React.FC<Props> = withFocusable()(observer(function GatewaysSelectDialog({
  setFocus,
  item,
  action
}: Props) {
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const tokenManager = useContext(TokenManagerContext);
  const navigationController = useContext(NavigationControllerContext);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await item?.fetch();
    })();
  }, [item]);
  useEffect(() => {
    if (item?.id) {
      streamViewUtilsService.dispose();
      streamViewUtilsService.prefetch(item?.id);
    }
  }, [item]);
  useEffect(() => {
    if (streamViewUtilsService.isPreFetched) {
      setFocus('LIST_ITEM_1');
    }
  }, [streamViewUtilsService.isPreFetched]);
  const handleItemPress = async (data: GatewayWithWeight) => {
    const isValidToken = await tokenManager.checkToken();
    if (!isValidToken) return tokenManager.logout();
    streamViewUtilsService.setCurrentGateway(data);
    navigationController.setCurrentId(item.id);
    navigationController.setAllowedPlayPress(true);
    navigationController.playGame(navigate).catch(e => console.log(e));
  };
  const handleClose = () => {
    action(DialogResult.Cancel);
    streamViewUtilsService.dispose();
  };
  const handleFocusedItem = props => {
    containerRef.current?.scrollTo({
      top: props.y - sizeRemToPx(20),
      behavior: 'smooth'
    });
  };
  return <div className={'scrollable__wrapper'}>
        <h1>{`Select a server to play`}</h1>
        <h3>{`GameName: ${item.name}   >>//>>   GameID: ${item.id}`}</h3>
        <FocusableButton onEnterPress={handleClose} autofocus className={'focusable-close-button'} type={'close'} />
        <FocusableVersionSelector item={item} />

        <div className={'scrollable-container'} ref={containerRef}>
          {/*{Boolean(errorMessage) && (*/}
          {/*  <div className={'error-container'}>*/}
          {/*    <p className={'error-message'}>{errorMessage}</p>*/}
          {/*    /!*<FocusableButton autofocus type={'primary'} onEnterPress={handleRefresh}>*!/*/}
          {/*    /!*  {t('ACHIEVEMENTS_REFRESH')}*!/*/}
          {/*    /!*</FocusableButton>*!/*/}
          {/*  </div>*/}
          {/*)}*/}
          {!streamViewUtilsService.isPreFetched && <Loading classNames={['loading-gateways']} size={'small'} loadingText={'Loading servers...'} />}
          {streamViewUtilsService.isPreFetched && streamViewUtilsService.gateways.map((item, idx) => item?.gateway ? <FocusableListItem onClick={handleItemPress.bind(null, item)} key={item.gateway.address} focusKey={`LIST_ITEM_${idx + 1}`} onEnterPress={handleItemPress.bind(null, item)} onBecameFocused={handleFocusedItem}>
                  {item.gateway.name} - ping: {item.ping}ms, predictedQueue: {item.predictedQueue}, weight:{' '}
                  {item.weight.toFixed(2)}
                </FocusableListItem> : undefined)}
        </div>
      </div>;
}));
export default GatewaysSelectDialog;