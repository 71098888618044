import { log } from '@utomik-app-monorepo/logger';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable } from 'mobx';

import {
  RequestHandle,
  RequestMethod,
  RequestPriority,
  RequestQueue,
  RequestStatus,
  RetryValue,
} from '../../requestQueue/requestQueue';
import { IHttpResponse } from '../../transports/httpTransport/httpTransport';

export enum AsyncObjectState {
  None,
  Error,
  Pending,
  Done,
}

export type ObjectID = number | string;

/**
 * Container for Object T that holds state, Object T and Error error.
 */
export abstract class AsyncObject<T> {
  /**
   * object ID (returned by API)
   * */
  @observable
  public id: ObjectID;

  @action
  public setId(id: ObjectID): void {
    this.id = id;
  }

  @observable
  protected reloadCount = 0;

  protected _requestPromise: Promise<IHttpResponse<T>>;

  /**
   * Object Container State, determines if i.e. application ins available.
   */
  @observable
  public state: AsyncObjectState;

  @action
  public setState(state: AsyncObjectState): void {
    this.state = state;
  }
  /**
   * Object of Error type.
   */
  @observable
  private _error: Error | null;

  @action
  public setError(error: Error): void {
    this._error = error;
  }
  /**
   * The request queue.
   */
  public requestQueue: RequestQueue;
  /**
   * The url used to get the object.
   */
  protected url: string;
  /**
   * A list of handles to requests that have been added to the request queue.
   */
  protected requestHandles: RequestHandle<T, Record<string, unknown>>[] = [];
  /**
   * How many times the request should retry before failing.
   */
  protected retries: RetryValue;
  /**
   * Default constructor.
   * @param requestQueue The request queue used to fetch the object.
   * @param id The object's unique id.
   * @param url The api endpoint url.
   * @param object Dunno.
   * @param retries How many times to retry if request fails.
   */
  public constructor(requestQueue: RequestQueue, id?: ObjectID, url?: string, object?: T, retries?: RetryValue) {
    this.url = url;
    this.requestQueue = requestQueue;
    this.retries = retries;
    makeObservable(this);
    this.setId(id);
    this.setState(!isNullOrUndefined(object) ? AsyncObjectState.Done : AsyncObjectState.None);
    this.setError(null);
  }

  /** Load function to be implemented for the type that this object loads.*/
  protected abstract load(object: T): void;

  /** TODO: Force derived classes to implement an unload.
  //protected abstract unload(): void;

  /**
   * Gets the error message as string. Handles object state and does a typeguard for you.
   */
  @computed
  public get errorMessage(): string {
    if (this.state !== AsyncObjectState.Error) {
      return '';
    } else if (isNullOrUndefined(this._error)) {
      return 'UNKNOWN_ERROR';
    } else {
      return this._error.message;
    }
  }

  public abortFetch(): void {
    for (const requestHandle of this.requestHandles) {
      this.requestQueue.remove(requestHandle);
      log(`abortFetch(): ${requestHandle.url}`);
    }
    this.requestHandles.length = 0;
  }

  /**
   * Add the request to the requestQueue.
   * @param url - The URL to queue.
   * @param priority - The priority with which to queue the URL.
   */
  protected queueRequest(url: string, priority = RequestPriority.Medium): Promise<IHttpResponse<T>> {
    const onStatusChanged = (status: RequestStatus): void => {
      switch (status) {
        case RequestStatus.QueuedForRetry:
          this.setState(AsyncObjectState.Error);
          break;
        case RequestStatus.RetryingFailedRequest:
          this.setState(AsyncObjectState.Pending);
          break;
      }
    };
    const request = this.requestQueue.add<T, Record<string, unknown>>(
      url,
      {},
      priority,
      RequestMethod.Get,
      { headers: { 'Cache-Control': 'no-cache' } as any },
      this.retries,
      null,
      onStatusChanged
    );
    this.requestHandles.push(request);
    return request.promise;
  }

  /**
   * Fetches the result of the promise. If it's been resolved before, return the previous response.
   *
   * Promise<T | null>. It follows most stages of ObjectContainerState.
   * Sets Pending when there is activity.
   * Sets Done state and Object<T> when request has finished.
   * Sets Error state and Error object when error occurred.
   *
   * @param priority - The priority.
   */

  public async fetch(priority = RequestPriority.Medium): Promise<void> {
    if (this.state === AsyncObjectState.Done) {
      return;
    }

    return this.reFetch(priority);
  }

  /**
   * Works like regular fetch, except it refetches the data when the promise is already resolved
   * (which can be used to update the data of a previous fetch).
   *
   * Promise<T | null>. It follows most stages of ObjectContainerState.
   * Sets Pending when there is activity.
   * Sets Done state and Object<T> when request has finished.
   * Sets Error state and Error object when error occurred.
   */
  public async reFetch(priority = RequestPriority.Medium, removeLimit = false) {
    let promise = this._requestPromise;

    this.setState(AsyncObjectState.Pending);

    if (removeLimit) {
      this.url = this.url.replace(/.limit=\d+/, '');
    }

    if (!promise) {
      promise = this.doFetch(this.url, priority); // Most of the heavy lifting is done here.
    }
    try {
      const response = await promise;
      if (this.state === AsyncObjectState.Done || !response?.data) return; //handled by someone else!
      this.load(response.data);
      this.setState(AsyncObjectState.Done);
      // Disabled logger for performance/spam because it is called in high frequency.
      // logger.debug(`fetch(${this.url}) - Done`);
      return;
    } catch (error: any) {
      if (error?.message !== 'aborted') {
        console.error(`fetch(${this.url}) - Error: ${error}`);
      }
      this.setError(error);
      this.setState(AsyncObjectState.Error);
      throw error;
    }
  }

  // This allows child classes to overload fetch, and handle the response in their own way!
  protected async doFetch(url?: string, priority = RequestPriority.Medium): Promise<IHttpResponse<T>> {
    if (!isNullOrUndefined(url)) this.url = url;
    if (isNullOrUndefined(this.url)) {
      log(`doFetch() url undefined!`);
      return Promise.reject('doFetch() url undefined!');
    }

    this.setState(AsyncObjectState.Pending);

    try {
      this._requestPromise = this.queueRequest(this.url, priority);
      const apiResponse = await this._requestPromise;
      this.setError(null);
      return apiResponse;
    } catch (error: any) {
      this.setError(error);
      this.setState(AsyncObjectState.Error);
      throw error; //rethrow...
    } finally {
      this.requestHandles.length = 0;
      this._requestPromise = null;
    }
  }
}
