import { faAnglesDown, faAnglesUp, faArrowLeft, faArrowRight, faCheck, faDeleteLeft, faUpLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { KeyboardLayoutEntryList, customClearButtonKey, customVirtualBackspaceKey, customVirtualEnterKey, keyboardLayout } from '@utomik-app-monorepo/constants';
import { IKeyboardButtonBody, KeyboardButtonState, KeyboardControllerContext } from '@utomik-app-monorepo/store';
import { KeyboardPosition } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './focusable-keyboard.scss';
const additionalButtonsMap = {
  '{space}': {
    value: '<small>Space</small>',
    width: '100%'
  }
};
const positionButtonIconsMap = {
  [KeyboardPosition.Top]: <FontAwesomeIcon size={'2xs'} icon={faAnglesDown} />,
  [KeyboardPosition.Bottom]: <FontAwesomeIcon size={'2xs'} icon={faAnglesUp} />
};
const FocusableKeyboardButton = withFocusable()<{
  name?: string;
  width?: string;
  isEnabled?: boolean;
  fontSize?: string;
  code?: string;
  keyCode?: number;
  which?: number;
  shiftKey?: boolean;
  onClick?: (e: any) => void;
  element?: React.ReactNode;
}>(observer(function FocusableKeyboardButton(props) {
  const {
    name,
    width,
    isEnabled,
    focused,
    fontSize,
    onClick,
    setFocus,
    element
  } = props;
  return <div onMouseOver={() => setFocus()} onMouseDown={() => onClick(props)} style={{
    width: width,
    fontSize
  }} className={cx('focusable-keyboard--button', {
    'focusable-keyboard--button--focused': focused,
    'focusable-keyboard--button--enabled': isEnabled
  })}>
        {name || element}
      </div>;
}));
const CentralGrid: React.FC<{
  layout: KeyboardLayoutEntryList;
  onKeyPress: ({
    name,
    code,
    shiftKey,
    which,
    keyCode
  }: {
    name: string;
    code: string;
    shiftKey: boolean;
    which: number;
    keyCode: number;
  }) => void;
}> = observer(function CentralButtonsGrid({
  layout,
  onKeyPress
}) {
  return <div className={'central-buttons-grid'}>
      {layout.map((row, index) => {
      return <div key={`row_${row[index].key}`} className={'buttons-row'} style={{
        display: 'flex'
      }}>
            {row.map(({
          key,
          code,
          shiftKey,
          keyCode
        }) => {
          return <FocusableKeyboardButton focusKey={key + code} onEnterPress={onKeyPress} key={key + code} which={keyCode} name={key} code={code} keyCode={keyCode} width={additionalButtonsMap[key]?.width} shiftKey={shiftKey} onClick={onKeyPress} />;
        })}
          </div>;
    })}
    </div>;
});
export const FocusableKeyboard = withFocusable({
  blockNavigationOut: true,
  trackChildren: true
})(observer(function FocusableKeyboard({
  setFocus,
  hasFocusedChild
}) {
  const keyboardController = useContext(KeyboardControllerContext);
  useEffect(() => {
    if (keyboardController.isKeyboardVisible) {
      setFocus();
    }
  }, [keyboardController.isKeyboardVisible]);
  const currentLocale = keyboardLayout[keyboardController.currentLanguage];
  const layout = currentLocale[keyboardController.mode];
  const handleChangeMode = ({
    code
  }: {
    code: string;
  }) => {
    if (!hasFocusedChild) return;
    if (code === 'KeyboardModeSwitchKey') {
      keyboardController.switchMode('capsLock');
    } else if (code === 'SymbolsSwitchKey') {
      keyboardController.switchMode('symbols');
    }
  };
  const handleKeyPress = (ev: IKeyboardButtonBody) => {
    if (!hasFocusedChild) return;
    keyboardController.handleKeyPress(ev);
    keyboardController.handleKeyRelease(ev);
  };
  const handleBackspace = () => {
    if (!hasFocusedChild) return;
    keyboardController.handleKeyPress({
      key: customVirtualBackspaceKey,
      code: customVirtualBackspaceKey
    });
    keyboardController.handleKeyRelease({
      key: customVirtualBackspaceKey,
      code: customVirtualBackspaceKey
    });
  };
  const handleEnter = () => {
    if (!hasFocusedChild) return;
    keyboardController.handleKeyPress({
      key: customVirtualEnterKey,
      code: customVirtualEnterKey
    });
    keyboardController.handleKeyRelease({
      key: customVirtualEnterKey,
      code: customVirtualEnterKey
    });
  };
  const handleClear = () => {
    if (!hasFocusedChild) return;
    document.activeElement.dispatchEvent(new KeyboardEvent('keydown', {
      key: customClearButtonKey
    }));
  };
  const handleChangePosition = () => {
    if (!hasFocusedChild) return;
    keyboardController.switchPosition();
  };
  const handleMoveCaretLeft = () => {
    if (!hasFocusedChild) return;
    keyboardController.handleKeyPress({
      key: 'ArrowLeft',
      code: 'ArrowLeft'
    });
    keyboardController.handleKeyRelease({
      key: 'ArrowLeft',
      code: 'ArrowLeft'
    });
  };
  const handleMoveCaretRight = () => {
    if (!hasFocusedChild) return;
    keyboardController.handleKeyPress({
      key: 'ArrowRight',
      code: 'ArrowRight'
    });
    keyboardController.handleKeyRelease({
      key: 'ArrowRight',
      code: 'ArrowRight'
    });
  };
  const keyboardElement = <div className={cx('focusable-keyboard', {
    [`focusable-keyboard--visible--${keyboardController.position}`]: keyboardController.isKeyboardVisible
  })} tabIndex={0} onMouseDown={e => {
    e.preventDefault();
  }}>
        <div className={'focusable-keyboard--left-grid'}>
          <FocusableKeyboardButton element={<small>{keyboardController.currentLanguage?.toUpperCase()}</small>} width={'12rem'} onEnterPress={keyboardController.switchLanguage} onClick={keyboardController.switchLanguage} />
          <FocusableKeyboardButton element={<small>!@#</small>} width={'12rem'} isEnabled={keyboardController.mode === 'symbols'} onEnterPress={handleChangeMode} onClick={handleChangeMode} code={'SymbolsSwitchKey'} />
          <FocusableKeyboardButton isEnabled={keyboardController.shift === KeyboardButtonState.Pressed} element={<FontAwesomeIcon size={'sm'} icon={faUpLong} />} width={'12rem'} onEnterPress={handleChangeMode} onClick={handleChangeMode} code={'KeyboardModeSwitchKey'} />
          <FocusableKeyboardButton element={positionButtonIconsMap[keyboardController.position]} width={'12rem'} onEnterPress={handleChangePosition} onClick={handleChangePosition} fontSize={'3rem'} />
        </div>
        <div className={'focusable-keyboard--central-grid'}>
          <CentralGrid layout={layout} onKeyPress={({
        name,
        code,
        shiftKey,
        which,
        keyCode
      }) => handleKeyPress({
        key: name,
        code,
        shiftKey,
        which,
        keyCode
      })} />
          <FocusableKeyboardButton onEnterPress={() => handleKeyPress({
        key: ' ',
        code: 'Space',
        shiftKey: false,
        which: 32,
        keyCode: 32
      })} onClick={() => handleKeyPress({
        key: ' ',
        code: 'Space',
        shiftKey: false,
        which: 32,
        keyCode: 32
      })} element={<small>
                <Trans context={'The space key on a virtual onscreen keyboard'}>Space</Trans>
              </small>} width={'99%'} />
        </div>
        <div className={'focusable-keyboard--right-grid'}>
          <FocusableKeyboardButton element={<FontAwesomeIcon size={'sm'} icon={faDeleteLeft} />} width={'10.5rem'} onEnterPress={handleBackspace} onClick={handleBackspace} />
          <FocusableKeyboardButton element={<small>
                <Trans context={'The "Clear" key on a virtual onscreen keyboard. Clears an input'}>Clear</Trans>
              </small>} width={'10.5rem'} onEnterPress={handleClear} onClick={handleClear} />
          <FocusableKeyboardButton element={<FontAwesomeIcon icon={faCheck} />} width={'10.5rem'} onEnterPress={handleEnter} onClick={handleEnter} />
          <div className={'arrows__container'}>
            <FocusableKeyboardButton onEnterPress={handleMoveCaretLeft} onClick={handleMoveCaretLeft} element={<FontAwesomeIcon size={'xs'} icon={faArrowLeft} />} />
            <FocusableKeyboardButton onEnterPress={handleMoveCaretRight} onClick={handleMoveCaretRight} element={<FontAwesomeIcon size={'xs'} icon={faArrowRight} />} />
          </div>
        </div>
      </div>;
  return ReactDOM.createPortal(keyboardElement, document.body);
}));