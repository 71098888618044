import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { ApiApplicationAgeRating, ApplicationAgeRating } from '../applicationStore/application';
import { AsyncObjectState } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';
import { AgeRatingSystem, ApiAgeRatingSystem } from './ageRatingSystem';

export class AgeRatingSystemStore extends ObjectStore<AgeRatingSystem, ApiAgeRatingSystem> {
  public constructor(queue: RequestQueue, url = 'v1/ageratingsystems') {
    super(queue, url);
    makeObservable(this);
  }

  @observable
  private _fetchAllstate = AsyncObjectState.None;
  @computed
  public get fetchAllState(): AsyncObjectState {
    return this._fetchAllstate;
  }
  @action
  private setFetchAllState(state: AsyncObjectState): void {
    this._fetchAllstate = state;
  }

  public async fetchAll(): Promise<void> {
    this.setFetchAllState(AsyncObjectState.Pending);
    try {
      await super.fetch();
      this.setFetchAllState(AsyncObjectState.Done);
    } catch (e) {
      this.setFetchAllState(AsyncObjectState.Error);
      throw e;
    }
  }

  protected createAndLoadItem(apiAgeRatingSystem: ApiAgeRatingSystem): AgeRatingSystem {
    return AgeRatingSystem.parse(apiAgeRatingSystem);
  }

  public getAppAgeRatingSystems(apiAppAgeRatingSystems: ApiApplicationAgeRating[]): ApplicationAgeRating[] {
    return (apiAppAgeRatingSystems || [])?.reduce<ApplicationAgeRating[]>(
      (appAgeRatingSystems: ApplicationAgeRating[], apiAppAgeRatingSystem: ApiApplicationAgeRating) => {
        // ageRatingSystem
        if (!this.itemsMap.size) return [];

        const ageRatingSystem = this.itemsMap.get(apiAppAgeRatingSystem.ageratingsystem.id);

        // Abort this one if we can't find the ageRatingSystem.
        if (isNullOrUndefined(ageRatingSystem)) {
          console.error(
            `getAppAgeRatingSystems: AgeRatingSystem not found, ID: ${apiAppAgeRatingSystem.ageratingsystem.id}`
          );
          return null;
        }

        // ageRating
        const ageRating = ageRatingSystem.ageRating.find((item) => {
          return item.id === apiAppAgeRatingSystem.agerating.id;
        });
        ageRating.imageUrl = apiAppAgeRatingSystem.agerating.image_url;
        // ageRating
        const contentDescriptors = ageRatingSystem.contentDescriptors.filter((contentDescriptor) => {
          let found = false;
          apiAppAgeRatingSystem.contentdescriptors.forEach((element) => {
            if (element.id == contentDescriptor.id) found = true;
          });
          return found;
        });

        // appAgeRatingSystem
        const appAgeRatingSystem: ApplicationAgeRating = {
          ageRatingSystem: ageRatingSystem,
          ageRating: ageRating,
          contentDescriptors: contentDescriptors,
        };

        appAgeRatingSystems.push(appAgeRatingSystem);
        return appAgeRatingSystems;
      },
      []
    );
  }
}
