import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useMouseSideScroll } from '@utomik-app-monorepo/hooks';
import { DialogQueueContext, MediaPlayerControllerContext, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { ApplicationMedia } from '@utomik-app-monorepo/store';
import { repeatFnCall } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef } from 'react';
import { GamepadButtons } from '../GamepadButtons/GamepadButtons';
import { MediaTileItem } from '../media-tile-item/media-tile-item';
import { SimpleButton } from '../simple-button/simple-button';
import './media-tiles-section.scss';

/**
 * Component renders Section of tiles. Tiles lists are focusable.
 * */

type Props = {
  data: ApplicationMedia[];
  setFocus?: (id: string) => void;
  onBecameFocused?: (props: any, itemProps?: any) => void;
  focusKey?: string;
  name?: string;
  hasMouseOver?: boolean;
  className?: string;
  scrollType?: 'transform' | 'native';
  title?: string;
};
export const MediaTilesSection = withFocusable({
  trackChildren: true
})<Props>(observer(function MediaTilesSection({
  data,
  navigateByDirection,
  hasFocusedChild,
  setFocus,
  hasMouseOver,
  className,
  scrollType = 'transform',
  title
}) {
  const mediaPlayerController = useContext(MediaPlayerControllerContext);
  const navigationController = useContext(NavigationControllerContext);
  const dialogQueue = useContext(DialogQueueContext);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const scrollableWrapperRef = useRef<HTMLDivElement>(null);
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const forwardButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (scrollType !== 'native') return;
    const scrollableContainerRefLocal = scrollableContainerRef.current;
    const handleScroll = () => {
      if (scrollableContainerRefLocal.scrollLeft === 0) {
        backButtonRef.current.disabled = true;
      } else {
        backButtonRef.current.disabled = false;
      }
      if (scrollableContainerRefLocal.scrollLeft >= scrollableContainerRefLocal.scrollWidth - scrollableContainerRefLocal.clientWidth) {
        forwardButtonRef.current.disabled = true;
      } else {
        forwardButtonRef.current.disabled = false;
      }
    };
    handleScroll();
    scrollableContainerRefLocal?.addEventListener('scroll', handleScroll);
    return () => scrollableContainerRefLocal?.removeEventListener('scroll', handleScroll);
  }, [data]);
  const handlePrev = () => {
    scrollableContainerRef.current?.scrollTo({
      left: scrollableContainerRef.current.scrollLeft - window.innerWidth,
      behavior: 'smooth'
    });
  };
  const handleNext = () => {
    scrollableContainerRef.current?.scrollTo({
      left: scrollableContainerRef.current.scrollLeft + window.innerWidth,
      behavior: 'smooth'
    });
  };
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    const handler = ({
      name
    }) => {
      if (name === 'MediaViewerDialog') {
        intervalId = repeatFnCall(setFocus.bind(null, String(mediaPlayerController.currentItem.id)));
      }
    };
    // set the current tile focus after the Media Player dialog is closed
    dialogQueue.on('removeDialog', handler);
    //dispose the controller on unmount
    return () => {
      dialogQueue.off('removeDialog', handler);
      mediaPlayerController?.dispose();
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      //set media for the Media Played Dialog
      mediaPlayerController.setMedia(data);
      if (!scrollableContainerRef.current) return;
      if (scrollType === 'transform') {
        scrollableContainerRef.current.style.transform = `translateX(0)`;
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data]);
  const handleFocus = props => {
    if (!scrollableContainerRef.current) return;
    //horizontal centering
    if (scrollType === 'transform') {
      scrollableContainerRef.current.style.transform = `translateX(${-props.x}px)`;
    } else if (scrollType === 'native') {
      scrollableWrapperRef.current.scrollTo({
        left: props.x,
        behavior: 'smooth'
      });
    }
  };
  const handleEnterPress = props => {
    //open the media player dialog
    mediaPlayerController.open(props.index);
  };
  const handleMouseTranslate = useMouseSideScroll(scrollableContainerRef, navigateByDirection);
  return <div className={cx('media-tile-section', {
    'media-tile-section--focused': hasFocusedChild && scrollType !== 'native',
    [className]: !!className
  })} ref={scrollableWrapperRef}>
        {scrollType === 'native' && <SimpleButton ref={backButtonRef} className={'prev-media-button'} onClick={handlePrev} kind={'back'} />}
        {scrollType === 'transform' && <div className={'gamepad-buttons__container'}>
            <GamepadButtons type={'round'} name={'A'} label={'Fullscreen'} />
          </div>}
        {scrollType === 'transform' && <div className={'left-dummy'} onMouseEnter={() => handleMouseTranslate(true, 'left')} onMouseOut={() => handleMouseTranslate(false, 'left')} />}
        <div className={'scrollable-container'} ref={scrollableContainerRef}>
          {data.map((item, index) => <MediaTileItem showOutline={navigationController.isGamepadInteraction && scrollType === 'native'} scaleOnFocus={scrollType === 'transform'} key={item.id} focusKey={String(item.id)} item={item} onEnterPress={handleEnterPress} onBecameFocused={handleFocus} index={index} hasMouseOver={hasMouseOver} />)}
        </div>
        {scrollType === 'transform' && <div className={'right-dummy'} onMouseEnter={handleMouseTranslate.bind(null, true, 'right')} onMouseOut={handleMouseTranslate.bind(null, false, 'right')} />}
        {scrollType === 'native' && <SimpleButton ref={forwardButtonRef} className={'next-media-button'} onClick={handleNext} kind={'forward'} />}
      </div>;
}));