import { action, computed, makeObservable, observable } from 'mobx';
import { QueueState } from '../streamTypes';

export default class QueueController {
  private _queueCheckInterval: NodeJS.Timeout | string | number | undefined;
  private readonly _queueInfoCb: () => void;

  @observable
  private _queue: QueueState = { position: null, isQueue: false, time: null };
  @action
  private _setQueue(queue: QueueState) {
    //console.debug(`QueueController`, `_setQueue`, queue);
    this._queue = queue;
  }
  @computed
  public get queue() {
    return this._queue;
  }

  /**
   * Ctor
   * @param queueInfoCb Queue Info Callback, called periodically when in queue.
   */
  public constructor(queueInfoCb: () => void) {
    this._queueInfoCb = queueInfoCb;
    makeObservable(this);
  }

  /**
   * Reset the controller.
   */
  public reset() {
    this._stopQueueCheckInterval();
    this._setQueue({ position: null, isQueue: false, time: null });
  }

  /**
   * Set queue, this also resets the queue check interval timer.
   * @param queue
   */
  public setQueue(queue: QueueState) {
    this._setQueue(queue);
    /**
     * Set interval to ping WS channel to prevent closing and get current queue position
     * */
    this._stopQueueCheckInterval();
    // Only check queue if in queue.
    if (queue.isQueue) {
      this._queueCheckInterval = setInterval(() => {
        console.debug(`QueueController`, `queueCheckInterval`);
        this._queueInfoCb();
      }, 15000);
    }
  }

  /**
   * Stop queue checking interval.
   */
  private _stopQueueCheckInterval() {
    clearInterval(this._queueCheckInterval);
  }
}
