import React, { useCallback, useEffect, useRef } from 'react';
export const useMouseSideScroll = (scrollableContainer: React.MutableRefObject<HTMLDivElement>, navigateByDirection: (direction: string) => void) => {
  const rafIdRef = useRef<ReturnType<typeof setInterval>>(null);
  useEffect(() => {
    return () => {
      clearInterval(rafIdRef.current);
    };
  }, []);
  return useCallback((isMouseOver: boolean, side?: 'left' | 'right' | 'top' | 'bottom') => {
    clearInterval(rafIdRef.current);
    if (isMouseOver) {
      const translateCarousel = () => {
        navigateByDirection(side);
        clearInterval(rafIdRef.current);
        rafIdRef.current = setInterval(translateCarousel, 400);
      };
      translateCarousel();
    }
  }, [rafIdRef.current, scrollableContainer.current]);
};