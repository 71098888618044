import { action, makeObservable } from 'mobx';

import { RequestQueue } from '../../../requestQueue/requestQueue';
import { ObjectID } from '../../objectStore/asyncObject';
import { ObjectStore } from '../../objectStore/objectStore';
import { ApiApplicationVersion, ApplicationVersion } from './applicationversion';

/**
 * Store to manage application versions.
 */
export class ApplicationVersionStore extends ObjectStore<ApplicationVersion, ApiApplicationVersion> {
  private readonly applicationId: ObjectID;

  public constructor(queue: RequestQueue, applicationId: ObjectID, url: string) {
    super(queue, url);
    makeObservable(this);
    this.applicationId = applicationId;
  }

  public createAndLoadItem(apiApplicationVersion: ApiApplicationVersion): ApplicationVersion {
    const item = this.getItem(apiApplicationVersion.id);
    item.load(apiApplicationVersion);
    return item;
  }

  /**
   * Gets item if it exist, else creates a new one.
   */
  @action
  public getItem(id: ObjectID): ApplicationVersion {
    const item = this.itemsMap.get(id) as ApplicationVersion;

    // Invalid ID's should be rejected.
    if ((typeof id === 'string' && id.length === 0) || (typeof id === 'number' && id < 1)) return null;

    if (item) {
      return item;
    }

    const applicationVersion = new ApplicationVersion(this, this.applicationId, id);
    this.itemsMap.set(id, applicationVersion);
    return applicationVersion;
  }
}
