import { enableRecCaching } from '@utomik-app-monorepo/constants';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { SecurityToken } from '../../securityToken/securityToken';
import { HttpTransport, IHttpConfig, IHttpResponse } from '../httpTransport/httpTransport';
import { NetworkException, NotYetImplementedException, TimeOutException } from '../httpTransport/utomikError';

export interface ErrorResponse {
  error: string;
  detail?: string;
  username?: string; // TODO: refactor this once the platform has the new error messages
  email?: string; // TODO: refactor this once the platform has the new error messages
}

export class AxiosTransport extends HttpTransport {
  /**
   * public for tests... -_- MEH!
   */
  public api: AxiosInstance;

  public constructor(host: string, securityToken: SecurityToken) {
    super(host, securityToken);

    this.api = axios.create({ baseURL: host, headers: { 'Accept-Language': 'de', 'X-Utomik-Rec-Caching': 'TRUE' } });
  }

  /**
   * Handle response object.
   * @param response Generic AxiosResponse object.
   */
  private handleResponse<T>(response: AxiosResponse<T>): IHttpResponse<T> {
    if (
      [
        200, // OK
        201, // OK, RESOURCE CREATED
        204, // OK, NO CONTENT
        210, // OK, UPDATE JWT
      ].indexOf(response.status) === -1
    ) {
      // Any unknown success responses will be logged to debug.
      console.error(`Request '${response.config.url}' returned unhandled code '${response.status}'`);
    }
    return response;
  }

  /**
   * See https://github.com/axios/axios#handling-errors for details on how to develop.
   * The generic error handler. This function -should- always throw an exception.
   * @param error This is the error response object.
   */
  private handleException(error: AxiosError<ErrorResponse>, endPointUrl: string): void {
    // Extract a few handy bits
    const { code = '', message = '', response } = error;
    const status = response?.status;
    const url: string = error.request?.url || endPointUrl || '(unknown url)';

    if (code === 'ECONNABORTED') {
      // Connection timeout!
      this.logError(url, 'Server', code, status, message);
      throw new TimeOutException();
    } else if (response) {
      // Sometimes, like when there are too many requests or when an endpoint doesn't exist, the data.error is undefined,
      // but the data.detail is filled with handy information.
      let errorMessage = response?.data?.error;
      if (isNullOrUndefined(errorMessage)) {
        errorMessage = response?.data?.detail;
      }
      // TODO: refactor this once the platform has the new error messages
      if (isNullOrUndefined(errorMessage)) {
        if (!isNullOrUndefined(response?.data?.username)) errorMessage = 'USER_NAME_TAKEN';
        else if (!isNullOrUndefined(response?.data?.email)) errorMessage = 'EMAIL_TAKEN';
      }
      // Transport received an error response (5xx, 4xx).
      this.logError(url, 'Server', code, status, errorMessage);
      this.handleError(status, errorMessage);
    } else if (error.request) {
      // Request sent but no response. Most likely no internet. Status codes are passed manually because they don't actually exist without a response.
      if (message === 'Network Error') {
        const response: AxiosResponse<ErrorResponse> = error.response;
        let errorMessage = response?.data?.error;
        if (isNullOrUndefined(errorMessage)) {
          errorMessage = response?.data?.detail;
        }
        // Let's try to keep all known cases in the same format as the API.
        this.logError(url, 'Network', code, status, errorMessage);
        throw new NetworkException('NETWORK_ERROR', status);
      } else {
        // Catch-all for errors not caught by above.
        this.logError(url, 'Network', code, status, message);
        throw new NetworkException(message, status);
      }
    } else {
      // Something happened in setting up the request that triggered an Error. Should really never occur.
      this.logError(url, 'Unknown', code, status, message);
      throw new NotYetImplementedException(`An unknown error has occurred: ${message}`);
    }
  }

  /**
   * Log in our defined format.
   * @param url Request URL
   * @param type Pre-defined error type
   * @param code Error code
   * @param status Request status
   * @param message Error message
   */
  private logError(
    url: string,
    type: 'Unknown' | 'Network' | 'Server',
    code: string,
    status: number = null,
    message: string
  ): void {
    // Let's not log falsey values, to help make logs more readable.
    // That includes "", 0, false, undefined, null, etc
    const i = [];
    if (type) i.push(`type: "${type}"`);
    if (code) i.push(`code: "${code}"`);
    if (status) i.push(`status: "${status}"`);
    const ii = i.length > 0 ? ` (${i.join(', ')})` : '';

    console.warn(`Request "${url}" failed${ii}: "${message}"`);
  }

  /**
   * getConfig is a helper that will create an IHttpConfig.
   * @param config Request configuration
   */
  private getConfig<D>(config?: IHttpConfig): AxiosRequestConfig<D> {
    if (config && config != this.defaultConfig) {
      this.defaultConfig = { ...this.defaultConfig, ...config };
    }

    // Default to default Config
    if (!isNullOrUndefined(this.defaultConfig) && !isNullOrUndefined(this.securityToken?.httpToken)) {
      this.defaultConfig.headers.Authorization = this.securityToken.httpToken;
    }

    if (!isNullOrUndefined(this.defaultConfig) && enableRecCaching) {
      this.defaultConfig.headers['X-Utomik-Rec-Caching'] = 'TRUE';
    }
    return this.defaultConfig;
  }

  public resetDefaultConfig(language: string): void {
    this.defaultConfig = { headers: { 'Accept-Language': language } as any };
  }

  /**
   * Do the actual request.
   *
   * @param requestCB - The request callback.
   */
  private async perform<Response>(requestCB: () => Promise<AxiosResponse<Response>>): Promise<AxiosResponse<Response>> {
    try {
      const result = await requestCB();

      switch (result.status) {
        case 210: // 210 a Utomik only extension on the HTTP protocol where the JWT has to be refreshed.
          await this.securityToken.refresh();
          return result;
        default:
          return result;
      }
    } catch (error: any) {
      // If the error we got a 401 with header stale=true or with signature has expired from the Platform,
      // we will try to refresh the token and then retry the request.
      if (
        error.response?.status == 401 &&
        ((error.response?.headers && error.response?.headers['www-authenticate']?.includes('stale="true"')) ||
          error.response?.data?.detail == 'Signature has expired.') &&
        this.securityToken?.isSet
      ) {
        await this.securityToken.refresh();
        return await requestCB();
      } else {
        throw error;
      }
    }
  }

  // Get from api endpoint and handle response.
  public async get<GetResponse>(endPointUrl: string, config?: IHttpConfig): Promise<IHttpResponse<GetResponse>> {
    try {
      const response = await this.perform<GetResponse>(() =>
        this.api.get<GetResponse, AxiosResponse<GetResponse>, never>(endPointUrl, this.getConfig<never>(config))
      );
      return this.handleResponse<GetResponse>(response);
    } catch (error: any) {
      this.handleException(error, endPointUrl);
    }

    return null;
  }

  // Delete from api endpoint and handle response.
  public async delete<DeleteResponse>(
    endPointUrl: string,
    config?: IHttpConfig
  ): Promise<IHttpResponse<DeleteResponse>> {
    try {
      return await this.perform(() =>
        this.api.delete<DeleteResponse, AxiosResponse<DeleteResponse>, never>(
          endPointUrl,
          this.getConfig<never>(config)
        )
      );
    } catch (error: any) {
      this.handleException(error, endPointUrl);
    }

    return null;
  }

  // Post to api endpoint and handle response.
  public async post<PostResponse, PostData>(
    endPointUrl: string,
    postData: PostData,
    config?: IHttpConfig
  ): Promise<IHttpResponse<PostResponse>> {
    try {
      const response = await this.perform<PostResponse>(() =>
        this.api.post<PostResponse, AxiosResponse<PostResponse>, PostData>(
          endPointUrl,
          postData,
          this.getConfig<PostData>(config)
        )
      );
      return this.handleResponse<PostResponse>(response);
    } catch (error: any) {
      this.handleException(error, endPointUrl);
    }

    return null;
  }

  // Put to api endpoint and handle response.
  public async put<PutResponse, PutData>(
    endPointUrl: string,
    putData: PutData,
    config?: IHttpConfig
  ): Promise<IHttpResponse<PutResponse>> {
    try {
      const response = await this.perform<PutResponse>(() =>
        this.api.put<PutResponse, AxiosResponse<PutResponse>, PutData>(endPointUrl, putData, this.getConfig(config))
      );
      return this.handleResponse<PutResponse>(response);
    } catch (error: any) {
      this.handleException(error, endPointUrl);
    }

    return null;
  }

  // Patch to api endpoint and handle response.
  public async patch<PatchResponse, PatchData>(
    endPointUrl: string,
    data: PatchData,
    config?: IHttpConfig
  ): Promise<IHttpResponse<PatchResponse>> {
    try {
      const response = await this.perform<PatchResponse>(() =>
        this.api.patch<PatchResponse, AxiosResponse<PatchResponse>, PatchData>(
          endPointUrl,
          data,
          this.getConfig(config)
        )
      );
      return this.handleResponse<PatchResponse>(response);
    } catch (error: any) {
      this.handleException(error, endPointUrl);
    }

    return null;
  }
}
