export type GetPersistentValue = <T>(name: string) => T | null;
export type SetPersistentValue = <T>(name: string, value: T) => void;
export type RemovePersistentValue = (name: string) => void;

export class PersistentStore {
  public get: GetPersistentValue;
  public set: SetPersistentValue;
  public remove: RemovePersistentValue;

  public constructor(get: GetPersistentValue, set: SetPersistentValue, remove: RemovePersistentValue) {
    this.get = get;
    this.set = set;
    this.remove = remove;
  }
}
