import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectStore } from '../objectStore/objectStore';
import { ApiUserStat, UserStat } from './userStat';

interface UserStats {
  gamesPlayed: number;
  totalSecondsPlayed: number;
  mostPlayed: UserStat;
  lastPlayed: UserStat;
}

export class UserStatsStore extends ObjectStore<UserStat, ApiUserStat> {
  public constructor(queue: RequestQueue, url = 'v1/users/me/stats') {
    super(queue, url, [15, 20]);
    makeObservable(this);
  }

  protected createAndLoadItem(apiUserStat: ApiUserStat): UserStat {
    return UserStat.parse(apiUserStat);
  }

  public getItemByApplicationId(applicationId: number): UserStat {
    const userStat = this.itemsMap.get(applicationId);
    return isNullOrUndefined(userStat) ? null : userStat;
  }

  @computed
  public get userStats(): UserStats {
    const stats = this.items.reduce<UserStats>(
      (stats: UserStats, userStat: UserStat): UserStats => {
        // Games played
        stats.gamesPlayed++;
        // Total time played
        stats.totalSecondsPlayed = stats.totalSecondsPlayed + userStat.secondsPlayed;
        // Most played
        if (
          isNullOrUndefined(stats.mostPlayed) ||
          (userStat.secondsPlayed > 0 && userStat.secondsPlayed > stats.mostPlayed.secondsPlayed)
        ) {
          stats.mostPlayed = userStat;
        }
        //Last played
        if (isNullOrUndefined(stats.lastPlayed)) {
          stats.lastPlayed = userStat;
        } else {
          if (userStat.lastPlayed > stats.lastPlayed.lastPlayed) {
            stats.lastPlayed = userStat;
          }
        }
        return stats;
      },
      {
        gamesPlayed: 0,
        totalSecondsPlayed: 0,
        mostPlayed: null,
        lastPlayed: null,
      }
    );

    return stats;
  }

  @action
  public optimisticUpdateStat(applicationId: number, sessionPlayTime: number, stopDate: Date): void {
    const userStat = this.getItemByApplicationId(applicationId);
    if (!isNullOrUndefined(userStat)) {
      userStat.secondsPlayed = userStat.secondsPlayed + sessionPlayTime;
      userStat.lastPlayed = stopDate;
    } else {
      this.itemsMap.set(
        applicationId,
        UserStat.parse({
          id: `TEMP_${applicationId}`, // will be stored in userStat.userStatId
          last_played: stopDate.toISOString(),
          total_seconds_played: sessionPlayTime,
          application: {
            id: applicationId, // will become userStat.id
          },
        })
      );
    }
  }
}
