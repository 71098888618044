import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './new-update-available-dialog.scss';
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
export const NewUpdateAvailableDialog = withFocusable()<Props>(observer(function AllowedKeyboardDialog({
  action
}) {
  return <div className={'new-update-available-dialog__wrapper'}>
        <div className={'titles__container'}>
          <p>A newer version of the app is available. Restart the app to use the new version?</p>
        </div>
        <FocusableButton className={'button-restart'} onEnterPress={action.bind(null, DialogResult.OK)} focusable={true} type={'primary'} autofocus>
          {t({
        context: 'Button - Restart the application',
        message: 'Restart'
      })}
        </FocusableButton>
      </div>;
}));
export default NewUpdateAvailableDialog;