import { t } from '@lingui/macro';
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { ChannelStore } from '../../../dataStore/stores/channelStore/channelStore';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';
import { ChannelTileProvider } from '../../global/tile-provider/channel-tile-provider';
import { PageScrollState } from '../pageScrollState/pageScrollState';
export class ChannelsController {
  private _channelStore: ChannelStore;
  private readonly _autorunDisposer: ReturnType<typeof autorun>;
  private _initialized = false;
  @observable
  private _ready = false;
  @observable
  private _currentProvider: ChannelTileProvider = null;
  @observable
  private _genreChannelsProvider: ChannelTileProvider = null;
  @observable
  private _styleChannelsProvider: ChannelTileProvider = null;
  @observable
  private _publisherChannelsProvider: ChannelTileProvider = null;
  @observable
  private _featuredChannelsProvider: ChannelTileProvider = null;
  @observable
  private _otherChannelsProvider: ChannelTileProvider = null;
  @observable
  private _allNotEmptyProviders: ChannelTileProvider[] = null;
  private _pageScrollState: PageScrollState;
  public constructor(channelStore: ChannelStore) {
    makeObservable(this);
    this._channelStore = channelStore;
    this._autorunDisposer = autorun(() => {
      const providers: ChannelTileProvider[] = [this._featuredChannelsProvider, this._genreChannelsProvider, this._styleChannelsProvider, this._publisherChannelsProvider, this._otherChannelsProvider];
      const filteredProviders = providers.reduce((prev, curr) => {
        if (curr && !(curr.state === AsyncObjectState.Done && !curr.length || curr.state === AsyncObjectState.Error)) {
          return [...prev, curr];
        }
        return prev;
      }, [] as ChannelTileProvider[]);
      if (providers.every(p => p?.state === AsyncObjectState.Done || p?.state === AsyncObjectState.Error)) {
        runInAction(() => {
          this._ready = true;
        });
      }
      this._setAllNotEmptyProviders(filteredProviders);
    });
  }
  @action
  private _setAllNotEmptyProviders(providers: ChannelTileProvider[]) {
    this._allNotEmptyProviders = providers;
  }
  @computed
  public get isReady() {
    return this._ready;
  }
  public get pageScrollState() {
    return this._pageScrollState;
  }
  public clearPageScrollState() {
    this._pageScrollState = new PageScrollState();
  }
  @computed
  public get currentProvider() {
    return this._currentProvider;
  }
  @action
  public setCurrentProvider(provider: ChannelTileProvider) {
    this._currentProvider = provider;
  }
  @computed
  public get genreChannelsProvider(): ChannelTileProvider {
    return this._genreChannelsProvider;
  }
  @computed
  public get styleChannelsProvider(): ChannelTileProvider {
    return this._styleChannelsProvider;
  }
  @computed
  public get publisherChannelsProvider(): ChannelTileProvider {
    return this._publisherChannelsProvider;
  }
  @computed
  public get featuredChannelsProvider(): ChannelTileProvider {
    return this._featuredChannelsProvider;
  }
  @computed
  public get otherChannelsProvider(): ChannelTileProvider {
    return this._otherChannelsProvider;
  }
  @computed
  public get allNotEmptyChannels(): ChannelTileProvider[] {
    return this._allNotEmptyProviders;
  }
  @action
  public init(): void {
    if (this._initialized) return;
    this._pageScrollState = new PageScrollState();
    this._genreChannelsProvider = new ChannelTileProvider('genre_channels', t({
      context: 'Channel title or carousel title. Games in channel are sorted by this value',
      message: 'By genre'
    }), this._channelStore.genreChannels);
    this._styleChannelsProvider = new ChannelTileProvider('style_channels', t({
      context: 'Channel title or carousel title. Games in channel are sorted by this value',
      message: 'By style'
    }), this._channelStore.styleChannels);
    this._publisherChannelsProvider = new ChannelTileProvider('publisher_channels', t({
      context: 'Channel title or carousel title. Games in channel are sorted by this value',
      message: 'By publisher'
    }), this._channelStore.publisherChannels);
    this._featuredChannelsProvider = new ChannelTileProvider('featured_channels', t({
      context: 'Channel title or carousel title',
      message: 'Recommended channels'
    }), this._channelStore.recommendedChannels, true);
    this._otherChannelsProvider = new ChannelTileProvider('other_channels', t({
      context: 'Channel title or carousel title',
      message: 'Other channels'
    }), this._channelStore.otherChannels);
    this._initialized = true;
  }
  @action
  public clear(): void {
    this._genreChannelsProvider = null;
    this._styleChannelsProvider = null;
    this._publisherChannelsProvider = null;
    this._featuredChannelsProvider = null;
    this._otherChannelsProvider = null;
    this._initialized = false;
    this._pageScrollState?.clearState();
    this._setAllNotEmptyProviders(null);
  }
  public dispose = () => {
    this._autorunDisposer();
  };
}