import { faArrowRightFromBracket, faChevronRight, faComment, faCommentSlash, faGamepad, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { ClientControllerContext, KeyboardControllerContext, NavigationControllerContext, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { isTouchableDevice } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './stream-side-menu.module.scss';
export type StreamSideMenuItem = {
  title?: string;
  icon: React.ReactNode;
  onClick?: () => void;
  visible: boolean;
  active?: boolean;
};
type Props = {
  show?: boolean;
  onExitStream: () => void;
};
const TIME_TO_HIDE_SIDE_MENU = 5000;
export const StreamSideMenu: React.FC<Props> = observer(({
  show,
  onExitStream
}) => {
  const navigationController = useContext(NavigationControllerContext);
  const clientController = useContext(ClientControllerContext);
  const keyboardController = useContext(KeyboardControllerContext);
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const [open, setOpen] = useState(true);
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    // close after 5 sec after the stream init
    if (streamViewUtilsService.state === 'Initialized' && show) {
      timeoutId = setTimeout(() => {
        setOpen(false);
      }, TIME_TO_HIDE_SIDE_MENU);
    }
    return () => clearTimeout(timeoutId);
  }, [streamViewUtilsService.state, show]);
  const sideMenuItems: StreamSideMenuItem[] = [{
    title: t({
      context: 'Switch notifications message',
      message: 'Notifications'
    }),
    icon: <FontAwesomeIcon icon={clientController.isNetworkNotificationMinimized ? faCommentSlash : faComment} />,
    onClick: () => clientController.switchNetworkNotificationMinimized(),
    visible: true,
    active: clientController.isNetworkNotificationMinimized
  }, {
    title: t({
      context: 'Show on-screen keyboard message',
      message: 'Keyboard'
    }),
    icon: <FontAwesomeIcon icon={faKeyboard} />,
    onClick: () => keyboardController.switchInputFocused(),
    visible: streamViewUtilsService.isAllowedKeyboard,
    active: keyboardController.isKeyboardVisible
  }, {
    title: t({
      context: 'Show on-screen gamepad message',
      message: 'Gamepad'
    }),
    icon: <FontAwesomeIcon icon={faGamepad} />,
    onClick: () => streamViewUtilsService.setOnScreenGamepadVisible(!streamViewUtilsService.isOnScreenGamepadVisible),
    visible: streamViewUtilsService.state === 'Initialized' && !navigationController.isGamepadConnected,
    active: streamViewUtilsService.isOnScreenGamepadVisible
  }, {
    title: t({
      context: 'Quit the game message',
      message: 'Quit'
    }),
    icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
    onClick: onExitStream,
    visible: true
  }];
  const handleOpen = () => {
    setOpen(prev => !prev);
  };
  const handleItemClick = (item: StreamSideMenuItem) => {
    item.onClick && item.onClick();
    handleOpen();
  };
  return show && isTouchableDevice && ReactDOM.createPortal(<div className={styles.streamSideMenuWrapper}>
        <div className={cx(styles.streamSideMenu, {
      [styles.streamSideMenuOpen]: open
    })}>
          {sideMenuItems.map(item => {
        if (!item.visible) return null;
        return <button key={item.title} onClick={handleItemClick.bind(null, item)} className={cx(styles.streamSideMenuItem, {
          [styles.streamSideMenuItemActive]: item.active
        })}>
                {item.icon}
                <small>{item.title}</small>
              </button>;
      })}
          <button onClick={handleOpen} className={styles.arrowButton}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>, document.body);
});