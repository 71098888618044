import { action, computed, makeObservable, observable } from 'mobx';
import { Channel } from '../../../dataStore/stores/channelStore/channel';
import { ChannelStore } from '../../../dataStore/stores/channelStore/channelStore';
import { PageScrollState } from '../pageScrollState/pageScrollState';
export class EventController {
  @observable
  private _isInitialized: boolean;
  private _channelStore: ChannelStore;
  private readonly _eventChannel: Channel;
  private readonly _pageScrollState: PageScrollState;
  constructor(channelStore: ChannelStore) {
    this._eventChannel = new Channel(channelStore, 'indie-fest-2023', 'Play during Utomik Indie Fest', null, true);
    this._pageScrollState = new PageScrollState();
    this._channelStore = channelStore;
    makeObservable(this);
  }
  public async init() {
    if (this._isInitialized) return;
    await this._eventChannel.fetch();
    await this._eventChannel.appList.getApps(0, 14);
    this._setInitialized(true);
  }
  public get pageScrollState() {
    return this._pageScrollState;
  }
  @action
  private _setInitialized(val: boolean) {
    this._isInitialized = val;
  }
  @computed
  public get isInitialized() {
    return this._isInitialized;
  }
  @computed
  public get channel() {
    return this._eventChannel;
  }
}