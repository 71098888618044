import { getDeviceTag, isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { ObservableMap, action, makeObservable, observable, override } from 'mobx';

import { PageScrollState } from '../../../app/global/pageScrollState/pageScrollState';
import { ApplicationStore } from '../applicationStore/applicationStore';
import { ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';
import { ApiChannel, Channel } from './channel';
import { ChannelList } from './channelList';

export class ChannelStore extends ObjectStore<Channel, ApiChannel> {
  @observable
  private _itemsSlugMap: ObservableMap<ObjectID, Channel>;
  private _appStore: ApplicationStore;
  private _recommendedChannels: ChannelList;
  private _genreChannels: ChannelList;
  private _styleChannels: ChannelList;
  private _publisherChannels: ChannelList;
  private _otherChannels: ChannelList;
  private _pageScrollState = new PageScrollState();

  /**
   * @param applicationStore - Is here mostly for the RequestQueue.
   * @param url - Api endpoint url ('v2/channels').
   * @param retries - Amount of retries before failing request.
   */
  constructor(applicationStore: ApplicationStore, url = 'v2/channels', retries = null) {
    super(applicationStore.requestQueue, url, retries);
    this._appStore = applicationStore;

    // Create the channel lists.
    this._recommendedChannels = new ChannelList(
      this,
      'recommendedchannels',
      `v2/recommended/channels?fields=name,slug,id&cloud_platform=${getDeviceTag()}`
    );
    this._genreChannels = new ChannelList(
      this,
      'genreChannels',
      `v2/channels?category=genre&fields=name,slug,id&order=name&cloud_platform=${getDeviceTag()}`
    );
    this._styleChannels = new ChannelList(
      this,
      'styleChannels',
      `v2/channels?category=style&fields=name,slug,id&order=name&cloud_platform=${getDeviceTag()}`
    );
    this._publisherChannels = new ChannelList(
      this,
      'publisherChannels',
      `v2/channels?category=partner&fields=name,slug,id&order=name&cloud_platform=${getDeviceTag()}`
    );
    this._otherChannels = new ChannelList(
      this,
      'otherChannels',
      `v2/channels?category=other&fields=name,slug,id&order=name&cloud_platform=${getDeviceTag()}`
    );

    this._itemsSlugMap = new ObservableMap<ObjectID, Channel>();
    makeObservable(this);
  }

  public get pageScrollState() {
    return this._pageScrollState;
  }

  public clearPageScrollState() {
    this._pageScrollState = new PageScrollState();
  }

  /**
   * Get a channel by id. If the id does not exist, create it and add it to the itemsMap.
   *
   * @param id - Can be an integer, or a string (the channel's slug) like 'coming_soon'.
   * @param name - The name of the channel. Can be a genre, a publisher, or something like 'Recently Played'.
   */
  @action
  public getItem(id: ObjectID, name?: string): Channel {
    let item: Channel = null;
    let isSlug = false;
    // if there's any non-numeric characters in the ID, it's a slug, even if parseInt() would work (10tons, 1C, 2K Games, etc.)
    const idString = id?.toString();
    let foundNonNumber = false;
    for (let i = 0; !foundNonNumber && i < idString?.length; i++) {
      const code = idString.charCodeAt(i);
      foundNonNumber = code <= 47 || code >= 58;
    }
    let parsedId: ObjectID = id;
    if (foundNonNumber) {
      item = this._itemsSlugMap.get(id) as Channel;
      isSlug = true;
    } else {
      parsedId = typeof id === 'string' ? parseInt(id, 10) : id;
      item = this.itemsMap.get(parsedId) as Channel;
    }
    if (item) {
      // If the name is not set then set it again.
      if (isNullOrUndefined(item.name) || item.name.length === 0) item.name = name;
      // If the slug is not set then set it again.
      if ((isNullOrUndefined(item.slug) || item.slug.length === 0) && isSlug) {
        item.slug = parsedId.toString();
      }
      return item;
    }
    // If the channel is not in the itemsMap/itemsSlugMap, just make a new channel and shove it in there.
    const channel = new Channel(this, parsedId, name, this.retries);
    if (isSlug) {
      channel.slug = parsedId.toString();
      this._itemsSlugMap.set(parsedId, channel);
    } else {
      this.itemsMap.set(parsedId, channel);
    }
    return channel;
  }

  /**
   * When called, set all the channels and recommended channels to null.
   */
  @override
  public override unload(): void {
    super.unload();
    this._itemsSlugMap.clear();
    this._recommendedChannels.unload();
    this._genreChannels.unload();
    this._styleChannels.unload();
    this._publisherChannels.unload();
    this._otherChannels.unload();
  }

  public get recommendedChannels(): ChannelList {
    return this._recommendedChannels;
  }

  public get genreChannels(): ChannelList {
    return this._genreChannels;
  }

  public get styleChannels(): ChannelList {
    return this._styleChannels;
  }

  public get publisherChannels(): ChannelList {
    return this._publisherChannels;
  }

  public get otherChannels(): ChannelList {
    return this._otherChannels;
  }

  public get appStore(): ApplicationStore {
    return this._appStore;
  }

  @action
  public mapItem(channel: Channel): void {
    this.itemsMap.set(channel.id, channel);
    this._itemsSlugMap.set(channel.slug, channel);
  }

  // Create a channel object and then load it.
  protected createAndLoadItem(apiChannel: ApiChannel): Channel {
    const channel = new Channel(this, apiChannel.id, apiChannel.name);
    channel.load(apiChannel);
    return channel;
  }
}
