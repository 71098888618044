import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncList } from '../../stores/objectStore/asyncList';
import {
  IStreamGetGatewayList,
  IStreamGetGatewayListAvailableGateway,
} from '../../stores/streamStore/streamGetGatewayList';
import { ObjectID } from '../objectStore/asyncObject';

export interface IQueueLength {
  queueLength: number;
  waitingTime: number;
}

export class QueueLength extends AsyncList<IQueueLength> {
  public constructor(requestQueue: RequestQueue, id: ObjectID, url?: string) {
    super(requestQueue, id, url);
  }

  public async getQueueLength(
    gateway: IStreamGetGatewayList,
    cloudToken: string
  ): Promise<IStreamGetGatewayListAvailableGateway> {
    const options = {
      method: 'GET',
      headers: { 'x-auth-token': cloudToken },
    };

    const promise = new Promise<IStreamGetGatewayListAvailableGateway>((resolve, reject) => {
      setTimeout(() => {
        reject({ ...gateway, queueLength: 199, predictedQueue: 199, waitingTime: -1 });
      }, 10000);

      fetch(gateway.address + this.url, options)
        .then((response) => response.json())
        .then((result: IStreamGetGatewayListAvailableGateway) =>
          resolve({
            ...gateway,
            queueLength: result.queueLength,
            predictedQueue: result.predictedQueue,
            waitingTime: result.waitingTime,
          })
        )
        .catch(() => reject({ ...gateway, queueLength: 199, predictedQueue: 199, waitingTime: -1 }));
    });

    return promise;
  }
}
