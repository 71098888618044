import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { INTERNAL_BACK_EVENT_NAME } from '@utomik-app-monorepo/constants';
import { useMouseScroll } from '@utomik-app-monorepo/hooks';
import { DialogQueueContext, ObjectID, PlatformControllerContext } from '@utomik-app-monorepo/store';
import { setLimitedTransformValues, sizeRemToPx } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './focusable-select.scss';
type FocusableSelectContentProps = {
  values: {
    name: string;
  }[];
  width?: 'narrow' | 'medium' | 'wide';
  selectedValue: string;
  focused?: boolean;
  onEnterPress?: (props?: any) => void;
  hasFocusedChild?: boolean;
  isOpen: boolean;
  onEnterPressChild?: (props: any) => void;
  setFocus?: (key?: string) => void;
  blockNavigationOut?: boolean;
  setIsOpen?: (val: any) => void;
  scrollType?: 'native' | 'transform';
  openDown?: boolean;
};
type FocusableSelectProps<T> = {
  values: T[];
  width?: 'narrow' | 'medium' | 'wide';
  selectedValue: string;
  onChange: (val: T) => void;
  disabled?: boolean;
  scrollType?: 'native' | 'transform';
  openDown?: boolean;
};
const FocusableOption = withFocusable()<{
  onClick?: () => void;
  onMouseOver?: () => void;
}>(({
  children,
  focused,
  onClick
}) => {
  const itemRef = useRef<HTMLDivElement>(null);
  return <div ref={itemRef} className={cx('focusable-option', {
    'focusable-option--focus': focused
  })} style={{
    height: sizeRemToPx(4) + 'px'
  }} onClick={onClick}>
        {children}
      </div>;
});
const FocusableSelectContent = withFocusable({
  trackChildren: true
})<FocusableSelectContentProps>(({
  focused,
  values,
  selectedValue,
  hasFocusedChild,
  isOpen,
  onEnterPressChild,
  setFocus,
  width = 'narrow',
  navigateByDirection,
  setIsOpen,
  focusable,
  scrollType = 'transform',
  openDown
}) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const scrollableNativeContainerRef = useRef<HTMLDivElement>(null);
  useMouseScroll(scrollableContainerRef, navigateByDirection, true, scrollType === 'transform');
  useEffect(() => {
    if (isOpen && values.find(v => v.name === selectedValue)) {
      return setFocus(selectedValue);
    } else if (isOpen) {
      setFocus();
    }
  }, [isOpen]);
  useEffect(() => {
    if (!focused && !hasFocusedChild) {
      setIsOpen(false);
    }
  }, [focused, hasFocusedChild]);
  const handleFocus = props => {
    if (scrollType === 'transform' && scrollableContainerRef.current) {
      setLimitedTransformValues(scrollableContainerRef, props, true, 2.5);
    } else if (scrollType === 'native' && scrollableNativeContainerRef.current) {
      scrollableNativeContainerRef.current.scroll({
        behavior: 'smooth',
        top: props.y
      });
    }
  };
  const handleEnterPressChild = val => {
    onEnterPressChild(val);
  };
  return <div className={cx(`focusable-select__container--${width}`, {
    'focusable-select__container--focus': focused || hasFocusedChild,
    'focusable-select__container--disabled': !focusable
  })} onClick={!isOpen ? setIsOpen.bind(null, true) : undefined}>
        <p>{selectedValue}</p>
        <FontAwesomeIcon className={cx('chevron-dow-select', {
      'chevron-dow-select--rotated': isOpen
    })} icon={faChevronDown} />

        <div ref={scrollableNativeContainerRef} className={cx('date-picker-content__wrapper', {
      'date-picker-content__wrapper--visible': isOpen && !openDown,
      'date-picker-content__wrapper--visible-down': isOpen && openDown,
      'date-picker-content__wrapper--native-scroll': scrollType === 'native'
    })}>
          <div className="scrollable-container" ref={scrollableContainerRef}>
            {values.map((value, idx) => <FocusableOption focusable={value.name === selectedValue || isOpen} focusKey={value.name} key={value.name + idx} onBecameFocused={handleFocus} onEnterPress={handleEnterPressChild.bind(null, value)} onClick={handleEnterPressChild.bind(null, value)}>
                {value.name}
              </FocusableOption>)}
          </div>
        </div>
      </div>;
});
export const FocusableSelect: React.FC<FocusableSelectProps<{
  name: string;
  id?: ObjectID;
}>> = observer(function FocusableSelect({
  selectedValue,
  values,
  onChange,
  width,
  disabled,
  scrollType,
  openDown
}) {
  const dialogQueue = useContext(DialogQueueContext);
  const platformController = useContext(PlatformControllerContext);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    //this useEffect used to close the select and the dialog if it exists
    if (dialogQueue.currentDialog) {
      dialogQueue.currentDialog.isClosable = !isOpen;
    }
    const handleBack = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };
    const keyHandler = e => {
      if (e.keyName === 'back' || e.key === 'GoBack') {
        handleBack();
      }
    };
    if (platformController.isTizen) {
      window.addEventListener('tizenhwkey', keyHandler);
    } else if (platformController.isWebOS) {
      window.addEventListener('keydown', keyHandler);
    }
    window.addEventListener(INTERNAL_BACK_EVENT_NAME, handleBack);
    return () => {
      window.removeEventListener('tizenhwkey', keyHandler);
      window.removeEventListener('keydown', keyHandler);
      window.removeEventListener(INTERNAL_BACK_EVENT_NAME, handleBack);
    };
  }, [isOpen]);
  const handleEnterPressChild = value => {
    setIsOpen(isOpen => !isOpen);
    if (isOpen) {
      onChange(value);
    }
  };
  const handleOpen = () => {
    setIsOpen(isOpen => !isOpen);
  };
  return <FocusableSelectContent width={width} blockNavigationOut={isOpen} values={values} selectedValue={selectedValue} isOpen={isOpen} onEnterPressChild={handleEnterPressChild} onEnterPress={handleOpen} setIsOpen={setIsOpen} focusable={!disabled} scrollType={scrollType} openDown={openDown} />;
});