import { t } from '@lingui/macro';
import { ObservableMap, action, computed, flow, makeObservable, observable } from 'mobx';

import { Application } from '../../../dataStore/stores/applicationStore/application';
import { AsyncObjectState, ObjectID } from '../../../dataStore/stores/objectStore/asyncObject';
import { AppTileProvider } from '../../global/tile-provider/app-tile-provider';

export class AppInfoController {
  private _name: string = null;
  @observable
  private _language: string = null;
  @observable
  private _id: ObjectID = null;
  private _appItemProviders: ObservableMap<ObjectID, AppTileProvider> = new ObservableMap();

  public constructor(name = '') {
    makeObservable(this);
    this._name = name;
  }

  @computed
  public get appItemProviderAppInfo(): AppTileProvider {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return this._appItemProviders.get(`AppInfo - ${this._id}`);
  }

  @computed
  public get appItemProviderExitPage(): AppTileProvider {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return this._appItemProviders.get(`ExitPage - ${this._id}`);
  }
  public loadApplication = flow(function* (this: AppInfoController, application: Application, language: string) {
    const id = `${this._name}${application.id}`;
    if (id === this._id && this._language === language) return;
    this._id = id;
    this._language = language;

    // Wait until the application is fetched.
    if (application.state !== AsyncObjectState.Done) yield application.fetch();

    // Set up related app list (two providers, identical except for the analytic origin).
    // eslint-disable-next-line lingui/no-unlocalized-strings
    if (application.relatedAppList && !this._appItemProviders.get(`AppInfo - ${this._id}`)) {
      this._appItemProviders.set(
        // eslint-disable-next-line lingui/no-unlocalized-strings
        `AppInfo - ${this._id}`,
        new AppTileProvider(
          application.slug,
          t({ context: 'Title of the carousel with game tiles', message: 'Related games' }),
          // eslint-disable-next-line lingui/no-unlocalized-strings
          ['AppInfo', `${application.slug}`, 'Related'],
          application.relatedAppList
        )
      );
    }
    // eslint-disable-next-line lingui/no-unlocalized-strings
    if (application.relatedAppList && !this._appItemProviders.get(`ExitPage - ${this._id}`)) {
      this._appItemProviders.set(
        // eslint-disable-next-line lingui/no-unlocalized-strings
        `ExitPage - ${this._id}`,
        new AppTileProvider(
          application.slug,
          t({ context: 'Title of the carousel with game tiles', message: 'Related games' }),
          // eslint-disable-next-line lingui/no-unlocalized-strings
          ['ExitPage', `${application.slug}`, 'Related'],
          application.relatedAppList
        )
      );
    }
  });

  @action
  public clearCarouselStates(): void {
    this._id = null;
  }
}
