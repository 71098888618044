import { analyticsRoutes, reversedRoutes } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { isTest } from '@utomik-app-monorepo/utils';

import { ObjectID } from '../../../dataStore/stores/objectStore/asyncObject';
import { PlatformController } from '../platformController/platformController';
import {
  AnalyticItemTypes,
  GoogleAnalyticsManager,
  IAnalyticEventBody,
  IUserPropertiesAnalytic,
  SubpageTypes,
} from './clients/googleAnalyticsManager';

export interface ISubpageProps {
  subpage_name: string;
  subpage_type: SubpageTypes;
  item_name?: string;
  item_type?: AnalyticItemTypes;
  result?: string;
}

export class AnalyticController {
  private _gtm: GoogleAnalyticsManager;
  private _platformCOntroller: PlatformController;

  constructor(platformController: PlatformController) {
    this._gtm = new GoogleAnalyticsManager(platformController);
  }

  /**
   * When called, this will initialize our analytic stores.
   * @param userId Used by the GoogleTagManager to obtain the active user's user ID
   * @param userProps User properties for Google Analytic
   */
  public start(userId: ObjectID, userProps: IUserPropertiesAnalytic): void {
    if (isTest) {
      return console.log('Analytics disabled. Testing mode...');
    }

    this._gtm.initialize({ userID: userId, userProperties: userProps });
  }

  /**
   * When called, this will end every registered client.
   */
  public stop(): void {
    this._gtm.end();
  }

  public sendPageView(virtualPageURL: string): void {
    this._gtm.sendPageView({ page_title: virtualPageURL, page_location: virtualPageURL });
  }

  public sendOpenSubPage(data: ISubpageProps): void {
    this._gtm.sendSubPageView({ action: 'open', ...data });
  }

  public sendCloseSubPage(data: ISubpageProps): void {
    this._gtm.sendSubPageView({ action: 'close', ...data });
  }

  public login(): void {
    this._gtm.sendEvent({ event_name: 'login', method: 'credentials' });
  }

  public cloudSetting(): void {
    this._gtm.sendEvent({ event_name: 'set_cloud_setting', game_device: 'Cloud' });
  }

  public navigate(
    data: Required<Pick<IAnalyticEventBody, 'location_on_page' | 'item_name' | 'item_type'>> &
      Pick<IAnalyticEventBody, 'location_index'>
  ): void {
    this._gtm.sendEvent({ event_name: 'navigate', ...data });
  }

  public addToMyList(
    data: Required<Pick<IAnalyticEventBody, 'location_on_page' | 'item_name'>> &
      Pick<IAnalyticEventBody, 'location_index'>
  ): void {
    this._gtm.sendEvent({ event_name: 'add_to_list', ...data, item_type: AnalyticItemTypes.Game });
  }

  public removeFromMyList(
    data: Required<Pick<IAnalyticEventBody, 'location_on_page' | 'item_name'>> &
      Pick<IAnalyticEventBody, 'location_index'>
  ): void {
    this._gtm.sendEvent({ event_name: 'remove_from_list', ...data, item_type: AnalyticItemTypes.Game });
  }

  public clickExternalLink(data: Required<Pick<IAnalyticEventBody, 'location_on_page' | 'url'>>): void {
    this._gtm.sendEvent({ event_name: 'click_link', ...data });
  }

  public featuredItem(
    data: Required<Pick<IAnalyticEventBody, 'location_on_page' | 'item_name' | 'item_type' | 'location_index'>>
  ): void {
    this._gtm.sendEvent({ event_name: 'feature_item', ...data });
  }

  public playGame(
    data: Required<Pick<IAnalyticEventBody, 'item_name' | 'location_on_page'>> &
      Pick<IAnalyticEventBody, 'location_index'>
  ): void {
    this._gtm.sendEvent({
      event_name: 'play_game',
      ...data,
      method: 'manually',
      item_type: AnalyticItemTypes.Game,
      game_device: 'Cloud',
    });
  }

  public stopGame(
    data: Required<Pick<IAnalyticEventBody, 'item_name' | 'location_on_page'>> &
      Pick<IAnalyticEventBody, 'location_index'>
  ): void {
    this._gtm.sendEvent({
      event_name: 'stop_game',
      ...data,
      method: 'manually',
      item_type: AnalyticItemTypes.Game,
    });
  }

  public gameStarted(
    data: Required<Pick<IAnalyticEventBody, 'item_name' | 'location_on_page'>> &
      Pick<IAnalyticEventBody, 'location_index'>
  ): void {
    this._gtm.sendEvent({
      event_name: 'game_started',
      ...data,
      method: 'manually',
      item_type: AnalyticItemTypes.Game,
      game_device: 'Cloud',
    });
  }

  public gameEnded(
    data: Required<Pick<IAnalyticEventBody, 'item_name' | 'location_on_page'>> &
      Pick<IAnalyticEventBody, 'location_index'>
  ): void {
    this._gtm.sendEvent({
      event_name: 'game_ended',
      ...data,
      item_type: AnalyticItemTypes.Game,
      game_device: 'Cloud',
    });
  }

  public rateGame(
    data: Required<Pick<IAnalyticEventBody, 'location_on_page' | 'item_name' | 'rating'>> &
      Pick<IAnalyticEventBody, 'location_index'>
  ): void {
    this._gtm.sendEvent({ event_name: 'rate_game', ...data, item_type: AnalyticItemTypes.Game });
  }

  public shareGame(data: Required<Pick<IAnalyticEventBody, 'location_on_page' | 'item_name' | 'method'>>): void {
    this._gtm.sendEvent({
      event_name: 'share',
      ...data,
      item_type: AnalyticItemTypes.Game,
      content_type: AnalyticItemTypes.Game,
    });
  }

  public getBreadCrumbs = ({
    pageName,
    currentSection,
    isCarousel,
    isSeeAll,
    isGrid,
    injectOnPosition,
  }: {
    pageName?: string;
    currentSection?: string;
    isCarousel?: boolean;
    isSeeAll?: boolean;
    isGrid?: boolean;
    injectOnPosition?: { name: string; position: number };
  } = {}) => {
    const splitHash = location.hash.split(/(\/[a-zA-Z-]*)/) || [];
    const firstPart = splitHash[1];

    const pageNameRoute = analyticsRoutes[firstPart] || reversedRoutes[firstPart];

    let itemType = '';
    let lastElem = '';

    if (isCarousel) {
      itemType = 'Carousel';
    }

    if (isSeeAll) {
      lastElem = 'SeeAll';
    } else if (isGrid) {
      lastElem = 'Grid';
    }

    const strArr = [pageName || pageNameRoute, itemType, currentSection, lastElem];

    try {
      if (injectOnPosition) {
        strArr[injectOnPosition.position] = injectOnPosition.name;
      }
    } catch (e) {
      log(e);
    }

    return strArr.filter((b) => b);
  };

  // /**
  //  * The client language.
  //  */
  // public clientLanguage(): void {
  //   const language = getCurrentLanguage();
  //   this._gtm.sendEvent('Client', 'Language', language);
  // }
  //
  // /**
  //  * The client country.
  //  */
  // public clientCountry(): void {
  //   this._countryByIp
  //     .fetch()
  //     .then(() => {
  //       this._gtm.sendEvent('Client', 'Country', this._countryByIp.data.countryName);
  //     })
  //     .catch((err) => {
  //       console.log(err, 'Unable to get country by IP address');
  //     });
  // }
}
