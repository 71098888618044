import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import './FocusableCheckbox.scss';
type Props = {
  label: string;
  initialState?: boolean;
  onChangeValue?: (props: {
    value: boolean;
    name?: string;
  }) => void;
  name?: string;
  disabled?: boolean;
  value?: boolean;
};
const Checkbox = withFocusable()<Props & {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}>(({
  label,
  inputProps,
  focused,
  setFocus,
  focusable
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const focusHandler = () => {
      setFocus();
    };
    inputRef.current.addEventListener('focus', focusHandler);
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('focus', focusHandler);
      }
    };
  }, []);
  return <div className={cx('focusable-checkbox__container', {
    'focusable-checkbox__container--disabled': !focusable,
    'focusable-checkbox__container--focus': focused
  })}>
        <input type={'checkbox'} {...inputProps} disabled={!focusable} ref={inputRef} />
        <span>{label}</span>
      </div>;
});
export const FocusableCheckbox: React.FC<Props> = observer(function FocusableCheck({
  label,
  onChangeValue,
  name,
  disabled,
  value
}) {
  const handleEnterPress = () => {
    onChangeValue({
      value: !value,
      name
    });
  };
  return <Checkbox onEnterPress={handleEnterPress} label={label} inputProps={{
    checked: value,
    onClick: handleEnterPress,
    onChange: () => undefined
  }} focusable={!disabled} />;
});