import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import './step-progress.scss';
type StepProgressProps = {
  maxValue: number;
  value: number;
};
export const StepProgress: React.FC<StepProgressProps> = observer(function StepProgress({
  maxValue,
  value
}) {
  const segments = Array.from({
    length: maxValue
  }).map((_, idx) => {
    return <div key={`step-progress-segment-${idx}`} className={cx('step-progress-segment', {
      'step-progress-segment--active': idx < value,
      'step-progress-segment--highlighted': idx === value
    })} />;
  });
  return <div className={'step-progress__container'}>{segments}</div>;
});