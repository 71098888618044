import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './stream-message-dialog.scss';
export const StreamMessageDialog = observer(function StreamMessageDialog({
  action,
  text
}) {
  return <div className={'stream-message__wrapper'}>
      {text}
      <FocusableButton className={'confirm-button'} autofocus type={'primary'} onEnterPress={() => action(DialogResult.OK)}>
        {t({
        message: 'OK'
      })}
      </FocusableButton>
    </div>;
});
export default StreamMessageDialog;