import { ApiAppPermissions } from '../appPermission/appPermission';

export interface ApiAppListEntry {
  id: number;
  name: string;
  slug: string;
  permissions: ApiAppPermissions;
  box_image: {
    url: string;
    download_url: string;
  };
  cloud: {
    platform_support: string[];
  };
}

export class AppListEntry {
  static parse(apiItem: ApiAppListEntry): AppListEntry {
    return new AppListEntry(apiItem);
  }

  public constructor({ id, name, slug, permissions, box_image, cloud }: ApiAppListEntry) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.permissions = permissions;
    this.box_image = box_image;
    this.cloud = cloud;
  }

  public id: number;
  public name: string;
  public slug: string;
  public permissions: ApiAppPermissions;
  public box_image: ApiAppListEntry['box_image'];
  public cloud: ApiAppListEntry['cloud'];
}
