import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './failed-requests-dialog.scss';
export const FailedRequestsDialog = observer(function FailedRequestsDialog({
  action
}) {
  return <div className={'failed-requests-dialog__container'}>
      <h1 className={'title'}>
        <>{t({
          message: 'Some data could not be loaded. Please check your internet connection and try again'
        })}</>
      </h1>
      <div className={'button-container'}>
        <FocusableButton autofocus type={'primary'} onEnterPress={() => action(DialogResult.OK)}>
          {t({
          message: 'Retry'
        })}
        </FocusableButton>
        <FocusableButton type={'secondary'} onEnterPress={() => action(DialogResult.Cancel)}>
          {t({
          message: 'Cancel'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default FailedRequestsDialog;