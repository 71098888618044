import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useScrollBar } from '@utomik-app-monorepo/hooks';
import { AchievementStore } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import { isNullOrUndefined, setLimitedTransformValues } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef } from 'react';
import { AchievementTile } from '../../achievement-tile/achievement-tile';
import './achievements-dialog.scss';
type Props = {
  achievementsStore: AchievementStore;
  index: number;
  action: (action: DialogResult, value?: any) => void;
  scrollType?: 'native' | 'transform';
};
export const AchievementsDialog = withFocusable()<Props>(observer(function AchievementsDialog({
  setFocus,
  achievementsStore,
  index,
  scrollType = 'transform'
}) {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollableWrapperRef = useRef<HTMLDivElement>(null);
  useScrollBar(scrollableRef, 0.7, scrollType === 'transform');
  useEffect(() => {
    setTimeout(() => {
      !index ? setFocus() : setFocus(`ACHIEVEMENT_${index}`);
    }, 250);
  }, []);
  const handleFocus = props => {
    if (scrollType === 'native') {
      scrollableWrapperRef.current?.scrollTo({
        top: props.y - (props.node as HTMLDivElement).clientHeight / 2,
        behavior: 'smooth'
      });
    } else {
      setLimitedTransformValues(scrollableRef, props);
    }
  };
  const hiddenAchievements = useMemo(() => achievementsStore?.items.filter(x => {
    return x?.hidden && isNullOrUndefined(x?.values.unlockedAt);
  }), [achievementsStore?.items]);
  return <div className={'dialog__container'}>
        <div className={'achievement__wrapper'} ref={scrollableWrapperRef} style={{
      overflow: scrollType === 'native' ? 'auto' : 'hidden'
    }}>
          <div className={'scrollable-container'} ref={scrollableRef}>
            {achievementsStore?.achievementGroupSortedByUnlockedRecently.achievements.map((item, idx) => <AchievementTile focusKey={`ACHIEVEMENT_${idx}`} key={item.id} achievement={item} onBecameFocused={handleFocus} isNew={achievementsStore?.newAchievements.has(item.id)} />)}
            {hiddenAchievements.length > 0 && <AchievementTile focusKey={`hidden-achievements`} key={'hidden-achievements'} onBecameFocused={handleFocus} hiddenAchievementsCount={hiddenAchievements.length} />}
          </div>
        </div>
      </div>;
}));
export default AchievementsDialog;