import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import jwtDecode from 'jwt-decode';
import { action, computed, makeObservable, observable } from 'mobx';

import { ApiLGTokenRSSResponse } from '../stores/lgStore/lgStore';

export interface IPersonalKeyJWTDecode {
  user: number;
  exp: number;
}

export class LgPersonalizedKeyToken {
  @observable
  private _tokenResponse: ApiLGTokenRSSResponse;

  public constructor() {
    makeObservable(this);
  }

  @action
  public setValue(tokenResponse: ApiLGTokenRSSResponse): void {
    this._tokenResponse = tokenResponse;
  }

  @computed
  public get value(): ApiLGTokenRSSResponse {
    return this._tokenResponse;
  }

  @computed
  public get isSet(): boolean {
    return !isNullOrUndefined(this._tokenResponse);
  }

  @computed
  public get jwtDecode(): IPersonalKeyJWTDecode {
    return jwtDecode(this._tokenResponse?.token);
  }

  @computed
  public get expiresAt(): Date {
    const data = new Date(0);
    const utcSeconds = this.jwtDecode.exp;
    data.setUTCSeconds(utcSeconds);

    return data;
  }

  @computed
  public get expiresAtUnixTime(): number {
    return this.jwtDecode.exp;
  }

  @computed
  public get encodedValue(): string {
    return this._tokenResponse?.token;
  }
}
