import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { TvSidebarControllerContext } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent, pathTv } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import './sidebar-menu-item.scss';
const avatarImg = pathTv + require('images/svg/profile_avatar.svg');
type SidebarMenuItemProps = {
  name: string;
  icon: string;
  isRoundedIcon?: boolean;
  badgeValue?: number;
  link?: string;
  focused?: boolean;
  setFocus?: () => void;
  isActive?: boolean;
  focusKey?: string;
  onBecameFocused?: (props: any) => void;
  onEnterPress?: (props: any) => void;
  id?: number | string;
};
export const SidebarMenuItem = withFocusable()<SidebarMenuItemProps>(observer(function SidebarMenuItem({
  name,
  icon,
  focused,
  setFocus,
  isActive,
  badgeValue,
  isRoundedIcon,
  id
}: SidebarMenuItemProps) {
  const sidebarController = useContext(TvSidebarControllerContext);
  const handleClick = () => {
    setFocus();
    dispatchEnterEvent();
  };
  const handleMouseOver = () => {
    setFocus();
  };
  return <div data-menu-id={id} className={cx('sidebar-menu-item__container', {
    'sidebar-menu-item__container--active': isActive,
    'sidebar-menu-item__container--focus': focused || isActive && !sidebarController.isExpanded
  })} onClick={handleClick} onMouseEnter={handleMouseOver}>
        {!!badgeValue && <div className={'badge'}>{badgeValue < 100 ? badgeValue : '99+'}</div>}

        <img decoding={'sync'} className={cx('icon', {
      'icon--round': isRoundedIcon
    })} src={icon || avatarImg} alt={icon} />

        <div className={cx('sidebar-menu-item__name', {
      'sidebar-menu-item__name--visible': sidebarController.isExpanded
    })}>
          <div className={'name__container'}>
            <span>{name}</span>
          </div>
        </div>
      </div>;
}));