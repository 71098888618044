import { AsyncObjectState, ObjectID } from '@utomik-app-monorepo/store';
import { MyListStoreContext, TokenManagerContext } from '@utomik-app-monorepo/store';
import { useContext, useState } from 'react';
export const useMyListDelete = () => {
  const tokenManager = useContext(TokenManagerContext);
  const myListController = useContext(MyListStoreContext);
  const [idsToDelete, setIdsToDelete] = useState<ObjectID[]>([]);
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const handleDelete = async () => {
    if (myListController.state === AsyncObjectState.Pending) return;
    if (isDeleteMode) {
      const isValidToken = await tokenManager.checkToken();
      if (!isValidToken) return tokenManager.logout();
      myListController.multipleRemove(idsToDelete);
      setIdsToDelete([]);
      setIsDeleteMode(false);
      return;
    }
    setIsDeleteMode(true);
  };
  const handleCancel = () => {
    setIdsToDelete([]);
    setIsDeleteMode(false);
  };
  return {
    isDeleteMode,
    idsToDelete,
    setIdsToDelete,
    handleDelete,
    handleCancel
  };
};