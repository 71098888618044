import { GA_SESSION_ID } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { Platform } from '@utomik-app-monorepo/utils';
import { v4 as uuidv4 } from 'uuid';

import { ObjectID } from '../../../../dataStore/stores/objectStore/asyncObject';
import { PlatformController } from '../../platformController/platformController';
import { ISubpageProps } from '../analyticController';

export enum SubpageTypes {
  Dialog = 'Dialog',
  Notification = 'Notification',
  Sidebar = 'Sidebar',
}

export enum AnalyticItemTypes {
  Game = 'Game',
  Channel = 'Channel',
  News = 'News',
  SeeAll = 'SeeAll',
}

export interface IUserPropertiesAnalytic {
  user_country: { value: string };
  user_language: { value: string };
  new_user: { value: string };
  games_played: { value: string };
  user_os_version: { value: string };
  user_screen_resolution: { value: string };
  system_vendor: { value: string };
}

export interface IAnalyticEventBody {
  event_name: string;
  location_on_page?: string;
  location_index?: string;
  item_name?: string;
  item_type?: AnalyticItemTypes;
  amount?: number;
  rating?: string;
  method?: string;
  content_type?: string;
  url?: string;
  game_device?: 'Cloud' | 'Local';
}

export class GoogleAnalyticsManager {
  private _platformController: PlatformController;
  private readonly _dataLayerName = 'dataLayer';

  private _clientId: string;
  private _sessionId: string;
  private _userProperties: IUserPropertiesAnalytic;

  private _intervalId: ReturnType<typeof setTimeout>;

  private _analyticEvents = [];

  private _isTracking = false;

  constructor(platformController: PlatformController) {
    this._platformController = platformController;
  }

  private get currentAnalyticKeys(): { measurementId: string; apiSecret: string } {
    switch (this._platformController.currentPlatform) {
      case Platform.WebOS: {
        return {
          measurementId: process.env.NX_GA_MEASUREMENT_ID_TV,
          apiSecret: process.env.NX_GA_API_SECRET_TV,
        };
      }
      case Platform.Tizen: {
        return {
          measurementId: process.env.NX_GA_MEASUREMENT_ID_TV,
          apiSecret: process.env.NX_GA_API_SECRET_TV,
        };
      }
      case Platform.Vidaa: {
        return {
          measurementId: process.env.NX_GA_MEASUREMENT_ID_TV,
          apiSecret: process.env.NX_GA_API_SECRET_TV,
        };
      }
      case Platform.AndroidTV: {
        return {
          measurementId: process.env.NX_GA_MEASUREMENT_ID_TV,
          apiSecret: process.env.NX_GA_API_SECRET_TV,
        };
      }
      default: {
        if (this._platformController.isWeb) {
          return {
            measurementId: process.env.NX_GA_MEASUREMENT_ID_WEBAPP,
            apiSecret: process.env.NX_GA_API_SECRET_WEBAPP,
          };
        }

        return {
          measurementId: null,
          apiSecret: null,
        };
      }
    }
  }

  /**
   * Initializes the AnalyticClient.
   */
  public initialize = ({
    userID,
    userProperties,
  }: {
    userID: ObjectID;
    userProperties: IUserPropertiesAnalytic;
  }): void => {
    log(`GoogleAnalytics initialized`);

    this._userProperties = userProperties;
    this._clientId = String(userID);
    this._sessionId = this._getSessionID();

    this._startAnalytics();
  };
  /**
   * The end command, which will be handled here.
   */
  public end(): void {
    log(`GoogleAnalytics is no longer tracking.`);
    this._isTracking = false;
  }

  /**
   * This is the only way to determine if analytics is enabled.
   */
  private get _analyticsEnabled(): boolean {
    return window[this._dataLayerName];
  }

  /**
   * Determines if events should be sent.
   */
  private get _shouldSendEvent(): boolean {
    return this._analyticsEnabled && this._isTracking;
  }

  private _getSessionID() {
    let sid: string;
    const storageSid = sessionStorage.getItem(GA_SESSION_ID);

    if (storageSid) {
      sid = storageSid;
    } else {
      sid = uuidv4();
      sessionStorage.setItem(GA_SESSION_ID, sid);
    }

    return sid;
  }

  private _sendAnalyticsBody = (data) => {
    const _data = data;
    const body = {};
    const MAX_EVENTS_IN_QUEUE = 20;

    const cid = this._clientId;
    const sid = this._sessionId;

    _data['engagement_time_msec'] = '100';
    _data['session_id'] = sid;

    body['client_id'] = cid;

    this._analyticEvents.push({
      name: _data.event_name,
      params: _data,
    });

    body['user_properties'] = this._userProperties;

    body['events'] = this._analyticEvents;

    clearTimeout(this._intervalId);

    const handler = () => {
      const keys = this.currentAnalyticKeys;

      try {
        fetch(
          `https://www.google-analytics.com/mp/collect?measurement_id=${keys.measurementId}&api_secret=${keys.apiSecret}`,
          {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify(body),
          }
        ).catch((e) => console.log(e?.message));
        this._analyticEvents = [];
      } catch (e) {
        console.log('Unable to send Google Analytics events');
      }
    };

    if (this._analyticEvents.length > MAX_EVENTS_IN_QUEUE) {
      return handler();
    }

    this._intervalId = setTimeout(handler, 3000);
  };

  private _initializeAnalytics(): void {
    window[this._dataLayerName] = window[this._dataLayerName] || [];
    window[this._dataLayerName]['push'] = (data) => this._sendAnalyticsBody(data);
  }

  /**
   * Starts Google Analytics, if it wasn't started already, and ensures that Google Analytics userID is set.
   */
  private _startAnalytics(): void {
    // Initialize Analytics, if it wasn't initialized already.
    if (!this._analyticsEnabled) {
      this._initializeAnalytics();
    }

    this._isTracking = true;
  }

  public sendPageView({ page_location, page_title }: { page_location: string; page_title: string }): void {
    const pageViewData = {
      event_name: 'page_view',
      page_location,
      page_title,
    };

    if (this._shouldSendEvent) {
      window[this._dataLayerName].push(pageViewData);
    }
  }

  public sendSubPageView(
    data: ISubpageProps & {
      action: 'open' | 'close';
    }
  ): void {
    const pageViewData = { ...data, event_name: data.action + '_subpage' };

    delete pageViewData['action'];

    if (this._shouldSendEvent) {
      window[this._dataLayerName].push(pageViewData);
    }
  }

  /**
   * @param data Should have keys in snake_case
   * */
  public sendEvent(data: IAnalyticEventBody): void {
    if (this._shouldSendEvent) {
      window[this._dataLayerName].push(data);
    }
  }
}
