import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Application } from '@utomik-app-monorepo/store';
import { AsyncObjectState } from '@utomik-app-monorepo/store';
import { isNullOrUndefined, normalizeArray } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import 'moment-duration-format';
import React, { useCallback, useMemo } from 'react';
import { AchievementTile } from '../achievement-tile/achievement-tile';
import { AchievementsStatsPanel } from '../achievements-stats-panel/achievements-stats-panel';
import { Loading } from '../loading/loading';
import './achievements-tab.scss';
type Props = {
  app: Application;
  onFocus?: (props: any) => void;
};
export const AchievementsTab = withFocusable()<Props>(observer(function AchievementsTab({
  app,
  onFocus
}) {
  const group = 'achievementGroupSortedByUnlockedRecently';
  const isLoading = !(app?.appVersion?.achievements?.state === AsyncObjectState.Done);
  const newAchievementsMap = normalizeArray([], 'id');
  const achievementsNotHidden = useMemo(() => app?.appVersion?.achievements?.[group]?.achievements.filter(x => !(x.hidden && isNullOrUndefined(x?.values.unlockedAt))), [app?.appVersion?.achievements?.items]);
  const hiddenAchievements = useMemo(() => app?.appVersion?.achievements.items.filter(x => {
    return x.hidden && isNullOrUndefined(x?.values.unlockedAt);
  }), [app?.appVersion?.achievements?.items]);
  const renderAchievementsList = useCallback(() => {
    return achievementsNotHidden.map((ach, idx) => {
      return <AchievementTile isNew={Boolean(newAchievementsMap[ach.id])} onBecameFocused={onFocus} key={ach.id} achievement={ach} focusKey={`achievement-${idx}`} />;
    });
  }, [achievementsNotHidden]);
  const component = [];
  if (isLoading) {
    component.push(<Loading loadingText={''} key={'loading'} />);
  } else {
    component.push(<AchievementsStatsPanel key={'stats-panel'} app={app} />);
    if (achievementsNotHidden?.length) {
      const achievementsList = renderAchievementsList();
      component.push(achievementsList);
    }
    if (hiddenAchievements?.length) {
      component.push(<AchievementTile onBecameFocused={onFocus} hiddenAchievementsCount={hiddenAchievements?.length} key={'hidden_achievements'} />);
    }
    if (!achievementsNotHidden?.length && !hiddenAchievements?.length) {
      component.push(<AchievementTile onBecameFocused={onFocus} isEmpty key={'empty_achievements'} />);
    }
  }
  return <>{component}</>;
}));