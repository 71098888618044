import * as React from 'react';
import { faStarHalfStroke as strokeHalfStar, faStar as strokeStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { NotificationFactoryContext } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FocusableButton } from '../focusable-button/focusable-button';
import './focusable-rating.scss';
type Props = {
  isEditable?: boolean;
  onChange?: (rating: number) => void;
  starsCount?: number;
  value: number;
  focusKeyPrefix?: string;
  focusValue?: number;
  setFocusValue?: (value: number) => void;
  className?: string;
  withButton?: boolean;
  onButtonPress?: (props?: any) => void;
};
enum StarsState {
  Empty,
  Half,
  Solid,
}
const FocusableDummy = withFocusable()(({
  setFocus
}) => {
  const handleClick = () => {
    dispatchEnterEvent();
  };
  const handleMouseOver = () => {
    setFocus();
  };
  return <div onClick={handleClick} onMouseOver={handleMouseOver} style={{
    width: '4rem',
    height: '4rem',
    zIndex: 1
  }} />;
});
const map = {
  0: [1, 2],
  1: [3, 4],
  2: [5, 6],
  3: [7, 8],
  4: [9, 10]
};
const SegmentedStar = observer(function SegmentedStar({
  isActive,
  state,
  onFocusPartial,
  onEnterPartial,
  idx,
  focusKeyPrefix
}) {
  const [hasFocusedChild, setHasFocusedChild] = useState(false);
  return <div className={cx('focusable-rating__star', {
    'focusable-rating__star--active': isActive,
    'focusable-rating__star--focus': hasFocusedChild
  })}>
      {state === StarsState.Empty && <FontAwesomeIcon className={'star-icon'} icon={strokeStar} />}
      {state === StarsState.Half && <FontAwesomeIcon className={'star-icon'} icon={strokeHalfStar} />}
      {state === StarsState.Solid && <FontAwesomeIcon className={'star-icon'} icon={solidStar} />}
      <div className={'segmented-star'}>
        {/*<FocusableDummy*/}
        {/*  onEnterPress={onEnterPartial}*/}
        {/*  focusKey={`${focusKeyPrefix}${map[idx][0]}`}*/}
        {/*  onBecameFocused={() => {*/}
        {/*    onFocusPartial(map[idx][0]);*/}
        {/*    setHasFocusedChild(true);*/}
        {/*  }}*/}
        {/*  onBecameBlurred={() => {*/}
        {/*    setHasFocusedChild(false);*/}
        {/*  }}*/}
        {/*/>*/}
        <FocusableDummy onEnterPress={onEnterPartial} focusKey={`${focusKeyPrefix}${map[idx][1]}`} onBecameFocused={() => {
        onFocusPartial(map[idx][1]);
        setHasFocusedChild(true);
      }} onBecameBlurred={() => {
        setHasFocusedChild(false);
      }} />
      </div>
    </div>;
});
const FocusableRating = withFocusable({
  forgetLastFocusedChild: true,
  trackChildren: true
})<Props>(({
  starsCount,
  focusValue,
  setFocusValue,
  value,
  onChange,
  hasFocusedChild,
  focusKeyPrefix,
  className
}) => {
  const notificationFactory = useContext(NotificationFactoryContext);
  useEffect(() => {
    if (!hasFocusedChild) {
      setFocusValue(value);
    }
  }, [hasFocusedChild, value]);
  const handleEnterPress = useCallback(() => {
    if (value !== focusValue) {
      notificationFactory.showGameRatedNotification();
      onChange(focusValue);
    }
  }, [value, focusValue]);
  return <div className={cx('focusable-rating__container', {
    [className]: !!className
  })}>
      {Array.from({
      length: starsCount
    }).map((_, idx) => {
      let starState: StarsState;
      if (focusValue / 2 >= idx + 1) {
        starState = StarsState.Solid;
      } else if (idx + 1 > Math.ceil(focusValue / 2)) {
        starState = StarsState.Empty;
      } else {
        starState = StarsState.Half;
      }
      return <SegmentedStar key={idx} idx={idx} focusKeyPrefix={focusKeyPrefix} onFocusPartial={setFocusValue} onEnterPartial={handleEnterPress} isActive={Math.ceil(value / 2) === idx + 1} state={starState} />;
    })}
    </div>;
});
export const GameRating: React.FC<Omit<Props, 'focusValue' | 'setFocusValue'>> = observer(function GameRating({
  starsCount = 5,
  value,
  onChange,
  focusKeyPrefix = 'star',
  className,
  withButton,
  onButtonPress
}) {
  const notificationFactory = useContext(NotificationFactoryContext);
  const [focusValue, setFocusValue] = useState<number>(value);
  useEffect(() => {
    setFocusValue(value);
  }, [value]);
  const handleButtonPress = (focusValue: number) => {
    notificationFactory.showGameRatedNotification();
    onButtonPress && onButtonPress(focusValue);
  };
  return <>
      <FocusableRating className={className} focusKeyPrefix={focusKeyPrefix} preferredChildFocusKey={`${focusKeyPrefix}${focusValue}`} starsCount={starsCount} onChange={onChange} value={withButton ? focusValue : value} focusValue={focusValue} setFocusValue={setFocusValue} />
      {withButton && <FocusableButton onEnterPress={handleButtonPress?.bind(null, focusValue)} className={'rate-button'} type={'secondary'} size={'xlarge'} focusable={!!focusValue}>
          Rate game
        </FocusableButton>}
    </>;
});