import { TIME_SHOW_NOTIFICATION } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, NotificationStackContext } from '@utomik-app-monorepo/store';
import { AnalyticItemTypes, SubpageTypes } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef } from 'react';
import './notification-host.scss';
export const NotificationHost = observer(function NotificationHost() {
  const notificationStack = useContext(NotificationStackContext);
  const analyticController = useContext(AnalyticControllerContext);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  const notification = notificationStack.notifications[0];
  const Content = notification?.content;
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (!notification) return undefined;
    analyticController.sendOpenSubPage({
      subpage_type: SubpageTypes.Notification,
      subpage_name: notification.key,
      item_type: AnalyticItemTypes.Game
    });
    timeoutRef.current = setTimeout(() => {
      clearTimeout(timeoutRef.current);
      notification.close();
      analyticController.sendCloseSubPage({
        subpage_type: SubpageTypes.Notification,
        subpage_name: notification.key,
        item_type: AnalyticItemTypes.Game
      });
    }, TIME_SHOW_NOTIFICATION);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [notification]);
  return notification ? <div className={cx(`notification__wrapper ${notification.classes.join(' ')}`, {
    'notification__wrapper--show': true
  })}>
      <Content />
    </div> : null;
});