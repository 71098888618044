import { getDeviceTag, isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { ObservableMap, flow, makeObservable, observable, override } from 'mobx';
import slug from 'slug';

import { RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncList } from '../objectStore/asyncList';
import { ObjectID } from '../objectStore/asyncObject';

export interface ApiChannelLink {
  id: number;
  query_filters: string[];
  slug: string;
}

export interface ChannelMeta {
  slug: string;
  analyticsName: string;
  // The analyticsName is either the slug (for normal channels) or
  // of the following format: generated,{genre/partner},{genre_id/partner_id},{name}
}

export enum ChannelType {
  genre,
  partner,
}

/**
 * A list of links from a genre or partner to the related channel
 */
export class ChannelLinkList extends AsyncList<ApiChannelLink> {
  @observable
  private _genreChannelLinkList: ObservableMap<ObjectID, string>;
  @observable
  private _partnerChannelLinkList: ObservableMap<ObjectID, string>;

  /**
   * @param requestQueue - The request queue.
   * @param url - The url of the endpoint for the channel links.
   */
  public constructor(
    requestQueue: RequestQueue,
    url = `v2/channels?category=style,genre,partner,other&fields=id,query_filters,slug&cloud_platform=${getDeviceTag()}`
  ) {
    super(requestQueue, 'genreLinkList', url);
    makeObservable(this);
  }

  /**
   * Get the channel links from the endpoint and add them to the lists.
   */
  public fetchAll = flow(function* (this: ChannelLinkList) {
    try {
      let channellinks = [];
      let atEnd = false;
      while (!atEnd) {
        const response = yield this.getItems(channellinks.length, Number.MAX_SAFE_INTEGER, RequestPriority.High);
        atEnd = response.atEnd;
        channellinks = channellinks.concat(response.items);
      }
      const genreRegex = /^genres.id\s*=\s*(\d+)$/;
      const partnerRegex = /^developers.id\s*=\s*(\d+) or publishers.id\s*=\s*(\d+)$/;
      this._genreChannelLinkList = new ObservableMap<ObjectID, string>();
      this._partnerChannelLinkList = new ObservableMap<ObjectID, string>();
      channellinks.forEach((link) => {
        if (!link?.query_filters || link.query_filters.length > 1) return;
        const queryFilter = link.query_filters[0];
        const genreMatch = genreRegex.exec(queryFilter);
        if (genreMatch) {
          this._genreChannelLinkList.set(Number(genreMatch[1]), link.slug);
        }
        const partnerMatch = partnerRegex.exec(queryFilter);
        if (partnerMatch) {
          this._partnerChannelLinkList.set(Number(partnerMatch[1]), link.slug);
        }
      });
    } catch (error: any) {
      if (error.message ? error.message !== 'aborted' : error !== 'aborted') {
        console.error(`Error: ${error}`);
      }
      throw error;
    }
  });

  /**
   * Get the channel that is related to the given genre or partner
   *
   * @param id - the id of the genre or partner
   * @param type - the type of channel we want to find (either genre or partner)
   * @param name - optional, the name of the channel (if we don't find an actual channel)
   * @returns - the slug of the channel
   */
  public getChannelMeta(id: number, type: ChannelType, name?: string): ChannelMeta {
    const meta = { slug: undefined, analyticsName: undefined };
    if (type == ChannelType.genre) {
      meta.slug = this._genreChannelLinkList?.get(id);
      if (!meta.slug && !isNullOrUndefined(name)) {
        meta.slug = `generated,genre,${id},${encodeURIComponent(name.replaceAll(',', ''))}`;
        meta.analyticsName = `generated-genre-${slug(name, { lower: true })}`;
      }
    } else if (type == ChannelType.partner) {
      meta.slug = this._partnerChannelLinkList?.get(id);
      if (!meta.slug && !isNullOrUndefined(name)) {
        meta.slug = `generated,partner,${id},${name.replaceAll(',', '')}`;
        meta.analyticsName = `generated-partner-${encodeURIComponent(name.replaceAll(',', ''))}`;
      }
    }
    if (!meta.analyticsName) meta.analyticsName = meta.slug;
    return meta;
  }

  @override
  public override unload(): void {
    super.unload();

    // Clearing these things when they aren't set yet causes errors obviously.
    // This can happen during the profile not filled out dialog, which happens before the preload.
    if (!isNullOrUndefined(this._genreChannelLinkList)) {
      this._genreChannelLinkList.clear();
    }
    if (!isNullOrUndefined(this._partnerChannelLinkList)) {
      this._partnerChannelLinkList.clear();
    }
  }
}
