import {
  GA_CLIENT_ID,
  GA_SESSION_ID,
  LOCALSTORAGE_ALC,
  LOCALSTORAGE_AUTH_CLOUD_API_TOKEN,
  LOCALSTORAGE_LG_TOKEN,
} from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { differenceInHours, isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { IReactionDisposer, action, autorun, computed, flow, makeObservable, observable } from 'mobx';

import { LgPersonalizedKeyToken } from '../../../dataStore/lgPersonalizedKeyToken/lgPersonalizedKeyToken';
import { SecurityToken } from '../../../dataStore/securityToken/securityToken';
import {
  ApiLoginKeyResponse,
  AuthorizationStore,
  LoginKeyResponse,
  TokenAuthResponse,
} from '../../../dataStore/stores/authorizationStore/authorizationStore';
import { ApiLGTokenRSSResponse, LGStore } from '../../../dataStore/stores/lgStore/lgStore';
import { DialogFactory } from '../dialogFactory/dialogFactory';
import { PersistentStore } from '../persistentStore/persistentStore';
import { PlatformController } from '../platformController/platformController';

export type LoginCallback = (token: SecurityToken) => Promise<void>;
export type LogoutCallback = () => Promise<void>;
export type LgPersonalKeyServiceStart = () => void;

export class TokenManager {
  private readonly _platformController: PlatformController;
  private readonly _securityToken: SecurityToken;
  private readonly _authorizationStore: AuthorizationStore;
  private readonly _sessionStore: PersistentStore;
  private readonly _lgStore: LGStore;
  private readonly _lgPersonalizedKeyToken: LgPersonalizedKeyToken;
  private readonly _dialogFactory: DialogFactory;

  private _loginCallback: LoginCallback;
  private _logoutCallback: LogoutCallback;
  private _beforeLogoutCallback: LogoutCallback;
  private _lgPersonalKeyServiceStart: LgPersonalKeyServiceStart;
  private readonly _withExpiryInterval: number;
  private readonly _expiryCheckFudge = 300; // in seconds

  // Initialization
  @observable
  private _isInitialized = false;
  @computed
  public get isInitialized(): boolean {
    return this._isInitialized;
  }

  // Login error
  @observable
  private _error: string = null;
  public get error(): string {
    return this._error;
  }

  @action
  public setError(error: string): void {
    this._error = error;
  }

  @action
  public clearError(): void {
    this._error = null;
  }

  /** "Busy" state for when login is in progress. */
  @observable
  private _busy = false;

  @computed
  public get busy(): boolean {
    return this._busy;
  }

  @action
  public setBusy(busy: boolean): void {
    this._busy = busy;
  }

  // JWT Autorun
  private _jwtAutorunDisposer: IReactionDisposer;
  private _jwtValidationTimer: NodeJS.Timeout;

  @computed
  public get isAuthorized(): boolean {
    return this._securityToken.isSet;
  }

  // Logging in with ALC splits these up, but it's still a handy shorthand for unit tests etc.
  @action
  public setTokenValueAndStartSession(token: TokenAuthResponse): void {
    this.token.setValue(token);
  }

  public get token(): SecurityToken {
    return this._securityToken;
  }

  public get lgToken(): LgPersonalizedKeyToken {
    return this._lgPersonalizedKeyToken;
  }

  /**
   * Returns if remember me should be enabled.
   * If set: it returns the stored value.
   * If not set: it returns the default (true)
   */
  public get rememberMe(): boolean {
    return false;
  }

  /**
   * Returns the account for the user that last logged in, if they had "remember me" enabled.
   */
  public get rememberedAccount(): string {
    return undefined;
  }

  /**
   * Handle token expiry behavior.
   */
  private _handleTokenExpiry(): void {
    // Handle JWT expiry
    this._jwtAutorunDisposer = autorun(() => {
      this._runInterval();
    });
  }

  /**
   * (Re)sets the interval.
   * Runs checkToken on tick if JWT is available.
   */
  private async _runInterval(): Promise<void> {
    // Clear existing interval
    clearInterval(this._jwtValidationTimer);
    if (this.isAuthorized) {
      log(`Tracking token expiration time.`);
      // Set new interval
      this._jwtValidationTimer = setInterval(async () => {
        await this.checkToken();
        if (this._platformController.isWebOS) await this.checkLGToken();
      }, this._withExpiryInterval);
    } else {
      log(`No longer tracking authorization expiration time.`);
    }
  }

  public async refreshOldToken(token: SecurityToken): Promise<boolean> {
    const timeUntilExpirationInHours = differenceInHours(token.expiresAt, new Date(Date.now()));

    // We use a bit of fudge here so that it is not as sensitive.
    // If we have an _withExpiryInterval and a few milliseconds left, we rather try to update right now!
    const nextCheckPlusFudgeInHours = (this._withExpiryInterval * 0.001 + this._expiryCheckFudge) / 3600;
    if (timeUntilExpirationInHours <= nextCheckPlusFudgeInHours) {
      // The strategy behind this comes down to 'if the token is going to expire before our next check (or we're too late), try to update it now'
      try {
        log(`Token expires in ${timeUntilExpirationInHours * 60} minutes, refreshing.`);
        await token.refresh();
      } catch (err) {
        console.warn(`Could not refresh token.`);
        return false;
      }
    } else {
      log(`Token expires in ${timeUntilExpirationInHours} hours.`);
    }

    // Token is valid.
    return true;
  }

  public async refreshOldLgToken(lgToken: LgPersonalizedKeyToken): Promise<boolean> {
    const timeUntilExpirationInHours = differenceInHours(lgToken.expiresAt, new Date(Date.now()));

    /**
     * We use a bit of fudge here so that it is not as sensitive.
     * If we have an _withExpiryInterval and a few milliseconds left, we rather try to update right now!
     */
    const nextCheckPlusFudgeInHours = (this._withExpiryInterval * 0.001 + this._expiryCheckFudge) / 3600;
    if (timeUntilExpirationInHours <= nextCheckPlusFudgeInHours) {
      /**
       * The strategy behind this comes down to 'if the token is going to expire before our next check (or we're too late),
       * try to update it now'
       */
      try {
        log(`LG token expires in ${timeUntilExpirationInHours * 60} minutes, refreshing.`);
        this.rssTokenAuth();
      } catch (err) {
        console.warn(`Could not refresh LG token.`);
        return false;
      }
    } else {
      log(`LG token expires in ${timeUntilExpirationInHours} hours.`);
    }

    // Token is valid.
    return true;
  }

  /**
   * Checks if our token is valid or expiring.
   * Try to update the JWT token if we can.
   * Returns if the token is valid or not.
   */
  public async checkToken(): Promise<boolean> {
    if (!this.isAuthorized) {
      log(`No token, ignoring check.`);
      return false;
    }

    return await this.refreshOldToken(this.token);
  }

  public async checkLGToken(): Promise<boolean> {
    if (!this.isAuthorized) {
      log(`No token, ignoring check.`);
      return false;
    }

    return await this.refreshOldLgToken(this.lgToken);
  }

  /**
   * Retrieves the ALC from the session or keytar.
   */
  public async getALC(): Promise<LoginKeyResponse | null> {
    const apiLoginKey: ApiLoginKeyResponse = this._sessionStore.get<ApiLoginKeyResponse>(LOCALSTORAGE_ALC);
    if (isNullOrUndefined(apiLoginKey)) {
      log(`No stored alc found.`);
      return null;
    } else {
      log(`Using session ALC.`);
    }

    const alc = new LoginKeyResponse(apiLoginKey);
    try {
      alc.validate();
      return alc;
    } catch (error) {
      console.error(`ALC invalid. (Reason: ${error})`);
      return null;
    }
  }

  /**
   * Handle login
   * 1. Obtain ALC.
   * 2. Handles session.
   * 3. Handles persistence.
   * 4. Authorizes with JWT.
   * @param email Account
   * @param password Password
   * @param persist Persist aka rememberMe
   */
  public async login(email: string, password: string): Promise<void> {
    this.setError('');
    try {
      this.setBusy(true);
      // Obtain hostname
      const hostname = 'web-gui-standalone';
      // Obtain ALC
      const alc = await this._authorizationStore.login(email, password, hostname);
      // Handle session
      this._sessionStore.set<string>('account', email);
      this._sessionStore.set<ApiLoginKeyResponse>(LOCALSTORAGE_ALC, alc.getApiLoginKeyResponse());

      await this.storeALC(alc);
      // Authorize
      await this.loginWithAlc(alc);
    } finally {
      this.setBusy(false);
    }
  }

  // Handles "remember me".
  public async storeALC(alc: LoginKeyResponse): Promise<void> {
    this._sessionStore.set<ApiLoginKeyResponse>(LOCALSTORAGE_ALC, alc.getApiLoginKeyResponse());
  }

  // Clear ALC token. Triggered on logout.
  public async clearALC(): Promise<void> {
    this._sessionStore.remove(LOCALSTORAGE_ALC);
  }

  public async clearCloudApiToken(): Promise<void> {
    this._sessionStore.remove(LOCALSTORAGE_AUTH_CLOUD_API_TOKEN);
  }

  public async clearLgToken(): Promise<void> {
    this._sessionStore.remove(LOCALSTORAGE_LG_TOKEN);
  }

  /**
   * Login with ALC.
   * 1. Obtain JWT.
   * 2. Invalidate other ALCs.
   * 3. Use JWT to finish login (start session, set state, and call loginCallback).
   * @param alc
   */
  public loginWithAlc = flow(function* (this: TokenManager, alc: LoginKeyResponse) {
    this.setError('');
    this.setBusy(true);

    try {
      // Obtain JWT
      const jwt = yield this._authorizationStore.loginWithALC(alc);
      // Invalidate other ALCs
      try {
        yield this._authorizationStore.invalidateAllOtherALCs(jwt, alc);
      } catch (error) {
        console.error(`Could not invalidate an alc. (Reason: ${error})`);
      }
      this.token.setValue(jwt);

      if (this._platformController.isWebOS) yield this.rssTokenAuth();
      yield this.loginCloudAPI();
      yield this.loginWithJWT();
    } catch (error) {
      yield this.clearALC();
      yield this.clearCloudApiToken();
      yield this.clearLgToken();
      throw error;
    } finally {
      this.setBusy(false);
    }
  });

  /**
   * Login with stored JWT.
   * 1. Start session.
   * 2. Set state.
   * 3. Call loginCallback.
   */
  public loginWithJWT = flow(function* (this: TokenManager) {
    log(`User ${this.token.userId} is now logged in`);

    // Token set and session started. Do the rest of the login stuff.
    if (this._loginCallback) yield this._loginCallback(this.token);
    return true;
  });

  public loginCloudAPI = flow(function* (this: TokenManager) {
    try {
      const token = yield this._authorizationStore.loginCloudAPI(this.token.value);

      this._sessionStore.set<string>(LOCALSTORAGE_AUTH_CLOUD_API_TOKEN, token.jwt);
    } catch (error) {
      console.log(`loginCloudAPI error: ${error}`);
    }
  });

  /**
   * This part is relevant for LG RSS Feed
   * */
  public rssTokenAuth = flow(function* (this: TokenManager) {
    try {
      const lgJwt = yield this._lgStore.rssTokenAPI(this.token.value);

      this._sessionStore.set<ApiLGTokenRSSResponse>(LOCALSTORAGE_LG_TOKEN, lgJwt);
      this._lgPersonalizedKeyToken.setValue(lgJwt);
      this._lgPersonalKeyServiceStart();
    } catch (error) {
      console.log(`loginRSSTokenAuth error: ${error}`);
    }
  });

  /**
   * Logout
   * 1. Gets information needed.
   * 2. Clears persistence.
   * 3. Clears session.
   * 4. Invalidates token.
   * 5. Ends session.
   * 6. Clears state.
   * 7. Callback.
   */
  public logout = flow(function* (this: TokenManager) {
    this.setError('');
    // Run before logout callback
    yield this._beforeLogoutCallback();

    // Get session variables
    const alc = new LoginKeyResponse(this._sessionStore.get<ApiLoginKeyResponse>(LOCALSTORAGE_ALC));

    // Clear session
    this._sessionStore.remove('account');
    // Clear [LOCALSTORAGE_ALC]
    yield this.clearALC();
    // Clear [LOCALSTORAGE_AUTH_CLOUD_API_TOKEN]
    yield this.clearCloudApiToken();
    // Clear [LOCALSTORAGE_LG_TOKEN]
    yield this.clearLgToken();

    // Invalidate the ALC
    if (!isNullOrUndefined(alc)) {
      // We don't want to wait for this to finish on logout, so no await
      this._authorizationStore
        .invalidate(this.token.value, alc)
        .catch((e) => console.error(`Could not invalidate alc. (Reason: ${e})`));
    }

    // Clear JWT and session.
    this.setTokenValueAndStartSession(null);

    // And do the logoutCallback
    if (this._logoutCallback) this._logoutCallback();

    localStorage.removeItem(GA_CLIENT_ID);
    sessionStorage.removeItem(GA_SESSION_ID);
  });

  /**
   * Initializes the token manager if needed.
   * 1. Ensures the serviceClient is connected.
   * 2. Attempts to login.
   */
  public init = flow(function* (this: TokenManager) {
    // Don't initialize if already initialized.
    if (this._isInitialized) {
      console.error(`Already initialized`);
      return;
    }

    this._securityToken.init(
      this._authorizationStore,
      () => {
        // no-op
      },
      () => this.getALC()
    );

    // Login.
    try {
      const alc = yield this.getALC();
      if (alc) {
        yield this.loginWithAlc(alc);
        log(`Successfully logged in.`);
      }
    } catch (error: any) {
      // We continue if we couldn't log in for some reason.
      console.error(`Could not login: ${error?.message ? error.message : error}`);
    }
    log(`Initialized.`);
    this._isInitialized = true;
  });

  /**
   * TokenManager constructor
   * @param securityToken
   * @param authorizationStore AuthorizationStore
   * @param sessionStore Browser session
   * @param lgStore LG stuff
   * @param lgPersonalizedKeyToken LG RSS
   * @param dialogFactory DialogFactory
   * @param loginCallback LoginCallback
   * @param beforeLogoutCallback
   * @param logoutCallback LogoutCallback
   * @param withExpiryInterval Interval to check token validity. Defaults 0, which disables this behavior.
   */
  public constructor(
    platformController: PlatformController,
    securityToken: SecurityToken,
    authorizationStore: AuthorizationStore,
    sessionStore: PersistentStore,
    lgStore: LGStore,
    lgPersonalizedKeyToken: LgPersonalizedKeyToken,
    dialogFactory: DialogFactory,
    loginCallback: LoginCallback,
    beforeLogoutCallback: LogoutCallback,
    logoutCallback: LogoutCallback,
    lgPersonalKeyServiceStart: LgPersonalKeyServiceStart,
    withExpiryInterval = 0
  ) {
    makeObservable(this);
    this._platformController = platformController;
    this._securityToken = securityToken;
    this._authorizationStore = authorizationStore;
    this._sessionStore = sessionStore;
    this._lgStore = lgStore;
    this._lgPersonalizedKeyToken = lgPersonalizedKeyToken;
    this._dialogFactory = dialogFactory;
    this._loginCallback = loginCallback;
    this._beforeLogoutCallback = beforeLogoutCallback;
    this._logoutCallback = logoutCallback;
    this._lgPersonalKeyServiceStart = lgPersonalKeyServiceStart;
    this._withExpiryInterval = withExpiryInterval;

    if (withExpiryInterval > 0) this._handleTokenExpiry();
  }

  public dispose = () => {
    clearInterval(this._jwtValidationTimer);
    this._jwtAutorunDisposer();
  };
}
