import { action, computed, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncObject, AsyncObjectState } from '../objectStore/asyncObject';

export interface ApiIssueType {
  id: number;
  name: string;
  value: string;
}

export class IssueType {
  public id: number;
  public name: string;
  public value: string;
}

export class IssueTypes extends AsyncObject<ApiIssueType[]> {
  public constructor(requestQueue: RequestQueue, url = `v1/support/customer/issuetypes`) {
    super(requestQueue, 0, url);
    makeObservable(this);
  }

  @observable
  private _items: IssueType[] = [];
  @computed
  public get items(): IssueType[] {
    return this._items;
  }
  @action
  private setIssueTypes(issueTypes: IssueType[]): void {
    this._items = issueTypes;
  }

  @action
  public load(apiIssueTypes: ApiIssueType[]): void {
    try {
      this.setIssueTypes(
        apiIssueTypes.map(
          (apiIssueType): IssueType => ({
            id: apiIssueType.id,
            name: apiIssueType.name,
            value: apiIssueType.value,
          })
        )
      );
    } catch (ex) {
      throw new Error(`Failed loading issueTypes.`);
    }
  }

  @action
  public unload(): void {
    this.setState(AsyncObjectState.None);
    this._items = [];
  }
}
