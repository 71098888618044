import { getCurrentVersion } from '@utomik-app-monorepo/utils';

import { WindowData } from '../tizen/interfaces/application';

export class AndroidTVWindowData extends WindowData {
  private readonly _appInfo: { version: string };

  constructor() {
    super();
    if (window.cordova) {
      this._appInfo = {
        version: getCurrentVersion(),
      };
    }
  }

  get instance() {
    return window.cordova;
  }

  get deviceId(): string {
    return window.device.uuid;
  }

  get getAppInfo(): { version: string } {
    return this._appInfo;
  }

  get getDeviceInfo(): { version: string; model: string; brand: string } {
    return {
      brand: window.device?.brand ?? 'Android TV brand',
      model: window.device?.model ?? 'Android TV model',
      version: window.device?.version ?? 'Android TV version',
    };
  }

  get hasBluetoothSupport(): boolean {
    //TODO: ask about the method to check bluetooth support
    return true;
  }

  get allowedToPlayMainVideo(): boolean {
    return true;
  }
}
