export class CustomGamepad implements Gamepad {
  public id = 'VirtualOnScreenGamepad';
  public mapping: GamepadMappingType = 'standard';
  public index = 0;
  public connected = true;
  public _axes = [0, 0, 0, 0];
  public timestamp = 0;
  public hapticActuators = null;
  public vibrationActuator = null;
  private _buttons = [
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
    { pressed: false, touched: false, value: 0 },
  ];
  public setButton = (index: number, pressed: boolean, value = 0) => {
    if (this._buttons[index]) {
      this._buttons[index].pressed = pressed;
      this._buttons[index].value = value;
    }
  };

  public get buttons() {
    return this._buttons;
  }

  public setAxes = (stick: 'left' | 'right', { x, y }: { x: number; y: number }) => {
    if (stick === 'left') {
      this._axes[0] = x;
      this._axes[1] = y * -1;
    } else if (stick === 'right') {
      this._axes[2] = x;
      this._axes[3] = y * -1;
    }
  };

  public get axes() {
    return this._axes;
  }
}
