export enum ControllerTypes {
  FullController = 'full_controller',
  Touch = 'touch',
  Keyboard = 'keyboard',
  Mouse = 'mouse',
}

export interface IConnectKeyboard {
  type: ControllerTypes.Keyboard;
  action: 'connected';
  LShiftBtnState: boolean;
  RShiftBtnState: boolean;
  LAltBtnState: boolean;
  RAltBtnState: boolean;
  LCtrlBtnState: boolean;
  RCtrlBtnState: boolean;
}
export interface IDisconnectKeyboard {
  type: ControllerTypes.Keyboard;
  action: 'disconnected';
}
export enum KeyboardPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export enum KeyboardButtonState {
  Pressed = 'pressed',
  Released = 'released',
}
export interface IConnectKeyboard {
  type: ControllerTypes.Keyboard;
  action: 'connected';
  LShiftBtnState: boolean;
  RShiftBtnState: boolean;
  LAltBtnState: boolean;
  RAltBtnState: boolean;
  LCtrlBtnState: boolean;
  RCtrlBtnState: boolean;
}
export interface IDisconnectKeyboard {
  type: ControllerTypes.Keyboard;
  action: 'disconnected';
}

export interface IKeyboardButtonBody {
  code: string;
  key: string;
  shiftKey: boolean;
  which: number;
  keyCode: number;
}

export type KeyboardLayout = {
  default: { value: string; code: number; shiftPressed: boolean }[][];
  capsLock: { value: string; code: number; shiftPressed: boolean }[][];
  symbols: { value: string; code: number; shiftPressed: boolean }[][];
};

export type KeyboardMode = keyof KeyboardLayout;

export interface IKeyboardController {
  shift: KeyboardButtonState;
  mode: KeyboardMode;
  position: KeyboardPosition;
  isKeyboardVisible: boolean;

  setIsKeyboardVisible(isVisible: boolean): void;
  switchMode(value: KeyboardMode): void;
  switchPosition(): void;
  handleKeyPress(keyCode: string): void;
  handleKeyRelease(keyCode: string): void;
  setWS(ws: WebSocket): void;
  init(): void;
  reset(): void;
  dispose(): void;
}
