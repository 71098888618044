import { Trans, t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableBackButton, FocusableButton } from '@utomik-app-monorepo/components';
import { LoginControllerContext, LoginLocation, NavigationControllerContext } from '@utomik-app-monorepo/store';
import logo from 'images/svg/horizontalLogo.svg';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { AuthorizationForm } from './authForm';
import styles from './authorization.module.scss';

//import { SsoAuthorization } from './sso-authorization/sso-authorization';

export const Authorization = withFocusable()(observer(() => {
  const navigationController = useContext(NavigationControllerContext);
  const loginController = useContext(LoginControllerContext);
  const handleBack = () => {
    loginController.setLocation(-1);
  };
  return <div className={styles.container}>
        {loginController.location === LoginLocation.Login && <FocusableBackButton showOutline={navigationController.isGamepadInteraction} onEnterPress={handleBack} className={styles.backButton} showLabel={navigationController.isGamepadInteraction} />}
        <h1 className={styles.loginText}>{t({
        context: 'Button - Log in action',
        message: 'Log in'
      })}</h1>
        <span className={styles.haveAccount}>
          {t({
        message: 'Don’t have an account?'
      })}

          <FocusableButton showOutline={navigationController.isGamepadInteraction} type={'transparent'}>
            <a>{t({
            context: 'Button - Sign up action',
            message: 'Sign up'
          })}</a>
          </FocusableButton>
        </span>
        <img className={styles.logo} src={logo} alt={'vertical-utomik-logo'} />
        <div className={styles.formContainer}>
          <AuthorizationForm />

          {/*<div className={styles.orLoginWith}>or login with</div>*/}

          {/*<SsoAuthorization />*/}
        </div>
        {loginController.location === LoginLocation.Login && <FocusableButton showOutline={navigationController.isGamepadInteraction} type={'transparent'}>
            <p className={styles.forgotPasswordText}>
              {t({
          context: 'Button - forgot password action',
          message: 'Forgot password'
        })}
            </p>
          </FocusableButton>}
        <span className={styles.terms}>
          <Trans context={'Terms of service confirmation text'}>
            By logging in you agree to Utomik’s
            <FocusableButton showOutline={navigationController.isGamepadInteraction} type={'transparent'}>
              <a>terms of service</a>
            </FocusableButton>
          </Trans>
        </span>
      </div>;
}));