import { pathTv } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';
import './loading.scss';
interface P {
  loadingText?: string;
  classNames?: string[];
  size?: 'small' | 'medium' | 'large';
}
export const Loading: FC<P> = observer(function Loading({
  loadingText,
  classNames = [],
  size = 'medium'
}: P): ReactElement {
  const getClass = (cn: string[] = []): string => {
    const classes = [...cn, ...classNames];
    return classes.join(' ');
  };
  const imgUrl = pathTv + require('images/img/loading-spinner.png');
  return <div className={'app-wrapper--no-gradient'}>
      <div className={getClass([cx(`logo-loading`, {
      [`logo-loading--${size}`]: true
    })])}>
        <div className="logo-loading__container">
          <img decoding={'sync'} alt={'logo-loading'} src={imgUrl} />
        </div>
        <p className="logo-loading-text">{loadingText}</p>
      </div>
    </div>;
});