import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { NothingFound } from '@utomik-app-monorepo/components';
import { Routes } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, AnalyticItemTypes, Application, ApplicationStoreContext, AsyncObjectState, Channel, ChannelStoreContext, ClientControllerContext, HomeControllerContext, NavigationControllerContext, SearchResultStoreContext } from '@utomik-app-monorepo/store';
import { joinLocation } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TileCarousel } from '../../../components/tile-carousel/tile-carousel';
import styles from './search.module.scss';
export const Search = withFocusable()(observer(({
  setFocus
}) => {
  const [items, setItems] = useState<(Application | Channel)[]>([]);
  const navigationController = useContext(NavigationControllerContext);
  const clientController = useContext(ClientControllerContext);
  const searchResultStore = useContext(SearchResultStoreContext);
  const analyticController = useContext(AnalyticControllerContext);
  const applicationStore = useContext(ApplicationStoreContext);
  const homeController = useContext(HomeControllerContext);
  const channelStore = useContext(ChannelStoreContext);
  const navigate = useNavigate();
  useEffect(() => {
    homeController.init();
    homeController.providers.init();
  }, []);
  useEffect(() => {
    const items = searchResultStore.searchResultList?.searchResultsUngrouped.map(item => {
      let resItem: Application | Channel;
      if (item.type === 'application') {
        resItem = applicationStore.getItem(item.item.id);
      } else if (item.type === 'channel') {
        resItem = channelStore.getItem(item.item.id);
      }
      return resItem;
    });
    setItems(items || []);
  }, [searchResultStore.searchResultList?.searchResultsUngrouped]);
  useEffect(() => {
    setFocus(searchResultStore.scrollState.currentFocusKey || 'search-field');
  }, []);
  const handleFocus = (props, itemProps) => {
    searchResultStore.scrollState.currentFocusKey = itemProps.focusKey;
  };
  const handlePress = props => {
    const breadcrumbs = analyticController.getBreadCrumbs({
      currentSection: props.carouselName,
      isCarousel: true
    });
    if (props.type === 'application') {
      analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Game,
        location_on_page: joinLocation(breadcrumbs)
      });
      navigate(`${Routes.AppInfo}/${props.item.id}`);
    } else if (props.type === 'channel') {
      analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Channel,
        location_on_page: joinLocation(breadcrumbs)
      });
      navigate(`${Routes.Channels}/${props.item.id}`);
    }
  };
  const handleResetSearch = () => {
    searchResultStore.clear();
    setFocus('search-field');
  };
  return <div className={styles.container}>
        <div className={styles.scrollableContainer}>
          {(!!searchResultStore.searchResultList?.searchResults?.length && searchResultStore.searchResultList?.state === AsyncObjectState.Done || !!searchResultStore.searchTerm.length && searchResultStore.searchResultList?.state !== AsyncObjectState.Done) && <TileCarousel name={t({
        message: 'Top results'
      })} isLoadingCarousel={false} focusKey={'rop_results'} key={'top_results'} onChildPress={handlePress} onChildFocused={handleFocus} itemsList={{
        items: items,
        atEnd: true
      }} showOutline={navigationController.isGamepadInteraction} showPlatformIcons={clientController.user.isAdmin} />}

          {/*{!!searchResultStore.searchTerm?.length &&*/}
          {/*  searchResultStore.searchResultList?.state !== AsyncObjectState.Done && (*/}
          {/*    <Loading size={'small'} classNames={[styles.searchLoading]} loadingText={t('SEARCH_SEARCHING')} />*/}
          {/*  )}*/}

          {!searchResultStore.searchResultList?.searchResults?.length && searchResultStore.searchResultList?.state === AsyncObjectState.Done && <NothingFound className={styles.nothingFound} title={t({
        message: 'Sorry, we could not find anything'
      })} subtitle={t({
        message: 'Try change request'
      })} onButtonPress={handleResetSearch} withButton />}

          <TileCarousel name={homeController.providers?.recommendedApplications?.name} isLoadingCarousel={false} focusKey={homeController.providers?.recommendedApplications?.slug} key={homeController.providers?.recommendedApplications?.slug} onChildFocused={handleFocus} onChildPress={handlePress} provider={homeController.providers.recommendedApplications} showOutline={navigationController.isGamepadInteraction} showPlatformIcons={clientController.user.isAdmin} />
        </div>
      </div>;
}));