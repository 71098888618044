import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { BaseObject, ObjectStore } from '../objectStore/objectStore';

export class ApiGlobalUnlockedAchievement implements BaseObject {
  id: ObjectID;
  unlocked_at: Date;
}

//If we didn't need to get rid of the underscore from the API object,
//This entire next class would be obsolete.
export class GlobalUnlockedAchievement implements BaseObject {
  private readonly _apiObj: Readonly<ApiGlobalUnlockedAchievement>;

  constructor(apiObj: ApiGlobalUnlockedAchievement) {
    this._apiObj = apiObj;
  }
  public get unlockedAt(): Date {
    return this._apiObj.unlocked_at;
  }
  public get id(): ObjectID {
    return this._apiObj.id;
  }
}

export class GlobalUnlockedAchievementStore extends ObjectStore<
  GlobalUnlockedAchievement,
  ApiGlobalUnlockedAchievement
> {
  public constructor(requestQueue: RequestQueue, url = 'v1/users/me/global/unlockedachievements', retries = null) {
    super(requestQueue, url, retries);
  }
  protected createAndLoadItem(x: ApiGlobalUnlockedAchievement): GlobalUnlockedAchievement {
    return new GlobalUnlockedAchievement(x);
  }
}
