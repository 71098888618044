import { log } from '@utomik-app-monorepo/logger';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { DialogFactory } from '../dialogFactory/dialogFactory';
import { DialogQueue } from '../dialogQueue/dialogQueue';

export class ConnectivityReporter {
  private readonly _reactionDisposer: ReturnType<typeof reaction>;
  /**
   * This will be the observed value of being connected.
   * Powered by MobX.
   */
  @observable
  private _connected = true;

  @observable
  private _enabled = false;

  /**
   * An action to set the connection status, powered by MobX.
   * @param connected If we're connected or not.
   */
  @action
  private setConnected(connected: boolean): void {
    this._connected = connected;
  }
  /**
   * A computed value, powered by MobX.
   * This reports the connection status.
   */
  @computed
  public get connected(): boolean {
    return this._connected;
  }

  /**
   * A computed value, powered by MobX.
   * Indicates if the connectivity reporter is enabled or not.
   */
  @computed
  public get enabled(): boolean {
    return this._enabled;
  }

  /**
   * Enables the connectivity reporter.
   */
  @action
  public enable(): void {
    this._enabled = true;
    this.updateOnlineStatus();
  }

  /**
   * Disables the connectivity reporter.
   */
  @action
  public disable(): void {
    this._enabled = false;
  }

  /**
   * This function should be called when we want to update the network status.
   * It will log and update the status if it has changed.
   */
  private updateOnlineStatus(): void {
    // if (!this._enabled) {
    //   return;
    // }
    // It may be important to log this information for reference in the future.
    log(navigator.onLine ? `Connection established.` : `Connection lost.`);
    // Update the state, and let MobX handle the magic for any observing components.
    this.setConnected(navigator.onLine);
  }

  public constructor(dialogFactory: DialogFactory, dialogQueue: DialogQueue) {
    makeObservable(this);
    if (!window?.addEventListener) {
      // Warn when window wasn't available.
      console.warn(`Window not available. Listeners not enabled.`);
    } else if (!navigator) {
      // Warn when navigator wasn't available.
      console.warn(`Navigator not available. Listeners not enabled.`);
    } else {
      log(`Listeners enabled.`);
      // Set the listeners.
      window.addEventListener('online', this.updateOnlineStatus.bind(this));
      window.addEventListener('offline', this.updateOnlineStatus.bind(this));
      // Set the initial values.
      this.updateOnlineStatus();
    }

    this._reactionDisposer = reaction(
      () => this._connected,
      () => {
        if (dialogQueue?.currentDialog?.analyticName === 'MediaViewerDialog') return;

        if (!this._connected) {
          dialogFactory.showConnectionDialog();
        } else {
          dialogQueue.removeCurrent();
        }
      }
    );

    if (!this._connected) {
      dialogFactory.showConnectionDialog();
    }
  }

  public dispose() {
    window.removeEventListener('online', this.updateOnlineStatus.bind(this));
    window.removeEventListener('offline', this.updateOnlineStatus.bind(this));
    this._reactionDisposer();
  }
}
