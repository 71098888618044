import { t } from '@lingui/macro';
import { TokenManagerContext } from '@utomik-app-monorepo/store';
import { DialogProps, DialogResult } from '@utomik-app-monorepo/store';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Loading } from '../../loading/loading';
import './logging-out-dialog.scss';
export const LoggingOutDialog: React.FC<DialogProps> = observer(function LoggingOutDialog({
  action
}): React.ReactElement {
  const tokenManager = useContext(TokenManagerContext);
  useEffect(() => {
    const disposer = reaction(() => tokenManager.isAuthorized, () => {
      if (!tokenManager.isAuthorized) {
        action(DialogResult.Close);
      }
    });
    return () => disposer();
  }, []);
  return <div className="logout-dialog__container">
      <div>
        <h2 className="logout-dialog--title">
          <>{t({
            context: 'Logout dialog message',
            message: 'Logging out'
          })}</>
        </h2>
        <p className="logout-dialog--subtitle">{t({
          context: 'Logout dialog message',
          message: 'Please wait'
        })}</p>
      </div>
      <Loading classNames={['loading-logout']} size={'small'} />
    </div>;
});
export default LoggingOutDialog;