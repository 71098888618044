import { i18n } from '@lingui/core';

import { messages as deMessages } from './de/messages.js';
import { messages as enMessages } from './en/messages.js';
import { messages as esMessages } from './es/messages.js';
import { messages as frMessages } from './fr/messages.js';
import { messages as itMessages } from './it/messages.js';
import { messages as nlMessages } from './nl/messages.js';

i18n.load({
  en: enMessages,
  de: deMessages,
  es: esMessages,
  fr: frMessages,
  it: itMessages,
  nl: nlMessages,
});

export { i18n };
