import { log } from '@utomik-app-monorepo/logger';
import { Platform, checkCurrentPlatform } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { KeyboardController } from '../keyboardController/keyboardController';

type Options = {
  callbackInterval?: number;
  subscribe?: boolean;
  sleep?: boolean;
  autoAlign?: boolean;
  keys?: string[];
};

export class MouseController {
  private readonly _reactionDisposer: ReturnType<typeof reaction>;
  private _keyboardController: KeyboardController;
  private _webOSRef = null;

  @observable
  private _hoveredElement: Element;

  @observable
  private _isCursorVisible = false;

  constructor(keyboardController: KeyboardController) {
    this._keyboardController = keyboardController;

    const request = this._requestCursor(
      () => {
        this._isCursorVisible = true;
        request.cancel();
      },
      () => {
        this._isCursorVisible = false;
      }
    );

    this._reactionDisposer = reaction(
      () => this._hoveredElement,
      (arg, prev) => {
        if (prev) {
          const event = new MouseEvent('mouseleave', {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          prev.dispatchEvent(event);
          return true;
        } else {
          log('No previously hovered element with magic remote');
          return false;
        }
      }
    );

    this.init();

    makeObservable(this);
  }

  @action
  private _setIsCursorVisible(isVisible: boolean) {
    this._isCursorVisible = isVisible;
  }
  @computed
  public get isCursorVisible() {
    return this._isCursorVisible;
  }

  private _requestCursor = (onSuccess: (coords?: any) => void, onError: () => void) => {
    return window?.webOS?.service?.request('luna://com.webos.service.mrcu', {
      method: 'sensor/getSensorData',
      parameters: {
        callbackInterval: 1,
        subscribe: true,
        sleep: true,
        autoAlign: false,
      } as Options,
      onSuccess: (inResponse) => {
        if (inResponse?.coordinate) {
          onSuccess(inResponse?.coordinate);
        }
        return true;
      },
      onFailure: (inError) => {
        onError && onError();
        console.log('Failed to get sensor data');
        console.log('[' + inError.errorCode + ']: ' + inError.errorText);
        return false;
      },
    });
  };

  private _handleCursorVisibility = (e) => {
    this._setIsCursorVisible(e.detail.visibility);

    if (this.isCursorVisible) {
      // Call sensor/getSensorData with subscription
      this._webOSRef = window?.webOS.service.request('luna://com.webos.service.mrcu', {
        method: 'sensor/getSensorData',
        parameters: {
          callbackInterval: 1,
          subscribe: true,
          sleep: true,
          autoAlign: false,
        },
        onSuccess: (inResponse) => {
          if (inResponse?.coordinate) {
            this._handleCoordinateChange(inResponse.coordinate);
          }
          return true;
        },
        onFailure: (inError) => {
          console.log('Failed to get sensor data');
          console.log('[' + inError.errorCode + ']: ' + inError.errorText);
          return false;
        },
      });
      this._webOSRef = this._requestCursor((coords) => {
        this._handleCoordinateChange(coords);
      }, null);
    } else {
      this._webOSRef?.cancel();
    }
  };

  private _handleCoordinateChange = ({ x, y }) => {
    if (this._keyboardController.isKeyboardVisible) return;

    const element = document.elementFromPoint(x, y);

    if (!element || element === this._hoveredElement) return;

    const event = new MouseEvent('mouseenter', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    element.dispatchEvent(event);

    this._hoveredElement = element;
  };

  public init() {
    const platform = checkCurrentPlatform();
    if (platform === Platform.WebOS) {
      document.addEventListener('cursorStateChange', this._handleCursorVisibility);
      log('Mouse controller is Initialized (WebOS)');
    }
    //for testing in web mode
    if (!platform) {
      document.body.addEventListener('mouseover', () => {
        this._setIsCursorVisible(true);
      });
      document.body.addEventListener('mouseleave', () => {
        this._setIsCursorVisible(false);
      });
    }
  }

  public dispose() {
    document.removeEventListener('cursorStateChange', this._handleCursorVisibility);
    this._webOSRef?.cancel();
    this._reactionDisposer();
  }
}
