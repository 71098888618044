import { log } from '@utomik-app-monorepo/logger';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { EventEmitter } from 'events';
import remove from 'lodash/remove';
import { action, computed, makeObservable, observable } from 'mobx';
import { AnalyticController } from '../analyticController/analyticController';
import { SubpageTypes } from '../analyticController/clients/googleAnalyticsManager';
export interface DialogActionButton {
  name: string;
  action: () => void;
  extraClasses?: string[];
  disabled?: boolean;
}
export interface IDialog {
  analyticName: string;
  analyticValue?: number;
  enableAnalytic?: boolean;
  outsideClickClose?: boolean;
  showClose?: boolean;
  onTop?: boolean;
  props?: any;
  onResult: DialogOnResult;
  isClosable?: boolean;
  withGamepadControls?: boolean;
  title?: string;
  showHeader?: boolean;
}
export const enum DialogResult {
  Close = 0,
  OK = 1,
  Continue = 2,
  Cancel = 3,
  Retry = 4,
  Action1 = 5,
  Action2 = 6,
}
export const getDialogResultName = (result: DialogResult): string => {
  switch (result) {
    case DialogResult.Action1:
      return 'Action1';
    case DialogResult.Action2:
      return 'Action2';
    case DialogResult.Cancel:
      return 'Cancel';
    case DialogResult.Close:
      return 'Close';
    case DialogResult.Continue:
      return 'Continue';
    case DialogResult.OK:
      return 'OK';
    case DialogResult.Retry:
      return 'Retry';
    default:
      return 'Unknown';
  }
};
export type DialogProps = {
  action: (action: DialogResult, value?: unknown) => void;
};
export type DialogContent = (dialogProps: DialogProps) => React.ReactElement;
export type DialogOnResult = (result: DialogResult, value?: unknown) => void;

/**
 * Dialog Class
 */
export class Dialog {
  private readonly _dialogQueue: DialogQueue;
  private readonly _analyticController: AnalyticController;
  private _outsideClickClose: boolean;
  private _isClosable: boolean;
  private _withGamepadControls: boolean;
  private _title: string;
  private _props: any;
  private _onResult: (result: DialogResult, value: unknown) => void;
  public readonly analyticName: string;
  public readonly enableAnalytic: boolean;
  @observable
  private _showHeader: boolean;
  @observable
  private _showClose: boolean;
  public get isClosable(): boolean {
    return this._isClosable;
  }
  public set isClosable(isClosable) {
    this._isClosable = isClosable;
  }
  @action
  public setShowHeader(show: boolean) {
    this._showHeader = show;
  }
  public get showHeader() {
    return this._showHeader;
  }
  public get withGamepadControls(): boolean {
    return this._withGamepadControls;
  }

  /**
   * Tells you if the dialog is closable by clicking outside.
   */
  public get outsideClickClose(): boolean {
    return this._outsideClickClose;
  }
  /**
   * Tells you if the dialog is closable by clicking the close button.
   */
  public get showClose(): boolean {
    return this._showClose;
  }
  public set showClose(showClose) {
    this._showClose = showClose;
  }
  public get title() {
    return this._title;
  }
  /**
   * Calls a DialogContent function to pass DialogProps.
   */
  public get props(): React.ReactElement {
    return {
      ...this._props,
      action: (result: DialogResult, value: unknown): void => {
        this.action(result, value);
      }
    };
  }
  /**
   * An action handle for dialogs.
   * @param result The DialogAction to distinuish between actions.
   * @param value An optional variable incase an action returns a value (i.e. an identifier)
   */
  public action(result: DialogResult, value?: unknown): void {
    log(`Dialog "${this.analyticName}" result: "${getDialogResultName(result)}"` + `${value ? ` with "${value}"` : ``}`);
    this._onResult(result, value);
    this._dialogQueue.remove(this);
    this._analyticController.sendCloseSubPage({
      subpage_name: this.analyticName,
      subpage_type: SubpageTypes.Dialog,
      result: String(result)
    });
  }

  /**
   * Constructor
   * @param dialogQueue dialogQueue is required so that we can tell it that the dialog is closing.
   * @param showClose Tell the dialog that it can be closed by clicking next to it.
   * @param content A DialogContent function that accepts DialogProps and returns some react function.
   * @param onResult A callback method that is called when an action is made.
   */
  public constructor(dialogQueue: DialogQueue, outsideClickClose: boolean, showClose: boolean, props: any, onResult: DialogOnResult, analyticName: string, enableAnalytic: boolean, isClosable: boolean, withGamepadControls: boolean, analyticController: AnalyticController, analyticValue: any, title: string, showHeader: boolean) {
    this._dialogQueue = dialogQueue;
    this._outsideClickClose = outsideClickClose;
    this._showClose = showClose;
    this._props = props;
    this._onResult = isNullOrUndefined(onResult) ? (): void => {
      // do nothing
    } : onResult;
    this.analyticName = analyticName;
    this.enableAnalytic = enableAnalytic;
    this._isClosable = isClosable;
    this._withGamepadControls = withGamepadControls;
    this._analyticController = analyticController;
    this._title = title;
    this._showHeader = showHeader;
    if (this.enableAnalytic) {
      this._analyticController.sendOpenSubPage({
        subpage_name: this.analyticName,
        subpage_type: SubpageTypes.Dialog
      });
    }
    makeObservable(this);
  }
}
export declare interface DialogQueue {
  on(event: 'addDialog', listener: () => void): this;
  on(event: 'removeDialog', listener: (ev: {
    name: string;
  }) => void): this;
}

/**
 * DialogQueue, responsible for handling all Dialogs.
 */
export class DialogQueue extends EventEmitter {
  @observable
  private _dialogs: Dialog[] = [];
  private readonly _analyticController: AnalyticController;
  public constructor(analyticController: AnalyticController) {
    super();
    this._analyticController = analyticController;
    makeObservable(this);
  }

  /**
   * Creates and adds a dialog to the array of dialogs.
   * @param iDialog IDialog object that describes the dialog.
   */
  @action
  public add({
    outsideClickClose = false,
    showClose = false,
    props = {},
    onResult,
    analyticName = '',
    analyticValue,
    onTop = false,
    enableAnalytic = true,
    isClosable = true,
    withGamepadControls = false,
    title = '',
    showHeader = false
  }: IDialog): Dialog {
    const dialog = new Dialog(this, outsideClickClose, showClose, props, onResult, analyticName, enableAnalytic, isClosable, withGamepadControls, this._analyticController, analyticValue, title, showHeader);
    if (onTop) {
      this._dialogs.unshift(dialog);
    } else {
      this._dialogs.push(dialog);
    }
    this.emit('addDialog');
    return dialog;
  }
  @computed
  public get hasDialogs(): boolean {
    return this._dialogs.length > 0;
  }

  /**
   * This is like hasDialogs but only counts dialogs that are not non-modal.
   */
  @computed
  public get hasModalDialogs(): boolean {
    return this._dialogs.filter(x => !x.showClose && !x.outsideClickClose).length > 0;
  }

  /**
   * Removes a dialog from the array of dialogs.
   * @param removedDialog Instance of Dialog you wish to remove.
   */
  @action
  public remove(removedDialog: Dialog): void {
    remove(this._dialogs, (dialog: Dialog) => {
      return dialog === removedDialog;
    });
    this.emit('removeDialog', {
      name: removedDialog?.analyticName
    });
  }
  @action
  public removeCurrent(): void {
    this.remove(this.currentDialog);
  }

  /**
   * Removes all dialogs from the array of dialogs.
   */
  @action
  public clear(): void {
    this._dialogs = [];
  }

  /**
   * Getter for the current dialog, which is at the first in queue
   */
  @computed
  public get currentDialog(): Dialog {
    if (this._dialogs.length > 0) {
      return this._dialogs[0]; // The dialog queue is FIFO, so that the first dialog added is handled first
    }
    return null;
  }
}