import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React from 'react';
import './progress-bar.scss';
export interface ProgressBarProps {
  progress?: number;
  warning?: boolean;
}
/**
 * ProgressBar
 * @param progress Percentage of progress between 0.0 to 1.0. "null" or "undefined" to show an idle state instead of progress. Default is 0
 * @param warning Indication if there are any problems in the progress. Default is false
 */
const ProgressBar: React.FC<ProgressBarProps> = observer(function ProgressBar({
  progress = 0,
  warning = false
}): React.ReactElement {
  let barClassName = 'progress-bar';
  if (warning) {
    barClassName = `${barClassName} progress-bar--warning`;
  }
  return <div className="progress">
      {isNullOrUndefined(progress) && warning && <div className="idle-wrapper">
          <div className="progress-idle progress-idle--warning"></div>
        </div>}
      {isNullOrUndefined(progress) && !warning && <div className="idle-wrapper">
          <div className="progress-idle"></div>
        </div>}
      {!isNullOrUndefined(progress) && !warning && <div className={barClassName} style={{
      width: `${progress * 100}%`
    }}></div>}
      {!isNullOrUndefined(progress) && warning && <>
          <div className={barClassName} style={{
        width: `${progress * 100}%`
      }}></div>
          <div className="idle-wrapper--progress-warning">
            <div className="progress-idle progress-idle--progress-warning"></div>
            <div className="progress-idle progress-idle--progress-warning2"></div>
          </div>
        </>}
    </div>;
});
export default ProgressBar;