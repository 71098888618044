import { isFullscreen, isKeyboardLockSupportedByBrowser } from '../helpers/helpers';
import { SendAsJsonFunc } from './wssStreamingController';

export default class KeyboardController {
  private _sendAsJson: SendAsJsonFunc;

  private _fullscreenChangeListener = (): void => {};

  private _keysDown: number[] = []; // To mimic the protocol, ScanCode.
  private _capsLock: boolean = false;
  private _numLock: boolean = false;
  private _scrollLock: boolean = false;

  constructor(sendAsJson: SendAsJsonFunc) {
    this.lockEscOnFullscreen();
    this._sendAsJson = sendAsJson;

    window.addEventListener('keydown', this._handleKeyDown);
    window.addEventListener('keyup', this._handleKeyUp);
    window.addEventListener('focus', this._handleGainFocus);
    window.addEventListener('blur', this._handleLostFocus);
    window.addEventListener('paste', this._handlePaste);
  }

  public dispose = () => {
    //console.debug(`keyboardController`, `dispose`);
    window.removeEventListener('keydown', this._handleKeyDown);
    window.removeEventListener('keyup', this._handleKeyUp);
    window.removeEventListener('focus', this._handleGainFocus);
    window.removeEventListener('blur', this._handleLostFocus);
    window.removeEventListener('paste', this._handlePaste);

    document.removeEventListener('fullscreenchange', this._fullscreenChangeListener);
  };

  private lockEscOnFullscreen(): void {
    /**
     * If the feature is supported, we want to try to use the Keyboard Lock API to lock the escape key.
     * https://developer.mozilla.org/en-US/docs/Web/API/Keyboard
     *
     * This API only does something when going fullscreen with the Fullscreen API
     * https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
     *
     * In which case, we want to change the browser behavior of pressing Esc
     * to exit fullscreen and/or pointer lock
     * (see Pointer Lock API: https://developer.mozilla.org/en-US/docs/Web/API/Pointer_Lock_API)
     * into press and HOLD Esc (for a few seconds) to exit.
     */

    // Feature detection.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nav: any = navigator;
    const supportsKeyboardLock = isKeyboardLockSupportedByBrowser();

    if (!supportsKeyboardLock) {
      console.log('keyboardController', 'Keyboard Lock API unsupported!');
      return;
    }

    this._fullscreenChangeListener = (() => {
      if (isFullscreen()) {
        nav.keyboard
          .lock(['Escape'])
          .then(() => {
            console.log('keyboardController', 'Keyboard Esc key locked');
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            console.log('keyboardController', `Failed to lock keyboard Esc key: ${error}`);
          });
        return;
      }
      // we are going out of fullscreen mode, let's unlock the keyboard
      nav.keyboard.unlock();
      console.log('keyboardController', 'Keyboard unlocked.');
    }).bind(this);

    document.addEventListener('fullscreenchange', this._fullscreenChangeListener);
  }

  private _handleKeyDown = (evt: KeyboardEvent) => {
    this.sendKeyEvent(evt, true);
    this._filterBrowserKeys(evt);
  };

  private _handleKeyUp = (evt: KeyboardEvent) => {
    this.sendKeyEvent(evt, false);
    this._filterBrowserKeys(evt);
  };

  private _filterBrowserKeys(evt: KeyboardEvent) {
    let filter = false;
    switch (evt.code) {
      case 'Tab': // make sure that if Tab is pressed the browser doesn't focus on anohter element
        if (!evt.metaKey) filter = true;
        break;
      case 'ArrowLeft': // make sure the browser can't navigate back
      case 'ArrowRight': // make sure the browser can't navigate forward
        if (evt.altKey) filter = true;
        break;
      case 'F11': // make sure the browser can't go fullscreen with this key
      case 'F5': // make sure the browser can't refresh with this key
        filter = true;
        break;
      default:
        filter = false;
    }

    if (filter) {
      // prevent browser from handling this message
      evt.preventDefault();
    }
  }

  private _handlePaste = () => {
    //console.debug("keyboardController", "_handlePaste");

    navigator.clipboard
      .readText()
      .then((text) => {
        this._sendAsJson('keyboard', 'clipboard', { text });
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
  };

  private sendKeyEvent(evt: KeyboardEvent, isPressed: boolean) {
    const scanCode = this.SCANCODE_MAP.get(evt.code);
    if (scanCode == undefined) {
      if (isPressed) console.debug(`User pressed key with unknown code value: ${evt.code}`);
      return;
    }

    if (isPressed) {
      if (!this._keysDown.some((nr) => nr == scanCode)) {
        this._keysDown.push(scanCode);
      }
    } else {
      this._keysDown = this._keysDown.filter((nr) => nr != scanCode);
    }

    // == Shift key up ==
    // In windows, if you press both shift keys, it will only send a shift release for the last key released.
    // As a workaround, we'll release all shift keys if the key is shift.
    const shiftScanCodes = [0x002a, 0x0036];
    if (!isPressed && shiftScanCodes.includes(scanCode)) {
      this._keysDown = this._keysDown.filter((nr) => !shiftScanCodes.includes(nr));
    }

    // == Caps lock, num lock and scroll lock ==
    // NOTE: Not all of these are fully supported on all operating systems.
    this._capsLock = evt.getModifierState('CapsLock');
    this._numLock = evt.getModifierState('NumLock');
    this._scrollLock = evt.getModifierState('ScrollLock');

    this._sendKeyState();
  }

  private _sendKeyState() {
    const state: Record<string, boolean | Array<number>> = {
      pressed: this._keysDown,
    };

    if (this._capsLock) state['capslock'] = true;
    if (this._numLock) state['numlock'] = true;
    if (this._scrollLock) state['scrlock'] = true;

    this._sendAsJson('keyboard', 'state', state);
  }

  private _handleLostFocus = (/*event: FocusEvent*/) => {
    this._keysDown = [];
    this._sendKeyState();

    this._sendAsJson('focus', 'lost', {});
  };

  private _handleGainFocus = (/*event: FocusEvent*/) => {
    this._sendAsJson('focus', 'gain', {});
  };

  public setKeyboardLayoutLanguage = (langId: number) => {
    //console.debug(`Sending keyboard layout language: ${langId}`);
    this._sendAsJson('keyboard', 'language', { code: langId });
  };

  // Sigh, this is the only cross-platform way I found without using the deprecated evt.keyCode
  // Translates from KeyEvent.code to windows' scan code

  // We used this list:
  // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_code_values
  private readonly SCANCODE_MAP = new Map<string, number>([
    ['AltRight', 0xe038],
    ['ArrowDown', 0xe050],
    ['ArrowLeft', 0xe04b],
    ['ArrowRight', 0xe04d],
    ['ArrowUp', 0xe048],
    ['AudioVolumeDown', 0xe02e],
    ['AudioVolumeMute', 0xe020],
    ['AudioVolumeUp', 0xe030],
    ['Backquote', 0x0029],
    ['Backslash', 0x002b],
    ['Backspace', 0x000e],
    ['BracketLeft', 0x001a],
    ['BracketRight', 0x001b],
    ['BrowserBack', 0xe06a],
    ['BrowserFavorites', 0xe066],
    ['BrowserForward', 0xe069],
    ['BrowserHome', 0xe032],
    ['BrowserRefresh', 0xe067],
    ['BrowserSearch', 0xe065],
    ['BrowserStop', 0xe068],
    ['CapsLock', 0x003a],
    ['Comma', 0x0033],
    ['ContextMenu', 0xe05d],
    ['ControlLeft', 0x001d],
    ['ControlRight', 0xe01d],
    ['Convert', 0x0079],
    ['Copy', 0xe018],
    ['Cut', 0xe017],
    ['Delete', 0xe053],
    ['Digit0', 0x000b],
    ['Digit1', 0x0002],
    ['Digit2', 0x0003],
    ['Digit3', 0x0004],
    ['Digit4', 0x0005],
    ['Digit5', 0x0006],
    ['Digit6', 0x0007],
    ['Digit7', 0x0008],
    ['Digit8', 0x0009],
    ['Digit9', 0x000a],
    ['Eject', 0xe02c],
    ['End', 0xe04f],
    ['Enter', 0x001c],
    ['Equal', 0x000d],
    ['Escape', 0x0001],
    ['F1', 0x003b],
    ['F10', 0x0044],
    ['F11', 0x0057],
    ['F12', 0x0058],
    ['F13', 0x0064],
    ['F14', 0x0065],
    ['F15', 0x0066],
    ['F16', 0x0067],
    ['F17', 0x0068],
    ['F18', 0x0069],
    ['F19', 0x006a],
    ['F2', 0x003c],
    ['F20', 0x006b],
    ['F21', 0x006c],
    ['F22', 0x006d],
    ['F23', 0x006e],
    ['F24', 0x0076],
    ['F3', 0x003d],
    ['F4', 0x003e],
    ['F5', 0x003f],
    ['F6', 0x0040],
    ['F7', 0x0041],
    ['F8', 0x0042],
    ['F9', 0x0043],
    ['Help', 0xe03b],
    ['Home', 0xe047],
    ['Insert', 0xe052],
    ['IntlBackslash', 0x0056],
    ['IntlRo', 0x0073],
    ['IntlYen', 0x007d],
    ['KanaMode', 0x0070],
    ['KeyA', 0x001e],
    ['KeyB', 0x0030],
    ['KeyC', 0x002e],
    ['KeyD', 0x0020],
    ['KeyE', 0x0012],
    ['KeyF', 0x0021],
    ['KeyG', 0x0022],
    ['KeyH', 0x0023],
    ['KeyI', 0x0017],
    ['KeyJ', 0x0024],
    ['KeyK', 0x0025],
    ['KeyL', 0x0026],
    ['KeyM', 0x0032],
    ['KeyN', 0x0031],
    ['KeyO', 0x0018],
    ['KeyP', 0x0019],
    ['KeyQ', 0x0010],
    ['KeyR', 0x0013],
    ['KeyS', 0x001f],
    ['KeyT', 0x0014],
    ['KeyU', 0x0016],
    ['KeyV', 0x002f],
    ['KeyW', 0x0011],
    ['KeyX', 0x002d],
    ['KeyY', 0x0015],
    ['KeyZ', 0x002c],
    ['MediaPlayPause', 0xe022],
    ['MediaSelect', 0xe06d],
    ['MediaStop', 0xe024],
    ['MediaTrackNext', 0xe019],
    ['MediaTrackPrevious', 0xe010],
    ['MetaLeft', 0xe05b],
    ['MetaRight', 0xe05c],
    ['Minus', 0x000c],
    ['NonConvert', 0x007b],
    ['NumLock', 0xe045],
    ['Numpad0', 0x0052],
    ['Numpad1', 0x004f],
    ['Numpad2', 0x0050],
    ['Numpad3', 0x0051],
    ['Numpad4', 0x004b],
    ['Numpad5', 0x004c],
    ['Numpad6', 0x004d],
    ['Numpad7', 0x0047],
    ['Numpad8', 0x0048],
    ['Numpad9', 0x0049],
    ['NumpadAdd', 0x004e],
    ['NumpadComma', 0x007e],
    ['NumpadDecimal', 0x0053],
    ['NumpadDivide', 0xe035],
    ['NumpadEnter', 0xe01c],
    ['NumpadEqual', 0x0059],
    ['NumpadMultiply', 0x0037],
    ['NumpadSubtract', 0x004a],
    ['OSLeft', 0xe05b],
    ['OSRight', 0xe05c],
    ['PageDown', 0xe051],
    ['PageUp', 0xe049],
    ['Paste', 0xe00a],
    ['Pause', 0x0045], // CTRL+Pause should be 0xE046
    ['Period', 0x0034],
    ['Power', 0xe05e],
    ['PrintScreen', 0xe037], // ALT+PrintScreen should be 0x0054
    ['Quote', 0x0028],
    ['ScrollLock', 0x0046],
    ['Semicolon', 0x0027],
    ['ShiftLeft', 0x002a],
    ['ShiftRight', 0x0036],
    ['Slash', 0x0035],
    ['Sleep', 0xe05f],
    ['Space', 0x0039],
    ['Tab', 0x000f],
    ['Undo', 0xe008],
    ['VolumeDown', 0xe02e],
    ['VolumeUp', 0xe030],
    ['WakeUp', 0xe063],
    ['AltLeft', 0x0038],
  ]);
}
