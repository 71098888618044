import { t } from '@lingui/macro';
import { LOCAL_STORAGE_STREAM_NOTIFICATION_KEY, RATE_UTOMIK_URL } from '@utomik-app-monorepo/constants';
import { myUrl, platformURL } from '@utomik-app-monorepo/constants';
import { Routes } from '@utomik-app-monorepo/constants';
import { i18n } from '@utomik-app-monorepo/locales';
import { log } from '@utomik-app-monorepo/logger';
import { isDevelopment, isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable } from 'mobx';

import { Countries } from '../../../dataStore/stores/countries/countries';
import { ApiGender, User } from '../../../dataStore/stores/userStore/user';
import { TokenManager } from '../tokenManager/tokenManager';

const supportedLocales = require('../../../../../../../../supportedLocales');
const { setCurrentLocale } = require('../../../../../../../../localeManager');

export enum UserType {
  Developer,
  Ninja,
  EndUser,
}

export type GenderEntry = { name: string; id: ApiGender; apiId: string };

export type OnLanguageChanged = (locales: string) => Promise<void>;
export type BeforeLanguageChanged = () => Promise<boolean>;

/**
 * The ClientController handles global functionality like the theme, the current staging tier, the language, etc.
 */
export class ClientController {
  @observable
  private _isNetworkNotificationMinimized = false;
  // The authorization store is only used for connections from the client to the Utomik website, which requires a token.
  private readonly _tokenManager: TokenManager;
  private readonly _countries: Countries;
  // Callback that handles language change.
  private readonly _onLanguageChanged: OnLanguageChanged;
  // Callback that handles prep-work for language change.
  private readonly _beforeLanguageChanged: BeforeLanguageChanged;

  public constructor(
    tokenManager: TokenManager,
    countries: Countries,
    onLanguageChanged: OnLanguageChanged,
    beforeLanguageChanged: BeforeLanguageChanged
  ) {
    makeObservable(this);
    this._tokenManager = tokenManager;
    this._countries = countries;
    this._onLanguageChanged = onLanguageChanged;
    this._beforeLanguageChanged = beforeLanguageChanged;
  }
  @computed
  public get genders(): GenderEntry[] {
    return [
      {
        name: t({ context: 'Gender is unspecified', message: `Unspecified` }),
        id: 'u' as ApiGender,
        apiId: 'GENDER_UNSPECIFIED',
      },
      { name: t({ context: 'Gender is Male', message: `Male` }), id: 'm' as ApiGender, apiId: 'GENDER_MALE' },
      { name: t({ context: 'Gender is Female', message: `Female` }), id: 'f' as ApiGender, apiId: 'GENDER_FEMALE' },
    ];
  }

  public getGenderById = (id: ApiGender): GenderEntry => {
    return this.genders.find((g) => g.id === id);
  };

  public getGenderByName = (name: string): GenderEntry => {
    return this.genders.find((g) => g.name === name);
  };

  public getGenderByApiId = (apiId: string): GenderEntry => {
    return this.genders.find((g) => g.apiId === apiId);
  };

  public get locales(): { name: string; id: string }[] {
    return supportedLocales.map((locale) => {
      const displayNames = new Intl.DisplayNames(['en'], { type: 'language' });
      return { id: locale, name: displayNames.of(locale) };
    });
  }

  public getLocaleById = (id: string): { id: string; name: string } => {
    return this.locales.find((loc) => loc.id === id);
  };

  public setLocale = (id: string): void => {
    setCurrentLocale(id, i18n);
    //this._onLanguageChanged(id);
  };

  private _cameFrom = Routes.Home;

  public get cameFrom() {
    return this._cameFrom;
  }

  public set cameFrom(from: Routes) {
    this._cameFrom = from;
  }

  public get countries() {
    return this._countries;
  }

  @observable
  private _showStatsForNerds = false;
  @computed
  public get showStatsForNerds(): boolean {
    return this._showStatsForNerds;
  }
  @action
  public setShowStatsForNerds(showStatsForNerds: boolean): void {
    this._showStatsForNerds = showStatsForNerds;
  }

  public get shouldUpgradeSubscription() {
    return !(this.user.isCloudUser || this.user.isCloudTester) && this.user.hasSubscription;
  }
  @action
  public switchNetworkNotificationMinimized = () => {
    if (this._isNetworkNotificationMinimized) {
      localStorage.removeItem(this.storageConnectionWarningKey);
      this._isNetworkNotificationMinimized = false;
    } else {
      localStorage.setItem(this.storageConnectionWarningKey, 'true');
      this._isNetworkNotificationMinimized = true;
    }
  };
  @computed
  public get isNetworkNotificationMinimized() {
    return this._isNetworkNotificationMinimized;
  }

  private _isDevelopmentMode: boolean = isDevelopment;
  public get isDevelopmentMode(): boolean {
    return this._isDevelopmentMode;
  }
  public setDevelopmentMode(isDevelopmentMode: boolean): void {
    this._isDevelopmentMode = isDevelopmentMode;
  }

  private _isRTL = false;
  public get isRTL(): boolean {
    return this._isRTL;
  }
  public setIsRTL(isRTL: boolean): void {
    this._isRTL = isRTL;
  }

  @observable
  private _user: User = null;
  @computed
  public get user(): User {
    return this._user;
  }
  @action
  public setUser(user: User): void {
    const isMinimized = localStorage.getItem(LOCAL_STORAGE_STREAM_NOTIFICATION_KEY + user?.id);

    if (!isNullOrUndefined(isMinimized)) {
      this._isNetworkNotificationMinimized = Boolean(isMinimized);
    }

    this._user = user;
  }

  @computed
  public get collectingUserInfo(): boolean {
    return !this._user?.profileCompleted;
  }

  public get storageConnectionWarningKey() {
    return LOCAL_STORAGE_STREAM_NOTIFICATION_KEY + this.user?.id;
  }

  // Open URL in the website.
  private getWebsiteLink(url: string): string {
    const tokenParam = this._tokenManager.isAuthorized ? `token=${this._tokenManager.token.encodedValue}&` : ``;
    const apiUrlParam = `api_url=${encodeURI(platformURL)}&`;
    const nextParam = `next=${encodeURI(url)}`;
    return `${myUrl}/import-session?${tokenParam}${apiUrlParam}${nextParam}`;
  }

  public getRateUtomikURL(): string {
    let url = RATE_UTOMIK_URL;
    if (!isNullOrUndefined(this._user)) url = `${RATE_UTOMIK_URL}?id=${this._user?.id}`;
    return url;
  }

  /**
   * Open an external (non-Utomik) url.
   *
   * @param url - The url to visit.
   */
  public async openExternalURL(url: string): Promise<void> {
    try {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      log(`openExternalURL opened: "${url}"`);
    } catch {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      log(`openExternalURL failed with: "${url}"`);
    }
  }

  /**
   * Open an internal (Utomik) url.
   *
   * @param url - The url to visit.
   * @param parseForWebsite - Boolean that determines if it needs to be parsed for the Utomik website (adding tokens and stuff).
   */
  public async openInternalURL(url: string, parseForWebsite = true): Promise<void> {
    try {
      if (parseForWebsite) {
        url = this.getWebsiteLink(url);
      }
      // eslint-disable-next-line lingui/no-unlocalized-strings
      log(`openInternalURL opened: "${url}"`);
    } catch {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      log(`openInternalURL failed with: "${url}"`);
    }
  }
}
