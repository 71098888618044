import { Routes } from '@utomik-app-monorepo/constants';
import { i18n } from '@utomik-app-monorepo/locales';
import { pathTv } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable } from 'mobx';

const menuItems = [
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'Home', message: 'Home' }),
    icon: pathTv + require('images/svg/sidebar/Home.svg'),
    link: Routes.Home,
    id: 0,
  },
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'Search', message: 'Search' }),
    icon: pathTv + require('images/svg/sidebar/Search.svg'),
    link: Routes.Search,
    id: 1,
  },
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'Channels', message: 'Channels' }),
    icon: pathTv + require('images/svg/sidebar/channels.svg'),
    link: Routes.Channels,
    id: 2,
  },
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'Events', message: 'Events' }),
    icon: pathTv + require('images/svg/sidebar/calendar.svg'),
    link: Routes.Events,
    id: 3,
  },
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'My list', message: 'My list' }),
    icon: pathTv + require('images/svg/sidebar/icons.svg'),
    link: Routes.MyList,
    id: 4,
  },
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'News', message: 'News' }),
    icon: pathTv + require('images/svg/sidebar/Notification.svg'),
    link: Routes.News,
    id: 5,
  },
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'Ninja', message: 'Ninja' }),
    icon: pathTv + require('images/svg/ninja-icon.svg'),
    link: Routes.Ninja,
    id: 6,
  },
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'Settings', message: 'Settings' }),
    icon: pathTv + require('images/svg/sidebar/Setting.svg'),
    link: Routes.Settings,
    id: 7,
  },
  {
    name: i18n.t({ comment: 'Side menu item name', id: 'Profile', message: 'Profile' }),
    icon: pathTv + require('images/img/profile.png'),
    link: Routes.Profile,
    id: 8,
  },
];

export class TvSidebarController {
  @observable
  private _isExpanded: boolean;
  private readonly _menuItems: typeof menuItems;

  public constructor() {
    makeObservable(this);
    this._isExpanded = false;
    this._menuItems = menuItems;
  }

  @computed
  public get isExpanded() {
    return this._isExpanded;
  }

  @computed
  public get menuItems() {
    return this._menuItems;
  }

  @action
  public setIsExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded;
  }
}
