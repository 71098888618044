import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { customClearButtonKey, customVirtualBackspaceKey, customVirtualEnterKey } from '@utomik-app-monorepo/constants';
import { useSetTestId } from '@utomik-app-monorepo/hooks';
import { KeyboardControllerContext } from '@utomik-app-monorepo/store';
import { getTextWidth, repeatFnCall, sizeRemToPx } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { CSSProperties, useContext, useEffect, useRef } from 'react';
import './focusable-input.scss';
type Props = {
  value?: string;
  style?: CSSProperties;
  placeholder?: string;
  type?: 'email' | 'password' | 'text' | 'search';
  name?: string;
  errorMessage?: React.ReactNode | string;
  defaultValue?: string;
  width?: 'fullwidth' | 'normal';
  onBlur?: (e: any) => void;
  autofocus?: boolean;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  inputId?: string;
};
const excludedKeys = {
  ArrowRight: true,
  ArrowLeft: true,
  ArrowDown: true,
  ArrowUp: true,
  Enter: true
};
export const FocusableInput = withFocusable()<Props>(observer(function FocusableInput({
  value,
  setValue,
  focused,
  placeholder,
  setFocus,
  type,
  errorMessage,
  style,
  onBlur,
  width = 'normal',
  autofocus,
  focusable = true,
  inputId
}) {
  const caretPositionRef = useRef<number>(value.length);
  const caretModifiedRef = useRef<boolean>(false);
  const keyboardController = useContext(KeyboardControllerContext);
  const inputRef = useRef<HTMLDivElement>(null);
  const textFieldRef = useRef<HTMLDivElement>(null);
  const caretRef = useRef<HTMLSpanElement>(null);
  useSetTestId(inputRef, type);
  const handleChangeCaret = (shiftCaret = 0) => {
    const inputWidthOffset = inputRef.current.clientWidth - textFieldRef.current.offsetLeft - sizeRemToPx(2);
    let caretPositionIndex: number;
    if (shiftCaret > 0) {
      caretPositionIndex = caretPositionRef.current + shiftCaret > inputRef.current.innerText?.length ? inputRef.current.innerText?.length : ++caretPositionRef.current;
    } else if (shiftCaret === 0) {
      caretPositionIndex = inputRef.current.innerText?.length;
    } else if (shiftCaret < 0) {
      caretPositionIndex = caretPositionRef.current + shiftCaret < 0 ? 0 : --caretPositionRef.current;
    }
    const value = inputRef.current.innerText.slice(0, caretPositionIndex);
    const caretTextWidthFromStart = getTextWidth(value, inputRef.current);
    if (caretTextWidthFromStart > inputWidthOffset) {
      //if the caret is reached to the right side of the input
      const offset = inputWidthOffset - caretTextWidthFromStart;
      textFieldRef.current.style.transform = `translateX(${offset}px)`;
      caretRef.current.style.left = `${inputWidthOffset}px`;
    } else {
      textFieldRef.current.style.transform = `translateX(0px)`;
      caretRef.current.style.left = `${caretTextWidthFromStart}px`;
    }
  };
  const handleKeyDown = (e: Partial<KeyboardEvent>, isPressed: boolean) => {
    if (excludedKeys[e.key] && e.isTrusted || !isPressed && !e.isTrusted) return;
    switch (e.key) {
      case customClearButtonKey:
        {
          //clean input
          setValue(() => '');
          caretPositionRef.current = 0;
          caretModifiedRef.current = false;
          return;
        }
      case 'ArrowLeft':
        {
          if (inputRef.current.innerText.length > 0) {
            caretModifiedRef.current = true;
          }
          //move caret left
          handleChangeCaret(-1);
          return;
        }
      case 'ArrowRight':
        {
          //move caret right
          handleChangeCaret(+1);
          return;
        }
      case customVirtualBackspaceKey:
        {
          //remove a char on caret position
          setValue(prev => {
            //prevent negative values
            if (caretPositionRef.current - 1 < 0) return prev;
            const leftPart = prev.slice(0, caretPositionRef.current - 1);
            const rightPart = prev.slice(caretPositionRef.current);
            //concat strings together
            return leftPart + rightPart;
          });
          return;
        }
      case customVirtualEnterKey:
        {
          keyboardController.setIsKeyboardVisible(false);
          //focus on the next input on the view if it exists
          const inputs: NodeListOf<HTMLDivElement> = document.querySelectorAll('[data-id="INPUT"]');
          inputs.forEach((el, idx) => {
            if (el === inputRef.current && el.innerText.length > 0 && inputs.item(idx + 1)) {
              (inputs.item(idx + 1) as HTMLDivElement).focus();
            }
          });
          return;
        }
    }
    if (e.key.length < 2) {
      setValue(prev => prev.slice(0, caretPositionRef.current) + e.key + prev.slice(caretPositionRef.current));
    }
  };
  useEffect(() => {
    const localInputRef = inputRef.current;
    if (!caretModifiedRef.current) {
      caretPositionRef.current = value.length;
    }
    const handleKeyDownEvent = (e: KeyboardEvent) => {
      handleKeyDown(e, true);
    };
    localInputRef.addEventListener('keydown', handleKeyDownEvent, true);
    return () => {
      localInputRef.removeEventListener('keydown', handleKeyDownEvent, true);
    };
  }, [keyboardController.shift, value]);
  useEffect(() => {
    if (!keyboardController.isKeyboardVisible && document.activeElement === inputRef.current) {
      setFocus();
    }
  }, [keyboardController.isKeyboardVisible]);
  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'characterData' || mutation.type === 'childList') {
          if (!caretModifiedRef.current) {
            //if caret was not modified
            handleChangeCaret(0);
          } else if (mutation.oldValue?.length > inputRef.current.innerText.length) {
            //if prev value has more length
            handleChangeCaret(-1);
          } else if (mutation.oldValue?.length < inputRef.current.innerText.length) {
            //if prev value has less length
            handleChangeCaret(+1);
          }
          if (!inputRef.current.innerText || inputRef.current.innerText.length === caretPositionRef.current) {
            caretModifiedRef.current = false;
          }
        }
      });
    });
    handleChangeCaret(0);
    const config = {
      characterData: true,
      childList: true,
      subtree: true,
      characterDataOldValue: true
    };
    observer.observe(inputRef.current, config);
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    if (autofocus) {
      focusable && repeatFnCall(setFocus);
    }
  }, [autofocus, focusable]);
  useEffect(() => {
    if (document.activeElement === inputRef.current) return;
    if (focused) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [focused]);
  useEffect(() => {
    const localInputRef = inputRef.current;
    const handleEnterPress = e => {
      if (focused && e.key === 'Enter') {
        localInputRef?.blur();
        localInputRef?.focus();
      }
    };
    document.addEventListener('keydown', handleEnterPress, true);
    return () => {
      document.removeEventListener('keydown', handleEnterPress, true);
    };
  }, [focused]);
  useEffect(() => {
    const localInputRef = inputRef.current;
    const handleFocus = () => {
      keyboardController.setKeyCallback(handleKeyDown);
    };
    const handleBlur = () => {
      keyboardController.setKeyCallback(null);
    };
    localInputRef.addEventListener('focus', handleFocus, true);
    localInputRef.addEventListener('blur', handleBlur, true);
    return () => {
      localInputRef.removeEventListener('focus', handleFocus, true);
      localInputRef.removeEventListener('blur', handleBlur, true);
    };
  }, []);
  return <div className={cx('focusable-input__wrapper', {
    'focusable-input__wrapper--focus': focused
  })}>
        <div id={inputId} style={style} data-kind={type} placeholder={placeholder} data-disabled={!focusable} className={cx('focusable-input', {
      [`focusable-input--${width}`]: true
    })} onBlur={onBlur} ref={inputRef} data-id={'INPUT'} tabIndex={0}>
          <div ref={textFieldRef} className={'text-field'}>
            {value}
          </div>
        </div>
        <small className={'focusable-input-error'}>{errorMessage}</small>
        <span ref={caretRef} className={'custom-caret'} />
      </div>;
}));