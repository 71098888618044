import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

// You only need to import this file once like 'import "./injectFontAwesome"' once.

// Usage found here:
// https://fontawesome.com/v5.15/how-to-use/javascript-api/setup/getting-started#using-node
library.add(fab, fas, far);
