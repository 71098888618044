import { Trans, t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { AchievementTilesCarousel, FocusableBackButton, FocusablePlayButtons, FocusableTextSection, GameDetails, GameRating, MediaTilesSection, SessionInfo, StarRating, TagsList } from '@utomik-app-monorepo/components';
import { useAfterGameLogic, useAppFetch, useMyListNotifications } from '@utomik-app-monorepo/hooks';
import { AnalyticControllerContext, Application, ApplicationStoreContext, AsyncObjectState, DialogQueueContext, NavigationContext, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { sizeRemToPx } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { MainSlider } from '../../../components/main-slider/main-slider';
import styles from './app-info.module.scss';
const RatingSection = withFocusable()<{
  app: Application;
}>(observer(({
  app
}) => {
  const analyticController = useContext(AnalyticControllerContext);
  const handleRate = useCallback((rating: number) => {
    analyticController.rateGame({
      rating: String(rating),
      item_name: app.slug,
      location_on_page: `AppInfo - ${app.slug}`
    });
    app.rating.setUser(rating);
  }, [app.slug]);
  const userrRating = app.rating.user || '-';
  return <div>
        <h1 className={styles.sectionName}>
          <Trans context={'Current user rating on a game page'}>
            My rating: <mark className={styles.mark}> {userrRating} </mark>
          </Trans>
        </h1>
        <div className={styles.ratingDetailsContainer}>
          <div className={styles.ratingContainer}>
            <GameRating withButton className={styles.gameRating} value={app.rating.user} onButtonPress={handleRate} />
            <SessionInfo app={app} />
          </div>
          <GameDetails showRating={false} focusable={false} app={app} />
        </div>
      </div>;
}));
const PlaySection = withFocusable()<{
  app: Application;
}>(observer(({
  app
}) => {
  const {
    backHandler
  } = useContext(NavigationContext);
  const navigationController = useContext(NavigationControllerContext);
  return <div className={styles.playSection}>
        <div className={styles.descriptionSection}>
          <FocusableBackButton className={styles.backButton} showLabel={false} showOutline={navigationController.isGamepadInteraction} onEnterPress={backHandler} useIntersectionObserver={false} />
          <StarRating className={styles.starRating} rating={app?.rating.global} isLoading={false} />
          <p className={styles.gameTitle}>{app?.name}</p>
          <p className={styles.gameDescription}>{app?.excerpt}</p>
          <TagsList className={styles.tagList} tags={app?.genres} />
          <FocusablePlayButtons size={'xlarge'} showOutline={navigationController.isGamepadInteraction} className={styles.playButtons} show={true} item={app} />
        </div>
        <img className={styles.mainImage} loading={'lazy'} src={app.spotlightImages.deluxeImage?.url} />
      </div>;
}));
const AchievementsScrollableCarousel = withFocusable()<{
  app: Application;
}>(observer(({
  app
}) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const handleFocus = props => {
    const leftOffset = parseInt(window.getComputedStyle(props.node.parentNode).paddingLeft) + sizeRemToPx(1);
    scrollableContainerRef.current.scroll({
      left: props.x - leftOffset,
      behavior: 'smooth'
    });
  };
  return <div className={cx(styles.achievementsSection, {
    [styles.achievementsSectionLoading]: app?.appVersion?.achievements?.state !== AsyncObjectState.Done
  })}>
        <AchievementTilesCarousel containerRef={scrollableContainerRef} onChildFocused={handleFocus} maxTiles={10} achievementStore={app?.appVersion?.achievements} scrollType={'native'} />
      </div>;
}));
export const AppInfo = withFocusable({
  trackChildren: true
})(observer(({
  setFocus
}) => {
  const {
    id
  } = useParams();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const applicationStore = useContext(ApplicationStoreContext);
  const navigationController = useContext(NavigationControllerContext);
  const dialogQueue = useContext(DialogQueueContext);
  const app = applicationStore.getItem(id);
  useEffect(() => {
    if (!dialogQueue.hasDialogs) {
      setFocus();
    }
  }, [dialogQueue.hasDialogs, location.pathname]);
  useAfterGameLogic(app, setFocus);
  useAppFetch(app);
  useMyListNotifications(app);
  const handleFocusSection = props => {
    if (navigationController.isGamepadInteraction) {
      scrollableContainerRef.current.scrollTo({
        top: props.top,
        behavior: 'smooth'
      });
    }
  };
  const handleRelatedPress = () => {
    setFocus('play-section');
  };
  return <div key={location.pathname} className={styles.container} ref={scrollableContainerRef}>
        <PlaySection focusKey={'play-section'} preferredChildFocusKey={'main-button'} onBecameFocused={handleFocusSection} app={app} />
        <div className={styles.sectionsWrapper}>
          <h1 className={styles.sectionName}>{t({
          message: 'Media'
        })}</h1>
          <MediaTilesSection key={location.pathname} onBecameFocused={handleFocusSection} scrollType={'native'} className={styles.mediaSection} data={app.gameMedia} />
          <RatingSection onBecameFocused={handleFocusSection} app={app} />
          <FocusableTextSection analyticOrigin={['AppInfo']} onBecamePartialFocused={handleFocusSection} description={app.description} />
          <AchievementsScrollableCarousel focusKey={'Achievements'} onBecameFocused={handleFocusSection} app={app} />
          <MainSlider analyticsSectionName={'RelatedGames'} name={t({
        context: 'Title of the carousel with game tiles',
        message: 'Related games'
      })} onSlidePress={handleRelatedPress} onBecameFocused={handleFocusSection} appList={app.relatedAppList} dotsFocusKey={'slider-dots'} analyticsOriginSlug={app.slug} />
        </div>
      </div>;
}));