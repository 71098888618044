import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { FocusableSelect } from '../focusable-select/focusable-select';
import './focusable-date-picker.scss';
type Props = {
  onChange: (date: string) => void;
  errorMessage?: string;
  currentDate?: {
    date: number;
    month: number;
    year: number;
  };
  scrollType?: 'native' | 'transform';
};
export const FocusableDatePicker = withFocusable()<Props>(observer(function FocusableDatePicker({
  onChange,
  errorMessage,
  currentDate,
  focusable,
  scrollType
}) {
  const [momentDate, setMomentDate] = useState(moment().set({
    date: currentDate?.date,
    month: currentDate?.month,
    year: currentDate?.year
  }));
  const dates = useMemo(() => {
    return Array.from({
      length: momentDate.daysInMonth()
    }).map((_, idx) => ({
      name: String(idx + 1)
    }));
  }, [momentDate]);
  const months = useMemo(() => {
    return moment.months().map(m => ({
      name: m
    }));
  }, [momentDate]);
  const years = useMemo(() => {
    const year = new Date().getFullYear();
    const maxYearsOver18 = 105;
    return Array.from({
      length: maxYearsOver18
    }, (v, i) => ({
      name: String(year - 18 - maxYearsOver18 + i + 1)
    }));
  }, [momentDate]);
  useEffect(() => {
    onChange(momentDate.format('YYYY-MM-DD'));
  }, [momentDate]);
  return <div className={'focusable-date-picker__container'}>
        <FocusableSelect width={'medium'} values={dates} selectedValue={String(momentDate.date())} onChange={val => {
      setMomentDate(prev => prev.clone().set('date', +val.name));
    }} disabled={!focusable} scrollType={scrollType} />
        <FocusableSelect width={'wide'} values={months} selectedValue={String(momentDate.format('MMMM'))} onChange={val => {
      setMomentDate(prev => prev.clone().set('months', months.findIndex(item => item.name === val.name)));
    }} scrollType={scrollType} disabled={!focusable} />
        <FocusableSelect width={'medium'} values={years} selectedValue={String(momentDate.format('YYYY'))} onChange={val => {
      setMomentDate(prev => prev.clone().set('year', +val.name));
    }} scrollType={scrollType} disabled={!focusable} />
        <div className="focusable-input-error">{errorMessage}</div>
      </div>;
}));