import { TIME_TO_SHOW_AFTER_GAME_MODAL } from '@utomik-app-monorepo/constants';
import { Routes } from '@utomik-app-monorepo/constants';
import { Application } from '@utomik-app-monorepo/store';
import { ClientControllerContext, DialogFactoryContext, StreamingFeedbackControllerContext } from '@utomik-app-monorepo/store';
import { repeatFnCall } from '@utomik-app-monorepo/utils';
import { useContext, useEffect } from 'react';
export const useAfterGameLogic = (app: Application, setFocus: (key?: string) => void) => {
  const clientController = useContext(ClientControllerContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const streamingFeedbackController = useContext(StreamingFeedbackControllerContext);
  useEffect(() => {
    let isMounted = true;
    let intervalId: ReturnType<typeof setInterval>;
    (async () => {
      /**
       * If user came from the stream view
       * */
      if (isMounted && clientController.cameFrom === Routes.GameRun) {
        streamingFeedbackController.init();
        /**
         * Show the dialog after every N session
         * */
        if (streamingFeedbackController.shouldShowFeedbackDialog) {
          await dialogFactory.showStreamingFeedbackDialog();
        }
        if (
        /**
         * Check if we should show the after game modal
         * */
        app.initialPlayTimeSeconds < TIME_TO_SHOW_AFTER_GAME_MODAL && app?.userStat?.secondsPlayed >= TIME_TO_SHOW_AFTER_GAME_MODAL) {
          await dialogFactory.showAfterGameDialog(app);
        } else {
          /**
           * If not - focus on achievements section
           * */
          intervalId = repeatFnCall(setFocus.bind(null, 'Achievements'));
        }
      }
    })();
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [app?.userStat?.secondsPlayed]);
};