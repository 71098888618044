import { RequestQueue } from '../../../dataStore/requestQueue/requestQueue';
import { ConnectivityReporter } from '../connectivityReporter/connectivityReporter';

/**
 * A class that handles online/offline logic.
 */
export class ConnectionMonitor {
  public connectivityReporter: ConnectivityReporter = null;
  public requestQueue: RequestQueue = null;

  public constructor(connectivityReporter: ConnectivityReporter, requestQueue: RequestQueue) {
    this.connectivityReporter = connectivityReporter;
    this.requestQueue = requestQueue;
  }

  public get hasFailedRequests(): boolean {
    return this.requestQueue.failedRequests.length > 0;
  }

  public get failedRequestsQty() {
    return this.requestQueue.failedRequests.length;
  }

  public get connected(): boolean {
    return this.connectivityReporter.connected;
  }

  public dispose = () => {
    this.connectivityReporter.dispose();
  };
}
