import { MIN_ITEMS_IN_VIEW } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { autorun, makeObservable, override } from 'mobx';
import { Application } from '../../../dataStore/stores/applicationStore/application';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';
import { RecentlyPlayedStore } from '../../../dataStore/stores/recentlyPlayedStore/recentlyPlayedStore';
import { AppTileProvider } from './app-tile-provider';
export class RecentlyPlayedAppTileProvider extends AppTileProvider {
  private readonly _autorunDisposer: ReturnType<typeof autorun>;
  private _recentlyPlayedStore: RecentlyPlayedStore;
  @override
  public override get state(): AsyncObjectState {
    return this._recentlyPlayedStore?.appList?.state;
  }
  @override
  public override get length(): number {
    return this._recentlyPlayedStore.recentlyPlayedApps.length;
  }
  @override
  public override get confirmedEmpty(): boolean {
    return this.length == 0 && this._recentlyPlayedStore.initialized;
  }
  public get recentlyPlayedAppsShort(): {
    items: Application[];
    atEnd: boolean;
  } {
    return {
      items: this._recentlyPlayedStore.recentlyPlayedApps.slice(0, MIN_ITEMS_IN_VIEW),
      atEnd: this._recentlyPlayedStore.recentlyPlayedApps.length <= MIN_ITEMS_IN_VIEW
    };
  }
  public get recentlyPlayedApps() {
    return this._recentlyPlayedStore.recentlyPlayedApps;
  }

  /**
   * Initialize and set local apps.
   *
   * @param slug
   * @param name
   * @param analyticOrigin
   * @param applications
   */

  public constructor(slug: string, name: string, analyticOrigin: string[], recentlyPlayedStore: RecentlyPlayedStore) {
    // This extends the original app tile provider but doesn't need an app list because it doesn't use pagination.
    super(slug, name, [...analyticOrigin, 'RecentlyPlayed'], recentlyPlayedStore.appList);
    makeObservable(this);
    this._recentlyPlayedStore = recentlyPlayedStore;
    this._autorunDisposer = autorun(() => {
      log(`RecentlyPlayedAppTileProvider.recentlyPlayedApps changed, flipping updateBit. Length: ${this._recentlyPlayedStore.recentlyPlayedApps.length}`);
      this.flipBit();
    });
  }
  public dispose = () => {
    this._autorunDisposer();
  };
}