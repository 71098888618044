import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton } from '@utomik-app-monorepo/components';
import { Routes } from '@utomik-app-monorepo/constants';
import { useMyListDelete } from '@utomik-app-monorepo/hooks';
import { AnalyticControllerContext, AnalyticItemTypes, AppList, AppTileProvider, Application, AsyncObjectState, ChannelList, ChannelStoreContext, ChannelTileProvider, DialogQueueContext, Channel as GameChannel, MyListStoreContext, NavigationContext, NavigationControllerContext, RequestPriority } from '@utomik-app-monorepo/store';
import { joinLocation, repeatFnCall } from '@utomik-app-monorepo/utils';
import emptyMyList from 'images/svg/sad_empty.svg';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tile } from '../../../components/tile/tile';
import styles from './channel.module.scss';
type Props = {
  appList?: AppList;
  provider?: AppTileProvider | ChannelTileProvider;
  type: 'seeall' | 'channel' | 'mylist';
  name?: string;
};
export const Channel = withFocusable()<Props>(observer(({
  appList,
  provider,
  setFocus,
  type,
  name
}) => {
  const [res, setRes] = useState<{
    items: (Application | GameChannel)[];
    atEnd: boolean;
  }>({
    items: Array.from({
      length: 100
    }),
    atEnd: false
  });
  const scrollableGridRef = useRef<HTMLDivElement>(null);
  const channelController = useContext(ChannelStoreContext);
  const navigationController = useContext(NavigationControllerContext);
  const myListStore = useContext(MyListStoreContext);
  const dialogQueue = useContext(DialogQueueContext);
  const analyticController = useContext(AnalyticControllerContext);
  const {
    backHandler
  } = useContext(NavigationContext);
  const {
    isDeleteMode,
    setIdsToDelete,
    idsToDelete,
    handleDelete,
    handleCancel
  } = useMyListDelete();
  const navigate = useNavigate();
  const {
    id
  } = useParams();
  const channel = id && channelController.getItem(id);
  const channelName = name || provider?.name || channel?.name;
  const dataList = useMemo(() => {
    if (appList) return appList;
    if (channel) return channel.appList;
    if (provider instanceof ChannelTileProvider) return provider.channelList;
  }, [appList, channel, provider]);
  useEffect(() => {
    if (type === 'mylist' && myListStore.initialized) {
      setRes({
        items: myListStore.myListApps,
        atEnd: false
      });
    }
  }, [myListStore.myListApps, myListStore.initialized]);
  useEffect(() => {
    if (channel) channel.fetch();
    const fetchItems = async () => {
      if (type === 'mylist') {
        await myListStore.initialize();
        return;
      }
      if (dataList instanceof AppList) {
        const res = await dataList.getApps(0, 100, RequestPriority.High);
        setRes(res);
      } else if (dataList instanceof ChannelList) {
        const res = await dataList.getChannels(0, 100, false, RequestPriority.High);
        setRes(res);
      }
    };
    fetchItems();
  }, [dataList, channel]);
  useEffect(() => {
    if ((dataList?.state === AsyncObjectState.Done || type === 'mylist') && !dialogQueue.hasDialogs) {
      const focusKey = type === 'seeall' ? channelController.pageScrollState.currentFocusKeySeeAll : channelController.pageScrollState.currentFocusKey;
      repeatFnCall(setFocus.bind(null, focusKey || ''));
    }
  }, [dataList?.state, setFocus, channelController.pageScrollState.currentFocusKey, dialogQueue.hasDialogs, myListStore.myListApps]);
  const handleTileFocused = (props: any, itemProps: any) => {
    if (type === 'seeall') {
      channelController.pageScrollState.currentFocusKeySeeAll = itemProps.focusKey;
    } else {
      channelController.pageScrollState.currentFocusKey = itemProps.focusKey;
    }
    if (!scrollableGridRef.current) return;
    const {
      paddingTop
    } = getComputedStyle(scrollableGridRef.current);
    scrollableGridRef.current.scroll({
      behavior: 'smooth',
      top: props.y - parseInt(paddingTop)
    });
  };
  const handleTilePressed = (props: any) => {
    if (isDeleteMode) {
      setIdsToDelete(prev => {
        channelController.pageScrollState.clearState();
        if (prev.includes(props.item?.id)) {
          return prev.filter(item => item !== props.item?.id);
        }
        return [...prev, props.item?.id];
      });
      return;
    }
    const breadcrumbs = analyticController.getBreadCrumbs({
      currentSection: provider?.slug || channel?.slug,
      pageName: type === 'mylist' ? 'MyList' : 'Channel',
      isGrid: true
    });
    if (type === 'seeall') {
      channelController.pageScrollState.currentFocusKey = null;
    }
    if (props.item instanceof Application) {
      analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Game,
        location_on_page: joinLocation(breadcrumbs)
      });
      navigate(`${Routes.AppInfo}/${props.item.id}`);
    } else if (props.item instanceof GameChannel) {
      analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Channel,
        location_on_page: joinLocation(breadcrumbs)
      });
      navigate(`${Routes.Channels}/${props.item.id}`);
    }
  };
  const emptyText = type === 'mylist' ? t({
    context: 'Message shows if the My list is empty. Call to action - Add more games to the My list',
    message: "It's empty! But you can fix it"
  }) : t({
    context: 'Message is shown if the channel page has no items to show',
    message: 'Sorry, this channel currently has no games for TV. Please check back later! In the mean time, why not check out Utomik on PC?'
  });
  return <div className={styles.container}>
        <div className={styles.backTitleContainer}>
          {type !== 'mylist' && <FocusableButton showOutline={navigationController.isGamepadInteraction} type={'back'} className={styles.backButton} onEnterPress={backHandler} />}
          <div>
            <p className={styles.channelTitle}>{channelName}</p>
            {res.items.length > 0 && dataList?.state === AsyncObjectState.Done && <small className={styles.itemsCounter}>
                {res.items.length} {pluralize('item', res.items.length)}
              </small>}
          </div>
          {type === 'mylist' && res.items.length > 0 && <div className={styles.deleteButtonsContainer}>
              {isDeleteMode && <FocusableButton type={'secondary'} onEnterPress={handleCancel}>
                  {t({
            message: 'Cancel'
          })}
                </FocusableButton>}
              {(!isDeleteMode || idsToDelete.length !== 0) && <FocusableButton type={'delete'} onEnterPress={handleDelete} isLoading={myListStore.state === AsyncObjectState.Pending}>
                  <FontAwesomeIcon style={{
            marginRight: '1rem'
          }} icon={faTrash} />
                  <p>
                    {t({
              context: 'Button - remove a game from my list',
              message: 'Remove'
            })}{' '}
                    {idsToDelete.length ? `${idsToDelete.length} ${pluralize(t({
              message: 'item'
            }), idsToDelete.length)}` : ''}
                  </p>
                </FocusableButton>}
            </div>}
        </div>
        {!res.items.length && <div className={styles.emptyStateContainer}>
            {type === 'mylist' && <img className={styles.emptyStateMyListImage} src={emptyMyList} />}
            <p className={styles.emptyStateText}>{emptyText}</p>
            {type === 'mylist' && <FocusableButton focusKey={'MAIN_BUTTON'} className={'go-home-button'} type={'primary'} onEnterPress={navigate.bind(null, Routes.Home)} autofocus>
                {t({
          context: 'Button title - redirect to the Home page',
          message: 'Go to main'
        })}
              </FocusableButton>}
          </div>}
        <div className={styles.scrollableGrid} ref={scrollableGridRef}>
          {res.items.map((item, idx) => <Tile idx={idx} focusKey={channelName + idx} onEnterPress={handleTilePressed} onBecameFocused={handleTileFocused} showOutline={navigationController.isGamepadInteraction} className={styles.gridItem} key={idx} showCheck={isDeleteMode} isChecked={idsToDelete.includes(item?.id)} item={item} carouselName={name} />)}
        </div>
      </div>;
}));