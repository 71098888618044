import { AppGenreList } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import './tags-list.scss';
type Props = {
  tags: AppGenreList;
  isLoading?: boolean;
  className?: string;
};
export const TagsList: React.FC<Props> = observer(function TagsList({
  tags,
  isLoading,
  className
}) {
  const NUMBER_OF_TAGS = 4;
  return <div className={cx('tags__container', {
    'tags__container--loading': isLoading,
    [className]: !!className
  })}>
      {tags?.items?.map((item, idx) => idx < NUMBER_OF_TAGS ? <mark key={idx}>{item.name}</mark> : undefined)}
    </div>;
});