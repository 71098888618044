import { Trans, t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { DialogResult, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { ordinalSuffix, pathTv } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './stream-queue-dialog.scss';
export const StreamQueueDialog = withFocusable()<{
  action: (action: DialogResult, value?: unknown) => void;
}>(observer(function StreamQueueDialog({
  action,
  setFocus
}) {
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const henImg = pathTv + require('images/svg/hen-wait.svg');
  useEffect(() => {
    if (streamViewUtilsService.state === 'Initialized') {
      action(DialogResult.OK);
    }
  }, [streamViewUtilsService.state]);
  useEffect(() => {
    setFocus();
  }, []);
  const placeInLine = ordinalSuffix(streamViewUtilsService.queueState.position);
  const timeInLine = streamViewUtilsService.queueState.time;
  return <div className={'stream-queue__container'}>
        <h1 className={'title'}>
          <>{t({
          message: 'Sorry but all servers are currently busy'
        })}</>
        </h1>
        <img decoding={'sync'} src={henImg} />
        {!!streamViewUtilsService.queueState?.position && <div className={'place-text'}>
            <span>
              <Trans context={'Place in line in a queue'}>
                You are in line for the <span>{placeInLine}</span> place
              </Trans>
            </span>
          </div>}

        {!!streamViewUtilsService.queueState?.time && <div className={'time-text'}>
            <span>
              <Trans context={'Estimated time of a queue'}>{timeInLine} min</Trans>
            </span>
          </div>}

        <FocusableButton type={'primary'} onEnterPress={() => action(DialogResult.Close)}>
          <Trans context={'Button - Quit the queue'}>Get out of line</Trans>
        </FocusableButton>
      </div>;
}));
export default StreamQueueDialog;