import currencyFormatter from 'currency-formatter';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectStore } from '../objectStore/objectStore';
import { ISubscriptionPlan } from './interfaces';

export class SubscriptionPlansStore extends ObjectStore<ISubscriptionPlan> {
  constructor(requestQueue: RequestQueue) {
    super(requestQueue, 'v2/shop/subscriptionplans');
  }

  protected createAndLoadItem(res: ISubscriptionPlan): ISubscriptionPlan {
    return res;
  }

  public getPriceByPlanName = (name: string) => {
    const localesMap = {
      SEK: 'de-DE',
    };

    const plan = this.items.find((item) => item.name === name);

    if (!plan) return '-.--';

    let price = 0;

    if (plan.pricing && Array.isArray(plan.pricing)) {
      price += plan.pricing.reduce((prev, curr) => prev + curr.amount_in_cents / 100, 0);
    }

    if (plan.add_ons && Array.isArray(plan.add_ons)) {
      plan.add_ons.forEach((addon) => {
        price += addon.pricing.reduce((prev, curr) => prev + curr.amount_in_cents / 100, 0);
      });
    }

    const { currency } = plan.pricing[0];
    return currencyFormatter.format(price, {
      code: currency,
      decimal: '.',
      thousand: ',',
      locale: localesMap[currency] || 'nl-NL',
      symbol: currency === 'CAD' ? 'C$' : undefined,
    });
  };
}
