import { IDEAL_PING_MS, LOCALSTORAGE_AUTH_CLOUD_API_TOKEN } from '@utomik-app-monorepo/constants';

import { PersistentStore } from '../../../app/global/persistentStore/persistentStore';
import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { HasGatewayResource } from '../streamStore/hasGatewayResource';
import { QueueLength } from '../streamStore/queueLength';
import {
  IStreamGetGatewayList,
  IStreamGetGatewayListAvailableGateway,
  StreamGetGatewayList,
} from '../streamStore/streamGetGatewayList';

export class GatewayStore {
  private readonly _sessionStore: PersistentStore;
  private readonly _pingTimeoutMs: number = 2000;
  private readonly _idealPingMs: number = IDEAL_PING_MS;
  private readonly _idealQueue: number = 10;

  private readonly _gatewayList: StreamGetGatewayList;
  private readonly _hasResource: HasGatewayResource;
  private readonly _queueLength: QueueLength;

  constructor(requestQueue: RequestQueue, sessionStore: PersistentStore) {
    this._gatewayList = new StreamGetGatewayList(requestQueue, 'gatewaysList', '/v2/cloud/servergroups');
    this._hasResource = new HasGatewayResource(requestQueue, 'hasResource', '/mgtm/server/has-resource');
    this._queueLength = new QueueLength(requestQueue, 'queueLength', '/queue/length');
    this._sessionStore = sessionStore;
  }

  public get gatewayList(): StreamGetGatewayList {
    return this._gatewayList;
  }

  public get hasResources(): HasGatewayResource {
    return this._hasResource;
  }

  public get queueLength(): QueueLength {
    return this._queueLength;
  }

  /**
   Calculation and sorting of ping through a web socket for each of the gateways
   */
  public getSortedByPingGatewayList = async () => {
    const gatewayList = await this._gatewayList.getGatewayList();
    const authCloudApiToken = this._sessionStore.get<string>(LOCALSTORAGE_AUTH_CLOUD_API_TOKEN);

    const gatewayListWithPing = await Promise.allSettled(
      gatewayList.map(async (gateway) => {
        return this._calculateWssPing(gateway, authCloudApiToken);
      })
    );

    const filteredData = gatewayListWithPing.filter(
      (a) => a.status == 'fulfilled'
    ) as PromiseFulfilledResult<IStreamGetGatewayList>[];

    const sortByPing = (a, b) => a.value.ping - b.value.ping;

    return filteredData.sort(sortByPing).map((item) => item.value);
  };

  public getAvailableGatewayForStreaming = async (
    appId: ObjectID = null
  ): Promise<IStreamGetGatewayListAvailableGateway[]> => {
    const authCloudApiToken = this._sessionStore.get<string>(LOCALSTORAGE_AUTH_CLOUD_API_TOKEN);
    const sortedByPingGatewayList = await this.getSortedByPingGatewayList();

    /**
     Checking available resource for start streaming
     **/
    const gatewayResource = await Promise.allSettled(
      sortedByPingGatewayList.map(async (gateway) => {
        return this._hasResource.getHasGatewayResource(gateway, authCloudApiToken, appId);
      })
    );

    const filteredGatewayResource = gatewayResource.filter(
      (a) => a.status === 'fulfilled' && a.value.hasResources
    ) as PromiseFulfilledResult<IStreamGetGatewayListAvailableGateway>[];
    const filteredGatewayResourceValue = filteredGatewayResource.map((e) => e.value);

    /**
     Checking queue for each gateway
     **/
    const gatewayQueue = await Promise.allSettled(
      filteredGatewayResourceValue.map(async (gateway) => {
        return this._queueLength.getQueueLength(gateway, authCloudApiToken);
      })
    );

    const filteredGatewayQueue = gatewayQueue.filter(
      (a) => a.status == 'fulfilled'
    ) as PromiseFulfilledResult<IStreamGetGatewayListAvailableGateway>[];
    const filteredGatewayQueueValue = filteredGatewayQueue.map((e) => e.value);

    return this._calculateBetterExperience(filteredGatewayQueueValue) ?? [];
  };

  /**
   With each of the gateways, a websocket session is created and a message is sent 5 times.
   First message from [wssMessage] contains time when user start ping activities [new Date.getTime()]

   5 times we repeat sending [wssMessage] message and then calculate the difference between
   current time and time when user start ping activities.
   */
  private _calculateWssPing = async (gateway: IStreamGetGatewayList, cloudToken: string) => {
    let sendCount = 0;
    let wssMessage;
    const currentWss = new WebSocket(`wss://${gateway.address.split('//')[1]}/?${cloudToken}&ping=1`);

    return new Promise((resolve, reject) => {
      currentWss.onopen = () => {
        wssMessage = { type: 'settings', action: 'ping', value: new Date().getTime() };

        currentWss.send(JSON.stringify(wssMessage));
      };

      currentWss.onmessage = (evt) => {
        if (sendCount < 4) {
          currentWss.send(JSON.stringify(wssMessage));
          sendCount++;
        } else {
          const delta = Math.ceil((new Date().getTime() - JSON.parse(evt.data).value) / 5);
          currentWss.close();

          resolve({ ping: delta, ...gateway });
        }
      };

      currentWss.onerror = () => {
        reject({ ping: -1, ...gateway });
      };

      setTimeout(() => {
        /**
         * If the request is too long we return "HI" ping value to not remove that gateway from the list
         * */
        resolve({ ping: 999, ...gateway });
        currentWss?.close();
      }, this._pingTimeoutMs);
    });
  };

  private _calculateBetterExperience = (gateways: IStreamGetGatewayListAvailableGateway[]) => {
    const result = gateways.reduce((acc, cur) => {
      return [
        ...acc,
        {
          ...cur,
          weight: (Math.exp(cur.ping / this._idealPingMs) + Math.exp(cur.predictedQueue / this._idealQueue)).toFixed(4),
        },
      ];
    }, []);

    return result.sort((a, b) => a.weight - b.weight);
  };
}
