import { action, makeObservable } from 'mobx';

import { AxiosTransport } from '../../../dataStore/transports/axiosTransport/axiosTransport';
import { IHttpResponse } from '../../../dataStore/transports/httpTransport/httpTransport';
import { IUploadData, IUploadDataRequest, IUploadResponse, IUploadSdn } from './types';

export default class UploadService {
  constructor(private readonly _httpTransport: AxiosTransport) {
    makeObservable(this);
  }

  @action
  public async uploadFile(url: string, uploadData: IUploadData): Promise<IUploadResponse | void> {
    const sanitizedFileName = uploadData.filename.replace(/[\s]/g, '_');
    const dataToRequest: IUploadDataRequest = {
      filename: sanitizedFileName,
      tag: uploadData.tag,
    };

    let initialResponse: IUploadResponse | null = null;
    let rejectMessage = 'FAILED_TO_CREATE_IMAGE';

    try {
      const result = await this.createImageResource(url, dataToRequest);

      if (result?.data) {
        initialResponse = result.data;
        rejectMessage = 'UPLOAD_TO_CDN_FAILED';
        await this.uploadAndValidateMedia(url, initialResponse, uploadData);
        initialResponse.is_validated = true;
        return result.data;
      }
    } catch (error) {
      try {
        await this.handleUploadError(url, initialResponse);
        throw new Error(rejectMessage);
      } catch (e) {
        throw new Error(rejectMessage);
      }
    }
  }

  private async createImageResource(
    url: string,
    dataToRequest: IUploadDataRequest
  ): Promise<IHttpResponse<IUploadResponse> | undefined> {
    return this._httpTransport.post<IUploadResponse, IUploadDataRequest>(url, dataToRequest);
  }

  private async uploadAndValidateMedia(
    url: string,
    initialResponse: IUploadResponse,
    uploadData: IUploadData
  ): Promise<void> {
    await this.uploadToCDN(uploadData, initialResponse);
    await this.validateMedia(url, initialResponse.id);
  }

  private async handleUploadError(url: string, initialResponse: IUploadResponse | null): Promise<void> {
    if (initialResponse && initialResponse.id) {
      await this.deleteMedia(url + '/' + initialResponse.id);
    }
  }

  @action
  public async uploadToCDN(uploadData: IUploadData, cdnHeaders: IUploadResponse): Promise<void> {
    const { mechanism } = cdnHeaders.upload_action;
    let method: 'post' | 'put' = 'post';
    let postData = null;

    switch (mechanism) {
      case 's3_upload': // amazon data
        postData = this.getAmazonFormData(uploadData, cdnHeaders);
        break;

      case 'akamai_netstorage_upload': // akamai data
        postData = this.getAkamaiFormData(uploadData, cdnHeaders);
        method = 'put';
        break;

      default:
        // Other cases are not supported, so don't do anything
        break;
    }
    if (postData !== null) {
      try {
        await this._httpTransport[method](postData.url, postData.formdata, postData.options);
      } catch (error) {
        throw new Error('UPLOAD_MECHANISM_NOT_SUPPORTED');
      }
    } else {
      throw new Error('UPLOAD_MECHANISM_NOT_SUPPORTED');
    }
  }

  @action
  public async validateMedia(url: string, mediaId: number): Promise<void> {
    await this._httpTransport.post(url + '/' + mediaId + '/do_validate', {});
  }

  @action
  public getAmazonFormData(uploadData: IUploadData, cdnHeaders: IUploadResponse): IUploadSdn | null {
    if (uploadData.file === undefined) {
      return null;
    }

    const config = cdnHeaders.upload_action.config;
    const amazonHeaders = {
      'Content-Type': undefined,
      Accept: 'application/json',
    };

    const fd = new FormData();
    const amazonConfig = config;

    fd.append('key', amazonConfig.key);
    fd.append('AWSAccessKeyId', amazonConfig.AWSAccessKeyId);
    fd.append('policy', amazonConfig.policy);
    fd.append('acl', amazonConfig.acl);
    fd.append('signature', amazonConfig.signature);
    fd.append('x-amz-storage-class', amazonConfig['x-amz-storage-class']);
    fd.append('file', uploadData.file);

    const amazonFormData: IUploadSdn = {
      url: amazonConfig.url,
      formdata: fd,
      options: { headers: amazonHeaders },
    };

    return amazonFormData;
  }

  @action
  public getAkamaiFormData(uploadData: IUploadData, cdnHeaders: IUploadResponse): IUploadSdn | null {
    if (uploadData.file === undefined) {
      return null;
    }
    const config = cdnHeaders.upload_action.config;
    const akamaiHeaders = {
      'Content-Type': undefined,
      Accept: 'application/json',
      'X-Akamai-ACS-Action': config['action'],
      'X-Akamai-ACS-Auth-Data': config['auth-data'],
      'X-Akamai-ACS-Auth-Sign': config['auth-sign'],
    };

    const fd = new FormData();
    if (uploadData.file instanceof Blob) {
      fd.append('file', uploadData.file);
    } else {
      fd.append('file', this.dataURLtoBlob(uploadData.file));
    }

    const akamaiFormData: IUploadSdn = {
      url: config.url,
      formdata: fd,
      options: { headers: akamaiHeaders },
    };

    return akamaiFormData;
  }

  private extractByteString(dataURI: string): string | null {
    const parts = dataURI.split(',');
    if (parts[0].indexOf('base64') >= 0) {
      return atob(parts[1]);
    } else {
      return decodeURIComponent(parts[1]);
    }
  }

  private extractMimeString(dataURI: string): string | null {
    const parts = dataURI.split(',');
    const mimeStringParts = parts[0].split(':');
    if (mimeStringParts.length >= 2) {
      const mimeType = mimeStringParts[1].split(';')[0];
      return mimeType;
    }
    return null;
  }

  @action
  public dataURLtoBlob(dataURI: string): Blob | string {
    const byteString = this.extractByteString(dataURI);
    const mimeString = this.extractMimeString(dataURI);
    if (byteString && mimeString) {
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      try {
        return new Blob([ia], { type: mimeString });
      } catch (err) {
        return dataURI;
      }
    }

    return dataURI;
  }

  @action
  public async deleteMedia(url: string): Promise<void> {
    await this._httpTransport.delete(url);
  }
}
