import { action, computed, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncObject, AsyncObjectState } from '../objectStore/asyncObject';

export enum SubscriptionStatus {
  None = '',
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Deactivated = 'DEACTIVATED',
  Expired = 'EXPIRED',
}

interface Order {
  id: number;
  reseller: {
    id: number;
    name: string;
  };
}

interface Subscription {
  id: number;
  status: SubscriptionStatus;
  name: string;
  renews: Date;
  expire_due: Date;
  order: Order;
}

export interface ApiAccountSummary {
  is_owner: boolean;
  can_start_trial: boolean;
  subscription: Subscription;
}

export class AccountSummary extends AsyncObject<ApiAccountSummary> {
  public constructor(requestQueue: RequestQueue, url = 'v2/accounts/mine/summary') {
    super(requestQueue, 'mine', url);
    makeObservable(this);
  }

  @observable
  private _apiAccountSummmary: ApiAccountSummary = null;

  @action
  public load(x: ApiAccountSummary): void {
    this._apiAccountSummmary = x;
  }

  @action
  public unload(): void {
    this._apiAccountSummmary = null;
    this.setState(AsyncObjectState.None);
  }

  @computed
  public get order(): Order {
    return this._apiAccountSummmary?.subscription?.order ?? null;
  }

  @computed
  public get subscription(): Subscription {
    return this._apiAccountSummmary?.subscription ?? null;
  }

  @computed
  public get isOwner(): boolean {
    return this._apiAccountSummmary?.is_owner ?? false;
  }

  @computed
  public get canStartTrial(): boolean {
    return this._apiAccountSummmary?.can_start_trial ?? false;
  }

  @computed
  public get name(): string {
    return this._apiAccountSummmary?.subscription?.name ?? '';
  }

  @computed
  public get status(): SubscriptionStatus {
    return this._apiAccountSummmary?.subscription?.status ?? SubscriptionStatus.None;
  }

  @computed
  public get renewalDate(): Date {
    return this._apiAccountSummmary?.subscription?.renews ?? null;
  }

  @computed
  public get expireDue(): Date {
    return this._apiAccountSummmary?.subscription?.expire_due ?? null;
  }
}
