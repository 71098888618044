import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { TileType } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import './focusable-see-all.scss';
type Props = {
  text?: string;
  link?: string;
  style?: CSSProperties;
  focused?: boolean;
  onEnterPress?: () => void;
  setFocus?: (key?: string) => void;
  focusKey?: string;
  onBecameFocused?: (props: any, itemProps?: any) => void;
  channelView?: boolean;
  type: TileType;
  hasMouseOver?: boolean;
  item: {
    id: 'seeall';
  };
};
export const FocusableSeeAll = withFocusable()<Props>(React.memo(function FocusableSeeAll({
  text = 'See all',
  style,
  focused,
  setFocus,
  channelView,
  hasMouseOver = true
}: Props) {
  const handleClick = () => {
    dispatchEnterEvent();
  };
  const handleMouseOver = () => {
    if (hasMouseOver) {
      setFocus();
    }
  };
  return <div style={style} className={'see-all__container'} onMouseOver={handleMouseOver} onClick={handleClick}>
        <div className={cx('tile-image', {
      'tile-image--focus': focused && !channelView,
      'tile-image--channel-view': channelView,
      'tile-image--channel-view--focus': channelView && focused
    })}>
          {text}
        </div>
      </div>;
}));