import { secToTime } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { CSSProperties } from 'react';
import './progress.scss';
type Props = {
  title?: string;
  value?: number;
  maxValue?: number;
  minValue?: number;
  showCurrentValue?: boolean;
  showStartEndValues?: boolean;
  style?: CSSProperties;
  className?: string;
  valuePosition?: 'top' | 'bottom';
  background?: 'light' | 'dark';
  type?: 'percent' | 'time';
};
export const Progress: React.FC<Props> = observer(function Progress({
  title,
  value = 0,
  minValue = 0,
  maxValue = 0,
  showCurrentValue,
  showStartEndValues,
  style,
  className = '',
  valuePosition = 'top',
  background = 'light',
  type = 'percent'
}) {
  const divided = isNaN(value / maxValue) ? 0 : value / maxValue;
  const valuePercent = divided * 100 + '%';
  let currentValue;
  let currentMaxValue;
  let currentMinValue;
  let transition;
  if (type === 'percent') {
    currentValue = (divided * 100).toFixed() + '%';
    currentMaxValue = maxValue;
    currentMinValue = minValue;
    transition = '800ms width';
  } else if (type === 'time') {
    currentValue = secToTime(value);
    currentMaxValue = secToTime(maxValue);
    currentMinValue = secToTime(value);
    transition = '200ms width linear';
  }
  return <div className={`progress__wrapper ${className}`} style={style}>
      {!!title && <p className={'title'}>{title}</p>}
      <div className={`progressbar-container--${background}`}>
        <div className={'progressbar'} style={{
        transition,
        width: valuePercent
      }}>
          {showCurrentValue && <p className={`progress-value--${valuePosition}`}>{currentValue}</p>}
        </div>
      </div>
      {showStartEndValues && <div className={'progress-values-container'}>
          <p>{currentMinValue}</p>
          <p>{currentMaxValue}</p>
        </div>}
    </div>;
});