import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './leave-queue-dialog.scss';
export const LeaveQueueDialog = observer(function LeaveQueueDialog({
  action
}) {
  return <div className={'leave-queue__container'}>
      <h1 className={'title'}>
        <>
          {t({
          context: 'Asks user if they want to leave the queue and redirect to the game info page',
          message: 'Are you sure you want to leave the queue?'
        })}
        </>
      </h1>
      <div className={'buttons-container'}>
        <FocusableButton autofocus type={'secondary'} onEnterPress={() => action(DialogResult.OK)}>
          {t({
          context: 'Button - User decide to stay in queue and not leave it',
          message: 'Stay in the queue'
        })}
        </FocusableButton>
        <FocusableButton type={'delete'} onEnterPress={() => action(DialogResult.Action1)}>
          {t({
          context: 'Button - User decide to leave the queue and redirect to the game info page',
          message: 'Leave'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default LeaveQueueDialog;