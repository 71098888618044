import { Routes } from '@utomik-app-monorepo/constants';
import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { Application } from '../../../dataStore/stores/applicationStore/application';
import { DialogQueue } from '../dialogQueue/dialogQueue';
import { NavigationController } from '../navigationController/navigationController';
import { TvSidebarController } from '../tvSidebarController/tvSidebarController';

interface VideoPlayerConfig {
  fullscreen: boolean;
  url: string;
  backgroundUrl: string;
  muted: boolean;
  showLoader: boolean;
  forciblyShow: boolean;
  looping: boolean;
}

export class VideoPlayerController {
  private readonly _autorunDisposer: ReturnType<typeof autorun>;
  private readonly _navigationController: NavigationController;
  private readonly _dialogQueue: DialogQueue;
  private readonly _sidebarController: TvSidebarController;

  @observable
  private _config: VideoPlayerConfig = {
    url: null,
    muted: true,
    fullscreen: false,
    backgroundUrl: null,
    showLoader: true,
    forciblyShow: false,
    looping: false,
  };

  @observable
  private _currentApp: Application;

  /**
   * Freeze the video player for application change
   * */

  @observable
  private _frozen: boolean;

  @observable
  private _paused: boolean;

  constructor(
    navigationController: NavigationController,
    dialogQueue: DialogQueue,
    sidebarController: TvSidebarController
  ) {
    this._navigationController = navigationController;
    this._dialogQueue = dialogQueue;
    this._sidebarController = sidebarController;

    this._autorunDisposer = autorun(() => {
      if (this._frozen) return;

      this.setCurrentApp(this._navigationController.currentApp);
    });

    makeObservable(this);
  }
  @action
  public setConfig(config: VideoPlayerConfig) {
    this._config = config;
  }
  @computed
  public get config() {
    return this._config;
  }

  @action
  public setFrozen(val: boolean) {
    this._frozen = val;
  }
  @computed
  public get frozen() {
    return this._frozen;
  }

  @action
  public setCurrentApp(app: Application) {
    this._currentApp = app;
  }

  @computed
  public get currentApp() {
    return this._currentApp;
  }

  @action
  public setPaused(val: boolean) {
    this._paused = val;
  }

  @computed
  public get paused() {
    const locationHash = location.hash.split('#');
    const isGameRunPage = locationHash[1]?.startsWith(Routes.GameRun);

    return (this._paused || this._sidebarController.isExpanded || this._dialogQueue.hasDialogs) && !isGameRunPage;
  }
  @action
  public reset() {
    this.setFrozen(false);
    this.setPaused(false);
    this._currentApp = null;
  }

  public dispose = () => {
    this._autorunDisposer();
  };
}
