import cx from 'classnames';
import React from 'react';
import './GamepadButtons.scss';
type Props = {
  type: 'round' | 'long';
  label?: string;
  name: string;
};
export const GamepadButtons: React.FC<Props> = React.memo(function GamepadButtons({
  type,
  label,
  name
}) {
  return <div className={'gamepad-button__container'}>
      <div className={cx('gamepad-button', {
      [`gamepad-button--${type}`]: true
    })}>{name}</div>
      {!!label && <p className={'label'}>{label}</p>}
    </div>;
});