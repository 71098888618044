import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import cx from 'classnames';
import marksy from 'marksy/jsx';
import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import './markdown.scss';
interface MarskyProps {
  href?: string;
  title?: string;
  children: React.ReactChild;
  focusable?: boolean;
  setFocus?: (props?: any) => void;
  idx?: number;
}
export interface MarkdownProps {
  text: string;
  callback?: (href: string) => void;
  onFocusLink?: (props: any, itemProps: any) => void;
  hasFocusableLinks?: boolean;
  focusKey?: string;
}
const FocusableDummy = withFocusable()<{
  style?: CSSProperties;
}>(({
  style
}) => {
  return <span style={style} className={'local-link-dummy'} />;
});
const h1: React.FC<MarskyProps> = ({
  children
}: MarskyProps): React.ReactElement => {
  return <h2 className="markdown-heading-1">{children}</h2>;
};
const h2: React.FC<MarskyProps> = ({
  children
}: MarskyProps): React.ReactElement => {
  return <h2 className="markdown-heading-2">{children}</h2>;
};
const h3: React.FC<MarskyProps> = ({
  children
}: MarskyProps): React.ReactElement => {
  return <h3 className="markdown-heading-3">{children}</h3>;
};
const p: React.FC<MarskyProps> = ({
  children
}: MarskyProps): React.ReactElement => {
  return <p className="markdown-paragraph">{children}</p>;
};
const strong: React.FC<MarskyProps> = ({
  children
}: MarskyProps): React.ReactElement => {
  return <strong className="markdown-strong">{children}</strong>;
};
const ul: React.FC<MarskyProps> = ({
  children
}: MarskyProps): React.ReactElement => {
  return <ul className="markdown-list">{children}</ul>;
};
const li: React.FC<MarskyProps> = ({
  children
}: MarskyProps): React.ReactElement => {
  return <li className="markdown-list-item">
      <FontAwesomeIcon icon={faAngleRight} className="markdown-list-item-icon" />
      <div className="markdown-list-item-text">{children}</div>
    </li>;
};
const FocusableLocalLink = withFocusable()<{
  href: string;
  forciblyFocused: boolean;
  disabled?: boolean;
  onClick: (e: any) => void;
}>(({
  focused,
  children,
  href,
  focusable,
  forciblyFocused,
  disabled,
  onClick
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  return <Link ref={linkRef} to={href} className={cx('local-link-focusable', {
    'local-link-focusable--focus': focused || forciblyFocused,
    'local-link-focusable--disabled': disabled
  })} onClick={onClick}>
      {children}
    </Link>;
});

// We are left this. You may decide in the future to move the user to an external browser.
/*const FocusableExternalLink = withFocusable()(({ focused, children }) => {
  return (
    <span
      className={cx('local-link-focusable', {
        'local-link-focusable--focus': focused,
      })}
    >
      {children}
    </span>
  );
});*/

const Markdown: React.FC<MarkdownProps> = observer(function Markdown({
  text,
  callback,
  onFocusLink,
  hasFocusableLinks = false,
  focusKey
}: MarkdownProps): React.ReactElement {
  const linksRef = useRef<string[]>([]);
  const CustomAnchor = withFocusable()<MarskyProps>(observer(function CustomAnchor({
    href,
    children,
    idx
  }: MarskyProps) {
    const [focused, setFocused] = React.useState(false);
    const handleFocusLink = useCallback((props, itemProps) => {
      setFocused(true);
      onFocusLink(props, itemProps);
    }, []);
    const handleBlurLink = useCallback(() => {
      setFocused(false);
    }, []);
    const handleLinkClick = (e, href) => {
      e?.preventDefault();
      callback(href);
    };
    return <>
          <FocusableDummy onBecameBlurred={handleBlurLink} onBecameFocused={handleFocusLink} focusable={hasFocusableLinks} focusKey={focusKey + idx} onEnterPress={() => callback(href)} />
          <FocusableLocalLink forciblyFocused={focused} focusKey={href + idx} focusable={false} disabled={!hasFocusableLinks} href={href} onBecameFocused={onFocusLink} onEnterPress={() => handleLinkClick(null, href)} onClick={e => handleLinkClick(e, href)}>
            {children}
          </FocusableLocalLink>
        </>;
  }));
  const settings = {
    createElement: React.createElement,
    components: {},
    elements: {
      h1,
      h2,
      h3,
      p,
      strong,
      ul,
      li,
      a: ({
        href,
        title,
        children
      }) => {
        if (href.startsWith('/app-info/')) {
          linksRef.current.push(href);
          return <CustomAnchor idx={linksRef.current.length} href={href} title={title} focusable={hasFocusableLinks}>
              {children}
            </CustomAnchor>;
        }
        return <span>{children}</span>;
      }
    }
  };
  const compile = marksy(settings);
  const description = compile(text).tree;
  return <div className="markdown">{description}</div>;
});
export default Markdown;