import { log } from '@utomik-app-monorepo/logger';
import moment from 'moment/moment';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncObjectState, ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';

export interface ApiServiceSetting {
  id: ObjectID;
  name: string;
  value: any;
}
export class ServiceSettingStore extends ObjectStore<ApiServiceSetting> {
  public constructor(requestQueue: RequestQueue, url = `v1/servicesettings`, retries = null) {
    super(requestQueue, url, retries);
  }
  protected createAndLoadItem(x: ApiServiceSetting): ApiServiceSetting {
    return x; //No "translation" needed
  }

  public getServiceSetting = (nameOrId: string | number) => {
    const data: { setting: ApiServiceSetting; isActive: boolean } = {
      setting: {
        name: null,
        value: null,
        id: null,
      },
      isActive: false,
    };

    if (this.state === AsyncObjectState.Done) {
      const field = typeof nameOrId === 'number' ? 'id' : 'name';

      const setting = this.items.find((setting) => setting[field] === nameOrId);

      if (setting) {
        data.setting = setting;

        if (setting?.value?.starts_at && setting?.value?.ends_at) {
          data.isActive = moment().isBetween(setting.value.starts_at, setting.value.ends_at, undefined, '[)');
        }
      }
    } else if (this.state === AsyncObjectState.Pending) {
      log('ServiceSettingStore is fetching');
    } else {
      log('ServiceSettingStore should be fetched');
    }
    return data;
  };

  public get gamesComEventSetting() {
    return this.getServiceSetting('event_gamescom_2023');
  }
}
