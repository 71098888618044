import { NavigationControllerContext, VideoPlayerControllerContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
export const useResetVideoPlayerOnStream = () => {
  const videoPlayerController = useContext(VideoPlayerControllerContext);
  const navigationController = useContext(NavigationControllerContext);
  useEffect(() => {
    videoPlayerController.reset();
    return () => {
      videoPlayerController.setCurrentApp(navigationController.currentApp);
    };
  }, []);
};