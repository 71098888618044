export interface IApplicationInformation {
  iconPath: string;
  id: string;
  name: string;
  packageId: string;
  show: boolean;
  version: string;
}

export abstract class WindowData {
  public abstract get instance():
    | typeof window.tizen
    | typeof window.webOS
    | typeof window.Hisense
    | typeof window.cordova;

  public abstract get getAppInfo(): any;

  public abstract get deviceId(): string;

  public get isTizen(): boolean {
    return !!window.tizen;
  }

  public get isWebos(): boolean {
    return !!window.webOS;
  }

  public abstract get hasBluetoothSupport(): boolean;

  public abstract get getDeviceInfo(): { version: string; model: string; brand: string };

  public abstract get allowedToPlayMainVideo(): boolean;
}
