import { t } from '@lingui/macro';
import { Countries } from '@utomik-app-monorepo/store';
import { User } from '@utomik-app-monorepo/store';
import { DialogProps } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { CompleteProfileForm } from '../../complete-profile-form/complete-profile-form';
import './complete-profile-dialog.scss';
type CompleteProfileDialogProps = {
  user: User;
  countries: Countries;
} & DialogProps;
export const CompleteProfileDialog: FC<CompleteProfileDialogProps> = observer(function CompleteProfileDialog({
  user,
  countries,
  action
}: CompleteProfileDialogProps): React.ReactElement {
  return <div className="complete-profile-dialog">
      <div className="complete-profile-title">
        <h5>
          <>{t({
            message: 'Please finish your profile to continue'
          })}</>
        </h5>
      </div>
      <CompleteProfileForm user={user} countries={countries} action={action} />
    </div>;
});
export default CompleteProfileDialog;