import { observer } from 'mobx-react';
import React from 'react';
import './progress-circle.scss';
export interface SpinnerCircleProps {
  className: string;
  strokeMargin: number;
  id?: number;
}
const SpinnerCircle: React.FC<SpinnerCircleProps> = observer(function SpinnerCircle({
  className,
  strokeMargin,
  id
}): React.ReactElement {
  const strokeRadius = 50 - 0.5 * strokeMargin;
  return <svg className={className} viewBox={'0 0 100 100'}>
      <g>
        <defs>
          <linearGradient id={'progress_spinner_gradient' + id} gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="0" y2="90">
            <stop offset="0.1" style={{
            stopColor: 'var(--spinner-color)',
            stopOpacity: 1
          }}></stop>
            <stop offset="1" style={{
            stopColor: 'var(--spinner-color)',
            stopOpacity: 0
          }}></stop>
          </linearGradient>
        </defs>

        <g>
          <path style={{
          stroke: 'var(--spinner-color)'
        }} d={`M 50 50
        m 0 -${strokeRadius}
        a ${strokeRadius},${strokeRadius} 0 1 1 0 ${2 * strokeRadius}`} />
          <path style={{
          stroke: `url(#${'progress_spinner_gradient' + id})`
        }} d={`M 50 50
          m 0 ${strokeRadius}a
          ${strokeRadius},${strokeRadius} 0 1 1 0,-${2 * strokeRadius}`} />
        </g>

        <animateTransform values="0,50,50;360,50,50" attributeName="transform" type="rotate" repeatCount="indefinite" additive="replace" dur="1000ms"></animateTransform>
      </g>
    </svg>;
});
export default SpinnerCircle;