import { action, computed, makeObservable, observable } from 'mobx';

export enum Steps {
  General,
  Network,
  Streaming,
  Support,
  PrivacyPolicy,
  Licenses,
  Debug,
}

export class SettingsViewController {
  @observable
  private _activeTab: Steps = null;
  @observable
  private _isMobileView = true;

  constructor() {
    makeObservable(this);
  }
  @action
  private _handleResize = () => {
    this._isMobileView = window.screen.width < 768;
  };
  @computed
  public get isMobileView() {
    return this._isMobileView;
  }
  @computed
  public get activeTab() {
    return this._activeTab;
  }
  @action
  public setActiveTab = (activeTab: Steps) => {
    this._activeTab = activeTab;
  };

  @action
  public reset = () => {
    this._activeTab = null;
  };

  public init = () => {
    window.addEventListener('resize', this._handleResize);
    this._handleResize();
  };

  public dispose = () => {
    window.removeEventListener('resize', this._handleResize);
    this._handleResize();
    this.reset();
  };
}
