import { t } from '@lingui/macro';
import { AFFILIATE_CODE_LG, AFFILIATE_CODE_SAMSUNG, AFFILIATE_CODE_VIDAA } from '@utomik-app-monorepo/constants';
import { Platform, checkCurrentPlatform, isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable } from 'mobx';

import { AuthorizationStore, LoginKeyResponse } from '../../../dataStore/stores/authorizationStore/authorizationStore';
import { TizenWindowData } from '../tizen/tizenWindowData';
import { TokenManager } from '../tokenManager/tokenManager';
import { VidaaWindowData } from '../vidaa/vidaaWindowData';
import { WebOSWindowData } from '../webOS/webOSWindowData';
import { IApiPairingCode, PairingCodeAction, PairingCodeStatus } from './interfaces';

const MOCK_DEVICE_ID = 'vhgfjhvjgjgvjvjvjccjhvhjv';
const CODE_STATUS_CHECKING_INTERVAL = 5000;

export class PairingCodeManager {
  private readonly _authorizationStore: AuthorizationStore;
  private readonly _tokenManager: TokenManager;
  private _tizen: TizenWindowData;
  private _webos: WebOSWindowData;
  private _vidaa: VidaaWindowData;

  private _checkingIntervalId: ReturnType<typeof setInterval>;
  private _expiresIntervalId: ReturnType<typeof setInterval>;

  @observable
  private _pairingCode: IApiPairingCode = null;
  @observable
  private _expiresIn: number;
  @observable
  private _isExpired: boolean;
  @observable
  private _isLoading = true;
  @observable
  private _statusMessage: string;

  public constructor(authorizationStore: AuthorizationStore, tokenManager: TokenManager) {
    this._authorizationStore = authorizationStore;
    this._tokenManager = tokenManager;
    this._tizen = new TizenWindowData();
    this._webos = new WebOSWindowData();
    this._vidaa = new VidaaWindowData();

    makeObservable(this);
  }

  @computed
  public get pairingCode() {
    return this._pairingCode;
  }
  @action
  public setPairingCode(code: IApiPairingCode) {
    this._pairingCode = code;
  }
  @computed
  public get expiresIn() {
    return this._expiresIn;
  }
  @action
  private _setExpiresIn(val: number) {
    this._expiresIn = val;
  }
  @computed
  public get isExpired() {
    return !isNullOrUndefined(this._expiresIn) && this._expiresIn < 1000;
  }
  @computed
  public get isLoading() {
    return this._isLoading;
  }
  @action
  private _setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }
  @action
  private _setStatusMessage(errorMessage: string) {
    this._statusMessage = errorMessage;
  }
  public get statusMessage() {
    return this._statusMessage;
  }

  /**
   * Run interval for checking expiring time
   * */

  private _runExpiresInterval() {
    const expiresCallback = () => {
      if (!this._pairingCode) return;

      const now = Date.now();
      const expiredAt = new Date(this._pairingCode.expires_at).valueOf();

      this._setExpiresIn(expiredAt - now);

      if (this.isExpired) {
        //if pairing code is expired
        this.setPairingCode(null);
        clearInterval(this._expiresIntervalId);
      }
    };
    //set interval to check if code is expired every 1sec
    this._expiresIntervalId = setInterval(expiresCallback, 1000);
  }

  /**
   * Run checking the pairing code status with exact interval
   * @param interval interval value in ms
   * */

  private _runCheckingCodeStatusInterval(interval: number) {
    this._checkingIntervalId = setInterval(async () => {
      await this._checkPairingCodeStatus();
    }, interval);
  }

  /**
   * Fetch the generated pairing code from the server
   * @param action pairing code action
   * */

  public async doGeneratePairingCode(action: PairingCodeAction) {
    this._setIsLoading(true);
    this._setExpiresIn(null);
    this._setStatusMessage(null);
    this._runExpiresInterval();

    if (this.pairingCode?.action !== action || this.isExpired) {
      this.setPairingCode(null);

      const platform = checkCurrentPlatform();

      const affiliateCodesMap = {
        [Platform.Tizen]: AFFILIATE_CODE_SAMSUNG,
        [Platform.WebOS]: AFFILIATE_CODE_LG,
        [Platform.Vidaa]: AFFILIATE_CODE_VIDAA,
      };

      const deviceIdsMap = {
        [Platform.Tizen]: this._tizen?.deviceId,
        [Platform.WebOS]: this._webos?.deviceId,
        [Platform.Vidaa]: this._vidaa?.deviceId,
      };

      const body = {
        affiliate_code: affiliateCodesMap[platform] ?? null,
        device_id: deviceIdsMap[platform] || MOCK_DEVICE_ID,
        device_name: navigator.userAgent,
        action: action,
        type: 'TV' as const,
      };
      try {
        const result = await this._authorizationStore.generatePairingCode(body);

        this.setPairingCode(result);
      } catch (e: any) {
        this._setIsLoading(false);
        this._setStatusMessage(e.message);
        throw e;
      }
    }

    this._runCheckingCodeStatusInterval(CODE_STATUS_CHECKING_INTERVAL);
    this._setIsLoading(false);
  }

  /**
   * Invalidate all the pairing codes except the pairing code with status PENDING
   * */

  public async doInvalidatePairingCodes() {
    this._setIsLoading(true);
    await this._authorizationStore.invalidatePairingCodes({ device_id: this._tizen?.deviceId || MOCK_DEVICE_ID });
    this._setIsLoading(false);
    return true;
  }

  /**
   * Check the current pairing code status and log in user if the token is available
   * */

  private async _checkPairingCodeStatus() {
    if (!this.pairingCode || this._tokenManager.isAuthorized) return;

    try {
      const platform = checkCurrentPlatform();

      const deviceIdsMap = {
        [Platform.Tizen]: this._tizen?.deviceId,
        [Platform.WebOS]: this._webos?.deviceId,
        [Platform.Vidaa]: this._vidaa?.deviceId,
      };

      const response = await this._authorizationStore.checkPairingCodesStatus({
        code: [this._pairingCode?.code],
        device_id: deviceIdsMap[platform] || MOCK_DEVICE_ID,
      });

      const result = response[0];

      switch (result?.status) {
        case PairingCodeStatus.USED: {
          /**
           * The user finished their profile, got a subscription,
           * and this pairing code was linked to the user, it is now used.
           * The ALC token will be in the status response.
           * */
          const loginKeyResponse = new LoginKeyResponse(result.alc_token);

          this._setStatusMessage(t({ context: 'Status of the pairing process', message: 'Logging in...' }));

          await this._tokenManager.loginWithAlc(loginKeyResponse);
          await this._tokenManager.storeALC(loginKeyResponse);

          break;
        }

        case PairingCodeStatus.PENDING: {
          /**
           * The pairing code is linked to a user,
           * but the user might need to finish their profile and/or get a subscription.
           * */
          this._setStatusMessage(t({ context: 'Status of the pairing process', message: 'In progress...' }));
          // eslint-disable-next-line lingui/no-unlocalized-strings
          return console.warn('User already has a valid pairing code. Need to finish profile');
        }

        case PairingCodeStatus.EXPIRED: {
          /**
           *The pairing code expired and cannot be used anymore.
           * */
          // eslint-disable-next-line lingui/no-unlocalized-strings
          return console.warn('Pairing code is expired');
        }

        case PairingCodeStatus.UNUSED: {
          /**
           * The pairing code is not used yet. It was generated, but not used in any subsequent request.
           * */
          // eslint-disable-next-line lingui/no-unlocalized-strings
          return console.warn('Pairing code is unused yet');
        }

        default:
          // eslint-disable-next-line lingui/no-unlocalized-strings
          return console.warn('Unknown pairing code status');
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * Dispose the pairing code manager
   * */

  public dispose() {
    clearInterval(this._checkingIntervalId);
    clearInterval(this._expiresIntervalId);
    this._setStatusMessage(null);
    this._setIsLoading(false);
  }
}
