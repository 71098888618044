import { UtomikError } from '@utomik-app-monorepo/store';
import { LoginControllerContext } from '@utomik-app-monorepo/store';
import { convertToHTTPRange, isNullOrUndefined, parseMinutesFromThrottledError } from '@utomik-app-monorepo/utils';
import { useContext } from 'react';
export const useLoginErrorHandler = (): [(error: UtomikError) => void, string] => {
  const loginController = useContext(LoginControllerContext);
  const handleError = (error: UtomikError): void => {
    const errorMessage = error.message;
    let statusCodeOrRange: number;
    if (!isNullOrUndefined(error.statusCode && error.statusCode !== 429)) {
      statusCodeOrRange = convertToHTTPRange(error.statusCode);
    }
    console.warn(`(Reason: ${errorMessage})`);
    switch (errorMessage) {
      case 'PROFILE_INCOMPLETE':
        // The user profile is still incomplete, we don't want to error on that here.
        return;
      case 'REQUEST_TIMED_OUT':
        loginController.setError('LOGIN_TIMED_OUT');
        return;
      case 'INCORRECT_LOGIN_CREDENTIALS':
        // Wrong username and password combination.
        loginController.setError('LOGIN_INCORRECT_LOGIN_CREDENTIALS');
        return;
      case 'LOGIN_INVALID_EMAIL':
        loginController.setError('LOGIN_INVALID_EMAIL_INPUT');
        return;
      case 'USER_IS_BANNED':
        loginController.setError('LOGIN_USER_IS_BANNED');
        return;
      case 'USER_IS_UNDER_EIGHTEEN_AND_NO_MEMBER':
        loginController.setError('LOGIN_USER_IS_UNDER_AGE');
        return;
      case 'Revoked token.':
        loginController.setError('LOGIN_TOKEN_EXPIRED');
        return;
      default:
        switch (statusCodeOrRange) {
          case 429:
            {
              // Show a message for too many requests, for the user that likes to brute force the login.
              const minutes = parseMinutesFromThrottledError(errorMessage);
              loginController.setError('LOGIN_TOO_MANY_REQUESTS', minutes);
              return;
            }
          case 400:
            loginController.setError('LOGIN_ERROR_400_RANGE');
            return;
          case 500:
            loginController.setError('LOGIN_ERROR_500_RANGE');
            return;
          default:
            loginController.setError('LOGIN_UNKNOWN_ERROR');
            return;
        }
    }
  };
  return [handleError, loginController.errorMessage];
};