import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRightFromBracket, faComment, faCommentSlash, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { ClientControllerContext, DialogFactoryContext, DialogQueueContext, DialogResult, KeyboardControllerContext, NavigationControllerContext, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import './stream-overlay-dialog.scss';
type StreamOverlayItemProps = {
  icon: IconProp;
  title: string;
  onClick?: () => void;
};
const StreamOverlayItem = withFocusable()<StreamOverlayItemProps>(observer(function StreamOverlayItem({
  icon,
  focused,
  title,
  focusable,
  stealFocus,
  onClick
}) {
  const handleClick = () => {
    stealFocus();
    dispatchEnterEvent();
  };
  return <div onClick={handleClick} className={cx('stream-overlay-item', {
    'stream-overlay-item--focus': focused,
    'stream-overlay-item--disabled': focusable === false
  })}>
        <FontAwesomeIcon icon={icon} />
        <span className={'stream-overlay-item-text'}>{title}</span>
      </div>;
}));
export const StreamOverlayDialog = withFocusable()<{
  action: (action: DialogResult, value?: unknown) => void;
}>(observer(function StreamOverlayDialog({
  setFocus
}) {
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const clientController = useContext(ClientControllerContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const dialogQueue = useContext(DialogQueueContext);
  const navigationController = useContext(NavigationControllerContext);
  const keyboardController = useContext(KeyboardControllerContext);
  const handleStreamInput = () => {
    keyboardController.switchInputFocused();
    navigationController.longPressButtonService.dispose();
  };
  const handleExitStream = async () => {
    const res = await dialogFactory.showCloseStreamDialog(navigationController.currentApp);
    if (res === DialogResult.OK) {
      dialogQueue.clear();
      streamViewUtilsService.streamViewRef.disposeStream();
    }
  };
  useEffect(() => {
    setFocus(streamViewUtilsService.isAllowedKeyboard ? 'keyboard-icon' : undefined);
  }, []);
  return <div className={'stream-overlay__wrapper'}>
        <StreamOverlayItem focusable={true} icon={clientController.isNetworkNotificationMinimized ? faCommentSlash : faComment} onEnterPress={clientController.switchNetworkNotificationMinimized} title={t({
      message: 'Use small warnings'
    })} />

        {streamViewUtilsService.isAllowedKeyboard && <StreamOverlayItem focusKey={'keyboard-icon'} icon={faKeyboard} onEnterPress={handleStreamInput} title={t({
      message: 'Open keyboard'
    })} />}

        <StreamOverlayItem focusable={true} icon={faArrowRightFromBracket} onEnterPress={handleExitStream} title={t({
      context: 'Quit the game message',
      message: 'Quit the game'
    })} />
      </div>;
}));
export default StreamOverlayDialog;