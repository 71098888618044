import { action, computed, makeObservable, observable } from 'mobx';

import { ChannelLinkList, ChannelMeta, ChannelType } from '../channelLinkList/channelLinkList';

export interface ApiGenre {
  id: number;
  name: string;
}

export class Genre {
  static parse(apiItem: ApiGenre, channelLinkList: ChannelLinkList): Genre {
    return new Genre(apiItem, channelLinkList);
  }
  private _channelLinkList: ChannelLinkList;

  public constructor(apiGenre: ApiGenre, channelLinkList: ChannelLinkList) {
    makeObservable(this);
    this._init(apiGenre, channelLinkList);
  }

  @action
  private _init({ id, name }: ApiGenre, channelLinkList: ChannelLinkList): void {
    this._id = id;
    this._name = name;
    this._channelLinkList = channelLinkList;
  }

  /**
   * Link to the channel id of the channel related to this genre
   */
  @computed
  public get channelMeta(): ChannelMeta {
    return this._channelLinkList.getChannelMeta(this.id, ChannelType.genre, this.name);
  }

  @observable
  private _id: number;
  @computed
  public get id(): number {
    return this._id;
  }

  @observable
  private _name: string;
  @computed
  public get name(): string {
    return this._name;
  }
}
