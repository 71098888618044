import { faCalendarDays, faClock, faFlag, faGamepad, faHeart, faStar, faVenusMars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import { Trans as TransReact } from '@lingui/react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton } from '@utomik-app-monorepo/components';
import { Routes, myUrl } from '@utomik-app-monorepo/constants';
import { AccountSummaryController, AccountSummaryControllerContext, ApplicationStoreContext, ClientControllerContext, DialogFactoryContext, DialogQueueContext, DialogResult, LoginControllerContext, LoginLocation, NavigationContext, SubscriptionStatus, TokenManagerContext } from '@utomik-app-monorepo/store';
import logoIcon from 'images/svg/small-logo.svg';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import pluralize from 'pluralize';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './profile.module.scss';
const useSubscriptionStatus = (accountController: AccountSummaryController) => {
  const [isStatusRefresh, setStatusRefresh] = useState(false);
  const isActiveSubscription = accountController.summary.status === SubscriptionStatus.Active || moment(accountController?.summary?.subscription?.expire_due).valueOf() > Date.now();
  const handleRefresh = async () => {
    if (isStatusRefresh) return;
    setStatusRefresh(true);
    return accountController.refreshData().finally(() => setStatusRefresh(false));
  };
  return {
    isStatusRefresh,
    isActiveSubscription,
    handleRefresh: handleRefresh
  };
};
export const Profile = withFocusable()(observer(({
  setFocus
}) => {
  const navigate = useNavigate();
  const clientController = useContext(ClientControllerContext);
  const accountController = useContext(AccountSummaryControllerContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const dialogQueue = useContext(DialogQueueContext);
  const applicationStore = useContext(ApplicationStoreContext);
  const tokenManager = useContext(TokenManagerContext);
  const loginController = useContext(LoginControllerContext);
  const {
    backHandler
  } = useContext(NavigationContext);
  const {
    isActiveSubscription,
    isStatusRefresh,
    handleRefresh
  } = useSubscriptionStatus(accountController);
  useEffect(() => {
    if (!dialogQueue.hasDialogs) {
      setFocus();
    }
  }, [dialogQueue.hasDialogs]);
  const mostPlayedGame = useMemo(() => applicationStore.getItem(clientController?.user?.mostPlayedApplication?.id), [clientController?.user?.mostPlayedApplication]);
  const lastPlayedGame = useMemo(() => applicationStore.getItem(clientController?.user?.lastPlayedApplication?.id), [clientController?.user?.lastPlayedApplication]);
  const handleNinjaFlow = async () => {
    if (clientController.user.isNinja) {
      navigate(Routes.Ninja);
    } else {
      await dialogFactory.showFinishExternalActionsDialog('https://www2.utomik.com/ninja');
    }
  };
  const handleGetSubscription = () => {
    dialogFactory.showFinishExternalActionsDialog(`${myUrl}/subscription`);
  };
  const handleLogout = async () => {
    const result = await dialogFactory.showSubmitLogoutDialog();
    if (result !== DialogResult.OK) return;
    loginController.setLocation(LoginLocation.Authorization);
    try {
      await tokenManager.logout();
    } catch (e) {
      console.log(e);
    } finally {
      navigate(Routes.Home);
    }
  };
  const gender = useMemo(() => clientController.getGenderById(clientController.user?.patchUserValues.gender), [clientController.user?.patchUserValues.gender]);
  const fullName = `${clientController.user.patchUserValues?.first_name || ''} ${clientController.user.patchUserValues?.last_name || ''}`;
  const gamesPlayed = clientController.user.gamesPlayed;
  return <div className={styles.container}>
        <div className={styles.backTitleContainer}>
          <FocusableButton onEnterPress={backHandler} type={'back'} className={styles.backButton} />
          <p className={styles.pageTitle}>{t({
          context: 'Page title',
          message: 'Profile'
        })}</p>
        </div>
        <div className={styles.content}>
          <div className={styles.userDataSection}>
            <div className={styles.avatarContainer}>
              <img decoding={'async'} className={styles.avatarImg} src={clientController.user.avatarUrl || require('images/svg/profile_avatar.svg')} />
              <div className={styles.nameContainer}>
                <p>{clientController.user.patchUserValues?.username}</p>
                <small>{fullName}</small>
              </div>
              <div className={styles.editProfileButtonContainer}>
                <FocusableButton autofocus onEnterPress={() => dialogFactory.showEditProfileDialog()} type={'secondary'}>
                  {t({
                context: 'Button - Edit user`s profile',
                message: 'Edit'
              })}
                </FocusableButton>
              </div>
            </div>
            <div className={styles.userDataContainer}>
              <div className={styles.dobItem}>
                <FontAwesomeIcon icon={faCalendarDays} />
                <div>
                  <small>{t({
                  context: 'User`s date of birth',
                  message: 'Date of Birth'
                })}</small>
                  <p>{moment(clientController.user.patchUserValues?.birthdate || '-').format('L')}</p>
                </div>
              </div>
              <div className={styles.countryItem}>
                <FontAwesomeIcon icon={faFlag} />
                <div>
                  <small>{t({
                  context: 'User`s country',
                  message: 'Country'
                })}</small>
                  <p>{clientController?.user?.country?.name || '-'}</p>
                </div>
              </div>
              <div className={styles.genderItem}>
                <FontAwesomeIcon icon={faVenusMars} />
                <div>
                  <small>{t({
                  context: 'User`s gender',
                  message: 'Gender'
                })}</small>
                  <p>{gender.name || '-'}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.subscriptionSection}>
            <div className={styles.topSection}>
              <div className={styles.subscriptionDateContainer}>
                <img decoding={'async'} src={logoIcon} />
                <div>
                  <small>{t({
                  context: 'Title of page section',
                  message: 'Subscription'
                })}</small>
                  <p>
                    {isActiveSubscription ? t({
                  context: 'Shows subscription status',
                  message: 'Active'
                }) : t({
                  context: 'Shows subscription status',
                  message: 'Expired'
                })}
                  </p>
                </div>
              </div>
              <FocusableButton onEnterPress={handleRefresh} type={'secondary'} isLoading={isStatusRefresh}>
                {t({
              context: 'Button - Refresh subscription status',
              message: 'Refresh'
            })}
              </FocusableButton>
            </div>
            <div className={styles.bottomSection}>
              {isActiveSubscription ? <div className={styles.subscriptionDateContainer}>
                  <img decoding={'async'} src={logoIcon} />
                  <div>
                    <small>
                      {t({
                  context: 'Shows when the subscription ends: "Ends 30.01.2024"',
                  message: 'Ends'
                })}
                    </small>
                    <p>
                      {accountController.summary.expireDue ? moment(accountController.summary.expireDue).format('ll') : ' - '}
                    </p>
                  </div>
                </div> : <FocusableButton onEnterPress={handleGetSubscription} size={'medium'} type={'primary'}>
                  {t({
              message: 'Get subscription'
            })}
                </FocusableButton>}
            </div>
          </div>
          <div className={styles.discoverSection}>
            <div className={styles.discoverGroup}>
              <div className={styles.tile}>
                <div className={styles.octagon}>
                  <FontAwesomeIcon icon={faGamepad} />
                </div>
                <small>
                  <TransReact id="{gamesPlayed, plural, one {Game} other {Games}}" values={{
                gamesPlayed
              }} />
                </small>
                <strong>{clientController.user.gamesPlayed}</strong>
              </div>
              <div className={styles.tile}>
                <div className={styles.octagon}>
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <small>{t({
                message: 'Most played'
              })}</small>
                <strong>{mostPlayedGame?.name || '-'}</strong>
              </div>
            </div>
            <div className={styles.discoverGroup}>
              <div className={styles.tile}>
                <div className={styles.octagon}>
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <small>{t({
                context: 'Shows Last played game name',
                message: 'Last session'
              })}</small>
                <strong>{lastPlayedGame?.name || '-'}</strong>
              </div>
              <div className={styles.tile}>
                <div className={styles.octagon}>
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <small>{t({
                context: 'How many games are rated by the user',
                message: 'Rated'
              })}</small>
                <strong>
                  {clientController.user.totalRatedGames}{' '}
                  {pluralize('game', clientController.user.totalRatedGames) || '-'}
                </strong>
              </div>
            </div>
          </div>
          <div className={styles.ninjaSection}>
            <img decoding={'async'} className={styles.ninjaImg} src={require('images/svg/ninja.svg')} />
            <div className={styles.ninjaTextContainer}>
              <Trans context={'Call to action for a user - Become a Utomik Ninja Tester'}>
                <p>Like testing?</p>
                <p>Earn reward points?</p>
              </Trans>
              <FocusableButton className={styles.ninjaButton} onEnterPress={handleNinjaFlow} size={'medium'} type={'secondary'}>
                {clientController.user.isNinja ? <Trans context={'Button - Open Ninja page'}>Open Ninja page</Trans> : <Trans context={'Button - Become a Utomik Ninja Tester'}>Become a Utomik Ninja Tester</Trans>}
              </FocusableButton>
            </div>
          </div>
          <div className={styles.logoutButtonContainer}>
            <FocusableButton icon={<FontAwesomeIcon icon={'arrow-right-from-bracket'} />} onEnterPress={handleLogout} className={styles.logoutButton} type={'delete'}>
              <Trans context={'Button - Log out'}>Log out</Trans>
            </FocusableButton>
          </div>
        </div>
      </div>;
}));