import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Routes } from '@utomik-app-monorepo/constants';
import { useMouseScroll } from '@utomik-app-monorepo/hooks';
import { useMouseSideScroll } from '@utomik-app-monorepo/hooks';
import { AnalyticControllerContext, ChannelStoreContext, ClientControllerContext, EventControllerContext, LogsControllerContext, NavigationControllerContext, NewsItemStoreContext, SearchResultStoreContext, ServiceSettingStoreContext, TvSidebarControllerContext } from '@utomik-app-monorepo/store';
import { SubpageTypes } from '@utomik-app-monorepo/store';
import { LogEventAction, LogEventType } from '@utomik-app-monorepo/store';
import { pathTv, sizeRemToPx } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidebarMenuItem } from '../sidebar-menu-item/sidebar-menu-item';
import './sidebar-menu.scss';
const pointer = pathTv + require('images/svg/sidebar/pointer-menu.svg');
const pointer2 = pathTv + require('images/svg/sidebar/pointer-big.png');
type SidebarContainerProps = {
  currentId: number;
};
const SidebarContainer = withFocusable({
  trackChildren: true,
  forgetLastFocusedChild: true
})<SidebarContainerProps>(observer(function SidebarContainer({
  navigateByDirection,
  hasFocusedChild,
  currentId
}) {
  const sidebarController = useContext(TvSidebarControllerContext);
  const clientController = useContext(ClientControllerContext);
  const newsItemStore = useContext(NewsItemStoreContext);
  const searchResultStore = useContext(SearchResultStoreContext);
  const channelController = useContext(ChannelStoreContext);
  const eventController = useContext(EventControllerContext);
  const navigationController = useContext(NavigationControllerContext);
  const {
    gamesComEventSetting
  } = useContext(ServiceSettingStoreContext);
  const pointerRef = useRef<HTMLImageElement>(null);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const handleMouseTranslate = useMouseSideScroll(scrollableContainerRef, navigateByDirection);
  const isCursorVisible = useMouseScroll(scrollableContainerRef, navigateByDirection);
  const [pointerHeight, setPointerHeight] = useState(250);
  const navigate = useNavigate();
  const location = useLocation();
  const handleEnterPress = useCallback(props => {
    if (props.link !== location.pathname) {
      navigationController.reset();
      searchResultStore.clear();
      searchResultStore.scrollState.clearState();
      channelController.pageScrollState.clearState();
      eventController.pageScrollState.clearState();
      eventController.pageScrollState.clearState();
    }
    navigate(props.link, {
      state: {
        key: Date.now()
      }
    });
  }, []);
  const setScrollPosition = (y: number) => {
    if (!scrollableContainerRef.current) return;
    const offset = sizeRemToPx(61);
    if (y > offset) {
      scrollableContainerRef.current.style.transform = `translateY(${-(y - offset)}px)`;
    } else {
      scrollableContainerRef.current.style.transform = `translateY(0px)`;
    }
  };
  const handleBecameFocused = useCallback(props => {
    setPointerHeight(props.y);
    if (isCursorVisible) return;
    setScrollPosition(props.y);
  }, [isCursorVisible]);
  useEffect(() => {
    const item = Array.from(scrollableContainerRef.current?.children).find(item => item.getAttribute('data-menu-id') === String(currentId)) as HTMLDivElement;
    const containerY = scrollableContainerRef.current?.getBoundingClientRect()?.y;
    const itemY = item?.getBoundingClientRect()?.y;
    setPointerHeight(itemY - containerY);
    setScrollPosition(itemY - containerY);
    sidebarController.setIsExpanded(hasFocusedChild);
  }, [hasFocusedChild, location.pathname]);
  const shouldShowPointers = typeof currentId === 'number' || hasFocusedChild;
  const renderItems = useCallback(() => {
    return sidebarController.menuItems.map(item => {
      if (item.link === Routes.Ninja && !clientController.user.isNinja) return null;
      if (item.link === Routes.Events && !gamesComEventSetting.isActive) return null;
      const isActive = currentId === item.id;
      return <SidebarMenuItem id={item.id} key={item.name} badgeValue={Routes.News === item.link ? newsItemStore.unreadCount : undefined} {...item} icon={item.link === Routes.Profile ? clientController.user.avatarUrl : item.icon} isRoundedIcon={item.link === Routes.Profile} isActive={isActive} focusKey={`MENU_ITEM_${item.id}`} onBecameFocused={handleBecameFocused} onEnterPress={handleEnterPress} />;
    });
  }, [currentId, isCursorVisible]);
  return <div className={'sidebar-container'}>
        <div className={'top-dummy'} onMouseEnter={() => handleMouseTranslate(true, 'top')} onMouseOut={() => handleMouseTranslate(false, 'top')} />
        <div className={'opacity-container'}>
          <div className={'scrollable-container'} ref={scrollableContainerRef}>
            <div className={cx('pointers-container', {
          'pointers-container--show': shouldShowPointers
        })}>
              <img decoding={'sync'} src={pointer} className={'sidebar-pointer'} style={{
            transform: `translateY(${pointerHeight}px)`
          }} alt={'pointer'} ref={pointerRef} />
              <img decoding={'sync'} src={pointer2} className={'sidebar-pointer-2'} style={{
            transform: `translateY(${pointerHeight}px)`
          }} alt={'pointer-2'} ref={pointerRef} />
            </div>

            {renderItems()}
          </div>
        </div>
        <div className={'bottom-dummy'} onMouseEnter={() => handleMouseTranslate(true, 'bottom')} onMouseOut={() => handleMouseTranslate(false, 'bottom')} />
      </div>;
}));
export const SidebarMenu = withFocusable()(observer(function SidebarMenu({
  setFocus,
  navigateByDirection
}) {
  const location = useLocation();
  const sidebarController = useContext(TvSidebarControllerContext);
  const logsController = useContext(LogsControllerContext);
  const analyticController = useContext(AnalyticControllerContext);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const isCursorVisible = useMouseScroll(scrollableContainerRef, navigateByDirection);
  //SVG
  const logo = pathTv + require('images/svg/logo.svg');
  const currentId = useMemo(() => sidebarController?.menuItems?.find(item => location.pathname.split('/')[1] === item.link.split('/')[1])?.id, [location.pathname, isCursorVisible]);
  useEffect(() => {
    const mouseLeaveHandler = () => {
      sidebarController.isExpanded && navigateByDirection('right');
    };
    menuRef.current.addEventListener('mouseleave', mouseLeaveHandler);
    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener('mouseleave', mouseLeaveHandler);
      }
    };
  }, [sidebarController.isExpanded]);
  useEffect(() => {
    const disposer = reaction(() => sidebarController.isExpanded, isExpanded => {
      if (typeof currentId !== 'number') return;
      if (isExpanded) {
        setFocus(`MENU_ITEM_${currentId}`);
        analyticController.sendOpenSubPage({
          subpage_name: sidebarController.menuItems[currentId].name,
          subpage_type: SubpageTypes.Sidebar
        });
        logsController.addNewLogEvent({
          type: LogEventType.Sidebar,
          action: LogEventAction.Open,
          value: location.hash
        });
      } else {
        analyticController.sendCloseSubPage({
          subpage_name: sidebarController.menuItems[currentId].name,
          subpage_type: SubpageTypes.Sidebar
        });
        logsController.addNewLogEvent({
          type: LogEventType.Sidebar,
          action: LogEventAction.Close,
          value: location.hash
        });
      }
    });
    return () => disposer();
  }, [currentId]);
  return <section className={cx('sidebar', {
    'sidebar--expanded': sidebarController.isExpanded
  })} ref={menuRef}>
        <div className={'sidebar__logo'}>
          <img decoding={'sync'} src={logo} alt={'logo'} />
        </div>
        <SidebarContainer currentId={currentId} preferredChildFocusKey={`MENU_ITEM_${currentId}`} />
      </section>;
}));