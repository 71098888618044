import axios from 'axios';
import camelCase from 'lodash/camelCase';

export interface ApiCountryByIp {
  country_code: string;
  country_name: string;
  country_numeric: number;
  currency: string;
}

export interface CountryByIpData {
  countryCode: string;
  countryName: string;
  countryNumeric: number;
  currency: string;
}

export class CountryByIp {
  public data: CountryByIpData;

  private _load(object: ApiCountryByIp) {
    const reMapped = {} as CountryByIpData;

    Object.entries(object).forEach(([key, value]) => {
      reMapped[camelCase(key)] = value;
    });

    this.data = reMapped;
  }

  public async fetch() {
    if (this.data) return;

    //use axios here due to the requestQueue is not ready at this step
    try {
      const { data } = await axios.get<ApiCountryByIp>('https://api.utomik.com/v1/actions/do_check_country_by_ip');

      this._load(data);
    } catch (e) {
      console.log('Error while getting country by ip', e);
    }
  }
}
