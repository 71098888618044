import { TIME_TO_CHECK_UPDATES } from '@utomik-app-monorepo/constants';
import { Routes } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { DialogFactoryContext, PlatformControllerContext } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
export const useAppUpdatesCheck = () => {
  const platformController = useContext(PlatformControllerContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const location = useLocation();
  const locationRef = useRef(location);
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);
  useEffect(() => {
    locationRef.current = location; // Update the ref whenever location changes
  }, [location]);
  const checkUpdate = async () => {
    if (locationRef.current.pathname.startsWith(Routes.GameRun)) return;
    log('START CHECKING UPDATES');
    const res = await window.checkUpdates({
      initial: false
    });
    log(`IS NEW VERSION AVAILABLE = ${res}`);
    if (res) {
      const result = await dialogFactory.showNewUpdateAvailableDialog();
      if (result === DialogResult.OK) {
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    if (platformController.instance && !platformController.isVidaa && !platformController.isAndroidTV && !platformController.isWebOS) {
      intervalRef.current = setInterval(checkUpdate, TIME_TO_CHECK_UPDATES);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
};