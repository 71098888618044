import { VideoPlayerControllerContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
export const useResetVideoPlayer = () => {
  const videoPlayerController = useContext(VideoPlayerControllerContext);
  useEffect(() => {
    videoPlayerController.setConfig({
      muted: true,
      fullscreen: false,
      backgroundUrl: null,
      url: null,
      forciblyShow: false,
      showLoader: true,
      looping: false
    });
  }, []);
};