import { INTERNAL_LB_EVENT_NAME, INTERNAL_RB_EVENT_NAME } from '@utomik-app-monorepo/constants';
import { DialogQueueContext, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
export const useGamepadBumpers = (setFocus: (key: string) => void, sectionNames: string[]) => {
  const navigationController = useContext(NavigationControllerContext);
  const dialogQueue = useContext(DialogQueueContext);
  useEffect(() => {
    const currentIdx = sectionNames.indexOf(navigationController.currentSection);
    const handlerRB = () => {
      if (dialogQueue.hasDialogs) return;
      const nextSection = sectionNames[currentIdx + 1];
      nextSection && setFocus(nextSection);
    };
    const handlerLB = () => {
      if (dialogQueue.hasDialogs) return;
      const prevSection = sectionNames[currentIdx - 1];
      prevSection && setFocus(prevSection);
    };
    document.addEventListener(INTERNAL_RB_EVENT_NAME, handlerRB);
    document.addEventListener(INTERNAL_LB_EVENT_NAME, handlerLB);
    return () => {
      document.removeEventListener(INTERNAL_RB_EVENT_NAME, handlerRB);
      document.removeEventListener(INTERNAL_LB_EVENT_NAME, handlerLB);
    };
  }, [navigationController.currentSection, sectionNames, dialogQueue.hasDialogs]);
};