import { platformURL } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { openNewWindowInCenter, sizeRemToPx } from '@utomik-app-monorepo/utils';
import axios from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';

type SsoProvider = {
  url: string;
  id: number;
  logo: {
    url: string;
    id: number;
    download_url: string;
    tag: string;
  } | null;
  color: string | null;
  registration_fields?: {
    email?: boolean;
    first_name?: boolean;
    last_name?: boolean;
    username?: boolean;
    birthdate?: boolean;
    country?: boolean;
    gender?: boolean;
  };
  slug: string | null;
  display_name: string | null;
  sso_url: string | null;
  disabled: boolean;
  deprecation_date: string | null;
};

export class SsoService {
  @observable
  private _ssoProviders: SsoProvider[] = [];

  constructor() {
    makeObservable(this);
  }
  public async fetch() {
    const providers = await this._fetchSsoProviders();
    this._setSSOProviders(providers);
  }
  @computed
  public get ssoProviders() {
    return this._ssoProviders;
  }
  @action
  private _setSSOProviders(ssoProviders: SsoProvider[]) {
    this._ssoProviders = ssoProviders;
  }

  private _fetchSsoProviders = async (): Promise<SsoProvider[]> => {
    try {
      const res = await axios(platformURL + '/v1/ssoproviders');
      return res?.data;
    } catch (error) {
      log(error);
      return [];
    }
  };

  private async _processInNewWindowAsPromise(url: string) {
    try {
      const socialWindow = openNewWindowInCenter({
        url: url,
        title: 'SocialWindow',
        w: sizeRemToPx(100),
        h: sizeRemToPx(100),
      });

      const timer = setInterval(() => {
        if (socialWindow && socialWindow.closed) {
          clearInterval(timer);
          //this.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_STOPPED);
        }
      }, 500);
    } catch (e) {
      //this.setSsoActionStatus(SsoActionStatuses.SSO_ACTION_STOPPED);
    }
  }

  public async reauthWithSSO(slug: string) {
    const redirectURL = `https://my.utomik.com/#/?frompage=/login&action=reauth`;

    const reauthUrl = `${platformURL}/login/${slug}?auth_type=reauthenticate&auth_action=reauth&next=${encodeURIComponent(
      redirectURL
    )}`;
    try {
      await this._processInNewWindowAsPromise(reauthUrl);
    } catch (e) {
      console.error('Unable to re-auth with SSO');
    }
  }

  private async _generateSecret() {
    // Generate 16 random bytes
    const randomBytes = new Uint8Array(16);
    crypto.getRandomValues(randomBytes);

    // Convert the bytes to a Base64 string
    const base64String = btoa(String.fromCharCode.apply(null, randomBytes as any));

    return base64String;
  }

  public loginWithSso = async (slug: string) => {
    const secret = await this._generateSecret();
    const loginUrl = `${platformURL}/login/${slug}?transfer-session-for=client&secret=${secret}&auth_action=login&next=${'https://webapp.dev.utomik.com'}?frompage=${
      location.href
    }&action=login`;

    window.location.href = loginUrl;
  };
}
