import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { MIN_ITEMS_IN_VIEW } from '@utomik-app-monorepo/constants';
import { Application } from '@utomik-app-monorepo/store';
import { RequestPriority as RP } from '@utomik-app-monorepo/store';
import { AppList } from '@utomik-app-monorepo/store';
import { Channel } from '@utomik-app-monorepo/store';
import { ChannelList } from '@utomik-app-monorepo/store';
import { TileType } from '@utomik-app-monorepo/store';
import { repeatFnCall } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import snakeCase from 'lodash/snakeCase';
import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FocusableSeeAll } from '../focusable-see-all/focusable-see-all';
import { TileItem } from '../tile-item/tile-item';
import './tile-carousel.scss';

/**
 * Component renders Section of tiles. Tiles list items are focusable.
 * */

export type AppTileCarouselProps = {
  list: AppList | ChannelList | {
    items: Application[];
    atEnd: boolean;
  };
  name: string;
  onEnterPress?: (props: any) => void;
  onSeeAllPress?: () => void;
  onChildFocused?: (props: any, itemProps?: any) => void;
  autofocus?: boolean;
  showSeeAllTile?: boolean;
  style?: CSSProperties;
  isLoading?: boolean;
  isSpotlight?: boolean;
  isChannelsView?: boolean;
  isScrollable?: boolean;
  isLong?: boolean;
  hasMouseOver?: boolean;
};
const TilesRow = observer(function TilesRow({
  list,
  onEnterPress,
  isSpotlight,
  isLoading,
  autofocus,
  setFocus,
  navigateByDirection,
  onChildFocused,
  showSeeAllTile,
  onSeeAllPress,
  name,
  isChannelsView,
  isScrollable = true,
  isLong,
  hasMouseOver
}) {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const isMountedRef = useRef(true);
  const [items, setItems] = useState<{
    items: Application[] | Channel[];
    atEnd: boolean;
  }>(null);
  const getData = useCallback(async () => {
    if (list instanceof AppList) {
      try {
        const data = await list.getApps(0, MIN_ITEMS_IN_VIEW, RP.High, true);
        isMountedRef.current && setItems(data);
      } catch (e: any) {
        console.warn(e.message);
      }
    } else if (list instanceof ChannelList) {
      try {
        const data = await list.getChannels(0, MIN_ITEMS_IN_VIEW, false, RP.High);
        isMountedRef.current && setItems(data);
      } catch (e: any) {
        console.warn(e.message);
      }
    } else if (Array.isArray(list?.items)) {
      isMountedRef.current && setItems(list);
    }
  }, [list]);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      if (list instanceof AppList || list instanceof ChannelList) {
        list.abortFetch();
      }
    };
  }, []);
  useEffect(() => {
    getData();
  }, [list]);
  const isAtEnd = items?.atEnd;
  const handleFocus = useCallback((props, itemProps) => {
    if (!scrollableContainerRef.current) return;
    //horizontal centering
    if (isScrollable) {
      scrollableContainerRef.current.style.transform = `translateX(${-props.x}px)`;
    }
    onChildFocused && onChildFocused(props, itemProps);
  }, [isScrollable]);
  const tiles = useMemo(() => {
    return items?.items?.map((item, idx) => {
      const tileType = item instanceof Application ? TileType.Regular : TileType.ChannelHome;
      return <TileItem focusKey={`${snakeCase(name)}_${item?.id}`} key={item?.id} item={item} onEnterPress={!(item as Application)?.isAgeRestricted ? onEnterPress : undefined} onBecameFocused={handleFocus} carouselName={name} idx={idx} isSpotlight={isSpotlight} channelsView={isChannelsView} type={tileType} isLong={isLong} hasMouseOver={hasMouseOver} />;
    });
  }, [items?.items, hasMouseOver]);
  return isLoading || !items?.items?.length ? (
  /**
   * Add skeleton
   * */
  <>
      <div className={'carousel-skeleton__title'} />
      <div className={'carousel-skeleton__tiles-container'}>
        {Array.from({
        length: 8
      }).map((_, idx) => <div key={`${name}_${idx}`} className={cx('carousel-skeleton__tile', {
        'carousel-skeleton__tile--channel-view': isChannelsView
      })} />)}
      </div>
    </>) : <>
      <div className={'title__container'}>
        <span className={'carousel-title'}>{name}</span>
      </div>
      <div className={'apps-container'}>
        <div className="scrollable-container" ref={scrollableContainerRef}>
          {tiles}
          {showSeeAllTile && isAtEnd === false && <FocusableSeeAll focusKey={`${snakeCase(name)}_seeall`} onBecameFocused={handleFocus} onEnterPress={onSeeAllPress} item={{
          id: 'seeall'
        }} channelView={isChannelsView} type={TileType.SeeAll} hasMouseOver={hasMouseOver} />}
        </div>
      </div>
    </>;
});
export const TileCarousel = withFocusable()<AppTileCarouselProps>(observer(function TileCarousel({
  list,
  name,
  onEnterPress,
  onChildFocused,
  autofocus,
  onSeeAllPress,
  showSeeAllTile = true,
  style,
  isLoading,
  isSpotlight,
  setFocus,
  navigateByDirection,
  focused,
  isChannelsView,
  isScrollable,
  isLong,
  hasMouseOver
}) {
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (focused && !isLoading || autofocus) {
      intervalId = repeatFnCall(setFocus);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [focused, isLoading]);
  return <section style={style} className={cx('app-tile-carousel-section', {
    'app-tile-carousel-section--spotlight': isSpotlight,
    'app-tile-carousel-section--channel-view': isChannelsView
  })}>
        <TilesRow list={list} onEnterPress={onEnterPress} onChildFocused={onChildFocused} showSeeAllTile={showSeeAllTile} onSeeAllPress={onSeeAllPress} name={name} autofocus={autofocus} isLoading={isLoading} setFocus={setFocus} navigateByDirection={navigateByDirection} isSpotlight={isSpotlight} isChannelsView={isChannelsView} isScrollable={isScrollable} isLong={isLong} hasMouseOver={hasMouseOver} />
      </section>;
}));