import { t } from '@lingui/macro';
import { ApplicationStoreContext, ClientControllerContext, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { TileType } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { GamepadButtons } from '../GamepadButtons/GamepadButtons';
import './tileSelector.scss';
type Props = {
  show?: boolean;
  forcibleShow?: boolean;
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
  type?: TileType;
};
export const TileSelector: React.FC<Props> = observer(forwardRef(function TilesSelector({
  show,
  className,
  forcibleShow,
  type
}: Props, forwardedRef) {
  const navigationController = useContext(NavigationControllerContext);
  const clientController = useContext(ClientControllerContext);
  const applicationStore = useContext(ApplicationStoreContext);
  const [app, setApp] = useState(null);
  const [showPointer, setShowPointer] = useState(false);
  const buttonsContainerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  if (!type) {
    type = navigationController.currentTileType;
  }
  useEffect(() => {
    setShowPointer(false);
    if (buttonsContainerRef.current) {
      buttonsContainerRef.current.style.animation = 'none';
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setShowPointer(true);
      setApp(applicationStore.getItem(navigationController.currentId));
      if (!buttonsContainerRef.current) return;
      buttonsContainerRef.current.style.animation = 'fade 200ms normal forwards';
    }, 500);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [navigationController.currentSection, navigationController.currentId]);
  let buttons = null;
  switch (type) {
    case TileType.Regular:
      {
        buttons = <>
            {app?.isAllowedToView && <GamepadButtons type={'round'} label={'Info'} name={'A'} />}
            {app?.isAllowedToPlay && clientController.user.canPlay && <GamepadButtons type={'round'} label={'Play'} name={'X'} />}
          </>;
        break;
      }
    case TileType.Channel:
      {
        buttons = <GamepadButtons type={'round'} label={'Open'} name={'A'} />;
        break;
      }
    case TileType.ChannelHome:
      {
        buttons = <GamepadButtons type={'round'} label={'Open'} name={'A'} />;
        break;
      }
    case TileType.ChannelSearch:
      {
        buttons = <GamepadButtons type={'round'} label={'Open'} name={'A'} />;
        break;
      }
    case TileType.Channels:
      {
        buttons = <GamepadButtons type={'round'} label={'Open'} name={'A'} />;
        break;
      }
    case TileType.SeeAll:
      {
        buttons = <GamepadButtons type={'round'} label={'Open'} name={'A'} />;
        break;
      }
    case TileType.Select:
      {
        buttons = <GamepadButtons type={'round'} label={'Select/Unselect'} name={'A'} />;
        break;
      }
    case TileType.News:
      {
        buttons = <GamepadButtons type={'round'} label={'Read more'} name={'A'} />;
        break;
      }
  }
  const shouldShowSelector = show && !!navigationController.currentId || forcibleShow;
  if (navigationController.currentSection === t({
    context: 'Page section name',
    message: 'Featured'
  })) {
    type = TileType.Spotlight;
  }
  return <div className={cx('tiles-wrapper', {
    [`tiles-wrapper--${type}`]: true,
    'tiles-wrapper--show-selector': shouldShowSelector,
    'tiles-wrapper--show-pointer': showPointer && !app?.isAgeRestricted && shouldShowSelector,
    [className]: !!className
  })} ref={forwardedRef}>
        <div className={'buttons__container'} ref={buttonsContainerRef}>
          {buttons}
        </div>
      </div>;
}));