import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React from 'react';
import CirclePath from './circle-path';
import SpinnerCircle from './spinner-circle';

//Inspired by Kevin Qi
//https://github.com/kevinsqi/react-circular-progressbar/blob/master/src

export interface ProgressCircleProps {
  progress?: number;
  strokeMargin?: number;
  classes?: {
    root?: string;
    svg?: string;
    path?: string;
    progress?: string;
    spinner?: string;
  };
  id?: number;
  warning?: boolean;
  children?: any;
}
/**
 * ProgressCircle for showing progress, or a spinner when progress is unknown
 * @param progress (optional) between 0-1. 0 meaning empty, 1 meaning full. "null" or "undefined" to show the spinner instead of progress. Default is 0
 * @param strokeMargin (optional) space reserved for stroke width. Makes the circle radius smaller, to accomodate strokewidth 0 up until. Default is 10
 *                     'strokeMargin' which is specified in the css.
 * @param classes (optional) classes object {root, svg, path, progress, spinner} that can be used to customize classnames
 * @param id (optional) The spinner gradient is shared accross all instances. If you need multiple spinners at once, with different colors, please specify a unique ID. Default is -1
 * @param warning (optional) Indication if there are any problems in the progress. Default is false
 */
export const ProgressCircle: React.FC<ProgressCircleProps> = observer(function ProgressCircle({
  progress = 0,
  strokeMargin = 10,
  classes = {
    root: 'progress-circle',
    svg: 'progress-circle-svg',
    path: 'progress-circle-svg-path',
    progress: 'progress-circle-svg-progress',
    spinner: 'progress-circle-svg--spinner'
  },
  id = -1,
  warning = false,
  children
}): React.ReactElement {
  const originalClasses = {
    root: 'progress-circle',
    svg: 'progress-circle-svg',
    path: 'progress-circle-svg-path',
    progress: 'progress-circle-svg-progress',
    spinner: 'progress-circle-svg--spinner'
  };

  // Overwrite only the classes provided in the props.
  classes = {
    ...originalClasses,
    ...classes
  };
  if (!isNullOrUndefined(progress)) progress = Math.max(Math.min(1, progress), 0);
  if (warning) {
    classes.progress = `${classes.progress} progress-circle-svg-progress--warning`;
    classes.svg = `${classes.svg} progress-circle-svg--warning`;
  }
  return <div className={classes.root}>
      {isNullOrUndefined(progress) ? <SpinnerCircle id={id} className={`${classes.svg} ${classes.spinner}`} strokeMargin={strokeMargin} /> : <svg className={classes.svg} viewBox={'0 0 100 100'}>
          <CirclePath className={classes.path} progress={1} strokeMargin={strokeMargin} />
          <CirclePath className={classes.progress} progress={progress} strokeMargin={strokeMargin} />
          {warning ? <SpinnerCircle id={id} className={`${classes.svg} ${classes.spinner} warning-overlay`} strokeMargin={strokeMargin} /> : null}
        </svg>}
      <div className="progress-value">{children}</div>
    </div>;
});