import { TIME_START_BUTTON_ACTIVATE } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { action, computed, makeObservable, observable } from 'mobx';

import { DialogFactory } from '../../dialogFactory/dialogFactory';
import { DialogQueue, DialogResult } from '../../dialogQueue/dialogQueue';
import { StreamViewUtilsService } from '../streamViewUtilsService';

export const longPressMessages = {
  // 6: { message: 'Continue to hold to exit game', dialog: 'CloseStreamDialog' },
  7: { message: 'Continue to hold to display the additional menu', dialog: 'StreamOverlayDialog' },
};

type LongPressNotificationData = { message: string; dialog: string };

export class LongPressButtonService {
  @observable
  private _activated: boolean;
  @observable
  private _inProgress: boolean;
  @observable
  private _isInitialized: boolean;
  @observable
  private _notificationData: LongPressNotificationData = { message: null, dialog: null };

  private _buttonPressTimer: ReturnType<typeof setTimeout>;
  private readonly _dialogFactory: DialogFactory;
  private _dialogQueue: DialogQueue;
  private _streamViewUtilsService: StreamViewUtilsService;

  private _disposeStream: () => void;

  constructor(dialogFactory: DialogFactory, dialogQueue: DialogQueue, streamViewUtilsService: StreamViewUtilsService) {
    this._dialogFactory = dialogFactory;
    this._dialogQueue = dialogQueue;
    this._streamViewUtilsService = streamViewUtilsService;

    makeObservable(this);
  }
  @action
  public init = (disposeStream: () => void): void => {
    this._disposeStream = disposeStream;

    this._isInitialized = true;
  };
  @action
  private _setActivated(isActivated: boolean) {
    this._activated = isActivated;

    this._handleDialog(isActivated).catch(log);
  }
  @computed
  public get notificationData(): LongPressNotificationData {
    return this._notificationData;
  }
  @action
  private _setNotificationData(data: LongPressNotificationData): void {
    this._notificationData = data;
  }
  @computed
  public get activated() {
    return this._activated;
  }
  @action
  private _setInProgress(val: boolean) {
    this._inProgress = val;
  }
  @computed
  public get inProgress() {
    return this._inProgress;
  }

  @computed
  public get shouldShowNotification() {
    return this.inProgress && !this.activated;
  }

  private async _handleDialog(show: boolean) {
    if (show) {
      this.dispose();

      const res = await this._dialogFactory[`show${this.notificationData.dialog}`]();

      if (res === DialogResult.OK) {
        this._disposeStream();
      }
    } else {
      //close dialog if it exists
      if (
        Object.values(longPressMessages).find((item) => item.dialog === this._dialogQueue.currentDialog?.analyticName)
      ) {
        this._dialogQueue.removeCurrent();
      }
    }
  }

  private _startTimer() {
    this._setInProgress(true);

    this._buttonPressTimer = setTimeout(() => {
      this._setActivated(true);

      this._stopTimer();
    }, TIME_START_BUTTON_ACTIVATE);
  }

  private _stopTimer() {
    this._setInProgress(false);
    clearTimeout(this._buttonPressTimer);
  }

  public handleButtonPress = (isPressed: boolean, index: number) => {
    if (!this._isInitialized || this.activated || this._dialogQueue.hasDialogs) return null;

    this._setNotificationData(longPressMessages[index]);

    return new Promise((resolve) => {
      if (isPressed) {
        this._stopTimer();
        if (!this.activated) {
          this._startTimer();
        }
      } else {
        this._stopTimer();
        if (!this.activated) {
          resolve(false);
        } else {
          resolve(true);
        }
      }
    });
  };
  @action
  public dispose() {
    this._setActivated(false);
    this._stopTimer();
  }
}
