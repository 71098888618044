import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';

//In this case, the API object and the returned object are just... THE SAME.
export interface GlobalStatisticValue {
  id: ObjectID;
  value: number;
}
export class GlobalStatisticValueStore extends ObjectStore<GlobalStatisticValue> {
  public constructor(requestQueue: RequestQueue, url = 'v1/users/me/global/statisticvalues', retries = null) {
    super(requestQueue, url, retries);
  }
  protected createAndLoadItem(x: GlobalStatisticValue): GlobalStatisticValue {
    return x; //No "translation" needed
  }
}
