export class PageScrollState {
  private _currentFocusKey: string;
  private _currentFocusKeySeeAll: string;

  public set currentFocusKey(focusKey: string) {
    this._currentFocusKey = focusKey;
  }

  public get currentFocusKey() {
    return this._currentFocusKey;
  }

  public set currentFocusKeySeeAll(focusKey: string) {
    this._currentFocusKeySeeAll = focusKey;
  }

  public get currentFocusKeySeeAll() {
    return this._currentFocusKeySeeAll;
  }

  public clearState() {
    this._currentFocusKey = null;
    this._currentFocusKeySeeAll = null;
  }
}
