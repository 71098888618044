import { log } from '@utomik-app-monorepo/logger';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncList } from '../objectStore/asyncList';
import { ObjectID } from '../objectStore/asyncObject';
import { ApiAppStagingTier, AppStagingTier } from './appStagingtierStore/appStagingtier';

export class AppStagingTierList extends AsyncList<ApiAppStagingTier> {
  public constructor(requestQueue: RequestQueue, applicationId: ObjectID, url?: string) {
    super(requestQueue, applicationId, url);
  }

  public async getStagingTiers(): Promise<AppStagingTier[]> {
    try {
      const stagingTiers = await super.getItems(0, Number.MAX_SAFE_INTEGER);
      return stagingTiers.items.map((apiAppStagingTier): AppStagingTier => {
        return AppStagingTier.parse(apiAppStagingTier);
      });
    } catch (error) {
      //TODO: Davide: Implement proper error handling.
      log(`Error : ${error}`);
    }

    return null;
  }
}
