import { LOCALSTORAGE_AUTH_CLOUD_API_TOKEN } from '@utomik-app-monorepo/constants';
import {
  ApiApplicationVersion,
  ApiStagingTier,
  ConnectionQuality,
  GatewayWithWeight,
  IStreamView,
  NetworkTestConfig,
  QueueState,
  StreamState,
  StreamViewUtils,
} from '@utomik-app-monorepo/stream-view';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/api/flow';

import { Application } from '../../../dataStore/stores/applicationStore/application';
import { ApplicationStore } from '../../../dataStore/stores/applicationStore/applicationStore';
import { CountryByIp } from '../../../dataStore/stores/countryByIp/countryByIp';
import { ObjectID } from '../../../dataStore/stores/objectStore/asyncObject';
import { ControllerTypes } from '../keyboardController/interfaces';
import { PersistentStore } from '../persistentStore/persistentStore';
import { PlatformController } from '../platformController/platformController';
import { TokenManager } from '../tokenManager/tokenManager';
import { CustomGamepad } from './onScreenGamepad/CustomGamepad';

export class StreamViewUtilsService {
  private readonly _streamViewUtils: StreamViewUtils;
  private _tokenManager: TokenManager;
  private _sessionStore: PersistentStore;
  private _platformController: PlatformController;
  private _applicationStore: ApplicationStore;

  @observable
  private _streamViewRef: IStreamView;
  @observable
  private _currentGateway: GatewayWithWeight;
  @observable
  private _currentAppVersion: ApiApplicationVersion;
  @observable
  private _stagingTiers: ApiStagingTier[];
  @observable
  private _gateways: GatewayWithWeight[];
  @observable
  private _isPreFetched = false;
  @observable
  private _cloudToken: string;
  @observable
  private _connectionQuality: ConnectionQuality;
  @observable
  private _state: StreamState = 'Closed';
  @observable
  private _queueState: QueueState;
  @observable
  private _playSessionId: number;
  @observable
  private _lastPlaySessionId: number;
  @observable
  private _currentApp: Application;
  @observable
  private _lastPlaySessionTime: number;
  @observable
  private _onScreenGamepad: CustomGamepad;

  constructor(
    tokenManager: TokenManager,
    sessionStore: PersistentStore,
    applicationStore: ApplicationStore,
    platformController: PlatformController
  ) {
    this._tokenManager = tokenManager;
    this._sessionStore = sessionStore;
    this._platformController = platformController;
    this._applicationStore = applicationStore;
    this._streamViewUtils = new StreamViewUtils();

    makeObservable(this);
  }
  public get utils() {
    return this._streamViewUtils;
  }
  @action
  public setRef = (ref?: IStreamView) => {
    this._streamViewRef = ref;
  };
  @action
  public prefetch: (slugOrId: ObjectID) => Promise<void> = async (slugOrId) => {
    this._lastPlaySessionId = null;
    this._playSessionId = null;

    if (this.isPreFetched) return;

    this._currentApp = this._applicationStore.getItem(slugOrId);

    await this._currentApp.fetch();

    await this._applicationStore.userStatsStore.fetch();

    this._cloudToken = this._sessionStore.get<string>(LOCALSTORAGE_AUTH_CLOUD_API_TOKEN);

    this._stagingTiers = await this._getStagingTiers(slugOrId);

    this._gateways = await this._getWeightedGatewayList();

    this._currentAppVersion = await this._streamViewUtils.getRecommendedApplicationVersion(
      slugOrId,
      this._tokenManager.token.value.token
    );

    this._currentGateway = this._gateways[0];

    this._connectionQuality = 'excellent';
    this._isPreFetched = true;
  };
  public get streamViewRef(): IStreamView {
    return this._streamViewRef;
  }
  public get isPreFetched(): boolean {
    return this._isPreFetched;
  }
  public get gateways(): GatewayWithWeight[] {
    return this._gateways;
  }
  public get stagingTiers(): ApiStagingTier[] {
    return this._stagingTiers;
  }
  public get cloudToken(): string {
    return this._cloudToken;
  }

  private _getWeightedGatewayList: () => CancellablePromise<GatewayWithWeight[]> = flow(function* (
    this: StreamViewUtilsService
  ) {
    const authCloudApiToken = this._sessionStore.get<string>(LOCALSTORAGE_AUTH_CLOUD_API_TOKEN);
    return yield this._streamViewUtils.getWeightedGateways(authCloudApiToken, this._tokenManager.token.value.token);
  });

  private _getStagingTiers: (slugOrId: ObjectID) => CancellablePromise<ApiStagingTier[]> = flow(function* (
    this: StreamViewUtilsService,
    slugOrId
  ) {
    return yield this._streamViewUtils.getApplicationStagingtiers(this._tokenManager.token.value.token, slugOrId);
  });
  public startNetworkTest = async (precision: number = 1) => {
    this._streamViewUtils.networkTestController.resetData();

    const config: NetworkTestConfig = {
      precision: precision,
      device: null,
      gatewayUrl: null,
      sessionId: null,
      countryData: { countryName: null, countryCode: null },
    };

    const countryByIp = new CountryByIp();

    await countryByIp.fetch();

    config.countryData.countryCode = countryByIp.data.countryCode;
    config.countryData.countryName = countryByIp.data.countryName;
    config.device = this._platformController.isWeb ? 'WEB' : 'TV';
    config.precision = precision;

    if (this._state !== 'Initialized') {
      const authCloudApiToken = this._sessionStore.get<string>(LOCALSTORAGE_AUTH_CLOUD_API_TOKEN);
      const gateway = await this._streamViewUtils.getRecommendedGateway(
        authCloudApiToken,
        this._tokenManager.token.value.token
      );
      config.gatewayUrl = gateway.gateway.address;
    } else {
      config.gatewayUrl = this._currentGateway.gateway.address;
      config.sessionId = this._playSessionId;
    }

    await this._streamViewUtils.networkTestController.startTest({ ...config });
  };
  public resetNetworkTest = () => {
    this._streamViewUtils.networkTestController.resetData();
  };
  @action
  public setConnectionQuality = (quality: ConnectionQuality) => {
    this._connectionQuality = quality;
  };
  @action
  public setStreamState = (newState: StreamState, queueState?: QueueState, playsessionId?: number) => {
    this._state = newState;
    this._queueState = queueState;
    this._playSessionId = playsessionId;
    this._lastPlaySessionId = playsessionId;
  };
  public get connectionQuality() {
    return this._connectionQuality;
  }
  public get state() {
    return this._state;
  }

  public get queueState() {
    return this._queueState;
  }
  @action
  public setCurrentGateway = (gateway: GatewayWithWeight): void => {
    this._currentGateway = gateway;
  };
  @action
  public setCurrentAppVersion = (appVersion: ApiApplicationVersion): void => {
    this._currentAppVersion = appVersion;
  };
  public get currentAppVersion() {
    return this._currentAppVersion;
  }
  public get currentGateway() {
    return this._currentGateway;
  }
  @computed
  public get lastPlaySessionTime() {
    return this._lastPlaySessionTime;
  }
  @computed
  public get lastPlaySessionId() {
    return this._lastPlaySessionId;
  }
  @computed
  public get playSessionId() {
    return this._playSessionId;
  }
  @action
  public updateUserStats = async () => {
    if (!this._playSessionId) return;

    try {
      await this._applicationStore.userStatsStore.reFetch();

      this._lastPlaySessionTime =
        this._currentApp.userStat?.secondsPlayed - this._currentApp.initialPlayTimeSeconds || 0;
    } catch (e: any) {
      console.log('Send do_end request failed with error ' + e.message);
    }
  };
  @computed
  public get isAllowedKeyboard(): boolean {
    return (
      this._currentApp?.interface.includes(ControllerTypes.Keyboard) || !this._currentApp?.interface.length || false
    );
  }

  @action
  public setOnScreenGamepadVisible = (isVisible: boolean): void => {
    if (isVisible) {
      this._onScreenGamepad = new CustomGamepad();
      window.dispatchEvent(new CustomEvent('gamepadconnected', { detail: { gamepad: this._onScreenGamepad } }));
    } else {
      window.dispatchEvent(new CustomEvent('gamepaddisconnected', { detail: { gamepad: this._onScreenGamepad } }));
      this._onScreenGamepad = null;
    }
  };
  @computed
  public get onScreenGamepad() {
    return this._onScreenGamepad;
  }

  public get isOnScreenGamepadVisible(): boolean {
    return !!this._onScreenGamepad;
  }

  @action
  public dispose = () => {
    this._currentGateway = null;
    this._playSessionId = null;
    this._currentAppVersion = null;
    this._isPreFetched = false;
    this._connectionQuality = 'excellent';
    this._currentApp = null;
    this._queueState = null;
    this._gateways = null;
    this._stagingTiers = null;
    this._cloudToken = null;
    this._state = 'Closed';
    this._streamViewRef = null;
    this._onScreenGamepad = null;
  };
}
