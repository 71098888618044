import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './start-game-dialog.scss';
export const StartGameDialog = observer(({
  action
}) => {
  return <div className={'start-game-dialog__container'}>
      <p className={'start-game-dialog--title'}>We're all set for the start of the game</p>
      <FocusableButton onClick={() => action(DialogResult.OK)} focusable={false} type={'clickable'}>
        Let's play!
      </FocusableButton>
    </div>;
});
export default StartGameDialog;