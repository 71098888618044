import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './exit-app-dialog.scss';
export const ExitAppDialog = observer(withFocusable({
  blockNavigationOut: true
})<{
  action: (action: DialogResult, value?: unknown) => void;
}>(observer(function ExitAppDialog({
  action
}) {
  return <div className={'exit-app-modal'}>
          <h1 className={'title'}>
            <>
              {t({
          context: 'Exit App Dialog - Asks user to confirm app exit',
          message: 'Are you sure you want to exit?'
        })}
            </>
          </h1>
          <span className={'buttons-container'}>
            <FocusableButton type={'primary'} onEnterPress={action.bind(null, DialogResult.OK)}>
              {t({
          message: 'Yes'
        })}
            </FocusableButton>
            <FocusableButton type={'secondary'} onEnterPress={action.bind(null, DialogResult.Cancel)} autofocus>
              {t({
          message: 'No'
        })}
            </FocusableButton>
          </span>
        </div>;
})));
export default ExitAppDialog;