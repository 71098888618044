import { action, computed, makeObservable, observable } from 'mobx';
import { AppList } from '../../../dataStore/stores/applicationStore/appList';
import { ApiAppListEntry } from '../../../dataStore/stores/applicationStore/appListEntry';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';

/**
 * Provides App Tiles for the Carousel.
 */
export class AppTileProvider {
  @observable
  public slug: string;
  @observable
  public analyticOrigin: string[];
  @observable
  private _name: string;
  @observable
  protected _appList: AppList;
  @observable
  private _initialLoaded = false;
  @action
  private _setInitialLoaded(initialLoaded: boolean): void {
    this._initialLoaded = initialLoaded;
  }
  @observable
  private _updateBit = false;
  @observable
  private _withPlayTime = false;
  @computed
  public get name(): string {
    return this._name;
  }
  @action
  public setName(name: string): void {
    this._name = name;
  }
  @computed
  public get updateBit(): boolean {
    return this._updateBit;
  }
  @action
  public unload(): void {
    this._appList?.unload();
    this.flipBit();
  }

  /**
   * Touch flipBit so that a carousel will be able to redraw the items on demand.
   * This is mainly used for recentlyPlayedProvider.
   */
  @action
  public flipBit(): void {
    this._updateBit = !this._updateBit;
  }
  public get expandable(): boolean {
    return true;
  }
  @computed
  public get state(): AsyncObjectState {
    return this._appList?.state || AsyncObjectState.None;
  }
  @computed
  public get length(): number {
    return this._appList?.length || 0;
  }
  @computed
  public get confirmedEmpty(): boolean {
    return !this._appList?.length && (this._appList?.state === AsyncObjectState.Done || this._appList?.state === AsyncObjectState.Error);
  }
  @action
  public setAppList(appList: AppList): void {
    this._appList = appList;
    /**
     * Experimental, encourage carousels to rerender when AppList has changed.
     */
    this.flipBit();
  }
  @computed
  public get appList(): AppList {
    return this._appList;
  }

  /**
   * This just initializes the app with the provided parameters. You can see what they do in the initialize function.
   *
   * @param slug
   * @param name
   * @param analyticOrigin
   * @param appList
   */
  public constructor(slug: string, name: string, analyticOrigin: string[], appList: AppList = null, withPlayTime = false) {
    makeObservable(this);
    this.initialize(slug, name, analyticOrigin, appList, withPlayTime);
  }

  /**
   * Initialize the app-tile-provider.
   * @param slug - Unique name for the channel.
   * @param analyticOrigin Where the tile provider is located (used for analytics).
   * @param name - Name of the channel.
   * @param appList - The list of applications.
   */
  @action
  private initialize(slug: string, name: string, analyticOrigin: string[], appList: AppList, withPlayTime: boolean): void {
    this.slug = slug;
    this.analyticOrigin = analyticOrigin;
    this._name = name;
    this.setAppList(appList);
    this._withPlayTime = withPlayTime;
  }
  public getItemsList(fromIdx = 0, count = 0): ApiAppListEntry[] {
    return this._appList.itemSet?.slice(fromIdx, fromIdx + count);
  }
}