import { Routes } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { ChannelsControllerContext, DialogFactoryContext, DialogQueueContext, HomeControllerContext, KeyboardControllerContext, LoginControllerContext, NavigationControllerContext, PlatformControllerContext, SearchResultStoreContext, SettingsViewControllerContext, StreamViewUtilsContext, TokenManagerContext, TvSidebarControllerContext } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import { LoginLocation } from '@utomik-app-monorepo/store';
import { dispatchBackEvent, isNullOrUndefined } from '@utomik-app-monorepo/utils';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
export const useBackButton = () => {
  const sidebarController = React.useContext(TvSidebarControllerContext);
  const loginController = React.useContext(LoginControllerContext);
  const dialogQueue = React.useContext(DialogQueueContext);
  const dialogFactory = React.useContext(DialogFactoryContext);
  const tokenManager = React.useContext(TokenManagerContext);
  const homeController = React.useContext(HomeControllerContext);
  const channelsController = React.useContext(ChannelsControllerContext);
  const streamViewUtilsService = React.useContext(StreamViewUtilsContext);
  const platformController = React.useContext(PlatformControllerContext);
  const keyboardController = React.useContext(KeyboardControllerContext);
  const searchResultStore = React.useContext(SearchResultStoreContext);
  const settingsViewController = React.useContext(SettingsViewControllerContext);
  const navigationController = React.useContext(NavigationControllerContext);
  const location = useLocation();
  const navigate = useNavigate();
  const splitPath = location.pathname.split('/');
  let isMainPage = splitPath.length <= 2;
  if (platformController.isWeb) {
    //for web app search,profile, news is not the main page
    isMainPage = splitPath.length <= 2 && location.pathname !== Routes.Search && location.pathname !== Routes.Profile && location.pathname !== Routes.News;
  }
  const handleExitStream = async isRemoteControl => {
    if (location.pathname.startsWith(Routes.GameRun) && !dialogQueue.hasDialogs) {
      if (!isRemoteControl) {
        throw new Error('Not a remote control back button pressed');
      }
      const gameId = splitPath[2];
      const result = await dialogFactory.showCloseStreamDialog(Number(gameId));
      if (result === DialogResult.OK) {
        await streamViewUtilsService.streamViewRef?.disposeStream();
        throw new Error('Dialog result OK');
      }
      if (result === DialogResult.Cancel) {
        dialogQueue.currentDialog?.action(DialogResult.Cancel);
        throw new Error('Dialog result cancel');
      }
    }
  };
  const handleOpenedDialogs = () => {
    if (dialogQueue.hasDialogs) {
      if (!dialogQueue.currentDialog?.isClosable) {
        throw new Error('Dialog is not closable');
      }
      dialogQueue.currentDialog.action(DialogResult.Cancel);
      throw new Error('Dialog result cancel');
    }
  };
  const handleOpenedKeyboard = () => {
    if (keyboardController.isKeyboardVisible) {
      keyboardController.setIsKeyboardVisible(false);
      throw new Error('Keyboard has been closed');
    }
  };
  const handleSettingsSubpageOpened = () => {
    if (!isNullOrUndefined(settingsViewController.activeTab) && settingsViewController.isMobileView) {
      settingsViewController.reset();
      throw new Error('Subpage has been closed');
    }
  };
  const handleSeeAll = () => {
    const isOpenSeeAll = !!homeController.currentProvider || !!channelsController.currentProvider;
    if (isMainPage && !isOpenSeeAll) {
      sidebarController.setIsExpanded(true);
    } else if (isMainPage && isOpenSeeAll) {
      //close SeeAll if it is opened
      homeController.setCurrentProvider(null);
      channelsController.setCurrentProvider(null);
    } else {
      //clear search on web app
      if (location.pathname === Routes.Search && platformController.isWeb) {
        searchResultStore.clear();
      }
      //return back
      if (platformController.isWeb) {
        if (navigationController.history.length > 2) {
          navigate(-1);
        } else {
          navigate(Routes.Home);
        }
      } else {
        if (window.history.length > 2) {
          navigate(-1);
        } else {
          navigate(Routes.Home);
        }
      }
    }
  };
  const handleAuthViews = () => {
    if (!tokenManager.isAuthorized && loginController.location !== LoginLocation.Authorization) {
      loginController.setLocation(-1);
      throw new Error('Location back');
    }
  };
  const handleExitApp = async () => {
    const result = await dialogFactory.showExitAppDialog();
    switch (result) {
      case DialogResult.Cancel:
        // sidebarController.setIsExpanded(true);
        // throw new Error('Expand the sidebar');
        break;
      case DialogResult.OK:
        if (platformController.isTizen) {
          window.tizen.application.getCurrentApplication().exit();
          throw new Error('Close Tizen app');
        } else if (platformController.isWebOS || platformController.isVidaa) {
          window.close();
          throw new Error('Close webOS/Hisense app');
        } else if (platformController.isAndroidTV) {
          navigator.app.exitApp();
          throw new Error('Close AndroidTV app');
        }
    }
  };
  const backHandler = async (isRemoteControl: boolean) => {
    dispatchBackEvent();
    try {
      //if keyboard is still opened -> close it
      handleOpenedKeyboard();
      //stream exit dialog
      await handleExitStream(isRemoteControl);
      //if any dialog is still opened -> close it
      handleOpenedDialogs();
      //if the settings subpage is opened -> close it
      handleSettingsSubpageOpened();
      //sidebar is not expanded and user is authorized
      if (!sidebarController.isExpanded && tokenManager.isAuthorized) {
        handleSeeAll();
      } else {
        //close sidebar if it is still opened
        sidebarController.setIsExpanded(false);
        // authorization views
        handleAuthViews();
        //show exit app dialog and react on the result
        if (!platformController.isWeb) {
          await handleExitApp();
        }
      }
    } catch (e: any) {
      log(e.message);
      return;
    }
  };
  useEffect(() => {
    const keyHandler = e => {
      //if (keyboardController.isKeyboardVisible) return;

      if (platformController.isAndroidTV || e.keyName === 'back' || e.key === 'GoBack' || e.key === 'Backspace' && !platformController.isWeb) {
        backHandler(true);
      }
    };
    if (platformController.isTizen) {
      window.addEventListener('tizenhwkey', keyHandler);
    } else if (platformController.isAndroidTV) {
      document.addEventListener('backbutton', keyHandler, false);
    } else {
      window.addEventListener('keydown', keyHandler);
    }
    return () => {
      window.removeEventListener('tizenhwkey', keyHandler);
      document.removeEventListener('backbutton', keyHandler);
      window.removeEventListener('keydown', keyHandler);
    };
  }, [isMainPage, location, keyboardController.isKeyboardVisible]);
  return [backHandler];
};