import axios from 'axios';
import { action, makeObservable, observable } from 'mobx';

import { ClientController } from '../../../app/global/clientController/clientController';
import { PlatformController } from '../../../app/global/platformController/platformController';
import { RequestMethod, RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { IssueType, IssueTypes } from '../issueTypes/issueTypes';
import { AsyncObjectState } from '../objectStore/asyncObject';
import { IAttachmentResponse } from './interfaces';

export abstract class LogsStore {
  private readonly _requestQueue: RequestQueue;
  private readonly _platformController: PlatformController;
  private readonly _clientController: ClientController;
  private _issueTypes: IssueTypes;
  @observable
  private _currentIssueType: IssueType;

  @observable
  private _state = AsyncObjectState.None;

  protected constructor(
    requestQueue: RequestQueue,
    platformController: PlatformController,
    issueTypes: IssueTypes,
    clientController: ClientController
  ) {
    this._requestQueue = requestQueue;
    this._platformController = platformController;
    this._issueTypes = issueTypes;
    this._clientController = clientController;

    makeObservable(this);
  }

  public get state() {
    return this._state;
  }

  public set state(state) {
    this._state = state;
  }

  public initialize = async () => {
    await this.createLogsFile();
  };

  protected async sendLogFiles(fileStrings: string[]): Promise<void> {
    //send filenames to S3
    const promisesSendFileNames = fileStrings.map(
      (_, idx) =>
        this._requestQueue.add<IAttachmentResponse>(
          '/v1/support/customer/attachments',
          { filename: `logfile_${idx + 1}.txt` },
          RequestPriority.Medium,
          RequestMethod.Post,
          null,
          null,
          true
        ).promise
    );

    try {
      const responsesSendFileNames = await Promise.all(promisesSendFileNames);
      //send files to S3
      const promisesSendFiles = responsesSendFileNames.map(({ data }, idx) => {
        const url = data.upload_action.config.url;

        delete data.upload_action.config.url;
        console.log(fileStrings[idx]);
        const blob = new Blob([fileStrings[idx]], { type: 'text/plain' });

        const formData = new FormData();

        Object.entries(data.upload_action.config).forEach(([key, value]) => {
          formData.append(key, value);
        });

        formData.append('file', blob, data.fileName);

        return axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      });

      await Promise.all(promisesSendFiles);

      const fileIds = responsesSendFileNames.map((res) => res.data.id);

      await this._requestQueue.add(
        '/v1/support/customer/tickets',
        {
          subject: '[TV Support] Bug report for ' + this._clientController.user.userName,
          description:
            'TV bug report. Please reply to this email and send us your question or bug report, and our support team will contact you shortly. Thank you!',
          attachments: fileIds,
          tags: this._platformController.currentPlatform,
          issuetype: this._currentIssueType.value,
        },
        RequestPriority.Medium,
        RequestMethod.Post,
        null,
        null,
        true
      ).promise;
    } catch (e) {
      console.log(e);
      this.state = AsyncObjectState.Error;
    }
  }

  public get issueTypes() {
    return this._issueTypes.items.map((item) => ({ name: item.name }));
  }

  public get currentIssueType() {
    return this._currentIssueType;
  }
  @action
  public setCurrentIssueType(name: string) {
    if (!name) {
      return (this._currentIssueType = null);
    }
    this._currentIssueType = this._issueTypes.items.find((item) => item.name === name);
  }

  protected abstract createLogsFile(): Promise<boolean>;

  abstract addLogToFile(log: string): void;

  abstract deleteLogsFolder(): void;

  abstract deleteLogFile(name: string): void;

  abstract dispose(): void;
}
