import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { observer } from 'mobx-react';
import React, { CSSProperties } from 'react';
import { FocusableButton } from '../focusable-button/focusable-button';
import './toggle-button.scss';

/**
 * Toggle button that renders as many buttons as many children you pass to it
 * */

type Props = {
  activeIdx?: number;
  setActiveIdx?: (idx: number) => void;
  buttonsChildren: (React.ReactNode | string)[];
  focusKey?: string;
  onBecameFocused?: (props: any, itemProps?: any) => void;
  style?: CSSProperties;
};
export const ToggleButton = withFocusable()<Props>(observer(function ToggleButton({
  activeIdx,
  setActiveIdx,
  buttonsChildren,
  style
}) {
  return <div className={'toggle-button__wrapper'} style={style}>
        {buttonsChildren.map((item, idx) => <FocusableButton key={idx} isActive={activeIdx === idx} type={'secondary'} onEnterPress={() => setActiveIdx(idx)}>
            {item}
          </FocusableButton>)}
      </div>;
}));