import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton, SingleNews } from '@utomik-app-monorepo/components';
import { Routes } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, AnalyticItemTypes, AsyncObjectState, NavigationContext, NavigationControllerContext, NewsItemStoreContext } from '@utomik-app-monorepo/store';
import { joinLocation, sizeRemToPx } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './news.module.scss';
export const News = withFocusable()(observer(({
  setFocus
}) => {
  const {
    backHandler
  } = useContext(NavigationContext);
  const navigate = useNavigate();
  const navigationController = useContext(NavigationControllerContext);
  const analyticController = useContext(AnalyticControllerContext);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const newsItemStore = useContext(NewsItemStoreContext);
  const items = newsItemStore.state !== AsyncObjectState.Done ? Array.from<null>({
    length: 30
  }) : newsItemStore.items;
  useEffect(() => {
    if (newsItemStore.state !== AsyncObjectState.Done) {
      newsItemStore.fetch();
    }
  }, []);
  useEffect(() => {
    if (newsItemStore.state === AsyncObjectState.Done) {
      setFocus(newsItemStore.pageScrollState?.currentFocusKey);
    }
  }, [newsItemStore.state, newsItemStore.pageScrollState?.currentFocusKey]);
  const handleFocusItem = props => {
    scrollableContainerRef.current?.scroll({
      behavior: 'smooth',
      top: props.y - sizeRemToPx(7)
    });
  };
  const handleEnterPress = props => {
    if (!props.news) return;
    props.news.setRead(true);
    newsItemStore.pageScrollState.currentFocusKey = props.focusKey;
    const breadcrumbs = analyticController.getBreadCrumbs({
      isGrid: true
    });
    analyticController.navigate({
      item_name: props.news?.id,
      location_index: String(props.idx),
      item_type: AnalyticItemTypes.News,
      location_on_page: joinLocation(breadcrumbs)
    });
    navigate(`${Routes.News}/${props.news.id}`);
  };
  return <div className={styles.container}>
        <div className={styles.backTitleContainer}>
          <FocusableButton onEnterPress={backHandler} type={'back'} className={styles.backButton} />
          <p className={styles.pageTitle}>{t({
          message: 'News'
        })}</p>
        </div>
        <div className={styles.contentContainer} ref={scrollableContainerRef}>
          {items.map((item, idx) => <SingleNews idx={idx} key={`news-${idx}`} hasMouseOver={false} focusKey={`news-${idx}`} showOutline={navigationController.isGamepadInteraction} onEnterPress={handleEnterPress} onBecameFocused={handleFocusItem} layout={'vertical'} news={item} />)}
        </div>
      </div>;
}));