import { computed, makeObservable, observable, runInAction } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { BaseObject, ObjectStore } from '../objectStore/objectStore';
import { GlobalStatisticValue, GlobalStatisticValueStore } from './globalStatisticValueStore';

export interface ApiGlobalStatistic {
  id: ObjectID;
  api_name: string;
  name: string;
  default_value: number;
}

export class GlobalStatistic implements BaseObject {
  private readonly _globalStatisticValueStore: GlobalStatisticValueStore;
  @observable
  private _apiObj: ApiGlobalStatistic;

  public constructor(globalStatisticValueStore: GlobalStatisticValueStore, apiObj: ApiGlobalStatistic) {
    runInAction(() => {
      this._apiObj = apiObj;
    });
    this._globalStatisticValueStore = globalStatisticValueStore;
    makeObservable(this);
  }

  @computed get _globalStatisticValue(): GlobalStatisticValue {
    return this._globalStatisticValueStore.items.find((x) => x.id === this.id);
  }

  @computed
  public get apiName(): string {
    return this._apiObj.api_name;
  }

  @computed
  public get name(): string {
    return this._apiObj.name;
  }

  @computed
  public get defaultValue(): number {
    return this._apiObj.default_value;
  }

  @computed
  public get value(): number {
    return this._globalStatisticValue?.value ?? this.defaultValue;
  }

  @computed
  public get id(): ObjectID {
    return this._apiObj.id;
  }
}

export class GlobalStatisticStore extends ObjectStore<GlobalStatistic, ApiGlobalStatistic> {
  private readonly _globalStatisticValueStore: GlobalStatisticValueStore;

  public constructor(
    requestQueue: RequestQueue,
    globalStatisticValueStore: GlobalStatisticValueStore,
    url = 'v2/global/statistics',
    retries = null
  ) {
    super(requestQueue, url, retries);
    this._globalStatisticValueStore = globalStatisticValueStore;
  }
  protected createAndLoadItem(x: ApiGlobalStatistic): GlobalStatistic {
    return new GlobalStatistic(this._globalStatisticValueStore, x);
  }
}
