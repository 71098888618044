import * as React from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { ApplicationMedia } from '@utomik-app-monorepo/store';
import { dispatchEnterEvent, pathTv } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import './media-tile-item.scss';

/**
 * Component renders tiles. Tiles are focusable with default image if no image for the tile.
 * */

type Props = {
  index?: number;
  item: ApplicationMedia;
  style?: {
    [key: string]: string;
  };
  hasMouseOver?: boolean;
  showOutline?: boolean;
  scaleOnFocus?: boolean;
};
export const MediaTileItem = withFocusable()<Props>(observer(function MediaTileItem({
  item,
  focused,
  setFocus,
  style,
  hasMouseOver,
  showOutline = false,
  scaleOnFocus = true
}) {
  const handleClick = () => {
    setFocus();
    dispatchEnterEvent();
  };
  const handleMouseOver = () => {
    if (hasMouseOver) {
      setFocus();
    }
  };
  return <div style={style} className={'media-tile__container'}>
        <div className={cx('media-tile-item', {
      'media-tile-item--focus': focused && scaleOnFocus,
      'media-tile-item--outline': focused && showOutline
    })} onClick={handleClick} onMouseOver={handleMouseOver}>
          {item.tag === 'SS' ? <img decoding={'sync'} src={item?.url} /> : <div className={'video-item-container'}>
              <img decoding={'sync'} src={pathTv + require('images/svg/logo.svg')} />
            </div>}
        </div>
      </div>;
}));