import { Routes } from '@utomik-app-monorepo/constants';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { MainMenu } from '../../components/main-menu/main-menu';
import { TopBar } from '../../components/top-bar/top-bar';
import styles from './wrapper.module.scss';
const routesHideMainMenu = [Routes.Search, `${Routes.Channels}/`, Routes.Profile, Routes.News];
export const WithBars: React.FC<React.PropsWithChildren> = observer(() => {
  const [isDesktopAndHide, setIsDesktopAndHide] = useState(true);
  const location = useLocation();
  const isChannel = location.pathname.startsWith(`${Routes.Channels}/`);
  const isProfile = location.pathname.startsWith(`${Routes.Profile}`);
  const isNews = location.pathname.startsWith(`${Routes.News}`);
  useEffect(() => {
    const allowed = routesHideMainMenu.find(r => location.pathname.startsWith(r));
    const handleResize = () => {
      const isDesktop = window.innerWidth > 1400;
      setIsDesktopAndHide(allowed && !isDesktop);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);
  return <div className={styles.container}>
      <TopBar hideProfileNewsSection={!(isChannel || isProfile || isNews || !isDesktopAndHide)} showBackButton={!(isChannel || isProfile || isNews || !isDesktopAndHide)} />
      <main className={cx(styles.content, {
      [styles.contentNoPadding]: isDesktopAndHide
    })}>
        <Outlet />
      </main>
      {!isDesktopAndHide && <MainMenu />}
    </div>;
});