import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { DialogResult } from '@utomik-app-monorepo/store';
import { pathTv } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './allowed-keyboard-dialog.scss';
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
const svg = <svg width="70" height="70" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0 -1052.5)" fill="none" stroke="#000">
      <circle cx="35" cy="1087.5" r="32.5" strokeWidth="5" />
      <g strokeWidth="2.5">
        <path d="m17.5 1087.5h35" />
        <path d="m17.5 1077.5h35" />
        <path d="m17.5 1097.5h35" />
      </g>
    </g>
  </svg>;
export const AllowedKeyboardDialog = withFocusable()<Props>(observer(function AllowedKeyboardDialog({
  action
}) {
  return <div className={'allowed-keyboard-dialog__wrapper'}>
        <div className={'titles__container'}>
          <h1>Text input is available for this game</h1>
          <div className={'menu-button-svg'}>{svg}</div>
          <img decoding={'sync'} src={pathTv + require('images/svg/black-gamepad.svg')} />
          <h2>To use: hold the menu button, and select the keyboard from the stream menu</h2>
        </div>
        <FocusableButton className={'button-ok'} onEnterPress={action.bind(null, DialogResult.OK)} focusable={true} type={'primary'} autofocus>
          {t({
        message: 'OK'
      })}
        </FocusableButton>
      </div>;
}));
export default AllowedKeyboardDialog;