import { DndContext, PointerSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton, FocusableSelect, LicensesTab, Stepper } from '@utomik-app-monorepo/components';
import { privacyPolicyUrl, supportUrl } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, ClientControllerContext, DialogFactoryContext, DialogQueueContext, LanguagesStoreContext, NavigationContext, NavigationControllerContext, SettingsViewControllerContext, StreamingFeedbackControllerContext } from '@utomik-app-monorepo/store';
import { Steps } from '@utomik-app-monorepo/store';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './settings.module.scss';
type ButtonsProps = {
  step: Steps;
  setStep: (step: Steps) => void;
  isNetworkTestAllowed?: boolean;
  isDebuggingAllowed?: boolean;
  showOutline?: boolean;
};
const DraggableLanguagesList = withFocusable({
  trackChildren: true
})(({
  pauseSpatialNavigation,
  resumeSpatialNavigation,
  hasFocusedChild,
  setFocus
}) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const {
    items
  } = useContext(LanguagesStoreContext);
  const [isMoving, setIsMoving] = useState(false);
  const [currentId, setCurrentId] = useState<number>(0);
  const [languages, setLanguages] = useState(items.map(item => ({
    id: item.code,
    name: item.name
  })));
  const sensors = useSensors(useSensor(TouchSensor, {
    activationConstraint: {
      distance: 5 // Starts dragging after moving 5 pixels
    }
  }), useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5 // Starts dragging after moving 5 pixels
    }
  }));
  const handleDragEnd = event => {
    const {
      active,
      over
    } = event;
    if (active.id !== over.id) {
      setLanguages(items => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };
  const handleFocus = (props, itemProps) => {
    scrollableContainerRef.current.scroll({
      top: props.y - scrollableContainerRef.current.clientHeight / 2,
      behavior: 'smooth'
    });
    setCurrentId(itemProps.id);
  };
  useEffect(() => {
    return () => resumeSpatialNavigation();
  }, []);
  useEffect(() => {
    if (!hasFocusedChild) {
      resumeSpatialNavigation();
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Enter' || !hasFocusedChild) return;
      setIsMoving(prev => {
        if (prev) {
          resumeSpatialNavigation();
        }
        return !prev;
      });
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [hasFocusedChild]);
  useEffect(() => {
    const handleUp = () => {
      if (isMoving) {
        const currentIndex = currentId - 1;
        if (currentIndex - 1 < 0) return;
        setLanguages(items => {
          const oldIndex = items.findIndex((_, idx) => idx === currentIndex);
          const newIndex = items.findIndex((_, idx) => idx === currentIndex - 1);
          return arrayMove(items, oldIndex, newIndex);
        });
        setCurrentId(prev => prev - 1);
      }
    };
    const handleDown = () => {
      if (isMoving) {
        const currentIndex = currentId - 1;
        if (currentIndex + 1 > languages.length) return;
        setLanguages(items => {
          const oldIndex = items.findIndex((_, idx) => idx === currentIndex);
          const newIndex = items.findIndex((_, idx) => idx === currentIndex + 1);
          return arrayMove(items, oldIndex, newIndex);
        });
        setCurrentId(prev => prev + 1);
      }
    };
    window.addEventListener('gamepad-up', handleUp);
    window.addEventListener('gamepad-down', handleDown);
    return () => {
      window.removeEventListener('gamepad-up', handleUp);
      window.removeEventListener('gamepad-down', handleDown);
    };
  }, [isMoving, currentId, languages]);
  return <div className={styles.settingColumn}>
        <p className={styles.description}>
          <Trans>
            A prioritized list of languages that game content information can be shown in. Game content information will
            be shown in the first available language in this list. Drag them in your preferred order.
          </Trans>
        </p>
        <div ref={scrollableContainerRef} style={{
      overflowY: 'scroll',
      maxHeight: '40vh',
      padding: '0 1rem',
      touchAction: 'none'
    }}>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={languages} strategy={verticalListSortingStrategy}>
              {languages.map((language, index) => <SortableItem isGrabbed={isMoving} onEnterPress={() => pauseSpatialNavigation()} onBecameFocused={handleFocus} key={language.id} item={language} id={index + 1} />)}
            </SortableContext>
          </DndContext>
        </div>
        <FocusableButton style={{
      width: '25rem',
      alignSelf: 'end',
      marginTop: '3rem'
    }} type={'primary'}>
          <Trans>Save and reload</Trans>
        </FocusableButton>
      </div>;
});
const SortableItem = withFocusable()<{
  item: {
    id: string;
    name: string;
  };
  id: number;
  isGrabbed: boolean;
}>(({
  item,
  id,
  focused,
  isGrabbed
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: item.id
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 'auto',
    cursor: 'grab',
    opacity: isDragging ? 0.8 : 1,
    touchAction: 'manipulation'
  };
  return <div ref={setNodeRef} style={style} className={cx(styles.columnItem, {
    [styles.columnItemFocused]: focused,
    [styles.columnItemActive]: isGrabbed && focused
  })} {...attributes} {...listeners}>
        <FontAwesomeIcon width={'5rem'} icon="arrows-up-down" />
        <p>
          {id}. {item.name}
        </p>
      </div>;
});
const Buttons = withFocusable()<ButtonsProps>(observer(function Buttons({
  step,
  setStep,
  isNetworkTestAllowed,
  isDebuggingAllowed,
  showOutline
}) {
  return <div className={styles.tabButtonsContainer}>
        <FocusableButton showOutline={showOutline} className={styles.tabButton} icon={<FontAwesomeIcon width={'2.5rem'} icon={'gears'} />} onEnterPress={() => setStep(Steps.General)} type={'tab-button-rect'} isActive={step === Steps.General} absoluteElement={<FontAwesomeIcon className={styles.chevron} width={'2.5rem'} icon={'circle-chevron-right'} />}>
          <Trans context={'Tab - Settings page'}>General</Trans>
        </FocusableButton>
        {isNetworkTestAllowed && <FocusableButton showOutline={showOutline} className={styles.tabButton} icon={<FontAwesomeIcon width={'2.5rem'} icon={'wifi'} />} absoluteElement={<FontAwesomeIcon className={styles.chevron} width={'2.5rem'} icon={'circle-chevron-right'} />} focusKey={'button-to-focus'} onEnterPress={() => setStep(Steps.Network)} type={'tab-button-rect'} isActive={step === Steps.Network}>
            <Trans context={'Tab - Settings page'}>Network</Trans>
          </FocusableButton>}
        <FocusableButton showOutline={showOutline} className={styles.tabButton} icon={<FontAwesomeIcon width={'2.5rem'} icon="gamepad" />} absoluteElement={<FontAwesomeIcon className={styles.chevron} width={'2.5rem'} icon={'circle-chevron-right'} />} onEnterPress={() => setStep(Steps.Streaming)} type={'tab-button-rect'} isActive={step === Steps.Streaming}>
          <Trans context={'Tab - Settings page'}>Streaming</Trans>
        </FocusableButton>
        <FocusableButton showOutline={showOutline} className={styles.tabButton} icon={<FontAwesomeIcon width={'2.5rem'} icon="circle-question" />} absoluteElement={<FontAwesomeIcon className={styles.chevron} width={'2.5rem'} icon={'circle-chevron-right'} />} onEnterPress={() => setStep(Steps.Support)} type={'tab-button-rect'} isActive={step === Steps.Support}>
          <Trans context={'Tab - Settings page'}>Support</Trans>
        </FocusableButton>
        <FocusableButton showOutline={showOutline} className={styles.tabButton} icon={<FontAwesomeIcon width={'2.5rem'} icon="file-lines" />} absoluteElement={<FontAwesomeIcon className={styles.chevron} width={'2.5rem'} icon={'circle-chevron-right'} />} onEnterPress={() => setStep(Steps.PrivacyPolicy)} type={'tab-button-rect'} isActive={step === Steps.PrivacyPolicy}>
          <Trans context={'Tab - Settings page'}>Privacy policy</Trans>
        </FocusableButton>
        <FocusableButton showOutline={showOutline} className={styles.tabButton} icon={<FontAwesomeIcon width={'2.5rem'} icon="closed-captioning" />} absoluteElement={<FontAwesomeIcon className={styles.chevron} width={'2.5rem'} icon={'circle-chevron-right'} />} onEnterPress={() => setStep(Steps.Licenses)} type={'tab-button-rect'} isActive={step === Steps.Licenses}>
          <Trans context={'Tab - Settings page'}>Licenses</Trans>
        </FocusableButton>
        {isDebuggingAllowed && <FocusableButton showOutline={showOutline} className={styles.tabButton} icon={<FontAwesomeIcon width={'2.5rem'} icon="screwdriver-wrench" />} absoluteElement={<FontAwesomeIcon className={styles.chevron} width={'2.5rem'} icon={'circle-chevron-right'} />} onEnterPress={() => setStep(Steps.Debug)} type={'tab-button-rect'} isActive={step === Steps.Debug}>
            <Trans context={'Tab - Settings page'}>Debug</Trans>
          </FocusableButton>}
      </div>;
}));
const Content = withFocusable()<{
  isMobile: boolean;
}>(observer(({
  isMobile,
  setFocus
}) => {
  const settingsViewController = useContext(SettingsViewControllerContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const dialogQueue = useContext(DialogQueueContext);
  const analyticController = useContext(AnalyticControllerContext);
  const {
    backHandler
  } = useContext(NavigationContext);
  const clientController = useContext(ClientControllerContext);
  const streamingFeedbackController = useContext(StreamingFeedbackControllerContext);
  const {
    i18n
  } = useLingui();
  useEffect(() => {
    if (!dialogQueue.hasDialogs) {
      setFocus();
    }
  }, [dialogQueue.hasDialogs]);
  const handleResetAllDontShowAgain = () => {
    streamingFeedbackController.setDontShowAgain({
      value: false
    });
  };
  const handleOpenExternalUrl = (url: string) => {
    analyticController.clickExternalLink({
      url,
      location_on_page: 'Settings'
    });
    window.open(url, '_blank');
  };
  const showContentAsModal = isMobile && !isNullOrUndefined(settingsViewController.activeTab);
  return <div className={cx(styles.contentContainer, {
    [styles.contentContainerAsDialog]: showContentAsModal
  })}>
        {showContentAsModal && <div className={styles.backTitleContainer}>
            <FocusableButton autofocus onEnterPress={backHandler} className={styles.backButton} type={'back'} />
            <p className={styles.tabTitle}>
              <Trans context={'Name of the page'}>Settings</Trans>
            </p>
          </div>}
        <Stepper steps={[<div key={Steps.General}>
              <div className={styles.streamingContainer}>
                <div className={styles.settingRow}>
                  <Trans context={'Settings page - Current selected language for the app'}>
                    Utomik Client Language
                  </Trans>
                  <FocusableSelect openDown width={'narrow'} scrollType={'native'} values={clientController.locales} selectedValue={clientController.getLocaleById(i18n.locale).name} onChange={({
            id
          }) => clientController.setLocale(String(id))} />
                </div>
                <div className={styles.settingRow}>
                  <Trans context={'Settings page - Preferred languages list for the API'}>
                    Preferred Content Languages
                  </Trans>
                  <FocusableButton type={'secondary'}>
                    <Trans>Manage languages</Trans>
                  </FocusableButton>
                </div>
                <DraggableLanguagesList />
              </div>
            </div>, <div key={Steps.Network} className={styles.networkTestContainer}>
              <img decoding={'async'} className={styles.speedometerImg} src={require('images/img/speedometer.png')} />
              <h1 className={styles.networkTestTitle}>
                <Trans context={'Call to action - Start a network test'}>Test your connection</Trans>
              </h1>
              <p className={styles.networkTestDescription}>
                <Trans context={'Network test explanation'}>
                  To ensure a smooth streaming experience, it's important to test your internet connection. Check your
                  bandwidth, ping, and packet loss
                </Trans>
              </p>
              <FocusableButton className={styles.networkTestButton} onEnterPress={() => dialogFactory.showCheckConnectionDialog()} type={'primary'}>
                <Trans context={'Button - Start network test'}>Test now</Trans>
              </FocusableButton>
            </div>, <div key={Steps.Streaming} className={styles.streamingContainer}>
              <div className={styles.settingRow}>
                <span>
                  <Trans context={'Settings page - Should use small connection warnings (options: "after 5 times", "Always")'}>
                    Use small connection warnings
                  </Trans>
                </span>
                <FocusableButton type={'primary'} onEnterPress={clientController.switchNetworkNotificationMinimized}>
                  {clientController.isNetworkNotificationMinimized ? <Trans context={'Option for Settings page - Should use small connection warnings'}>Always</Trans> : <Trans context={'Option for Settings page - Should use small connection warnings'}>
                      After 5 times
                    </Trans>}
                </FocusableButton>
              </div>
              <div className={styles.settingRow}>
                <span>
                  <Trans context={'Test - Reset all "Do not show again", resets all "Do not show again" checkboxes if they were changes'}>
                    Reset all "Do not show again"
                  </Trans>
                </span>
                <FocusableButton isLoading={streamingFeedbackController.isLoading} type={'primary'} onEnterPress={handleResetAllDontShowAgain}>
                  <Trans context={'Button - Reset all "Do not show again"'}>Reset</Trans>
                </FocusableButton>
              </div>
            </div>, <div className={styles.centeredContainer}>
              <FocusableButton onEnterPress={handleOpenExternalUrl.bind(null, `https://${supportUrl}`)} type={'primary'}>
                <Trans context={'Button - "Open support"'}>Open Utomik Support</Trans>
              </FocusableButton>
            </div>, <div className={styles.centeredContainer}>
              <FocusableButton onEnterPress={handleOpenExternalUrl.bind(null, `https://${privacyPolicyUrl}`)} type={'primary'}>
                <Trans context={'Button - Open privacy policy'}>Open Privacy Policy</Trans>
              </FocusableButton>
            </div>, <div key={Steps.Licenses} className={styles.licensesContainer}>
              <LicensesTab focusablePartials={false} layout={'vertical'} />
            </div>, <div className={styles.centeredContainer} key={Steps.Debug}>
              <FocusableButton onEnterPress={() => ''} type={'primary'}>
                {/*{true ? t('SETTINGS_DEBUG_DISABLE_STREAM_CHARTS') : t('SETTINGS_DEBUG_ENABLE_STREAM_CHARTS')}*/}
              </FocusableButton>
            </div>]} currentStep={settingsViewController.activeTab} />
      </div>;
}));
export const Settings = withFocusable()(observer(({
  setFocus
}) => {
  const clientController = useContext(ClientControllerContext);
  const settingsViewController = useContext(SettingsViewControllerContext);
  const navigationController = useContext(NavigationControllerContext);
  const isNetworkTestAllowed = clientController?.user?.hasSubscription && clientController?.user?.isCloudUser;
  const isDebuggingAllowed = clientController?.user?.isAdmin || clientController?.user?.isCloudAdmin;
  const {
    i18n: {
      t
    }
  } = useLingui();
  useEffect(() => {
    if (!settingsViewController.isMobileView) {
      settingsViewController.setActiveTab(Steps.General);
    } else {
      settingsViewController.setActiveTab(null);
    }
  }, [settingsViewController.isMobileView, isNetworkTestAllowed]);
  useEffect(() => {
    if (settingsViewController.isMobileView && isNullOrUndefined(settingsViewController.activeTab)) {
      setFocus();
    }
  }, [settingsViewController.activeTab]);
  useEffect(() => {
    settingsViewController.init();
    return () => settingsViewController.dispose();
  }, []);
  return <div className={styles.container}>
        <div className={styles.titleContainer}>
          <p className={styles.pageTitle}>{t('Settings')}</p>
        </div>
        <div className={styles.contentGrid}>
          <Buttons showOutline={navigationController.isGamepadInteraction} isDebuggingAllowed={isDebuggingAllowed} isNetworkTestAllowed={isNetworkTestAllowed} step={settingsViewController.activeTab} setStep={settingsViewController.setActiveTab} />

          <Content focusable={!settingsViewController.isMobileView} blockNavigationOut={settingsViewController.isMobileView && !isNullOrUndefined(settingsViewController.setActiveTab)} isMobile={settingsViewController.isMobileView} />
        </div>
      </div>;
}));