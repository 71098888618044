import { DEFAULT_RETRIES } from '@utomik-app-monorepo/constants';

import { RequestMethod, RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncList } from '../../stores/objectStore/asyncList';
import { ObjectID } from '../objectStore/asyncObject';

export interface IServerGroups {
  id: number;
  url: string;
  name: string;
  gateways: IStreamGetGatewayList[];
  tags: string[];
  supported_countries: string[];
}

export interface IStreamGetGatewayList {
  active: boolean;
  address: string;
  id: number;
  max_sessions: number;
  name: string;
  status: string;
  url: string;
  ping?: number;
}

export interface IStreamGetGatewayListAvailableGateway extends IStreamGetGatewayList {
  hasResources?: boolean;
  queueLength?: number;
  predictedQueue?: number;
  waitingTime?: number;
  weight?: number;
}

export class StreamGetGatewayList extends AsyncList<IStreamGetGatewayList[]> {
  public constructor(requestQueue: RequestQueue, id: ObjectID, url?: string) {
    super(requestQueue, id, url);
  }

  /**
    Collecting all available gateways from each of the server groups and form a common array of gateways
   */
  public async getGatewayList(): Promise<IStreamGetGatewayList[]> {
    const response = await this.requestQueue.add<IServerGroups[]>(
      this.url,
      {},
      RequestPriority.High,
      RequestMethod.Get,
      null,
      DEFAULT_RETRIES,
      true
    ).promise;
    const gatewayList: IStreamGetGatewayList[] = response.data.reduce((acc, serverGroup) => {
      return [...acc, ...serverGroup.gateways];
    }, [] as IStreamGetGatewayList[]);

    return gatewayList;
  }
}
