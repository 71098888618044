import { AxiosRequestHeaders } from 'axios';

import { SecurityToken } from '../../securityToken/securityToken';
import {
  BadRequestException,
  InvalidTokenException,
  NotAuthorizedException,
  NotFoundException,
  NotYetImplementedException,
  PreconditionFailedException,
  ServerException,
  TimeOutException,
  TooManyRequestsException,
} from './utomikError';

export interface IUtomikHeader {
  // Utomik Headers
  Authorization?: string;
  'Accept-Language'?: string;
  'X-Utomik-Rec-Caching'?: string;
  'Cache-Control'?: string;
}

export interface IHttpConfig {
  headers?: AxiosRequestHeaders & IUtomikHeader;
}

export interface IHttpResponse<Item> {
  data: Item;
  status: number;
  statusText: string;
  headers: unknown;
  request?: unknown;
}

export abstract class HttpTransport {
  protected host: string;
  protected securityToken: SecurityToken;

  public constructor(host: string, securityToken: SecurityToken) {
    this.host = host;
    this.initialize(securityToken);
  }

  public initialize(securityToken: SecurityToken): void {
    this.securityToken = securityToken;
  }

  public reset(): void {
    this.securityToken = null;
  }

  public get token(): SecurityToken {
    return this.securityToken;
  }

  /**
   * The Request configuration holds the configuration object used set,
   * i.e. headers like Accept-Language.
   */
  public defaultConfig: IHttpConfig;

  public abstract resetDefaultConfig(language: string): void;

  public abstract get<T>(_endPointUrl: string, _config?: IHttpConfig): Promise<IHttpResponse<T>>;

  public abstract delete<T>(_endPointUrl: string, _config?: IHttpConfig): Promise<IHttpResponse<T>>;

  public abstract post<T, R>(_endPointUrl: string, _postData: R, _config?: IHttpConfig): Promise<IHttpResponse<T>>;

  public abstract put<T, R>(_endPointUrl: string, _putData?: R, _config?: IHttpConfig): Promise<IHttpResponse<T>>;

  public abstract patch<T, R>(_endPointUrl: string, _patchData?: R, _config?: IHttpConfig): Promise<IHttpResponse<T>>;

  protected isAbsolute(url: string): boolean {
    return url.startsWith('http://') || url.startsWith('https://');
  }

  /**
   * Decide which exception to throw based on the status code of the response.
   *
   * @param status
   */
  protected handleError(status: number, message: string): void {
    switch (status) {
      case 400:
        throw new BadRequestException(message);
      case 401:
        throw new NotAuthorizedException(message);
      case 403:
        throw new InvalidTokenException(message);
      case 404:
        throw new NotFoundException(message);
      case 408:
        throw new TimeOutException();
      case 412:
        throw new PreconditionFailedException(message);
      case 429:
        throw new TooManyRequestsException(message);
      case 500:
        throw new ServerException(message);
      default:
        throw new NotYetImplementedException(message, status);
    }
  }
}
