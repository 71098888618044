import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FocusableButton } from '../focusable-button/focusable-button';
import './pagination-buttons.scss';
enum ButtonsFocusKeys {
  Prev = 'pagination-button-1',
  Next = 'pagination-button-2',
}
type Props = {
  prev: {
    onPress: () => void;
    isPulsating?: boolean;
  };
  next: Props['prev'];
  currPage: number;
  pagesCount: number;
  preferredChildFocusKey?: `${ButtonsFocusKeys}`;
  className?: string;
};
export const PaginationButtons = withFocusable({
  trackChildren: true
})<Props>(observer(function PaginationButtons({
  prev,
  next,
  currPage,
  pagesCount,
  className,
  setFocus,
  hasFocusedChild
}) {
  useEffect(() => {
    if (!hasFocusedChild) return;
    if (currPage <= 1) {
      setFocus(ButtonsFocusKeys.Next);
    } else if (currPage >= pagesCount) {
      setFocus(ButtonsFocusKeys.Prev);
    }
  }, [currPage, hasFocusedChild]);
  return <div className={cx('pagination-buttons__container', {
    [className]: !!className
  })}>
        <FocusableButton focusKey={ButtonsFocusKeys.Prev} pulsating={prev.isPulsating} onEnterPress={prev.onPress} type={'back'} focusable={currPage !== 1} />
        <div className={'counter__container'}>{(currPage || 1) + '/' + (pagesCount || 1)}</div>
        <FocusableButton focusKey={ButtonsFocusKeys.Next} pulsating={next.isPulsating} onEnterPress={next.onPress} type={'forward'} focusable={currPage !== pagesCount} />
      </div>;
}));