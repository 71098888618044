import { NINJA_EXCLUSIVE_CAROUSEL_SLUG, NINJA_FOCUS_CAROUSEL_SLUG } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { ChannelStore } from '../../../dataStore/stores/channelStore/channelStore';
import { NinjaSummary } from '../../../dataStore/stores/ninjaSummary/ninjaSummary';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';
import { User } from '../../../dataStore/stores/userStore/user';
import { AppTileProvider } from '../../global/tile-provider/app-tile-provider';
import { ClientController } from '../clientController/clientController';
import { PageScrollState } from '../pageScrollState/pageScrollState';
import NinjaCarousel from './ninjaCarousel';

export class NinjaController {
  private readonly _autorunDisposer: ReturnType<typeof autorun>;
  private readonly _channelStore: ChannelStore;
  private readonly _clientController: ClientController;
  private readonly _ninjaSummary: NinjaSummary;
  private readonly _pageScrollState: PageScrollState = null;
  @observable
  private _state = AsyncObjectState.None;
  @observable
  private _allNotEmptyProviders: AppTileProvider[] = [];

  // Change these to set the ninja carousel ids
  public readonly focusChannelSlug: string = NINJA_FOCUS_CAROUSEL_SLUG;
  public readonly exclusiveChannelSlug: string = NINJA_EXCLUSIVE_CAROUSEL_SLUG;

  // Ninja Carousels
  public readonly focusCarousel: NinjaCarousel;
  public readonly exclusiveCarousel: NinjaCarousel;

  public get pageScrollState() {
    return this._pageScrollState;
  }

  public clearPageScrollState() {
    this._pageScrollState.clearState();
  }

  /**
   * Constructor
   * @param channelStore Used to display ninja carousels
   * @param clientController Used to display the user object
   * @param ninjaSummary Used to display ninja specific information
   */
  public constructor(channelStore: ChannelStore, clientController: ClientController, ninjaSummary: NinjaSummary) {
    this._channelStore = channelStore;
    this._clientController = clientController;
    this._ninjaSummary = ninjaSummary;
    this.focusCarousel = new NinjaCarousel(this._channelStore, this.focusChannelSlug);
    this.exclusiveCarousel = new NinjaCarousel(this._channelStore, this.exclusiveChannelSlug);

    makeObservable(this);

    this._pageScrollState = new PageScrollState();

    this._autorunDisposer = autorun(() => {
      const providers: AppTileProvider[] = [this.focusCarousel.provider, this.exclusiveCarousel.provider];
      const filteredProviders = providers.reduce((acc, curr) => {
        if (curr && (curr.state !== AsyncObjectState.Done || (curr.state === AsyncObjectState.Done && curr.length))) {
          return [...acc, curr];
        }
        return acc;
      }, [] as AppTileProvider[]);

      this._setAllNotEmptyProviders(filteredProviders);
    });
  }

  /**
   * Ensures that required data is fetched.
   */
  public async loadIfNeeded(): Promise<void> {
    if (this._state === AsyncObjectState.Done || this._state === AsyncObjectState.Pending) return;

    this._setState(AsyncObjectState.Pending);

    const promises: Promise<unknown>[] = [];
    const focus = this._channelStore.getItem(this.focusChannelSlug);
    if (focus.state === AsyncObjectState.None || focus.state === AsyncObjectState.Error) {
      log(`loadIfNeeded(): Loading focus channel ${this.focusChannelSlug}`);
      promises.push(focus.fetch());
    }
    const exclusive = this._channelStore.getItem(this.exclusiveChannelSlug);
    if (exclusive.state === AsyncObjectState.None || exclusive.state === AsyncObjectState.Error) {
      log(`loadIfNeeded(): Loading exclusive channel ${this.exclusiveChannelSlug}`);
      promises.push(exclusive.fetch());
    }

    if (this._ninjaSummary.state === AsyncObjectState.None || this._ninjaSummary.state === AsyncObjectState.Error) {
      log(`loadIfNeeded(): Loading ninja summary`);
      promises.push(this._ninjaSummary.fetch());
    }
    await Promise.all(promises);

    this._setState(AsyncObjectState.Done);
  }
  @action
  private _setState(state: AsyncObjectState) {
    this._state = state;
  }
  @computed
  public get state() {
    return this._state;
  }

  @computed
  public get allNotEmptyProviders() {
    return this._allNotEmptyProviders;
  }

  @action
  private _setAllNotEmptyProviders(providers: AppTileProvider[]) {
    this._allNotEmptyProviders = providers;
  }

  /**
   * The user object
   */
  @computed
  public get user(): User {
    return this._clientController.user;
  }

  /**
   * Returns the NinjaSummary
   */
  @computed
  public get summary(): NinjaSummary {
    return this._ninjaSummary;
  }

  /**
   * Ninja experience points
   */
  @computed
  public get experienceInCurrentLevel(): number {
    if (!this.summary?.nextNinjaLevel?.min) return 0;
    return this.summary.experience - this.summary.nextNinjaLevel?.min;
  }

  /**
   * Ninja experience points
   */
  @computed
  public get nextNinjaLevelMax(): number {
    if (!this.summary?.nextNinjaLevel?.max) return 0;
    return this.summary.nextNinjaLevel?.max - this.summary.nextNinjaLevel?.min;
  }

  /**
   * Returns progress for ninja rank
   */
  @computed
  public get progress(): number {
    if (!this.nextNinjaLevelMax) return 0;
    return this.experienceInCurrentLevel / this.nextNinjaLevelMax;
  }

  public unload() {
    this._setState(AsyncObjectState.None);
    this.focusCarousel.provider.unload();
    this.exclusiveCarousel.provider.unload();
    this._pageScrollState.clearState();
  }

  public dispose = () => {
    this._autorunDisposer();
    this.focusCarousel.dispose();
    this.exclusiveCarousel.dispose();
  };
}
