export type KeyboardLayoutEntryList = { key: string; keyCode: number; shiftKey: boolean; code: string }[][];

export type KeyboardLayout = {
  default: KeyboardLayoutEntryList;
  capsLock: KeyboardLayoutEntryList;
  symbols: KeyboardLayoutEntryList;
};

const symbols = [
  [
    { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
    { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
    { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
    { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
    { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
    { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
    { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
    { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
    { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
    { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
  ],
  [
    { key: '!', keyCode: 49, shiftKey: true, code: 'Digit1' },
    { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
    { key: '#', keyCode: 51, shiftKey: true, code: 'Digit3' },
    { key: '$', keyCode: 52, shiftKey: true, code: 'Digit4' },
    { key: '%', keyCode: 53, shiftKey: true, code: 'Digit5' },
    { key: '^', keyCode: 54, shiftKey: true, code: 'Digit6' },
    { key: '&', keyCode: 55, shiftKey: true, code: 'Digit7' },
    { key: '*', keyCode: 56, shiftKey: true, code: 'Digit8' },
    { key: '(', keyCode: 57, shiftKey: true, code: 'Digit9' },
    { key: ')', keyCode: 48, shiftKey: true, code: 'Digit0' },
  ],
  [
    { key: '±', keyCode: 189, shiftKey: true, code: 'IntlBackslash' },
    { key: '_', keyCode: 189, shiftKey: true, code: 'Minus' },
    { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    { key: '+', keyCode: 187, shiftKey: true, code: 'Equal' },
    { key: '=', keyCode: 187, shiftKey: false, code: 'Equal' },
    { key: '[', keyCode: 219, shiftKey: false, code: 'BracketLeft' },
    { key: ']', keyCode: 221, shiftKey: false, code: 'BracketRight' },
    { key: '{', keyCode: 219, shiftKey: true, code: 'BracketLeft' },
    { key: '}', keyCode: 221, shiftKey: true, code: 'BracketRight' },
    { key: '|', keyCode: 220, shiftKey: true, code: 'Backslash' },
  ],
  [
    { key: '\\', keyCode: 220, shiftKey: false, code: 'Backslash' },
    { key: '/', keyCode: 191, shiftKey: false, code: 'Slash' },
    { key: '<', keyCode: 188, shiftKey: true, code: 'Comma' },
    { key: '>', keyCode: 190, shiftKey: true, code: 'Period' },
    { key: '~', keyCode: 192, shiftKey: true, code: 'Backquote' },
    { key: '`', keyCode: 192, shiftKey: false, code: 'Backquote' },
    { key: ':', keyCode: 186, shiftKey: true, code: 'Semicolon' },
    { key: '"', keyCode: 222, shiftKey: true, code: 'Quote' },
    { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
    { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
  ],
];

const german: KeyboardLayout = {
  default: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: 'ß', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
    [
      { key: 'q', keyCode: 81, shiftKey: false, code: 'KeyQ' },
      { key: 'w', keyCode: 87, shiftKey: false, code: 'KeyW' },
      { key: 'e', keyCode: 69, shiftKey: false, code: 'KeyE' },
      { key: 'r', keyCode: 82, shiftKey: false, code: 'KeyR' },
      { key: 't', keyCode: 84, shiftKey: false, code: 'KeyT' },
      { key: 'z', keyCode: 89, shiftKey: false, code: 'KeyY' },
      { key: 'u', keyCode: 85, shiftKey: false, code: 'KeyU' },
      { key: 'i', keyCode: 73, shiftKey: false, code: 'KeyI' },
      { key: 'o', keyCode: 79, shiftKey: false, code: 'KeyO' },
      { key: 'p', keyCode: 80, shiftKey: false, code: 'KeyP' },
      { key: 'ü', keyCode: 219, shiftKey: false, code: 'BracketLeft' },
    ],
    [
      { key: 'a', keyCode: 65, shiftKey: false, code: 'KeyA' },
      { key: 's', keyCode: 83, shiftKey: false, code: 'KeyS' },
      { key: 'd', keyCode: 68, shiftKey: false, code: 'KeyD' },
      { key: 'f', keyCode: 70, shiftKey: false, code: 'KeyF' },
      { key: 'g', keyCode: 71, shiftKey: false, code: 'KeyG' },
      { key: 'h', keyCode: 72, shiftKey: false, code: 'KeyH' },
      { key: 'j', keyCode: 74, shiftKey: false, code: 'KeyJ' },
      { key: 'k', keyCode: 75, shiftKey: false, code: 'KeyK' },
      { key: 'l', keyCode: 76, shiftKey: false, code: 'KeyL' },
      { key: 'ö', keyCode: 186, shiftKey: false, code: 'Semicolon' },
      { key: 'ä', keyCode: 222, shiftKey: false, code: 'Quote' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'y', keyCode: 90, shiftKey: false, code: 'KeyZ' },
      { key: 'x', keyCode: 88, shiftKey: false, code: 'KeyX' },
      { key: 'c', keyCode: 67, shiftKey: false, code: 'KeyC' },
      { key: 'v', keyCode: 86, shiftKey: false, code: 'KeyV' },
      { key: 'b', keyCode: 66, shiftKey: false, code: 'KeyB' },
      { key: 'n', keyCode: 78, shiftKey: false, code: 'KeyN' },
      { key: 'm', keyCode: 77, shiftKey: false, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
  ],
  capsLock: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: 'ß', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
    [
      { key: 'Q', keyCode: 81, shiftKey: true, code: 'KeyQ' },
      { key: 'W', keyCode: 87, shiftKey: true, code: 'KeyW' },
      { key: 'E', keyCode: 69, shiftKey: true, code: 'KeyE' },
      { key: 'R', keyCode: 82, shiftKey: true, code: 'KeyR' },
      { key: 'T', keyCode: 84, shiftKey: true, code: 'KeyT' },
      { key: 'Z', keyCode: 89, shiftKey: true, code: 'KeyY' },
      { key: 'U', keyCode: 85, shiftKey: true, code: 'KeyU' },
      { key: 'I', keyCode: 73, shiftKey: true, code: 'KeyI' },
      { key: 'O', keyCode: 79, shiftKey: true, code: 'KeyO' },
      { key: 'P', keyCode: 80, shiftKey: true, code: 'KeyP' },
      { key: 'Ü', keyCode: 219, shiftKey: true, code: 'BracketLeft' },
    ],
    [
      { key: 'A', keyCode: 65, shiftKey: true, code: 'KeyA' },
      { key: 'S', keyCode: 83, shiftKey: true, code: 'KeyS' },
      { key: 'D', keyCode: 68, shiftKey: true, code: 'KeyD' },
      { key: 'F', keyCode: 70, shiftKey: true, code: 'KeyF' },
      { key: 'G', keyCode: 71, shiftKey: true, code: 'KeyG' },
      { key: 'H', keyCode: 72, shiftKey: true, code: 'KeyH' },
      { key: 'J', keyCode: 74, shiftKey: true, code: 'KeyJ' },
      { key: 'K', keyCode: 75, shiftKey: true, code: 'KeyK' },
      { key: 'L', keyCode: 76, shiftKey: true, code: 'KeyL' },
      { key: 'Ö', keyCode: 186, shiftKey: true, code: 'Semicolon' },
      { key: 'Ä', keyCode: 222, shiftKey: true, code: 'Quote' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'Y', keyCode: 90, shiftKey: true, code: 'KeyZ' },
      { key: 'X', keyCode: 88, shiftKey: true, code: 'KeyX' },
      { key: 'C', keyCode: 67, shiftKey: true, code: 'KeyC' },
      { key: 'V', keyCode: 86, shiftKey: true, code: 'KeyV' },
      { key: 'B', keyCode: 66, shiftKey: true, code: 'KeyB' },
      { key: 'N', keyCode: 78, shiftKey: true, code: 'KeyN' },
      { key: 'M', keyCode: 77, shiftKey: true, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
  ],
  symbols: symbols,
};

const dutch: KeyboardLayout = {
  default: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
    [
      { key: 'q', keyCode: 81, shiftKey: false, code: 'KeyQ' },
      { key: 'w', keyCode: 87, shiftKey: false, code: 'KeyW' },
      { key: 'e', keyCode: 69, shiftKey: false, code: 'KeyE' },
      { key: 'r', keyCode: 82, shiftKey: false, code: 'KeyR' },
      { key: 't', keyCode: 84, shiftKey: false, code: 'KeyT' },
      { key: 'y', keyCode: 89, shiftKey: false, code: 'KeyY' },
      { key: 'u', keyCode: 85, shiftKey: false, code: 'KeyU' },
      { key: 'i', keyCode: 73, shiftKey: false, code: 'KeyI' },
      { key: 'o', keyCode: 79, shiftKey: false, code: 'KeyO' },
      { key: 'p', keyCode: 80, shiftKey: false, code: 'KeyP' },
      { key: '¨', keyCode: 222, shiftKey: false, code: 'Quote' },
    ],
    [
      { key: 'a', keyCode: 65, shiftKey: false, code: 'KeyA' },
      { key: 's', keyCode: 83, shiftKey: false, code: 'KeyS' },
      { key: 'd', keyCode: 68, shiftKey: false, code: 'KeyD' },
      { key: 'f', keyCode: 70, shiftKey: false, code: 'KeyF' },
      { key: 'g', keyCode: 71, shiftKey: false, code: 'KeyG' },
      { key: 'h', keyCode: 72, shiftKey: false, code: 'KeyH' },
      { key: 'j', keyCode: 74, shiftKey: false, code: 'KeyJ' },
      { key: 'k', keyCode: 75, shiftKey: false, code: 'KeyK' },
      { key: 'l', keyCode: 76, shiftKey: false, code: 'KeyL' },
      { key: 'ç', keyCode: 186, shiftKey: false, code: 'Semicolon' },
      { key: 'ñ', keyCode: 222, shiftKey: false, code: 'Quote' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'z', keyCode: 90, shiftKey: false, code: 'KeyZ' },
      { key: 'x', keyCode: 88, shiftKey: false, code: 'KeyX' },
      { key: 'c', keyCode: 67, shiftKey: false, code: 'KeyC' },
      { key: 'v', keyCode: 86, shiftKey: false, code: 'KeyV' },
      { key: 'b', keyCode: 66, shiftKey: false, code: 'KeyB' },
      { key: 'n', keyCode: 78, shiftKey: false, code: 'KeyN' },
      { key: 'm', keyCode: 77, shiftKey: false, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: '_', keyCode: 189, shiftKey: true, code: 'Minus' },
    ],
  ],
  capsLock: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
    [
      { key: 'Q', keyCode: 81, shiftKey: true, code: 'KeyQ' },
      { key: 'W', keyCode: 87, shiftKey: true, code: 'KeyW' },
      { key: 'E', keyCode: 69, shiftKey: true, code: 'KeyE' },
      { key: 'R', keyCode: 82, shiftKey: true, code: 'KeyR' },
      { key: 'T', keyCode: 84, shiftKey: true, code: 'KeyT' },
      { key: 'Y', keyCode: 89, shiftKey: true, code: 'KeyY' },
      { key: 'U', keyCode: 85, shiftKey: true, code: 'KeyU' },
      { key: 'I', keyCode: 73, shiftKey: true, code: 'KeyI' },
      { key: 'O', keyCode: 79, shiftKey: true, code: 'KeyO' },
      { key: 'P', keyCode: 80, shiftKey: true, code: 'KeyP' },
      { key: '¨', keyCode: 222, shiftKey: true, code: 'Quote' },
    ],
    [
      { key: 'A', keyCode: 65, shiftKey: true, code: 'KeyA' },
      { key: 'S', keyCode: 83, shiftKey: true, code: 'KeyS' },
      { key: 'D', keyCode: 68, shiftKey: true, code: 'KeyD' },
      { key: 'F', keyCode: 70, shiftKey: true, code: 'KeyF' },
      { key: 'G', keyCode: 71, shiftKey: true, code: 'KeyG' },
      { key: 'H', keyCode: 72, shiftKey: true, code: 'KeyH' },
      { key: 'J', keyCode: 74, shiftKey: true, code: 'KeyJ' },
      { key: 'K', keyCode: 75, shiftKey: true, code: 'KeyK' },
      { key: 'L', keyCode: 76, shiftKey: true, code: 'KeyL' },
      { key: 'Ç', keyCode: 186, shiftKey: true, code: 'Semicolon' },
      { key: 'Ñ', keyCode: 222, shiftKey: true, code: 'Quote' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'Z', keyCode: 90, shiftKey: true, code: 'KeyZ' },
      { key: 'X', keyCode: 88, shiftKey: true, code: 'KeyX' },
      { key: 'C', keyCode: 67, shiftKey: true, code: 'KeyC' },
      { key: 'V', keyCode: 86, shiftKey: true, code: 'KeyV' },
      { key: 'B', keyCode: 66, shiftKey: true, code: 'KeyB' },
      { key: 'N', keyCode: 78, shiftKey: true, code: 'KeyN' },
      { key: 'M', keyCode: 77, shiftKey: true, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: '_', keyCode: 189, shiftKey: true, code: 'Minus' },
    ],
  ],
  symbols: symbols,
};

const italian: KeyboardLayout = {
  default: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: "'", keyCode: 222, shiftKey: false, code: 'Quote' },
    ],
    [
      { key: 'q', keyCode: 81, shiftKey: false, code: 'KeyQ' },
      { key: 'w', keyCode: 87, shiftKey: false, code: 'KeyW' },
      { key: 'e', keyCode: 69, shiftKey: false, code: 'KeyE' },
      { key: 'r', keyCode: 82, shiftKey: false, code: 'KeyR' },
      { key: 't', keyCode: 84, shiftKey: false, code: 'KeyT' },
      { key: 'y', keyCode: 89, shiftKey: false, code: 'KeyY' },
      { key: 'u', keyCode: 85, shiftKey: false, code: 'KeyU' },
      { key: 'i', keyCode: 73, shiftKey: false, code: 'KeyI' },
      { key: 'o', keyCode: 79, shiftKey: false, code: 'KeyO' },
      { key: 'p', keyCode: 80, shiftKey: false, code: 'KeyP' },
      { key: 'è', keyCode: 219, shiftKey: false, code: 'BracketLeft' },
    ],
    [
      { key: 'a', keyCode: 65, shiftKey: false, code: 'KeyA' },
      { key: 's', keyCode: 83, shiftKey: false, code: 'KeyS' },
      { key: 'd', keyCode: 68, shiftKey: false, code: 'KeyD' },
      { key: 'f', keyCode: 70, shiftKey: false, code: 'KeyF' },
      { key: 'g', keyCode: 71, shiftKey: false, code: 'KeyG' },
      { key: 'h', keyCode: 72, shiftKey: false, code: 'KeyH' },
      { key: 'j', keyCode: 74, shiftKey: false, code: 'KeyJ' },
      { key: 'k', keyCode: 75, shiftKey: false, code: 'KeyK' },
      { key: 'l', keyCode: 76, shiftKey: false, code: 'KeyL' },
      { key: 'ò', keyCode: 186, shiftKey: false, code: 'Semicolon' },
      { key: 'à', keyCode: 222, shiftKey: false, code: 'Quote' },
    ],
    [
      { key: '<', keyCode: 226, shiftKey: false, code: 'IntlBackslash' },
      { key: 'z', keyCode: 90, shiftKey: false, code: 'KeyZ' },
      { key: 'x', keyCode: 88, shiftKey: false, code: 'KeyX' },
      { key: 'c', keyCode: 67, shiftKey: false, code: 'KeyC' },
      { key: 'v', keyCode: 86, shiftKey: false, code: 'KeyV' },
      { key: 'b', keyCode: 66, shiftKey: false, code: 'KeyB' },
      { key: 'n', keyCode: 78, shiftKey: false, code: 'KeyN' },
      { key: 'm', keyCode: 77, shiftKey: false, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
  ],
  capsLock: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: "'", keyCode: 222, shiftKey: false, code: 'Quote' },
    ],
    [
      { key: 'Q', keyCode: 81, shiftKey: true, code: 'KeyQ' },
      { key: 'W', keyCode: 87, shiftKey: true, code: 'KeyW' },
      { key: 'E', keyCode: 69, shiftKey: true, code: 'KeyE' },
      { key: 'R', keyCode: 82, shiftKey: true, code: 'KeyR' },
      { key: 'T', keyCode: 84, shiftKey: true, code: 'KeyT' },
      { key: 'Y', keyCode: 89, shiftKey: true, code: 'KeyY' },
      { key: 'U', keyCode: 85, shiftKey: true, code: 'KeyU' },
      { key: 'I', keyCode: 73, shiftKey: true, code: 'KeyI' },
      { key: 'O', keyCode: 79, shiftKey: true, code: 'KeyO' },
      { key: 'P', keyCode: 80, shiftKey: true, code: 'KeyP' },
      { key: 'È', keyCode: 219, shiftKey: true, code: 'BracketLeft' },
    ],
    [
      { key: 'A', keyCode: 65, shiftKey: true, code: 'KeyA' },
      { key: 'S', keyCode: 83, shiftKey: true, code: 'KeyS' },
      { key: 'D', keyCode: 68, shiftKey: true, code: 'KeyD' },
      { key: 'F', keyCode: 70, shiftKey: true, code: 'KeyF' },
      { key: 'G', keyCode: 71, shiftKey: true, code: 'KeyG' },
      { key: 'H', keyCode: 72, shiftKey: true, code: 'KeyH' },
      { key: 'J', keyCode: 74, shiftKey: true, code: 'KeyJ' },
      { key: 'K', keyCode: 75, shiftKey: true, code: 'KeyK' },
      { key: 'L', keyCode: 76, shiftKey: true, code: 'KeyL' },
      { key: 'Ò', keyCode: 186, shiftKey: true, code: 'Semicolon' },
      { key: 'À', keyCode: 222, shiftKey: true, code: 'Quote' },
    ],
    [
      { key: '>', keyCode: 226, shiftKey: true, code: 'IntlBackslash' },
      { key: 'Z', keyCode: 90, shiftKey: true, code: 'KeyZ' },
      { key: 'X', keyCode: 88, shiftKey: true, code: 'KeyX' },
      { key: 'C', keyCode: 67, shiftKey: true, code: 'KeyC' },
      { key: 'V', keyCode: 86, shiftKey: true, code: 'KeyV' },
      { key: 'B', keyCode: 66, shiftKey: true, code: 'KeyB' },
      { key: 'N', keyCode: 78, shiftKey: true, code: 'KeyN' },
      { key: 'M', keyCode: 77, shiftKey: true, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
  ],
  symbols: symbols,
};

const french: KeyboardLayout = {
  default: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
    [
      { key: 'a', keyCode: 65, shiftKey: false, code: 'KeyA' },
      { key: 'z', keyCode: 90, shiftKey: false, code: 'KeyZ' },
      { key: 'e', keyCode: 69, shiftKey: false, code: 'KeyE' },
      { key: 'r', keyCode: 82, shiftKey: false, code: 'KeyR' },
      { key: 't', keyCode: 84, shiftKey: false, code: 'KeyT' },
      { key: 'y', keyCode: 89, shiftKey: false, code: 'KeyY' },
      { key: 'u', keyCode: 85, shiftKey: false, code: 'KeyU' },
      { key: 'i', keyCode: 73, shiftKey: false, code: 'KeyI' },
      { key: 'o', keyCode: 79, shiftKey: false, code: 'KeyO' },
      { key: 'p', keyCode: 80, shiftKey: false, code: 'KeyP' },
      { key: '^', keyCode: 219, shiftKey: false, code: 'BracketLeft' },
    ],
    [
      { key: 'q', keyCode: 81, shiftKey: false, code: 'KeyQ' },
      { key: 's', keyCode: 83, shiftKey: false, code: 'KeyS' },
      { key: 'd', keyCode: 68, shiftKey: false, code: 'KeyD' },
      { key: 'f', keyCode: 70, shiftKey: false, code: 'KeyF' },
      { key: 'g', keyCode: 71, shiftKey: false, code: 'KeyG' },
      { key: 'h', keyCode: 72, shiftKey: false, code: 'KeyH' },
      { key: 'j', keyCode: 74, shiftKey: false, code: 'KeyJ' },
      { key: 'k', keyCode: 75, shiftKey: false, code: 'KeyK' },
      { key: 'l', keyCode: 76, shiftKey: false, code: 'KeyL' },
      { key: 'm', keyCode: 77, shiftKey: false, code: 'KeyM' },
      { key: 'ù', keyCode: 186, shiftKey: false, code: 'Semicolon' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'w', keyCode: 87, shiftKey: false, code: 'KeyW' },
      { key: 'x', keyCode: 88, shiftKey: false, code: 'KeyX' },
      { key: 'c', keyCode: 67, shiftKey: false, code: 'KeyC' },
      { key: 'v', keyCode: 86, shiftKey: false, code: 'KeyV' },
      { key: 'b', keyCode: 66, shiftKey: false, code: 'KeyB' },
      { key: 'n', keyCode: 78, shiftKey: false, code: 'KeyN' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: ';', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: ':', keyCode: 191, shiftKey: false, code: 'Slash' },
      { key: '!', keyCode: 223, shiftKey: false, code: 'Quote' },
    ],
  ],
  capsLock: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
    [
      { key: 'A', keyCode: 65, shiftKey: true, code: 'KeyA' },
      { key: 'Z', keyCode: 90, shiftKey: true, code: 'KeyZ' },
      { key: 'E', keyCode: 69, shiftKey: true, code: 'KeyE' },
      { key: 'R', keyCode: 82, shiftKey: true, code: 'KeyR' },
      { key: 'T', keyCode: 84, shiftKey: true, code: 'KeyT' },
      { key: 'Y', keyCode: 89, shiftKey: true, code: 'KeyY' },
      { key: 'U', keyCode: 85, shiftKey: true, code: 'KeyU' },
      { key: 'I', keyCode: 73, shiftKey: true, code: 'KeyI' },
      { key: 'O', keyCode: 79, shiftKey: true, code: 'KeyO' },
      { key: 'P', keyCode: 80, shiftKey: true, code: 'KeyP' },
      { key: '^', keyCode: 219, shiftKey: false, code: 'BracketLeft' },
    ],
    [
      { key: 'Q', keyCode: 81, shiftKey: true, code: 'KeyQ' },
      { key: 'S', keyCode: 83, shiftKey: true, code: 'KeyS' },
      { key: 'D', keyCode: 68, shiftKey: true, code: 'KeyD' },
      { key: 'F', keyCode: 70, shiftKey: true, code: 'KeyF' },
      { key: 'G', keyCode: 71, shiftKey: true, code: 'KeyG' },
      { key: 'H', keyCode: 72, shiftKey: true, code: 'KeyH' },
      { key: 'J', keyCode: 74, shiftKey: true, code: 'KeyJ' },
      { key: 'K', keyCode: 75, shiftKey: true, code: 'KeyK' },
      { key: 'L', keyCode: 76, shiftKey: true, code: 'KeyL' },
      { key: 'M', keyCode: 77, shiftKey: true, code: 'KeyM' },
      { key: 'Ù', keyCode: 186, shiftKey: true, code: 'Semicolon' },
      { key: '*', keyCode: 222, shiftKey: true, code: 'Quote' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'W', keyCode: 87, shiftKey: true, code: 'KeyW' },
      { key: 'X', keyCode: 88, shiftKey: true, code: 'KeyX' },
      { key: 'C', keyCode: 67, shiftKey: true, code: 'KeyC' },
      { key: 'V', keyCode: 86, shiftKey: true, code: 'KeyV' },
      { key: 'B', keyCode: 66, shiftKey: true, code: 'KeyB' },
      { key: 'N', keyCode: 78, shiftKey: true, code: 'KeyN' },
      { key: '?', keyCode: 188, shiftKey: true, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: '/', keyCode: 191, shiftKey: true, code: 'Slash' },
      { key: '!', keyCode: 223, shiftKey: false, code: 'Quote' },
    ],
  ],
  symbols: symbols,
};

const spanish: KeyboardLayout = {
  default: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: "'", keyCode: 222, shiftKey: false, code: 'Quote' },
      { key: '¡', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
    [
      { key: 'q', keyCode: 81, shiftKey: false, code: 'KeyQ' },
      { key: 'w', keyCode: 87, shiftKey: false, code: 'KeyW' },
      { key: 'e', keyCode: 69, shiftKey: false, code: 'KeyE' },
      { key: 'r', keyCode: 82, shiftKey: false, code: 'KeyR' },
      { key: 't', keyCode: 84, shiftKey: false, code: 'KeyT' },
      { key: 'y', keyCode: 89, shiftKey: false, code: 'KeyY' },
      { key: 'u', keyCode: 85, shiftKey: false, code: 'KeyU' },
      { key: 'i', keyCode: 73, shiftKey: false, code: 'KeyI' },
      { key: 'o', keyCode: 79, shiftKey: false, code: 'KeyO' },
      { key: 'p', keyCode: 80, shiftKey: false, code: 'KeyP' },
      { key: '`', keyCode: 219, shiftKey: false, code: 'BracketLeft' },
      { key: '+', keyCode: 221, shiftKey: false, code: 'BracketRight' },
    ],
    [
      { key: 'a', keyCode: 65, shiftKey: false, code: 'KeyA' },
      { key: 's', keyCode: 83, shiftKey: false, code: 'KeyS' },
      { key: 'd', keyCode: 68, shiftKey: false, code: 'KeyD' },
      { key: 'f', keyCode: 70, shiftKey: false, code: 'KeyF' },
      { key: 'g', keyCode: 71, shiftKey: false, code: 'KeyG' },
      { key: 'h', keyCode: 72, shiftKey: false, code: 'KeyH' },
      { key: 'j', keyCode: 74, shiftKey: false, code: 'KeyJ' },
      { key: 'k', keyCode: 75, shiftKey: false, code: 'KeyK' },
      { key: 'l', keyCode: 76, shiftKey: false, code: 'KeyL' },
      { key: 'ñ', keyCode: 186, shiftKey: false, code: 'Semicolon' },
      { key: '´', keyCode: 192, shiftKey: false, code: 'Backquote' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'z', keyCode: 90, shiftKey: false, code: 'KeyZ' },
      { key: 'x', keyCode: 88, shiftKey: false, code: 'KeyX' },
      { key: 'c', keyCode: 67, shiftKey: false, code: 'KeyC' },
      { key: 'v', keyCode: 86, shiftKey: false, code: 'KeyV' },
      { key: 'b', keyCode: 66, shiftKey: false, code: 'KeyB' },
      { key: 'n', keyCode: 78, shiftKey: false, code: 'KeyN' },
      { key: 'm', keyCode: 77, shiftKey: false, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
      { key: '-', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
  ],
  capsLock: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
      { key: "'", keyCode: 222, shiftKey: false, code: 'Quote' },
      { key: '¡', keyCode: 189, shiftKey: false, code: 'Minus' },
    ],
    [
      { key: 'Q', keyCode: 81, shiftKey: true, code: 'KeyQ' },
      { key: 'W', keyCode: 87, shiftKey: true, code: 'KeyW' },
      { key: 'E', keyCode: 69, shiftKey: true, code: 'KeyE' },
      { key: 'R', keyCode: 82, shiftKey: true, code: 'KeyR' },
      { key: 'T', keyCode: 84, shiftKey: true, code: 'KeyT' },
      { key: 'Y', keyCode: 89, shiftKey: true, code: 'KeyY' },
      { key: 'U', keyCode: 85, shiftKey: true, code: 'KeyU' },
      { key: 'I', keyCode: 73, shiftKey: true, code: 'KeyI' },
      { key: 'O', keyCode: 79, shiftKey: true, code: 'KeyO' },
      { key: 'P', keyCode: 80, shiftKey: true, code: 'KeyP' },
      { key: '`', keyCode: 219, shiftKey: false, code: 'BracketLeft' },
      { key: '+', keyCode: 221, shiftKey: false, code: 'BracketRight' },
    ],
    [
      { key: 'A', keyCode: 65, shiftKey: true, code: 'KeyA' },
      { key: 'S', keyCode: 83, shiftKey: true, code: 'KeyS' },
      { key: 'D', keyCode: 68, shiftKey: true, code: 'KeyD' },
      { key: 'F', keyCode: 70, shiftKey: true, code: 'KeyF' },
      { key: 'G', keyCode: 71, shiftKey: true, code: 'KeyG' },
      { key: 'H', keyCode: 72, shiftKey: true, code: 'KeyH' },
      { key: 'J', keyCode: 74, shiftKey: true, code: 'KeyJ' },
      { key: 'K', keyCode: 75, shiftKey: true, code: 'KeyK' },
      { key: 'L', keyCode: 76, shiftKey: true, code: 'KeyL' },
      { key: 'Ñ', keyCode: 186, shiftKey: true, code: 'Semicolon' },
      { key: '¨', keyCode: 192, shiftKey: true, code: 'Backquote' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'Z', keyCode: 90, shiftKey: true, code: 'KeyZ' },
      { key: 'X', keyCode: 88, shiftKey: true, code: 'KeyX' },
      { key: 'C', keyCode: 67, shiftKey: true, code: 'KeyC' },
      { key: 'V', keyCode: 86, shiftKey: true, code: 'KeyV' },
      { key: 'B', keyCode: 66, shiftKey: true, code: 'KeyB' },
      { key: 'N', keyCode: 78, shiftKey: true, code: 'KeyN' },
      { key: 'M', keyCode: 77, shiftKey: true, code: 'KeyM' },
      { key: ';', keyCode: 188, shiftKey: true, code: 'Comma' },
      { key: ':', keyCode: 190, shiftKey: true, code: 'Period' },
      { key: '_', keyCode: 189, shiftKey: true, code: 'Minus' },
    ],
  ],
  symbols: symbols,
};

const english: KeyboardLayout = {
  default: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
    ],
    [
      { key: 'q', keyCode: 81, shiftKey: false, code: 'KeyQ' },
      { key: 'w', keyCode: 87, shiftKey: false, code: 'KeyW' },
      { key: 'e', keyCode: 69, shiftKey: false, code: 'KeyE' },
      { key: 'r', keyCode: 82, shiftKey: false, code: 'KeyR' },
      { key: 't', keyCode: 84, shiftKey: false, code: 'KeyT' },
      { key: 'y', keyCode: 89, shiftKey: false, code: 'KeyY' },
      { key: 'u', keyCode: 85, shiftKey: false, code: 'KeyU' },
      { key: 'i', keyCode: 73, shiftKey: false, code: 'KeyI' },
      { key: 'o', keyCode: 79, shiftKey: false, code: 'KeyO' },
      { key: 'p', keyCode: 80, shiftKey: false, code: 'KeyP' },
    ],
    [
      { key: 'a', keyCode: 65, shiftKey: false, code: 'KeyA' },
      { key: 's', keyCode: 83, shiftKey: false, code: 'KeyS' },
      { key: 'd', keyCode: 68, shiftKey: false, code: 'KeyD' },
      { key: 'f', keyCode: 70, shiftKey: false, code: 'KeyF' },
      { key: 'g', keyCode: 71, shiftKey: false, code: 'KeyG' },
      { key: 'h', keyCode: 72, shiftKey: false, code: 'KeyH' },
      { key: 'j', keyCode: 74, shiftKey: false, code: 'KeyJ' },
      { key: 'k', keyCode: 75, shiftKey: false, code: 'KeyK' },
      { key: 'l', keyCode: 76, shiftKey: false, code: 'KeyL' },
      { key: '?', keyCode: 191, shiftKey: true, code: 'Slash' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'z', keyCode: 90, shiftKey: false, code: 'KeyZ' },
      { key: 'x', keyCode: 88, shiftKey: false, code: 'KeyX' },
      { key: 'c', keyCode: 67, shiftKey: false, code: 'KeyC' },
      { key: 'v', keyCode: 86, shiftKey: false, code: 'KeyV' },
      { key: 'b', keyCode: 66, shiftKey: false, code: 'KeyB' },
      { key: 'n', keyCode: 78, shiftKey: false, code: 'KeyN' },
      { key: 'm', keyCode: 77, shiftKey: false, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
    ],
  ],
  capsLock: [
    [
      { key: '1', keyCode: 49, shiftKey: false, code: 'Digit1' },
      { key: '2', keyCode: 50, shiftKey: false, code: 'Digit2' },
      { key: '3', keyCode: 51, shiftKey: false, code: 'Digit3' },
      { key: '4', keyCode: 52, shiftKey: false, code: 'Digit4' },
      { key: '5', keyCode: 53, shiftKey: false, code: 'Digit5' },
      { key: '6', keyCode: 54, shiftKey: false, code: 'Digit6' },
      { key: '7', keyCode: 55, shiftKey: false, code: 'Digit7' },
      { key: '8', keyCode: 56, shiftKey: false, code: 'Digit8' },
      { key: '9', keyCode: 57, shiftKey: false, code: 'Digit9' },
      { key: '0', keyCode: 48, shiftKey: false, code: 'Digit0' },
    ],
    [
      { key: 'Q', keyCode: 81, shiftKey: true, code: 'KeyQ' },
      { key: 'W', keyCode: 87, shiftKey: true, code: 'KeyW' },
      { key: 'E', keyCode: 69, shiftKey: true, code: 'KeyE' },
      { key: 'R', keyCode: 82, shiftKey: true, code: 'KeyR' },
      { key: 'T', keyCode: 84, shiftKey: true, code: 'KeyT' },
      { key: 'Y', keyCode: 89, shiftKey: true, code: 'KeyY' },
      { key: 'U', keyCode: 85, shiftKey: true, code: 'KeyU' },
      { key: 'I', keyCode: 73, shiftKey: true, code: 'KeyI' },
      { key: 'O', keyCode: 79, shiftKey: true, code: 'KeyO' },
      { key: 'P', keyCode: 80, shiftKey: true, code: 'KeyP' },
    ],
    [
      { key: 'A', keyCode: 65, shiftKey: true, code: 'KeyA' },
      { key: 'S', keyCode: 83, shiftKey: true, code: 'KeyS' },
      { key: 'D', keyCode: 68, shiftKey: true, code: 'KeyD' },
      { key: 'F', keyCode: 70, shiftKey: true, code: 'KeyF' },
      { key: 'G', keyCode: 71, shiftKey: true, code: 'KeyG' },
      { key: 'H', keyCode: 72, shiftKey: true, code: 'KeyH' },
      { key: 'J', keyCode: 74, shiftKey: true, code: 'KeyJ' },
      { key: 'K', keyCode: 75, shiftKey: true, code: 'KeyK' },
      { key: 'L', keyCode: 76, shiftKey: true, code: 'KeyL' },
      { key: '?', keyCode: 191, shiftKey: true, code: 'Slash' },
    ],
    [
      { key: '@', keyCode: 50, shiftKey: true, code: 'Digit2' },
      { key: 'Z', keyCode: 90, shiftKey: true, code: 'KeyZ' },
      { key: 'X', keyCode: 88, shiftKey: true, code: 'KeyX' },
      { key: 'C', keyCode: 67, shiftKey: true, code: 'KeyC' },
      { key: 'V', keyCode: 86, shiftKey: true, code: 'KeyV' },
      { key: 'B', keyCode: 66, shiftKey: true, code: 'KeyB' },
      { key: 'N', keyCode: 78, shiftKey: true, code: 'KeyN' },
      { key: 'M', keyCode: 77, shiftKey: true, code: 'KeyM' },
      { key: ',', keyCode: 188, shiftKey: true, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: true, code: 'Period' },
      { key: ',', keyCode: 188, shiftKey: false, code: 'Comma' },
      { key: '.', keyCode: 190, shiftKey: false, code: 'Period' },
    ],
  ],
  symbols: symbols,
};

export const keyboardLayout = { en: english, de: german, it: italian, nl: dutch, fr: french, es: spanish };
