import { Routes } from '@utomik-app-monorepo/constants';
import { TokenManagerContext, VideoPlayerControllerContext } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router';
import { VideoPlayer } from '../video-player/video-player';
import './background-video-player.scss';
export const BackgroundVideoPlayer = observer(function BackgroundVideoPlayer() {
  const tokenManager = useContext(TokenManagerContext);
  const videoPlayerController = useContext(VideoPlayerControllerContext);
  const location = useLocation();
  const showImage = videoPlayerController.currentApp || videoPlayerController.config.url || videoPlayerController.config.forciblyShow;
  const fullscreen = videoPlayerController.config.fullscreen;
  const narrow = location.pathname.startsWith(Routes.Search) || location.pathname.startsWith(Routes.Ninja);
  const isDisabled = location.pathname.startsWith(Routes.GameRun);
  const handleFadeOut = () => {
    videoPlayerController.setConfig({
      muted: true,
      fullscreen: false,
      url: null,
      forciblyShow: false,
      showLoader: true,
      backgroundUrl: null,
      looping: false
    });
  };
  return ReactDOM.createPortal(<VideoPlayer backgroundUrl={videoPlayerController.config.backgroundUrl} url={videoPlayerController.config.url} muted={videoPlayerController.config.muted} onFadeOutEnd={handleFadeOut} application={videoPlayerController.currentApp} className={cx('background-video-player', {
    'background-video-player--fullscreen': fullscreen,
    'background-video-player--show': showImage && !isDisabled,
    'background-video-player--narrow': narrow
  })} fadeOut={tokenManager.isAuthorized} loop={videoPlayerController.config.looping} />, document.body);
});