import { action, makeObservable, observable } from 'mobx';

import { ObjectID } from '../objectStore/asyncObject';

export interface ApiUserRating {
  application: {
    id: ObjectID;
  };
  rating: number;
}

export class UserRating {
  public static parse(apiItem: ApiUserRating): UserRating {
    return new UserRating(apiItem);
  }
  public id: ObjectID;

  @observable
  public rating: number;

  public constructor(apiUserRating: ApiUserRating) {
    makeObservable(this);
    // We want to key UserRating by applicationId.
    this.id = apiUserRating.application.id;
    this.setRating(apiUserRating.rating);
  }

  @action
  public setRating(rating: number): void {
    this.rating = rating;
  }
}
