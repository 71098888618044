import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useGamepadBumpers } from '@utomik-app-monorepo/hooks';
import { NavigationControllerContext } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { GamepadButtons } from '../GamepadButtons/GamepadButtons';
import './top-menu.scss';
const TitlesContainer: React.FC<{
  show: boolean;
}> = observer(function TitlesContainer({
  show
}) {
  const navigationController = useContext(NavigationControllerContext);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const scrollableWrapperRef = useRef<HTMLDivElement>(null);
  const pointerRef = useRef<HTMLDivElement>(null);
  const prevSelectedRef = useRef<HTMLDivElement>(null);
  const titlesMap = useMemo(() => {
    //create a map with indexes
    return navigationController.sectionNames.reduce((previousValue, currentValue, currentIndex) => {
      previousValue[currentValue] = currentIndex + 1;
      return previousValue;
    }, {});
  }, [show, navigationController.sectionNames]);
  useEffect(() => {
    const activeElement = scrollableContainerRef.current?.children[titlesMap[navigationController.currentSection]] as HTMLDivElement;
    if (!activeElement) return;
    let offset;
    const left = activeElement.offsetLeft;
    const widthActiveEl = activeElement.offsetWidth;
    const widthContainer = scrollableContainerRef.current.offsetWidth;
    const widthWrapper = scrollableWrapperRef.current.offsetWidth;
    const widthPointer = pointerRef.current.offsetWidth;
    const offsetMiddle = scrollableWrapperRef.current.clientWidth / 2;
    if (left >= 0 && left < offsetMiddle - widthActiveEl / 2) {
      offset = 0;
    } else if (left >= widthContainer - widthWrapper + offsetMiddle - widthActiveEl) {
      offset = widthContainer - widthWrapper;
    } else {
      offset = left - offsetMiddle + widthActiveEl / 2;
    }
    if (!scrollableContainerRef.current || !pointerRef.current) return;
    scrollableContainerRef.current.style.transform = `translateX(${-offset}px)`;
    pointerRef.current.style.transform = `rotate(90deg) translateY(${-(left + widthActiveEl / 2 - widthPointer / 2)}px)`;
    prevSelectedRef.current = activeElement;
  }, [show, navigationController.currentSection]);
  const titles = useMemo(() => {
    return navigationController.sectionNames.map((name, idx) => <div key={`top-menu-item-${idx}`} className={'top-menu-item'}>
        {name}
      </div>);
  }, [navigationController.sectionNames]);
  const pointer = useMemo(() => <div className={'top-menu-pointer'} ref={pointerRef}></div>, []);
  return <div className={'scrollable-wrapper'} ref={scrollableWrapperRef}>
      <div className={'scrollable-container'} ref={scrollableContainerRef}>
        {pointer}
        {titles}
      </div>
    </div>;
});
export const TopMenu = withFocusable()(observer(function TopMenu({
  setFocus
}) {
  const navigationController = useContext(NavigationControllerContext);
  const show = !!navigationController.sectionNames?.length && !!navigationController.currentSection;
  useGamepadBumpers(setFocus, navigationController.sectionNames);
  return <section className={cx('top-menu__container', {
    'top-menu__container--show': show
  })}>
        <GamepadButtons type={'long'} name={'L1'} />
        <TitlesContainer show={show} />
        <GamepadButtons type={'long'} name={'R1'} />
      </section>;
}));