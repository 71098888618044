import { CHANNEL_MIN_SPOTLIGHT_ENTRIES } from '@utomik-app-monorepo/constants';
import { computed, flow, makeObservable, observable } from 'mobx';

import { RequestPriority } from '../../../dataStore/requestQueue/requestQueue';
import { Application } from '../../../dataStore/stores/applicationStore/application';
import { Channel } from '../../../dataStore/stores/channelStore/channel';
import { ChannelStore } from '../../../dataStore/stores/channelStore/channelStore';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';
import { AppTileProvider } from '../../global/tile-provider/app-tile-provider';

export interface FilterObject {
  filter: string;
  name: string;
}

export class ChannelInfoController {
  private readonly _channelStore: ChannelStore;

  @observable
  private _initialized = false;
  @computed
  public get initialized(): boolean {
    return this._initialized;
  }

  @observable
  private _channel: Channel = null;
  @computed
  public get channel(): Channel {
    return this._channel;
  }

  @observable
  private _gridProvider: AppTileProvider = null;
  @computed
  public get gridProvider(): AppTileProvider {
    return this._gridProvider;
  }

  @observable
  private _spotlightEntries: Application[] = null;
  @computed
  public get topSpotlightEntry(): Application {
    return this._spotlightEntries?.length > 0 ? this._spotlightEntries[0] : null;
  }

  public constructor(channelStore: ChannelStore) {
    makeObservable(this);
    this._channelStore = channelStore;
  }

  public init = flow(function* (this: ChannelInfoController, channelSlug: string) {
    if (channelSlug === this._channel?.slug) return;

    // Cleanup
    this._initialized = false;
    this._channel = null;
    this._gridProvider = null;
    this._spotlightEntries = null;

    // Prepare channels
    this._channel = this._channelStore.getItem(channelSlug);
    if (this._channel.state !== AsyncObjectState.Done) {
      yield this._channel.fetch();
    }
    // Prepare GridProvider
    this._gridProvider = new AppTileProvider(
      this._channel.slug,
      this._channel.name,
      ['ChannelGrid', `${this._channel.analyticsName}`],
      this._channel.appList
    );

    // Prepare spotlights
    const spotlight = yield this._channel.spotlightList.getApps(
      0,
      CHANNEL_MIN_SPOTLIGHT_ENTRIES,
      RequestPriority.High,
      true
    );
    this._spotlightEntries = spotlight.items;
    this._initialized = true;
  });
}
