import { IReactionDisposer } from 'mobx';

import { ConnectionMonitor } from '../connectionMonitor/connectionMonitor';
import { NotificationFactory } from '../notificationFactory/notificationFactory';

export class NotificationWatcher {
  private readonly _connectionMonitor: ConnectionMonitor;
  private readonly _notificationFactory: NotificationFactory;
  private readonly _connectionWatcherDisposer: IReactionDisposer;
  private readonly _requestWatcherDisposer: IReactionDisposer;

  // private _createConnectionWatcher(): IReactionDisposer {
  //   return autorun(() => {
  //     // Display and remove notification when internet disappears and comes back.
  //   });
  // }
  //
  // private _createRequestWatcher(): IReactionDisposer {
  //   return autorun(() => {
  //     // Display and remove notification depending on if there are failed requests or not.
  //   });
  // }

  public constructor(notificationFactory: NotificationFactory, connectionMonitor: ConnectionMonitor) {
    this._connectionMonitor = connectionMonitor;
    this._notificationFactory = notificationFactory;

    // Init watchers
    //this._connectionWatcherDisposer = this._createConnectionWatcher();
    //this._requestWatcherDisposer = this._createRequestWatcher();

    // Init disposers
    // window.addEventListener('beforeunload', () => {
    //   // Dispose watchers before unloading.
    //   this._connectionWatcherDisposer();
    //   this._requestWatcherDisposer();
    // });
  }
}
