import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Routes, analyticsRoutes } from '@utomik-app-monorepo/constants';
import { useMouseScroll, useSetAllowedPlayPress } from '@utomik-app-monorepo/hooks';
import { AnalyticControllerContext, AnalyticItemTypes, AppTileProvider, Application, ApplicationStoreContext, Channel, ChannelStoreContext, ChannelsControllerContext, DialogQueueContext, HomeControllerContext, MyListAppTileProvider, NavigationControllerContext, RecentlyPlayedAppTileProvider } from '@utomik-app-monorepo/store';
import { joinLocation, repeatFnCall } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TileCarousel } from '../tile-carousel/tile-carousel';
import { TileSelector } from '../tileSelector/tileSelector';
import './tiles-section.scss';
export const TilesSection = withFocusable({
  trackChildren: true
})(observer(function TilesSection({
  setFocus,
  hasFocusedChild,
  navigateByDirection
}) {
  const homeController = useContext(HomeControllerContext);
  const channelsController = useContext(ChannelsControllerContext);
  const analyticController = useContext(AnalyticControllerContext);
  const channelStore = useContext(ChannelStoreContext);
  const dialogQueue = useContext(DialogQueueContext);
  const appController = useContext(ApplicationStoreContext);
  const navigationController = useContext(NavigationControllerContext);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const isFocusedOnceRef = useRef<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMountedRef = useRef(true);
  const isCursorVisible = useMouseScroll(scrollableRef, navigateByDirection, true);
  useSetAllowedPlayPress(hasFocusedChild);
  useEffect(() => {
    if (isMountedRef.current) {
      navigationController.setSectionNames(homeController.allNotEmptyProviders.map(p => p.name));
    }
  }, [homeController.allNotEmptyProviders, homeController.currentProvider]);
  useEffect(() => {
    homeController.init();
    channelsController.init();
    return () => {
      navigationController.reset();
      isMountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (isMountedRef.current) {
      intervalId = repeatFnCall(setFocus.bind(null, homeController.pageScrollState.currentFocusKey));
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [location, homeController.currentProvider, dialogQueue.hasDialogs, homeController.allNotEmptyProviders.length > 1]);
  const handleFocus = useCallback((props, itemProps) => {
    if (itemProps?.name) {
      navigationController.setCurrentSection(itemProps.name);
    }
    if (!scrollableRef.current) return;
    if (isFocusedOnceRef.current) {
      scrollableRef.current.style.transition = 'transform 200ms ease-out';
    } else {
      scrollableRef.current.style.transition = 'none';
      isFocusedOnceRef.current = true;
    }
    scrollableRef.current.style.transform = `translateY(${-props.y}px)`;
  }, [scrollableRef.current, isFocusedOnceRef.current]);
  const handleChildFocus = useCallback((_, itemProps) => {
    navigationController.setCurrentId(itemProps.item.id, itemProps.type);
    navigationController.setCurrentIdx(itemProps.idx);
    //set focus key
    homeController.pageScrollState.currentFocusKey = itemProps.focusKey;
  }, []);
  const handleEnterPress = useCallback(props => {
    const breadcrumbs = analyticController.getBreadCrumbs({
      pageName: analyticsRoutes[Routes.Home],
      currentSection: props.carouselName,
      isCarousel: true
    });

    //redirect depends on the instance type
    if (props.item instanceof Application) {
      //send analytics
      appController.analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Game,
        location_on_page: joinLocation(breadcrumbs)
      });
      return navigate(`${Routes.AppInfo}/${props.item.id}`);
    }
    if (props.item instanceof Channel) {
      channelStore.pageScrollState.clearState();
      //send analytics
      appController.analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Channel,
        location_on_page: joinLocation(breadcrumbs)
      });
      return navigate(`${Routes.Channels}/${props.item.id}`);
    }
  }, []);
  const handleSeeAllPress = (provider: AppTileProvider) => {
    channelStore.pageScrollState.clearState();
    const breadcrumbs = analyticController.getBreadCrumbs({
      currentSection: navigationController.currentSection,
      isSeeAll: true,
      isCarousel: true
    });
    analyticController.navigate({
      item_name: 'SeeAll',
      item_type: AnalyticItemTypes.Channel,
      location_on_page: joinLocation(breadcrumbs)
    });
    if (provider.slug === 'my_list') {
      return navigate(Routes.MyList);
    }
    homeController.setCurrentProvider(provider);
  };
  const carousels = useMemo(() => homeController.allNotEmptyProviders.map(provider => {
    let list;
    if (provider instanceof RecentlyPlayedAppTileProvider) {
      list = provider.recentlyPlayedAppsShort;
    } else if (provider instanceof MyListAppTileProvider) {
      list = provider.myListApps;
    } else if (provider instanceof AppTileProvider) {
      list = provider.appList;
    } else {
      list = provider.channelList;
    }
    return <TileCarousel key={provider.slug} focusKey={provider.name} list={list} name={provider.name}
    //focusable={!!provider.length}
    onBecameFocused={handleFocus} onChildFocused={handleChildFocus} onEnterPress={handleEnterPress} onSeeAllPress={handleSeeAllPress.bind(null, provider as AppTileProvider)} isLoading={!provider?.length} showSeeAllTile={provider.slug !== 'spotlight'} isSpotlight={provider.slug === 'spotlight'} hasMouseOver={!isCursorVisible} />;
  }), [homeController.allNotEmptyProviders, isCursorVisible]);
  return <section className={'tiles-section'}>
        <TileSelector show={hasFocusedChild} />
        <div className={'scrollable-container'} ref={scrollableRef}>
          {carousels}
        </div>
      </section>;
}));