import orderBy from 'lodash/orderBy';
import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';

/**
 * A single notification toaster, which is (most of the time) the blue banner below the main navigation, informing the user about important stuff.
 */
export class NotificationToaster {
  private readonly _notificationStack: NotificationStack;
  private _key: string;
  private _classes: string[];
  private _date: Date;
  private _priority: number;
  private _content: React.FC<unknown>;
  private _onClose: () => void;
  public constructor(notificationStack: NotificationStack, key: string, classes: string[], priority: number, content: React.FC<unknown>, onClose: () => void) {
    this._notificationStack = notificationStack;
    this._key = key;
    this._classes = classes;
    this._date = new Date(Date.now());
    this._priority = priority;
    this._content = content;
    this._onClose = onClose;
  }
  public get key(): string {
    return this._key;
  }
  public get classes(): string[] {
    return this._classes;
  }
  public get date(): Date {
    return this._date;
  }
  public get priority(): number {
    return this._priority;
  }
  public get content(): React.FC<unknown> {
    return this._content;
  }

  // Trigger on clicking the cross to close.
  public close(): void {
    // onClose callback function if set.
    if (this._onClose !== null) {
      this._onClose();
    }
    // Remove the notification.
    this._notificationStack.remove(this.key);
  }
}

/**
 * Stack of all notification items.
 */
export class NotificationStack {
  public constructor() {
    makeObservable(this);
  }
  @observable
  private _notifications: NotificationToaster[] = [];
  @computed
  public get notifications(): NotificationToaster[] {
    return orderBy(Array.from(this._notifications.values()), ['priority', 'date'], ['desc', 'asc']);
  }

  /**
   * Add a new notification if it's key is not yet in the notification stack.
   *
   * @param key - Unique identifier for this notification. Only one notification with the same key can exist at the same time. Also used to delete notifications.
   * @param classes - Optional extra classes for styling.
   * @param priority - The priority of the notification. Higher priorities stack over lower priorities.
   * @param content - The content of the notification.
   * @param onClose - Callback on close.
   */
  @action
  public add(key: string, classes: string[], priority: number, content: React.FC<unknown>, onClose: () => void = null): void {
    if (!this._notifications.find((item: NotificationToaster) => {
      return item.key == key;
    })) {
      // Create new notification.
      const notification = new NotificationToaster(this, key, classes, priority, content, onClose);
      this._notifications.push(notification);
    }
  }

  /**
   * Remove a notification from the stack.
   *
   * @param notificationKey - The notification key to delete.
   */
  @action
  public remove(notificationKey: string): void {
    this._notifications = this._notifications.filter((notification: NotificationToaster) => notification.key !== notificationKey);
  }
}