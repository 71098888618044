import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, t } from '@lingui/macro';
import { AsyncObjectState } from '@utomik-app-monorepo/store';
import { DialogQueueContext, LogsControllerContext } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import { FocusableSelect } from '../../focusable-select/focusable-select';
import { Loading } from '../../loading/loading';
import { Stepper } from '../../stepper/stepper';
import './send-bug-report-dialog.scss';
enum Steps {
  Subject,
  Send,
}
export const SendBugReportDialog = observer(function StreamPingTooHighDialog() {
  const [step, setStep] = useState(Steps.Subject);
  const logsController = useContext(LogsControllerContext);
  const dialogQueue = useContext(DialogQueueContext);
  useEffect(() => {
    if (!logsController.logsStore?.state) return;
    logsController.logsStore.state = AsyncObjectState.None;
  }, []);
  useEffect(() => {
    return () => {
      logsController.logsStore?.setCurrentIssueType(null);
    };
  }, []);
  const handleSendReport = () => {
    logsController.generateAndSendBugReport();
  };
  const handleSelectChange = (val: {
    name: string;
  }) => {
    logsController.logsStore?.setCurrentIssueType(val.name);
  };
  const handleNextPress = () => {
    setStep(Steps.Send);
  };
  let body;
  let button;
  switch (logsController.logsStore?.state) {
    case AsyncObjectState.None:
      {
        dialogQueue.currentDialog.setShowHeader(true);
        body = <h1 className={'modal__description'}>
          <Trans context={'Text - Support ticket explanation'}>
            <p>A support ticket, with activity log will be created.</p>{' '}
            <p>You will receive an email with your ticket.</p>{' '}
            <p>To submit your question, please respond to this email.</p>{' '}
            <p>Our support department will be happy to assist.</p>
          </Trans>
        </h1>;
        button = <Trans context={'Button - Create a support ticket'}>Create support ticket</Trans>;
        break;
      }
    case AsyncObjectState.Pending:
      {
        dialogQueue.currentDialog.setShowHeader(false);
        body = <div className={'loading__container'}>
          <Loading loadingText={t({
            context: 'Text - In process of sending the bug report',
            message: 'Sending bug report'
          })} />
        </div>;
        break;
      }
    case AsyncObjectState.Done:
      {
        dialogQueue.currentDialog.setShowHeader(true);
        body = <div className={'result__container'}>
          <FontAwesomeIcon icon={faCircleCheck} className={'done-circle'} />
          <h1>
            <>{t({
                context: 'Text - The bug report has been sent',
                message: 'Report has been sent'
              })}</>
          </h1>
        </div>;
        break;
      }
    case AsyncObjectState.Error:
      {
        dialogQueue.currentDialog.setShowHeader(true);
        body = <div className={'result__container'}>
          <FontAwesomeIcon icon={faCircleXmark} className={'error-circle'} />
          <h1>
            <>
              {t({
                context: 'Text - Sending report failure (any error)',
                message: 'Sending report failed. Try again.'
              })}
            </>
          </h1>
        </div>;
        break;
      }
    default:
      {
        body = <h1 className={'modal__description'}>
          <Trans context={'Text - Support ticket explanation'}>
            <p>A support ticket, with activity log will be created.</p>{' '}
            <p>You will receive an email with your ticket.</p>{' '}
            <p>To submit your question, please respond to this email.</p>{' '}
            <p>Our support department will be happy to assist.</p>
          </Trans>
        </h1>;
        button = <Trans context={'Button - Create a support ticket'}>Create support ticket</Trans>;
      }
  }
  return <div className={'send-bug-report-dialog__container'}>
      <h1 className={'modal__title'}>
        <Trans context={'Title - Bug report'}>Bug report</Trans>
      </h1>
      <Stepper steps={[<>
            <FocusableSelect values={logsController.logsStore?.issueTypes || []} selectedValue={logsController.logsStore?.currentIssueType?.name || t({
        context: 'Select - Select a subject of a ticket',
        message: 'Select a subject'
      })} onChange={handleSelectChange} width={'wide'} />

            <FocusableButton disabled={!logsController.logsStore?.currentIssueType} focusable={!!logsController.logsStore?.currentIssueType} type={'primary'} onEnterPress={handleNextPress}>
              {t({
          context: 'Button - Next step',
          message: 'NEXT'
        })}
            </FocusableButton>
          </>, <>
            {body}
            {button && <FocusableButton type={'primary'} focusable={!!logsController.logsStore && logsController.logsStore?.state !== AsyncObjectState.Pending} onEnterPress={handleSendReport} autofocus>
                {button}
              </FocusableButton>}
          </>]} currentStep={step} />
    </div>;
});
export default SendBugReportDialog;