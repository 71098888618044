import {
  KeyboardLayout,
  Routes,
  customVirtualBackspaceKey,
  customVirtualEnterKey,
  keyboardLayout,
} from '@utomik-app-monorepo/constants';
import { i18n } from '@utomik-app-monorepo/locales';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { LogEventAction, LogEventType } from '../../../dataStore/stores/logsStore/interfaces';
import { LogsController } from '../../../dataStore/stores/logsStore/logsController';
import { KeyboardButtonState, KeyboardPosition } from './interfaces';
import './interfaces';

type KeyboardMode = keyof KeyboardLayout;

const excludedVirtualKeys = {
  [customVirtualBackspaceKey]: 'Backspace',
  [customVirtualEnterKey]: 'Enter',
};

export class KeyboardController {
  private readonly _reactionDisposer: ReturnType<typeof reaction>;
  private readonly _logsController: LogsController;
  @observable
  private _isKeyboardVisible = false;
  @observable
  private _mode: KeyboardMode = 'default';
  @observable
  private _currentLanguage = 'en';
  @observable
  private _position: KeyboardPosition = KeyboardPosition.Bottom;
  @observable
  private _shift: KeyboardButtonState = KeyboardButtonState.Released;
  private _keyCallback?: (event: Partial<KeyboardEvent>, isPressed: boolean) => void;

  constructor(logsController: LogsController) {
    this._logsController = logsController;

    makeObservable(this);

    //this reaction is used for logging
    this._reactionDisposer = reaction(
      () => this._isKeyboardVisible,
      (isVisible) => {
        if (isVisible) {
          this._logsController.addNewLogEvent({
            type: LogEventType.Keyboard,
            action: LogEventAction.Show,
            value: `Connected to a server: ${this._isKeyboardVisible}`,
          });
        } else {
          this._logsController.addNewLogEvent({
            type: LogEventType.Keyboard,
            action: LogEventAction.Hide,
            value: `Connected to a server: ${this._isKeyboardVisible}`,
          });
        }
      }
    );

    this.init();
  }

  private get _isStreamPage() {
    return !!location.hash.split('#')[1]?.startsWith(Routes.GameRun);
  }

  public setKeyCallback(callback: (event: Partial<KeyboardEvent>, isPressed: boolean) => void) {
    this._keyCallback = callback;
  }
  public switchInputFocused() {
    const input = document.getElementById('stream-text-input') as HTMLInputElement;

    if (input) {
      if (this.isKeyboardVisible) {
        input.blur();
      } else {
        input.focus();
      }
      return true;
    }

    return false;
  }

  @action
  public setIsKeyboardVisible(isVisible: boolean) {
    this._isKeyboardVisible = isVisible;

    if (!isVisible) {
      this.reset();
    } else {
      if (keyboardLayout[i18n.locale]) {
        this._currentLanguage = i18n.locale;
      }
    }
  }

  @computed
  public get currentLanguage() {
    return this._currentLanguage;
  }

  @action
  public setCurrentLanguage(lng: string) {
    this._currentLanguage = lng;
  }

  @action
  public switchLanguage = () => {
    if (this._currentLanguage === 'en' && keyboardLayout[i18n.locale]) {
      this._currentLanguage = i18n.locale;
      return;
    }

    this._currentLanguage = 'en';
  };

  @computed
  public get shift() {
    return this._shift;
  }

  @action
  private _setShift(value: KeyboardButtonState) {
    this._shift = value;
  }

  @computed
  public get mode() {
    return this._mode;
  }

  @action
  private _setMode(mode: KeyboardMode) {
    this._mode = mode;
  }

  @action
  public switchMode(value: KeyboardMode) {
    if (value === this._mode) {
      this._setMode('default');
    } else {
      this._setMode(value);
    }

    if (this._mode === 'capsLock') {
      this._setShift(KeyboardButtonState.Pressed);
    } else {
      this._setShift(KeyboardButtonState.Released);
    }
  }

  @computed
  public get position() {
    return this._position;
  }

  @action
  public switchPosition() {
    if (this._position === KeyboardPosition.Bottom) {
      this._position = KeyboardPosition.Top;
    } else {
      this._position = KeyboardPosition.Bottom;
    }
  }

  @computed
  public get isKeyboardVisible() {
    return this._isKeyboardVisible;
  }

  public handleKeyPress = (event: Partial<KeyboardEvent>) => {
    if (!this._isKeyboardVisible) return;

    //add handler
    if (this._keyCallback) {
      const evCopy = { ...event };

      if (this._isStreamPage && excludedVirtualKeys[event.key]) {
        //system keys substitution
        evCopy.key = excludedVirtualKeys[event.key];
        evCopy.code = excludedVirtualKeys[event.key];
      }

      evCopy.bubbles = true;
      evCopy.cancelable = true;

      evCopy.shiftKey &&
        this._keyCallback({ key: 'Shift', code: 'ShiftLeft', bubbles: true, cancelable: true, shiftKey: true }, true);
      this._keyCallback(evCopy, true);
      evCopy.shiftKey &&
        this._keyCallback({ key: 'Shift', code: 'ShiftLeft', bubbles: true, cancelable: true, shiftKey: false }, false);
    }
  };

  public handleKeyRelease = (event: Partial<KeyboardEvent>) => {
    if (!this._isKeyboardVisible) return;

    //add handler
    if (this._keyCallback) {
      const evCopy = { ...event };

      if (this._isStreamPage && excludedVirtualKeys[event.key]) {
        //system keys substitution
        evCopy.key = excludedVirtualKeys[event.key];
        evCopy.code = excludedVirtualKeys[event.key];
      }

      evCopy.bubbles = true;
      evCopy.cancelable = true;

      this._keyCallback(evCopy, false);
    }
  };
  @action
  public init() {
    const handleFocus = (e) => {
      if (!e.target?.getAttribute) return;

      const attr = e.target?.getAttribute('data-id');
      if (attr === 'INPUT') {
        this.setIsKeyboardVisible(true);
      }
    };

    const handleBlur = (e) => {
      if (!e.target?.getAttribute) return;

      const attr = e.target?.getAttribute('data-id');

      if (attr === 'INPUT') {
        this.setIsKeyboardVisible(false);
      }
    };

    window.addEventListener('focus', handleFocus, true);
    window.addEventListener('blur', handleBlur, true);
  }

  @action
  public reset() {
    if (this._isStreamPage && !this._isKeyboardVisible) {
      (document.activeElement as HTMLDivElement).blur();
    }

    this._mode = 'default';
    this._shift = KeyboardButtonState.Released;
    this._position = KeyboardPosition.Bottom;
  }
  @action
  public dispose() {
    this.reset();

    this._reactionDisposer();
  }
}
