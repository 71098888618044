import {
  INTERNAL_BACK_EVENT_NAME,
  INTERNAL_LB_EVENT_NAME,
  INTERNAL_RB_EVENT_NAME,
} from '@utomik-app-monorepo/constants';

/**
 * Our helper function to determine if a value is null or undefined.
 * @param val Any unknown value
 */
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { murmur2 } from 'murmurhash-js';
import React, { RefObject } from 'react';

import { languages } from './languages';

export { WebGamePadHelperAxesCode, WebGamePadHelperKeycode } from './webExternalGamepadController';

export function isNullOrUndefined<T>(val: T | undefined | null): boolean {
  return val === undefined || val === null;
}

/**
 * Returns a string that is an encodedURIcomponent that requests the app by id or slug
 * @param id
 */
export function getEncodedAppQuery(id: string | number): string {
  if (typeof id === 'string') {
    return encodeURIComponent(`slug="${id}"`);
  }
  return encodeURIComponent(`id=${id}`);
}

/**
 * Returns the difference of two dates in hours.
 * @param a Date A
 * @param b Date B
 */
export const differenceInHours = (a: Date, b: Date): number => {
  return (a.getTime() - b.getTime()) / 1000 / 60 / 60;
};

/**
 * Converts the given HTTP status code to a HTTP status code range.
 * E.g. 404 becomes 400 etc.
 * @param statusCode The HTTP status code to convert to a range.
 */
export const convertToHTTPRange = (statusCode: number): number => {
  if (!isNullOrUndefined(statusCode)) {
    return statusCode;
  }
  // Get the error range by taking the first digit and multiplying it by 100.
  return parseInt(statusCode.toString().substring(0, 1)) * 100;
};

/**
 * Returns the number of minutes from a throttled request error message.
 * If the given error message is not a throttled request error, a default of 5 minutes is returned.
 * @param errorMessage The error message that may contain a throttled request error message.
 */
export const parseMinutesFromThrottledError = (errorMessage: string): number => {
  let minutes = 5;
  if (errorMessage?.startsWith('Request was throttled')) {
    // Parse the message. Currently no better way with how the endpoint is handled.
    const myRegexp = /Expected available in ([0-9]+)/g;
    const match = myRegexp.exec(errorMessage.toString());
    if (match && match.length > 0) minutes = (Math.floor(parseInt(match[1]) / 300) + 1) * 5;
  }
  return minutes;
};

/**
 * Converts an array of objects into an object with keys from object.
 * @param { Array } array Array of objects with the same structure
 * @param { string | number } key Key from the object from array that will be a key for the new returned object
 */

export function normalizeArray<T>(array: T[], key: keyof T) {
  return array.reduce((acc, curr) => {
    return { ...acc, [curr[key as string]]: curr };
  }, {});
}

/**
 * Converts data to JSON
 * @param data Any data to stringify
 */

export const toJSON = (data: unknown) => JSON.stringify(data);

/**
 * Repeats the function call n-times by interval
 * @param { Function } callback Callback function to repeat call
 * @param { number } count number of callback calls
 * @param { number } intervalMs interval between function calls in ms
 */

export const repeatFnCall = (callback: () => unknown, count = 1, intervalMs = 10) => {
  let counter = 1;
  const intId = setInterval(() => {
    if (counter > count) return clearInterval(intId);
    callback();
    counter++;
  }, intervalMs);
  return intId;
};

/**
 * Stops video buffering
 * @param { HTMLVideoElement } element HTMLVideo element reference
 */

export const unloadVideo = (element: HTMLVideoElement) => {
  if (element) {
    element.pause();
    element.removeAttribute('src');
    element.removeAttribute('objectSrc');
    element.load();
  }
};

/**
 * Converts rem to px based on the current body font size
 * @param { number } rems Value in rem
 */

export const sizeRemToPx = (rems: number) => {
  return Number((parseFloat(getComputedStyle(document.body).fontSize) * rems).toFixed(0));
};

/**
 * Set transform values for the ref element (used for scrolling pages cause of better performance)
 * @param ref Ref of the element we want to move using transform
 * @param focusProps props that we receive if an element is focused (onBecameFocused method)
 * @param isEnabled set false if you want to disable scroll
 * @param verticalDivider divider of centering the focused element on the view
 * @param horizontalDivider the same as above but for the horizontal scrolling
 * @param useAbsoluteValues should use either absolute or relative values
 * */

export const setLimitedTransformValues = (
  ref: RefObject<HTMLDivElement>,
  focusProps: any,
  isEnabled = true,
  verticalDivider = 5,
  horizontalDivider = 2.5,
  useAbsoluteValues?: boolean
) => {
  if (!ref.current || !ref.current.parentElement || !isEnabled) return;

  const focusTop = useAbsoluteValues ? focusProps.top : focusProps.y;
  const focusLeft = useAbsoluteValues ? focusProps.left : focusProps.x;

  ref.current.style.willChange = 'transform';
  ref.current.style['-webkit-backface-visibility'] = 'hidden';

  const maxTop = Math.abs(ref.current.scrollHeight - ref.current.parentElement.clientHeight);
  const minTop = ref.current.parentElement.clientHeight / verticalDivider;

  const maxLeft = Math.abs(ref.current.scrollWidth - ref.current.parentElement.clientWidth);
  const minLeft = ref.current.parentElement.clientWidth / horizontalDivider;

  let translateYCoords: number;

  if (focusTop > maxTop) {
    translateYCoords = maxTop;
  } else {
    if (focusTop - minTop < minTop) {
      translateYCoords = 0;
    } else {
      translateYCoords = focusTop - minTop;
    }
  }

  let translateXCoords: number;

  if (focusLeft < minLeft) {
    translateXCoords = 0;
  } else {
    if (focusLeft - minLeft > maxLeft) {
      translateXCoords = maxLeft;
    } else {
      translateXCoords = focusLeft - minLeft;
    }
  }

  ref.current.style.transform = `translateY(-${translateYCoords}px) translateX(-${translateXCoords}px)`;
};

/**
 * Check TV platform support for the app
 * @param { Array } platformSupport Array of platform names
 * @returns { boolean }
 * */

export const checkTvSupport = (platformSupport: string[]): boolean => {
  const platformToLowerCase = platformSupport.reduce((acc, cur) => {
    return [...acc, cur.toLowerCase()];
  }, [] as string[]);

  return platformToLowerCase.includes('tv');
};

/**
 * Dispatch "Back" event, that handles in useReturnBack hook
 **/

export const dispatchBackEvent = () => {
  document.dispatchEvent(new CustomEvent(INTERNAL_BACK_EVENT_NAME));
};

/**
 * Dispatch the "RB" gamepad event
 **/

export const dispatchRBEvent = () => {
  document.dispatchEvent(new CustomEvent(INTERNAL_RB_EVENT_NAME));
};

/**
 * Dispatch the "RB" gamepad event
 **/

export const dispatchLBEvent = () => {
  document.dispatchEvent(new CustomEvent(INTERNAL_LB_EVENT_NAME));
};

/**
 * Dispatch "Enter" event
 **/

export const dispatchEnterEvent = () => {
  document.dispatchEvent(
    new KeyboardEvent('keydown', {
      keyCode: 13,
      key: 'Enter',
      code: 'Enter',
      bubbles: true,
      cancelable: true,
    })
  );
};

/**
 * Converts number to string with ordinal suffix
 * @param { number } i number to convert
 * @returns { string } with ordinal suffix
 * */

export const ordinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;

  if (i === 1) {
    return 'First';
  }
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

/**
 * @param {string} string string to convert to PascalCase
 * @returns {string} converted to PascalCase
 * */

export const toPascalCase = (string: string) => startCase(camelCase(string)).replace(/ /g, '');

/**
 * Determines what is current platform
 * */

export enum Platform {
  WebOS = 'webos',
  Tizen = 'tizen',
  Vidaa = 'Vidaa',
  AndroidTV = 'AndroidTV',
}

export const checkCurrentPlatform = (): Platform => {
  if (window?.webOS) {
    return Platform.WebOS;
  } else if (window?.tizen) {
    return Platform.Tizen;
  } else if (window.navigator.userAgent.includes('VIDAA')) {
    return Platform.Vidaa;
  } else if (window.cordova) {
    return Platform.AndroidTV;
  } else {
    return null;
  }
};

/**
 * Gets path to the TV bundle
 * @returns {string} path to the bundle on TV
 * */

export const pathTv: string = window?.pathTV || '';

/**
 * Converts bytes to megabytes
 * @returns {string} megabytes value
 * */
export const bytesToMegaBytes = (bytes, decimals = 1) => Number((bytes / 1024 ** 2).toFixed(decimals));

/**
 * Converts bits per second to Megabits per second.
 *
 * @param {number} bps - The number of bits per second.
 * @param {number} decimals - The number of decimal places in the output.
 * @return {number} The speed in Megabits per second.
 */
export const bpsToMbps = (bps, decimals = 1) => Number((bps / 1000000).toFixed(decimals));

/**
 * Converts seconds to m:ss format (untrimmed)
 * */
export const secToTime = (sec: number) => {
  const duration = moment.duration(sec, 'seconds');

  return (duration as any).format('m:ss', { trim: false });
};

export const registerTizenKeys = () => {
  const platform = checkCurrentPlatform();
  if (platform === Platform.Tizen) {
    window.tizen.tvinputdevice.registerKeyBatch(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'MediaPlayPause']);
  }
};

export function getCurrentLanguage(): string {
  const shortLangCode = navigator.language.split('-')[0];
  return languages[shortLangCode]?.name;
}

export const isFileProtocol = location.protocol === 'file:';

export const joinLocation = (data: string[]): string => data?.join(' - ');

export function randomGaussian(mean: number, stdDev: number) {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random();
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  num = num * stdDev + mean;
  return num;
}

export function getCurrentFont(element: Element) {
  const computedStyle = window.getComputedStyle(element);

  const fontSize = computedStyle.getPropertyValue('font-size');
  const fontFamily = computedStyle.getPropertyValue('font-family');
  const fontWeight = computedStyle.getPropertyValue('font-weight');
  const fontStyle = computedStyle.getPropertyValue('font-style');

  return { fontFamily, fontStyle, fontSize, fontWeight };
}

export function getTextWidth(text: string, element: Element) {
  const { fontSize, fontFamily } = getCurrentFont(element);

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) return null;

  // Set the font styles
  context.font = `${fontSize} ${fontFamily}`;

  // Measure the text width
  const metrics = context.measureText(text);
  return metrics.width;
}
export const getCurrentVersion = () => window.currentVersion || process.env.CURRENT_VERSION;

export const isDevelopment = process.env.NX_MODE === 'development';
export const isTest = process.env.NX_MODE === 'test';

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const openNewWindowInCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  if (window.focus) newWindow.focus();

  return newWindow;
};

export function getFontColor(backgroundColor: string): string {
  // Calculate the brightness of the background color
  const color = backgroundColor.substring(1); // strip #
  const rgb = parseInt(color, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Set font color based on brightness
  return brightness < 128 ? 'white' : 'black';
}

export const requestFullScreenAndPointer = async () => {
  try {
    if (!isSafari) {
      await (document as any).body.requestPointerLock?.({ unadjustedMovement: true });
    }
    await document.body.requestFullscreen?.({ navigationUI: 'hide' });
  } catch (e) {
    console.log(e);
  }
};

export const setTestId = (ref: React.MutableRefObject<HTMLElement>, type?: string) => {
  if (!ref.current) {
    return;
  }

  const name = ref.current?.tagName.toLowerCase();
  const elType = type ? `${type}-` : '';
  const elText = murmur2(ref.current.innerText || (ref.current.firstChild as HTMLImageElement)?.src || '');

  ref.current.setAttribute('data-testid', `${name}${(elType || elText) && '__'}${elType + elText}`);
};

export const isTouchableDevice = 'ontouchstart' in window;

export function getBrowserData(): {
  client: string;
  clientVersion: string;
  os: string;
  osVersion: string;
  model: string;
  type: 'pc' | 'mobile' | 'tv';
} {
  const userAgent = navigator.userAgent;
  let client = 'Unknown';
  let clientVersion = 'Unknown';
  let os = 'Unknown';
  let osVersion = 'Unknown';
  const model = 'Unknown';
  let type: 'pc' | 'mobile' = 'pc'; // Default to 'pc'

  // Detecting the client (Browser)
  if (/chrome|chromium|crios/i.test(userAgent)) {
    client = 'Chrome';
  } else if (/firefox|fxios/i.test(userAgent)) {
    client = 'Firefox';
  } else if (/safari/i.test(userAgent) && !/chrome|chromium|crios/i.test(userAgent)) {
    client = 'Safari';
  } else if (/msie|trident/i.test(userAgent)) {
    client = 'Internet Explorer';
  } else if (/edg/i.test(userAgent)) {
    client = 'Edge';
  }

  // Extracting the version number for the detected browser
  const versionRegex = new RegExp(client + '[\\/\\s]([\\d\\.]+)', 'i');
  const versionMatch = userAgent.match(versionRegex);
  clientVersion = versionMatch ? versionMatch[1] : 'Unknown';

  // Detecting the OS
  if (/android/i.test(userAgent)) {
    os = 'Android';
    type = 'mobile';
  } else if (/windows nt/i.test(userAgent)) {
    os = 'Windows';
    const match = userAgent.match(/windows nt (\d+\.\d+)/i);
    osVersion = match ? match[1] : 'Unknown';
  } else if (/mac os/i.test(userAgent)) {
    os = 'MacOS';
    const match = userAgent.match(/mac os x (\d+[._]\d+)/i);
    osVersion = match ? match[1].replace('_', '.') : 'Unknown';
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    os = 'iOS';
    type = 'mobile';
  } else if (/linux/i.test(userAgent)) {
    os = 'Linux';
  }

  // Setting type based on certain keywords
  if (/mobi|android|touch|tablet/i.test(userAgent)) {
    type = 'mobile';
  }
  return { client, os, osVersion, type, clientVersion, model };
}

export const getDeviceTag = (): 'mobile' | 'tv' | '' => {
  const currentTvPlatform = checkCurrentPlatform();

  if (isTouchableDevice && !currentTvPlatform) {
    return 'mobile';
  } else if (currentTvPlatform) {
    return 'tv';
  } else {
    return '';
  }
};
