import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { INTERNAL_BACK_EVENT_NAME } from '@utomik-app-monorepo/constants';
import { useMouseScroll } from '@utomik-app-monorepo/hooks';
import { Achievement, Application, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { AsyncObjectState } from '@utomik-app-monorepo/store';
import { AnalyticControllerContext, DialogQueueContext } from '@utomik-app-monorepo/store';
import { setLimitedTransformValues } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment-duration-format';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AchievementTile } from '../../achievement-tile/achievement-tile';
import { FocusableBackButton } from '../../focusable-back-button/focusable-back-button';
import { FocusableButton } from '../../focusable-button/focusable-button';
import { GameRating } from '../../focusable-rating/game-rating';
import { Loading } from '../../loading/loading';
import { Stepper } from '../../stepper/stepper';
import './focusable-after-game-modal.scss';
type Props = {
  app: Application;
  setFocus?: (id?: string) => void;
};
enum Steps {
  Info,
  Achievements,
}
export const FocusableAfterGameModal: React.FC<Props> = withFocusable()(observer(function FocusableAfterGameModal({
  app,
  navigateByDirection,
  setFocus
}) {
  const analyticController = useContext(AnalyticControllerContext);
  const dialogQueue = useContext(DialogQueueContext);
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [step, setStep] = useState<Steps>(Steps.Info);
  const isCursorVisible = useMouseScroll(scrollableRef, navigateByDirection, true);
  const handleFocus = useCallback(props => {
    setLimitedTransformValues(scrollableRef, props, !isCursorVisible);
  }, []);
  useEffect(() => {
    if (step === Steps.Info) {
      setFocus();
    } else {
      setFocus('ACHIEVEMENT_0');
    }
    dialogQueue.currentDialog.setShowHeader(step === Steps.Info);
    const handleBack = () => {
      if (dialogQueue.currentDialog) {
        dialogQueue.currentDialog.isClosable = step === Steps.Info;
        setStep(Steps.Info);
      }
    };
    document.addEventListener(INTERNAL_BACK_EVENT_NAME, handleBack);
    return () => {
      document.removeEventListener(INTERNAL_BACK_EVENT_NAME, handleBack);
    };
  }, [step]);
  const handleRate = useCallback(rating => {
    analyticController.rateGame({
      rating: String(rating),
      location_on_page: 'Dialog - AfterGame',
      item_name: app.slug,
      location_index: null
    });
    app.rating.setUser(rating);
  }, []);
  if (app.state !== AsyncObjectState.Done) return <div className={'after-game-modal__wrapper'} key={'gameInfo'}>
          <Loading loadingText={''} />
        </div>;
  const achievementsArr: Achievement[] = Array.from(app?.appVersion?.achievements?.newAchievements?.values() || []);
  return <Stepper steps={[<div className={'after-game-modal__wrapper'} key={'gameInfo'}>
            <div className={'main-content-container'}>
              <div className={'image-container'}>
                {!!app.spotlightImages?.deluxeImage?.url && <img decoding={'sync'} src={app.spotlightImages?.deluxeImage?.url} />}
              </div>
              <div className={'info-container'}>
                <div>
                  <p>{t({
              context: 'Shows last play session time',
              message: 'Last play session'
            })}</p>
                  <strong>
                    {(moment.duration(streamViewUtilsService.lastPlaySessionTime, 'seconds') as any).format('h[h] m[m] s[s]')}
                  </strong>
                </div>
                <div>
                  <p>{t({
              context: 'Shows total play time',
              message: 'Total play time'
            })}</p>
                  <strong>
                    {(moment.duration(app?.userStat?.secondsPlayed, 'seconds') as any).format('h[h] m[m] s[s]')}
                  </strong>
                </div>
                {app?.appVersion?.achievements?.newAchievements.size > 0 && <FocusableButton absoluteElement={<div className={'achievements-count__container'}>
                        +{app?.appVersion?.achievements?.newAchievements.size}
                      </div>} isLoading={app.appVersion?.achievements.state === AsyncObjectState.Pending} className={'achievements-button'} type={'secondary'} onEnterPress={() => setStep(Steps.Achievements)} autofocus>
                    {t({
            context: 'Shows newly gathered achievements',
            message: 'New achievements'
          })}{' '}
                    <FontAwesomeIcon className={'trophy-icon'} icon={faTrophy} />
                  </FocusableButton>}
              </div>
            </div>
            <div className={'rating__container'}>
              <GameRating focusKeyPrefix={'after_game_modal_star'} value={app.rating.user} onChange={handleRate} />
            </div>
          </div>, <div className={'achievements-list__container'} key={'achievements'}>
            <FocusableBackButton className={'achievements-list-back-button'} onEnterPress={() => setStep(Steps.Info)} />
            <div className={'scrollable-container'} ref={scrollableRef}>
              {achievementsArr.map((item, idx) => <AchievementTile focusKey={`ACHIEVEMENT_${idx}`} key={item.id} achievement={item} onBecameFocused={handleFocus} isNew={app?.appVersion?.achievements?.newAchievements.has(item.id)} />)}
            </div>
          </div>]} currentStep={step} />;
}));
export default FocusableAfterGameModal;