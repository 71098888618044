export class QuickPanel {
  static readonly APP_ID_QUICK_PANEL = 'com.samsung.tv.quickpanel';
  private readonly _instance: typeof window.tizen;

  private _appControlDataArr;
  private _appControl;

  constructor(instance) {
    this._instance = instance;

    this._init();
  }

  private _init() {
    if (!this._instance) return;

    this._appControlDataArr = [
      new this._instance.ApplicationControlData('DeeplinkType', ['landing']),
      new this._instance.ApplicationControlData('SubType', ['Setting']),
    ];

    this._appControl = new this._instance.ApplicationControl(
      'http://tizen.org/appcontrol/operation/default',
      null,
      null,
      null,
      this._appControlDataArr
    );
  }

  public async launch() {
    return new Promise((resolve, reject) => {
      if (!this._instance) return reject('No Tizen instance');

      this._instance.application.launchAppControl(
        this._appControl,
        QuickPanel.APP_ID_QUICK_PANEL,
        () => resolve(`Launch success`),
        (e) => reject(`Launch failed: ${e.message}`)
      );
    });
  }
}
