import { POTENTIALLY_RELOADED_STREAM_PAGE_KEY, TIME_DEBOUNCE_TILES } from '@utomik-app-monorepo/constants';
import { Routes } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { joinLocation } from '@utomik-app-monorepo/utils';
import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { Application } from '../../../dataStore/stores/applicationStore/application';
import { ApplicationStore } from '../../../dataStore/stores/applicationStore/applicationStore';
import { LogEventAction, LogEventType } from '../../../dataStore/stores/logsStore/interfaces';
import { LogsController } from '../../../dataStore/stores/logsStore/logsController';
import { ObjectID } from '../../../dataStore/stores/objectStore/asyncObject';
import { AnalyticController } from '../analyticController/analyticController';
import { ClientController } from '../clientController/clientController';
import { DialogFactory } from '../dialogFactory/dialogFactory';
import { DialogQueue, DialogResult } from '../dialogQueue/dialogQueue';
import { LongPressButtonService } from '../streamViewUtilsService/longPressButtonService/longPressButtonService';
import { StreamViewUtilsService } from '../streamViewUtilsService/streamViewUtilsService';

export enum ControllerActions {
  Up = 'up',
  Down = 'down',
  Left = 'left',
  Right = 'right',
}

export enum TileType {
  Regular = 'regular',
  Channels = 'channels',
  Channel = 'channel',
  ChannelHome = 'channel-home',
  ChannelSearch = 'channel-search',
  Spotlight = 'spotlight',
  Small = 'small',
  News = 'news',
  SeeAll = 'seeall',
  Select = 'select',
}

export enum InteractionType {
  Gamepad = 'gamepad',
  Mouse = 'mouse',
  Touch = 'touch',
}

export class NavigationController {
  private readonly _autorunDisposer: ReturnType<typeof autorun>;
  private readonly _longPressButtonService: LongPressButtonService;
  private _clientController: ClientController;
  private _dialogFactory: DialogFactory;
  private _applicationStore: ApplicationStore;
  private _dialogQueue: DialogQueue;
  private _analyticController: AnalyticController;
  private _logsController: LogsController;
  private _streamViewUtilsService: StreamViewUtilsService;

  @observable
  private _currentID: ObjectID;
  @observable
  private _currentSection: string;
  @observable
  private _isGamepadConnected: boolean;
  @observable
  private _sectionNames: string[] = [];
  @observable
  private _currentApp: Application;
  @observable
  private _currentInteraction: InteractionType;
  private _currentTileType: TileType;
  private _allowedPlayPress: boolean;
  private _gameRunOrigin: string;
  private _currentIdx: number;

  private _timeoutId: ReturnType<typeof setTimeout>;

  public history = {
    entries: [],
    push(route: string) {
      this.entries.push(route);
    },
    pop() {
      this.entries.pop();
    },
    get length() {
      return this.entries.length;
    },
  };

  constructor(
    clientController: ClientController,
    dialogFactory: DialogFactory,
    streamViewUtilsService: StreamViewUtilsService,
    applicationStore: ApplicationStore,
    dialogQueue: DialogQueue,
    analyticController: AnalyticController,
    logsController: LogsController
  ) {
    this._clientController = clientController;
    this._dialogFactory = dialogFactory;
    this._streamViewUtilsService = streamViewUtilsService;
    this._applicationStore = applicationStore;
    this._dialogQueue = dialogQueue;
    this._analyticController = analyticController;
    this._logsController = logsController;
    this._longPressButtonService = new LongPressButtonService(dialogFactory, dialogQueue, streamViewUtilsService);

    makeObservable(this);

    this._autorunDisposer = autorun(() => {
      this.clearChangeTimeout();

      if (!this.currentId || typeof this.currentId !== 'number') {
        return;
      }

      if (!this._currentApp) {
        this.setCurrentApp();
      } else {
        this._timeoutId = setTimeout(() => {
          this.setCurrentApp();
        }, TIME_DEBOUNCE_TILES);
      }
    });

    window.addEventListener('gamepadconnected', this._setGamepadConnected);
    window.addEventListener('gamepaddisconnected', this._setGamepadDisconnected);
    window.addEventListener('mousemove', this.setInteractionMode.bind(this, InteractionType.Mouse), false);
    window.addEventListener('touchstart', this.setInteractionMode.bind(this, InteractionType.Touch), false);
  }

  public clearChangeTimeout() {
    clearTimeout(this._timeoutId);
  }

  @computed
  public get currentApp(): Application {
    return this._currentApp;
  }

  @action
  public setCurrentApp() {
    this._currentApp = this._applicationStore.getItem(this.currentId);
  }

  @action
  private _clearCurrentApp() {
    this._currentApp = null;
  }

  @computed
  public get currentId() {
    return this._currentID;
  }

  @computed
  public get isGamepadConnected() {
    return this._isGamepadConnected;
  }

  @computed
  public get longPressButtonService() {
    return this._longPressButtonService;
  }

  @action
  private _setGamepadConnected = (e) => {
    //check if it is not the onScreen gamepad
    if (!e.detail?.gamepad) {
      this._isGamepadConnected = true;
      //hide the onscreen gamepad
      this._streamViewUtilsService.setOnScreenGamepadVisible(false);
    }
    log('GAMEPAD CONNECTED');

    this._logsController.addNewLogEvent({
      type: LogEventType.Gamepad,
      action: LogEventAction.Connect,
      value: e.gamepad?.id || e.detail?.gamepad?.id,
    });
  };
  @action
  private _setGamepadDisconnected = (e) => {
    const gamepads = navigator.getGamepads();

    let isEmpty = true;

    for (const gamepad of gamepads) {
      if (gamepad) {
        isEmpty = false;
      }
    }

    log('GAMEPAD DISCONNECTED');

    this._logsController.addNewLogEvent({
      type: LogEventType.Gamepad,
      action: LogEventAction.Disconnect,
      value: e.gamepad?.id || e.detail?.gamepad?.id,
    });

    if (isEmpty) {
      this._isGamepadConnected = false;
      this.setInteractionMode(InteractionType.Touch);
      return;
    }
  };
  @action
  public setCurrentId(id: ObjectID, type = TileType.Regular) {
    this.setCurrentTileType(type);

    this._currentID = id;
  }

  @computed
  public get currentSection() {
    return this._currentSection;
  }
  @action
  public setCurrentSection(name: string) {
    this._currentSection = name;
  }
  @computed
  public get sectionNames() {
    return this._sectionNames;
  }
  @action
  public setSectionNames(names: string[]) {
    this._sectionNames = names;
  }
  public get currentTileType() {
    return this._currentTileType;
  }

  public setCurrentIdx(idx: number) {
    this._currentIdx = idx;
  }
  public setCurrentTileType(type: TileType) {
    this._currentTileType = type;
  }

  public getIsAllowedPlayPress() {
    return this._allowedPlayPress;
  }

  public setAllowedPlayPress(allowed: boolean) {
    this._allowedPlayPress = allowed;
  }

  public get gameRunOrigin() {
    return this._gameRunOrigin;
  }

  public setGameRunOrigin(value: string) {
    this._gameRunOrigin = value;
  }

  private _redirectToStream(navigate: NavigateFunction) {
    this._dialogQueue.clear();

    navigate(`${Routes.GameRun}/${this._currentID}`, {
      replace: this.gameRunOrigin?.includes(Routes.AppInfo) && !this.currentSection?.includes('Related'),
    });
  }

  public async playGame(navigate: NavigateFunction, locationOnPage?: string[]) {
    window.sessionStorage.removeItem(POTENTIALLY_RELOADED_STREAM_PAGE_KEY);
    // Check if allowed to press the play button
    if (
      !this._allowedPlayPress ||
      (this._dialogQueue.hasDialogs && this._dialogQueue.currentDialog?.analyticName !== 'GatewaysSelectDialog')
    )
      return;
    //Check if current ID is not empty and is game ID
    if (!this._currentID || typeof this._currentID !== 'number') return console.error('No app ID has been set');

    const app = this._applicationStore.getItem(this._currentID);
    // Check if allowed to play the game
    if (
      !this._clientController.user.isTesterOrAdmin &&
      (!app?.isAllowedToPlay || !this._clientController.user.canPlay)
    ) {
      return log('This game is not allowed to play');
    }

    this._gameRunOrigin = location.hash;

    this.setAllowedPlayPress(false);

    if (this._dialogQueue.currentDialog?.analyticName === 'GatewaysSelectDialog') {
      return this._redirectToStream(navigate);
    }

    if (this._clientController.user.isTesterOrAdmin) {
      const res = await this._dialogFactory.showGatewaysSelectionDialog(app);

      if (res === DialogResult.Cancel) {
        return this._streamViewUtilsService.dispose();
      }
    } else if (!this.isGamepadConnected) {
      const res = await this._dialogFactory.showRequireGamepadDialog(0);

      if (res !== DialogResult.OK) return;
    }

    const breadCrumbs = this._analyticController.getBreadCrumbs({
      currentSection: this._currentSection,
    });

    this._analyticController.playGame({
      item_name: app.slug,
      location_on_page: joinLocation(locationOnPage || breadCrumbs),
      location_index: String(this._currentIdx) || undefined,
    });

    this.setCurrentIdx(null);

    this._redirectToStream(navigate);
  }
  @computed
  public get isGamepadInteraction() {
    return this._currentInteraction === InteractionType.Gamepad;
  }
  @computed
  public get isMouseInteraction() {
    return this._currentInteraction === InteractionType.Mouse;
  }
  @computed
  public get isTouchInteraction() {
    return this._currentInteraction === InteractionType.Touch;
  }
  @action
  public setInteractionMode(mode: InteractionType) {
    this._currentInteraction = mode;
  }

  public reset = () => {
    this.setGameRunOrigin(null);
    this.setCurrentId(null);
    this.setCurrentTileType(null);
    this.setCurrentSection(null);
    this.setSectionNames([]);
    this._clearCurrentApp();
    this.setAllowedPlayPress(false);
    this.setCurrentIdx(null);

    clearTimeout(this._timeoutId);

    return true;
  };

  public dispose() {
    this.reset();

    window.removeEventListener('gamepadconnected', this._setGamepadConnected);
    window.removeEventListener('gamepaddisconnected', this._setGamepadDisconnected);
    this._autorunDisposer();
  }
}
