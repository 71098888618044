import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { action, computed, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../../requestQueue/requestQueue';
import { AsyncObjectState, ObjectID } from '../../objectStore/asyncObject';
import { AchievementProgressStore } from './achievementprogressStore';
import { Achievementschema } from './achievementschema';

export interface AchievementGroup {
  title: string;
  translate: boolean;
  achievements: Achievement[];
}

export interface Achievement {
  id: ObjectID;
  name: string;
  group: string;
  description: string;
  hidden: boolean;
  values: {
    min: number;
    max: number;
    value: number;
    unlockedAt: Date;
  };
  images: {
    locked: string;
    unlocked: string;
  };
}

export enum SortOptions {
  ByUnlocked,
  ByAZ,
  ByGroup,
}

export class AchievementStore {
  private readonly _achievementProgressStore: AchievementProgressStore;
  private readonly _achievementschema: Achievementschema;
  @observable
  private _newAchievements: Map<ObjectID, Achievement> = new Map();

  public constructor(applicationId: ObjectID, versionId: ObjectID, requestQueue: RequestQueue) {
    makeObservable(this);
    this._achievementProgressStore = new AchievementProgressStore(
      requestQueue,
      `v2/users/me/applications/${applicationId}/versions/live/achievementprogress`
    );
    this._achievementschema = new Achievementschema(requestQueue, applicationId, versionId);
  }

  /**
   * TODO:
   * This part kinda mimics the "fetch" of an async object, but not quite.
   * It combines the result from 2 fetches from 2 different async objects.
   * Not sure if this is the best way to go about this, as it doesn't respond
   * in the same fashion to doing a second fetch for instance.
   * It is not so much a datastore, as more of a "combined" datastore.
   * Perhaps this would be better as a different API endpoint ?
   * Currently refactoring datastores. Seing as this is a bit of a weird one, I will leave it for now. - Roel
   */
  @observable
  private _state = AsyncObjectState.None;
  @computed
  public get state(): AsyncObjectState {
    return this._state;
  }
  @action
  private setState(state: AsyncObjectState): void {
    this._state = state;
  }

  public async fetch(): Promise<void> {
    if (this.state === AsyncObjectState.Done) {
      return;
    }
    this.setState(AsyncObjectState.Pending);
    try {
      await Promise.all([this._achievementschema.fetch(), this._achievementProgressStore.fetch()]);
      this.setState(AsyncObjectState.Done);
    } catch (ex) {
      console.error(`fetch() failed with reason: ${JSON.stringify(ex)}`);
      this.setState(AsyncObjectState.Error);
      throw ex;
    }
    this.loadItems();
  }

  public async updateProgress(): Promise<void> {
    if (this.state !== AsyncObjectState.Done) {
      await this.fetch();
    } else {
      this._setNewAchievements([]);

      const oldItems: Achievement[] = this.achievementGroupSortedByUnlockedRecently.achievements.filter(
        (item) => !!item.values.unlockedAt
      );

      this.setState(AsyncObjectState.Pending);
      try {
        await this._achievementschema.fetch();
        await this._achievementProgressStore.reFetch();
        this.setState(AsyncObjectState.Done);
      } catch (ex) {
        console.error(`reFetch() failed with reason: ${JSON.stringify(ex)}`);
        this.setState(AsyncObjectState.Error);
        return;
      }
      this.loadItems();

      const newItems: Achievement[] = this.achievementGroupSortedByUnlockedRecently.achievements.filter(
        (item) => !!item.values.unlockedAt
      );

      this._setNewAchievements(newItems.filter((item) => !oldItems.find((old) => old.id === item.id)));
    }
  }

  @observable
  private _items: Achievement[] = [];
  @computed
  public get items(): Achievement[] {
    return this._items;
  }

  @action
  public loadItems(): void {
    if ((this._achievementschema?.achievementversions?.length || 0) == 0) {
      this._items = [];
      return;
    }
    this._items = this._achievementschema?.achievementversions?.map((schema): Achievement => {
      const progress = this._achievementProgressStore.items.find((item) => {
        return item.id === schema.achievementId;
      });
      return {
        id: schema.id,
        name: schema.name,
        group: schema.group,
        description: schema.description,
        hidden: schema.hidden,
        values: {
          min: schema.values.min,
          max: schema.values.max,
          value: progress.statisticValue,
          unlockedAt: progress.unlockedAt,
        },
        images: {
          unlocked: schema.images.unlocked,
          locked: schema.images.locked,
        },
      };
    });
  }

  @computed
  public get newAchievements() {
    return this._newAchievements;
  }
  @action
  public clearNewAchievements() {
    this._setNewAchievements([]);
  }
  @action
  private _setNewAchievements(ach: Achievement[]) {
    const map = new Map();

    ach.forEach((a) => {
      map.set(a.id, a);
    });

    this._newAchievements = map;
  }

  @computed
  public get achievementsNotHidden(): Achievement[] {
    return this.items.filter((x) => {
      return !(x.hidden && isNullOrUndefined(x.values.unlockedAt));
    });
  }

  @computed
  public get achievementsUnlockedCount(): number {
    return this.items.filter((x) => {
      return !isNullOrUndefined(x.values.unlockedAt);
    }).length;
  }

  @computed
  public get achievementsCount(): number {
    return this.items.length;
  }

  @computed
  public get achievementsProgress(): number {
    return (this.achievementsUnlockedCount / this.achievementsCount) * 100;
  }

  @computed
  public get hasMultipleGroups(): boolean {
    if (this.items.length == 0) return false;
    const group = !this.items[0].group ? `ACHIEVEMENTS_NO_ACHIEVEMENT_GROUP_TITLE` : this.items[0].group;

    let hasMultiple = false;
    this.items.forEach((item) => {
      const itemGroup = !item.group ? `ACHIEVEMENTS_NO_ACHIEVEMENT_GROUP_TITLE` : item.group;
      if (itemGroup != group) hasMultiple = true;
    });
    return hasMultiple;
  }

  @computed public get achievementGroupSortedByUnlocked(): AchievementGroup[] {
    const unlockedGroups = groupBy(this.items, (x) => {
      let unlocked: boolean;
      const xUnlocked = !isNullOrUndefined(x.values.unlockedAt);

      forEach(this.items, (y) => {
        const yUnlocked = !isNullOrUndefined(y.values.unlockedAt);
        if (xUnlocked === yUnlocked) {
          unlocked = xUnlocked;
          return false;
        }
        return true;
      });
      return unlocked;
    });
    const groups: AchievementGroup[] = [];
    const unlockedGroup = unlockedGroups['true'] || [];
    if (unlockedGroup.length > 0)
      groups.push({
        title: 'ACHIEVEMENTS_UNLOCKED_ACHIEVEMENTS',
        translate: true,
        achievements: orderBy(
          unlockedGroup,
          [
            (achievement: Achievement): number => {
              return achievement.values.unlockedAt?.getTime() || 0;
            },
          ],
          ['desc']
        ),
      });
    const lockedGroup = unlockedGroups['false'] || [];
    if (lockedGroup.length > 0)
      groups.push({
        title: 'ACHIEVEMENTS_LOCKED_ACHIEVEMENTS',
        translate: true,
        achievements: lockedGroup,
      });
    return groups;
  }

  @computed
  public get achievementGroupSortedByUnlockedRecently(): AchievementGroup {
    return {
      title: 'ACHIEVEMENTS_ALL_ACHIEVEMENTS',
      translate: true,
      achievements: orderBy(
        this.achievementsNotHidden,
        [
          (achievement: Achievement): number => {
            return achievement.values.unlockedAt?.getTime() || 0;
          },
        ],
        ['desc']
      ),
    };
  }

  @computed
  public get achievementGroupSortedByAZ(): AchievementGroup[] {
    return [
      {
        title: 'ACHIEVEMENTS_ALL_ACHIEVEMENTS',
        translate: true,
        achievements: sortBy(this.items, [
          (achievement: Achievement): string => {
            return achievement.name.toLowerCase();
          },
        ]),
      },
    ];
  }

  @computed
  public get achievementGroupSortedByGroup(): AchievementGroup[] {
    const grouped = groupBy(this.items, (x) => {
      let group: string;
      forEach(this.items, (y) => {
        if (x.group === y.group) {
          if (!x.group) group = `ACHIEVEMENTS_NO_ACHIEVEMENT_GROUP_TITLE`;
          else group = x.group;
          return false;
        }
        return true;
      });
      return group;
    });
    const groups: AchievementGroup[] = [];
    for (const key in grouped) {
      groups.push({
        title: key,
        translate: key === `ACHIEVEMENTS_NO_ACHIEVEMENT_GROUP_TITLE`,
        achievements: grouped[key],
      });
    }

    return orderBy(
      groups,
      [
        (group: AchievementGroup): boolean => {
          return group.translate;
        },
      ],
      ['asc']
    );
  }

  @computed
  public get achievementGroupSortedByDefault(): AchievementGroup[] {
    return [
      {
        title: 'ACHIEVEMENTS_ALL_ACHIEVEMENTS',
        translate: true,
        achievements: this.items,
      },
    ];
  }
}
