import { initNavigation } from '@noriginmedia/react-spatial-navigation';
import { WebNavigationControls } from '@utomik-app-monorepo/components';
import { useSentryInit } from '@utomik-app-monorepo/hooks';
import { useAnalyticsSendView, useConnectionMonitor, useCustomHistory, useFeaturedItemsAnalytics } from '@utomik-app-monorepo/hooks';
import { ConnectionMonitorContext, LoaderContext, TokenManagerContext } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { DialogHost } from './dialog-host/dialog-host';
import { NotificationHost } from './notification-host/notification-host';
import { Authorization } from './views/authorization/authorization';
import { Loading } from './views/loading/loading';
export const App: React.FC<React.PropsWithChildren> = observer(() => {
  const tokenManager = useContext(TokenManagerContext);
  const loader = useContext(LoaderContext);
  const connectionMonitor = useContext(ConnectionMonitorContext);

  //useAuthWatcher(); // for SSO
  useCustomHistory();
  useAnalyticsSendView();
  useFeaturedItemsAnalytics();
  useConnectionMonitor();
  useSentryInit({
    dsn: 'https://9227673c3264443a8e1c3031f5099409@sentry.light-it.tools/279'
  });
  useEffect(() => {
    /**
     * Init and prefetch data
     */
    (async () => {
      await loader.unauthorizedPreload();
      tokenManager.init();
    })();
    initNavigation();

    //clear listeners
    return () => {
      connectionMonitor.connectivityReporter.dispose();
    };
  }, []);
  let activeComponent: React.ReactElement;
  if (!tokenManager.isInitialized) {
    activeComponent = <Loading />;
  } else if (!tokenManager.isAuthorized) {
    activeComponent = <Authorization />;
  } else if (!loader.isFinished) {
    activeComponent = <Loading />;
  } else {
    activeComponent = <Outlet />;
  }
  return <WebNavigationControls>
      <div className={'app-root'}>
        {activeComponent}
        <DialogHost />
        <NotificationHost />
      </div>
    </WebNavigationControls>;
});
export default App;