import { ConnectionMonitorContext, DialogFactoryContext, DialogQueueContext, DialogResult, RequestPriority } from '@utomik-app-monorepo/store';
import React, { useEffect, useRef } from 'react';
const showPriorities = [RequestPriority.High, RequestPriority.Critical];
export const useConnectionMonitor = () => {
  const isShowingDialog = useRef(false);
  const connectionMonitor = React.useContext(ConnectionMonitorContext);
  const dialogFactory = React.useContext(DialogFactoryContext);
  const dialogQueue = React.useContext(DialogQueueContext);
  const showDialog = async () => {
    const res = await dialogFactory.showFailedRequestsDialog();
    if (res === DialogResult.OK) {
      connectionMonitor.requestQueue.retryFailedRequests();
    } else if (res === DialogResult.Cancel) {
      connectionMonitor.requestQueue.clearFailedRequests();
    }
    isShowingDialog.current = false;
  };
  useEffect(() => {
    if (connectionMonitor.failedRequestsQty === 0) return;
    const show = connectionMonitor.requestQueue.failedRequests.some(request => showPriorities.includes(request.priority));
    if (show && !isShowingDialog.current) {
      isShowingDialog.current = true;
      showDialog();
    }
  }, [connectionMonitor.failedRequestsQty, dialogQueue.currentDialog?.analyticName]);
};