import { observer } from 'mobx-react';
import React from 'react';
import './progress-circle.scss';
export interface CirclePathProps {
  progress: number;
  className: string;
  strokeMargin: number;
}
const CirclePath: React.FC<CirclePathProps> = observer(function CirclePath({
  progress,
  className,
  strokeMargin
}): React.ReactElement {
  const strokeRadius = 50 - 0.5 * strokeMargin;
  const circumference = strokeRadius * 2 * Math.PI;
  const p = Math.min(1, Math.max(0, progress));
  return <path style={{
    ...{
      strokeDasharray: `${circumference}px`,
      strokeDashoffset: circumference * (1 - p)
    }
  }} className={className} fill={'none'} d={`M 50 50
      m 0,-${strokeRadius}
      a ${strokeRadius},${strokeRadius} 0 1 1 0,${2 * strokeRadius}
      a ${strokeRadius},${strokeRadius} 0 1 1 0,-${2 * strokeRadius}`} />;
});
export default CirclePath;