import * as Yup from 'yup';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { AsyncObjectState, ClientControllerContext, DialogProps, DialogResult, UtomikError } from '@utomik-app-monorepo/store';
import { convertToHTTPRange, isNullOrUndefined, parseMinutesFromThrottledError } from '@utomik-app-monorepo/utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { isValidDateTest, not18YearsOldTest } from '../../complete-profile-form/complete-profile-form-helper';
import { FocusableButton } from '../../focusable-button/focusable-button';
import { FocusableDatePicker } from '../../focusable-date-picker/focusable-date-picker';
import { FocusableSelect } from '../../focusable-select/focusable-select';
import { Loading } from '../../loading/loading';
import { TextInputField } from '../../text-input-field/text-input-field';
import './edit-profile-dialog.scss';
const EditProfileForm: React.FC<DialogProps> = observer(({
  action
}) => {
  const errorRef = useRef<{
    message: string;
    minutes?: number;
  }>(null);
  const clientController = useContext(ClientControllerContext);
  const {
    i18n
  } = useLingui();
  const handleError = (error: UtomikError): void => {
    const errorMessage = error.message;
    let statusCodeOrRange: number;
    if (!isNullOrUndefined(error.statusCode && error.statusCode !== 429)) {
      statusCodeOrRange = convertToHTTPRange(error.statusCode);
    }
    console.error(`CompleteProfileForm.onSubmit() exception. (Reason: ${errorMessage})`);
    switch (errorMessage) {
      case 'REQUEST_TIMED_OUT':
        errorRef.current = {
          message: i18n.t({
            comment: 'Input field error',
            message: 'A timeout occurred while trying to submit your profile information. Please try again later.',
            id: 'A timeout occurred while trying to submit your profile information. Please try again later.'
          })
        };
        return;
      case 'Revoked token.':
        errorRef.current = {
          message: i18n.t({
            comment: 'Input field error',
            id: 'Your login token has expired. Please click \u0027Back\u0027 and log in again.',
            message: 'Your login token has expired. Please click \u0027Back\u0027 and log in again.'
          })
        };
        return;
      case 'USER_NAME_TAKEN':
        errorRef.current = {
          message: i18n.t({
            comment: 'Input field error',
            message: 'Another user already has this username.',
            id: 'Another user already has this username.'
          })
        };
        return;
      case 'EMAIL_TAKEN':
        errorRef.current = {
          message: i18n.t({
            comment: 'Input field error',
            message: 'This email address is already in use.',
            id: 'This email address is already in use.'
          })
        };
        return;
      default:
        switch (statusCodeOrRange) {
          case 429:
            {
              // Show a message for too many requests, for the user that likes to brute force this.
              const minutes = parseMinutesFromThrottledError(errorMessage);
              errorRef.current = {
                message: i18n.t({
                  id: 'Too many requests. Please try again in {minutes} minutes.',
                  message: 'Too many requests. Please try again in {minutes} minutes.',
                  comment: 'Input field error',
                  values: {
                    minutes
                  }
                })
              };
              return;
            }
          case 400:
            errorRef.current = {
              message: i18n.t({
                comment: 'Input field error',
                message: 'Something went wrong. Please contact support.',
                id: 'Something went wrong. Please contact support.'
              })
            };
            return;
          case 500:
            errorRef.current = {
              message: i18n.t({
                comment: 'Input field error',
                message: 'Something went wrong. Please try again later.',
                id: 'Something went wrong. Please try again later.'
              })
            };
            return;
          default:
            errorRef.current = {
              message: i18n.t({
                comment: 'Input field error',
                message: 'Unknown error occurred',
                id: 'Unknown error occurred'
              })
            };
            return;
        }
    }
  };
  const initialValues = {
    username: clientController.user?.patchUserValues?.username || '',
    first_name: clientController.user?.patchUserValues?.first_name || '',
    last_name: clientController.user?.patchUserValues?.last_name || '',
    birthdate: clientController.user?.patchUserValues?.birthdate || moment().subtract(18, 'years').format('YYYY-MM-DD'),
    gender: clientController.user?.patchUserValues?.gender || clientController.genders[0].id
  };
  const validationShape = {
    username: Yup.string().required(i18n.t({
      id: '{field} required.',
      comment: 'Input field error',
      message: '{field} required.',
      values: {
        field: i18n.t('Username')
      }
    })).min(3, i18n.t({
      id: '{field} must contain at least {value} characters.',
      message: '{field} must contain at least {value} characters.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Username'),
        value: 3 .toLocaleString(i18n.locale)
      }
    })).max(32, i18n.t({
      id: '{field} must contain max {value} characters.',
      message: '{field} must contain max {value} characters.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Username'),
        value: 32 .toLocaleString(i18n.locale)
      }
    })).matches(/^[a-zA-Z0-9]+$/, i18n.t({
      id: '{field} may contain letters (uppercase or lowercase a-z) and numbers (0-9).',
      message: '{field} may contain letters (uppercase or lowercase a-z) and numbers (0-9).',
      comment: 'Input field error',
      values: {
        field: i18n.t('Username')
      }
    })).test('username', i18n.t({
      comment: 'Input field error',
      message: 'Another user already has this username.',
      id: 'Another user already has this username.'
    }), () => errorRef.current?.message !== 'Another user already has this username.'),
    first_name: Yup.string().required(i18n.t({
      id: '{field} required.',
      comment: 'Input field error',
      message: '{field} required.',
      values: {
        field: i18n.t('First name')
      }
    })).min(2, i18n.t({
      id: '{field} must contain at least {value} characters.',
      message: '{field} must contain at least {value} characters.',
      comment: 'Input field error',
      values: {
        field: i18n.t('First name'),
        value: 2 .toLocaleString(i18n.locale)
      }
    })).max(32, i18n.t({
      id: '{field} must contain max {value} characters.',
      message: '{field} must contain max {value} characters.',
      comment: 'Input field error',
      values: {
        field: i18n.t('First name'),
        value: 32 .toLocaleString(i18n.locale)
      }
    })).matches(/^[a-zA-Z0-9]+$/, i18n.t({
      id: '{field} may contain letters (uppercase or lowercase a-z) and numbers (0-9).',
      message: '{field} may contain letters (uppercase or lowercase a-z) and numbers (0-9).',
      comment: 'Input field error',
      values: {
        field: i18n.t('First name')
      }
    })),
    last_name: Yup.string().required(i18n.t({
      id: '{field} required.',
      comment: 'Input field error',
      message: '{field} required.',
      values: {
        field: i18n.t('Last name')
      }
    })).min(2, i18n.t({
      id: '{field} must contain at least {value} characters.',
      message: '{field} must contain at least {value} characters.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Last name'),
        value: 2 .toLocaleString(i18n.locale)
      }
    })).max(32, i18n.t({
      id: '{field} must contain max {value} characters.',
      message: '{field} must contain max {value} characters.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Last name'),
        value: 32 .toLocaleString(i18n.locale)
      }
    })).matches(/^[a-zA-Z0-9]+$/, i18n.t({
      id: '{field} may contain letters (uppercase or lowercase a-z) and numbers (0-9).',
      message: '{field} may contain letters (uppercase or lowercase a-z) and numbers (0-9).',
      comment: 'Input field error',
      values: {
        field: i18n.t('Last name')
      }
    })),
    birthdate: Yup.string().required(i18n.t({
      id: '{field} required.',
      comment: 'Input field error',
      message: '{field} required.',
      values: {
        field: i18n.t('Date of Birth')
      }
    })).test('birthdate', i18n.t({
      id: '{field} is not valid.',
      message: '{field} is not valid.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Date of Birth')
      }
    }), isValidDateTest).test('birthdate', i18n.t({
      comment: 'Input field error',
      message: 'You have to be over 18 years old.',
      id: 'You have to be over 18 years old.'
    }), not18YearsOldTest),
    gender: Yup.string()
  };
  const validationSchema = Yup.object().shape(validationShape);
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: async (values, {
      setSubmitting,
      setErrors,
      validateForm
    }): Promise<void> => {
      try {
        setErrors({});
        errorRef.current = undefined;
        setSubmitting(true);

        // Patch the user
        await clientController.user.patchUser(values);
        await clientController.user.reFetch();
        setSubmitting(false);
        action(DialogResult.OK);
      } catch (error: any) {
        handleError(error);
        // we need to validate the form again after an error in patching
        await validateForm();
        setSubmitting(false);
      }
    }
  });
  const currentGender = useMemo(() => {
    return clientController.getGenderById(formik.values.gender) || clientController.genders[0];
  }, [formik.values.gender]);
  const currentDate = useMemo(() => {
    const date = moment(formik.values.birthdate);
    return {
      date: date.date(),
      month: date.month(),
      year: date.year()
    };
  }, [formik.values.birthdate]);
  const handleDateSelectChange = useCallback(date => {
    !formik.touched.birthdate && formik.setFieldTouched('birthdate');
    formik.setFieldValue('birthdate', date);
  }, []);
  const handleGenderSelectChange = useCallback(val => {
    formik.setFieldValue('gender', clientController.getGenderByName(val.name)?.id);
    formik.validateField('gender');
  }, []);
  return <form className={'edit-profile-dialog__form'} onSubmit={formik.handleSubmit} onChange={() => errorRef.current = undefined}>
      <TextInputField className={'edit-profile-dialog__input-container'} width={'fullwidth'} placeholder={i18n.t('Username')} type={'text'} name={'username'} icon={<FontAwesomeIcon icon={'user'} />} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.username} errorMessage={formik.errors.username} />
      <TextInputField className={'edit-profile-dialog__input-container'} width={'fullwidth'} placeholder={i18n.t('First name')} type={'text'} name={'first_name'} icon={<FontAwesomeIcon icon={'id-card'} />} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.first_name} errorMessage={formik.errors.first_name} />
      <TextInputField className={'edit-profile-dialog__input-container'} width={'fullwidth'} placeholder={i18n.t('Last name')} type={'text'} name={'last_name'} icon={<FontAwesomeIcon icon={'id-card'} />} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.last_name} errorMessage={formik.errors.last_name} />
      <p className={'edit-profile-dialog--selector-name'}>{i18n.t('Date of Birth')}:</p>
      <FocusableDatePicker errorMessage={formik.touched.birthdate && formik.errors.birthdate} currentDate={currentDate} focusable={true} onChange={handleDateSelectChange} scrollType={'native'} />
      <p className={'edit-profile-dialog--selector-name'}>{i18n.t('Gender')}:</p>
      <FocusableSelect width={'wide'} values={clientController.genders} selectedValue={currentGender.name} onChange={handleGenderSelectChange} scrollType={'native'} />
      <div className={'edit-profile-dialog__buttons-container'}>
        <FocusableButton disabled={formik.isSubmitting} onEnterPress={() => action(DialogResult.Cancel)} type={'secondary'}>
          {t({
          message: 'Cancel'
        })}
        </FocusableButton>
        <FocusableButton isLoading={formik.isSubmitting} focusable={!formik.isSubmitting && formik.isValid} role={'submit'} type={'primary'}>
          {t({
          message: 'Save'
        })}
        </FocusableButton>
      </div>
    </form>;
});
export const EditProfileDialog = withFocusable()(observer(({
  action
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<AsyncObjectState>(AsyncObjectState.None);
  const clientController = useContext(ClientControllerContext);
  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    if (!e.target.files[0]) return;
    setState(AsyncObjectState.Pending);
    try {
      await clientController.user.uploadAvatar(e.target.files[0]);
      setState(AsyncObjectState.Done);
    } catch (e: any) {
      setState(AsyncObjectState.Error);
    } finally {
      fileInputRef.current.value = null;
    }
  };
  return <div className={'edit-profile-dialog__container'}>
        <div className={'edit-profile-dialog__avatar-content'}>
          <div className={'edit-profile-dialog__avatar'}>
            <img decoding={'async'} className={'edit-profile-dialog__avatar--image'} src={clientController.user.avatarUrl || require('images/svg/profile_avatar.svg')} />
            <FontAwesomeIcon className={'edit-profile-dialog__avatar--image--edit-icon'} icon={faEdit} />
            <input ref={fileInputRef} onChange={handleFileChange} className={'edit-profile-dialog__avatar--hidden-input-file'} type={'file'} />
            {state === AsyncObjectState.Pending && <Loading classNames={['edit-profile-dialog--loading']} size={'small'} />}
          </div>
          <p className={'edit-profile-dialog--error-message'}>
            {state === AsyncObjectState.Error && t({
          context: 'Error - The file is not acceptable for uploading as an avatar',
          message: `The file is not acceptable`
        })}
          </p>
        </div>
        <EditProfileForm action={action} />
      </div>;
}));
export default EditProfileDialog;