import { faEarthEurope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Steps } from '@utomik-app-monorepo/store';
import { pathTv } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { QRCode } from '../qr-code/qr-code';
export const PhoneUrl = observer(function PhoneUrl({
  url
}) {
  return <div className={'phone__container'}>
      <span className={'on-image-link'}>
        <FontAwesomeIcon icon={faEarthEurope} className="markdown-list-item-icon" />
        {url}
      </span>
      <img decoding={'sync'} className={'phone-image'} src={pathTv + require('images/img/iPhone-14.png')} />
    </div>;
});
type QRCodeWithLinkProps = {
  qrCodeTitle: string;
  qrCodeDescription: string;
  url: string;
  linkTitle: string;
  children?: any;
};
export const QRCodeWithLink: React.FC<QRCodeWithLinkProps> = observer(function QRCodeWIthLink({
  qrCodeTitle,
  qrCodeDescription,
  linkTitle,
  url,
  children
}) {
  return <div className={'support-qr-code__wrapper'} key={Steps.PrivacyPolicy}>
      <UtomikQRCode title={qrCodeTitle} description={qrCodeDescription} url={url} />
      <div className={'or-text'}>or</div>
      <div className={'support-url__wrapper'}>
        <span className={'support-url__container'}>
          <h1>{linkTitle}</h1>
          {url}
        </span>

        <PhoneUrl url={url} />

        {children}
      </div>
    </div>;
});
type UtomikQRCodeProps = {
  title?: string;
  description?: string;
  url: string;
  code?: number;
  statusMessage?: string;
  isLoading?: boolean;
  className?: string;
};
export const UtomikQRCode: React.FC<UtomikQRCodeProps> = observer(function UtomikQRCode({
  title,
  description,
  isLoading,
  url,
  code,
  statusMessage,
  className
}) {
  return <div className={cx('qr-code__container', {
    [className]: !!className
  })}>
      {!!title && <h1>{title}</h1>}
      {!!description && <p>{description}</p>}
      <QRCode url={url} code={code} isLoading={isLoading} message={statusMessage} />
    </div>;
});