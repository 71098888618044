import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';

import { RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { IHttpResponse } from '../../transports/httpTransport/httpTransport';
import { AsyncObject, AsyncObjectState, ObjectID } from './asyncObject';

interface PagedListHeader {
  link?: string;
}

/**
 * An AsyncList is a list of AsyncObjects. But it's also an AsyncObject itself. (If that's not correct, please provide a better docblock.)
 */
export class AsyncList<EltType> extends AsyncObject<EltType[]> {
  @observable
  protected _itemSet: EltType[];
  @observable
  private emptySet = false;

  @computed
  public get confirmedEmpty(): boolean {
    return this.emptySet;
  }

  @computed
  public get itemSet(): EltType[] {
    return this._itemSet;
  }

  private mainUrl: string;
  private nextURL: string;
  private prevURL: string; // Not used yet.

  /**
   *
   * @param requestQueue - The RequestQueue, used to determine the priority of the list.
   * @param id - Id can confusingly be either a string or an int. All it has to be is a unique identifier.
   * @param url - The url of the list. All the lists in the Client, including everything that passes through the AppList class,
   * comes through here.
   */
  public constructor(requestQueue: RequestQueue, id: ObjectID, url?: string) {
    super(requestQueue, id, url);
    makeObservable(this);
    this.mainUrl = url;
  }

  /**
   * Parses a link, which basically means filling the prevURL and nextURL values of this list.
   *
   * @param s - The URL to parse.
   */
  private parseLink(s: string): void {
    const regex = /<([^>]+)>; rel="([^"]+)"/g;

    this.prevURL = null;
    this.nextURL = null;
    let message: RegExpExecArray;
    while ((message = regex.exec(s))) {
      const [, value, key] = message;

      switch (key) {
        case 'next':
          this.nextURL = value;
          break;
        case 'prev':
          this.prevURL = value;
      }
    }
  }

  /**
   * Parses the response header and (for now) looks for the "link" section for the next URL.
   * @param Header
   */
  private parseHeader(Header: unknown): void {
    const pageHeader = Header as PagedListHeader;
    if (isNullOrUndefined(pageHeader) || isNullOrUndefined(pageHeader.link)) return;
    this.parseLink(pageHeader.link);
  }

  /**
   * Fetching is not used for lists. Only for items in the list.
   */
  public override async fetch(): Promise<void> {
    throw new Error('not used');
  }

  /**
   * Clear the current itemSet.
   */
  @action
  public unload(): void {
    this._itemSet = null;
    this.prevURL = null;
    this.nextURL = null;
    this.emptySet = false;
    this.url = this.mainUrl;
    this._requestPromise = null;
    this.setState(AsyncObjectState.None);
  }

  /**
   * Parse the header of the provided Response. Set state to done. Add it to the itemSet.
   *
   * @param response - The Response to handle.
   */
  @action
  public handleResponse(response: IHttpResponse<EltType[]>): void {
    this.parseHeader(response.headers);
    this.setState(AsyncObjectState.Done);
    if (response.data?.length) {
      this._itemSet?.push(...response.data);
    } else {
      this.emptySet = this._itemSet.length == 0;
    }
  }

  /**
   * Workaround for flow limitation.
   */
  private async _doFetch(url: string, priority: RequestPriority): Promise<IHttpResponse<EltType[]>> {
    return super.doFetch(url, priority);
  }

  /**
   * Returns items from the list. NB: Might return less than "count" items, if not enough items are readily available.
   * if less then 'count' items are returned, check "atEnd" to see if another call can be done to return the rest of the desired items.
   *
   * @param index - Current index or starting point.
   * @param count - Amount of items to return.
   * @param priority - The priority of the current list. Some lists have to be retrieved before others.
   */
  public async getItems(
    index: number,
    count: number,
    priority = RequestPriority.High,
    limitRequest = false,
    reload = false
  ): Promise<{ items: EltType[]; atEnd: boolean }> {
    // This function serves as a type helper for flow.
    return this._getItems(index, count, priority, limitRequest, reload);
  }

  /**
   * Returns items from the list. NB: Might return less than "count" items, if not enough items are readily available.
   * if less then 'count' items are returned, check "atEnd" to see if another call can be done to return the rest of the desired items.
   *
   * @param index - Current index or starting point.
   * @param count - Amount of items to return.
   * @param priority - The priority of the current list. Some lists have to be retrieved before others.
   */
  private _getItems = flow(function* (
    this: AsyncList<EltType>,
    index: number,
    count: number,
    priority = RequestPriority.High,
    limitRequest = false,
    reload = false
  ) {
    // If we're already making a request, wait for it to be resolved
    if (this._requestPromise) yield this._requestPromise;

    // When itemSet is empty, request the initial paginated set of items.
    if (isNullOrUndefined(this.itemSet) || this.itemSet.length == 0 || reload) {
      this._itemSet = [];
      let url = this.url;
      if (limitRequest) {
        let sign = '?';
        if (this.url.indexOf('?') !== -1) {
          sign = '&';
        }
        this.url = this.url.replace(/.limit=\d+/, '');
        url = this.url + `${sign}limit=${count}`;
      }
      this._doFetch(url, priority); //sets request promise too...
      const response = yield this._requestPromise;
      if (response === null) throw new Error('aborted');
      this.handleResponse(response);

      reload && this.reloadCount++;
    }

    // If our index has passed our currently loaded itemSet, and there is a nextURL available, add it to the itemSet array.
    while (index >= this._itemSet?.length && !isNullOrUndefined(this.nextURL)) {
      const response = yield this._doFetch(this.nextURL, priority);
      if (response === null) throw new Error('aborted');
      this.handleResponse(response);
    }

    // Check if there are still items left. Otherwise, we are at the end of the set.
    const outOfItems = count >= this._itemSet?.length - index && isNullOrUndefined(this.nextURL);

    // Return the slice we need.
    return { items: this._itemSet?.slice(index, index + count), atEnd: outOfItems };
  });

  //not used
  protected load(): void {
    return;
  }
}
