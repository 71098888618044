import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './close-stream-dialog.scss';
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
export const CloseStreamDialog: React.FC<Props> = observer(function CloseStreamDialog({
  action
}) {
  return <div className={'close-stream-dialog__container'}>
      <p>{t({
        message: 'The game will be closed if you press OK. Make sure you have saved your progress first!'
      })}</p>
      <div className={'buttons-container'}>
        <FocusableButton onEnterPress={() => action && action(DialogResult.OK)} type={'primary'}>
          {t({
          message: 'OK'
        })}
        </FocusableButton>
        <FocusableButton onEnterPress={() => action && action(DialogResult.Cancel)} autofocus type={'secondary'}>
          {t({
          message: 'Cancel'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default CloseStreamDialog;