import { Trans } from '@lingui/macro';
import { isDevelopment } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React from 'react';
import './error.scss';
interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}
interface Props {
  children: React.ReactElement;
}
export default observer(class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }
  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error: error,
      errorInfo: null
    };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      errorInfo: errorInfo
    });
  }
  render() {
    const error = this.state.error?.toString();
    const stack = this.state.errorInfo?.componentStack;
    if (this.state.hasError) {
      if (isDevelopment) {
        return <div className={'error__wrapper'}>
              <h1>Oops, something went wrong :(</h1>
              <h3>Error: {error}</h3>
              <h3>Component stack: {stack}</h3>
              <h2>This message is NOT shown in production builds and only intended to help developers!</h2>
            </div>;
      } else {
        return <div className={'error__wrapper'}>
              <h1>
                <Trans context={'Error message to a user if the app is crashed'}>Something went wrong :(</Trans>
              </h1>
              <h2>
                <Trans context={'Error message to a user if the app is crashed and required action'}>
                  Please restart Utomik, or contact support@utomik.com
                </Trans>
              </h2>
            </div>;
      }
    }
    return this.props.children;
  }
});