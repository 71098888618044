import { log } from '@utomik-app-monorepo/logger';

import { RecentlyPlayedStore } from '../../../dataStore/stores/recentlyPlayedStore/recentlyPlayedStore';
import { UserStatsStore } from '../../../dataStore/stores/userStatsStore/userStatsStore';
import { IGamingHubAppData } from './interfaces';

const NUMBER_APPS_TO_SEND = 8;

export class RecentlyPlayedGameHubController {
  private readonly recentlyPlayedStore: RecentlyPlayedStore;
  private readonly userStatsStore: UserStatsStore;

  constructor(recentlyPlayed: RecentlyPlayedStore, userStatsStore: UserStatsStore) {
    this.userStatsStore = userStatsStore;
    this.recentlyPlayedStore = recentlyPlayed;
  }

  public generateAndSend(isNeedToCleanUp?: boolean, isStartStreamAction?: boolean) {
    if (!this.recentlyPlayedStore?.recentlyPlayedApps) return;

    if (isNeedToCleanUp) {
      window.saveRecentlyPlayed(false, null);
      return;
    }

    const payload = this.recentlyPlayedStore?.recentlyPlayedApps?.reduce((prev, curr, idx) => {
      if (idx >= NUMBER_APPS_TO_SEND) return prev;

      let timestamp;
      let rating;
      const genre = curr.genres?.items?.map((e) => e.name) ?? [];
      if (genre.length > 3) {
        genre.length = 3;
      }

      if (idx == 0 && isStartStreamAction) {
        timestamp = Date.now();
      } else {
        timestamp = this.userStatsStore.getItemByApplicationId(+curr.id)?.lastPlayed?.getTime() || Date.now();
      }

      if (curr?.ageRatings?.items && curr?.ageRatings?.items[0]?.ageRating?.rating) {
        rating = curr?.ageRatings?.items[0]?.ageRating?.rating;
      } else {
        rating = '18';
      }

      prev.push({
        app_id: window.tizen?.application.getAppInfo().id,
        app_name: 'Utomik',
        app_logo_url: null, //  "app_logo_url" is displayed in GameHome "Recently Played" area. If the "app_logo_url" is empty, we insert Samsung UX logo.
        cp_game_id: String(curr.id),
        genre: genre,
        deeplink: `show:${curr.id}`,
        rating: rating,
        image: curr?.images?.find((item) => item.tag === 'B2')?.url,
        timestamp: timestamp,
        title: curr?.name ?? '',
      });

      return prev;
    }, [] as IGamingHubAppData[]);

    if (window.saveRecentlyPlayed != null) {
      window.saveRecentlyPlayed(true, payload);

      log('Save data for the Gaming Hub');
    }
  }
}
