import { initNavigation } from '@noriginmedia/react-spatial-navigation';
import { LoaderContext, TokenManagerContext } from '@utomik-app-monorepo/store';
import { registerTizenKeys } from '@utomik-app-monorepo/utils';
import React, { useEffect } from 'react';
import { useAnalyticsSendView } from './useAnalyticsSendView';
import { useConnectionMonitor } from './useConnectionMonitor';
import { useFeaturedItemsAnalytics } from './useFeaturedItemsAnalytics';
import { useInitialLoading } from './useInitialLoading';
import { useSentryInit } from './useSentryInit';
export const useInitialPreload = () => {
  const tokenManager = React.useContext(TokenManagerContext);
  const loader = React.useContext(LoaderContext);
  useInitialLoading();
  useSentryInit({
    dsn: 'https://019b304b0d8041b1b8eb230e08b5ae27@sentry.light-it.tools/96'
  });
  useAnalyticsSendView();
  useFeaturedItemsAnalytics();
  useConnectionMonitor();
  useEffect(() => {
    /**
     * Init and prefetch data
     */
    (async () => {
      initNavigation();
      await loader.unauthorizedPreload();
      tokenManager.init();
      registerTizenKeys();
    })();
  }, []);
};