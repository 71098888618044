import { DialogFactoryContext, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { Application } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import { IStreamView } from '@utomik-app-monorepo/stream-view';
import { useContext, useEffect, useState } from 'react';
enum DialogSteps {
  /**
   * Dialogs
   * */
  StreamQueueDialog,
  LeaveQueueDialog,
  /**
   * Actions
   * */
  RedirectGamePageAction,
  StartStreamAction,
}
export const useQueueDialogs = (app: Application, ref: IStreamView) => {
  const [step, setStep] = useState(0);
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const stepsMap = {
    [DialogSteps.StreamQueueDialog]: {
      dialog: () => dialogFactory.showStreamQueueDialog(Number(app.id)),
      actions: {
        [DialogResult.OK]: DialogSteps.StartStreamAction,
        [DialogResult.Close]: DialogSteps.LeaveQueueDialog,
        [DialogResult.Cancel]: DialogSteps.LeaveQueueDialog
      }
    },
    [DialogSteps.LeaveQueueDialog]: {
      dialog: () => dialogFactory.showLeaveQueueDialog(Number(app.id)),
      actions: {
        [DialogResult.OK]: DialogSteps.StreamQueueDialog,
        [DialogResult.Action1]: DialogSteps.RedirectGamePageAction,
        [DialogResult.Cancel]: DialogSteps.StreamQueueDialog,
        [DialogResult.Close]: DialogSteps.StreamQueueDialog
      }
    }
  };
  const queueDialogsHandler = async () => {
    if (streamViewUtilsService.queueState?.isQueue) {
      /**
       * Handle step actions
       * */
      switch (step) {
        case DialogSteps.RedirectGamePageAction:
          {
            ref.disposeStream();
            return;
          }
        case DialogSteps.StartStreamAction:
          {
            return;
          }
      }
      /**
       * Call show dialog and set step based on dialog result
       * */
      const result = await stepsMap[step].dialog();
      setStep(stepsMap[step].actions[result]);
    }
  };
  useEffect(() => {
    queueDialogsHandler();
  }, [streamViewUtilsService.queueState?.isQueue, step]);
  return null;
};