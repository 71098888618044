import React, { CSSProperties, useState } from 'react';
import { GamepadButtons } from '../GamepadButtons/GamepadButtons';
import { FocusableButton } from '../focusable-button/focusable-button';
import './focusable back button.scss';
type Props = {
  className?: string;
  text?: string;
  onEnterPress?: (props: any) => void;
  style?: CSSProperties;
  autofocus?: boolean;
  focusKey?: string;
  showLabel?: boolean;
  showOutline?: boolean;
  useIntersectionObserver?: boolean;
};
export const FocusableBackButton: React.FC<Props> = React.memo(function FocusableBackButton(props): JSX.Element {
  const {
    className,
    onEnterPress,
    style,
    autofocus,
    focusKey,
    showLabel = true,
    showOutline = true,
    useIntersectionObserver = true
  } = props;
  const [isFocusable, setIsFocusable] = useState(true);
  const handleVisibility = isVisible => {
    setIsFocusable(isVisible);
  };
  return <div className={`back-button ${className}`}>
      <FocusableButton autofocus={autofocus} focusKey={focusKey} style={style} type={'back'} onEnterPress={onEnterPress} onVisibilityChange={useIntersectionObserver ? handleVisibility : undefined} focusable={isFocusable} showOutline={showOutline} />
      {showLabel && <GamepadButtons type={'round'} name={'B'} label={'Back'} />}
    </div>;
});