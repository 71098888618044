/*
 * Loader: Attempts to pre-load data from the stores, for a specified period of time.
 * If the pre-load doesn't finish, before the timeout expires, the load can continue without the preload.
 *
 * NB: Stores can be used by any kind of UI/Controller. It is therefor preferred to only use stores in the loader,
 * and not load data from controllers or UI directly. If you need to load something from a controller, you probably
 * an architecture problem instead, and you need to find a store to put your data in ;P
 */
// Stores
import { ITEMS_PRELOAD, MAX_ITEMS_IN_CAROUSEL, MIN_ITEMS_IN_VIEW } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { RequestPriority as RP, RequestPriority } from '../../../dataStore/requestQueue/requestQueue';
import { AccountSummary } from '../../../dataStore/stores/accountSummary/accountSummary';
import { AgeRatingSystemStore } from '../../../dataStore/stores/ageRatingSystemStore/ageRatingSystemStore';
import { AppList } from '../../../dataStore/stores/applicationStore/appList';
import { ApplicationStore } from '../../../dataStore/stores/applicationStore/applicationStore';
import { ChannelLinkList } from '../../../dataStore/stores/channelLinkList/channelLinkList';
// Other imports
import { ChannelStore } from '../../../dataStore/stores/channelStore/channelStore';
import { ClientStagingTierStore } from '../../../dataStore/stores/clientStagingtierStore/clientStagingtierStore';
import { Countries } from '../../../dataStore/stores/countries/countries';
import { GenreStore } from '../../../dataStore/stores/genreStore/genreStore';
import { IssueTypes } from '../../../dataStore/stores/issueTypes/issueTypes';
import { LanguagesStore } from '../../../dataStore/stores/languagesStore/languagesStore';
import { MyListStore } from '../../../dataStore/stores/myListStore/myListStore';
import { NewsItemStore } from '../../../dataStore/stores/newsStore/newsItemStore';
import { GlobalAchievementStore } from '../../../dataStore/stores/ninjaSummary/globalAchievementStore';
import { GlobalStatisticStore } from '../../../dataStore/stores/ninjaSummary/globalStatisticStore';
import { GlobalStatisticValueStore } from '../../../dataStore/stores/ninjaSummary/globalStatisticValueStore';
import { GlobalUnlockedAchievementStore } from '../../../dataStore/stores/ninjaSummary/globalUnlockedAchievementStore';
import { ServiceSettingStore } from '../../../dataStore/stores/ninjaSummary/serviceSettingStore';
import { RecentlyPlayedStore } from '../../../dataStore/stores/recentlyPlayedStore/recentlyPlayedStore';
import { SubscriptionPlansStore } from '../../../dataStore/stores/subscriptionPlansStore/subscriptionPlansStore';
import { UserRatingStore } from '../../../dataStore/stores/userRatingStore/userRatingStore';
import { UserStatsStore } from '../../../dataStore/stores/userStatsStore/userStatsStore';
import { TokenManager } from '../tokenManager/tokenManager';

export interface Stores {
  // Data Stores
  applicationStore: ApplicationStore;
  channelStore: ChannelStore;
  channelLinkList: ChannelLinkList;
  genreStore: GenreStore;
  ageRatingSystemStore: AgeRatingSystemStore;
  userRatingStore: UserRatingStore;
  userStatsStore: UserStatsStore;
  clientStagingTierStore: ClientStagingTierStore;
  recentlyPlayedStore: RecentlyPlayedStore;
  myListStore: MyListStore;
  globalAchievementStore: GlobalAchievementStore;
  globalUnlockedAchievementStore: GlobalUnlockedAchievementStore;
  globalStatisticStore: GlobalStatisticStore;
  globalStatisticValueStore: GlobalStatisticValueStore;
  serviceSettingStore: ServiceSettingStore;
  newsItemStore: NewsItemStore;
  languagesStore: LanguagesStore;

  subscriptionPlansStore: SubscriptionPlansStore;
  // asyncObjects
  accountSummary: AccountSummary;
  recommendedApps: AppList;
  issueTypes: IssueTypes;
  countries: Countries;

  tokenManager: TokenManager;
}

/**
 * This class preloads the stores and also the spotlights and carousels.
 */
export class Loader {
  private MAX_LOAD_TIME = 1; // in seconds

  private _stores: Stores;
  private timerHandle: NodeJS.Timeout;

  // Boolean that determines whether preload is finished of not.
  @observable
  private _isFinished = false;

  @computed
  public get isFinished(): boolean {
    return this._isFinished;
  }

  public constructor(stores: Stores) {
    makeObservable(this);
    this._stores = stores;
  }

  /**
   * This method preloads all the stuff.
   *
   * @param maxLoadTime - After this time has passed, continue to next page, even if not finished.
   */
  @action
  public async load(maxLoadTime = this.MAX_LOAD_TIME): Promise<boolean> {
    return new Promise<boolean>((resolve, reject): void => {
      log(`Preload started.`);
      const finish = (): void => {
        clearTimeout(this.timerHandle);

        // Set finished after we've finished loading.
        runInAction((): void => {
          this._isFinished = true;
        });
        resolve(true);
      };

      if (maxLoadTime > 0) {
        // We resolve this promise after a maximum amount of time has passed.
        clearTimeout(this.timerHandle);
        this.timerHandle = setTimeout(() => {
          if (this._isFinished) return;
          log(`Loading stores: done - timeout expired`);
          finish();
        }, maxLoadTime * 1000);
      }

      const promise = Promise.all([
        this._stores.accountSummary.fetch(),
        this.loadStores(),
        this.loadHomepageChannels(),
      ]);
      promise
        .then((): void => {
          this._stores.genreStore.fetch(RequestPriority.Critical).catch((err) => log(err));
          this._stores.ageRatingSystemStore.fetchAll().catch((err) => log(err));
          this._stores.userRatingStore.fetch().catch((err) => log(err));
          this._stores.clientStagingTierStore.fetch().catch((err) => log(err));

          //this._stores.channelLinkList.fetchAll();

          this._stores.newsItemStore.fetch().catch((err) => log(err));
          this._stores.issueTypes.fetch().catch((err) => log(err));
          this._stores.userStatsStore.fetch().catch((err) => log(err));

          if (this._isFinished) return;
          log(`Loading stores: done.`);

          finish();
        })
        .catch((error): void => {
          if (this._isFinished) return;
          // Do not clear timeout here. Failed requests should not prevent the user from navigating to the home page.
          console.error(`Failed to load stores: error.` + !isNullOrUndefined(error) ? ` ${error}` : '');
          reject(error);
        });
    });
  }

  @action
  public unload(): void {
    log(`Unloading stores: start`);
    this._stores.genreStore.unload();
    this._stores.ageRatingSystemStore.unload();
    this._stores.channelStore.unload();
    this._stores.channelLinkList.unload();
    this._stores.userRatingStore.unload();
    this._stores.userStatsStore.unload();
    this._stores.clientStagingTierStore.unload();
    this._stores.applicationStore.unload();
    this._stores.recentlyPlayedStore.unload();
    this._stores.myListStore.unload();
    this._stores.globalAchievementStore.unload();
    this._stores.globalUnlockedAchievementStore.unload();
    this._stores.globalStatisticStore.unload();
    this._stores.globalStatisticValueStore.unload();
    this._stores.accountSummary.unload();
    this._stores.newsItemStore.unload();
    this._stores.issueTypes.unload();
    this._stores.recommendedApps.unload();
    this._stores.countries.unload();
    this._stores.serviceSettingStore.unload();
    this._stores.subscriptionPlansStore.unload();
    log(`Unloading stores: done`);

    // Unset finished after we've unloaded everything
    this._isFinished = false;
  }

  /**
   * Reload, which is basically unloading and loading again.
   * @param maxLoadTime
   */
  public async reload(maxLoadTime = this.MAX_LOAD_TIME): Promise<void> {
    this.unload();
    await this.load(maxLoadTime);
  }

  /**
   * Load all the essential stores.
   */
  private async loadStores(): Promise<void> {
    try {
      this._stores.serviceSettingStore.unload();

      const promises = [];
      // promises.push(this._stores.genreStore.fetch());
      // promises.push(this._stores.ageRatingSystemStore.fetchAll());
      // promises.push(this._stores.accountSummary.fetch());
      // promises.push(this._stores.userRatingStore.fetch());
      // promises.push(this._stores.clientStagingTierStore.fetch());
      promises.push(this._stores.serviceSettingStore.fetch(RP.High));
      promises.push(this._stores.subscriptionPlansStore.fetch(RP.High));

      // If this fails, maybe it should throw an error?
      await Promise.all(promises);
    } catch (e) {
      throw new Error('Error in loading stores');
    }
  }

  /**
   * Load the channels on the homepage.
   */
  private async loadHomepageChannels(): Promise<void> {
    try {
      await this.preloadSpotlightEntries();
      await this.preloadRecommendedApplications();
      await this.preloadRecentlyPlayedChannel();
      //await this.preloadMyListChannel();
      // const promises = [];
      // const { items } = await this._stores.channelStore.recommendedChannels.getChannels(0, HOME_CHANNEL_PAGECOUNT);
      // items?.forEach((channel: Channel) => {
      //   promises.push(this.preloadChannel(channel.slug, RP.High, RP.High, RP.High));
      // });
      // await Promise.all(promises);
      // await this.preloadChannel('new', RP.Medium, RP.Medium, RP.Medium);
      // await this.preloadChannel('coming_soon', RP.Medium, RP.Medium, RP.Medium);
    } catch (e: any) {
      throw new Error(e);
    }
  }

  /**
   * Preload a channel (fetch a channel by id and load all it's applications).
   *
   * @param channelSlug - Slug of the channel.
   * @param channelPrio - Priority with which to load the channel.
   * @param appListPrio - Priority with which to load the channel's applist.
   * @param appPrio - Priority with which to load the individual apps.
   */
  private async preloadChannel(channelSlug: string, channelPrio: RP, appListPrio: RP, appPrio: RP): Promise<void> {
    try {
      const channel = this._stores.channelStore.getItem(channelSlug);

      // Fetch the data for this channel.
      await channel.fetch(channelPrio);

      // Also preload minimum amount of applications for this channel.
      await this.preloadAppList(channel.appList, appListPrio, appPrio);
    } catch (e) {
      throw new Error('Error in preloading channel: ' + e);
    }
  }

  /**
   * Preload the applications from the provided AppList.
   * @param appList - The app list of the channel, of which the apps get fetched here.
   * @param appListPrio - Priority of the AppList.
   * @param appPrio - Priority of the individual apps.
   */
  private async preloadAppList(
    appList: AppList,
    appListPrio: RP,
    appPrio: RP,
    count = MIN_ITEMS_IN_VIEW
  ): Promise<void> {
    try {
      const promises = [];
      const { items } = await appList.getApps(0, count, appListPrio, true);
      items?.forEach((app) => {
        promises.push(app.fetch(appPrio));
      });
      await Promise.all(promises);
    } catch (e) {
      throw new Error('Error in preloading applist: ' + e);
    }
  }

  /**
   * Preload the recently played channel, before going to the home page.
   */
  private async preloadRecentlyPlayedChannel(): Promise<void> {
    try {
      // Initializing starts the 'preload'. As in, the entire list of apps get loaded.
      await this._stores.recentlyPlayedStore.initialize();
    } catch (e) {
      throw new Error('Error in preloading recently played channel: ' + e);
    }
  }

  /**
   * Preload the my list channel, before going to the home page.
   */
  private async preloadMyListChannel(): Promise<void> {
    try {
      // Initializing starts the 'preload'. As in, the entire list of apps get loaded.
      await this._stores.myListStore.initialize();
    } catch (e) {
      throw new Error('Error in preloading my list channel: ' + e);
    }
  }

  /**
   * Preload the recommended-for-you list, before going to the home page.
   */
  private async preloadRecommendedApplications(): Promise<void> {
    try {
      //Since this is the first carousel, we put both on high prio
      await this.preloadAppList(this._stores.recommendedApps, RP.Critical, RP.Critical, ITEMS_PRELOAD);
    } catch (e) {
      throw new Error('Error in preloading recommended applications: ' + e);
    }
  }

  /**
   * Preload recommended channels before going to the home page.
   */
  private async preloadSpotlightEntries(): Promise<void> {
    try {
      const spotlightChannel = this._stores.channelStore.getItem('spotlight');
      await spotlightChannel.fetch(RP.Critical);
      // Fetch the individual spotlight items.
      await spotlightChannel.spotlightList.getApps(0, MAX_ITEMS_IN_CAROUSEL, RP.Critical, true);
    } catch (e) {
      throw new Error('Error in preloading recommended channels: ' + e);
    }
  }

  public unauthorizedPreload = async () => {
    try {
      await Promise.all([
        this._stores.serviceSettingStore.fetch(RP.High),
        this._stores.subscriptionPlansStore.fetch(RP.High),
        this._stores.languagesStore.fetch(RP.High),
      ]);
    } catch (e) {
      console.warn('Unable to preload unauthorized data');
    }
  };
}
