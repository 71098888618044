import { LoaderContext, TokenManagerContext } from '@utomik-app-monorepo/store';
import React, { useEffect } from 'react';
export const useInitialLoading = () => {
  const tokenManager = React.useContext(TokenManagerContext);
  const loader = React.useContext(LoaderContext);
  useEffect(() => {
    /*
     * Set windowProperty as LG asked
     * https://utomik.atlassian.net/browse/TBS-185
     * */
    window.webOSSystem?.setWindowProperty?.('cloudgame_active', 'true');
  }, []);
  useEffect(() => {
    if (loader.isFinished && tokenManager.isInitialized && window?.hideLoading) {
      //disable initial loading element from main.js
      window.hideLoading();
    }
  }, [loader.isFinished, tokenManager.isInitialized]);
};