import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Routes } from '@utomik-app-monorepo/constants';
import { useViewOrientation } from '@utomik-app-monorepo/hooks';
import { ClientControllerContext, SearchResultStoreContext, TvSidebarControllerContext } from '@utomik-app-monorepo/store';
import ninjaIcon from 'images/svg/ninja-icon.svg';
import homeIcon from 'images/svg/sidebar/Home.svg';
import settingIcon from 'images/svg/sidebar/Setting.svg';
import channelsIcon from 'images/svg/sidebar/channels.svg';
import myListIcon from 'images/svg/sidebar/icons.svg';
import pointerBlue from 'images/svg/web-sidebar/pointer-blue.svg';
import pointerGreen from 'images/svg/web-sidebar/pointer-green.svg';
import { observer } from 'mobx-react';
import { useContext, useEffect, useMemo, useRef } from 'react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainMenuItem } from './main-menu-item/main-menu-item';
import styles from './main-menu.module.scss';
export const MainMenu = withFocusable({
  trackChildren: true
})(observer(({
  stealFocus,
  hasFocusedChild
}) => {
  const sidebarController = useContext(TvSidebarControllerContext);
  const searchResultStore = useContext(SearchResultStoreContext);
  const clientController = useContext(ClientControllerContext);
  const iconsContainerRef = useRef<HTMLDivElement>(null);
  const bluePointerRef = useRef<HTMLImageElement>(null);
  const greenPointerRef = useRef<HTMLImageElement>(null);
  const orientation = useViewOrientation();
  const location = useLocation();
  const navigate = useNavigate();
  const mainMenuRoutes = {
    Home: {
      route: Routes.Home,
      icon: homeIcon
    },
    Channels: {
      route: Routes.Channels,
      icon: channelsIcon
    },
    MyList: {
      route: Routes.MyList,
      icon: myListIcon
    },
    Settings: {
      route: Routes.Settings,
      icon: settingIcon
    },
    ...(clientController.user.isNinja && {
      Ninja: {
        route: Routes.Ninja,
        icon: ninjaIcon
      }
    })
  };
  const reversedRoutes = useMemo(() => Object.entries(mainMenuRoutes).reduce((prev, [key, val]) => {
    return {
      ...prev,
      [val.route]: key
    };
  }, {}), []);
  useEffect(() => {
    if (!hasFocusedChild) {
      sidebarController.setIsExpanded(false);
    }
  }, [hasFocusedChild]);
  useEffect(() => {
    if (sidebarController.isExpanded) {
      stealFocus();
    }
  }, [sidebarController.isExpanded]);
  useEffect(() => {
    const currentElement = iconsContainerRef.current.querySelector(`[data-id=${reversedRoutes[location.pathname]}]`) as HTMLDivElement;
    if (!currentElement || !orientation) {
      greenPointerRef.current.style.visibility = 'hidden';
      bluePointerRef.current.style.visibility = 'hidden';
      return;
    }
    greenPointerRef.current.style.visibility = 'visible';
    bluePointerRef.current.style.visibility = 'visible';
    let pointerPosition = 0;
    if (orientation?.includes('landscape')) {
      pointerPosition = currentElement.offsetTop;
    } else if (orientation?.includes('portrait')) {
      pointerPosition = currentElement.offsetLeft + currentElement.offsetWidth / 2;
    }
    greenPointerRef.current.style.transform = `translateX(calc(${pointerPosition}px - 50%))`;
    bluePointerRef.current.style.transform = `translateX(calc(${pointerPosition}px - 50%))`;
  }, [location.pathname, orientation]);
  const handleEnterPress = props => {
    searchResultStore.clear();
    const route = mainMenuRoutes[props?.id]?.route;
    route && navigate(route);
  };
  return <nav className={styles.container}>
        <div className={styles.wrapper}>
          <img ref={greenPointerRef} className={styles.greenPointer} src={pointerGreen} alt={'menu-green-pointer'} />
          <img ref={bluePointerRef} className={styles.bluePointer} src={pointerBlue} alt={'menu-blue-pointer'} />
          <div className={styles.iconsContainer} ref={iconsContainerRef}>
            {Object.entries(mainMenuRoutes).map(([key, val]) => {
          const isActive = location.pathname === val.route;
          return <MainMenuItem key={val.route} id={key} onEnterPress={handleEnterPress} icon={val.icon} isActive={isActive} />;
        })}
          </div>
        </div>
      </nav>;
}));