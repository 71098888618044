import { action, makeObservable } from 'mobx';

import { RequestQueue } from '../../../requestQueue/requestQueue';
import { AsyncObject, ObjectID } from '../../objectStore/asyncObject';

export interface ApiAchievementSchema {
  achievementschema: {
    id: ObjectID;
    application: {
      id: number;
    };
    achievementversions: {
      id: ObjectID;
      achievement: {
        id: ObjectID;
      };
      name: string;
      group: string;
      description: string;
      min_value: number;
      max_value: number;
      hidden: boolean;
      locked_image: {
        download_url: string;
      };
      unlocked_image: {
        download_url: string;
      };
    }[];
  };
}

export class Achievementversion {
  public id: ObjectID;
  public achievementId: ObjectID;
  public name: string;
  public group: string;
  public description: string;
  public hidden: boolean;
  public values: {
    min: number;
    max: number;
  };
  public images: {
    locked: string;
    unlocked: string;
  };
}

export class Achievementschema extends AsyncObject<ApiAchievementSchema> {
  public constructor(requestQueue: RequestQueue, applicationId: ObjectID, versionId: ObjectID) {
    super(requestQueue, versionId, `v2/applications/${applicationId}/versions/live?fields=achievementschema`);
    makeObservable(this);
    this.initialize(versionId, applicationId);
  }

  @action
  private initialize(versionId: ObjectID, applicationId: ObjectID): void {
    this.id = versionId;
    this.applicationId = applicationId;
  }

  @action
  public load(x: ApiAchievementSchema): void {
    if (!x.achievementschema) {
      return;
    }
    try {
      this.achievementversions = x.achievementschema.achievementversions.map((y) => {
        return {
          id: y.id,
          achievementId: y.achievement.id,
          name: y.name,
          group: y.group,
          description: y.description,
          hidden: y.hidden,
          values: {
            min: y.min_value,
            max: y.max_value,
          },
          images: {
            locked: y.locked_image?.download_url,
            unlocked: y.unlocked_image?.download_url,
          },
        };
      });
    } catch (ex) {
      throw new Error(`Failed loading achievementschema ${this.id}.`);
    }
  }
  public applicationId: ObjectID;
  public achievementversions: Achievementversion[] = [];
}
