import { DEFAULT_RETRIES } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';

import { RequestMethod, RequestPriority } from '../../requestQueue/requestQueue';
import { AsyncList } from '../../stores/objectStore/asyncList';
import { StreamStore } from '../../stores/streamStore/streamStore';
import { ObjectID } from '../objectStore/asyncObject';

export interface IPlaySessionResponse {
  applicationversion: IApplicationVersion;
  id: string;
  ip_address: string;
  session: ICloudSession;
  start_time: string;
  end_time?: string;
  url: string;
  playtime: number;
}

interface IApplicationVersion {
  id: number;
  url: string;
}

interface ICloudSession {
  id: string;
  url: string;
}

export class PlaySession extends AsyncList<IPlaySessionResponse> {
  private readonly streamStore: StreamStore;

  public constructor(streamStore: StreamStore, id: ObjectID, url?: string) {
    super(streamStore.requestQueue, id, url);

    this.streamStore = streamStore;
  }

  public async playSession(applicationVersion: number): Promise<IPlaySessionResponse> {
    log(`applicationVersion: ${applicationVersion}`);

    const response = await this.requestQueue.add<IPlaySessionResponse>(
      this.url,
      {
        applicationversion: applicationVersion,
        type: 'cloud',
      },
      RequestPriority.High,
      RequestMethod.Post,
      null,
      DEFAULT_RETRIES,
      true
    ).promise;

    return response.data;
  }
}
