export class WebGamePadHelperKeycode {
  /**
   * For regular buttons: maps browser controller-buttons to ???
   * See (https://learn.microsoft.com/en-us/windows/win32/xinput/directinput-and-xusb-devices)
   *
   * For dpad: maps buttons according to (u,r,d,l) = (1,2,4,8).
   */
  public transformKeyCode(arrayIndex: number): number {
    switch (arrayIndex) {
      case 0: // A
      case 1: // B
      case 2: // X
      case 3: // Y
      case 4: // L1/LB
      case 5: // R1/RB
        return arrayIndex;
      case 8: // Option
        return 6;
      case 9: // Option
        return 7;
      case 10: // KEYCODE_BUTTON_THUMBL
        return 8;
      case 11: // KEYCODE_BUTTON_THUMBR
        return 9;
      case 12: // Dpad - up
        return 1;
      case 13: // Dpad - down
        return 4;
      case 14: // Dpad - left
        return 8;
      case 15: // Dpad - right
        return 2;
      case 16: // Central button "PS"
        return 16;
    }

    return -1;
  }
}

export class WebGamePadHelperAxesCode {
  /**
   * Maps browser-controller axis index onto ??? axis index.
   * Output order is (Lx, Ly, _, Rx, Ry)
   */
  public transformAxesCode(arrayIndex: number): number {
    switch (arrayIndex) {
      case 0:
      case 1:
        return arrayIndex;
      case 2:
        return 3;
      case 3:
        return 4;
    }

    return -1;
  }
}
