import { action, computed, makeObservable, observable, toJS } from 'mobx';

import { ApplicationMedia } from '../../../dataStore/stores/applicationStore/application';
import { DialogFactory } from '../dialogFactory/dialogFactory';

export interface ISlideItem extends ApplicationMedia {
  hasPrev?: boolean;
  hasNext?: boolean;
  currentIndex?: number;
}

export class MediaPlayerController {
  private _dialogFactory: DialogFactory;
  @observable
  private _media: ApplicationMedia[];
  @observable
  private _currentItem: ISlideItem;

  constructor(dialogFactory: DialogFactory) {
    this._dialogFactory = dialogFactory;

    makeObservable(this);
  }
  @action
  public setMedia(media: ApplicationMedia[]) {
    this._media = media;
  }
  @computed
  public get media() {
    return this._media;
  }
  @action
  public setCurrentItem(item: ISlideItem) {
    this._currentItem = item;
  }
  @computed
  public get currentItem() {
    return this._currentItem;
  }

  public changeSlide = (delta: number) => {
    const currIdx = this._media.findIndex((app) => app.id === this._currentItem.id);
    const deltaIndex = currIdx >= 0 ? currIdx + delta : undefined;

    const mediaArr = toJS(this._media);

    this.setCurrentItem({
      ...mediaArr[deltaIndex],
      hasPrev: deltaIndex > 0,
      hasNext: !!mediaArr[deltaIndex + 1],
      currentIndex: currIdx,
    });
  };

  public open(index: number) {
    const mediaArr = toJS(this._media);

    this.setCurrentItem({
      ...mediaArr[index],
      hasPrev: index - 1 >= 0,
      hasNext: !!mediaArr[index >= 0 ? index + 1 : undefined],
      currentIndex: index,
    });

    this._dialogFactory.showMediaPlayerDialog();
  }

  public dispose() {
    this.setMedia([]);
    this.setCurrentItem(null);
  }
}
