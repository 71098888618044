export interface IEdenPreviewSectionsData {
  sections: IEdenPreviewTilesData[];
}

export interface IEdenPreviewTilesData {
  title: string;
  position: number;
  tiles: IEdenPreviewTileData[];
}

export interface IEdenPreviewTileData {
  title: string;
  image_ratio: '1by1';
  image_url: string;
  action_data: string;
}

export enum EdenPreviewType {
  all,
  recommendedGames,
}
