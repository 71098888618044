import { StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { useContext, useEffect } from 'react';
export const useStreamNetworkTest = (precision = 0.2) => {
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const runNetworkTest = async () => {
    if (streamViewUtilsService.state === 'Initialized') {
      await streamViewUtilsService.startNetworkTest(precision);
      streamViewUtilsService.resetNetworkTest();
    }
  };
  useEffect(() => {
    runNetworkTest();
  }, [streamViewUtilsService.state]);
};