import { Platform, checkCurrentPlatform } from '@utomik-app-monorepo/utils';

import { AndroidTVWindowData } from '../androidTV/androidTV';
import { TizenWindowData } from '../tizen/tizenWindowData';
import { VidaaWindowData } from '../vidaa/vidaaWindowData';
import { WebOSWindowData } from '../webOS/webOSWindowData';

export class PlatformController {
  private readonly _tizenInstance: TizenWindowData;
  private readonly _webosInstance: WebOSWindowData;
  private readonly _vidaaInstance: VidaaWindowData;
  private readonly _androidTVInstance: AndroidTVWindowData;

  constructor() {
    const platform = checkCurrentPlatform();
    switch (platform) {
      case Platform.Tizen: {
        this._tizenInstance = new TizenWindowData();
        break;
      }
      case Platform.WebOS: {
        this._webosInstance = new WebOSWindowData();
        break;
      }
      case Platform.Vidaa: {
        this._vidaaInstance = new VidaaWindowData();
        break;
      }
      case Platform.AndroidTV: {
        this._androidTVInstance = new AndroidTVWindowData();
      }
    }
  }

  public get instance() {
    return this._tizenInstance || this._webosInstance || this._vidaaInstance || this._androidTVInstance;
  }

  public get isTizen() {
    return this.instance instanceof TizenWindowData;
  }

  public get isWebOS() {
    return this.instance instanceof WebOSWindowData;
  }

  public get isVidaa() {
    return this.instance instanceof VidaaWindowData;
  }

  public get isAndroidTV() {
    return this.instance instanceof AndroidTVWindowData;
  }

  public get currentPlatform() {
    if (this.isWebOS) return Platform.WebOS;
    if (this.isTizen) return Platform.Tizen;
    if (this.isVidaa) return Platform.Vidaa;
    if (this.isAndroidTV) return Platform.AndroidTV;

    return location.hostname;
  }

  public get isTv(): boolean {
    return !!this.instance;
  }

  public get isWeb() {
    return this.currentPlatform === location.hostname && process.env.APP_TYPE === 'WEB';
  }

  public get appVersion() {
    return this.instance?.getAppInfo?.version || 'dev1.0.0';
  }

  public get osVersion(): string {
    return this.instance?.getDeviceInfo?.version || 'dev1.0.0';
  }

  public get deviceModel(): string {
    return this.instance?.getDeviceInfo?.model || location.hostname;
  }

  public get deviceBrand(): string {
    return this.instance?.getDeviceInfo?.brand;
  }

  public get isAllowedToPlayMainVideo() {
    return typeof this.instance?.allowedToPlayMainVideo === 'boolean' ? this.instance?.allowedToPlayMainVideo : true;
  }
}
