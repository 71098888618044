import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './stream-fullscreen-dialog.scss';
const StreamFullscreenDialog = withFocusable()(observer(({
  action
}) => {
  return <div className={'stream-fullscreen-dialog__container'}>
        <p className={'stream-fullscreen-dialog__title'}>To continue playing return to full screen mode</p>
        <div className={'stream-fullscreen-dialog__buttons__container'}>
          <FocusableButton onEnterPress={() => action(DialogResult.OK)} autofocus type={'primary'}>
            Full screen
          </FocusableButton>
          <FocusableButton onEnterPress={() => action(DialogResult.Cancel)} type={'secondary'}>
            {t({
          context: 'Quit the game message',
          message: 'Quit the game'
        })}
          </FocusableButton>
        </div>
      </div>;
}));
export default StreamFullscreenDialog;