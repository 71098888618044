import { RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';

export interface ApiLanguage {
  id: ObjectID;
  native_name: string;
  name: string;
  url: string;
  code: string;
}

export interface Language extends Omit<ApiLanguage, 'native_name'> {
  nativeName: string;
}
export class LanguagesStore extends ObjectStore<Language, ApiLanguage> {
  public constructor(requestQueue: RequestQueue, url = `v2/languages`, retries = null) {
    super(requestQueue, url, retries);
  }
  protected createAndLoadItem({ id, code, name, url, native_name }: ApiLanguage): Language {
    return { id, code, name, url, nativeName: native_name };
  }

  public getLanguageByCode = (code: string): Language | null => {
    return this.items.find((item) => item.code === code) || null;
  };
}
