import { t } from '@lingui/macro';
import logo from 'images/svg/horizontalLogo.svg';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './loading.module.scss';
export const Loading = observer(() => {
  return <div className={styles.container}>
      <img alt={'vertical-utomik-logo'} className={styles.logo} src={logo} />
      <p className={styles.loadingText}>{t({
        context: 'In process of loading data',
        message: 'Loading...'
      })}</p>
    </div>;
});