import { log } from '@utomik-app-monorepo/logger';
import { action, computed, makeObservable, observable, override } from 'mobx';

import { PageScrollState } from '../../../app/global/pageScrollState/pageScrollState';
import { RequestMethod, RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { ObjectID } from '../objectStore/asyncObject';
import { BaseObject, ObjectStore } from '../objectStore/objectStore';
import { NewsArticle, NewsArticleStore } from './newsArticleStore';

export interface ApiNewsItem extends BaseObject {
  id: ObjectID;
  read: boolean;
}

export class NewsItem implements BaseObject {
  @observable
  private _read: boolean = undefined;
  @action
  public setRead(read: boolean): void {
    this._read = read;
  }

  public pageScrollState: PageScrollState;

  readonly id: ObjectID;

  @computed
  public get read(): boolean {
    return this._read;
  }

  private readonly _newsStore: NewsArticleStore;
  public constructor(x: ApiNewsItem, newsArticleStore: NewsArticleStore) {
    this.id = x.id;
    this._newsStore = newsArticleStore;
    makeObservable(this);
    const item = this._newsStore.getItem(x.id);
    this.setRead(x.read);
    this._setItem(item);
    this.pageScrollState = new PageScrollState();
  }

  @observable
  private _article: NewsArticle;
  @action
  private _setItem(article: NewsArticle): void {
    this._article = article;
  }
  @computed
  public get article(): NewsArticle {
    return this._article;
  }
}

export class NewsItemStore extends ObjectStore<NewsItem, ApiNewsItem> {
  private readonly _newsArticleStore: NewsArticleStore;

  private _pageScrollState: PageScrollState;

  public constructor(requestQueue: RequestQueue, url = 'v2/news') {
    super(requestQueue, url, [10, 15]);
    this._newsArticleStore = new NewsArticleStore(requestQueue);
    makeObservable(this);
  }
  @computed
  private get _unreadItems(): NewsItem[] {
    return this.items.filter((x) => !x.read);
  }

  /**
   * This is used to get a number of unread items.
   */
  @computed
  public get unreadCount(): number {
    return this._unreadItems.length;
  }

  @observable
  private _readAll = false;
  @action
  private _setReadAll(readAll: boolean): void {
    this._readAll = readAll;
  }

  public get pageScrollState() {
    return this._pageScrollState;
  }

  public clearPageScrollState() {
    this._pageScrollState = new PageScrollState();
  }

  public async readAll(): Promise<void> {
    if (this._readAll) {
      log(`readAll(): Read all ignored, already read.`);
      return;
    }
    let publishDate: string = undefined;
    for (const { article } of this.items) {
      await article.fetch();
      publishDate = article.publishDate;
      if (publishDate) break;
    }

    if (publishDate) {
      await this.requestQueue.add(
        'v2/news/do_read_all',
        { read_date: publishDate },
        RequestPriority.High,
        RequestMethod.Post
      ).promise;
      // Set read all when request succeeds.
      this._setReadAll(true);
    } else {
      this._setReadAll(true);
      console.warn(`readAll(): Reading all done, but could not find last publish date.`);
    }

    //and update the items
    this.items.forEach((x) => x.setRead(true));
  }

  public override async fetch(requestPriority?: RequestPriority): Promise<void> {
    this._pageScrollState = new PageScrollState();
    await super.fetch(requestPriority).then(() => {
      // Set read all to false when new data comes in.
      this._setReadAll(false);
    });
  }

  public override async reFetch(requestPriority?: RequestPriority) {
    await super.reFetch(requestPriority).then(() => {
      // Set read all to false when new data comes in.
      if (
        !this.items.every((value: NewsItem) => {
          return value.read === true;
        })
      )
        this._setReadAll(false);
    });
  }

  /**
   * Unloads the store and child stores.
   */
  @override
  public override unload(): void {
    // Set read all to false when unloading.
    this._setReadAll(false);
    this._newsArticleStore.unload();
    super.unload();
  }

  protected createAndLoadItem(x: ApiNewsItem): NewsItem {
    return new NewsItem(x, this._newsArticleStore);
  }
}
