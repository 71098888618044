import GamepadController from './gamepadController';
import KeyboardController from './keyboardController';
import MouseController, { CursorMsg } from './mouseController';
import { DeviceTag, QueueState, StreamMessage } from '../streamTypes';

export interface BaseMsg {
  type: string;
}

export interface HandshakeMsg extends BaseMsg {
  type: 'handshake';
  action: string;
  value?: boolean;
  features?: string;
}

export interface QueueMsg extends BaseMsg {
  type: 'queue';
  position: number;
  time: number;
  action: string;
}

export interface ControllerMsg extends BaseMsg {
  type: 'controller';
  id: number;
  name: string;
  left: number;
  right: number;
  action: string;
}

export interface MessageMsg extends BaseMsg {
  type: 'message';
  title: string;
  time: number;
  value: number;
  action: string;
}

interface BaseSettingsMsg extends BaseMsg {
  type: 'settings';
  action: string;
}

export interface SettingsMsg extends BaseSettingsMsg {
  messageCode: string | number;
  value: {
    audio: number;
    video: number;
  };
  reason: string;
}

export interface SettingsNativeAudioMsg extends BaseSettingsMsg {
  ip: string;
  videoport: number;
  audioport: number;
}

type SettingsMessage = SettingsMsg | SettingsNativeAudioMsg;

export interface SettingsNativeAudioMsg extends BaseMsg {
  type: 'settings';

  /** The type of WebSocket action. In this case: udpforward */
  action: string;

  /**  The IP address where the stream is hosted */
  ip: string;

  /**
   * NOT used by the client! Left over from legacy code
   *
   * But we also have the ability to get video streaming on UDP
   */
  videoport: number;

  /** The port number for audio stream */
  audioport: number;
}

export interface StreamMsg extends BaseMsg {
  type: 'stream';
  action: string;
  value: number;
}

export interface LaunchArgs {
  jwt: string;
  playsessionId: string;
  appId: number;
  appversionId: number;
  x: number;
  y: number;
  platform_id: null; // Copied from tizen-tv. I don't know what this is.
  cloudstreaming: DeviceTag;
  mobile: number; // Copied from tizen-tv. I don't know what this is.
  tag: number; // Copied from tizen-tv. I don't know what this is.
  appLanguage?: string;
  guiLanguage?: string;
}

export type SendAsJsonFunc = (type: string, action: string, msg: Record<string, unknown>) => void;

export default class WSSStreamingController {
  private readonly _ws: WebSocket;
  private readonly _janusVideoCb: (streamID: number) => void;
  private readonly _janusAudioCb: (streamID: number) => void;
  private readonly _queueCb: (data: QueueState) => void;
  private readonly _setErrorCb: (message: StreamMessage) => void;
  private readonly _disposeStream: () => void;
  private _gamepadController: GamepadController | undefined;
  private _keyboardController: KeyboardController | undefined;
  private _mouseController: MouseController | undefined;
  private _launchArguments: LaunchArgs | undefined;

  public get mouseController(): MouseController | undefined {
    return this._mouseController;
  }

  public get keyboardController(): KeyboardController | undefined {
    return this._keyboardController;
  }

  constructor(
    videoElement: HTMLVideoElement,
    url: string,
    launchArguments: LaunchArgs,
    usePointerCapture: boolean,
    janusVideoCb: (streamID: number) => void,
    janusAudioCb: (streamID: number) => void,
    setErrorCb: (message: StreamMessage) => void,
    disposeStream: () => void,
    queueCb: (data: QueueState) => void,
    pointerCaptureLostCb: () => void,
    onNativeAudioStreamSettingsCb?: (settings: SettingsNativeAudioMsg) => void,
    onGamepadRegistrationHookCb?: (gamepad: Gamepad, registerGamepad: (systemGamepad: Gamepad) => void) => void,
  ) {
    this._janusVideoCb = janusVideoCb;
    this._janusAudioCb = janusAudioCb;
    this._setErrorCb = setErrorCb;
    this._disposeStream = disposeStream;
    this._queueCb = queueCb;
    this._ws = new WebSocket(url);
    this._launchArguments = launchArguments;
    console.debug(`WSSStreamingController`, `constructor url`, url);
    this.init(
      videoElement,
      usePointerCapture,
      pointerCaptureLostCb,
      onNativeAudioStreamSettingsCb,
      onGamepadRegistrationHookCb,
    );
  }

  private _handleQueueMessages = (message: QueueMsg) => {
    const msgAction = message.action;
    console.debug(`WSSStreamingController`, `_handleQueueMessages`, msgAction);
    switch (msgAction) {
      case 'leave': {
        this._queueCb({ position: null, isQueue: false, time: null });
        break;
      }
      case 'wait': {
        this._queueCb({
          position: message.position,
          time: message.time,
          isQueue: true,
        });
        break;
      }
    }
  };

  private _handleHandshakeMessages = (message: HandshakeMsg) => {
    // TODOTODO: test
    console.log(`Received message (${message.type}/${message.action}) with ready state ${this._ws.readyState}`);

    if (this._ws.readyState !== this._ws.OPEN) return;

    if (message['action'] === 'comparability') {
      // Check if current version is compatible
      if (message['value'] === true) {
        console.log('Version is compatible.');
      } else {
        this._setErrorCb({
          value: `This version is not compatible. Please update the application`,
          type: 'error',
        });
      }
    }
    // Get supported features
    if (message['action'] === 'version') {
      const features = message['features'];
      console.log(`Received the following features in handshake: ${JSON.stringify(features)}`);

      // Send the features enable message
      this._sendAsJson('handshake', 'features', { value: { queue: true } });

      // Send network test metrics
      //this._sendAsJson('speedtest', 'statistic', {
      //  value: this._metricsData,
      //});

      // Send the start stream message
      this._sendAsJson('stream', 'start', {
        launchArgs: { ...this._launchArguments },
      });
    }
  };

  private _handleMessageMessages = (message: MessageMsg) => {
    const msgAction = message['action'];
    console.debug(`WSSStreamingController`, `_handleMessageMessages`, message);
    if (msgAction === 'activity') {
      console.debug(`WSSStreamingController`, `activity`, JSON.stringify(message));
      this._setErrorCb({
        value: message['title'],
        type: 'activity',
        payload: { time: message['time'], value: message['value'] },
      });
    }
  };

  private _handleSettingsMessages = (
    message: SettingsMessage,
    onNativeAudioStreamSettingsCb?: (settings: SettingsNativeAudioMsg) => void,
  ) => {
    const msgAction = message['action'];
    console.debug(`WSSStreamingController`, `_handleSettingsMessages`, msgAction);
    switch (msgAction) {
      case 'streamIds':
        // eslint-disable-next-line no-case-declarations
        const msg = message as SettingsMsg;
        // eslint-disable-next-line no-case-declarations
        const videoId = msg['value']['video'];
        // eslint-disable-next-line no-case-declarations
        const audioId = msg['value']['audio'];
        if (videoId) this._janusVideoCb(videoId);
        if (audioId) this._janusAudioCb(audioId);
        break;
      case 'udpforward': {
        const msg = message as SettingsNativeAudioMsg;

        onNativeAudioStreamSettingsCb?.(msg);
        break;
      }
      case 'terminating': {
        const msg = message as SettingsMsg;
        const msgCode = msg['messageCode'];
        console.log(`msgCode: ${msgCode}`);
        /*
            The error code has different reasons for terminating the session
            In this case, we will wait for the code 1101
            1101 occurs when the user logs out of the game on their own in a virtual machine
            So we need to move user to Game Page without error message and should display After game popup

            Code 1212 indicates termination due to inactivity, we consider this normal behavior.
            Code 1207 indicates termination by the user, we consider this normal behavior.
            Code 1218 indicates the game crashed.
            For a full list of possible errorCodes see https://gitlab.com/a6463/streaming-gateway/-/blob/stage/enums/message-codes.js
        */
        if (msgCode == 1101 || msgCode == 1207 || msgCode == 1212) {
          this._disposeStream();
        } else if (msgCode == 1218) {
          console.warn(`game error`);
          this._setErrorCb({
            value: 'GameError',
            type: 'error',
          });
        } else {
          console.warn(`unknown error: ${msg['reason']}`);
          this._setErrorCb({
            value: 'UnknownError',
            type: 'error',
            payload: msgCode,
          });
        }
      }
    }
  };

  private _handleStreamMessages = (message: StreamMsg) => {
    switch (message['action']) {
      case 'bitrate':
        console.log(`Received bitrate - ${message.value}`);
        break;
      case 'reinit':
        console.log('Receive reinit from BE');
        break;
      case 'ready':
        //backend is ready for streaming
        console.log('BE is ready');
        this._queueCb({ position: null, isQueue: false, time: null });
        break;
    }
  };

  public init = (
    videoElement: HTMLVideoElement,
    usePointerCapture: boolean,
    pointerCaptureLostCb: () => void,
    onNativeAudioStreamSettingsCb?: (settings: SettingsNativeAudioMsg) => void,
    onGamepadRegistrationHookCb?: (gamepad: Gamepad, registerGamepad: (systemGamepad: Gamepad) => void) => void,
  ) => {
    this._gamepadController = new GamepadController(this._sendAsJson, onGamepadRegistrationHookCb);
    this._keyboardController = new KeyboardController(this._sendAsJson);
    this._mouseController = new MouseController(
      this._sendAsJson,
      videoElement,
      usePointerCapture,
      pointerCaptureLostCb,
    );

    if (process.env.NODE_ENV === 'development') {
      this._registerMsgCCGlobal();
    }

    this._ws.onopen = () => {
      console.debug(`WSSStreamingController`, `init`, `opened & sending handshake`);
      this._sendAsJson('handshake', 'version', { value: 1 });
    };

    this._ws.onerror = (error) => {
      console.warn(`WSSStreamingController: WebSocket error type: ${error.type}`);
      this._setErrorCb({
        value: 'SocketError',
        type: 'error',
      });
    };

    this._ws.onmessage = (data) => {
      const message: BaseMsg = JSON.parse(data.data);
      const msgType = message['type'];

      switch (msgType) {
        case 'handshake':
          this._handleHandshakeMessages(message as HandshakeMsg);
          break;
        case 'cursor':
          if (this._mouseController) {
            this._mouseController.handleServerCursorMessage(message as CursorMsg);
          }
          break;
        case 'queue':
          this._handleQueueMessages(message as QueueMsg);
          break;
        case 'controller':
          if (this._gamepadController) {
            this._gamepadController.handleServerMessage(message as ControllerMsg);
          }
          break;
        case 'message':
          this._handleMessageMessages(message as MessageMsg);
          break;
        case 'settings':
          this._handleSettingsMessages(
            message as SettingsMsg,
            onNativeAudioStreamSettingsCb,
          );
          break;
        case 'stream':
          this._handleStreamMessages(message as StreamMsg);
          break;
      }
    };

    this._ws.onclose = () => {
      console.debug(`WSSStreamingController`, `init`, `onclose`);
    };

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _global = (window /* browser */ || global) /* node */ as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _global.msgCC = (type: string, action: string, msg: any) => {
        console.log(`[MSGCC] '${type}/${action} : ${JSON.stringify(msg)}`);
        this._sendAsJson(type, action, msg);
      };
      console.log('[MSGCC] ENABLED');
    }
  };

  public dispose(): void {
    this._ws?.close();
    this._gamepadController?.dispose();
    this._keyboardController?.dispose();
    this._mouseController?.dispose();
  }

  public get ws(): WebSocket {
    return this._ws;
  }

  // --- utils ---
  private _sendAsJson = (type: string, action: string, msg: Record<string, unknown>) => {
    // Note: There doesn't seem to be a type for anything json serializable.
    // Record<string, any> is slightly too optimistic since it approves too much.
    // However, it is easier to have something that approves a bit too much as something that approves too little.

    if (this._ws.readyState !== this._ws.OPEN) return;

    //console.debug('Sending: ', type, action);
    msg.type = type;
    msg.action = action;
    this._ws.send(JSON.stringify(msg));
  };

  private _registerMsgCCGlobal() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _global = (window /* browser */ || global) /* node */ as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _global.msgCC = (type: string, action: string, msg: any) => {
      msg.type = type;
      console.log(`Sending ${JSON.stringify(msg)}.`);
      this._sendAsJson(type, action, msg);
    };
    console.log('msgCC is now set');
  }

  // --- Controllers ---

  public get gamePadController(): GamepadController | undefined {
    return this._gamepadController;
  }

  // --- Queue ---

  public queueLeave() {
    this._sendAsJson('queue', 'leave', {});
    console.log('Session leave message sent');
  }

  public queueInfo() {
    this._sendAsJson('queue', 'info', {});
  }
}
