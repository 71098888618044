export enum Routes {
  Home = '/',
  Search = '/search',
  Channels = '/channels',
  Events = '/events',
  MyList = '/my-list',
  News = '/news',
  Settings = '/settings',
  Profile = '/profile',
  Authorization = '/authorization',
  Login = '/authorization/login',
  ForgotPassword = '/authorization/forgot-password',
  AppInfo = '/app-info',
  GameRun = '/game/run',
  Ninja = '/ninja',
}

export const reversedRoutes = Object.keys(Routes).reduce((acc, curr) => {
  return { ...acc, [Routes[curr]]: curr };
}, {} as Record<string, Routes>);

export const analyticsRoutes = {
  [Routes.Home]: 'HomePage',
  [Routes.Ninja]: 'NinjaPage',
};
