import GamePadState from './gamepadState';
import { WebGamePadHelperAxesCode, WebGamePadHelperKeycode } from './webExternalGamepadController';
import { ControllerMsg, SendAsJsonFunc } from './wssStreamingController';

/*
  Overall notes and todos:
  - There is an axis deadzone of 0.2 right now. 20% is really high, and should probably be user-controlled anyway.
  - Deadzone D can be handled as a simple cutoff (v max(v, D)), or a bias (v = max((v-D)/(1-D), 0)). The latter is
    probably better.
  - The dpad code is overcomplicated. It's a bitfield with the main directions being (u,r,d,l = 1,2,4,8). Diagonals
    are just combinations of that. But you do still need to remove invalid combinations like ud and lr.
  - enums for the web and internal key/axis indices might be useful.
  - Why are we sending 'isPressed' to all the handle functions when we're already sending gamepadButton, which
    already has a 'pressed' bool?
  - It'll need a larger rewrite, but it'll probably be easier and more efficient to send the entire keystate. Something
    like this:
    { type:'controller', action:'state', buttons: 12345, axes: [0, 32600, 0, -20000, 20000, 240] }
    This contains the entire state of everything in one message. Currently, if you have multiple buttons pressed, you get
    multiple, separate messages that are basically as long as that one.
 */

export default class GamepadController {
  private _isPaused = false;
  private _isInitialized = false;
  private readonly _UP_LEFT_ID = 9;
  private readonly _UP_RIGHT_ID = 3;
  private readonly _DOWN_LEFT_ID = 12;
  private readonly _DOWN_RIGHT_ID = 6;
  private readonly _MAX_AXIS = 32767;
  private readonly _MAX_RUMBLE = 65535;
  private readonly _webGamePadHelperKeycode: WebGamePadHelperKeycode;
  private readonly _webGamePadHelperAxesCode: WebGamePadHelperAxesCode;
  private readonly _INTERVAL_TO_CHECK_GAMEPAD = 2000;
  private _deadzone = 0.2;

  private _rafId = -1;
  private _isStartGamePadListener = false;
  private _listOfGamePadsState: GamePadState[] = [];
  private _listOfClientConnectedControllers: Gamepad[] = [];
  private _listOfServerConnectedControllers: boolean[] = [];

  private _intervalIDs: NodeJS.Timeout[] = [];

  private _sendAsJson: SendAsJsonFunc;
  private _onGamepadRegistrationHookCb?: (gamepad: Gamepad, registerGamepad: (systemGamepad: Gamepad) => void) => void;

  constructor(sendAsJson: SendAsJsonFunc, onGamepadRegistrationHook?: (gamepad: Gamepad, registerGamepad: (systemGamepad: Gamepad) => void) => void) {
    this._sendAsJson = sendAsJson;
    this._onGamepadRegistrationHookCb = onGamepadRegistrationHook;
    this._webGamePadHelperKeycode = new WebGamePadHelperKeycode();
    this._webGamePadHelperAxesCode = new WebGamePadHelperAxesCode();
  }

  public get isPaused() {
    return this._isPaused;
  }

  public set isPaused(isPaused) {
    this._isPaused = isPaused;
  }

  public init() {
    if (this._isInitialized) return;
    console.debug(`gamepadController`, `init`);
    window.addEventListener('gamepadconnected', this._addController);
    window.addEventListener('gamepaddisconnected', this._removeController);
    this._scanGamepads();

    this._isInitialized = true;
  }

  public dispose() {
    //console.debug(`gamepadController`, `dispose`);
    window.removeEventListener('gamepadconnected', this._addController);
    window.removeEventListener('gamepaddisconnected', this._removeController);
    window.cancelAnimationFrame(this._rafId);

    this._listOfClientConnectedControllers = [];
    this._listOfServerConnectedControllers = [];
    this._intervalIDs.forEach((id) => clearInterval(id));
    this._intervalIDs = [];
    this._isPaused = false;
  }

  public handleServerMessage = (message: ControllerMsg) => {
    const msgAction = message['action'];
    console.debug(`WSSStreamingController`, `_handleControllerMessages`, msgAction);
    switch (msgAction) {
      case 'connected': {
        const nameOfDevice = message['name'];
        const serverSideId = message['id'];

        console.log(`Controller connected server side: ${nameOfDevice}, id: ${serverSideId}`);
        this._listOfServerConnectedControllers[serverSideId] = true;
        break;
      }
      case 'rumble': {
        const left = message['left'];
        const right = message['right'];
        const localDeviceId = this._remoteToLocalID(message['id']);

        this.vibrateController(localDeviceId, left, right);
        break;
      }
    }
  };

  private _localToRemoteID(localID: number): number {
    // +1 because we created the id based on the index in our list
    return localID + 1;
  }

  private _remoteToLocalID(remoteID: number): number {
    // -1 because we created the id based on the index in our list
    return remoteID - 1;
  }

  private _addController = (event: GamepadEvent) => {
    //for tests :(
    this._tryAddGamepad(event.gamepad);
  };

  /**
   * The reason for this feature can be found in the [onGamepadRegistration] comment - streamViewProps.ts
  */
  private _tryAddGamepad = (systemGamepad: Gamepad) => {
    if (this._onGamepadRegistrationHookCb) {
      this._onGamepadRegistrationHookCb(systemGamepad, this._addGamepad);

      return;
    }

    this._addGamepad(systemGamepad);
  }

  private _addGamepad = (systemGamepad: Gamepad) => {
    if (this._listOfClientConnectedControllers.some((c) => c.index === systemGamepad.index)) return;

    const id = this._localToRemoteID(systemGamepad.index);
    console.log(`Controller connected client side: ${systemGamepad.id}, id: ${id}`);
    this._registerController(id, systemGamepad.id + ' - ' + systemGamepad.index);

    // We keep meta information on connected controllers by the browsers index of the gamepad.
    // Note that there is no guarantee on what the index of the first connected gamepad is.
    this._listOfClientConnectedControllers[systemGamepad.index] = systemGamepad;
    this._listOfGamePadsState[systemGamepad.index] = new GamePadState(systemGamepad.id, systemGamepad.index);

    if (!this._getIsStartGamePadListener) {
      this._setStartGamePadListener(true);
      this._updateStatus();
    }
  };

  private _removeController = (event: GamepadEvent) => {
    //for the tests :(
    this._removeGamepad(event.gamepad);
  };

  private _removeGamepad = (systemGamepad: Gamepad) => {
    if (!systemGamepad) return;
    console.log(
      `Controller disconnected client side: ${systemGamepad.id}, id: ${this._localToRemoteID(systemGamepad.index)}`
    );

    const id = this._localToRemoteID(systemGamepad.index);
    this._sendAsJson('controller', 'disconnected', { id });

    this._listOfClientConnectedControllers = this._listOfClientConnectedControllers.filter((e) => {
      return e.index !== systemGamepad.index;
    });

    this._listOfServerConnectedControllers[id] = false;

    if (this._listOfClientConnectedControllers.length == 0) {
      this._setStartGamePadListener(false);
    }
  };

  private _scanGamepads = () => {
    const pads = navigator.getGamepads();

    for (let i = 0; i < pads.length; i++) {
      const pad = pads[i];
      if (pad) {
        if (pad.index in this._listOfClientConnectedControllers) {
          this._listOfClientConnectedControllers[pad.index] = pad;
        } else {
          this._tryAddGamepad(pad);
        }
      } else if (
        pad === null &&
        this._listOfClientConnectedControllers[i] !== null &&
        this._listOfClientConnectedControllers[i] !== undefined
      ) {
        this._removeGamepad(this._listOfClientConnectedControllers[i]);
      }
    }
  };

  private _handleRegularButtons = (
    gamepad: Gamepad,
    gamepadState: GamePadState,
    buttonIndex: number,
    transformedKeyCode: number,
    isPressed: boolean
  ) => {
    if (isPressed && !gamepadState.getPressedButton.has(buttonIndex)) {
      // send to the server
      this._sendButton(transformedKeyCode, isPressed, gamepad);
      // and add this button from Set
      gamepadState.setPressedButton(buttonIndex);
    } else if (!isPressed && gamepadState.getPressedButton.has(buttonIndex)) {
      // send to the server
      this._sendButton(transformedKeyCode, isPressed, gamepad);
      // and remove this button from Set
      gamepadState.removePressedButton(buttonIndex);
    }
  };

  private _handleDiagonal = (
    gamepad: Gamepad,
    gamepadState: GamePadState,
    diagonal: number,
    xVal: boolean,
    yVal: boolean
  ) => {
    if (xVal && yVal && !gamepadState.getDPadDiagonals.has(diagonal)) {
      this._sendDPad(diagonal, gamepad);
      gamepadState.setDPadDiagonals(diagonal);
    } else if (!xVal && !yVal && gamepadState.getDPadDiagonals.has(diagonal)) {
      gamepadState.removeDPadDiagonals(diagonal);
    }
  };

  private _handleDiagonalPads = (pad: Gamepad, state: GamePadState) => {
    /**
     * DPad diagonals combinations
     */
    const up = pad.buttons[12].value != 0;
    const down = pad.buttons[13].value != 0;
    const left = pad.buttons[14].value != 0;
    const right = pad.buttons[15].value != 0;

    this._handleDiagonal(pad, state, this._UP_LEFT_ID, left, up);
    this._handleDiagonal(pad, state, this._UP_RIGHT_ID, right, up);
    this._handleDiagonal(pad, state, this._DOWN_LEFT_ID, left, down);
    this._handleDiagonal(pad, state, this._DOWN_RIGHT_ID, right, down);

    if (state.getDPadDiagonals.size > 0) {
      state.setDiagonalInteraction(true);
      return true;
    }

    if (state.getDiagonalInteraction === true && state.getDPadDiagonals.size === 0) {
      state.setDiagonalInteraction(false);
      this._sendDPad(0, pad);
    }
  };

  private _handleDPadButtons = (
    gamepad: Gamepad,
    gamepadState: GamePadState,
    buttonIndex: number,
    transformedKeyCode: number,
    isPressed: boolean
  ) => {
    /**
     * Regular DPad buttons
     */
    if (isPressed && !gamepadState.getPressedButton.has(buttonIndex)) {
      this._sendDPad(transformedKeyCode, gamepad);
      gamepadState.setPressedButton(buttonIndex);
    } else if (!isPressed && gamepadState.getPressedButton.has(buttonIndex)) {
      this._sendDPad(0, gamepad);
      gamepadState.removePressedButton(buttonIndex);
    }
  };

  private _handleLT = (
    gamepad: Gamepad,
    gamepadState: GamePadState,
    gamepadButton: GamepadButton,
    buttonIndex: number,
    isPressed: boolean
  ) => {
    if (isPressed) {
      this._sendAxis(2, gamepadButton.value, gamepad);
      gamepadState.setPressedButton(buttonIndex);
    } else {
      if (gamepadState.getPressedButton.has(buttonIndex)) {
        this._sendAxis(2, -1, gamepad);
        gamepadState.removePressedButton(buttonIndex);
      }
    }
  };

  private _handleRT = (
    gamepad: Gamepad,
    gamepadState: GamePadState,
    gamepadButton: GamepadButton,
    buttonIndex: number,
    isPressed: boolean
  ) => {
    if (isPressed) {
      this._sendAxis(5, gamepadButton.value, gamepad);
      gamepadState.setPressedButton(buttonIndex);
    } else {
      if (gamepadState.getPressedButton.has(buttonIndex)) {
        this._sendAxis(5, -1, gamepad);
        gamepadState.removePressedButton(buttonIndex);
      }
    }
  };

  private _handleCheckGamepadAxis = (gamepad: Gamepad, gamepadState: GamePadState) => {
    for (let i = 0; i < gamepad.axes.length; i++) {
      const axesCode = this._webGamePadHelperAxesCode.transformAxesCode(i);

      if (Math.abs(gamepad.axes[i]) >= this._deadzone) {
        this._sendAxis(axesCode, gamepad.axes[i], gamepad);
        gamepadState.setPadAxis(i, 1);
      } else {
        if (gamepadState.getPadAxis(i) == 1) {
          this._sendAxis(axesCode, 0, gamepad);
          gamepadState.setPadAxis(i, 0);
        }
      }
    }
  };

  private _updateStatus = () => {
    this._scanGamepads();

    for (const gamepadIndex in this._listOfClientConnectedControllers) {
      if (this.isPaused) break;

      const gamepad = this._listOfClientConnectedControllers[gamepadIndex];
      const gamepadState = this._listOfGamePadsState[gamepadIndex];

      if (!gamepad) return;

      //check gamepad buttons
      for (let buttonIndex = 0; buttonIndex < gamepad.buttons.length; buttonIndex++) {
        const gamepadButton = gamepad.buttons[buttonIndex];
        const transformedKeyCode = this._webGamePadHelperKeycode.transformKeyCode(buttonIndex);
        const isPressed = gamepadButton.pressed;
        switch (buttonIndex) {
          // Regular buttons
          case 0: // A
          case 1: // B
          case 2: // X
          case 3: // Y
          case 4: // LB
          case 5: // RB
          case 8: // Back
          case 9: // Start
          case 10: // LS
          case 11: // RS
          case 16: // Guide
            this._handleRegularButtons(gamepad, gamepadState, buttonIndex, transformedKeyCode, isPressed);
            break;

          // DPad;
          case 12: // up
          case 13: // down
          case 14: // left
          case 15: {
            // right
            const isDiagonal = this._handleDiagonalPads(gamepad, gamepadState);

            if (isDiagonal) break;

            this._handleDPadButtons(gamepad, gamepadState, buttonIndex, transformedKeyCode, isPressed);
            break;
          }
          // LT
          case 6:
            this._handleLT(gamepad, gamepadState, gamepadButton, buttonIndex, isPressed);
            break;

          // RT
          case 7:
            this._handleRT(gamepad, gamepadState, gamepadButton, buttonIndex, isPressed);
            break;
        }
      }

      //check gamepad axis
      this._handleCheckGamepadAxis(gamepad, gamepadState);
    }

    if (this._listOfClientConnectedControllers.length != 0) {
      this._rafId = window.requestAnimationFrame(this._updateStatus);
    }
  };

  public get maxAxis(): number {
    return this._MAX_AXIS;
  }

  public vibrateController(deviceId: number, left: number, right: number): void {
    const gamePad = this._listOfClientConnectedControllers.find((controller) => controller?.index == deviceId);

    /**
     * Checking if there is vibration support on gamepad
     *
     * We are faced with situation when gamepad is support vibration,
     * but if user using bluetooth connection [gamePad.vibrationActuator] === null,
     * if user using cable connection [gamePad.vibrationActuator] != null and works correctly
     * So we added one more condition when [gamePad.vibrationActuator] is exists but value is null
     */
    if (!gamePad || !('vibrationActuator' in gamePad)) return;
    if (!gamePad['vibrationActuator']) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gamePad?.vibrationActuator?.playEffect('dual-rumble', {
      startDelay: 0,
      duration: 400,
      weakMagnitude: right / this._MAX_RUMBLE,
      strongMagnitude: left / this._MAX_RUMBLE,
    });
  }

  private get _getIsStartGamePadListener(): boolean {
    return this._isStartGamePadListener;
  }

  private _setStartGamePadListener(val: boolean) {
    this._isStartGamePadListener = val;
  }

  private _registerController = (id: number, name: string) => {
    this._sendAsJson('controller', 'connected', { id, name });

    /**
     * Add an interval here to ensure that all controllers are connected on server
     * */
    const intervalID: NodeJS.Timeout = setInterval(() => {
      if (this._listOfServerConnectedControllers[id] == true) {
        return clearInterval(intervalID);
      }
      this._sendAsJson('controller', 'connected', { id, name });
    }, this._INTERVAL_TO_CHECK_GAMEPAD);

    this._intervalIDs.push(intervalID);
  };

  private _sendButton = (button: number, isPressed: boolean, pad: Gamepad) => {
    const value = isPressed ? 1 : 0;

    const id = this._localToRemoteID(pad.index);
    this._sendAsJson('controller', 'button', { id, button, value });
  };

  private _sendDPad = (hat: number, pad: Gamepad) => {
    const id = this._localToRemoteID(pad.index);
    this._sendAsJson('controller', 'pad', { id, hat });
  };

  private _sendAxis = (axes: number, val: number, pad: Gamepad) => {
    const id = this._localToRemoteID(pad.index);
    const value = Math.round(val * this.maxAxis);
    this._sendAsJson('controller', 'axes', { id, axes, value });
  };
}
