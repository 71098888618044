import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton } from '@utomik-app-monorepo/components';
import { TextInputField } from '@utomik-app-monorepo/components';
import { Routes } from '@utomik-app-monorepo/constants';
import { ClientControllerContext, NavigationContext, NavigationControllerContext, NewsItemStoreContext, SearchResultStoreContext } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import logoIcon from 'images/svg/logo.svg';
import profileIcon from 'images/svg/profile_avatar.svg';
import notificationIcon from 'images/svg/sidebar/Notification.svg';
import { observer } from 'mobx-react';
import React, { useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './top-bar.module.scss';
type Props = {
  hideProfileNewsSection?: boolean;
  showBackButton?: boolean;
};
export const TopBar = withFocusable()<Props>(observer(({
  hideProfileNewsSection,
  showBackButton
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationController = useContext(NavigationControllerContext);
  const newsItemStore = useContext(NewsItemStoreContext);
  const searchResultStore = useContext(SearchResultStoreContext);
  const clientController = useContext(ClientControllerContext);
  const {
    backHandler
  } = useContext(NavigationContext);
  const handleSearchPress = () => {
    if (location.pathname !== Routes.Search) {
      searchResultStore.clear();
      searchResultStore.scrollState.clearState();
      navigate(Routes.Search);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchPress();
    searchResultStore.setSearchTerm(() => e.target.value, clientController.user.isTesterOrAdmin);
  };
  const handleProfilePress = async () => {
    if (location.pathname !== Routes.Profile) {
      navigate(Routes.Profile);
    }
  };
  const handleNewsPress = async () => {
    if (location.pathname !== Routes.News) {
      navigate(Routes.News);
    }
  };
  const newsBadgeCounter = useMemo(() => newsItemStore.unreadCount > 0 ? <div className={styles.newsCountBadgeContainer}>
            {newsItemStore.unreadCount > 99 ? '99+' : newsItemStore.unreadCount}
          </div> : null, [newsItemStore.unreadCount]);
  return <div className={cx(styles.container, {
    [styles.containerSearchView]: showBackButton
  })}>
        <div className={styles.logoContainer}>
          <img src={logoIcon} />
        </div>

        {showBackButton && <FocusableButton type={'back'} showOutline={navigationController.isGamepadInteraction} className={styles.backButton} onEnterPress={backHandler} />}

        <div className={styles.iconsContainer}>
          <TextInputField value={searchResultStore.searchTerm} showOutline={navigationController.isGamepadInteraction} focusKey={'search-field'} placeholder={t({
        message: 'Search games'
      })} className={styles.searchField} onChange={handleInputChange} onEnterPress={handleSearchPress} onClick={handleSearchPress} type={'search'} />

          {!hideProfileNewsSection && <div className={styles.rightSide}>
              <FocusableButton onEnterPress={handleNewsPress} type={'transparent'} showOutline={navigationController.isGamepadInteraction} absoluteElement={newsBadgeCounter}>
                <img className={styles.notificationIcon} src={notificationIcon} />
              </FocusableButton>
              <FocusableButton onEnterPress={handleProfilePress} type={'transparent'} showOutline={navigationController.isGamepadInteraction}>
                <img className={styles.profileIcon} src={clientController.user?.avatarUrl || '*****'} onError={e => {
            (e.target as HTMLImageElement).src = profileIcon;
            (e.target as HTMLImageElement).style.opacity = '0.6';
          }} />
              </FocusableButton>
            </div>}
        </div>
      </div>;
}));