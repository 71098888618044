import { ObservableMap, action, computed, makeObservable } from 'mobx';

import { RequestQueue, RetryValue } from '../../requestQueue/requestQueue';
import { AsyncObject, AsyncObjectState, ObjectID } from './asyncObject';

/**
 * Base class for data objects
 */
export interface BaseObject {
  id: ObjectID;
}

/**
 * An Object Store contains objects that are 'complete' when loaded from the API. It differs from the AsyncObject-store in that those objects
 * need an additional call to fully load the object.
 * Items in the regular ObjectStore do not have AsyncState.
 */
export abstract class ObjectStore<Item extends BaseObject, ApiItem = Item> extends AsyncObject<ApiItem[]> {
  protected itemsMap: ObservableMap<ObjectID, Item> = new ObservableMap<number, Item>();

  public getBaseUrl(): string {
    return this.url;
  }

  protected constructor(requestQueue: RequestQueue, url: string, retries?: RetryValue) {
    super(requestQueue, null, url, null, retries);
    this.requestQueue = requestQueue;
    this.url = url;
    this.retries = retries;
    makeObservable(this);
  }

  /** getItem was moved to the child classes, because not everything has a name, and a class might want to do different things when getting items. */

  @computed
  public get items(): Item[] {
    return Array.from(this.itemsMap.values());
  }

  protected abstract createAndLoadItem(apiItem: ApiItem): Item;

  @action
  protected load(object: ApiItem[]): void {
    const translatedItems = object.map((apiItem: ApiItem): [ObjectID, Item] => {
      const item = this.createAndLoadItem(apiItem);
      return [item.id, item];
    });

    this.itemsMap.replace(translatedItems);
  }

  @action
  public unload(): void {
    this.itemsMap.clear();
    this.setState(AsyncObjectState.None);
  }
}
