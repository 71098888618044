import { useEffect, useState } from 'react';

export const useVisibilityChange = (dispose: () => void) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    const onVisibilityChange = () => {
      setIsHidden(document.hidden);
      dispose();
    };
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') return;
    // Learn more: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [dispose]);

  return isHidden;
};
