import * as Yup from 'yup';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Routes } from '@utomik-app-monorepo/constants';
import { ClientControllerContext, Countries } from '@utomik-app-monorepo/store';
import { User } from '@utomik-app-monorepo/store';
import { UtomikError } from '@utomik-app-monorepo/store';
import { DialogProps, DialogResult } from '@utomik-app-monorepo/store';
import { convertToHTTPRange, isNullOrUndefined, parseMinutesFromThrottledError } from '@utomik-app-monorepo/utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FocusableButton } from '../focusable-button/focusable-button';
import { FocusableCountrySelect } from '../focusable-country-select/focusable-country-select';
import { FocusableDatePicker } from '../focusable-date-picker/focusable-date-picker';
import { FocusableInput } from '../focusable-input/focusable-input';
import { FocusableSelect } from '../focusable-select/focusable-select';
import { isValidDateTest, not18YearsOldTest } from './complete-profile-form-helper';
type LoginFormProps = {
  user: User;
  countries: Countries;
  action: DialogProps['action'];
};
export const CompleteProfileForm: FC<LoginFormProps> = withFocusable()(observer(function CompleteProfileForm({
  user,
  countries,
  action,
  setFocus
}) {
  const clientController = useContext(ClientControllerContext);
  const navigate = useNavigate();
  const {
    i18n
  } = useLingui();
  const [error, setError] = useState<{
    message: string;
    minutes?: number;
  }>(null);
  const initialValues = {
    username: user?.patchUserValues?.username || '',
    country: user?.patchUserValues?.country || undefined as number,
    birthdate: user?.patchUserValues?.birthdate || moment().subtract(18, 'years').format('YYYY-MM-DD'),
    gender: user?.patchUserValues?.gender || clientController.genders[0].id
  };
  const validationShape = {
    username: Yup.string().required(i18n.t({
      id: '{field} required.',
      message: '{field} required.',
      comment: 'Input field error',
      values: {
        field: t({
          message: `Username`
        })
      }
    })).min(3, i18n.t({
      id: '{field} must contain at least {value} characters.',
      message: '{field} must contain at least {value} characters.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Username'),
        value: 3 .toLocaleString(i18n.locale)
      }
    })).max(32, i18n.t({
      id: '{field} must contain max {value} characters.',
      message: '{field} must contain max {value} characters.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Username'),
        value: 32 .toLocaleString(i18n.locale)
      }
    })).matches(/^[a-zA-Z0-9]+$/, i18n.t({
      id: '{field} may contain letters (uppercase or lowercase a-z) and numbers (0-9).',
      message: '{field} may contain letters (uppercase or lowercase a-z) and numbers (0-9).',
      comment: 'Input field error',
      values: {
        field: i18n.t('Username')
      }
    })).test('username', i18n.t({
      comment: 'Input field error',
      id: 'Another user already has this username.',
      message: 'Another user already has this username.'
    }), () => error?.message !== 'COMPLETE_PROFILE_USERNAME_TAKEN'),
    birthdate: Yup.string().required(i18n.t({
      id: '{field} required.',
      message: '{field} required.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Date of Birth')
      }
    })).test('birthdate', i18n.t({
      id: '{field} is not valid.',
      message: '{field} is not valid.',
      comment: 'Input field error',
      values: {
        field: i18n.t('Date of Birth')
      }
    }), isValidDateTest).test('birthdate', i18n.t('You have to be over 18 years old.'), not18YearsOldTest),
    country: Yup.number().required(i18n.t({
      id: '{field} required.',
      comment: 'Input field error',
      message: '{field} required.',
      values: {
        field: i18n.t('Country')
      }
    })),
    // .test('country', t('COMPLETE_PROFILE_V_COUNTRY_NOT_SUPPORTED'), (id: number) =>
    //   countryNotSupportedTest(id, countries)
    // ),
    gender: Yup.string()
  };
  const validationSchema = Yup.object().shape(validationShape);
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    //validateOnMount: true,
    onSubmit: async (values, {
      setSubmitting,
      setErrors,
      validateForm
    }): Promise<void> => {
      try {
        setErrors({});
        setError(undefined);
        setSubmitting(true);

        // Patch the user
        await user.patchUser(values);
        await user.reFetch();
        navigate(Routes.Home, {
          replace: true
        });
        action(DialogResult.OK);
        setSubmitting(false);
      } catch (error: any) {
        handleError(error);
        // we need to validate the form again after an error in patching
        await validateForm();
        setSubmitting(false);
      }
    }
  });
  const [username, setUsername] = useState(formik.values.username);
  const currentDate = useMemo(() => {
    const date = moment(formik.values.birthdate);
    return {
      date: date.date(),
      month: date.month(),
      year: date.year()
    };
  }, []);
  const handleError = (error: UtomikError): void => {
    const errorMessage = error.message;
    let statusCodeOrRange: number;
    if (!isNullOrUndefined(error.statusCode && error.statusCode !== 429)) {
      statusCodeOrRange = convertToHTTPRange(error.statusCode);
    }

    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.error(`CompleteProfileForm.onSubmit() exception. (Reason: ${errorMessage})`);
    // possible errors:
    // - username taken: "USER_NAME_TAKEN" (platform doesn't properly return this yet, though)
    // - email taken: "EMAIL_TAKEN" (platform doesn't properly return this yet, though)
    // - country ID invalid: "Invalid pk (...)" (form prevents this, though)
    // - age too young/in future: "AGE_UNDER_18" (form prevents this, though)
    switch (errorMessage) {
      case 'REQUEST_TIMED_OUT':
        setError({
          message: i18n.t({
            comment: 'Input field error',
            message: 'A timeout occurred while trying to submit your profile information. Please try again later.',
            id: 'A timeout occurred while trying to submit your profile information. Please try again later.'
          })
        });
        return;
      case 'Revoked token.':
        setError({
          message: i18n.t({
            comment: 'Input field error',
            id: 'Your login token has expired. Please click \u0027Back\u0027 and log in again.',
            message: 'Your login token has expired. Please click \u0027Back\u0027 and log in again.'
          })
        });
        return;
      case 'USER_NAME_TAKEN':
        setError({
          message: i18n.t({
            comment: 'Input field error',
            message: 'Another user already has this username.',
            id: 'Another user already has this username.'
          })
        });
        return;
      case 'EMAIL_TAKEN':
        setError({
          message: i18n.t({
            comment: 'Input field error',
            message: 'This email address is already in use.',
            id: 'This email address is already in use.'
          })
        });
        return;
      default:
        switch (statusCodeOrRange) {
          case 429:
            {
              // Show a message for too many requests, for the user that likes to brute force this.
              const minutes = parseMinutesFromThrottledError(errorMessage);
              setError({
                message: i18n.t({
                  id: 'Too many requests. Please try again in {minutes} minutes.',
                  message: 'Too many requests. Please try again in {minutes} minutes.',
                  comment: 'Input field error',
                  values: {
                    minutes
                  }
                })
              });
              return;
            }
          case 400:
            setError({
              message: i18n.t({
                comment: 'Input field error',
                message: 'Something went wrong. Please contact support.',
                id: 'Something went wrong. Please contact support.'
              })
            });
            return;
          case 500:
            setError({
              message: i18n.t({
                comment: 'Input field error',
                message: 'Something went wrong. Please try again later.',
                id: 'Something went wrong. Please try again later.'
              })
            });
            return;
          default:
            setError({
              message: i18n.t({
                comment: 'Input field error',
                message: 'Unknown error occurred',
                id: 'Unknown error occurred'
              })
            });
            return;
        }
    }
  };
  useEffect(() => {
    formik.setErrors({});
    formik.setFieldValue('username', username);
  }, [username]);
  const handleCountrySelectChange = useCallback(country => {
    !formik.touched.country && formik.setFieldTouched('country');
    formik.setFieldValue('country', country.id);
  }, []);
  const handleDateSelectChange = useCallback(date => {
    !formik.touched.birthdate && formik.setFieldTouched('birthdate');
    formik.setFieldValue('birthdate', date);
  }, []);
  const handleGenderSelectChange = useCallback(val => {
    formik.setFieldValue('gender', clientController.getGenderByName(val.name)?.id);
    formik.validateField('gender');
  }, []);
  const currentGender = useMemo(() => clientController.getGenderById(formik.values.gender)?.name, [formik.values.gender]);
  return <form className="form" onSubmit={formik.handleSubmit} onChange={() => setError(undefined)}>
        <div className="complete-profile-form">
          <h1>
            <>{i18n.t('Username')}*:</>
          </h1>
          <FocusableInput width={'fullwidth'} inputId={'username'} value={formik.values.username} setValue={setUsername} onBlur={formik.handleBlur} onBecameFocused={() => setError(undefined)} errorMessage={formik.touched.username && formik.errors.username} focusable={!user?.patchUserValues?.username} />

          <h1>
            <>{i18n.t({
            comment: 'User`s date of birth',
            message: 'Date of Birth',
            id: 'Date of Birth'
          })}*:</>
          </h1>
          <FocusableDatePicker currentDate={currentDate} errorMessage={formik.touched.birthdate && formik.errors.birthdate} onChange={handleDateSelectChange} focusable={!user?.patchUserValues?.birthdate} onBecameBlurred={() => formik.setFieldTouched('birthdate')} />

          <h1>
            <>{i18n.t({
            comment: 'User`s country',
            id: 'Country',
            message: 'Country'
          })}*:</>
          </h1>
          <FocusableCountrySelect defaultCountry={formik.values.country} onChange={handleCountrySelectChange} countries={countries} errorMessage={formik.touched.country && formik.errors.country} focusable={!user?.patchUserValues?.country} onBecameBlurred={() => formik.setFieldTouched('country')} />

          <h1>
            <>{i18n.t({
            comment: 'User`s gender',
            id: 'Gender',
            message: 'Gender'
          })}:</>
          </h1>
          <FocusableSelect width={'wide'} values={clientController.genders} selectedValue={currentGender} onChange={handleGenderSelectChange} disabled={!!user?.patchUserValues?.gender && user?.patchUserValues?.gender !== 'u'} />

          <div className="buttons-container">
            <FocusableButton disabled={formik.isSubmitting || !formik.isValid || !formik.values.country} focusable={!(formik.isSubmitting || !formik.isValid || !formik.values.country)} type={'primary'} onEnterPress={formik.submitForm}>
              {i18n.t({
            comment: 'Button - After pressing this button user redirected into Utomik client app after profile completion',
            message: 'Start playing!',
            id: 'Start playing!'
          })}
            </FocusableButton>
          </div>
        </div>
      </form>;
}));