import { getCurrentVersion } from '@utomik-app-monorepo/utils';

import { WindowData } from '../tizen/interfaces/application';

export class VidaaWindowData extends WindowData {
  private readonly _appInfo: { version: string };
  private readonly _userAgent: string;

  constructor() {
    super();
    if (window.Hisense) {
      this._appInfo = {
        version: getCurrentVersion(),
      };
    }
    this._userAgent = window.navigator.userAgent;
  }

  get instance() {
    return this;
  }

  get deviceId(): string {
    return this._userAgent;
  }

  get getAppInfo(): { version: string } {
    return this._appInfo;
  }

  private _getOsVersion(): string {
    const versionPrefix = 'VIDAA/';
    const versionStart = this._userAgent.indexOf(versionPrefix) + versionPrefix.length;
    const versionEnd = this._userAgent.indexOf('(', versionStart);
    return this._userAgent.slice(versionStart, versionEnd).trim();
  }

  private _getBrand(): string {
    const brandStart = this._userAgent.indexOf('(', this._userAgent.indexOf('VIDAA/')) + 1;
    const brandEnd = this._userAgent.indexOf(';', brandStart);
    return this._userAgent.slice(brandStart, brandEnd);
  }

  private _getModel(): string {
    const modelPrefix = 'Model/';
    const modelStart = this._userAgent.indexOf(modelPrefix) + modelPrefix.length;
    const modelEnd = this._userAgent.indexOf(' ', modelStart);
    return this._userAgent.slice(modelStart, modelEnd);
  }

  get getDeviceInfo(): { version: string; model: string; brand: string } {
    return {
      version: this._getOsVersion(),
      model: this._getModel(),
      brand: this._getBrand(),
    };
  }

  get hasBluetoothSupport(): boolean {
    //TODO: ask about the method to check bluetooth support
    return true;
  }

  get allowedToPlayMainVideo(): boolean {
    return true;
  }
}
