import { ClientController } from '../../../app/global/clientController/clientController';
import { PlatformController } from '../../../app/global/platformController/platformController';
import { RequestQueue } from '../../requestQueue/requestQueue';
import { IssueTypes } from '../issueTypes/issueTypes';
import { AsyncObjectState } from '../objectStore/asyncObject';
import { LogsStore } from './logsStore';

export class WebosLogsStore extends LogsStore {
  constructor(
    requestQueue: RequestQueue,
    platformController: PlatformController,
    issueTypes: IssueTypes,
    clientController: ClientController
  ) {
    super(requestQueue, platformController, issueTypes, clientController);
  }

  protected createLogsFile = () => {
    return new Promise<boolean>((resolve, reject) => {
      window.webOS.service.request('luna://com.utomik.me.service', {
        method: 'createNewLogFile',
        parameters: {
          path: 'logs',
        },
        onSuccess() {
          resolve(true);
          return true;
        },
        onFailure(inError) {
          reject(inError);
          console.log(inError);
          return false;
        },
      });
    });
  };

  public addLogToFile = (log: string) => {
    window.webOS.service.request('luna://com.utomik.me.service', {
      method: 'addStringToLogFile',
      parameters: {
        string: log,
      },
      onSuccess: () => {
        return true;
      },
      onFailure: (inError) => {
        console.log(inError);
        return false;
      },
    });
  };

  public async deleteLogsFolder() {
    window.webOS.service.request('luna://com.utomik.me.service', {
      method: 'deleteFolder',
      parameters: {
        path: 'logs',
      },
      onSuccess: () => {
        return true;
      },
      onFailure: (inError) => {
        console.log(inError);
        return false;
      },
    });
  }

  public deleteLogFile(name: string) {
    window.webOS.service.request('luna://com.utomik.me.service', {
      method: 'deleteFile',
      parameters: {
        path: `logs/${name}`,
      },
      onSuccess: () => {
        return true;
      },
      onFailure: (inError) => {
        console.log(inError);
        return false;
      },
    });
  }

  public sendLogs = async () => {
    this.state = AsyncObjectState.Pending;

    window.webOS.service.request('luna://com.utomik.me.service', {
      method: 'getAllLogFiles',
      onSuccess: ({ Response: data }) => {
        this.sendLogFiles(data)
          .then(() => {
            this.state = AsyncObjectState.Done;
          })
          .catch(() => {
            this.state = AsyncObjectState.Error;
          });

        return true;
      },
      onFailure: (inError) => {
        this.state = AsyncObjectState.Error;
        console.log(inError);
        return false;
      },
    });
  };

  public dispose() {
    return false;
  }
}
