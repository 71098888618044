import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusableButton } from '@utomik-app-monorepo/components';
import { NavigationControls } from '@utomik-app-monorepo/components';
import Dialogs from '@utomik-app-monorepo/components';
import { LogsControllerContext, PlatformControllerContext } from '@utomik-app-monorepo/store';
import { Dialog, DialogResult } from '@utomik-app-monorepo/store';
import { LogEventAction, LogEventType } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import './dialog-renderer.scss';
export interface DialogRendererProps {
  dialog: Dialog;
}
export default withFocusable({
  blockNavigationOut: true
})<DialogRendererProps>(observer(function DialogRenderer({
  dialog,
  setFocus: setFocusFromParent
}): React.ReactElement {
  const logsController = useContext(LogsControllerContext);
  const platformController = useContext(PlatformControllerContext);

  //get current dialog react component from the default import
  const Content = Dialogs[dialog.analyticName];
  useEffect(() => {
    logsController.addNewLogEvent({
      type: LogEventType.Dialog,
      action: LogEventAction.Open,
      value: dialog?.analyticName
    });
    //Add interval for checking the pointer lock state and exit it if a dialog is opened
    const intervalId = setInterval(() => {
      if (document.pointerLockElement && platformController.isWeb) {
        document.exitPointerLock();
      }
    }, 200);
    return () => {
      clearInterval(intervalId);
      logsController.addNewLogEvent({
        type: LogEventType.Dialog,
        action: LogEventAction.Close,
        value: dialog?.analyticName
      });
    };
  }, []);
  const innerContent = <>
        {dialog.showHeader && <div className={'dialog-header'}>
            <div className={'dialog-title'}>{dialog.title}</div>
            <FocusableButton autofocus type={'close'} onEnterPress={() => dialog.action(DialogResult.Close)} focusKey={'dialog-close-button'} />
          </div>}
        <Content {...dialog.props} setFocusFromParent={setFocusFromParent} />
      </>;
  return <div className={`dialog theme--default`} onClick={e => e.stopPropagation()}>
        {dialog.withGamepadControls ? <NavigationControls>{innerContent}</NavigationControls> : innerContent}
      </div>;
}));