import { log } from '@utomik-app-monorepo/logger';
import { computed, makeObservable } from 'mobx';

import { AsyncObjectState } from '../objectStore/asyncObject';
import { GlobalAchievement, GlobalAchievementStore } from './globalAchievementStore';
import { GlobalStatisticStore } from './globalStatisticStore';
import { GlobalStatisticValueStore } from './globalStatisticValueStore';
import { GlobalUnlockedAchievementStore } from './globalUnlockedAchievementStore';
import { ServiceSettingStore } from './serviceSettingStore';

export interface NinjaFreeMonthSummary {
  eligibleToUnlockFreeMonths: boolean;
  freeMonthsReceived: number;
  freeMonthsUsed: number;
  ninjaAppsPlayedRequired: number;
  ninjaAppsPlayed: number;
  progress: number;
}

export interface NinjaLevelSummary {
  current: GlobalAchievement;
  next: GlobalAchievement;
  experience: number;
}

/**
 * NinjaSummary
 * WORK IN PROGRESS
 */
export class NinjaSummary {
  private readonly _globalAchievementStore: GlobalAchievementStore;
  private readonly _globalUnlockedAchievementStore: GlobalUnlockedAchievementStore;
  private readonly _globalStatisticStore: GlobalStatisticStore;
  private readonly _globalStatisticValueStore: GlobalStatisticValueStore;
  private readonly _serviceSettingStore: ServiceSettingStore;

  /**
   * Constructor for NinjaSummary
   * @param globalAchievementStore Required to load achievements
   * @param globalUnlockedAchievementStore Required to load user unlocked achievements
   * @param globalStatisticStore Required to load statistics
   * @param globalStatisticValueStore Required to load user statistics
   * @param serviceSettingStore Required to load service settings
   */
  public constructor(
    globalAchievementStore: GlobalAchievementStore,
    globalUnlockedAchievementStore: GlobalUnlockedAchievementStore,
    globalStatisticStore: GlobalStatisticStore,
    globalStatisticValueStore: GlobalStatisticValueStore,
    serviceSettingStore: ServiceSettingStore
  ) {
    this._globalAchievementStore = globalAchievementStore;
    this._globalUnlockedAchievementStore = globalUnlockedAchievementStore;
    this._globalStatisticStore = globalStatisticStore;
    this._globalStatisticValueStore = globalStatisticValueStore;
    this._serviceSettingStore = serviceSettingStore;
    makeObservable(this);
  }

  /***
   * Will load all required stores if needed.
   * Order: Achievements, UnlockedAchievements, Statistics, StatisticValues, ServiceSettings
   */
  public async fetch(): Promise<void> {
    log(`fetch()`);
    const promises = [];
    if (
      this._globalAchievementStore.state === AsyncObjectState.None ||
      this._globalAchievementStore.state === AsyncObjectState.Error
    )
      promises.push(this._globalAchievementStore.fetch());
    if (
      this._globalUnlockedAchievementStore.state === AsyncObjectState.None ||
      this._globalUnlockedAchievementStore.state === AsyncObjectState.Error
    )
      promises.push(this._globalUnlockedAchievementStore.fetch());
    if (
      this._globalStatisticStore.state === AsyncObjectState.None ||
      this._globalStatisticStore.state === AsyncObjectState.Error
    ) {
      promises.push(this._globalStatisticStore.fetch());
    }
    if (
      this._globalStatisticValueStore.state === AsyncObjectState.None ||
      this._globalStatisticValueStore.state === AsyncObjectState.Error
    )
      promises.push(this._globalStatisticValueStore.fetch());

    await Promise.all(promises);
  }

  /**
   * Reports the state of all stores aggegrated.
   * Takes presedence in order of None, Pending, Error and then Done.
   */
  @computed
  public get state(): AsyncObjectState {
    const states: AsyncObjectState[] = [
      this._globalAchievementStore.state,
      this._globalUnlockedAchievementStore.state,
      this._globalStatisticStore.state,
      this._globalStatisticValueStore.state,
      this._serviceSettingStore.state,
    ];
    if (states.indexOf(AsyncObjectState.None) > -1) return AsyncObjectState.None;
    else if (states.indexOf(AsyncObjectState.Pending) > -1) return AsyncObjectState.Pending;
    else if (states.indexOf(AsyncObjectState.Error) > -1) return AsyncObjectState.Error;
    else return AsyncObjectState.Done;
  }

  /**
   * Loads global unlocked achievements and global statistic values.
   */
  public async refreshUserData(): Promise<void> {
    await Promise.all([this._globalUnlockedAchievementStore.reFetch(), this._globalStatisticValueStore.reFetch()]);
  }

  /**
   * All unlocked achievements.
   */
  @computed
  private get _unlockedAchievements(): GlobalAchievement[] {
    return this._globalAchievementStore.items.filter((x) => x.unlockedAt);
  }

  /**
   * The current ninja level.
   */
  @computed
  public get highestUnlockedNinjaLevel(): GlobalAchievement {
    return this._unlockedAchievements
      .filter((achievement) => {
        return achievement?.group === 'ninja-level';
      })
      .sort((achievement) => {
        return achievement?.max;
      })
      .pop();
  }

  /**
   * The current ninja rank.
   */
  @computed
  public get highestUnlockedNinjaRank(): GlobalAchievement {
    return this._unlockedAchievements
      .filter((achievement) => {
        return achievement?.group === 'ninja-rank';
      })
      .sort((achievement) => {
        return achievement?.max;
      })
      .pop();
  }

  /**
   * The next ninja level.
   */
  @computed
  public get nextNinjaLevel(): GlobalAchievement {
    return this._globalAchievementStore.items.find(
      (x) => x.min === (this.highestUnlockedNinjaLevel?.max ?? 0) && x.group === 'ninja-level'
    );
  }

  /**
   * Ninja experience points
   */
  @computed
  public get experience(): number {
    return this._globalStatisticStore.items.find((x) => x.apiName === 'ninja-points')?.value ?? 0;
  }

  /**
   * Ninja apps required to unlock a free month. Defaults to 999, so that the progress circle can initiate by filling in, instead of running empty.
   */
  @computed
  public get ninjaAppsRequired(): number {
    return (
      (this._serviceSettingStore.items.find((x) => x.name === 'ninja_bsn_plays_for_free_month')?.value as number) ?? 999
    );
  }

  /**
   * Ninja apps required to play before a new month is unlocked.
   */
  @computed
  public get ninjaAppsPlayed(): number {
    // Calculated by the modulo of ninjaAppsRequired.
    return (
      (this._globalStatisticStore.items.find((x) => x.apiName === 'ninja-bsn-games-played')?.value ?? 0) %
      this.ninjaAppsRequired
    );
  }

  @computed
  public get eligibleToUnlockFreeMonths(): boolean {
    return true;
  }

  /**
   * Number of free months to enjoy
   */
  @computed
  public get numberOfFreeMonths(): number {
    const freeMonthsReceived =
      this._globalStatisticStore.items.find((x) => x.apiName === 'ninja-free-months-received')?.value ?? 0;
    const freeMonthsUsed =
      this._globalStatisticStore.items.find((x) => x.apiName === 'ninja-free-months-used')?.value ?? 0;
    return freeMonthsReceived - freeMonthsUsed;
  }
}
