import { log } from '@utomik-app-monorepo/logger';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';

import { RequestMethod, RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';
import { IHttpResponse } from '../../transports/httpTransport/httpTransport';
import { AsyncObject, AsyncObjectState } from '../objectStore/asyncObject';
import { UserAvatarStore } from '../userAvatarStore/userAvatarStore';
import { UserRatingStore } from '../userRatingStore/userRatingStore';
import { UserStat } from '../userStatsStore/userStat';
import { UserStatsStore } from '../userStatsStore/userStatsStore';

export interface PatchUserResponse {
  first_name?: string;
  last_name?: string;
  email?: string;
  username?: string;
  country?: {
    id: number;
  };
  gender?: ApiGender;
  birthdate?: string;
}

export type ApiGender = 'm' | 'f' | 'u';

export interface PatchUserValues {
  first_name?: string;
  last_name?: string;
  email?: string;
  username?: string;
  country?: number;
  gender?: ApiGender;
  birthdate?: string;
  avatar_url?: string;
}

export interface ApiUserSSOProvider {
  provider: string;
}

export interface ApiUserCountry {
  id: number;
  name: string;

  is_supported: boolean;
}

export interface ApiUser {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  gender: ApiGender;
  email: string;
  country: ApiUserCountry;
  birthdate: string;
  avatar_url: string;
  groups: string[];
  sso_providers: ApiUserSSOProvider[];
}

export interface ApiAvatarUpdate {
  avatar_url: string;
}

export enum RoleGroups {
  Public = 'Public',
  Registered = 'Registered',
  AppDevelopers = 'App5Developers',
  Subscription = 'Subscription',
  UtomikAdmins = 'UtomikAdmins',
  CloudUsers = 'Cloud User',
  CloudTesters = 'Cloud Tester',
  CloudAdmins = 'Cloud Admin',
  Ninjas = 'Ninjas',
  GamesCom = 'Gamescom2023',
}

export type CompleteProfileHandler = (user: User) => Promise<boolean>;

export class User extends AsyncObject<ApiUser> {
  private readonly userStatStore: UserStatsStore;
  private readonly userRatingStore: UserRatingStore;
  private readonly userAvatarStore: UserAvatarStore;
  private readonly _completeProfileHandler: CompleteProfileHandler;

  public constructor(
    requestQueue: RequestQueue,
    userStatStore: UserStatsStore,
    userRatingStore: UserRatingStore,
    userAvatarStore: UserAvatarStore,
    id: number,
    completeProfileHandler: CompleteProfileHandler
  ) {
    super(requestQueue, id, `v2/users/me`);
    makeObservable(this);
    this.userStatStore = userStatStore;
    this.userRatingStore = userRatingStore;
    this.userAvatarStore = userAvatarStore;
    this._completeProfileHandler = completeProfileHandler;
  }

  @observable
  private _apiUser: ApiUser;

  @action
  public load(apiUser: ApiUser): void {
    this._apiUser = apiUser;
  }

  @observable
  private _profileCompleted = false;

  @computed
  public get profileCompleted(): boolean {
    return this._profileCompleted;
  }

  @action
  private setProfileCompleted(status: boolean): void {
    this._profileCompleted = status;
  }

  /**
   * User username
   */
  @computed
  public get userName(): string {
    return this._apiUser?.username;
  }

  /**
   * User email
   */
  @computed
  public get email(): string {
    return this._apiUser?.email;
  }

  /**
   * User country
   */
  @computed
  public get country(): ApiUserCountry {
    return this._apiUser?.country;
  }

  /**
   * User avatar
   */
  @computed
  public get avatarUrl(): string {
    return this._apiUser?.avatar_url ? this._apiUser.avatar_url : undefined;
  }

  /**
   * User birthdate
   */
  @computed
  public get birthdate(): string {
    return this._apiUser?.birthdate;
  }

  /**
   * User is admin or not
   */
  @computed
  public get isAdmin(): boolean {
    return (this._apiUser?.groups || []).indexOf(RoleGroups.UtomikAdmins) > -1;
  }

  public get isTesterOrAdmin() {
    return this.isAdmin || this.isCloudAdmin || this.isCloudTester;
  }

  /**
   * User is cloud tester or not
   */
  @computed
  public get isCloudTester(): boolean {
    return (this._apiUser?.groups || []).includes(RoleGroups.CloudTesters);
  }

  /**
   * User is cloud user or not
   */
  @computed
  public get isCloudUser(): boolean {
    return (this._apiUser?.groups || []).includes(RoleGroups.CloudUsers);
  }

  /**
   * User is cloud admin or not
   */
  @computed
  public get isCloudAdmin(): boolean {
    return (this._apiUser?.groups || []).includes(RoleGroups.CloudAdmins);
  }

  /**
   * User is ninja or not
   */
  @computed
  public get isNinja(): boolean {
    return (this._apiUser?.groups || []).indexOf(RoleGroups.Ninjas) > -1 || this.isAdmin;
  }

  /**
   * User has any subscription or not
   */
  @computed
  public get hasSubscription(): boolean {
    const groups = this._apiUser?.groups || [];
    return Boolean(groups.includes(RoleGroups.Subscription) || groups.includes(RoleGroups.GamesCom));
  }

  /**
   * User is able to play or not
   */
  @computed
  public get canPlay(): boolean {
    const rolesAbleToPlay = [
      RoleGroups.CloudUsers,
      RoleGroups.CloudAdmins,
      RoleGroups.CloudTesters,
      RoleGroups.UtomikAdmins,
    ];

    const groups = this._apiUser?.groups || [];

    const includeGroup = Boolean(groups.find((group) => rolesAbleToPlay.includes(group as RoleGroups)));

    return Boolean(includeGroup && this.hasSubscription);
  }

  /**
   * User is cliq user or not
   */
  @computed
  public get isCliqUser(): boolean {
    return !isNullOrUndefined(this._apiUser?.sso_providers.find((x) => x.provider === 'cliq'));
  }

  @computed
  public get gamesPlayed(): number {
    return this.userStatStore.userStats.gamesPlayed;
  }

  @computed
  public get lastPlayedApplication(): UserStat {
    return this.userStatStore.userStats.lastPlayed;
  }

  @computed
  public get mostPlayedApplication(): UserStat {
    return this.userStatStore.userStats.mostPlayed;
  }

  @computed
  public get totalTimePlayedInSeconds(): number {
    return this.userStatStore.userStats.totalSecondsPlayed;
  }

  //TODO: yet ANOTHER paradigm for letting the outside world know what internal classes are doing...
  //Let's sit and think about this sometime - Roel
  @computed
  public get userStatStoreState(): AsyncObjectState {
    return this.userStatStore.state;
  }

  @computed
  public get userRatingStoreState(): AsyncObjectState {
    return this.userRatingStore.state;
  }

  @computed
  public get totalRatedGames(): number {
    return this.userRatingStore.items.reduce<number>((totalRatedGames): number => {
      totalRatedGames++;
      return totalRatedGames;
    }, 0);
  }

  @computed
  public get patchUserValues(): PatchUserValues {
    return {
      username: this._apiUser?.username,

      first_name: this._apiUser?.first_name,

      last_name: this._apiUser?.last_name,
      email: this._apiUser?.email,
      country: this._apiUser?.country?.id,
      gender: this._apiUser?.gender,
      birthdate: this._apiUser?.birthdate,
    };
  }

  @action
  private updateUser<T>(response: IHttpResponse<T>): void {
    // Create a new object to avoid {{TRIGGERING}} observables
    const apiUser = Object.assign({}, this._apiUser);
    // Patch each key in the user with the response if it exists.
    for (const key in apiUser) {
      if (response.data[key]) apiUser[key] = response.data[key];
    }
    // Update the user locally.
    this._apiUser = apiUser;
  }

  /**
   * Here we await a critical patch request.
   * If it succeeds we will update the local object and resolve.
   * @param values Complete Profile Values
   */
  public patchUser = flow(function* (this: User, values: PatchUserValues) {
    log(`Patching user.`);
    // Delete null or undefined values, because we don't want to post those.
    const copyValues = JSON.parse(JSON.stringify(values));

    for (const key in values) {
      if (isNullOrUndefined(copyValues[key]) || copyValues[key] === this.patchUserValues[key]) delete copyValues[key];
    }
    try {
      const promise = this.requestQueue.add<PatchUserResponse, PatchUserValues>(
        this.url,
        copyValues,
        RequestPriority.Critical,
        RequestMethod.Patch
      ).promise;
      const response = yield promise;
      this.updateUser(response);
      log(`Local User patched.`);
    } catch (error) {
      console.error(`User patch failed: ${error}.`);
      throw error;
    }
  });
  @action
  public setAvatar(url: string): void {
    this._apiUser.avatar_url = url;
  }

  /**
   * Here we await a new avatar image upload.
   * If it succeeds we will update the local object and resolve.
   */
  public uploadAvatar = flow(function* (this: User, image: File) {
    console.log('Uploading avatar.');
    try {
      if (!image) throw new Error('No image chosen');
      if (!this.userAvatarStore.isAcceptableImageType(image)) throw new Error('The file type is not acceptable');

      yield this.userAvatarStore.uploadImage({ tag: 'AV', file: image, filename: image.name });

      yield this.reFetch();

      console.log('Uploading avatar done.');
    } catch (e) {
      console.log('Uploading avatar error.');
      throw e;
    }
  });

  /**
   * Load the user and check if the profile is complete.
   * If the profile is not complete, call the completeProfileHandler.
   * If that resolves, the profile is considered complete.
   * If it rejects, the profile is incomplete or another issue occurred.
   */
  public async completeProfile(): Promise<void> {
    log(`Checking profile.`);
    this.setProfileCompleted(false);
    // Fetch the user, ensuring we have current data.
    await this.fetch();

    // Check if profile is complete.
    if (
      //(isNullOrUndefined(this._apiUser.email) && !this.isCliqUser) || // In the scenario of a Cliq user, we want to avoid asking for an email.
      isNullOrUndefined(this._apiUser.country) ||
      isNullOrUndefined(this._apiUser.birthdate) ||
      isNullOrUndefined(this._apiUser.username)
    ) {
      log(`Profile is incomplete.`);
      // Profile is not complete
      const result = await this._completeProfileHandler(this);

      if (result) {
        // Dialog resolved.
        log(`Profile completed.`);
      } else {
        log(`Profile was not completed.`);
        // Dialog did not resolve or was canceled.
        throw new Error('PROFILE_INCOMPLETE');
      }
    } else {
      log(`Profile is complete.`);
    }

    this.setProfileCompleted(true);
  }
}
