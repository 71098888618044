import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Achievement } from '@utomik-app-monorepo/store';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { Progress } from '../progress/progress';
import './achievement-tile.scss';
type Props = {
  isEmpty?: boolean;
  hiddenAchievementsCount?: number;
  achievement?: Achievement;
  focused?: boolean;
  isNew?: boolean;
};
export const AchievementTile = withFocusable()<Props>(observer(function AchievementTile({
  isEmpty,
  hiddenAchievementsCount,
  achievement,
  focused,
  isNew
}: Props) {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const handleLoadImage = useCallback(() => {
    setIsImageLoaded(true);
  }, []);
  let element;
  if (isEmpty) {
    element = <>
          <div className={'icon-container'}>
            <FontAwesomeIcon className={'trophy-icon'} icon={faTrophy} />
          </div>
          <div className={'text-container'}>
            <strong className={'title'}>
              <Trans context={'Message - No achievement tiles to show'}>No achievements</Trans>
            </strong>
            <p className={'text'}>
              <Trans context={'Message - Game does not have any achievements'}>
                This game doesn't have any achievements.
              </Trans>
            </p>
          </div>
        </>;
  } else if (hiddenAchievementsCount) {
    element = <>
          <div className={'icon-container'}>+{hiddenAchievementsCount}</div>
          <div className={'text-container'}>
            <strong className={'title'}>{`${hiddenAchievementsCount} ${t({
            id: '{hiddenAchievementsCount, plural, one {hidden achievement remaining} other {hidden achievements remaining}}'
          })}`}</strong>
            <p className={'text'}>{t({
            message: 'Details for each achievement will be revealed once unlocked'
          })}</p>
          </div>
        </>;
  } else {
    const imgUrl = achievement?.values?.unlockedAt ? achievement.images.unlocked : achievement.images.locked;
    const limitedValue = achievement?.values.value > achievement?.values.max ? achievement.values.max : achievement.values.value;
    element = <div className={'progress-achievement__wrapper'}>
          <div className={'progress-achievement__container'}>
            <div className={cx('icon-container', {
          'icon-container--loading': !isImageLoaded
        })}>
              <img decoding={'sync'} onLoad={handleLoadImage} src={imgUrl} />
            </div>
            <div className={'text-container'}>
              <strong className={'title'}>{achievement.name}</strong>
              <p className={'text'}>{achievement.description}</p>

              {Boolean(achievement?.values?.unlockedAt || achievement?.values?.value) && <Progress showCurrentValue className={'progress'} showStartEndValues={!!achievement.values.max} value={limitedValue || 1} minValue={achievement.values.min || 0} maxValue={achievement.values.max || 1} />}
            </div>
          </div>

          {isNew && <div className={'recently-achieved-label'}>{t({
          message: 'New'
        })}</div>}
        </div>;
  }
  return <div className={cx('achievement-tile__wrapper', {
    'achievement-tile__wrapper--focus': focused
  })}>{element}</div>;
}));