import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Application } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { AppRating } from '../app-rating/app-rating';
import './game-details.scss';
export const GameDetails = withFocusable()<{
  app: Application;
  onRatingFocused?: (props: any) => void;
  showRating?: boolean;
}>(observer(function GameDetails({
  app,
  onRatingFocused,
  showRating = true
}) {
  return <div className={'game-details__wrapper'}>
        <div className={'game-details__container'}>
          <table>
            <tbody>
              {!!app.originalReleaseDate && <tr>
                  <td>{t({
                context: 'Release date of a game',
                message: 'Release date'
              })}</td>
                  <td>{moment(app.originalReleaseDate).format('l')}</td>
                </tr>}
              {!!app.publishers?.length && <tr>
                  <td>{t({
                context: 'Publisher of a game',
                message: 'Publisher'
              })}</td>
                  <td>{app.publishers[0]?.name}</td>
                </tr>}
              {!!app.developers?.length && <tr>
                  <td>{t({
                context: 'Developer of a game',
                message: 'Developer'
              })}</td>
                  <td>{app.developers[0]?.name}</td>
                </tr>}
              {!!app.genres?.items?.length && <tr>
                  <td>{t({
                context: 'Gameplay type of a game',
                message: 'Gameplay'
              })}</td>
                  <td>
                    {app.genres?.items.map(g => g.name).slice(0, 4).join(', ')}
                  </td>
                </tr>}
              <tr>
                <td>{t({
                context: 'Available languages of a game',
                message: 'Languages'
              })}</td>
                <td>{app.supportedLanguages?.join(', ')}</td>
              </tr>
              {!!app.ageRatings?.items.length && app.ageRatings.items.map(r => <tr key={r.ageRating.id}>
                    <td>
                      <img decoding={'async'} alt={r.ageRating.rating} src={r.ageRating.imageUrl} />
                    </td>
                    <td>
                      {r.contentDescriptors.map(d => <p key={d.id}>{d.name}</p>)}
                    </td>
                  </tr>)}
            </tbody>
          </table>
        </div>
        {showRating && <div className={'game-rating__container'}>
            <AppRating app={app} analyticOrigin={['AppInfo']} onBecameFocused={onRatingFocused} />
          </div>}
      </div>;
}));