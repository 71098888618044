import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './get-subscription-dialog.scss';
type Props = {
  action: (action: DialogResult, value?: unknown) => void;
};
export const GetSubscriptionDialog: React.FC<Props> = observer(function GetSubscriptionDialog({
  action
}) {
  return <div className={'get-subscription-dialog__container'}>
      <p className={'get-subscription-dialog__title'}>{t({
        message: 'Manage subscription'
      })}</p>
      <p className={'get-subscription-dialog__text-explain'}>
        {t({
        context: 'The message shows if a user does not have Cloud subscription that is required for cloud gaming',
        message: 'A Utomik cloud subscription is needed in order to play on TV.  Do you wish to look at your upgrade options now? (You will be logged out.)'
      })}
      </p>
      <div className={'buttons-container'}>
        <FocusableButton onEnterPress={() => action(DialogResult.OK)} autofocus type={'primary'}>
          {t({
          message: 'OK'
        })}
        </FocusableButton>
        <FocusableButton onEnterPress={() => action(DialogResult.Cancel)} type={'secondary'}>
          {t({
          message: 'Cancel'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default GetSubscriptionDialog;