import { isFullscreen, isKeyboardLockSupportedByBrowser } from '../helpers/helpers';
import { BaseMsg, SendAsJsonFunc } from './wssStreamingController';

export class MouseDeltas {
  x: number = 0;
  y: number = 0;
}

export interface StandardCursorMsg extends BaseMsg {
  type: 'cursor';
  action: 'standard';
  visible: boolean;
  name: string;
}

export interface CustomCursorMsg extends BaseMsg {
  type: 'cursor';
  action: 'custom';
  visible: boolean;
  resource?: string;
  id: number;
}

export type CursorMsg = StandardCursorMsg | CustomCursorMsg;

export default class MouseController {
  private _domElement: HTMLElement;

  private _mouseMoveAutoInc = 0;
  private _cursorBase64Cache: string[] = [];

  private _cursorVisible = true;
  private _cursorVisibleChanged = false;
  private _usePointerCapture = true;

  private _sendAsJson: SendAsJsonFunc;

  private _fullscreenChangeListener = (): void => {};

  private _pointerLockChangeListener = (): void => {};

  private _pointerCaptureLostCb = (): void => {};

  constructor(
    sendAsJson: SendAsJsonFunc,
    domElement: HTMLElement,
    usePointerCapture: boolean,
    pointerCaptureLostCb: () => void
  ) {
    this._sendAsJson = sendAsJson;
    this._usePointerCapture = usePointerCapture;
    this._pointerCaptureLostCb = pointerCaptureLostCb;

    domElement.addEventListener('wheel', this._handleWheel, { passive: true });
    domElement.addEventListener('mousedown', this._handleMouseDown);
    domElement.addEventListener('mouseup', this._handleMouseUp);
    domElement.addEventListener('mousemove', this._handleMouseMove);

    this._domElement = domElement;
    this.lockOnFullscreen();

    // Hack:
    // If keyboard lock API is not supported, we try to simulate an
    // escape key press because the browser will capture it to exit lock and fullscreen mode.
    // If keyboard lock API is supported, this is not needed as the browser will exit lock
    // and fullscreen mode on an escape key long press. And thus an escape key press will
    // be a keyboard message like any other.
    if (this._usePointerCapture) {
      this._pointerLockChangeListener = (() => {
        console.debug(
          `Pointer magic with: ${this._cursorVisibleChanged}, ${this.pointerLocked()}, ${document.hasFocus()}`
        );

        if (this._cursorVisibleChanged) {
          this._cursorVisibleChanged = false;
          console.debug(`Do nothing`);
          return;
        }

        // We only call the pointer capture lost callback when we did not unlock the pointer
        // because the server side said so (this._cursorVisibleChanged == false)
        if (this._pointerCaptureLostCb && !this.pointerLocked()) {
          this._pointerCaptureLostCb();
        }

        if (!isKeyboardLockSupportedByBrowser() && !this.pointerLocked() && document.hasFocus()) {
          // if we are unlocking and focussed, we probably clicked escape to exit, so we simulate an escape key press
          this._sendAsJson('keyboard', 'button', { code: 27, isPressed: true, flags: 0 });
          this._sendAsJson('keyboard', 'button', { code: 27, isPressed: false, flags: 0 });
          console.debug(`Send something escaping`);
        }
      }).bind(this);
      document.addEventListener('pointerlockchange', this._pointerLockChangeListener);
    }
  }

  private lockOnFullscreen(): void {
    /**
     * We want to lock the mouse pointer when we go into fullscreen mode with the Fullscreen API
     * https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
     *
     * And if the feature is supported, we want to try to use the Keyboard Lock API to lock the escape key.
     * See KeyboardController.
     */
    this._fullscreenChangeListener = (() => {
      if (isFullscreen()) {
        this.lockPointer()
          .then(() => {
            console.log('mouseController', 'Pointer locked.');
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            console.log('streamController', `Failed to lock pointer: ${error}`);
          });
        return;
      }

      // If we go out of fullscreen but the pointer was not locked yet,
      // we do need to say that the pointer capture was lost because we intended it to be locked.
      // In other words, this is to make sure things go right in an edge where the pointerchanged event
      // will not happen.
      if (this._pointerCaptureLostCb && !this.pointerLocked()) {
        this._pointerCaptureLostCb();
      }
    }).bind(this);

    document.addEventListener('fullscreenchange', this._fullscreenChangeListener);
  }

  public async lockPointer(): Promise<void> {
    if (!this._usePointerCapture || this.pointerLocked()) {
      return Promise.resolve();
    }

    // If the server says the cursor must be visible, we do NOT lock anything
    if (this._cursorVisible) {
      return Promise.resolve();
    }

    try {
      // Based on mdn documentation found here: https://developer.mozilla.org/en-US/docs/Web/API/Pointer_Lock_API
      // The blink web engine used in electron supports `unadjustedMovement` in requestPointerLock(), but only on some OS-es.
      // This can improve mouse experience so we want to use it if available.
      // However, we have to use a workaround as typescript HTML bindings don't include non-standard function signatures.
      await this.requestPointerLockWrapper(this._domElement, {
        unadjustedMovement: true,
      });
    } catch {
      try {
        // try again without navUI parameter
        await this.requestPointerLockWrapper(this._domElement);
      } catch {
        if (this._pointerCaptureLostCb && !this.pointerLocked()) {
          this._pointerCaptureLostCb();
        }
      }
    }
  }

  public unlockPointer(): void {
    if (!this._usePointerCapture || !this.pointerLocked()) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc: any = document;
    if (doc?.exitPointerLock) {
      doc.exitPointerLock();
    } else if (doc?.webkitExitPointerLock) {
      /* Safari */
      doc.webkitExitPointerLock();
    } else if (doc?.mozExitPointerLock) {
      /* Firefox */
      return doc.mozExitPointerLock();
    }
  }

  public pointerLocked(): boolean {
    if (!this._usePointerCapture) return false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc: any = document;
    return (doc?.pointerLockElement || doc?.mozPointerLockElement || doc?.webkitPointerLockElement) == this._domElement;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async requestPointerLockWrapper(element: HTMLElement, navUI?: any): Promise<void> {
    // Because the pointer cannot be locked immediately after an unlock with the esc gesture,
    // We wrap the pointer lock request so it can try again a bit later.
    await this.requestPointerLock(element, navUI);
    if (this.pointerLocked()) return;

    setTimeout(() => {
      this.requestPointerLock(element, navUI);
    }, 1000);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private requestPointerLock(element: HTMLElement, navUI?: any): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const elem: any = element;
    if (elem?.requestPointerLock) {
      return navUI ? elem.requestPointerLock(navUI) : elem.requestPointerLock();
    } else if (elem?.webkitRequestPointerLock) {
      /* Safari */
      return navUI ? elem.webkitRequestPointerLock(navUI) : elem.webkitRequestPointerLock();
    } else if (elem?.mozRequestPointerLock) {
      /* Firefox */
      return navUI ? elem.mozRequestPointerLock(navUI) : elem.mozRequestPointerLock();
    }
    return Promise.resolve();
  }

  public dispose = () => {
    //console.debug(`mouseController`, `dispose`);

    this._domElement.removeEventListener('wheel', this._handleWheel);
    this._domElement.removeEventListener('mousedown', this._handleMouseDown);
    this._domElement.removeEventListener('mouseup', this._handleMouseUp);
    this._domElement.removeEventListener('mousemove', this._handleMouseMove);

    document.removeEventListener('fullscreenchange', this._fullscreenChangeListener);

    if (this._usePointerCapture) {
      document.removeEventListener('pointerlockchange', this._pointerLockChangeListener);
    }
  };

  public handleServerCursorMessage = (msg: CursorMsg) => {
    console.debug(`MouseController`, `handleServerCursorMessage`, msg.action);

    // If the server tells us that the cursor must be hidden,
    // we lock the pointer (aka capture the mouse, which hides the cursor).
    // Otherise we keep the pointer unlocked and visible.
    this._cursorVisibleChanged = true;
    this._cursorVisible = msg.visible;
    if (this._cursorVisible) {
      this.unlockPointer();
    } else {
      this.lockPointer();
    }

    let cursor: string = 'default';
    if (msg.action == 'standard') {
      console.debug(`[CUR] Standard cursor: '${msg.name}' with visibility ${msg.visible}`);
      cursor = msg.name;
    } else if (msg.action == 'custom') {
      let resource = msg.resource;
      if (resource) {
        this._cursorBase64Cache[msg.id] = resource;
      } else {
        resource = this._cursorBase64Cache[msg.id];
      }
      console.debug(`[CUR] custom cursor: ${msg.id}:'${msg.resource ?? 'null'}' with visibility ${msg.visible}`);
      if (resource) cursor = `url(data:application/cur;base64,${resource}), auto`;
    }

    if (this._cursorVisible) {
      this._domElement.style.cursor = cursor;
    } else {
      // Fallback for when the pointer lock is lost
      this._domElement.style.cursor = 'default';
    }
  };

  private _handleWheel = (evt: WheelEvent) => {
    if (!document.hasFocus()) return;
    // Send the event
    // For the protocol see: https://docs.google.com/document/d/1owUSRDvIfLPHAyrc5a_ZT0RRrv_tozAfgon5pq0QFnc#heading=h.7d3mdgdkpeb
    this._sendAsJson('mouse', 'wheel', {
      deltaX: -evt.deltaX,
      deltaY: -evt.deltaY,
    });
  };

  private _handleMouseDown = (evt: MouseEvent) => {
    this.lockPointer();
    this._handleMouseButton(evt, true);
  };

  private _handleMouseUp = (evt: MouseEvent) => {
    this._handleMouseButton(evt, false);
  };

  private _handleMouseButton = (evt: MouseEvent, isPressed: boolean) => {
    if (!this._cursorVisible && this.pointerLocked()) {
      this._sendAsJson('mouse', 'button', {
        dx: evt.movementX,
        dy: evt.movementY,
        btn: evt.button,
        isPressed,
      });
    } else {
      const [x, y] = this._mapMouseCoords(evt);
      this._sendAsJson('mouse', 'button', {
        x,
        y,
        btn: evt.button,
        isPressed,
      });
    }
  };

  private _handleMouseMove = (evt: MouseEvent) => {
    if (!document.hasFocus()) return;
    // For protocol see: https://docs.google.com/document/d/1owUSRDvIfLPHAyrc5a_ZT0RRrv_tozAfgon5pq0QFnc#heading=h.iuz9ezj31t3u.
    if (!this._cursorVisible && this.pointerLocked()) {
      this._sendAsJson('mouse', 'move', {
        dx: evt.movementX,
        dy: evt.movementY,
      });
    } else {
      const [x, y] = this._mapMouseCoords(evt);
      this._sendAsJson('mouse', 'move', {
        x,
        y,
        inc: this._mouseMoveAutoInc++,
      });
    }
  };

  /**
   * The json protocol for mouse move/click uses normalized mouse coordinates.
   * [0,0] maps to the top-left coordinate of the stream and [1,1] maps to the bottom-right.
   * This function maps the MouseEvent data to these relative coordinates.
   * @param evt The mouse event
   * @returns The [x,y] coordinates in the scale 0 to 1
   *
   *   x=0 is the left. x=1 is the right.
   *
   *   y=0 is the top.  y=1 is the bottom.
   */
  private _mapMouseCoords = (evt: MouseEvent): [number, number] => {
    if (this._domElement == undefined) {
      return [0, 0];
    }

    const videoElem = this._domElement as HTMLVideoElement;
    const streamWidth = videoElem.offsetWidth;
    const streamHeight = videoElem.offsetHeight;

    const videoRatio = videoElem.videoWidth / videoElem.videoHeight;
    const streamRatio = streamWidth / streamHeight;
    let displayWidth = streamWidth;
    let displayHeight = streamHeight;
    let displayX = 0;
    let displayY = 0;
    if (streamRatio > videoRatio) {
      // bars on the sides
      displayWidth = streamHeight * videoRatio;
      displayX = (streamWidth - displayWidth) / 2;
    } else {
      // bars on top and bottom
      displayHeight = streamWidth / videoRatio;
      displayY = (streamHeight - displayHeight) / 2;
    }

    let x = (evt.offsetX - displayX) / displayWidth;
    let y = (evt.offsetY - displayY) / displayHeight;
    x = Math.min(Math.max(x, 0), 1);
    y = Math.min(Math.max(y, 0), 1);
    return [x, y];
  };
}
