//import pkg from '../../package.json';
import { JanusJS } from 'janus-gateway';
import { action, autorun, computed, flow, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import JanusClient, { JanusState } from './janusClient';
import WSSStreamingController, { LaunchArgs, SettingsNativeAudioMsg } from './wssStreamingController';
import { ApiPlaySession, postPlaySession, postPlaySessionEnd, postPlaySessionStatistics } from '../models/playSessions';
import QueueController from './queueController';
import { StreamState, ConnectionState, StreamMessage, QueueState, ConnectionQuality } from '../streamTypes';
import { exceptionToString, isFullscreen } from '../helpers/helpers';
import { DeviceInfo } from '../components/streamViewProps';

const JANUS_DISPOSE_TIMEOUT = 20000;

type OnStateChanged = ((state: StreamState, queueState?: QueueState, playsessionID?: number) => void) | null;
type FullscreenChangeListener = () => void;

export default class StreamController {
  public readonly queueController: QueueController;

  private readonly _maxLostKeyframeCount = 15;
  private _videoElement: HTMLVideoElement | null = null;
  private _wssStreamingController: WSSStreamingController | null = null;
  private _janusVideoPlugin: JanusJS.PluginHandle | null = null;
  private _janusAudioPlugin: JanusJS.PluginHandle | null = null;
  private _janusVideoFastTimer: NodeJS.Timeout | number | undefined;
  private _janusVideoStatsTimer: NodeJS.Timeout | number | undefined;
  private _disposePromise: Promise<void> = Promise.resolve();

  private _pauseGamepadTimeout: NodeJS.Timeout | string | number | undefined;
  public mediaStream: MediaStream | null = null;
  private _lostPacketsArr: number[] = [];
  private readonly _dialogsReactionDisposer: IReactionDisposer;
  @observable
  private _onStateChanged: OnStateChanged = null;
  private _onConnectionQualityChanged: ((quality: ConnectionQuality) => void) | null = null;
  private _playSession: ApiPlaySession | null = null;
  private _playSessionBaseURL: string | null = null;
  private _playSessionToken: string | null = null;
  private _queueStateAutorunDisposer: IReactionDisposer | null = null;
  private _isFullscreen = false;
  private _fullscreenChangeListener: FullscreenChangeListener;

  @observable
  private _janusVideo: JanusClient | null = null;
  @observable
  private _janusAudio: JanusClient | null = null;

  @observable
  protected _state: StreamState = 'Closed';
  @observable
  private _connectionState: ConnectionState = 'good';

  @observable
  private _message: StreamMessage | null = null;

  @observable
  public lostKeyFrameCount = 0;

  @observable
  public lastFourConnectionStates: ConnectionState[] = [];

  public constructor() {
    this.lastFourConnectionStates = ['good', 'good', 'good', 'good'];
    this.queueController = new QueueController(() => {
      this._wssStreamingController?.queueInfo();
    });

    this._fullscreenChangeListener = (() => {
      this._isFullscreen = isFullscreen();
    }).bind(this);

    this._dialogsReactionDisposer = reaction(
      /**
       * TODO: Figure out when this has to pause, currently set to disabled.
       * Pause gamepad events handling while a modal is opened
       * */
      () => false, // TODO: Determine condition here.
      (has) => {
        clearTimeout(this._pauseGamepadTimeout);

        if (!this._wssStreamingController?.gamePadController) return false;

        if (has) {
          this._wssStreamingController.gamePadController.isPaused = true;
          return true;
        } else {
          this._pauseGamepadTimeout = setTimeout(() => {
            if (!this._wssStreamingController?.gamePadController) return; // NO-OP?
            this._wssStreamingController.gamePadController.isPaused = false;
            clearTimeout(this._pauseGamepadTimeout);
          }, 1000);
          return true;
        }
      }
    );

    this._queueStateAutorunDisposer = autorun(() => {
      //console.debug(`streamController`, !!this._onStateChanged, this._state, this.queueController.queue);
      const observables = {
        state: this._state,
        onStateChanged: this._onStateChanged,
        queue: this.queueController.queue,
      };
      if (observables.onStateChanged) {
        observables.onStateChanged(
          observables.state,
          observables.state === 'Queued' // Only send queueState when streamState is queued.
            ? Object.assign({}, observables.queue) //Observable objects may not be cloned, so return clean variable.
            : undefined,
          this._playSession?.id
        );
      }
    });

    makeObservable(this);
  }

  public get connectionState() {
    return this._connectionState;
  }

  @computed
  public get message() {
    return this._message;
  }

  @action
  public setConnectionState(state: ConnectionState) {
    this._connectionState = state;
    this.updateConnectionQuality(state);
  }

  @action
  public updateConnectionQuality(state: ConnectionState) {
    this.lastFourConnectionStates.shift();
    this.lastFourConnectionStates.push(state);
    // if the last 4 states are all good, send excellent; otherwise, send the latest state
    let connectionQuality: ConnectionQuality = state === 'good' ? 'good' : state === 'medium' ? 'medium' : 'low';
    if (
      connectionQuality === 'good' &&
      this.lastFourConnectionStates[0] === 'good' &&
      this.lastFourConnectionStates[1] === 'good' &&
      this.lastFourConnectionStates[2] === 'good'
    ) {
      connectionQuality = 'excellent';
    }
    if (this._onConnectionQualityChanged) {
      this._onConnectionQualityChanged(connectionQuality);
    }
  }

  public get maxLostKeyframeCount() {
    return this._maxLostKeyframeCount;
  }

  @computed
  public get state() {
    return this._state;
  }

  @action
  private _setState(state: StreamState) {
    this._state = state;
  }

  @action
  private _setOnStateChanged(onStateChanged: OnStateChanged) {
    this._onStateChanged = onStateChanged;
  }

  @computed
  public get isJanusDestroyed(): boolean {
    if (!this._janusVideo || !this._janusAudio) return false;

    return this._janusVideo.janusState === JanusState.destroyed && this._janusAudio.janusState === JanusState.destroyed;
  }

  @action
  public setMessage = (message: StreamMessage | null) => {
    this._message = message;
  };

  /**
   * Send a WS message to show the server whether the user is still active or not
   * */
  public sendActivityMessage(confirmResult: boolean) {
    if (confirmResult) {
      this._wssStreamingController?.ws?.send(
        JSON.stringify({
          type: 'settings',
          action: 'activity',
          value: 'I am here',
        })
      );
      return true;
    } else {
      this._wssStreamingController?.ws?.send(JSON.stringify({ type: 'CloseSessionEvent' }));
      return false;
    }
  }

  private openFullscreen(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const elem: any = document.body;
    if (elem?.requestFullscreen) {
      return elem.requestFullscreen();
    } else if (elem?.webkitRequestFullscreen) {
      /* Safari */
      return elem.webkitRequestFullscreen();
    } else if (elem?.mozRequestFullScreen) {
      /* Firefox */
      return elem.mozRequestFullscreen();
    }
    return Promise.resolve();
  }

  private closeFullscreen(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc: any = document;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.webkitExitFullscreen) {
      /* Safari */
      doc.webkitExitFullscreen();
    } else if (doc.mozExitFullScreen) {
      /* Firefox */
      return doc.mozExitFullscreen();
    }
  }

  public toggleFullscreen(): void {
    /**
     * This uses the Fullscreen API to toggle fullscreen mode:
     * https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
     */
    console.log(`streamController`, `Toggling fullscreen`);
    if (!this._isFullscreen) {
      this._isFullscreen = true;
      this.openFullscreen()
        .then(() => {
          console.log('streamController', 'Opened fullscreen');
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this._isFullscreen = false;
          console.log('streamController', `Failed to open fullscreen mode: ${error}`);
        });
      return;
    }

    this._isFullscreen = false;
    this.closeFullscreen();
    console.log('streamController', 'Closed fullscreen');
  }

  public lockPointer(): void {
    this._wssStreamingController?.mouseController?.lockPointer();
  }

  public setKeyboardLayoutLanguage(langId: number): void {
    this._wssStreamingController?.keyboardController?.setKeyboardLayoutLanguage(langId);
  }

  /**
   * init websocket connection
   * */
  private _initWS = flow(function* (
    this: StreamController,
    token: string,
    cloudToken: string,
    gatewayAddress: string,
    gatewayName: string,
    applicationId: number,
    applicationVersionId: number,
    width: number,
    height: number,
    baseURL: string,
    deviceInfo: DeviceInfo,
    guiLanguage: string | undefined,
    appLanguage: string | undefined,
    usePointerCapture: boolean,
    mobile: number,
    hardwareTag: number,
    pointerCaptureLostCb: () => void,
    onNativeAudioStreamSettingsCb?: (settings: SettingsNativeAudioMsg) => void,
    onGamepadRegistrationHookCb?: (gamepad: Gamepad, registerGamepad: (systemGamepad: Gamepad) => void) => void,
  ) {
    const isNativeAudioStream = !!onNativeAudioStreamSettingsCb;
    this.lostKeyFrameCount = 0;
    this._lostPacketsArr = [];
    this.setConnectionState('good');

    try {
      this._playSession = yield postPlaySession(baseURL, applicationVersionId, token);
      if (!this._playSession) throw new Error(`NO_PLAYSESSION`);
      yield postPlaySessionStatistics(baseURL, this._playSession.id, gatewayName, deviceInfo, token);
    } catch (e) {
      const error = exceptionToString(e);
      console.warn(`API error: ${error}`);
      this.setMessage({
        value: 'ApiError',
        type: 'error',
      });
    }

    try {
      this._playSessionToken = token;
      this._playSessionBaseURL = baseURL;
      const connectWS = () => {
        if (!this._videoElement) throw new Error('NO_VIDEO_ELEMENT');
        if (!this._playSession) throw new Error('NO_PLAYSESSION');

        const address = gatewayAddress.replace('https://', 'wss://');

        const url = `${address}/${isNativeAudioStream ? 'native' : ''}?${cloudToken}&clientVersion=${deviceInfo.client}-${deviceInfo.clientVersion}`;
        const launch_args: LaunchArgs = {
          jwt: token,
          playsessionId: `${this._playSession.id}`,
          appId: applicationId,
          appversionId: applicationVersionId,
          x: width,
          y: height,
          platform_id: null,
          cloudstreaming: deviceInfo.type,
          mobile,
          tag: hardwareTag,
        };
        if (guiLanguage) launch_args.guiLanguage = guiLanguage;
        if (appLanguage) launch_args.appLanguage = appLanguage;

        console.log(`Cloudsession starting. Gateway: ${address} playsessionid: ${this._playSession.id}".`);
        this._wssStreamingController = new WSSStreamingController(
          this._videoElement,
          url,
          launch_args,
          usePointerCapture,
          this._janusVideoInit,
          !isNativeAudioStream ? this._janusAudioInit : () => {},
          this.setMessage,
          async () => {
            await this.dispose();
          },
          (queue) => {
            this.queueController.setQueue(queue);
            if (queue.isQueue) this._setState('Queued');
          },
          pointerCaptureLostCb,
          onNativeAudioStreamSettingsCb,
          onGamepadRegistrationHookCb,
        );
      };

      if (
        !!this._wssStreamingController &&
        this._wssStreamingController.ws.readyState === this._wssStreamingController.ws.OPEN
      ) {
        this._wssStreamingController?.gamePadController?.dispose();
        this._wssStreamingController?.ws?.close();
        // Connect WS after the previous one is closed
        this._wssStreamingController.ws.onclose = connectWS;
      } else {
        connectWS();
      }
    } catch (e) {
      const error = exceptionToString(e);
      console.warn(`Stream error during initialization (initWS): ${error}`);
      this.setMessage({
        value: 'UnknownError',
        type: 'error',
      });
    }
  });

  /**
   * init janus
   * */
  public init = flow(function* (
    this: StreamController,
    videoElement: HTMLVideoElement | null,
    token: string,
    cloudToken: string,
    gatewayAddress: string,
    gatewayName: string,
    applicationId: number,
    applicationVersionId: number,
    width: number,
    height: number,
    baseURL: string,
    deviceInfo: DeviceInfo,
    guiLanguage: string | undefined,
    appLanguage: string | undefined,
    usePointerCapture: boolean,
    mobile: number,
    hardwareTag: number,
    onStateChanged: (state: StreamState, queueState?: QueueState, playsessionID?: number) => void,
    pointerCaptureLostCallback: () => void,
    onConnectionQualityChanged: (quality: ConnectionQuality) => void,
    onNativeAudioStreamSettings?: (settings: SettingsNativeAudioMsg) => void,
    onGamepadRegistrationHook?: (gamepad: Gamepad, registerGamepad: (systemGamepad: Gamepad) => void) => void,
  ) {
    yield this._disposePromise;

    document.addEventListener('fullscreenchange', this._fullscreenChangeListener);

    this._setOnStateChanged(onStateChanged);
    this._onConnectionQualityChanged = onConnectionQualityChanged;

    this._setState('Initializing');
    this.setMessage(null);

    try {
      if (!videoElement) throw new Error('NO_VIDEO_ELEMENT');
      this._videoElement = videoElement;
      // Create Janus instances
      this._janusVideo = new JanusClient(gatewayAddress);
      if (!onNativeAudioStreamSettings) this._janusAudio = new JanusClient(gatewayAddress);
      this.mediaStream = new MediaStream();

      yield this._initWS(
        token,
        cloudToken,
        gatewayAddress,
        gatewayName,
        applicationId,
        applicationVersionId,
        width,
        height,
        baseURL,
        deviceInfo,
        guiLanguage,
        appLanguage,
        usePointerCapture,
        mobile,
        hardwareTag,
        pointerCaptureLostCallback,
        onNativeAudioStreamSettings,
        onGamepadRegistrationHook,
      );
    } catch (e) {
      const error = exceptionToString(e);
      console.warn(`Stream error during initialization (init): ${error}`);
      this.setMessage({
        value: 'UnknownError',
        type: 'error',
      });
    }
  });

  private _handleVideoSuccess = (pluginHandle: JanusJS.PluginHandle, streamID: number) => {
    this._janusVideoPlugin = pluginHandle;

    const body = { request: 'watch', id: streamID };
    this._janusVideoPlugin.send({ message: body });
  };

  private _handleVideoError = (e: unknown) => {
    const error = exceptionToString(e);
    console.warn(`Stream error attaching plugin: ${error}`);
    this.setMessage({
      value: 'UnknownError',
      type: 'error',
    });
  };

  private _janusVideoInit = (streamID: number) => {
    if (!this._janusVideo || !this._janusVideo.client) throw new Error('VIDEO_NOT_INITIALIZED');
    this._janusVideo.client.attach({
      plugin: 'janus.plugin.streaming',
      error: this._handleVideoError,
      onmessage: this._handleVideoMessageJSEP,
      onremotetrack: this._handleRemoteVideoTrack,
      success: (pluginHandle) => this._handleVideoSuccess(pluginHandle, streamID),
      slowLink: (uplink: boolean, lost: number, mid: unknown) => {
        console.log(`${uplink} - ${lost} - ${mid}`);
      },
    });
  };

  private _handleVideoMessageJSEP = (msg: unknown, jsep?: JanusJS.JSEP) => {
    if (jsep) {
      if (!this._janusVideoPlugin) throw new Error(`VIDEO_NOT_INITIALIZED`);
      // Offer from the plugin, let's answer
      this._janusVideoPlugin.createAnswer({
        jsep: jsep,
        media: { audioSend: false, videoSend: false },
        success: (jsep: JanusJS.JSEP) => {
          if (!this._janusVideoPlugin) throw new Error(`VIDEO_NOT_INITIALIZED`);
          const body = { request: 'start' };
          this._janusVideoPlugin.send({ message: body, jsep: jsep });
        },
        error: (error: string) => {
          console.warn(`WebRTC error: ${error}`);
          this.setMessage({
            value: 'WebRTCError',
            type: 'error',
          });
        },
      });
    }
  };

  private _switchConnectionState = (absPacketsLost: number) => {
    this._lostPacketsArr.push(absPacketsLost);

    if (this._lostPacketsArr.length >= 3) {
      // Calculate the average packets lost count
      const avgLostPackets = this._lostPacketsArr.reduce((acc, curr) => acc + curr, 0) / this._lostPacketsArr.length;

      if (avgLostPackets > 10 || this.lostKeyFrameCount >= 3) {
        this.setConnectionState('low');
      } else if (avgLostPackets > 1 && avgLostPackets <= 10) {
        this.setConnectionState('medium');
      } else {
        this.setConnectionState('good');
      }
      // Remove the first element of the array to always measure the last three
      this._lostPacketsArr.shift();
    } else {
      this.setConnectionState('good');
    }
  };

  private _handleLostKeyFrameCount = (diffFramesDecoded: number) => {
    if (diffFramesDecoded <= 0 && !this.queueController.queue.isQueue) {
      this.lostKeyFrameCount++;
    } else if (this.lostKeyFrameCount != 0) {
      this.lostKeyFrameCount = 0;
    }
  };

  private _handleConnectionState = (diffFrameDecoded: number, diffPacketsLost: number) => {
    this._handleLostKeyFrameCount(diffFrameDecoded);

    if (navigator.onLine && !this.queueController.queue.isQueue) {
      if (this.lostKeyFrameCount >= this.maxLostKeyframeCount && this.message?.type !== 'error') {
        console.warn(`Connection was lost`);
        this.setMessage({
          value: 'ConnectionLost',
          type: 'error',
        });
      }

      const absPacketsLost = Math.abs(diffPacketsLost);

      if (absPacketsLost > 0 || this.lostKeyFrameCount >= 3) {
        this._switchConnectionState(absPacketsLost);
      } else {
        this._lostPacketsArr = [];
        this.setConnectionState('good');
      }
    } else {
      this._lostPacketsArr = [];
      this.lostKeyFrameCount = 0;
    }
  };

  private _sendStatisticMessage = (data: unknown) => {
    if (
      this._wssStreamingController &&
      this._wssStreamingController.ws.readyState === this._wssStreamingController.ws.OPEN
    ) {
      this._wssStreamingController.ws.send(JSON.stringify(data));
    }
  };

  private _handleRemoteVideoTrack = (track: MediaStreamTrack) => {
    if (this.state === 'Initialized') {
      return;
    }

    if (track.kind === 'video') {
      this._attachMediaStream(track);

      let startTime = 0;

      // There are two videoStats handlers.
      // * The normal 1000ms timer that informs the VM of the frame/bitrate data of the last second.
      // * A fast 100ms timer that checks if we lost the video signal and then requests a new keyframe.
      //
      // Extra info:
      // Ideally, these can be done by one timer, but JS timers have a ~10ms delay. The 100 ms timer is actually
      // triggered every 110 ms; if you used that timer for the bitrate data, it'd actually be sent
      // every 1100ms. Since this message is expected to represent 1s worth of data, the numbers would actually
      // be 10% off. By doing it separately it's only 1% off.

      // Stats for handleVideoFastStats
      const fastStats = {
        framesDecoded: 0, ///< Total frames decoded.
        packetsReceived: 0, ///< Total packets received.
      };
      // Stats for handleVideoStats
      const stats = {
        bytesReceived: 0, ///< Total bytes received.
        framesReceived: 0, ///< Total frames received.
        framesDecoded: 0, ///< Total frames decoded.
        packetsLost: 0, ///< Total packets lost.
        bytesReceivedMinute: 0, ///< Total bytes received (updates every 60 updates).
        framesReceivedMinute: 0, ///< Total frames received (updates every 60 updates).
        framesDecodedMinute: 0, ///< Total frames decoded (updates every 60 updates).
        packetsLostMinute: 0, ///< Total packets lost (updates every 60 updates).
        updateCounter: 0,
        timeLastMinute: 0,
      };

      const handleVideoFastStats = () => {
        this._janusVideoPlugin?.webrtcStuff?.pc?.getStats().then((value) => {
          value.forEach((res) => {
            if (res.type !== 'inbound-rtp') return;

            const { packetsReceived, framesDecoded, timestamp } = res;
            const diffPacketsReceived = packetsReceived != null ? packetsReceived - fastStats.packetsReceived : -1; // prettier-ignore
            const diffFramesDecoded = framesDecoded != null ? framesDecoded - fastStats.framesDecoded : -1; // prettier-ignore

            if (startTime === 0) startTime = timestamp;
            const time = (timestamp - startTime) / 1000;

            fastStats.framesDecoded = framesDecoded;
            fastStats.packetsReceived = packetsReceived;
            // Request keyframe if no frames were decoded in this interval.
            // NOTE: ideally, you'd skip requesting a keyframe if no frames had actually been sent. However,
            // there is no decent way of checking for that. packetsReceived seems to work, but packetsLost definitely
            // doesn't (the cause of frames not being decoded is often in the previous interval).
            if (diffPacketsReceived > 0 && diffFramesDecoded === 0) {
              console.debug(`${time}: request keyframe`);
              this._sendStatisticMessage({ type: 'stream', action: 'lostkeyframe', timestamp: timestamp }); // prettier-ignore
            }
          });
        });
      };

      const handleVideoStats = () => {
        this._janusVideoPlugin?.webrtcStuff?.pc?.getStats().then((value) => {
          value.forEach((res) => {
            if (res.type !== 'inbound-rtp') return;

            const { framesReceived, framesDecoded, packetsLost, bytesReceived, timestamp } = res; // prettier-ignore

            // QUESTION: Why is this here?!?
            if (!this._wssStreamingController) throw new Error(`STREAMINGCONTROLLER_NOT_INITALIZED`);
            if (!this._wssStreamingController.gamePadController) throw new Error('GAMEPADCONTROLLER_NOT_INITIALIZED');
            this._wssStreamingController.gamePadController.init();

            if (startTime === 0) startTime = timestamp;
            const time = (timestamp - startTime) / 1000;
            const timeRounded = Math.round(time * 10000) / 10000; // for logging, time only has to be precise to a tenth of a millisecond

            const diffFramesReceived = framesReceived != null ? framesReceived - stats.framesReceived : -1; // prettier-ignore
            const diffFramesDecoded = framesDecoded != null ? framesDecoded - stats.framesDecoded : -1; // prettier-ignore
            const diffPacketsLost = packetsLost != null ? packetsLost - stats.packetsLost : -1; // prettier-ignore
            const bitrate = bytesReceived != null ? (bytesReceived - stats.bytesReceived) * 8 : -1; // prettier-ignore

            stats.framesReceived = framesReceived;
            stats.framesDecoded = framesDecoded;
            stats.packetsLost = packetsLost;
            stats.bytesReceived = bytesReceived;

            const bitData = {
              type: 'stream',
              action: 'bitrate',
              timestamp: timestamp,
              framerateReceived: diffFramesReceived,
              framerateDecoded: diffFramesDecoded,
              realBitrate: bitrate,
              lossPacket: diffPacketsLost,
            };
            this._sendStatisticMessage(bitData);
            // Vu asked print all service info to console
            // only log if packets were lost - otherwise, debug is fine
            if (diffPacketsLost != 0) {
              console.log(
                `[WEBRTCs] ${timeRounded}: frRecv: ${diffFramesReceived}, frDec: ${diffFramesDecoded}, bitrate: ${bitrate}, pktloss: ${diffPacketsLost}`
              );
            } else {
              console.debug(
                `[WEBRTCs] ${timeRounded}: frRecv: ${diffFramesReceived}, frDec: ${diffFramesDecoded}, bitrate: ${bitrate}, pktloss: ${diffPacketsLost}`
              );
            }
            // every 60 seconds, log data for the past minute
            stats.updateCounter++;
            if (stats.updateCounter % 60 === 0) {
              const timeDiff = time - stats.timeLastMinute;
              const timeDiffRounded = Math.round(timeDiff * 10000) / 10000; // for logging, time only has to be precise to a tenth of a millisecond
              const diffFramesReceivedLastMinute = framesReceived != null ? framesReceived - stats.framesReceivedMinute : -1; // prettier-ignore
              const diffFramesDecodedLastMinute = framesDecoded != null ? framesDecoded - stats.framesDecodedMinute : -1; // prettier-ignore
              const diffPacketsLostLastMinute = packetsLost != null ? packetsLost - stats.packetsLostMinute : -1; // prettier-ignore
              const bitrateLastMinute = bytesReceived != null ? (bytesReceived - stats.bytesReceivedMinute) * 8 : -1; // prettier-ignore

              stats.timeLastMinute = time;
              stats.framesReceivedMinute = framesReceived;
              stats.framesDecodedMinute = framesDecoded;
              stats.packetsLostMinute = packetsLost;
              stats.bytesReceivedMinute = bytesReceived;

              const fRecAvg = Math.round((diffFramesReceivedLastMinute / timeDiff) * 100) / 100; // 2 decimals should be fine
              const fDecAvg = Math.round((diffFramesDecodedLastMinute / timeDiff) * 100) / 100; // 2 decimals should be fine
              const bRateAvg = Math.round(bitrateLastMinute / timeDiff); // this is a big number, no decimals needed
              console.log(
                `[WEBRTCm] ${timeRounded}, last ${timeDiffRounded} seconds, frRecv: ${fRecAvg}, frDec: ${fDecAvg}, bitrate: ${bRateAvg}, pktloss: ${diffPacketsLostLastMinute}`
              );
            }
            this._handleConnectionState(diffFramesDecoded, diffPacketsLost);
          });
        });
      };
      this._setState('Initialized');
      this._janusVideoFastTimer = setInterval(handleVideoFastStats, 100);
      this._janusVideoStatsTimer = setInterval(handleVideoStats, 1000);
    }
  };

  private _handleErrorAudio = (e: unknown) => {
    const error = exceptionToString(e);
    console.warn(`Stream error attaching plugin: ${error}`);
    this.setMessage({
      value: 'UnknownError',
      type: 'error',
    });
  };

  private _handleSuccessAudio = (pluginHandle: JanusJS.PluginHandle, streamID: number) => {
    this._janusAudioPlugin = pluginHandle;

    const body = { request: 'watch', id: streamID };
    this._janusAudioPlugin.send({ message: body });
  };

  private _handleMessageAudio = (msg: unknown, jsep?: JanusJS.JSEP) => {
    if (jsep?.sdp) {
      const stereo = jsep.sdp.indexOf('stereo=1') !== -1;
      if (!this._janusAudioPlugin) throw new Error(`AUDIO_NOT_INITIALIZED`);
      this._janusAudioPlugin.createAnswer({
        jsep: jsep,
        media: { audioSend: false, videoSend: false },
        // @ts-expect-error no types in lib
        customizeSdp: function (jsep: JanusJS.JSEP) {
          if (stereo && jsep?.sdp && jsep.sdp.indexOf('stereo=1') == -1) {
            // Make sure that our offer contains stereo too
            jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;stereo=1');
          }
        },
        success: (jsep: JanusJS.JSEP) => {
          const body = { request: 'start' };
          if (!this._janusAudioPlugin) throw new Error(`AUDIO_NOT_INITIALIZED`);
          this._janusAudioPlugin.send({ message: body, jsep: jsep });
        },
        error: (error: unknown) => {
          console.warn(`Web RTC error: ${error}`);
          this.setMessage({
            value: 'WebRTCError',
            type: 'error',
          });
        },
      });
    }
  };

  private _janusAudioInit = (streamID: number) => {
    let isInitialized = false;
    if (!this._janusAudio || !this._janusAudio.client) throw new Error('AUDIO_NOT_INITIALIZED');
    this._janusAudio.client.attach({
      plugin: 'janus.plugin.streaming',
      success: (pluginHandle: JanusJS.PluginHandle) => this._handleSuccessAudio(pluginHandle, streamID),
      error: this._handleErrorAudio,
      onmessage: this._handleMessageAudio,

      onremotetrack: (track: MediaStreamTrack) => {
        if (track.kind === 'audio' && !isInitialized) {
          this._attachMediaStream(track);
        }
        isInitialized = true;
      },
    });
  };

  /**
   * Get video element by id and attach the media stream to it
   * */

  private _attachMediaStream = (track: MediaStreamTrack) => {
    if (!this.mediaStream) throw new Error(`MEDIASTREAM_NOT_INITIALIZED`);
    if (!this._videoElement) throw new Error(`NO_VIDEO_ELEMENT`);
    /**
     * Attach the media stream to the element if both video and audio tracks are added
     * */
    this.mediaStream.addTrack(track.clone());
    this._videoElement.srcObject = this.mediaStream;
  };

  /**
   * Reset some controller fields
   * Moved to a private method to prevent creating a new promise if the previous one is unresolved yet
   * */
  private _dispose = flow(function* (
    this: StreamController,
    resolve: (value?: PromiseLike<void> | void) => void,
    reject: (value?: unknown) => void
  ) {
    let errorOccurred = null;

    this._setState('Closing');
    if (this._videoElement) {
      this._videoElement.srcObject = null;
      this._videoElement = null;
    }

    document.removeEventListener('fullscreenchange', this._fullscreenChangeListener);
    this._isFullscreen = false;

    try {
      // send the end session request
      if (this._playSession && this._playSessionToken && this._playSessionBaseURL) {
        yield postPlaySessionEnd(this._playSessionBaseURL, this._playSession, this._playSessionToken);
      }
    } catch (e: unknown) {
      errorOccurred = e;
    }

    this.setMessage(null);
    clearInterval(this._janusVideoFastTimer);
    clearInterval(this._janusVideoStatsTimer);
    if (this._pauseGamepadTimeout) {
      clearTimeout(this._pauseGamepadTimeout);
      this._pauseGamepadTimeout = undefined;
    }

    this._wssStreamingController?.dispose();
    this._wssStreamingController = null;

    // Video
    this._janusVideoPlugin?.send({ message: { request: 'stop' } });
    this._janusVideoPlugin?.hangup();
    this._janusVideoPlugin?.detach({});
    this._janusVideo?.destroy();

    // Audio
    this._janusAudioPlugin?.send({ message: { request: 'stop' } });
    this._janusAudioPlugin?.hangup();
    this._janusAudioPlugin?.detach({});
    this._janusAudio?.destroy();

    this.mediaStream = null;

    // Reset the queue, this should set default values before we clear the queueStateCallback.
    this.queueController.reset();

    /**
     * React on destroying Janus to resolve the promise
     */
    yield new Promise((resolve) => {
      const timeoutId = setTimeout(resolve, JANUS_DISPOSE_TIMEOUT);

      const disposer = reaction(
        () => this.isJanusDestroyed,
        () => {
          if (this.isJanusDestroyed) {
            resolve(true);
            clearTimeout(timeoutId);
            disposer();
          }
        }
      );
    });

    this.setMessage(null);
    this.lostKeyFrameCount = 0;
    this._lostPacketsArr = [];

    console.debug(
      `streamController`,
      `Changing state to: Closed, hasStateChangedCallback: ${!!this._onStateChanged}, Queue state: ${JSON.stringify(this.queueController.queue)}`
    );
    if (this._onStateChanged) {
      // Send closed statecallback manually, because the _queueStateAutorunDisposer is not in time.
      this._onStateChanged('Closed', undefined, this._playSession?.id);
    }
    this._playSession = null;
    this._playSessionBaseURL = null;
    this._playSessionToken = null;
    this._onStateChanged = null;

    this._onConnectionQualityChanged = null;
    this.setConnectionState('good');

    this._videoElement = null;
    this._janusVideoPlugin = null;
    this._janusAudioPlugin = null;

    this._janusVideo = null;
    this._janusAudio = null;

    this.lastFourConnectionStates = ['good', 'good', 'good', 'good'];

    this._setState('Closed');
    // Remove statecallback last, as queueController or other state updates may want to be send while disposing.
    // Exception for the setState to closed here, since we already sent that state.
    if (!errorOccurred) {
      resolve();
    } else {
      reject(errorOccurred);
    }
  });

  /**
   * Returns the previous call promise if it is not resolved yet
   * */
  public dispose = (): Promise<void> => {
    if (this._state === 'Closing' || this._state === 'Closed') return this._disposePromise;

    this._disposePromise = new Promise((resolve, reject) => {
      this._dispose(resolve, reject);
    });
    return this._disposePromise;
  };
}
