import { MIN_ITEMS_IN_VIEW, Routes, analyticsRoutes } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, ApplicationStoreContext, ChannelStoreContext, HomeControllerContext, PlatformControllerContext } from '@utomik-app-monorepo/store';
import { AnalyticItemTypes } from '@utomik-app-monorepo/store';
import { joinLocation } from '@utomik-app-monorepo/utils';
import React, { useEffect } from 'react';
export const useFeaturedItemsAnalytics = () => {
  const channelStore = React.useContext(ChannelStoreContext);
  const applicationStore = React.useContext(ApplicationStoreContext);
  const analyticController = React.useContext(AnalyticControllerContext);
  const homeController = React.useContext(HomeControllerContext);
  const platformController = React.useContext(PlatformControllerContext);
  useEffect(() => {
    if (!platformController.isWeb) {
      homeController.providers.recommendedChannels?.forEach((apiCh, idx) => {
        if (idx + 1 >= MIN_ITEMS_IN_VIEW) return;
        const channel = channelStore.getItem(apiCh.slug);
        const breadcrumbs = analyticController.getBreadCrumbs({
          currentSection: 'Featured',
          pageName: analyticsRoutes[Routes.Home],
          isCarousel: false
        });
        analyticController.featuredItem({
          location_on_page: joinLocation(breadcrumbs),
          item_type: AnalyticItemTypes.Channel,
          location_index: String(idx),
          item_name: channel.slug
        });
      });
    }
  }, [homeController.providers.recommendedChannels?.length, platformController.isWeb]);
  useEffect(() => {
    homeController.providers.spotlight?.appList?.itemSet?.forEach((apiApp, idx) => {
      if (idx + 1 >= MIN_ITEMS_IN_VIEW) return;
      const app = applicationStore.getItem(apiApp.slug);
      const breadcrumbs = analyticController.getBreadCrumbs({
        pageName: analyticsRoutes[Routes.Home],
        isCarousel: false,
        currentSection: 'Featured'
      });
      analyticController.featuredItem({
        location_on_page: joinLocation(breadcrumbs),
        item_type: AnalyticItemTypes.Game,
        location_index: String(idx),
        item_name: app.slug
      });
    });
  }, [homeController.providers.spotlight?.length]);
};