import Janus from 'janus-gateway';
import adapter from 'webrtc-adapter';
import { action, computed, makeObservable, observable } from 'mobx';

export enum JanusState {
  pendingInitial,
  available,
  pendingDestroy,
  destroyed,
  errorState,
}

export default class JanusClient {
  private _janusClient: Janus | undefined;

  @observable
  private _janusState = JanusState.pendingInitial;

  constructor(url: string) {
    Janus.init({
      // debug: 'all',
      dependencies: Janus.useDefaultDependencies({ adapter: adapter }),
      callback: () => {
        this._janusClient = new Janus({
          server: `${url}/janus`,
          success: () => {
            this.setJanusState(JanusState.available);
            console.log('Janus init done');
          },
          error: (error) => {
            this.setJanusState(JanusState.errorState);
            console.log('Janus error ' + error);
          },
          destroyed: () => {
            this.setJanusState(JanusState.destroyed);

            console.log('Close Janus Client');
          },
        });
      },
    });

    makeObservable(this);
  }

  public get client(): Janus | undefined {
    return this._janusClient;
  }

  @computed
  public get janusState(): JanusState {
    return this._janusState;
  }

  @action
  public setJanusState(state: JanusState) {
    this._janusState = state;
  }

  public destroy() {
    this.client?.destroy({});
  }
}
