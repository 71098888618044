import { Countries } from '@utomik-app-monorepo/store';
import { PatchUserValues, User } from '@utomik-app-monorepo/store';
import { computedFn } from 'mobx-utils';
import moment from 'moment';

/**
 * Extract the form values from the user object.
 * This will actually delete fields already filled in.
 * @param user User object
 */
export const toPatchUserValues = (user: User): [PatchUserValues, string[]] => {
  const filterFields = [];
  // Null is not allowed as a value so we will treat them as undefined.
  const patchUserValues: PatchUserValues = {
    username: user.patchUserValues.username || '',
    first_name: user.patchUserValues.first_name || '',
    last_name: user.patchUserValues.last_name || '',
    email: user.patchUserValues.email || '',
    country: user.patchUserValues.country || undefined,
    gender: user.patchUserValues.gender || 'u',
    birthdate: user.patchUserValues.birthdate || '',
  };
  for (const key in patchUserValues) {
    // Delete filled in keys, gender key is only filled in if it is not 'u'
    if (patchUserValues[key] && (key !== 'gender' || patchUserValues[key] !== 'u')) {
      delete patchUserValues[key];
      filterFields.push(key);
    }
    // For Cliq users we don't ask for email, first and last name
    if (['email', 'first_name', 'last_name'].includes(key) && user.isCliqUser) {
      delete patchUserValues[key];
      filterFields.push(key);
    }
  }
  return [patchUserValues, filterFields];
};

/**
 * Tests if the birthdate string accepts the user as 18 years old.
 * @param birthdate Birthdate
 */
export const not18YearsOldTest = (birthdate: string): boolean => {
  const m = moment(new Date(Date.now()));
  const d = m.diff(birthdate, 'year');
  return d >= 18;
};

/**
 * Tests if the date string is a valid date.
 * @param date Date
 */
export const isValidDateTest = (date: string): boolean => {
  return moment(date).isValid();
};

/**
 * Tests if the country is supported.
 * This assumes that the countries object has been loaded.
 * @param id Country ID
 */
export const countryNotSupportedTest = computedFn(
  (id: number, countries: Countries): boolean =>
    countries.items.find((country) => country.id === id)?.isSupported ?? false
);
