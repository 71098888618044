import React, { useEffect, useRef } from 'react';
export const useScrollBar = (scrollableContainerRef: React.MutableRefObject<HTMLDivElement>, widthRem = 0.7, isEnabled: boolean = true) => {
  const [height, setHeight] = React.useState(0);
  const rafIdRef = useRef<ReturnType<typeof requestAnimationFrame>>(null);
  const isMountedRef = useRef(true);
  const observer = React.useRef(isEnabled ? new ResizeObserver(entries => {
    // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
    rafIdRef.current = requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      const {
        height
      } = entries[0].contentRect;
      isMountedRef.current && setHeight(height);
    });
  }) : null);
  useEffect(() => {
    const rafIdRefScoped = rafIdRef.current;
    const observerRefScoped = observer.current;
    const scrollableContainerRefScoped = scrollableContainerRef.current;
    if (!scrollableContainerRefScoped) return;
    const current = scrollableContainerRef.current;
    const parent = scrollableContainerRef.current.parentElement;
    const indicatorHeight = parent.clientHeight / height * parent.clientHeight;
    if (indicatorHeight <= 0) return;
    observerRefScoped?.observe(scrollableContainerRefScoped);
    current.parentElement.style.position = 'relative';
    const element = document.createElement('div');
    element.style.position = 'absolute';
    element.style.right = '0';
    element.style.width = `${widthRem}rem`;
    element.style.height = `${indicatorHeight}px`;
    element.style.margin = '0';
    element.style.padding = '0';
    element.style.backgroundColor = '#23b24b';
    element.style.borderRadius = '1rem';
    element.style.top = '0px';
    element.style.willChange = 'transform';
    element.style.zIndex = '1';
    if (indicatorHeight >= parent.clientHeight) {
      element.style.backgroundColor = 'rgb(100, 100, 100)';
    }
    parent.appendChild(element);
    const transitionHandler = () => {
      const indicatorTop = current.getBoundingClientRect().top * -1 * (parent.clientHeight / height);
      element.style.transform = `translateY(${indicatorTop <= 0 ? 0 : indicatorTop > parent.clientHeight - indicatorHeight ? parent.clientHeight - indicatorHeight : indicatorTop}px)`;
    };
    const intervalId = setInterval(transitionHandler, 10);
    return () => {
      cancelAnimationFrame(rafIdRefScoped);
      clearInterval(intervalId);
      scrollableContainerRefScoped && observerRefScoped?.unobserve(scrollableContainerRefScoped);
      parent.removeChild(element);
      isMountedRef.current = false;
    };
  }, [scrollableContainerRef.current, height]);
};