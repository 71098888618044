import { action, computed, makeObservable, observable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { AsyncObject, AsyncObjectState } from '../objectStore/asyncObject';

export interface ApiCountry {
  id: number;
  name: string;
  code: number;
  is_supported: boolean;
}

export class Country {
  private readonly _apiCountry: ApiCountry;

  public constructor(apiCountry: ApiCountry) {
    this._apiCountry = apiCountry;
  }

  public get id(): number {
    return this._apiCountry.id;
  }
  public get name(): string {
    return this._apiCountry.name;
  }
  public get code(): number {
    return this._apiCountry?.code;
  }
  public get isSupported(): boolean {
    return this._apiCountry.is_supported;
  }

  public toApiCountry(): ApiCountry {
    return this._apiCountry;
  }
}

export class Countries extends AsyncObject<ApiCountry[]> {
  public constructor(requestQueue: RequestQueue, url = `v2/countries`) {
    super(requestQueue, 0, url);
    makeObservable(this);
  }

  @observable
  private _items: Country[] = [];

  @computed
  public get items(): Country[] {
    return this._items;
  }

  @action
  public load(apiCountries: ApiCountry[]): void {
    try {
      const countries = apiCountries?.map((apiCountry): Country => {
        return new Country(apiCountry);
      });
      this._items = countries || [];
    } catch (ex) {
      throw new Error(`Failed loading countries. ${ex}`);
    }
  }

  @action
  public unload(): void {
    this._items = [];
    this.setState(AsyncObjectState.None);
  }
}
