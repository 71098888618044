import { TIME_DEBOUNCE_TILES } from '@utomik-app-monorepo/constants';
import { DialogQueueContext, NavigationControllerContext, TvSidebarControllerContext, VideoPlayerControllerContext } from '@utomik-app-monorepo/store';
import { Application } from '@utomik-app-monorepo/store';
import { unloadVideo } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Loading } from '../loading/loading';
import './video-player.scss';
type Props = {
  application?: Application;
  backgroundUrl?: string;
  url?: string;
  className?: string;
  loop?: boolean;
  muted?: boolean;
  fadeOut?: boolean;
  onFadeOutEnd?: () => void;
};
const INTERVAL_TO_TRY_PLAY_VIDEO = 5000;
export const VideoPlayer: React.FC<Props> = observer(function VideoPlayer({
  application,
  url,
  backgroundUrl,
  className,
  loop,
  muted = true,
  fadeOut,
  onFadeOutEnd
}) {
  const navigationController = useContext(NavigationControllerContext);
  const videoPlayerController = useContext(VideoPlayerControllerContext);
  const sidebarController = useContext(TvSidebarControllerContext);
  const dialogQueue = useContext(DialogQueueContext);
  const videoRef = useRef<HTMLVideoElement>(null);
  const timeoutPlayRef = useRef<ReturnType<typeof setTimeout>>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const backgroundImageRef = useRef<HTMLImageElement>(null);
  const [isLoadedVideo, setIsLoadedVideo] = useState(false);
  const [isLoadedImage, setIsLoadedImage] = useState(false);
  const [hasError, setHasError] = useState(false);
  const imageUrl = backgroundUrl || application?.images?.find(item => item.tag === 'LB')?.url;
  const videoUrl = url || application?.spotlightVideo?.url;
  const shouldMountVideoTag = dialogQueue.currentDialog?.analyticName !== 'MediaViewerDialog';
  useEffect(() => {
    if (hasError) {
      intervalRef.current = setInterval(() => {
        videoRef.current?.load();
        playVideoDebounced.current();
      }, INTERVAL_TO_TRY_PLAY_VIDEO);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [hasError]);
  const playVideoDebounced = useRef(debounce(() => {
    if (videoPlayerController.paused) return;
    videoRef.current?.play().catch(e => {
      if (!muted && videoRef.current) {
        videoRef.current.muted = true;
        videoRef.current?.play().catch(console.log);
        return;
      }
      setHasError(true);
    });
  }, 500));
  useEffect(() => {
    const disposer = reaction(() => videoPlayerController.paused, isPaused => {
      if (!videoRef.current) return;
      if (isPaused) {
        videoRef.current.pause();
      } else if (videoRef.current.paused && videoRef.current.src) {
        videoRef.current?.play().catch(e => {
          console.log(e);
          setHasError(true);
        });
      }
    });
    return () => disposer();
  }, []);
  const isLoadingData = (!isLoadedVideo || !isLoadedImage) && !!videoUrl;
  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let intervalId: ReturnType<typeof setInterval>;
    const handler = () => {
      if (fadeOut) {
        try {
          videoRef.current.volume -= 0.01;
        } catch (e) {
          clearInterval(intervalId);
          onFadeOutEnd && onFadeOutEnd();
        }
      }
    };
    intervalId = setInterval(handler, 50);
    return () => {
      clearInterval(intervalId);
    };
  }, [fadeOut]);
  useEffect(() => {
    //pause the video playback if the app id has changed
    clearTimeout(timeoutPlayRef.current);
    if (!navigationController.currentId || !application?.id) return;
    if (application?.id === navigationController.currentId && !sidebarController.isExpanded) {
      timeoutPlayRef.current = setTimeout(() => {
        videoPlayerController.setPaused(false);
      }, TIME_DEBOUNCE_TILES);
    } else {
      videoPlayerController.setPaused(true);
    }
    return () => {
      clearTimeout(timeoutPlayRef.current);
    };
  }, [navigationController.currentId, sidebarController.isExpanded]);
  useEffect(() => {
    const videoRefScoped = videoRef.current;
    const playVideoDebouncedScoped = playVideoDebounced.current;
    setIsLoadedImage(false);
    videoPlayerController.setPaused(false);
    if (backgroundImageRef.current) {
      backgroundImageRef.current.style.opacity = '1';
    }
    if (videoRef.current && videoUrl) {
      videoRef.current.src = videoUrl;
      playVideoDebouncedScoped();
    }
    return () => {
      clearTimeout(timeoutPlayRef.current);
      playVideoDebouncedScoped.cancel();
      unloadVideo(videoRefScoped);
    };
  }, [videoUrl, imageUrl]);
  useEffect(() => {
    if (dialogQueue.hasDialogs) {
      videoPlayerController.setPaused(true);
    } else {
      videoPlayerController.setPaused(false);
    }
  }, [dialogQueue.hasDialogs]);
  useEffect(() => {
    if (!muted) {
      videoRef.current.volume = 1;
    }
  }, [muted]);
  useEffect(() => {
    const videoRefScoped = videoRef.current;
    const backgroundImageRefScoped = backgroundImageRef.current;
    if (!videoRefScoped) return;
    const playHandler = () => {
      if (!videoRefScoped) return;
      setIsLoadedVideo(true);
      setIsLoadedImage(true);
    };
    videoRefScoped.addEventListener('play', playHandler);
    const playingHandler = () => {
      if (!videoRefScoped) return;
      if (videoRefScoped.currentTime > 0) {
        if (backgroundImageRefScoped) {
          backgroundImageRefScoped.style.opacity = '0';
        }
        setIsLoadedVideo(true);
        setIsLoadedImage(true);
        setHasError(false);
      }
    };
    videoRefScoped.addEventListener('timeupdate', playingHandler);
    const pauseHandler = () => {
      if (backgroundImageRefScoped) {
        backgroundImageRefScoped.style.opacity = '1';
      }
      setIsLoadedVideo(true);
      setIsLoadedImage(true);
    };
    videoRefScoped.addEventListener('pause', pauseHandler);
    const errorHandler = () => {
      setIsLoadedVideo(true);
      setIsLoadedImage(true);
      setHasError(true);
      if (backgroundImageRefScoped) {
        backgroundImageRefScoped.style.opacity = '1';
      }
    };
    videoRefScoped.addEventListener('error', errorHandler);
    const endedHandler = () => {
      setIsLoadedVideo(true);
      setIsLoadedImage(true);
      if (backgroundImageRefScoped) {
        backgroundImageRefScoped.style.opacity = '1';
      }
    };
    videoRefScoped.addEventListener('ended', endedHandler);
    const waitingHandler = () => {
      setIsLoadedVideo(false);
      setIsLoadedImage(true);
      if (videoPlayerController.paused) {
        videoPlayerController.setPaused(true);
      }
      if (backgroundImageRefScoped) {
        backgroundImageRefScoped.style.opacity = '1';
      }
    };
    videoRefScoped.addEventListener('waiting', waitingHandler);
    const onLoadImageHandler = () => {
      setIsLoadedImage(true);
    };
    backgroundImageRefScoped.addEventListener('load', onLoadImageHandler);
    return () => {
      if (videoRefScoped) {
        videoRefScoped.removeEventListener('playing', playingHandler);
        videoRefScoped.removeEventListener('play', playHandler);
        videoRefScoped.removeEventListener('pause', pauseHandler);
        videoRefScoped.removeEventListener('error', errorHandler);
        videoRefScoped.removeEventListener('ended', endedHandler);
        videoRefScoped.removeEventListener('waiting', waitingHandler);
      }
      backgroundImageRefScoped && backgroundImageRefScoped.removeEventListener('load', onLoadImageHandler);
    };
  }, [videoPlayerController.paused]);
  return <section ref={sectionRef} className={cx('video-player__container', {
    [className]: !!className
  })}>
      {isLoadingData && videoPlayerController.config.showLoader && <Loading size={'large'} />}
      <img ref={backgroundImageRef} className={'background_image'} decoding={'sync'} src={imageUrl} />
      {shouldMountVideoTag && <video id={'main_video'} ref={videoRef} muted={muted} loop={loop} playsInline preload={'none'} height={576} width={720} poster="null" />}
    </section>;
});