import { Routes } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { useLocation } from 'react-router';
export enum GamepadButtons {
  PadUp = 'DPadUp',
  PadRight = 'DPadRight',
  PadDown = 'DPadDown',
  PadLeft = 'DPadLeft',
  Back = 'Back',
  Start = 'Start',
  A = 'A',
  B = 'B',
  X = 'X',
  Y = 'Y',
  RB = 'RB',
  RT = 'RT',
  LB = 'LB',
  LT = 'LT',
}
const TIME_TO_PRESS_NEXT_BUTTON = 2000;

/**
 * @param onSuccess callback is called on success combination pressed
 * @param applicablePages array of the applicable pages routes
 * */

export const useSecretCombination = (onSuccess: () => void, applicablePages: Routes[]) => {
  const location = useLocation();
  let timer: ReturnType<typeof setTimeout> = null;
  let counter = 0;

  /**
   *Array with the secret buttons sequence. You can add as many as you want :)
   * */

  const buttonsSequence = [GamepadButtons.RT, GamepadButtons.RT, GamepadButtons.LT, GamepadButtons.LT, GamepadButtons.RB, GamepadButtons.RB, GamepadButtons.LB, GamepadButtons.LB, GamepadButtons.Start];
  const combinationHandler = (buttonName: GamepadButtons): void => {
    if (!applicablePages.includes(location.pathname as Routes)) return;
    if (buttonsSequence[counter] === buttonName) {
      /**
       * Clear timeout every time buttons matched
       * */
      clearTimeout(timer);

      /**
       * Then set new one
       * */

      timer = setTimeout(() => {
        clearTimeout(timer);
        counter = 0;
      }, TIME_TO_PRESS_NEXT_BUTTON);
      /**
       * The counter is incremented each time the pressed button matches the corresponding button in the sequence
       * */
      counter++;
      if (counter === buttonsSequence.length) {
        /**
         * Reset counter if success
         * */
        counter = 0;
        onSuccess();
        log('Combination is correct !!!');
      }
    } else {
      /**
       * Reset counter if any other button is pressed
       * */
      counter = 0;
    }
  };
  return combinationHandler;
};