import { t } from '@lingui/macro';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment-duration-format';
import React, { useEffect, useRef, useState } from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import { ProgressCircle } from '../../progress-circle/progress-circle';
import './inactivity-dialog.scss';
export const InactivityDialog = observer(function InactivityDialog({
  action,
  time
}) {
  const initTime = moment.duration(time, 'minutes').asMilliseconds();
  const [counter, setCounter] = useState<number>(initTime);
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCounter(prev => {
        return prev - 1000;
      });
    }, 1000);
    return () => {
      clearInterval(intervalRef.current);
      action(DialogResult.Close);
    };
  }, []);
  useEffect(() => {
    if (counter <= 0) {
      clearInterval(intervalRef.current);
      action(DialogResult.Close);
    }
  }, [counter]);
  const progress = 1 - counter / initTime;
  const timeFormatted = (moment.duration(counter, 'milliseconds') as any).format('mm:ss');
  return <div className={'inactive-queue__container'}>
      <h1 className={'title'}>
        <>
          {t({
          context: 'Modal dialog - Asks a user if they are still here after 5 minutes of inactivity.',
          message: 'Are you still here?'
        })}
        </>
      </h1>
      <h2 className={'subtitle'}>
        <>
          {t({
          context: 'Modal dialog - Prompts a user that they will be disconnected',
          message: 'Inactive users will be disconnected'
        })}
        </>
      </h2>
      <h2 className={'warning'}>
        <>
          {t({
          context: 'Modal dialog - Prompts a user that all unsaved progress will be lost',
          message: 'Unsaved progress will be lost!'
        })}
        </>
      </h2>
      <ProgressCircle progress={progress}>
        <span className={'time-progress'}>{timeFormatted}</span>
      </ProgressCircle>
      <div className={'buttons-container'}>
        <FocusableButton autofocus type={'secondary'} onEnterPress={() => action(DialogResult.OK)}>
          {t({
          context: 'Inactivity Dialog Button - When a user pressed on that button the system close the inactivity dialog and continue the stream',
          message: 'I am here'
        })}
        </FocusableButton>
      </div>
    </div>;
});
export default InactivityDialog;