import { LOGIN_QR_TV_FLOW_SUBSCRIPTION } from '@utomik-app-monorepo/constants';
import { action, computed, makeObservable, observable } from 'mobx';

import { AuthorizationStore } from '../../../dataStore/stores/authorizationStore/authorizationStore';
import { DialogFactory } from '../dialogFactory/dialogFactory';
import { DialogQueue, DialogResult } from '../dialogQueue/dialogQueue';
import { SsoService } from '../ssoService/ssoService';
import { TokenManager } from '../tokenManager/tokenManager';

export enum LoginLocation {
  Authorization,
  LoginQR,
  SignUpQR,
  Login,
  ForgotPassword,
  ForgotPasswordResult,
}

export interface LoginErrorState {
  message: string;
  minutes?: number;
}

export class LoginController {
  private readonly _tokenManager: TokenManager;
  private readonly _authorizationStore: AuthorizationStore;
  private readonly _dialogQueue: DialogQueue;
  private readonly _dialogFactory: DialogFactory;
  private readonly _ssoService: SsoService;

  @observable
  private _history: LoginLocation[] = [LoginLocation.Authorization];

  @observable
  private _comeFrom = '';

  /** Current UI state of the login-view.. So "in what form" are we currently working. */
  @computed
  public get location(): LoginLocation {
    return this._history[this._history.length - 1];
  }

  @action
  public setLocation(location: LoginLocation | number, comeFrom?: string): void {
    if (location < 0) {
      this._history.splice(location);
    } else {
      if (this._history.length >= 10) {
        this._history.shift();
      }

      this._history.push(location);
    }
    ///this._location = location;
    this.setComeFrom(comeFrom ?? '');
  }

  /** "Busy" state for when login is in progress. */
  @computed
  public get busy(): boolean {
    return this._tokenManager.busy;
  }

  public setBusy(busy: boolean): void {
    this._tokenManager.setBusy(busy);
  }

  // Error value is currently only used to display the minutes remaining when too many requests are done.
  @observable
  private _errorValue = 0;

  @computed
  public get errorValue(): number {
    return this._errorValue;
  }

  @observable
  private _errorMessage = null;

  @computed
  public get errorMessage(): string {
    // if the TokenManager has an error, return that one instead
    const tokenManagerError = this._tokenManager?.error;
    return tokenManagerError?.length > 0 ? tokenManagerError : this._errorMessage;
  }

  /**
   * Set the error to be visually displayed in the login component.
   *
   * @param errorMessage - A string to set as error message.
   * @param errorValue - Some errors have a value like a time.
   */
  @action
  public setError(errorMessage = '', errorValue?: number): void {
    if (errorValue) this._errorValue = errorValue;
    this._errorMessage = errorMessage;
    // if the TokenManager has an error, erase it - we no longer care
    this._tokenManager?.clearError();
  }

  @action
  public clearError(): void {
    this._errorMessage = null;
    this._errorValue = 0;
    // if the TokenManager has an error, erase that too
    this._tokenManager?.clearError();
  }

  @action
  public setComeFrom(value: string): void {
    this._comeFrom = value;
  }

  /**
   * Controller that handles the login logic.
   *
   * @param tokenManager
   */
  public constructor(
    tokenManager: TokenManager,
    authorizationStore: AuthorizationStore,
    dialogQueue: DialogQueue,
    dialogFactory: DialogFactory
  ) {
    this._tokenManager = tokenManager;
    this._authorizationStore = authorizationStore;
    this._dialogQueue = dialogQueue;
    this._dialogFactory = dialogFactory;

    this._ssoService = new SsoService();

    makeObservable(this);
  }

  public subscriptionTVFlow = async () => {
    const result = await this._dialogFactory.showGetSubscriptionDialog();
    switch (result) {
      case DialogResult.OK: {
        await this._tokenManager.logout();
        this.setLocation(LoginLocation.LoginQR, LOGIN_QR_TV_FLOW_SUBSCRIPTION);
        break;
      }
      case DialogResult.Cancel: {
        this._dialogQueue.removeCurrent();
      }
    }
  };

  public async login(email: string, password: string): Promise<void> {
    await this._tokenManager.login(email, password);
  }

  public loginWithSso = async (slug: string) => {
    await this._ssoService.loginWithSso(slug);
  };

  public reAuthWithSso = async (slug: string) => {
    await this._ssoService.reauthWithSSO(slug);
  };

  public fetchSso = async () => {
    return this._ssoService.fetch();
  };

  public get ssoProviders() {
    return this._ssoService.ssoProviders.filter((p) => p.display_name && !p.disabled);
  }

  // Handle the "forgot password" case, and ask the platform to recover the user's password.
  public async forgotPassword(email: string): Promise<void> {
    this.setBusy(true);
    try {
      await this._authorizationStore.forgotPassword(email);
    } catch (e) {
      console.log(e, 'Forgot password error');
      throw e;
    } finally {
      this.setBusy(false);
    }
  }

  // Account that was remembered when "remember me" was used.
  public get comeFrom(): string {
    return this._comeFrom;
  }

  // Returns whether a valid token is currently available.
  @computed
  public get isAuthorized(): boolean {
    return this._tokenManager.isAuthorized;
  }
}
