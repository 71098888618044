import { makeObservable } from 'mobx';

import { RequestQueue } from '../../requestQueue/requestQueue';
import { ChannelLinkList } from '../channelLinkList/channelLinkList';
import { ObjectStore } from '../objectStore/objectStore';
import { ApiGenre, Genre } from './genre';

export class GenreStore extends ObjectStore<Genre, ApiGenre> {
  private _channelLinkList: ChannelLinkList;

  protected createAndLoadItem(genre: ApiGenre): Genre {
    return Genre.parse(genre, this._channelLinkList);
  }

  public constructor(queue: RequestQueue, channelLinkList: ChannelLinkList, url = 'v2/genres') {
    super(queue, url);
    this._channelLinkList = channelLinkList;
    makeObservable(this);
  }
}
