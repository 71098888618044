import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { ObservableMap, action, makeObservable, override } from 'mobx';

import { AnalyticController } from '../../../app/global/analyticController/analyticController';
import { RequestQueue } from '../../requestQueue/requestQueue';
import { AgeRatingSystemStore } from '../ageRatingSystemStore/ageRatingSystemStore';
import { ApiAppPermissions, AppPlayPermission } from '../appPermission/appPermission';
import { ChannelLinkList } from '../channelLinkList/channelLinkList';
import { GenreStore } from '../genreStore/genreStore';
import { LanguagesStore } from '../languagesStore/languagesStore';
import { ObjectID } from '../objectStore/asyncObject';
import { ObjectStore } from '../objectStore/objectStore';
import { UserRatingStore } from '../userRatingStore/userRatingStore';
import { UserStatsStore } from '../userStatsStore/userStatsStore';
import { ApiApplication, Application } from './application';

export class ApplicationStore extends ObjectStore<Application, ApiApplication> {
  private _itemsSlugMap: ObservableMap<ObjectID, Application>;
  public readonly genreStore: GenreStore;
  public readonly ageRatingSystemStore: AgeRatingSystemStore;
  public readonly userRatingStore: UserRatingStore;
  public readonly userStatsStore: UserStatsStore;
  public readonly analyticController: AnalyticController;
  public readonly channelLinkList: ChannelLinkList;
  public readonly languagesStore: LanguagesStore;

  public constructor(
    queue: RequestQueue,
    genreStore: GenreStore,
    ageRatingSystemStore: AgeRatingSystemStore,
    userRatingStore: UserRatingStore,
    userStatsStore: UserStatsStore,
    analyticController: AnalyticController,
    channelLinkList: ChannelLinkList,
    languagesStore: LanguagesStore,
    baseUrl = ''
  ) {
    super(queue, baseUrl + '/v2/applications');
    this.genreStore = genreStore;
    this.ageRatingSystemStore = ageRatingSystemStore;
    this.userRatingStore = userRatingStore;
    this.userStatsStore = userStatsStore;
    this.analyticController = analyticController;
    this.channelLinkList = channelLinkList;
    this._itemsSlugMap = new ObservableMap<ObjectID, Application>();
    this.languagesStore = languagesStore;
    makeObservable(this);
  }

  /**
   * Gets items, but also creates it if it does not exist (which is not clear from the method name).
   * @param id - Can be an application ID or a slug.
   * @param name - The name of the application, so we can show it before it's loaded from the server.
   * @param permissions - Application permissions.
   */
  @action
  public getItem(id: ObjectID, name?: string, permissions?: ApiAppPermissions): Application {
    // Parsing the object id to check if it is a slug is a temporary solution, it can be remove when we get the application slug on all endpoints
    // NOTE: when we definitely use the slug always, AppList no longer needs to call getItem() with both the ID and the slug, too
    let item: Application = null;
    let isSlug = false;
    // if there's any non-numeric characters in the ID, it's a slug, even if parseInt() would work (100% Hidden Object 2, 2Dark, etc.)
    const idString = id?.toString();
    let foundNonNumber = false;
    for (let i = 0; !foundNonNumber && i < idString?.length; i++) {
      const code = idString.charCodeAt(i);
      foundNonNumber = code <= 47 || code >= 58;
    }
    let parsedId: ObjectID = id;
    if (foundNonNumber) {
      item = this._itemsSlugMap.get(id) as Application;
      isSlug = true;
    } else {
      parsedId = typeof id === 'string' ? parseInt(id, 10) : id;
      item = this.itemsMap.get(parsedId) as Application;
    }
    if (item) {
      // If the name is not set then set it again.
      if (isNullOrUndefined(item.name) || item.name.length === 0) item.name = name;
      // If the slug is not set then set it again.
      if ((isNullOrUndefined(item.slug) || item.slug.length === 0) && isSlug) {
        item.slug = parsedId.toString();
      }
      if (isNullOrUndefined(item.playPermission) && !isNullOrUndefined(permissions)) {
        item.playPermission = new AppPlayPermission(this.requestQueue, item.id, permissions);
      }
      return item;
    }

    const app = new Application(this, parsedId, name, permissions);
    if (isSlug) {
      app.slug = parsedId.toString();
      if (parsedId && app) {
        this._itemsSlugMap.set(parsedId, app);
      }
    } else {
      if (parsedId && app) {
        this.itemsMap.set(parsedId, app);
      }
    }
    return app;
  }
  @action
  public mapItem(app: Application): void {
    if (app.id) {
      this.itemsMap.set(app.id, app);
    }
    if (app.slug && app) {
      this._itemsSlugMap.set(app.slug, app);
    }
  }

  public createAndLoadItem(appInfo: ApiApplication): Application {
    const app = this.getItem(appInfo.id);
    app.load(appInfo);
    return app;
  }

  /**
   * When called, set all the channels and recommended channels to null.
   */
  @override
  public override unload(): void {
    super.unload();
    this._itemsSlugMap.clear();
  }
}
