import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useSetTestId } from '@utomik-app-monorepo/hooks';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { CSSProperties, useEffect, useRef } from 'react';
import './text-input-field.scss';
type Props = {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  placeholder?: string;
  type?: 'email' | 'password' | 'text' | 'search';
  name?: string;
  errorMessage?: React.ReactNode | string;
  defaultValue?: string;
  width?: 'fullwidth' | 'normal';
  onBlur?: (e: any) => void;
  onClick?: (e: any) => void;
  autofocus?: boolean;
  showOutline?: boolean;
  className?: string;
  icon?: React.ReactNode;
};
export const TextInputField = withFocusable()<Props>(observer(function FocusableInput({
  value,
  onChange,
  focused,
  placeholder,
  setFocus,
  type,
  name,
  errorMessage,
  resumeSpatialNavigation,
  defaultValue,
  style,
  onBlur,
  width = 'normal',
  autofocus,
  focusable = true,
  showOutline,
  className,
  onClick,
  icon
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  useSetTestId(inputRef, type);
  useEffect(() => {
    if (autofocus) {
      setFocus();
    }
  }, [autofocus]);
  useEffect(() => {
    if (focused) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [focused]);
  useEffect(() => {
    const localInputRef = inputRef.current;
    const handleEnterPress = e => {
      if (focused && e.key === 'Enter') {
        localInputRef?.blur();
        localInputRef?.focus();
      }
    };
    window.addEventListener('keydown', handleEnterPress);
    return () => window.removeEventListener('keydown', handleEnterPress);
  }, [focused]);
  useEffect(() => {
    const localInputRef = inputRef.current;
    const handleFocus = () => {
      setFocus();
    };
    localInputRef.addEventListener('focus', handleFocus);
    return () => localInputRef?.removeEventListener('focus', handleFocus);
  }, []);
  useEffect(() => {
    const localInputRef = inputRef.current;
    //this useEffect to block focus on x axis if input is focused

    const keyDownHandler = e => {
      const target = e.target as HTMLInputElement;

      // target.type !== 'email' because email has no selectionStart value
      if (e.key === 'ArrowLeft' && localInputRef.selectionStart <= 0 && target.type !== 'email') {
        resumeSpatialNavigation();
      }
      if (e.key === 'ArrowRight' && localInputRef.selectionStart >= target.value.length && target.type !== 'email') {
        resumeSpatialNavigation();
      }
      if (e.key === 'ArrowDown') {
        resumeSpatialNavigation();
      }
      if (e.key === 'ArrowUp') {
        resumeSpatialNavigation();
      }
    };
    inputRef.current.addEventListener('keydown', keyDownHandler);
    return () => {
      if (localInputRef) {
        localInputRef.removeEventListener('keydown', keyDownHandler);
      }
    };
  }, []);
  return <div className={cx('text-input-field__container', {
    'text-input-field__container--focus': focused && showOutline,
    [className]: !!className
  })}>
        <span className={'text-input-field--input-icon'}>{icon}</span>
        <input style={style} defaultValue={defaultValue} type={type} name={name || type} placeholder={placeholder} disabled={!focusable} className={cx('text-input-field--input', {
      [`text-input-field--input--${width}`]: true
    })} value={value} onChange={onChange} onClick={onClick} onBlur={onBlur} ref={inputRef} />
        <small className={'text-input-field--input--error'}>{errorMessage}</small>
      </div>;
}));