import { DEFAULT_RETRIES } from '@utomik-app-monorepo/constants';
import { log } from '@utomik-app-monorepo/logger';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { ObservableMap, action, computed, flow, makeObservable, observable, runInAction, when } from 'mobx';

import { ConnectivityReporter } from '../../app/global/connectivityReporter/connectivityReporter';
import { HttpTransport, IHttpConfig, IHttpResponse } from '../transports/httpTransport/httpTransport';
import { UtomikError } from '../transports/httpTransport/utomikError';

/**
 * The RequestQueue uses the given transport layer to get data from urls that are added.
 * The given maximum number of requests can be running concurrently.
 * The RequestQueue can be initialized with given set of priorities,
 * where requests with higher priorities are performed before requests with lower priorities.
 * Requests are performed in a FIFO order.
 */

export type RetryValue = number | number[];

// An interface to the internally used Request
export interface RequestHandle<T, R> {
  readonly priority: RequestPriority;
  readonly url: string;
  promise: Promise<IHttpResponse<T>>;
  aborted: boolean;
  readonly method: RequestMethod;
  readonly requestData: R;
  readonly config: IHttpConfig;
  readonly currentRetry: number;
  readonly retries: RetryValue;
}

export enum RequestMethod {
  Get,
  Post,
  Put,
  Delete,
  Patch,
}

// Higher numbers are higher priorities (Critical priority means a request is not queued, but performed immediately).
export enum RequestPriority {
  Low = -1,
  Medium = 0,
  High = 1,
  Critical = 2,
}

export enum RequestStatus {
  RetryingFailedRequest, // A failed request is being retried with a call to retryFailedRequests()
  QueuedForRetry, // The request has failed and is queued for a manual retry, it can later be retried with a call to retryFailedRequests()
}

class Request<T, R> implements RequestHandle<T, R> {
  public successCallback: (value?: IHttpResponse<T>) => void;
  public failureCallback: (reason?: unknown) => void;
  public statusCallback: (status: RequestStatus) => void;

  public readonly priority: RequestPriority;
  public readonly url: string;
  public promise: Promise<IHttpResponse<T>>;
  public aborted: boolean;
  public readonly method: RequestMethod;
  public readonly requestData: R;
  public config: IHttpConfig;

  // Retries as a number or array of timeout values.
  public readonly retries: RetryValue = null;
  // The current retry.
  public currentRetry = 0;
  // If true, and the retries run out, this request calls its failure() function, instead of being added to the list of failed requests
  public alwaysCallFailureIfOutOfRetries: boolean;

  public constructor(
    URL: string,
    requestData: R,
    priority: RequestPriority,
    method: RequestMethod,
    config: IHttpConfig,
    retries: RetryValue,
    alwaysCallFailureIfOutOfRetries: boolean,
    statusCB: (status: RequestStatus) => void
  ) {
    this.url = URL;
    this.priority = priority;
    this.aborted = false;
    this.method = method;
    this.requestData = requestData;
    this.config = config;
    this.retries = retries;
    this.alwaysCallFailureIfOutOfRetries = alwaysCallFailureIfOutOfRetries;
    this.statusCallback = statusCB;
  }

  /**
   * @param successCB Callback when promise resolves succesfully.
   * @param failureCB Callback when promise resolves fails.
   * @param statusCB Callback when request status changes.
   */
  public init(successCB: (value?: IHttpResponse<T>) => void, failureCB: (reason?: unknown) => void): void {
    this.successCallback = successCB;
    this.failureCallback = failureCB;
  }

  public success(value?: IHttpResponse<T>): void {
    this.successCallback(value);
  }

  public failure(reason?: unknown): void {
    this.failureCallback(reason);
  }

  public status(status: RequestStatus): void {
    if (!isNullOrUndefined(this.statusCallback)) this.statusCallback(status);
  }

  public handleAbort(): void {
    this.failureCallback('aborted');
  }
}

// Interval in milliseconds that determines how long to wait before checking for internet again and resuming the request queue.
const INTERNET_RETRY_INTERVAL = 500;

/**
 * The RequestQueue handles the order in which requests are processed in the client. The priorities are manually assigned by us.
 */
export class RequestQueue {
  // Each priority has a queue of urls from which to get data.
  @observable
  private queues: ObservableMap<number, Request<unknown, unknown>[]> = new ObservableMap<
    number,
    Request<unknown, unknown>[]
  >();
  private transport: HttpTransport;
  private connectivityReporter: ConnectivityReporter;

  // Requests
  @observable
  private curNumberOfReqs = 0;
  private curNumberOfCriticalReqs = 0;
  private maxNumberOfReqs = 150;

  // Any error that returns one of the following status codes will not be retried:
  private _nonRetryAbleErrorCodes = [400, 401, 403, 404];

  // Internal array of failed requests, to be retried later.
  @observable
  public failedRequests: Request<unknown, unknown>[] = [];

  private retryQueueingEnabled = true;
  public enableRetryQueueing(): void {
    this.retryQueueingEnabled = true;
  }
  public disableRetryQueueing(): void {
    this.retryQueueingEnabled = false;
  }

  public get maxConcurrent(): number {
    return this.maxNumberOfReqs;
  }
  public set maxConcurrent(count: number) {
    this.maxNumberOfReqs = count;
  }

  public constructor(
    transport: HttpTransport,
    connectivityReporter: ConnectivityReporter,
    _maxNumberOfReqs = undefined
  ) {
    makeObservable(this);
    this.transport = transport;
    this.connectivityReporter = connectivityReporter;

    // If max number of requests is not defined, use the default.
    if (_maxNumberOfReqs !== undefined) {
      this.maxNumberOfReqs = _maxNumberOfReqs;
    }
    this.initQueues();
  }

  // Initiate the queues. Sort priorities from high to low and assign them as keys for the queues.
  @action
  private initQueues(): void {
    // Get a list of all priorities in the enum
    const supportedPriorities = [];
    Object.keys(RequestPriority).forEach((key: string): void => {
      const priority = RequestPriority[key];
      if (typeof priority !== 'string') supportedPriorities.push(priority);
    });

    // Sort priorities from highest to lowest.
    supportedPriorities.sort((a, b): number => (a < b ? 1 : -1));

    // Initiate the queues. Set the key to be the priority and set an empty array as value.
    for (const priority of supportedPriorities) {
      this.queues.set(priority, []);
    }
  }

  /**
   * Mark a request as aborted to remove it later.
   *
   * @param requestHandle
   */
  public remove<T, R>(requestHandle: RequestHandle<T, R>): void {
    // Nothing to do! Request prio does not exist!
    if (isNullOrUndefined(requestHandle) || !this.queues.has(requestHandle.priority)) return;

    // Mark the request as aborted, so that it may be removed from the queue later
    const request = requestHandle as Request<T, R>;
    request.aborted = true;
  }

  /**
   * Adds the given url with the given priority to the queue so it can be requested.
   * Higher priorities take precedence over smaller priorities and will be requested first.
   * Returns null if a priority is unsupported, otherwise a promise for the request is returned.
   *
   * @param url Api endpoint url,
   * @param requestData? Data to send to endpoint.
   * @param priority Priority of the request.
   * @param method Method of the request. Get or post.
   * @param config? Optional IHttpConfig
   * @param retries? Amount of times to retry.
   * @param alwaysCallFailureIfOutOfRetries? When unset or set to false,
   * the request queue will queue this request on failure so it can be retried later. In which case the promise will NOT resolve until the request succeeds.
   * When set to true, the request queue will not queue this request on failure, in which case the promise will be rejected.
   * @param onStatusChanged? A callback that is called to inform of status changes to this request.
   */
  @action
  public add<T, R = unknown>(
    url: string,
    requestData?: R,
    priority: RequestPriority = RequestPriority.Medium,
    method: RequestMethod = RequestMethod.Get,
    config?: IHttpConfig,
    retries?: RetryValue,
    alwaysCallFailureIfOutOfRetries?: boolean,
    onStatusChanged?: (status: RequestStatus) => void
  ): RequestHandle<T, R> {
    // Only add Requests who's priorities are defined in the RequestPriority object.
    if (!this.queues.has(priority)) return null;
    // Reject when aborted.
    if (this._clearingQueue) throw new Error('aborted');
    const token = this.transport?.token?.httpToken;

    const headersWithToken = token ? { Authorization: token, 'Cache-Control': 'no-cache' } : {};
    const mergedHeaders = { ...headersWithToken, ...config?.headers };
    delete config?.headers;
    const requestConfig = { headers: { ...mergedHeaders } as any, ...config };

    // Create a new request.
    let request = new Request<T, R>(
      url,
      requestData,
      priority,
      method,
      requestConfig,
      isNullOrUndefined(retries) || (Array.isArray(DEFAULT_RETRIES) && DEFAULT_RETRIES.length === 0)
        ? DEFAULT_RETRIES
        : retries,
      isNullOrUndefined(alwaysCallFailureIfOutOfRetries) ? false : alwaysCallFailureIfOutOfRetries,
      onStatusChanged
    );

    // Create the promise.
    request = this.createAndPushPromise(request);

    return request;
  }

  /**
   * Create promise, attach it to the request, and push the request to the queue.
   *
   * @param request
   * @param method
   * @param requestData
   */
  @action
  private createAndPushPromise<T, R>(request: Request<T, R>): Request<T, R> {
    // Set the promise. The logic to resolve or reject this promise is handled in the Request itself.
    request.promise = new Promise<IHttpResponse<T>>((resolve, reject): void => {
      // Initiate the request. We need to pass the resolve and reject for this promise into the request. It's the only way for the Request object to resolve the promise.
      request.init(resolve, reject);
      // TODO: Can we refactor this runInAction?
      runInAction(() => {
        // Push this request to the priority queue.
        this.push(request);

        // Start request handling anytime a new request is added.
        this.startNewRequests();
      });
    });
    return request;
  }

  /**
   * Starts new requests until the max number of simultaneous requests is reached.
   */
  @action
  private startNewRequests(): void {
    // First we make sure to execute all critical requests.
    const criticalQueue = this.queues.get(RequestPriority.Critical);
    while (criticalQueue.length > 0) {
      const request = this.popFromPriorityQueue(criticalQueue);
      // Handle the request
      this.handleRequestPromise(request);
    }

    // Then we execute queued requests until the maximum amount of requests is reached.
    while (this.maxNumberOfReqs - (this.curNumberOfReqs - this.curNumberOfCriticalReqs) > 0 && this.hasQueuedItems) {
      // Pop the item from the front of the queue.
      const request = this.pop();
      // Handle the request
      this.handleRequestPromise(request);
    }
  }

  /**
   * Set a flag that the queue is clearing.
   */
  @observable
  private _clearingQueue = false;
  @action
  private _setClearingQueue(x: boolean): void {
    if (this._clearingQueue != x) {
      if (x) {
        log(`No longer accepting requests.`);
      } else {
        log(`Now accepting requests.`);
      }
    }
    this._clearingQueue = x;
  }

  /**
   * Clear all get requests and wait for all other requests. Used to clear stuff before logout.
   */
  public clear = flow(function* (this: RequestQueue) {
    this._setClearingQueue(true);
    log(`Clearing...`);
    this.queues.forEach((queue) => {
      queue.forEach((request) => {
        if (request.method == RequestMethod.Get) request.aborted = true;
      });
    });

    log(`Waiting for requests to finish!`);
    yield when(() => this.curNumberOfReqs == 0 && !this.hasQueuedItems);

    // Also clear the failed requests.
    this.failedRequests = [];

    log(`Cleared!`);
    this._setClearingQueue(false);
  });
  @action
  public clearFailedRequests(this: RequestQueue) {
    this.failedRequests = [];
  }

  /**
   * Set up the promise for the request to communicate with the api endpoint.
   *
   * @param request The request that gets the promise attached.
   * @param axiosTimeout How long in milliseconds before Axios should retry the request.
   */
  @action
  private handleRequestPromise<T, R>(request: Request<T, R>, axiosTimeout: number = null): void {
    if (this.connectivityReporter.connected) {
      // This request is now active. Increase the total number of current requests.
      this.incNumberOfReqs(request);

      let timeout: number = null;
      if (isNullOrUndefined(axiosTimeout)) {
        // If no timeout is set, and the retries are defined as an array, take the first value of the array (5 secs in most cases).
        if (Array.isArray(request.retries) && request.retries.length > 0) {
          timeout = request.retries[0] * 1000;
          // Up this value here so it starts with the second retry value on fail.
          request.currentRetry++;
        }
      } else {
        timeout = axiosTimeout * 1000;
      }

      // If no default config is set, use the transport default.
      if (!request.config) {
        request.config = this.transport.defaultConfig;
      }

      // Assign timeout
      if (!isNullOrUndefined(timeout))
        request.config = Object.assign(request.config ? request.config : {}, { timeout: timeout });

      // Handle different methods in the Transport Layer.
      let requestPromise = null;
      switch (request.method) {
        case RequestMethod.Get:
          requestPromise = this.transport.get<T>(request.url, request.config);
          break;
        case RequestMethod.Post:
          requestPromise = this.transport.post<T, R>(request.url, request.requestData, request.config);
          break;
        case RequestMethod.Put:
          requestPromise = this.transport.put<T, R>(request.url, request.requestData, request.config);
          break;
        case RequestMethod.Patch:
          requestPromise = this.transport.patch<T, R>(request.url, request.requestData, request.config);
          break;
        case RequestMethod.Delete:
          requestPromise = this.transport.delete<T>(request.url, request.config);
          break;
        default:
          console.error(`No method given. ${request.method}`);
      }

      if (requestPromise !== null) {
        requestPromise
          .then((value: IHttpResponse<T>): void => {
            // Request succesful.
            request.success(request.aborted ? null : value);
            // Always start a new request whenever the previous one is succesfully done.
            this.decNumberOfReqs(request);
            this.startNewRequests();
          })
          .catch((errorResponse: UtomikError): void => {
            // Request failed. Determine whether to retry or not.
            this.handleRetryRequest(errorResponse, request);
          });
      }
    } else {
      setTimeout(() => {
        this.handleRequestPromise(request, axiosTimeout);
      }, INTERNET_RETRY_INTERVAL);
    }
  }

  /**
   * This function does the retry strategy.
   *
   * @param errorResponse - Thrown response from the promise catch.
   * @param request - The request to do the retry strategy on.
   */
  @action
  private handleRetryRequest<T, R>(errorResponse: UtomikError, request: Request<T, R>): void {
    if (this._nonRetryAbleErrorCodes.includes(errorResponse.statusCode)) {
      // Do not retry when you receive this response. Status code is in the ignore list of the Transport Layer.
      log(`Failed loading: ${request.url}. Not retrying.`);
      request.failure(errorResponse);
      // Failed. Not retrying. Go onward to the next request.
      this.decNumberOfReqs(request);
      this.startNewRequests();
    } else {
      // The maximum amount of retries is either the number passed, or the length of the array.
      let maxRetries = 0;
      // The time to wait in milliseconds.
      let currentTimeout = 0;

      // Normally, a default amount of retries are always passed to here. But in isolation, like in unit tests, it can be null.
      if (!isNullOrUndefined(request.retries)) {
        // These if statements look ugly but this is the most performant way to check types.
        if (request.retries.constructor === Number) {
          maxRetries = request.retries;
        } else if (request.retries.constructor === Array) {
          maxRetries = request.retries.length;
          // Check for undefined because currentRetry can go over the bounds of the array.
          if (request.retries[request.currentRetry] !== undefined) {
            currentTimeout = request.retries[request.currentRetry];
          }
        } else {
          throw new Error('Retries should be either a number or an array of numbers.');
        }
      }

      // Request failed.
      if (request.currentRetry < maxRetries) {
        // Max number of requests not yet reached.
        log(`Failed loading: ${request.url}. Retries left: ${maxRetries - request.currentRetry}`);
        request.currentRetry++;

        if (currentTimeout == 0) {
          log(`${request.url}: Immediate retry.`);
          // Immediately retry the request if timeout is zero.
          this.decNumberOfReqs(request);
          this.handleRequestPromise(request);
        } else {
          // If the timeout is not zero, set a delayed request and continue with the next request.
          log(`${request.url}: Retrying request with new timeout: (${currentTimeout} seconds.)`);
          this.decNumberOfReqs(request);
          this.handleRequestPromise(request, currentTimeout);
        }
      } else {
        log(`Failed loading: ${request.url}. No retries left. Request failed.`);
        // After all retries have been used up,
        if (this.retryQueueingEnabled && !request.alwaysCallFailureIfOutOfRetries) {
          //queue the request for retries and go onto the next request.
          //(unless the request has a setting that it shouldn't be queued for retries)
          request.status(RequestStatus.QueuedForRetry);
          this.failedRequests.push(request as Request<any, any>);
        } else request.failure(errorResponse);

        this.decNumberOfReqs(request);
        this.startNewRequests();
      }
    }
  }

  /**
   * Increase current number of requests.
   * @param request - Only used to check if it is a Critical Request to increase the appropriate variable.
   */
  @action
  private incNumberOfReqs<T, R>(request: Request<T, R>): void {
    this.curNumberOfReqs++;
    if (request.priority == RequestPriority.Critical) this.curNumberOfCriticalReqs++;
  }

  /**
   * Decrease current number of requests.
   * @param request - Only used to check if it is a Critical Request to decrease the appropriate variable.
   */
  @action
  private decNumberOfReqs<T, R>(request: Request<T, R>): void {
    this.curNumberOfReqs--;
    if (request.priority == RequestPriority.Critical) this.curNumberOfCriticalReqs--;
  }

  /**
   * When called, retries all requests in the failed request array. They go through the regular request queue.
   */
  @action
  public retryFailedRequests(): void {
    log('Retry failed requests called.');
    // This for loop runs in reverse to prevent issues with splicing during the loop.
    for (let i = this.failedRequests.length - 1; i >= 0; i--) {
      const request = this.failedRequests[i];
      // Reset the retries.
      request.currentRetry = 0;
      // Update status
      request.status(RequestStatus.RetryingFailedRequest);
      // Push it again to the queue.
      this.push(request);
      // Start request handling anytime a new request is added.
      this.startNewRequests();
      // Remove the request from the array to prevent doubles.
      this.failedRequests.splice(i, 1);
    }
  }

  /**
   * Push a request to a specific queue (based on priority).
   *
   * @param request
   * @param priority
   */
  @action
  private push<T, R>(request: Request<T, R>): void {
    // Get the queue with this request's priority and push the request to it.
    const queue = this.queues.get(request.priority);
    queue.push(request as Request<any, any>);
  }

  @action
  private pop<T, R>(): Request<T, R> {
    // Iterate from highest prio to lowest to pop the first url form the highest prio queue.
    for (const queue of this.queues.values()) {
      const request = this.popFromPriorityQueue<T, R>(queue as Request<T, R>[]);
      if (request) return request;
    }

    return null;
  }

  private popFromPriorityQueue<T, R>(queue: Request<T, R>[]): Request<T, R> {
    while (queue.length > 0) {
      const request = queue.shift();
      // If the request is aborted, we fulfill the promise and pop the next request from the queue.
      if (request.aborted) {
        request.handleAbort();
      } else {
        return request;
      }
    }

    return null;
  }
  @computed
  public get queuedCount(): number {
    let count = 0;
    for (const queue of this.queues.values()) {
      count += queue.length;
    }
    return count;
  }

  @computed
  // Only used in unit tests.
  public get runningCount(): number {
    return this.curNumberOfReqs;
  }

  @computed
  public get count(): number {
    return this.queuedCount + this.runningCount;
  }

  @computed
  private get hasQueuedItems(): boolean {
    for (const queue of this.queues.values()) {
      if (queue.length > 0) return true;
    }
    return false;
  }
}
