import { log } from '@utomik-app-monorepo/logger';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';
import { action, computed, makeObservable, observable } from 'mobx';

import {
  AuthorizationStore,
  LoginKeyResponse,
  TokenAuthResponse,
} from '../stores/authorizationStore/authorizationStore';

export type ALCCallback = () => Promise<LoginKeyResponse | null>;
export type RefreshResultCallback = (result: boolean) => void;

export class SecurityToken {
  private _authorizationStore: AuthorizationStore;
  private _getALCCB: ALCCallback;
  // Decided on a callback instead of EventEmitter, so we can be sure that after a refresh this was called
  private _refreshResultCB: RefreshResultCallback;
  private _refreshPromise: Promise<void> = null;
  @observable
  private _authResponse: TokenAuthResponse;

  public constructor() {
    makeObservable(this);
  }

  public init(
    authorizationStore: AuthorizationStore,
    refreshResultCB: RefreshResultCallback,
    alcCB: ALCCallback
  ): boolean {
    this._getALCCB = alcCB;
    this._refreshResultCB = refreshResultCB;
    this._authorizationStore = authorizationStore;
    return true;
  }

  @action
  public setValue(authResponse: TokenAuthResponse): void {
    this._authResponse = authResponse;
  }

  @computed
  public get value(): TokenAuthResponse {
    return this._authResponse;
  }

  @computed
  public get isSet(): boolean {
    return !isNullOrUndefined(this._authResponse);
  }

  @computed
  public get expiresAt(): Date {
    return this._authResponse?.expiresAt;
  }

  @computed
  public get expiresAtUnixTime(): number {
    return this._authResponse?.expiresAt.getTime() / 1000;
  }

  @computed
  public get userId(): number {
    return this._authResponse?.userId;
  }

  @computed
  public get encodedValue(): string {
    return this._authResponse?.token;
  }

  @computed
  public get httpToken(): string {
    return this._authResponse?.getHttpToken();
  }

  /**
   * Attempts to update the JWT token.
   * Note: This function will return a shared promise.
   */
  public async refresh(): Promise<void> {
    if (isNullOrUndefined(this._authorizationStore)) return Promise.resolve();
    if (this._refreshPromise) return this._refreshPromise;

    log(`Refreshing token.`);
    // We create a new promise here, so we can 'cache' and share it.
    this._refreshPromise = new Promise<void>((resolve, reject) => {
      const onSuccess = async (token: TokenAuthResponse): Promise<void> => {
        this.setValue(token);
        this._refreshResultCB(true);
        log(`Refreshing token success.`);
        resolve();
      };

      this.refreshWithToken()
        .then((token) => {
          onSuccess(token);
        })
        .catch((ex) => {
          console.error(`Failed. Reason: ${ex?.message ? ex.message : ex}`);
          this._refreshResultCB(false);
          reject(ex);
        });
    }).finally(() => {
      this._refreshPromise = null;
    });
    return this._refreshPromise;
  }

  /**
   * Attempts to update the JWT token using an ALC token or JWT token.
   */
  private async refreshWithToken(): Promise<TokenAuthResponse> {
    try {
      // We always try to refresh with an ALC token, but if we don't have it,
      // we try to refresh with a JWT token instead.
      // E.g.external logins with a JWT token don't come with an ALC token.
      let newToken: TokenAuthResponse = null;
      const alc: LoginKeyResponse = await this._getALCCB();
      if (!isNullOrUndefined(alc)) newToken = await this._authorizationStore.loginWithALC(alc);
      else newToken = await this._authorizationStore.loginWithJWT(this._authResponse);
      log(`Refresh success.`);
      return newToken;
    } catch (ex: any) {
      console.error(`Refresh failed. Reason: ${ex?.message ? ex.message : ex}`);
      throw ex;
    }
  }
}
