import { faClock, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { Application } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import React from 'react';
import './session-info.scss';
export const SessionInfo = observer(({
  app
}: {
  app: Application;
}) => {
  return <div className={'session-data__container'}>
      <div className={'last-played__container'}>
        <FontAwesomeIcon icon={faClock} className={'icon'} />
        <div className={'time__container'}>
          <p>{t({
            context: 'How long was prev session',
            message: 'Last session'
          })}</p>
          <span>{app?.userStat?.lastPlayed ? moment(app?.userStat?.lastPlayed).format('llll') : '-'}</span>
        </div>
      </div>
      <div className={'total-playtime__container'}>
        <FontAwesomeIcon className={'icon'} icon={faGamepad} />
        <div className={'time__container'}>
          <p>{t({
            message: 'Total play time'
          })}</p>
          <span>{(moment.duration(app?.userStat?.secondsPlayed, 'seconds') as any).format('h[h] m[m]')}</span>
        </div>
      </div>
    </div>;
});