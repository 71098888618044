import { action, computed, makeObservable, observable } from 'mobx';
import { ChannelList } from '../../../dataStore/stores/channelStore/channelList';
import { AsyncObjectState } from '../../../dataStore/stores/objectStore/asyncObject';

/**
 * Provides Channel Tiles for the Carousel.
 */
export class ChannelTileProvider {
  @observable
  public slug: string;
  @observable
  private _name: string;
  @observable
  protected _channelList: ChannelList;
  @observable
  private _initialLoaded = false;
  @action
  private _setInitialLoaded(initialLoaded: boolean): void {
    this._initialLoaded = initialLoaded;
  }
  @observable
  private _updateBit = false;
  @observable
  private _featured = false;
  @computed
  public get name(): string {
    return this._name;
  }
  @computed
  public get loops(): boolean {
    return this._featured;
  }
  @action
  public setName(name: string): void {
    this._name = name;
  }
  @computed
  public get updateBit(): boolean {
    return this._updateBit;
  }

  /**
   * Touch flipBit so that a carousel will be able to redraw the items on demand.
   * This is mainly used for recentlyPlayedProvider.
   */
  @action
  public flipBit(): void {
    this._updateBit = !this._updateBit;
  }
  public get expandable(): boolean {
    return false;
  }
  @computed
  public get state(): AsyncObjectState {
    return this._channelList?.state || AsyncObjectState.None;
  }
  @computed
  public get length(): number {
    return this._channelList?.length || 0;
  }
  @computed
  public get confirmedEmpty(): boolean {
    return this.length === 0 && this._initialLoaded;
  }
  @action
  public setChannelList(channelList: ChannelList): void {
    this._channelList = channelList;
    /**
     * Experimental, encourage carousels to rerender when ChannelList has changed.
     */
    this.flipBit();
  }
  @computed
  public get channelList(): ChannelList {
    return this._channelList;
  }

  /**
   * This just initializes the provider with the provided parameters. You can see what they do in the initialize function.
   *
   * @param slug
   * @param name
   * @param channelList
   */
  public constructor(slug: string, name: string, channelList: ChannelList = null, featured = false) {
    makeObservable(this);
    this.initialize(slug, name, channelList, featured);
  }

  /**
   * Initialize the channel-tile-provider.
   * @param slug - Unique name for the channel.
   * @param name - Name of the provider.
   * @param channelList - The list of channels.
   */
  @action
  private initialize(slug: string, name: string, channelList: ChannelList, featured: boolean): void {
    this.slug = slug;
    this._name = name;
    this.setChannelList(channelList);
    this._featured = featured;
  }
}