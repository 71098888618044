import { NavigationControllerContext } from '@utomik-app-monorepo/store';
import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
export const useNavigationReset = () => {
  const navigationController = React.useContext(NavigationControllerContext);
  const location = useLocation();
  useLayoutEffect(() => {
    if (navigationController.currentId && navigationController.currentId !== navigationController.currentApp?.id) {
      navigationController.setCurrentApp();
    }
  }, [location.pathname]);
};