import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { ClientControllerContext, StreamViewUtilsContext } from '@utomik-app-monorepo/store';
import { ConnectionQuality } from '@utomik-app-monorepo/stream-view';
import cx from 'classnames';
import throttle from 'lodash/throttle';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './connection-notificator.scss';
export const ConnectionNotificator = observer(function ConnectionNotificator() {
  const [qualityData, setQualityData] = useState<{
    message: string;
    name: ConnectionQuality;
  }>({
    message: '',
    name: 'excellent'
  });
  const [hitsCounter, setHitsCounter] = useState<number>(0);
  const streamViewUtilsService = useContext(StreamViewUtilsContext);
  const clientController = useContext(ClientControllerContext);
  useEffect(() => {
    const debounceRefLocal = debounceRef.current;
    return () => debounceRefLocal?.cancel();
  }, []);
  const debounceRef = useRef<ReturnType<typeof throttle>>(throttle((quality: ConnectionQuality) => {
    setHitsCounter(prev => prev + 1);
    switch (quality) {
      case 'excellent':
        {
          setQualityData({
            name: 'excellent',
            message: ''
          });
          break;
        }
      case 'good':
        {
          setQualityData({
            name: 'good',
            message: ''
          });
          break;
        }
      case 'medium':
        {
          setQualityData({
            name: 'medium',
            message: t({
              context: 'Medium quality internet connection message',
              message: 'Internet connection is poor. The game can stutter or freeze.'
            })
          });
          break;
        }
      case 'low':
        {
          setQualityData({
            name: 'low',
            message: t({
              context: 'Low quality internet connection message',
              message: 'Internet connection is unstable. Game performance may suffer.'
            })
          });
          break;
        }
    }
  }, 5000));

  //show notification minimized after 5 times or if the setting is enabled
  const showMinimized = hitsCounter > 5 || clientController.isNetworkNotificationMinimized;
  useEffect(() => {
    debounceRef.current(streamViewUtilsService.connectionQuality);
  }, [streamViewUtilsService.connectionQuality]);
  return <div className={cx(`connection-notificator__wrapper--${qualityData.name}`, {
    'connection-notificator__wrapper--minimized': showMinimized
  })}>
      <div className={'icon-container'}>
        <FontAwesomeIcon icon={faWifi} />
      </div>
      <div className={'message-container'}>{qualityData.message}</div>
    </div>;
});