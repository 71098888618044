import supportedLocales from './supportedLocales';

export const detectLocale = () => {
  // Check localStorage first
  const savedLocale = localStorage.getItem('appLocale');
  if (savedLocale && supportedLocales.includes(savedLocale)) {
    return savedLocale;
  }

  // Detect from browser
  if (typeof navigator !== 'undefined') {
    const browserLocales = navigator.languages ? navigator.languages : [navigator.language || navigator.userLanguage];

    for (const locale of browserLocales) {
      // Exact match
      if (supportedLocales.includes(locale)) {
        return locale;
      }

      // Primary language subtag
      const primaryLocale = locale.split('-')[0];
      if (supportedLocales.includes(primaryLocale)) {
        return primaryLocale;
      }
    }
  }

  // Fallback
  return 'en';
};

/**
 * Changes the application's locale and persists the choice.
 * @param {string} locale - The locale to activate.
 * @param {object} i18n - The i18n main instance.
 */
export const setCurrentLocale = (locale, i18n) => {
  if (supportedLocales.includes(locale)) {
    i18n.activate(locale);
    localStorage.setItem('appLocale', locale);
  } else {
    console.warn(`Unsupported locale: ${locale}`);
  }
};
