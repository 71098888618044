import { platformURL } from '@utomik-app-monorepo/constants';
import { getCurrentLanguage } from '@utomik-app-monorepo/utils';

import { LgPersonalizedKeyToken } from '../../dataStore/lgPersonalizedKeyToken/lgPersonalizedKeyToken';
import { RequestQueue } from '../../dataStore/requestQueue/requestQueue';
import { SecurityToken } from '../../dataStore/securityToken/securityToken';
import { GatewayStore } from '../../dataStore/stores/GatewayStore/GatewayStore';
import { AccountSummary } from '../../dataStore/stores/accountSummary/accountSummary';
import { AgeRatingSystemStore } from '../../dataStore/stores/ageRatingSystemStore/ageRatingSystemStore';
import { AppList } from '../../dataStore/stores/applicationStore/appList';
import { ApplicationStore } from '../../dataStore/stores/applicationStore/applicationStore';
import { AuthorizationStore } from '../../dataStore/stores/authorizationStore/authorizationStore';
import { ChannelLinkList } from '../../dataStore/stores/channelLinkList/channelLinkList';
import { ChannelStore } from '../../dataStore/stores/channelStore/channelStore';
import { ClientStagingTierStore } from '../../dataStore/stores/clientStagingtierStore/clientStagingtierStore';
import { Countries } from '../../dataStore/stores/countries/countries';
import { GenreStore } from '../../dataStore/stores/genreStore/genreStore';
import { IssueTypes } from '../../dataStore/stores/issueTypes/issueTypes';
import { LanguagesStore } from '../../dataStore/stores/languagesStore/languagesStore';
import { LGStore } from '../../dataStore/stores/lgStore/lgStore';
import { LogsController } from '../../dataStore/stores/logsStore/logsController';
import { MyListStore } from '../../dataStore/stores/myListStore/myListStore';
import { NewsItemStore } from '../../dataStore/stores/newsStore/newsItemStore';
import { GlobalAchievementStore } from '../../dataStore/stores/ninjaSummary/globalAchievementStore';
import { GlobalStatisticStore } from '../../dataStore/stores/ninjaSummary/globalStatisticStore';
import { GlobalStatisticValueStore } from '../../dataStore/stores/ninjaSummary/globalStatisticValueStore';
import { GlobalUnlockedAchievementStore } from '../../dataStore/stores/ninjaSummary/globalUnlockedAchievementStore';
import { NinjaSummary } from '../../dataStore/stores/ninjaSummary/ninjaSummary';
import { ServiceSettingStore } from '../../dataStore/stores/ninjaSummary/serviceSettingStore';
import { RecentlyPlayedStore } from '../../dataStore/stores/recentlyPlayedStore/recentlyPlayedStore';
import { SearchResultStore } from '../../dataStore/stores/searchResultStore/searchResultStore';
import { StreamStore } from '../../dataStore/stores/streamStore/streamStore';
import { SubscriptionPlansStore } from '../../dataStore/stores/subscriptionPlansStore/subscriptionPlansStore';
import { UserAvatarStore } from '../../dataStore/stores/userAvatarStore/userAvatarStore';
import { UserRatingStore } from '../../dataStore/stores/userRatingStore/userRatingStore';
import { UserStatsStore } from '../../dataStore/stores/userStatsStore/userStatsStore';
import { CompleteProfileHandler, User } from '../../dataStore/stores/userStore/user';
import { AxiosTransport } from '../../dataStore/transports/axiosTransport/axiosTransport';
import { AccountSummaryController } from './accountSummary/accountSummaryController';
import { AchievementController } from './achievementController/achievementController';
import { AnalyticController } from './analyticController/analyticController';
import { IUserPropertiesAnalytic } from './analyticController/clients/googleAnalyticsManager';
import { AppInfoController } from './appInfoController/appInfoController';
import { ChannelInfoController } from './channelInfoController/channelInfoController';
import { ChannelsController } from './channelsController/channelsController';
import { ClientController } from './clientController/clientController';
import { ConnectionMonitor } from './connectionMonitor/connectionMonitor';
import { ConnectivityReporter } from './connectivityReporter/connectivityReporter';
import { DialogFactory } from './dialogFactory/dialogFactory';
import { DialogQueue, DialogResult } from './dialogQueue/dialogQueue';
import { EdenPreviewGamingHubController } from './edenPreviewGamingHubController/edenPreviewGamingHubController';
import { EdenPreviewType } from './edenPreviewGamingHubController/interfaces';
import { EntitlementDataGamingHubController } from './entitlementDataGamingHubController/entitlementDataGamingHubController';
import { EventController } from './eventController/eventController';
import { HomeController } from './homeController/homeController';
import { KeyboardController } from './keyboardController/keyboardController';
import { PersonalizedKeyController } from './lg_personalizedKeyController/personalizedKeyController';
import { Loader } from './loader/loader';
import { LoginController } from './loginController/loginController';
import { MediaPlayerController } from './mediaPlayerController/mediaPlayerController';
import { MouseController } from './mouseController/mouseController';
import { NavigationController } from './navigationController/navigationController';
import { NinjaController } from './ninjaController/ninjaController';
import { NotificationFactory } from './notificationFactory/notificationFactory';
import { NotificationStack } from './notificationStack/notificationStack';
import { NotificationWatcher } from './notificationWatcher/notificationWatcher';
import { PairingCodeManager } from './pairingCodeManager/pairingCodeManager';
import { PersistentStore } from './persistentStore/persistentStore';
import { PlatformController } from './platformController/platformController';
import { RecentlyPlayedGameHubController } from './recentlyPlayedGameHubController/recentlyPlayedGameHubController';
import { SettingsViewController } from './settingsViewController/settingsViewController';
import { StreamViewUtilsService } from './streamViewUtilsService/streamViewUtilsService';
import { StreamingFeedbackController } from './streamingFeedbackController/streamingFeedbackController';
import { TokenManager } from './tokenManager/tokenManager';
import { TvSidebarController } from './tvSidebarController/tvSidebarController';
import UploadService from './uploadService/uploadService';
import { VideoPlayerController } from './videoPlayerController/videoPlayerController';

// End of imports

/**
 * The main store where all the other stores are set up. Also handles login and logout callbacks for some reason (if you know why, please add that here
 * or above the relevant code where that happens). Also sets some user stuff, without which, the user dropdown menu will be empty (but otherwise still work).
 */
export class RootStore {
  // Smart TV's instances
  public readonly platformController: PlatformController;
  public readonly logsController: LogsController;

  // Stream View
  public readonly streamViewUtilsService: StreamViewUtilsService;

  // Transport & Authorization
  private readonly httpTransport: AxiosTransport;
  private readonly authorizationStore: AuthorizationStore;
  private readonly requestQueue: RequestQueue;
  public readonly tokenManager: TokenManager;
  public readonly pairingCodeManager: PairingCodeManager;
  public readonly securityToken: SecurityToken;

  // Browser Stores
  private readonly sessionStore: PersistentStore;

  // Connectivity
  private readonly connectivityReporter: ConnectivityReporter;
  public readonly connectionMonitor: ConnectionMonitor;
  public readonly subscriptionPlansStore: SubscriptionPlansStore;

  // Data Stores
  public readonly applicationStore: ApplicationStore;
  public readonly channelStore: ChannelStore;
  public readonly recentlyPlayedStore: RecentlyPlayedStore;
  public readonly myListStore: MyListStore;
  public readonly channelLinkList: ChannelLinkList;
  public readonly genreStore: GenreStore;
  public readonly searchResultStore: SearchResultStore;
  public readonly ageRatingSystemStore: AgeRatingSystemStore;
  public readonly userRatingStore: UserRatingStore;
  public readonly userStatsStore: UserStatsStore;
  public readonly userAvatarStore: UserAvatarStore;
  public readonly clientStagingTierStore: ClientStagingTierStore;
  public readonly issueTypes: IssueTypes;
  public readonly ninjaSummary: NinjaSummary;
  public readonly accountSummary: AccountSummary;
  public readonly globalAchievementStore: GlobalAchievementStore;
  public readonly globalUnlockedAchievementStore: GlobalUnlockedAchievementStore;
  public readonly globalStatisticStore: GlobalStatisticStore;
  public readonly globalStatisticValueStore: GlobalStatisticValueStore;
  public readonly serviceSettingStore: ServiceSettingStore;
  public readonly newsItemStore: NewsItemStore;
  public readonly streamStore: StreamStore;
  public readonly gatewayStore: GatewayStore;
  public readonly languagesStore: LanguagesStore;

  // LG stuff
  public readonly lgStore: LGStore;
  public readonly lgPersonalizedKeyToken: LgPersonalizedKeyToken;

  public readonly recommendedApps: AppList;

  // Application Controllers
  public readonly clientController: ClientController;
  public readonly homeController: HomeController;
  public readonly loginController: LoginController;
  public readonly appInfoController: AppInfoController;
  public readonly channelInfoController: ChannelInfoController;
  public readonly ninjaController: NinjaController;
  public readonly accountSummaryController: AccountSummaryController;
  public readonly achievementController: AchievementController;
  public readonly channelsController: ChannelsController;
  public readonly navigationController: NavigationController;
  public readonly videoPlayerController: VideoPlayerController;
  public readonly eventController: EventController;

  //TV
  public readonly tvSidebarController: TvSidebarController;
  public readonly mouseController: MouseController;
  public readonly keyboardController: KeyboardController;
  public readonly mediaPlayerController: MediaPlayerController;
  public readonly streamingFeedbackController: StreamingFeedbackController;

  // Tizen - GamingHub
  public readonly recentlyPlayedGameHubController: RecentlyPlayedGameHubController;
  public readonly entitlementDataGamingHubController: EntitlementDataGamingHubController;
  public readonly edenPreviewGamingHubController: EdenPreviewGamingHubController;

  // LG - Gaming Hub
  public readonly personalizedKeyController: PersonalizedKeyController;

  // Other
  public readonly countries: Countries;
  public readonly analyticController: AnalyticController;
  public readonly dialogQueue: DialogQueue;
  public readonly dialogFactory: DialogFactory;
  public readonly notificationStack: NotificationStack;
  public readonly notificationFactory: NotificationFactory;
  public readonly notificationWatcher: NotificationWatcher;

  public readonly uploadService: UploadService;

  public readonly settingsViewController: SettingsViewController;

  public readonly loader: Loader;

  public constructor() {
    this.platformController = new PlatformController();

    // analytics
    this.analyticController = new AnalyticController(this.platformController);

    // Security token
    this.securityToken = new SecurityToken();

    // Dialogs
    this.dialogQueue = new DialogQueue(this.analyticController);
    this.dialogFactory = new DialogFactory(this.dialogQueue, this.analyticController);

    // Browser Stores
    this.connectivityReporter = new ConnectivityReporter(this.dialogFactory, this.dialogQueue);
    this.sessionStore = new PersistentStore(
      <T>(key: string): T => {
        return JSON.parse(localStorage.getItem(key)) as T;
      },
      <T>(key: string, value: T): void => {
        localStorage.setItem(key, JSON.stringify(value));
      },
      (name): void => {
        localStorage.removeItem(name);
      }
    );

    // Set loader
    this.loader = new Loader(this);

    // Transports & Authorization
    this.httpTransport = new AxiosTransport(platformURL, this.securityToken);
    this.httpTransport.resetDefaultConfig('en');
    //Upload files service
    this.uploadService = new UploadService(this.httpTransport);
    // Callback when the TokenManager wants to logout and has to complete first.
    const beforeLogoutCallback = async (): Promise<void> => {
      this.dialogQueue.clear();
      // Show logging out dialog
      this.dialogFactory.showLoggingOutDialog();
      this.searchResultStore.clear();
      await this.requestQueue.clear();
      this.httpTransport.resetDefaultConfig('en');
      //add this code here to have access to the settings and subscriptions data on the auth view
      this.loader.unload();
      this.httpTransport.reset();
      await this.loader.unauthorizedPreload();
    };
    // Callback when the TokenManager logout is triggered.
    const logoutCallback = async (): Promise<void> => {
      // End analytics
      this.analyticController.stop();
      this.logsController?.stop();
      // The reset
      this.streamingFeedbackController.unload();
      this.clientController.setUser(null);
      this.homeController.unload();
      this.ninjaController.unload();
      // Clear carousel cache on home and carousel states on other pages.
      this.channelsController.clear();
      this.dialogQueue.clear();
      this.connectivityReporter.disable(); // disable the connectivity reporter until log in, that way any errors during login can still be handled
      this.requestQueue.disableRetryQueueing();

      if (this.platformController.isTizen) {
        this.entitlementDataGamingHubController.generateAndSend();
        this.recentlyPlayedGameHubController.generateAndSend(true);
      }

      if (this.platformController.isWebOS) {
        this.personalizedKeyController.clearPersonalKeyService();
      }
    };

    // This callback triggers after inputting a correct combination of username and password.
    const loginCallback = async (token: SecurityToken): Promise<void> => {
      this.logsController.init();

      this.httpTransport.initialize(token);
      this.connectivityReporter.enable(); // now enable the connectivity reporter after login, that way any errors during login can still be handled

      // This handler is used by the complete profile feature.
      const completeProfileHandler: CompleteProfileHandler = async (user: User) => {
        const result = await this.dialogFactory.showCompleteProfileDialog(user, this.countries);
        // Only consider DialogResult "OK" as completed. All other scenarios should return false.
        return result === DialogResult.OK;
      };
      const user = new User(
        this.requestQueue,
        this.userStatsStore,
        this.userRatingStore,
        this.userAvatarStore,
        token.userId,
        completeProfileHandler
      );

      try {
        // Ensure that the user is loaded and has a complete profile.
        // CompleteProfileHandler will be used if it is not complete.
        await user.completeProfile();
      } catch (ex) {
        // First we logout
        await this.tokenManager.logout();
        // Then we retrow the error so it can be handled elsewhere
        //throw ex;
        return;
      }

      // Enable the queue-ing of failed requests after complete profile
      this.requestQueue.enableRetryQueueing();

      // Set user object.
      this.clientController.setUser(user);

      // Wait until everything is done preloading or the timeout is reached.
      // We are faced with issue if some channel, throw an error we didn't catch
      // that error here
      try {
        this.loader.unload();

        await Promise.allSettled([this.loader.load(), this.userStatsStore.fetch()]);

        const analyticUserProps: IUserPropertiesAnalytic = {
          new_user: { value: `${!this.userStatsStore.userStats.gamesPlayed}` },
          user_country: { value: user.country?.name },
          user_language: { value: getCurrentLanguage() },
          games_played: { value: `${this.userStatsStore.userStats.gamesPlayed}` },
          user_os_version: { value: this.platformController.osVersion },
          user_screen_resolution: { value: `${window.screen.availWidth}/${window.screen.availHeight}` },
          system_vendor: { value: this.platformController.deviceBrand },
        };

        // Initialize analytics
        this.analyticController.start(token.userId, analyticUserProps);
        this.analyticController.login();
        this.analyticController.cloudSetting();
      } catch (e) {
        console.error(e, 'Loader error');
      } finally {
        if (this.platformController.isTizen) {
          this.entitlementDataGamingHubController.generateAndSend();
          this.recentlyPlayedGameHubController.generateAndSend();
          this.edenPreviewGamingHubController.generateAndSend(EdenPreviewType.all);
        }
      }
    };
    const lgPersonalKeyServiceStart = (): void => {
      if (this.platformController.isWebOS) {
        this.personalizedKeyController.setPersonalKeyService();
      }
    };
    this.requestQueue = new RequestQueue(this.httpTransport, this.connectivityReporter);

    this.issueTypes = new IssueTypes(this.requestQueue);
    // LG GamingHub
    this.lgStore = new LGStore(this.requestQueue);
    this.lgPersonalizedKeyToken = new LgPersonalizedKeyToken();
    this.personalizedKeyController = new PersonalizedKeyController(this.lgPersonalizedKeyToken);

    //avatar Store
    this.userAvatarStore = new UserAvatarStore(this.requestQueue, this.uploadService);

    this.authorizationStore = new AuthorizationStore(this.requestQueue);
    this.tokenManager = new TokenManager(
      this.platformController,
      this.securityToken,
      this.authorizationStore,
      this.sessionStore,
      this.lgStore,
      this.lgPersonalizedKeyToken,
      this.dialogFactory,
      loginCallback,
      beforeLogoutCallback,
      logoutCallback,
      lgPersonalKeyServiceStart,
      3600000
    );
    this.connectionMonitor = new ConnectionMonitor(this.connectivityReporter, this.requestQueue);

    //Pairing code manager
    this.pairingCodeManager = new PairingCodeManager(this.authorizationStore, this.tokenManager);

    // Notifications
    this.notificationStack = new NotificationStack();
    this.notificationFactory = new NotificationFactory(this.notificationStack);
    this.notificationWatcher = new NotificationWatcher(this.notificationFactory, this.connectionMonitor);

    this.settingsViewController = new SettingsViewController();

    // Stores
    this.languagesStore = new LanguagesStore(this.requestQueue);
    this.subscriptionPlansStore = new SubscriptionPlansStore(this.requestQueue);
    this.channelLinkList = new ChannelLinkList(this.requestQueue);
    this.genreStore = new GenreStore(this.requestQueue, this.channelLinkList);
    this.userRatingStore = new UserRatingStore(this.requestQueue);
    this.userStatsStore = new UserStatsStore(this.requestQueue);
    this.clientStagingTierStore = new ClientStagingTierStore(this.requestQueue);
    this.ageRatingSystemStore = new AgeRatingSystemStore(this.requestQueue);
    this.applicationStore = new ApplicationStore(
      this.requestQueue,
      this.genreStore,
      this.ageRatingSystemStore,
      this.userRatingStore,
      this.userStatsStore,
      this.analyticController,
      this.channelLinkList,
      this.languagesStore
    );
    this.channelStore = new ChannelStore(this.applicationStore);
    this.recentlyPlayedStore = new RecentlyPlayedStore(this.applicationStore);
    this.myListStore = new MyListStore(
      this.applicationStore,
      this.notificationFactory,
      this.channelStore,
      this.analyticController
    );
    this.searchResultStore = new SearchResultStore(this.requestQueue);
    this.newsItemStore = new NewsItemStore(this.requestQueue);
    this.countries = new Countries(this.requestQueue);
    this.gatewayStore = new GatewayStore(this.requestQueue, this.sessionStore);
    this.streamStore = new StreamStore(this.requestQueue);

    this.recommendedApps = new AppList(this.applicationStore, 'Recommended4U', 'v2/recommended/applications');

    // NinjaSummary
    this.globalUnlockedAchievementStore = new GlobalUnlockedAchievementStore(this.requestQueue);
    this.globalAchievementStore = new GlobalAchievementStore(this.requestQueue, this.globalUnlockedAchievementStore);
    this.globalStatisticValueStore = new GlobalStatisticValueStore(this.requestQueue);
    this.globalStatisticStore = new GlobalStatisticStore(this.requestQueue, this.globalStatisticValueStore);
    this.serviceSettingStore = new ServiceSettingStore(this.requestQueue);
    this.ninjaSummary = new NinjaSummary(
      this.globalAchievementStore,
      this.globalUnlockedAchievementStore,
      this.globalStatisticStore,
      this.globalStatisticValueStore,
      this.serviceSettingStore
    );

    this.accountSummary = new AccountSummary(this.requestQueue);
    this.clientController = new ClientController(
      this.tokenManager,
      this.countries,
      async (lang): Promise<void> => {
        // On language change.
        this.httpTransport.defaultConfig.headers['Accept-Language'] = lang;
        location.reload();
      },
      async (): Promise<boolean> => {
        return true;
      }
    );

    this.logsController = new LogsController(
      this.platformController,
      this.httpTransport,
      this.requestQueue,
      this.issueTypes,
      this.clientController
    );
    this.channelsController = new ChannelsController(this.channelStore);

    this.keyboardController = new KeyboardController(this.logsController);
    this.mouseController = new MouseController(this.keyboardController);

    this.tvSidebarController = new TvSidebarController();

    //StreamView
    this.streamViewUtilsService = new StreamViewUtilsService(
      this.tokenManager,
      this.sessionStore,
      this.applicationStore,
      this.platformController
    );

    this.navigationController = new NavigationController(
      this.clientController,
      this.dialogFactory,
      this.streamViewUtilsService,
      this.applicationStore,
      this.dialogQueue,
      this.analyticController,
      this.logsController
    );

    this.videoPlayerController = new VideoPlayerController(
      this.navigationController,
      this.dialogQueue,
      this.tvSidebarController
    );

    this.homeController = new HomeController(
      this.channelStore,
      this.recentlyPlayedStore,
      this.myListStore,
      this.recommendedApps,
      this.channelsController,
      this.applicationStore
    );

    this.eventController = new EventController(this.channelStore);

    this.appInfoController = new AppInfoController();

    this.channelInfoController = new ChannelInfoController(this.channelStore);
    this.ninjaController = new NinjaController(this.channelStore, this.clientController, this.ninjaSummary);
    this.accountSummaryController = new AccountSummaryController(
      this.accountSummary,
      this.notificationFactory,
      this.analyticController
    );
    this.achievementController = new AchievementController();

    // Other
    this.loginController = new LoginController(
      this.tokenManager,
      this.authorizationStore,
      this.dialogQueue,
      this.dialogFactory
    );
    this.streamingFeedbackController = new StreamingFeedbackController(
      this.requestQueue,
      this.streamViewUtilsService,
      this.serviceSettingStore,
      this.clientController
    );

    // GamingHub controllers
    this.recentlyPlayedGameHubController = new RecentlyPlayedGameHubController(
      this.recentlyPlayedStore,
      this.userStatsStore
    );
    this.entitlementDataGamingHubController = new EntitlementDataGamingHubController(
      this.accountSummary,
      this.tokenManager
    );
    this.edenPreviewGamingHubController = new EdenPreviewGamingHubController(
      this.recentlyPlayedStore,
      this.recommendedApps
    );
    this.mediaPlayerController = new MediaPlayerController(this.dialogFactory);
  }
}
