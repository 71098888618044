import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FocusablePlayButtons, StarRating, TagsList } from '@utomik-app-monorepo/components';
import { SimpleButton } from '@utomik-app-monorepo/components';
import { Routes } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, AnalyticItemTypes, AppList, Application, ApplicationStoreContext, AsyncObjectState, NavigationControllerContext, ObjectID } from '@utomik-app-monorepo/store';
import { joinLocation, repeatFnCall } from '@utomik-app-monorepo/utils';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from './main-slider.module.scss';
import './main-slider.scss';
type Props = {
  kind?: 'Fader' | 'Slider';
  appList: AppList;
  isLoading?: boolean;
  onSlideChange?: (slideNumber: number) => void;
  dotsFocusKey?: string;
  onChildFocused?: (props: any, itemProps: any) => void;
  onSlidePress?: (index?: number) => void;
  name: string;
  analyticsSectionName?: string;
  analyticsOriginSlug?: string;
};
export const FocusableWrapper = withFocusable()<{
  sliderRef: React.MutableRefObject<Slider>;
  onSlidePress: (slideNumber: number) => void;
}>(({
  focused,
  children,
  pauseSpatialNavigation,
  resumeSpatialNavigation,
  navigateByDirection,
  sliderRef,
  onSlidePress
}) => {
  const navigationController = useContext(NavigationControllerContext);
  useEffect(() => {
    //gamepad interaction

    const handleUp = () => {
      resumeSpatialNavigation();
      repeatFnCall(navigateByDirection.bind(null, 'up'));
    };
    const handleDown = () => {
      resumeSpatialNavigation();
      repeatFnCall(navigateByDirection.bind(null, 'down'));
    };
    const handleRight = () => {
      sliderRef.current.slickNext();
      repeatFnCall(sliderRef.current.slickPause);
    };
    const handleLeft = () => {
      sliderRef.current.slickPrev();
      repeatFnCall(sliderRef.current.slickPause);
    };
    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        const activeSlideNumber = children.findIndex(el => el.props.className === 'slick-active') || 0;
        onSlidePress(activeSlideNumber);
      }
    };
    if (focused) {
      pauseSpatialNavigation();
      window.addEventListener('gamepad-right', handleRight);
      window.addEventListener('gamepad-left', handleLeft);
      window.addEventListener('gamepad-up', handleUp);
      window.addEventListener('gamepad-down', handleDown);
      window.addEventListener('keydown', handleKeyDown);
    } else {
      resumeSpatialNavigation();
      window.removeEventListener('gamepad-right', handleRight);
      window.removeEventListener('gamepad-left', handleLeft);
      window.removeEventListener('gamepad-up', handleUp);
      window.removeEventListener('gamepad-down', handleDown);
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      resumeSpatialNavigation();
      window.removeEventListener('gamepad-right', handleRight);
      window.removeEventListener('gamepad-left', handleLeft);
      window.removeEventListener('gamepad-up', handleUp);
      window.removeEventListener('gamepad-down', handleDown);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [focused, children]);
  return <div className={cx(styles.focusableSwitcher, {
    [styles.focusableSwitcherFocused]: focused && navigationController.isGamepadInteraction
  })}>
        {children}
      </div>;
});
export const Slide: React.FC<{
  app: Application;
  onClick: (appId: ObjectID) => void;
  index: number;
  kind: Props['kind'];
}> = observer(({
  app,
  onClick,
  index,
  kind
}) => {
  useEffect(() => {
    app?.fetch();
  }, [app]);
  return <div className={styles.slide} onMouseUp={onClick.bind(null, index)}>
      <img src={app?.images?.find(item => item.tag === 'B3')?.url} />
      <div className={styles.gameShortInfo}>
        <StarRating className={styles.starRating} rating={app?.rating.global} isLoading={false} />
        <p className={styles.gameTitle}>{app?.name}</p>
        <p className={styles.gameDescription}>{app?.excerpt}</p>
        <TagsList className={styles.tagList} tags={app?.genres} />
      </div>
    </div>;
});
export const MainSlider = withFocusable({
  trackChildren: true
})<Props>(observer(({
  appList,
  isLoading,
  onSlideChange,
  hasFocusedChild,
  dotsFocusKey,
  onChildFocused,
  onSlidePress,
  kind = 'Slider',
  setFocus,
  name,
  analyticsSectionName,
  analyticsOriginSlug
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const notADesktop = useMediaQuery({
    query: '(max-width: 1399px)'
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });
  const sliderRef = useRef<Slider>(null);
  const navigationController = useContext(NavigationControllerContext);
  const applicationStore = useContext(ApplicationStoreContext);
  const analyticController = useContext(AnalyticControllerContext);
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const [apps, setApps] = useState<Application[]>([]);
  useEffect(() => {
    appList?.getApps(0, 8).then(res => {
      setApps(res.items);
    });
  }, [appList]);
  const handleSlidePress = useCallback((slideNumber: number) => {
    const appId = apps[slideNumber]?.id;
    if (!appId) return;
    const app = applicationStore.getItem(appId);
    const breadcrumbs = analyticController.getBreadCrumbs({
      injectOnPosition: {
        name: analyticsOriginSlug,
        position: 1
      },
      currentSection: analyticsSectionName,
      isCarousel: true
    });
    analyticController.navigate({
      item_name: app.slug,
      location_index: String(slideNumber),
      item_type: AnalyticItemTypes.Game,
      location_on_page: joinLocation(breadcrumbs)
    });
    navigate(`${Routes.AppInfo}/${appId}`);
    onSlidePress && onSlidePress(slideNumber);
  }, [apps]);
  const handlePrev = useCallback(() => {
    sliderRef.current.slickPause();
  }, []);
  const handleNext = useCallback(() => {
    sliderRef.current.slickPause();
  }, []);
  const handleBeforeChange = useCallback((_, i) => {
    setCurrentSlideIdx(i);
  }, []);
  const settings: Settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 10000,
    waitForAnimate: true,
    //fade: kind === 'Fader',
    prevArrow: <SimpleButton className={'slick-prev'} onClick={handlePrev} kind={'back'} />,
    nextArrow: <SimpleButton className={'slick-next'} onClick={handleNext} kind={'forward'} />,
    appendDots(dots: React.ReactNode): React.JSX.Element {
      return <ul className={'slick-dots'}>
              {<FocusableWrapper onBecameFocused={onChildFocused} focusKey={dotsFocusKey} sliderRef={sliderRef} onSlidePress={handleSlidePress}>
                  {dots}
                </FocusableWrapper>}
            </ul>;
    }
  };
  return <div className={cx({
    [styles.containerSlider]: kind === 'Slider',
    [styles.containerFader]: kind === 'Fader',
    [styles.containerFocused]: hasFocusedChild,
    [styles.containerLoading]: apps[0]?.state !== AsyncObjectState.Done
  })}>
          {!!name && kind === 'Slider' && <span className={styles.containerSliderName}>{name}</span>}
          <Slider beforeChange={handleBeforeChange} key={location.pathname} ref={sliderRef} {...settings} className={styles.slider}>
            {apps.map((app, idx) => <Slide kind={kind} key={app.id} app={app} index={idx} onClick={handleSlidePress} />)}
          </Slider>
          {kind === 'Fader' && !isMobile && apps[currentSlideIdx] && <FocusablePlayButtons showOutline={navigationController.isGamepadInteraction} showMoreInfoButton={!notADesktop} className={styles.mainButtonsContainer} item={apps[currentSlideIdx]} show={true} />}
        </div>;
}));