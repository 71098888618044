import { IReactionDisposer, action, autorun, makeObservable } from 'mobx';

import { ChannelStore } from '../../../dataStore/stores/channelStore/channelStore';
import { AppTileProvider } from '../../global/tile-provider/app-tile-provider';

export default class NinjaCarousel {
  private readonly _channelStore: ChannelStore;
  private readonly _channelSlug: string;

  public provider: AppTileProvider;
  private _disposer: IReactionDisposer;

  private _handleCarousel(): void {
    this._disposer = autorun(() => {
      const channel = this._channelStore?.items?.find((channel) => channel.slug === this._channelSlug);
      if (channel?.appList) {
        this.provider.setAppList(channel?.appList);
      } else if (this.provider.appList) {
        this.provider.setAppList(null);
      }
    });
  }

  public constructor(channelStore: ChannelStore, channelSlug: string) {
    this._channelStore = channelStore;
    this._channelSlug = channelSlug;
    makeObservable(this);
    this.provider = new AppTileProvider(
      this._channelSlug,
      undefined,
      ['NinjaPage', `${this._channelSlug}`],
      undefined,
      true
    );
    this._handleCarousel();
  }

  @action
  public clear(): void {
    this.provider.unload();
  }

  public dispose = () => {
    this._disposer();
  };
}
