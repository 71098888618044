import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { SimpleButton } from '@utomik-app-monorepo/components';
import { AppList, AppTileProvider, Application, Channel, ChannelList, ChannelTileProvider } from '@utomik-app-monorepo/store';
import { sizeRemToPx } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { Tile } from '../tile/tile';
import styles from './tile-carousel.module.scss';
type Props = {
  provider?: AppTileProvider | ChannelTileProvider;
  itemsList?: {
    items: (Application | Channel)[];
    atEnd: boolean;
  };
  name?: string;
  slug?: string;
  onChildFocused?: (props: any, itemProps: any) => void;
  isLoadingCarousel?: boolean;
  showOutline?: boolean;
  showPlatformIcons?: boolean;
  onChildPress?: (props: any, provider?: AppTileProvider | ChannelTileProvider) => void;
};
const ITEMS_IN_ROW = 14;
export const TileCarousel = withFocusable()<Props>(observer(({
  provider,
  itemsList,
  setFocus,
  focused,
  name,
  slug,
  onChildFocused,
  showOutline,
  isLoadingCarousel,
  showPlatformIcons,
  onChildPress
}) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const forwardButtonRef = useRef<HTMLButtonElement>(null);
  const [fetchedItems, setFetchedItems] = useState<{
    items: (Application | Channel)[];
    atEnd: boolean;
  }>(itemsList || {
    items: [],
    atEnd: false
  });
  const items = itemsList || fetchedItems;
  const list = provider instanceof AppTileProvider ? provider?.appList : provider?.channelList;
  useEffect(() => {
    if (focused) {
      setFocus(`${slug || provider?.slug}-${items.items[0]?.id}`);
    }
  }, [focused, items]);
  const placeholders = useMemo(() => Array.from({
    length: ITEMS_IN_ROW
  }), []);
  useEffect(() => {
    if (list instanceof AppList) {
      list?.getApps(0, ITEMS_IN_ROW).then(setFetchedItems);
    }
    if (list instanceof ChannelList) {
      list?.getChannels(0, ITEMS_IN_ROW).then(setFetchedItems);
    }
  }, [list, provider?.state]);
  useEffect(() => {
    const scrollableContainerRefLocal = scrollableContainerRef.current;
    const handleScroll = () => {
      if (scrollableContainerRefLocal.scrollLeft === 0) {
        backButtonRef.current.disabled = true;
      } else {
        backButtonRef.current.disabled = false;
      }
      if (scrollableContainerRefLocal.scrollLeft >= scrollableContainerRefLocal.scrollWidth - scrollableContainerRefLocal.clientWidth) {
        forwardButtonRef.current.disabled = true;
      } else {
        forwardButtonRef.current.disabled = false;
      }
    };
    handleScroll();
    scrollableContainerRefLocal?.addEventListener('scroll', handleScroll);
    return () => scrollableContainerRefLocal?.removeEventListener('scroll', handleScroll);
  }, [list?.itemSet?.length, itemsList?.items]);
  const handleChildFocus = (props, itemProps) => {
    onChildFocused && onChildFocused(props, itemProps);
    scrollableContainerRef.current?.scrollTo({
      left: props.x - sizeRemToPx(2),
      behavior: 'smooth'
    });
  };
  const handlePrev = () => {
    scrollableContainerRef.current?.scrollTo({
      left: scrollableContainerRef.current.scrollLeft - window.innerWidth,
      behavior: 'smooth'
    });
  };
  const handleNext = () => {
    scrollableContainerRef.current?.scrollTo({
      left: scrollableContainerRef.current.scrollLeft + window.innerWidth,
      behavior: 'smooth'
    });
  };
  return <div className={styles.wrapper}>
          <p className={styles.title}>{name || provider?.name}</p>
          <div ref={scrollableContainerRef} className={styles.container}>
            <SimpleButton ref={backButtonRef} onClick={handlePrev} className={styles.prevButton} kind={'back'} />
            <div className={styles.scrollableWrapper}>
              {isLoadingCarousel || !items?.items?.length ? placeholders.map((_, idx) => <Tile carouselName={name} key={idx} />) : <>
                  {items.items.map((item, idx) => {
            const type = item instanceof Application ? 'application' : 'channel';
            return <Tile idx={idx} focusKey={`${slug || provider?.slug}-${item.id}`} key={item.id} onEnterPress={onChildPress} onBecameFocused={handleChildFocus} item={item} showOutline={showOutline} showPlatformIcons={showPlatformIcons} type={type} carouselName={name} />;
          })}
                  {!items.atEnd && <Tile idx={items.items.length + 1} focusKey={`${slug || provider?.slug}-see-all`} key={'see-all'} onEnterPress={props => onChildPress?.(props, provider)} onBecameFocused={handleChildFocus} isSeeAll={true} showOutline={showOutline} carouselName={name} />}
                </>}
            </div>
            <SimpleButton ref={forwardButtonRef} onClick={handleNext} className={styles.nextButton} kind={'forward'} />
          </div>
        </div>;
}));