import { Routes } from '@utomik-app-monorepo/constants';
import React from 'react';
import { createHashRouter, createMemoryRouter } from 'react-router-dom';
import App from './layout/app';
import { AppInfo } from './layout/views/app-info/app-info';
import { Channel } from './layout/views/channel/channel';
import { Channels } from './layout/views/channels/channels';
import { Home } from './layout/views/home/home';
import { MyList } from './layout/views/my-list/my-list';
import { NewsArticle } from './layout/views/news-article/news-article';
import { News } from './layout/views/news/news';
import { Ninja } from './layout/views/ninja/ninja';
import { Profile } from './layout/views/profile/profile';
import { Search } from './layout/views/search/search';
import { Settings } from './layout/views/settings/settings';
import { Stream } from './layout/views/stream/stream';
import { Wrapper } from './layout/wrapper';
let createRouter = createHashRouter;

//If the app is installed - use memory router
if (window.matchMedia('(display-mode: standalone)').matches) {
  createRouter = createMemoryRouter;
}
export const router = createRouter([{
  element: <App />,
  children: [{
    element: <Wrapper.WithBars />,
    children: [{
      path: Routes.Home,
      element: <Home />
    }, {
      path: Routes.Search,
      element: <Search />
    }, {
      path: `${Routes.Channels}/:id`,
      element: <Channel type={'channel'} />
    }, {
      path: Routes.Channels,
      element: <Channels />
    }, {
      path: Routes.MyList,
      element: <MyList />
    }, {
      path: Routes.Settings,
      element: <Settings />
    }, {
      path: Routes.Ninja,
      element: <Ninja />
    }, {
      path: Routes.Profile,
      element: <Profile />
    }, {
      path: Routes.News,
      element: <News />
    }]
  }, {
    path: `${Routes.AppInfo}/:id`,
    element: <AppInfo />
  }, {
    path: `${Routes.GameRun}/:id`,
    element: <Stream />
  }, {
    path: `${Routes.News}/:id`,
    element: <NewsArticle />
  }]
}]);