import { DEFAULT_RETRIES } from '@utomik-app-monorepo/constants';
// import {
//   IApiPairingCode,
//   ICheckPairingCodePayload,
//   ICheckPairingCodeResponse,
//   IGetPairingCodePayload,
//   IInvalidatePairingCodesPayload,
// } from '@utomik-app-monorepo/types';
import { isNullOrUndefined } from '@utomik-app-monorepo/utils';

import { RequestMethod, RequestPriority, RequestQueue } from '../../requestQueue/requestQueue';

export enum PairingCodeAction {
  LOG_IN = 'LOG_IN',
  SIGN_UP = 'SIGN_UP',
}

export enum PairingCodeStatus {
  UNUSED = 'UNUSED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  USED = 'USED',
}

export interface IApiPairingCode {
  code: number;
  url: string;
  expires_at: string;
  action: PairingCodeAction;
}

export interface IGetPairingCodePayload {
  type: 'TV';
  action: PairingCodeAction;
  device_id: string;
  device_name: string;
  affiliate_code: string;
}

export interface ICheckPairingCodePayload {
  code: number[];
  device_id: string;
}

export interface ICheckPairingCodeResponse {
  code: number;
  status: PairingCodeStatus;
  alc_token: ApiLoginKeyResponse;
  expires_at: string;
}

export interface IInvalidatePairingCodesPayload {
  device_id: string;
}

/**
 * Class for requesting ALC tokens.
 * plus some helper interfaces that are used for the replies.
 */

export interface ForgotPasswordRequest {
  email: string;
}

//Token response from the API ( /api-token-auth endpoint)
export interface ApiTokenAuthResponse {
  token: string;
}

export interface ApiToken {
  user_id: number;
  exp: number; // expiration date in seconds since Unix epoch (January 1, 1970, 00:00:00, UTC)
}

//Response to a call to loginCloudAPI
export interface TokenAuthCloudApiResponse {
  jwt: string;
}

//Response to a call to loginWithALC
export class TokenAuthResponse {
  public token: string;
  public expiresAt: Date;
  public userId: number;

  public constructor(_token: string = null) {
    if (_token) this.parse(_token);
  }

  public parse(_token: string): boolean {
    this.token = _token;
    let token: ApiToken;
    try {
      token = JSON.parse(atob(_token.split('.')[1]));
    } catch (err) {
      // TODO: Should probably reconsider this. This is a pain for unit tests too.
      console.error(`Could not parse TokenAuthResponse token!`);

      // We know that it expires about 16 hours from now.
      // To be safe we take the current time + 10 hours. This is currently unused.
      const date = new Date(Date.now());
      date.setHours(date.getHours() + 10);

      // Treat invalid token as anonymous.
      token = {
        user_id: -1,
        exp: date.getTime() / 1000,
      };
      return Boolean(token);
    }
    this.userId = token.user_id;
    this.expiresAt = new Date(token.exp * 1000);
    return true;
  }

  public getHttpToken(): string {
    return `JWT ${this.token}`;
  }
}

//API response to a login request with username and password ( /login-keys endpoint)
export interface ApiLoginKeyResponse {
  id: string;
  name: string;
  token: string;
  expires_at: string;
}

export class LoginKeyResponse {
  private readonly _apiLoginKeyResponse: ApiLoginKeyResponse;

  public constructor(loginKeyResponse: ApiLoginKeyResponse) {
    this._apiLoginKeyResponse = loginKeyResponse;
  }

  public get id(): string {
    return this._apiLoginKeyResponse.id;
  }
  public get name(): string {
    return this._apiLoginKeyResponse.name;
  }
  public get token(): string {
    return this._apiLoginKeyResponse.token;
  }
  public get expiresAt(): Date {
    return new Date(this._apiLoginKeyResponse.expires_at);
  }

  public validate(): void {
    if (isNullOrUndefined(this.id)) {
      throw new Error('LoginKeyResponse id invalid');
    }
    if (isNullOrUndefined(this.name)) {
      throw new Error('LoginKeyResponse name invalid');
    }
    if (isNullOrUndefined(this.token)) {
      throw new Error('LoginKeyResponse token invalid');
    }
    if (isNullOrUndefined(this.expiresAt) || isNaN(Number(this.expiresAt))) {
      throw new Error('LoginKeyResponse expiresAt invalid');
    }
    if (this.isExpired) {
      throw new Error('LoginKeyResponse expired');
    }
  }

  public get isExpired(): boolean {
    return this.expiresAt == null || new Date(Date.now()) > this.expiresAt;
  }

  public getHttpToken(): string {
    return `ALC ${this.token}`;
  }

  public getApiLoginKeyResponse(): ApiLoginKeyResponse {
    return this._apiLoginKeyResponse;
  }
}

/**
 * Class that handles logging in and managing tokens, for Utomik as well as through 'social authorities' (i.e. Facebook).
 */
export class AuthorizationStore {
  private readonly requestQueue: RequestQueue;

  /**
   * @param requestQueue RequestQueue
   */
  public constructor(requestQueue: RequestQueue) {
    this.requestQueue = requestQueue;
  }

  /**
   * This method handles the actual login by posting the login data to the API endpoint.
   * @param email user email
   * @param password user password
   * @param hostName machine name
   */
  public async login(email: string, password: string, hostName: string): Promise<LoginKeyResponse> {
    const postData = {
      email,
      password,
      name: `${hostName}`,
    };
    // Logging in is critical, do eet now!!
    const apiLoginKeyResponse = await this.requestQueue.add<ApiLoginKeyResponse>(
      '/login-keys',
      postData,
      RequestPriority.Critical,
      RequestMethod.Post,
      { headers: {} as any }
    ).promise;
    const loginKeyResponse = new LoginKeyResponse(apiLoginKeyResponse.data);
    return loginKeyResponse;
  }

  public async loginWithALC(token: LoginKeyResponse): Promise<TokenAuthResponse> {
    const config = { headers: { Authorization: token.getHttpToken() } as any };
    // Logging in is critical, do eet now!!
    const apiTokenAuthResponse = await this.requestQueue.add<ApiTokenAuthResponse>(
      '/api-token-auth',
      {},
      RequestPriority.Critical,
      RequestMethod.Post,
      config
    ).promise;
    const tokenAuthResponse = new TokenAuthResponse(apiTokenAuthResponse.data.token);
    return tokenAuthResponse;
  }

  public async loginCloudAPI(alc: TokenAuthResponse): Promise<TokenAuthCloudApiResponse> {
    const config = { headers: { Authorization: `JWT ${alc.token}` } as any };

    const apiTokenCloudApiResponse = await this.requestQueue.add<TokenAuthCloudApiResponse>(
      '/v2/cloud/api-token-auth',
      {},
      RequestPriority.Critical,
      RequestMethod.Post,
      config
    ).promise;
    return apiTokenCloudApiResponse.data;
  }

  public async loginWithJWT(jwt: TokenAuthResponse): Promise<TokenAuthResponse> {
    // Logging in is critical, do eet now!!
    const apiTokenAuthResponse = await this.requestQueue.add<ApiTokenAuthResponse>(
      `/api-token-refresh`,
      {
        token: jwt.token,
      },
      RequestPriority.Critical,
      RequestMethod.Post
    ).promise;
    const tokenAuthResponse = new TokenAuthResponse(apiTokenAuthResponse.data.token);
    return tokenAuthResponse;
  }

  public async invalidateAllOtherALCs(auth: TokenAuthResponse, alc: LoginKeyResponse): Promise<boolean> {
    const config = { headers: { Authorization: auth.getHttpToken() } as any };
    const apiResponse = await this.requestQueue.add<ApiLoginKeyResponse[]>(
      '/login-keys',
      undefined,
      RequestPriority.Critical,
      RequestMethod.Get,
      config
    ).promise;

    //DEPRECATED, this only exists because there can be existing logins with this string.
    //Once this client fully replaces the old client, we can probably remove this at some point.
    //Note: don't forget to remove the same thing in the API in the session transfer mechanism.
    const nameWithClient = `${alc.name} (client)`;

    await Promise.all(
      apiResponse.data
        .map((loginKey): LoginKeyResponse => {
          // We need to transform to the correct type.
          return new LoginKeyResponse(loginKey);
        })
        .filter((loginKey): boolean => {
          // We only want to logout keys that
          // - Have no name or
          // - Have a name that matches our name and alcId that doesn't match our alcId
          return (loginKey.name === alc.name || loginKey.name === nameWithClient) && loginKey.id !== alc.id;
        })
        .map((loginKey) => {
          return this.invalidate(auth, loginKey);
        })
    );

    return true;
  }

  public async invalidate(auth: TokenAuthResponse, alc: LoginKeyResponse): Promise<boolean | void> {
    // Without this check, ALCs with an _apiLoginKeyResponse equal to null would crash here.
    // (This can happen when the client unexpectedly crashes with 'remember me' selected, or restarting the client during the profile incomplete dialog.)
    if (!isNullOrUndefined(alc.getApiLoginKeyResponse())) {
      const config = { headers: { Authorization: auth.getHttpToken() } as any };
      await this.requestQueue.add(
        `/login-keys/${alc.id}`,
        undefined,
        RequestPriority.Critical,
        RequestMethod.Delete,
        config
      ).promise;

      return true;
    }
  }

  public async forgotPassword(email: string): Promise<boolean> {
    const postData: ForgotPasswordRequest = {
      email,
    };
    // skips the request queue.
    await this.requestQueue.add<Record<string, never>, ForgotPasswordRequest>(
      'v1/accounts/reset_password',
      postData,
      RequestPriority.Critical,
      RequestMethod.Post,
      null,
      DEFAULT_RETRIES,
      true
    ).promise;

    return true;
  }

  public async generatePairingCode(body: IGetPairingCodePayload) {
    const { data } = await this.requestQueue.add<IApiPairingCode, IGetPairingCodePayload>(
      '/v2/pairing/do_generate_code',
      body,
      RequestPriority.Critical,
      RequestMethod.Post,
      null,
      DEFAULT_RETRIES,
      true
    ).promise;

    return { ...data, action: body.action };
  }

  public async checkPairingCodesStatus(body: ICheckPairingCodePayload) {
    const { data } = await this.requestQueue.add<ICheckPairingCodeResponse, ICheckPairingCodePayload>(
      '/v2/pairing/do_check_status',
      body,
      RequestPriority.Critical,
      RequestMethod.Post,
      null,
      DEFAULT_RETRIES,
      true
    ).promise;

    return data;
  }

  public async invalidatePairingCodes(body: IInvalidatePairingCodesPayload) {
    const { data } = await this.requestQueue.add<unknown, IInvalidatePairingCodesPayload>(
      '/v2/pairing/do_invalidate_codes',
      body,
      RequestPriority.Critical,
      RequestMethod.Post,
      null,
      DEFAULT_RETRIES,
      true
    ).promise;

    return data;
  }
}
