import { t } from '@lingui/macro';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Stepper } from '@utomik-app-monorepo/components';
import { Routes, analyticsRoutes } from '@utomik-app-monorepo/constants';
import { AnalyticControllerContext, AnalyticItemTypes, AppTileProvider, AsyncObjectState, ChannelTileProvider, DialogQueueContext, HomeControllerContext, MyListAppTileProvider, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { joinLocation, sizeRemToPx } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainSlider } from '../../../components/main-slider/main-slider';
import { TileCarousel } from '../../../components/tile-carousel/tile-carousel';
import { Channel } from '../channel/channel';
import styles from './home.module.scss';
const HomeTilesSection = withFocusable({
  trackChildren: true
})<{
  onFocusChild: (props: any, itemProps: any) => void;
  onFocusCarousel: (props: any, itemProps: any) => void;
  onPressChild: (props: any, provider?: AppTileProvider | ChannelTileProvider) => void;
}>(observer(({
  setFocus,
  onFocusChild,
  onFocusCarousel,
  onPressChild,
  hasFocusedChild
}) => {
  const homeController = useContext(HomeControllerContext);
  const navigationController = useContext(NavigationControllerContext);
  const dialogQueue = useContext(DialogQueueContext);
  const providers = homeController.allNotEmptyProviders;
  useEffect(() => {
    if (!dialogQueue.hasDialogs) {
      setFocus(homeController.pageScrollState.currentFocusKey || 'main-button');
    }
  }, [providers[1]?.state, dialogQueue.hasDialogs]);
  const rows = providers.map(provider => {
    if (provider instanceof AppTileProvider) {
      if (provider.slug === 'spotlight') {
        return null;
      }
      if (provider.slug === 'my_list' && provider instanceof MyListAppTileProvider) {
        return <TileCarousel provider={provider} isLoadingCarousel={provider.state !== AsyncObjectState.Done} focusKey={provider.slug} key={provider.slug} onChildFocused={onFocusChild} onChildPress={onPressChild} onBecameFocused={onFocusCarousel} showOutline={navigationController.isGamepadInteraction} itemsList={provider.myListApps} slug={provider.slug} name={provider.name} />;
      }
      return <TileCarousel isLoadingCarousel={provider.state !== AsyncObjectState.Done} focusKey={provider.slug} key={provider.slug} onChildFocused={onFocusChild} onChildPress={onPressChild} onBecameFocused={onFocusCarousel} provider={provider} showOutline={navigationController.isGamepadInteraction} name={provider.name} />;
    }
  });
  return <div className={styles.scrollableWrapper}>{rows}</div>;
}));
export const Home = withFocusable()(observer(() => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const homeController = useContext(HomeControllerContext);
  const analyticController = useContext(AnalyticControllerContext);
  useEffect(() => {
    homeController.init();
    homeController.providers.init();
  }, []);
  const handleTilePress = useCallback((props, provider) => {
    const breadcrumbs = analyticController.getBreadCrumbs({
      pageName: analyticsRoutes[Routes.Home],
      currentSection: props.carouselName,
      isCarousel: true
    });
    if (props.type === 'application') {
      analyticController.navigate({
        item_name: props.item.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.Game,
        location_on_page: joinLocation(breadcrumbs)
      });
      navigate(`${Routes.AppInfo}/${props.item.id}`);
    } else if (provider.slug === 'my_list') {
      const breadcrumbs = analyticController.getBreadCrumbs({
        currentSection: props.carouselName,
        isCarousel: true,
        isSeeAll: true
      });
      analyticController.navigate({
        item_name: provider.slug,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.SeeAll,
        location_on_page: joinLocation(breadcrumbs)
      });
      navigate(Routes.MyList);
    } else if (props.isSeeAll) {
      const breadcrumbs = analyticController.getBreadCrumbs({
        currentSection: props.carouselName,
        isCarousel: true,
        isSeeAll: true
      });
      analyticController.navigate({
        item_name: props.key,
        location_index: String(props.idx),
        item_type: AnalyticItemTypes.SeeAll,
        location_on_page: joinLocation(breadcrumbs)
      });
      homeController.setCurrentProvider(provider);
    }
  }, []);
  const handleChildFocus = useCallback((_, itemProps) => {
    homeController.pageScrollState.currentFocusKey = itemProps.dotsFocusKey || itemProps.focusKey;
  }, []);
  const handleFocusMainSlider = useCallback((props, itemProps) => {
    homeController.pageScrollState.currentFocusKey = 'main-button';
    scrollableContainerRef.current?.scrollTo({
      top: props.y - sizeRemToPx(8),
      behavior: 'smooth'
    });
  }, []);
  const handleFocusCarousel = useCallback(props => {
    scrollableContainerRef.current?.scrollTo({
      top: props.y + sizeRemToPx(15),
      behavior: 'smooth'
    });
  }, []);
  const list = homeController.currentProvider instanceof AppTileProvider && homeController.currentProvider.appList;
  return <div ref={scrollableContainerRef} className={styles.container}>
        <Stepper steps={[<div className={styles.tilesContainer}>
              <MainSlider analyticsSectionName={'Spotlight'} name={t({
        message: 'Spotlight'
      })} kind={'Fader'} focusKey={'slider'} key={homeController.providers.spotlight?.slug} onBecameFocused={handleFocusMainSlider} onChildFocused={handleChildFocus} appList={homeController.providers.spotlight?.appList} />
              <HomeTilesSection focusKey={'tiles-section'} onFocusCarousel={handleFocusCarousel} onPressChild={handleTilePress} onFocusChild={handleChildFocus} />
            </div>, <Channel provider={homeController.currentProvider} appList={list} type={'seeall'} />]} currentStep={Number(!!homeController.currentProvider)} />
      </div>;
}));