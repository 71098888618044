import * as d3 from 'd3';
import { action, computed, makeObservable, observable } from 'mobx';

// NOTE: I've already optimized things from an O(n^2) to O(1), but there's other stuff
// that be done _much_ more efficiently.
// 1) In ChartProvider, there's little need to store all datapoints.
// 2) In ChartProvider, building chartData can probably done at addBitdata() if you store the
//    old results. This instead of recalculating everything during _calculateFrameData().
// 3) With a tighter coupling between ChartProvider and LineChart, you can probably eliminate
//    many of the map calls.
// On desktop, drawing graphs seem to cost about 2-4 ms now. That doesn't seem like much,
// but remember that you have 16ms to draw a frame.

const TIME_DOMAIN = 30; ///< Chart 30 points.

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/multi-line-chart
// Modified by Davide Nguyen for Utomik
function LineChart<Type = { x: number; y: number; z: string }>(
  data: Type[],
  {
    x,
    y,
    z,

    xDomain, // [xmin, xmax]
    xLabel = 'x',
    yDomain, // [ymin, ymax]
    yLabel = 'y', // a label for the y-axis
    color = 'currentColor', // stroke color of line, as a constant or a function of *z*

    marginTop = 20, // top margin, in pixels
    marginRight = 30, // right margin, in pixels
    marginBottom = 30, // bottom margin, in pixels
    marginLeft = 80, // left margin, in pixels
    width = 320, // outer width, in pixels
    height = 200, // outer height, in pixels
  }: {
    x: (t: Type) => number;
    y: (t: Type) => number;
    z: (t: Type) => string;

    xDomain?: [number, number];
    xLabel: string;
    yDomain?: [number, number];
    yLabel: string;

    color?: string | ((z: string) => string);

    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
    width?: number;
    height?: number;
  }
) {
  // Compute values.
  const X = d3.map(data, x);
  const Y = d3.map(data, y);
  const Z = d3.map(data, z);

  // Compute default domains, and unique the z-domain.
  const _xDomain: [number, number] = xDomain ? xDomain : (d3.extent(X) as [number, number]);
  const _yDomain: [number, number] = yDomain ? yDomain : (d3.extent(Y) as [number, number]);
  const zDomain = new d3.InternSet(Z);

  // Omit any data not present in the z-domain.
  const I = d3.range(X.length).filter((i) => zDomain.has(Z[i]));

  // Construct scales and axes.
  const xScale = d3.scaleLinear(_xDomain, [marginLeft, width - marginRight]);
  const yScale = d3.scaleLinear(_yDomain, [height - marginBottom, marginTop]);
  const xAxis = d3
    .axisBottom(xScale)
    .ticks(width / 80)
    .tickSizeOuter(0);
  const yAxis = d3.axisLeft(yScale).ticks(height / 60);

  // Construct a line generator.
  const line = d3
    .line<number>()
    //.curve(d3.curveNatural)
    .x((i) => xScale(X[i]))
    .y((i) => yScale(Y[i]));

  const svg = d3
    .create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

  // x-axis
  svg
    .append('g')
    .attr('transform', `translate(0,${height - marginBottom})`)
    .call(xAxis)
    .call((g) =>
      g
        .append('text')
        .attr('x', width - marginLeft - marginRight)
        .attr('y', 30)
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'start')
        .text(xLabel)
    );

  // y-axis
  svg
    .append('g')
    .attr('transform', `translate(${marginLeft},0)`)
    .call(yAxis)
    .call((g) => g.select('.domain').remove())
    .call((g) =>
      g
        .selectAll('.tick line')
        .clone()
        .attr('x2', width - marginLeft - marginRight)
        .attr('stroke-opacity', 0.1)
    )
    .call((g) =>
      g
        .append('text')
        .attr('x', -marginLeft)
        .attr('y', 10)
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'start')
        .text(yLabel)
    );

  // line?
  svg
    .append('g')
    .attr('fill', 'none')
    .attr('stroke', typeof color === 'string' ? color : null)
    .attr('stroke-width', 1.5)
    .selectAll('path')
    .data(d3.group(I, (i) => Z[i]))
    .join('path')
    .attr('stroke', typeof color === 'function' ? ([z]) => color(z) : null)
    .attr('d', ([, I]) => line(I));

  return svg.node();
}

type BitData = {
  framerateDecoded: number;
  framerateReceived: number;
  lossPacket: number;
  realBitrate: number;
};

export class ChartProvider {
  private _enabled = false;
  public get enabled() {
    return this._enabled;
  }
  public setEnabled(enabled: boolean) {
    this._enabled = enabled;
    if (!enabled) this.clearBitData();
  }

  private _bitData: BitData[] = [];
  public addBitData(bitData: BitData) {
    if (!this._enabled) return;
    this._bitData.push(bitData);
  }
  public clearBitData() {
    this._bitData.splice(0, this._bitData.length);
  }

  private get _calculatedFrameData() {
    const len: number = this._bitData.length;
    if (!this._enabled || len == 0) return [];

    // NOTE: when assigning objects, you're not making copies, you're making _references_!
    // be careful when passing them around.
    const chartData: { x: number; y: BitData; z: string }[] = [];
    const dummy: BitData = { framerateDecoded: 0, framerateReceived: 0, lossPacket: 0, realBitrate: 0 };
    const stats = { low: {} as BitData, high: {} as BitData, sum: {} as BitData, avg: {} as BitData };

    const i0: number = Math.max(len - (TIME_DOMAIN + 1), 0);
    let raw: BitData = this._bitData[i0];

    // Initialize.
    for (const key in dummy) {
      stats['low'][key] = raw[key];
      stats['high'][key] = raw[key];
      stats.sum[key] = 0; // Keep as 0 for now.
      stats.avg[key] = raw[key];
    }

    // Add the points inside the time-window.
    for (let i = i0; i < len; i++) {
      raw = this._bitData[i];
      for (const key in dummy) {
        if (raw[key] < stats.low[key]) stats.low[key] = raw[key];
        if (raw[key] > stats.high[key]) stats.high[key] = raw[key];
        stats.sum[key] += raw[key];
        stats.avg[key] = stats.sum[key] / (i - i0 + 1);
      }

      chartData.push({ x: i, y: raw, z: 'real' });
    }

    // Add straight lines for the window's low,high and avg points.
    chartData.push(
      { x: i0, y: stats.low, z: 'low' },
      { x: i0, y: stats.high, z: 'high' },
      { x: i0, y: stats.avg, z: 'avg' },

      { x: len, y: stats.low, z: 'low' },
      { x: len, y: stats.high, z: 'high' },
      { x: len, y: stats.avg, z: 'avg' }
    );

    return chartData;
  }

  public get fpsMLChart() {
    const chartData = this._calculatedFrameData;
    if (chartData.length === 0) return null;

    const colorPicker = (z: string) => {
      switch (z) {
        case 'low':
          return '#0000ff';
        case 'high':
          return '#ff0000';
        case 'avg':
          return '#00ff00';
        case 'real':
          return '#ffffff';
        default:
          return '#000000';
      }
    };

    const xDomainStart = Math.max(chartData[chartData.length - 1]?.x - TIME_DOMAIN || 0, 0);
    const xDomain30Seconds: [number, number] = [xDomainStart, xDomainStart + TIME_DOMAIN];
    const yDomainFramerate: [number, number] = [0, 70];

    const obj = {
      diffFrameDecoded: LineChart(chartData, {
        x: (d) => d.x,
        y: (d) => d.y.framerateDecoded,
        z: (d) => d.z,
        xDomain: xDomain30Seconds,
        xLabel: 'Seconds',
        yDomain: yDomainFramerate,
        yLabel: 'Frames decoded',
        color: colorPicker,
      }),
      diffFrameReceived: LineChart(chartData, {
        x: (d) => d.x,
        y: (d) => d.y.framerateReceived,
        z: (d) => d.z,
        xDomain: xDomain30Seconds,
        xLabel: 'Seconds',
        yDomain: yDomainFramerate,
        yLabel: 'Frames received',
        color: colorPicker,
      }),
      lossPacket: LineChart(chartData, {
        x: (d) => d.x,
        y: (d) => d.y.lossPacket,
        z: (d) => d.z,
        xDomain: xDomain30Seconds,
        xLabel: 'Seconds',
        yLabel: 'Packetloss',
        color: colorPicker,
      }),
      realBitrate: LineChart(chartData, {
        x: (d) => d.x,
        y: (d) => d.y.realBitrate,
        z: (d) => d.z,
        xDomain: xDomain30Seconds,
        xLabel: 'Seconds',
        yLabel: 'Real bitrate',
        color: colorPicker,
      }),
    };

    return obj;
  }

  public constructor() {
    // MobX decorator
    makeObservable<ChartProvider, '_enabled' | '_bitData'>(this, {
      _enabled: observable,
      enabled: computed,
      setEnabled: action,
      addBitData: action,
      clearBitData: action,
      fpsMLChart: computed,
      _bitData: observable,
    });
  }
}
