// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AndroidCordovaDirectoryEntry,
  AndroidCordovaFileEntry,
  AndroidCordovaFileWriter
} from "../../../../../../../../types/global";

import { ClientController } from '../../../app/global/clientController/clientController';
import { PlatformController } from '../../../app/global/platformController/platformController';
import { RequestQueue } from '../../requestQueue/requestQueue';
import { IssueTypes } from '../issueTypes/issueTypes';
import { AsyncObjectState } from '../objectStore/asyncObject';
import { LogsStore } from './logsStore';

/**
 * The main idea is to collect events in the local variable and ones per [TIMER_SAVING_DATA_TO_FILE_MS] ms and save
 * it to the file. If we try to save all the events at the same time, we have a problem with concurrent file access.
 */
export class AndroidTvLogsStore extends LogsStore {
  private TIMER_SAVING_DATA_TO_FILE_MS = 10000;
  private FOLDER_NAME = 'logs';
  private FILE_NAME = 'logFile.txt';

  private _dirEntry: AndroidCordovaDirectoryEntry;
  private _fileEntry: AndroidCordovaFileEntry;
  private _fileWrite: AndroidCordovaFileWriter;
  private _collectLogEvents: Array<string> = [];
  private _timerId: ReturnType<typeof setInterval>;

  constructor(
    requestQueue: RequestQueue,
    platformController: PlatformController,
    issueTypes: IssueTypes,
    clientController: ClientController
  ) {
    super(requestQueue, platformController, issueTypes, clientController);
  }

  private _createLogsDirectory = async () => {
    await new Promise((resolve, reject) => {
      window.requestFileSystem(
        window.LocalFileSystem.PERSISTENT,
        0,
        (fs) => {
          fs.root.getDirectory(
            this.FOLDER_NAME,
            { create: true },
            (data) => {
              this._dirEntry = data;
              resolve(true);
            },
            (error) => {
              console.log('createLogsDirectory ' + error);

              reject(false);
            }
          );
        },
        () => {
          console.log('Error creating logs directory');

          reject(false);
        }
      );
    });
  };

  private _createNewFile = async (fileName: string) => {
    await new Promise((resolve, reject) => {
      this._dirEntry.getFile(
        fileName,
        { create: true, exclusive: false },
        (data) => {
          this._fileEntry = data;

          resolve(true);
        },
        (error) => {
          console.log('createNewFile error ' + error);

          reject(false);
        }
      );
    });
  };

  private _createAnWriter = async () => {
    await new Promise((resolve, reject) => {
      this._fileEntry.createWriter(
        (data) => {
          this._fileWrite = data;

          resolve(true);
        },
        (error) => {
          console.log('writeToFile error ' + error);

          reject(false);
        }
      );
    });
  };

  protected createLogsFile = async () => {
    const currentDateTime = new Date().toISOString().split('.')[0].replaceAll(':', '.');
    const fileName = currentDateTime + this.FILE_NAME;

    try {
      // Make sure that directory exist
      await this._createLogsDirectory();

      // Clear previous timer and collection
      clearInterval(this._timerId);
      this._collectLogEvents = [];

      await this._createNewFile(fileName);
      await this._createAnWriter();
      await this.addLogToFile('*****************************START OF THE LOG FILE*******************************');

      const listOfFiles = await this._getSortedFileFromDirList();

      await this._removeOlderFiles(listOfFiles);

      // Start a new timer for all new events
      this._timerId = setInterval(() => {
        this._saveCollectionToFile();
      }, this.TIMER_SAVING_DATA_TO_FILE_MS);

      return true;
    } catch (e) {
      return false;
    }
  };

  // this method we're using just for collect locally all events
  public addLogToFile = async (log: string) => {
    this._collectLogEvents.push(log + '\r\n');
  };

  private _saveCollectionToFile = () => {
    this._fileWrite.write(this._collectLogEvents.join(''), true);
    this._collectLogEvents = [];
  };

  private _getSortedFileFromDirList = async (): Promise<AndroidCordovaFileEntry[]> => {
    const nativeURL = this._dirEntry.nativeURL;

    return new Promise<AndroidCordovaFileEntry[]>((resolve) => {
      window.resolveLocalFileSystemURL(
        nativeURL,
        function (directoryEntry) {
          const directoryReader = directoryEntry.createReader();

          // Read entries in the directory
          directoryReader.readEntries(
            (entries) => {
              const fileEntryList = [...entries];
              const sortedFileEntryList = fileEntryList.sort((a, b) => a.name.localeCompare(b.name));

              resolve(sortedFileEntryList);
            },
            (error) => {
              resolve([]);
              console.error('Error reading directory: ' + error);
            }
          );
        },
        function (error) {
          resolve([]);
          console.error('Error resolving directory URL: ' + error);
        }
      );
    });
  };

  private _removeOlderFiles = async (list: AndroidCordovaFileEntry[]) => {
    const MAX_LOG_FILES = 5;
    let counter = list.length - MAX_LOG_FILES;

    //remove older files if the files qty is more than MAX_LOG_FILES
    while (counter > 0) {
      try {
        list[0].remove();
        list.shift();
        counter--;
      } catch (e) {
        counter = 0;
      }
    }
  };

  public async deleteLogsFolder() {
    this._dirEntry.removeRecursively();
  }

  public deleteLogFile() {
    // noop
  }

  private _readFile = async (androidFileEntry: AndroidCordovaFileEntry): Promise<string> => {
    return new Promise((resolve, reject) => {
      this._dirEntry.getFile(
        androidFileEntry.name,
        { create: false },
        (fileEntry) => {
          fileEntry.file(
            (file) => {
              const reader = new FileReader();

              reader.onloadend = function () {
                const fileValue = this.result as string;
                resolve(fileValue);
              };

              reader.readAsText(file);
            },
            () => {
              reject();
              console.log('_readFileInner error');
            }
          );
        },
        (error) => {
          reject();
          console.log('_readFile error: ' + error.code);
        }
      );
    });
  };

  public sendLogs = async () => {
    this.state = AsyncObjectState.Pending;

    try {
      const fileEntries = await this._getSortedFileFromDirList();

      const fileStrings = await Promise.all(fileEntries.map(async (file) => await this._readFile(file)));

      await this.sendLogFiles(fileStrings);
      for (let i = 0; i < fileStrings.length; i++) {
        const element = fileStrings[i];
        console.log(i.toString() + element);
      }

      this.state = AsyncObjectState.Done;
    } catch (e) {
      this.state = AsyncObjectState.Error;
    }
  };

  public dispose() {
    clearInterval(this._timerId);

    this._dirEntry = null;
    this._fileEntry = null;
    this._fileWrite = null;
    this._collectLogEvents = [];
    this._timerId = null;
  }
}
