/* eslint-disable */
import React, { Component, ReactNode } from 'react';

import { XBOX } from './layouts';

interface GamepadProps {
  layout?: any;
  stickThreshold?: number;
  deadZone?: number;
  onConnect?: (index: number) => void;
  onDisconnect?: (index?: number) => void;
  onButtonDown?: (buttonName: string) => void;
  onButtonUp?: (buttonName: string) => void;
  onButtonChange?: (buttonName: string, pressed: boolean) => void;
  onAxisChange?: (axisName: string, value: number, previousValue: number) => void;
  onA?: () => void;
  onB?: () => void;
  onX?: () => void;
  onY?: () => void;
  onStart?: () => void;
  onBack?: () => void;
  onLT?: () => void;
  onRT?: () => void;
  onLB?: () => void;
  onRB?: () => void;
  onLS?: () => void;
  onRS?: () => void;
  onUp?: () => void;
  onDown?: () => void;
  onLeft?: () => void;
  onRight?: () => void;
  children?: ReactNode;
}

interface ButtonStates {
  [key: string]: boolean;
}

interface AxisStates {
  [key: string]: number;
}

interface PadState {
  connected: boolean;
  activeGamepadIndex: number | null;
  rfIds: any[]; // Replace `any` with the actual type
  buttons: ButtonStates;
  axis: AxisStates;
}

class Gamepad extends Component<GamepadProps> {
  static defaultProps: Partial<GamepadProps> = {
    layout: XBOX,
    stickThreshold: 0.5,
    deadZone: 0.2,
    onConnect: () => {},
    onDisconnect: () => {},
    onButtonDown: () => {},
    onButtonUp: () => {},
    onButtonChange: () => {},
    onAxisChange: () => {},
    onA: () => {},
    onB: () => {},
    onX: () => {},
    onY: () => {},
    onStart: () => {},
    onBack: () => {},
    onLT: () => {},
    onRT: () => {},
    onLB: () => {},
    onRB: () => {},
    onLS: () => {},
    onRS: () => {},
    onUp: () => {},
    onDown: () => {},
    onLeft: () => {},
    onRight: () => {},
  };

  private padState: PadState;
  private mounted: boolean;
  private intervalId: ReturnType<typeof setTimeout>;

  constructor(props: GamepadProps) {
    super(props);

    this.padState = {
      connected: false,
      activeGamepadIndex: null,
      rfIds: [],

      buttons: {
        A: false,
        B: false,
        X: false,
        Y: false,
        LB: false,
        LT: false,
        LS: false,
        RB: false,
        RT: false,
        RS: false,
        Start: false,
        Back: false,
        DPadUp: false,
        DPadRight: false,
        DPadDown: false,
        DPadLeft: false,
      },

      axis: {
        LeftStickX: 0.0,
        LeftStickY: 0.0,
        RightStickX: 0.0,
        RightStickY: 0.0,
        RightTrigger: 0.0,
        LeftTrigger: 0.0,
      },
    };

    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    //changed to interval instead requestAnimationFrame due to performance
    if (process.env.APP_TYPE === 'WEB') {
      this.intervalId = setInterval(() => {
        this.updateGamepad();
      }, 50);
    } else {
      this.updateGamepad();
    }
  }

  componentWillUnmount() {
    this.mounted = false;

    clearInterval(this.intervalId);
  }

  getActiveGamepad() {
    const gamepads = navigator.getGamepads();

    if (gamepads?.length > 0) {
      for (const gamepad of gamepads) {
        if (!gamepad) continue;

        const isAnyButtonPressed = gamepad.buttons.some((button) => button.pressed);
        const isAnyAxesChanged = gamepad.axes.some((axis) => Math.abs(axis) > this.props.stickThreshold);
        if (isAnyButtonPressed || isAnyAxesChanged) {
          this.padState.activeGamepadIndex = gamepad.index;
        }
      }
    }

    return gamepads[this.padState.activeGamepadIndex];
  }

  updateGamepad() {
    if (!this.mounted) return;
    const activeGamepad = this.getActiveGamepad();

    if (!(activeGamepad === null || activeGamepad === undefined)) {
      if (!this.padState.connected) {
        this.padState.connected = true;
        this.props.onConnect(activeGamepad.index);
      }

      this.updateAllButtons(activeGamepad);
      this.updateAllAxis(activeGamepad);
    } else if (this.padState.connected) {
      this.padState.connected = false;
      this.props.onDisconnect(activeGamepad?.index);
    }

    if (process.env.APP_TYPE !== 'WEB' && window && window.requestAnimationFrame) {
      window.requestAnimationFrame(this.updateGamepad.bind(this));
    }
  }

  updateAllButtons(gamepad) {
    for (let i = 0; i < gamepad.buttons.length; ++i) {
      const pressed = gamepad.buttons[i].pressed;
      const value = gamepad.buttons[i].value;

      let buttonName = this.buttonIndexToButtonName(i);
      this.updateButton(buttonName, pressed);

      let axisName = this.buttonIndexToAxisName(i);
      this.updateAxis(axisName, value);
    }
  }

  updateButton(buttonName, pressed) {
    if (this.padState.buttons[buttonName] === undefined) {
      return;
    }
    if (this.padState.buttons[buttonName] !== pressed) {
      this.padState.buttons[buttonName] = pressed;

      this.props.onButtonChange(buttonName, pressed);
      this.props[`onButton${pressed ? 'Down' : 'Up'}`](buttonName);
      if (pressed) this.props[`on${buttonName.replace('DPad', '')}`]();
    }
  }

  updateAllAxis(gamepad) {
    for (let i = 0; i < gamepad.axes.length; ++i) {
      let axisName = this.axisIndexToAxisName(i);

      this.updateAxis(axisName, gamepad.axes[i]);
    }
  }

  updateStickX(previousValue, axisName, value) {
    if (axisName === 'LeftStickX') {
      if (previousValue <= this.props.stickThreshold && value > this.props.stickThreshold) {
        this.props.onRight();
      }

      if (previousValue >= -this.props.stickThreshold && value < -this.props.stickThreshold) {
        this.props.onLeft();
      }
    }
  }

  updateStickY(previousValue, axisName, value) {
    if (axisName === 'LeftStickY') {
      if (previousValue <= this.props.stickThreshold && value > this.props.stickThreshold) {
        this.props.onUp();
      }

      if (previousValue >= -this.props.stickThreshold && value < -this.props.stickThreshold) {
        this.props.onDown();
      }
    }
  }

  updateSticks(axisName, value) {
    if (this.padState.axis[axisName] !== value) {
      const previousValue = this.padState.axis[axisName];
      this.padState.axis[axisName] = value;

      this.props.onAxisChange(axisName, value, previousValue);

      this.updateStickX(previousValue, axisName, value);

      this.updateStickY(previousValue, axisName, value);
    }
  }

  updateAxis(axisName, originalValue) {
    if (axisName && originalValue !== undefined && originalValue !== null && !isNaN(originalValue)) {
      const invert = axisName[0] === '-';
      let value = originalValue * (invert ? -1 : 1);

      if (Math.abs(value) < this.props.stickThreshold) {
        value = 0;
      }

      if (invert) axisName = axisName.substr(1);

      this.updateSticks(axisName, value);
    }
  }

  buttonIndexToButtonName(index) {
    const { layout } = this.props;

    if (layout.buttons && layout.buttons.length >= index + 1) {
      return layout.buttons[index];
    }

    return null;
  }

  buttonIndexToAxisName(index) {
    const { layout } = this.props;

    if (layout.buttonAxis && layout.buttonAxis.length >= index + 1) {
      return layout.buttonAxis[index];
    }

    return null;
  }

  axisIndexToAxisName(index) {
    const { layout } = this.props;

    if (layout.axis && layout.axis.length >= index + 1) {
      return layout.axis[index];
    }

    return null;
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

export default Gamepad;
