import { observer } from 'mobx-react';
import React, { ReactElement, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { useVisibilityChange } from '../hooks/useVisibilityChange';
import StreamControllerContext from '../contexts/streamControllerContext';
import { StreamErrorType } from '../streamTypes';
import { StreamViewProps } from './streamViewProps';
import { flowResult } from 'mobx';

/***
 * StreamView component, accepting properties of StreamViewProps
 * A mostly self contained React Component that will try to orchestrate
 * a cloud streaming session.
 */

export interface IStreamView {
  /**
   * Toggles between fullscreen and windowed mode.
   *
   * The following APIs are used to capture the cursor and
   * make sure the 'Escape' key must be pressed and held for a few seconds to exit:
   * https://developer.mozilla.org/en-US/docs/Web/API/Pointer_Lock_API
   * https://developer.mozilla.org/en-US/docs/Web/API/Keyboard
   *
   * This method can be called if these APIs are not supported by the browser,
   * in which case it will only toggle fullscreen mode.
   */
  toggleFullscreen: () => void;
  /**
   * Captures the cursor using the following API:
   * https://developer.mozilla.org/en-US/docs/Web/API/Pointer_Lock_API
   *
   * This method can be called if this API is not supported by the browser,
   * in which case it will do nothing.
   */
  lockPointer: () => void;
  /**
   * Sets a language identifier that will be used as the keyboard layout for the game.
   * See https://learn.microsoft.com/en-us/windows/win32/intl/language-identifiers
   */
  setKeyboardLayoutLanguage: (langId: number) => void;
  /**
   * Ends the stream by deleting and resetting all necessary values
   * */
  disposeStream: () => Promise<void>;
}

export const StreamView = observer(
  React.forwardRef(function StreamView(
    {
      elementId = 'main_video',
      applicationId,
      gatewayAddress,
      gatewayName,
      applicationVersionId,
      width = 1920,
      height = 1080,
      token,
      cloudToken,
      baseURL = 'https://api.utomik.com/',
      deviceInfo,
      guiLanguage,
      applicationLanguage,
      letterboxColor = 'black',
      usePointerCapture = true,
      mobile = 0,
      hardwareTag = 0,
      onStateChanged,
      onStreamError,
      onInactivity,
      onPointerCaptureLost,
      onConnectionQualityChanged,
      onNativeAudioStreamSettings,
      onGamepadRegistrationHook
    }: StreamViewProps,
    ref: React.Ref<IStreamView>
  ): ReactElement {
    const videoRef = useRef<HTMLVideoElement>(null);
    const streamController = useContext(StreamControllerContext);
    useVisibilityChange(
      streamController.dispose ||
        (() => {
          // no-op
        })
    );

    useImperativeHandle<IStreamView, IStreamView>(ref, () => {
      return {
        toggleFullscreen: () => {
          streamController.toggleFullscreen();
        },
        lockPointer: () => {
          streamController.lockPointer();
        },
        setKeyboardLayoutLanguage: (langId: number) => {
          streamController.setKeyboardLayoutLanguage(langId);
        },
        disposeStream: () => streamController.dispose(),
      };
    }, []);

    useEffect(() => {
      flowResult(
        streamController.init(
          videoRef.current,
          token,
          cloudToken,
          gatewayAddress,
          gatewayName,
          applicationId,
          applicationVersionId,
          width,
          height,
          baseURL,
          deviceInfo,
          guiLanguage,
          applicationLanguage,
          usePointerCapture,
          mobile,
          hardwareTag,
          onStateChanged ?? (() => {}),
          onPointerCaptureLost ?? (() => {}),
          onConnectionQualityChanged ?? (() => {}),
          onNativeAudioStreamSettings,
          onGamepadRegistrationHook
        )
      );
      return () => {
        streamController.dispose();
      };
    }, []);

    useEffect(() => {
      if (!streamController.message) return;
      if (streamController.message.value && streamController.message.type === 'error') {
        onStreamError?.(streamController.message.value as StreamErrorType, streamController.message.payload as number);
        streamController.dispose();
      }
      if (streamController.message.value && streamController.message.type === 'activity') {
        if (onInactivity != undefined) {
          onInactivity((streamController.message.payload as { time: number }).time).then((result) => {
            streamController.sendActivityMessage(result);
          });
        }
      }
    }, [applicationId, streamController.message]);

    return (
      <div
        //styles make sure video is letter-boxed and pillar-boxed properly
        style={{
          width: '100%',
          height: '100%',
          background: letterboxColor,
          boxSizing: 'border-box',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <video
          ref={videoRef}
          id={elementId}
          autoPlay
          playsInline
          poster="null"
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </div>
    );
  })
);

export default StreamView;
