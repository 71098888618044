import { log } from '@utomik-app-monorepo/logger';

import { LgPersonalizedKeyToken } from '../../../dataStore/lgPersonalizedKeyToken/lgPersonalizedKeyToken';

export class PersonalizedKeyController {
  private readonly _lgPersonalizedKeyToken: LgPersonalizedKeyToken;

  constructor(lgPersonalizedKeyToken: LgPersonalizedKeyToken) {
    this._lgPersonalizedKeyToken = lgPersonalizedKeyToken;
  }

  public setPersonalKeyService = () => {
    window?.webOS.service.request('luna://com.webos.service.contentminer', {
      method: 'setPersonalKey',
      parameters: {
        appId: window.webOS.fetchAppId(),
        key: `LG ${this._lgPersonalizedKeyToken.value.token}`,
        expiredTime: this._lgPersonalizedKeyToken.expiresAtUnixTime,
      },
      onSuccess: () => {
        return true;
      },
      onFailure: (inError) => {
        console.warn(`setPersonalKeyService error: ${inError}`);
        return false;
      },
    });
  };

  public clearPersonalKeyService = () => {
    window?.webOS.service.request('luna://com.webos.service.contentminer', {
      method: 'clearPersonalKey',
      parameters: {
        appId: window.webOS.fetchAppId(),
      },
      onSuccess: (inResponse) => {
        log(`clearPersonalKeyService response: ${inResponse}`);
        return true;
      },
      onFailure: (inError) => {
        console.warn(`clearPersonalKeyService error: ${inError}`);
        return false;
      },
    });
  };
}
