import React, { createContext } from 'react';
import { GatewayStore } from '../../dataStore/stores/GatewayStore/GatewayStore';
import { ApplicationStore } from '../../dataStore/stores/applicationStore/applicationStore';
import { ChannelStore } from '../../dataStore/stores/channelStore/channelStore';
import { Countries } from '../../dataStore/stores/countries/countries';
import { LanguagesStore } from '../../dataStore/stores/languagesStore/languagesStore';
import { LogsController } from '../../dataStore/stores/logsStore/logsController';
import { MyListStore } from '../../dataStore/stores/myListStore/myListStore';
import { NewsItemStore } from '../../dataStore/stores/newsStore/newsItemStore';
import { ServiceSettingStore } from '../../dataStore/stores/ninjaSummary/serviceSettingStore';
import { RecentlyPlayedStore } from '../../dataStore/stores/recentlyPlayedStore/recentlyPlayedStore';
import { SearchResultStore } from '../../dataStore/stores/searchResultStore/searchResultStore';
import { SubscriptionPlansStore } from '../../dataStore/stores/subscriptionPlansStore/subscriptionPlansStore';
import { UserStatsStore } from '../../dataStore/stores/userStatsStore/userStatsStore';
import { AccountSummaryController } from '../global/accountSummary/accountSummaryController';
import { AchievementController } from '../global/achievementController/achievementController';
import { AnalyticController } from '../global/analyticController/analyticController';
import { AppInfoController } from '../global/appInfoController/appInfoController';
import { ChannelInfoController } from '../global/channelInfoController/channelInfoController';
import { ChannelsController } from '../global/channelsController/channelsController';
import { ClientController } from '../global/clientController/clientController';
import { ConnectionMonitor } from '../global/connectionMonitor/connectionMonitor';
import { DialogFactory } from '../global/dialogFactory/dialogFactory';
import { DialogQueue } from '../global/dialogQueue/dialogQueue';
import { EdenPreviewGamingHubController } from '../global/edenPreviewGamingHubController/edenPreviewGamingHubController';
import { EntitlementDataGamingHubController } from '../global/entitlementDataGamingHubController/entitlementDataGamingHubController';
import { EventController } from '../global/eventController/eventController';
import { HomeController } from '../global/homeController/homeController';
import { KeyboardController } from '../global/keyboardController/keyboardController';
import { Loader } from '../global/loader/loader';
import { LoginController } from '../global/loginController/loginController';
import { MediaPlayerController } from '../global/mediaPlayerController/mediaPlayerController';
import { MouseController } from '../global/mouseController/mouseController';
import { NavigationController } from '../global/navigationController/navigationController';
import { NinjaController } from '../global/ninjaController/ninjaController';
import { NotificationFactory } from '../global/notificationFactory/notificationFactory';
import { NotificationStack } from '../global/notificationStack/notificationStack';
import { PairingCodeManager } from '../global/pairingCodeManager/pairingCodeManager';
import { PlatformController } from '../global/platformController/platformController';
import { RecentlyPlayedGameHubController } from '../global/recentlyPlayedGameHubController/recentlyPlayedGameHubController';
import { RootStore } from '../global/rootStore';
import { SettingsViewController } from '../global/settingsViewController/settingsViewController';
import { StreamViewUtilsService } from '../global/streamViewUtilsService/streamViewUtilsService';
import { StreamingFeedbackController } from '../global/streamingFeedbackController/streamingFeedbackController';
import { TokenManager } from '../global/tokenManager/tokenManager';
import { TvSidebarController } from '../global/tvSidebarController/tvSidebarController';
import { VideoPlayerController } from '../global/videoPlayerController/videoPlayerController';

// Authorization
export let TokenManagerContext: React.Context<TokenManager>;
export let PairingCodeContext: React.Context<PairingCodeManager>;

// Stores
export let ApplicationStoreContext: React.Context<ApplicationStore>;
export let ChannelStoreContext: React.Context<ChannelStore>;
export let SearchResultStoreContext: React.Context<SearchResultStore>;
export let MyListStoreContext: React.Context<MyListStore>;
export let RecentlyPlayedStoreContext: React.Context<RecentlyPlayedStore>;
export let RecentlyPlayedGameHubControllerContext: React.Context<RecentlyPlayedGameHubController>;
export let EntitlementDataGamingHubControllerContext: React.Context<EntitlementDataGamingHubController>;
export let EdenPreviewGamingHubControllerContext: React.Context<EdenPreviewGamingHubController>;
export let UserStatsStoreContext: React.Context<UserStatsStore>;
export let NewsItemStoreContext: React.Context<NewsItemStore>;
export let ServiceSettingStoreContext: React.Context<ServiceSettingStore>;
export let GatewayStoreContext: React.Context<GatewayStore>;
export let SubscriptionPlansStoreContext: React.Context<SubscriptionPlansStore>;
export let LanguagesStoreContext: React.Context<LanguagesStore>;

// Controllers
export let PlatformControllerContext: React.Context<PlatformController>;
export let LogsControllerContext: React.Context<LogsController>;
export let ClientControllerContext: React.Context<ClientController>;
export let LoginControllerContext: React.Context<LoginController>;
export let HomeControllerContext: React.Context<HomeController>;
export let AppInfoControllerContext: React.Context<AppInfoController>;
export let ChannelInfoControllerContext: React.Context<ChannelInfoController>;
export let NinjaControllerContext: React.Context<NinjaController>;
export let AccountSummaryControllerContext: React.Context<AccountSummaryController>;
export let AchievementControllerContext: React.Context<AchievementController>;
export let ChannelsControllerContext: React.Context<ChannelsController>;
//TV
export let TvSidebarControllerContext: React.Context<TvSidebarController>;
export let MouseControllerContext: React.Context<MouseController>;
export let KeyboardControllerContext: React.Context<KeyboardController>;
export let NavigationControllerContext: React.Context<NavigationController>;
export let MediaPlayerControllerContext: React.Context<MediaPlayerController>;
export let EventControllerContext: React.Context<EventController>;
export let StreamingFeedbackControllerContext: React.Context<StreamingFeedbackController>;
//StreamVIew
export let StreamViewUtilsContext: React.Context<StreamViewUtilsService>;

// Other
export let DialogQueueContext: React.Context<DialogQueue>;
export let DialogFactoryContext: React.Context<DialogFactory>;
export let NotificationStackContext: React.Context<NotificationStack>;
export let NotificationFactoryContext: React.Context<NotificationFactory>;
export let CountriesContext: React.Context<Countries>;
export let LoaderContext: React.Context<Loader>;
export let AnalyticControllerContext: React.Context<AnalyticController>;
export let ConnectionMonitorContext: React.Context<ConnectionMonitor>;
export let VideoPlayerControllerContext: React.Context<VideoPlayerController>;
export let SettingsViewControllerContext: React.Context<SettingsViewController>;
export const NavigationContext = React.createContext(null);
export interface IRootStore {
  platformController: PlatformController;
  logsController: LogsController;
  appInfoController: AppInfoController;
  applicationStore: ApplicationStore;
  channelStore: ChannelStore;
  clientController: ClientController;
  dialogQueue: DialogQueue;
  dialogFactory: DialogFactory;
  homeController: HomeController;
  loader: Loader;
  loginController: LoginController;
  notificationStack: NotificationStack;
  notificationFactory: NotificationFactory;
  recentlyPlayedStore: RecentlyPlayedStore;
  newsItemStore: NewsItemStore;
  myListStore: MyListStore;
  userStatsStore: UserStatsStore;
  searchResultStore: SearchResultStore;
  subscriptionPlansStore: SubscriptionPlansStore;
  analyticController: AnalyticController;
  connectionMonitor: ConnectionMonitor;
  channelInfoController: ChannelInfoController;
  ninjaController: NinjaController;
  accountSummaryController: AccountSummaryController;
  achievementController: AchievementController;
  channelsController: ChannelsController;
  tokenManager: TokenManager;
  pairingCodeManager: PairingCodeManager;
  countries: Countries;
  gatewayStore: GatewayStore;
  settingsViewController: SettingsViewController;
  languagesStore: LanguagesStore;
  //TV
  tvSidebarController: TvSidebarController;
  mouseController: MouseController;
  keyboardController: KeyboardController;
  serviceSettingStore: ServiceSettingStore;
  recentlyPlayedGameHubController: RecentlyPlayedGameHubController;
  entitlementDataGamingHubController: EntitlementDataGamingHubController;
  edenPreviewGamingHubController: EdenPreviewGamingHubController;
  navigationController: NavigationController;
  mediaPlayerController: MediaPlayerController;
  eventController: EventController;
  streamingFeedbackController: StreamingFeedbackController;
  videoPlayerController: VideoPlayerController;
  //StreamView
  streamViewUtilsService: StreamViewUtilsService;
}
export function initContexts(v: IRootStore): void {
  // Authorization
  TokenManagerContext = createContext(v.tokenManager);
  PairingCodeContext = createContext(v.pairingCodeManager);
  LogsControllerContext = createContext(v.logsController);

  // Stores
  ApplicationStoreContext = createContext(v.applicationStore);
  RecentlyPlayedStoreContext = createContext(v.recentlyPlayedStore);
  MyListStoreContext = createContext(v.myListStore);
  UserStatsStoreContext = createContext(v.userStatsStore);
  ChannelStoreContext = createContext(v.channelStore);
  SearchResultStoreContext = createContext(v.searchResultStore);
  NewsItemStoreContext = createContext(v.newsItemStore);
  ServiceSettingStoreContext = createContext(v.serviceSettingStore);
  GatewayStoreContext = createContext(v.gatewayStore);
  SubscriptionPlansStoreContext = createContext(v.subscriptionPlansStore);
  LanguagesStoreContext = createContext(v.languagesStore);

  // Controllers
  PlatformControllerContext = createContext(v.platformController);
  ClientControllerContext = createContext(v.clientController);
  LoginControllerContext = createContext(v.loginController);
  HomeControllerContext = createContext(v.homeController);
  AppInfoControllerContext = createContext(v.appInfoController);
  ChannelInfoControllerContext = createContext(v.channelInfoController);
  NinjaControllerContext = createContext(v.ninjaController);
  AccountSummaryControllerContext = createContext(v.accountSummaryController);
  AchievementControllerContext = createContext(v.achievementController);
  ChannelsControllerContext = createContext(v.channelsController);
  SettingsViewControllerContext = createContext(v.settingsViewController);
  //TV
  TvSidebarControllerContext = createContext(v.tvSidebarController);
  MouseControllerContext = createContext(v.mouseController);
  KeyboardControllerContext = createContext(v.keyboardController);
  RecentlyPlayedGameHubControllerContext = createContext(v.recentlyPlayedGameHubController);
  EntitlementDataGamingHubControllerContext = createContext(v.entitlementDataGamingHubController);
  EdenPreviewGamingHubControllerContext = createContext(v.edenPreviewGamingHubController);
  NavigationControllerContext = createContext(v.navigationController);
  MediaPlayerControllerContext = createContext(v.mediaPlayerController);
  EventControllerContext = createContext(v.eventController);
  StreamingFeedbackControllerContext = createContext(v.streamingFeedbackController);

  //StreamView
  StreamViewUtilsContext = createContext(v.streamViewUtilsService);

  // Other
  ConnectionMonitorContext = createContext(v.connectionMonitor);
  DialogQueueContext = createContext(v.dialogQueue);
  DialogFactoryContext = createContext(v.dialogFactory);
  NotificationStackContext = createContext(v.notificationStack);
  NotificationFactoryContext = createContext(v.notificationFactory);
  AnalyticControllerContext = createContext(v.analyticController);
  LoaderContext = createContext(v.loader);
  CountriesContext = createContext(v.countries);
  VideoPlayerControllerContext = createContext(v.videoPlayerController);
}
export let contextRootStore: RootStore;
export function initGlobals(): void {
  contextRootStore = new RootStore();
  initContexts(contextRootStore);
}

/**
 * A function for restarting Utomik if you don't have regular access to the SettingsController and can't use contexts
 */
export async function restartUtomikContextless(): Promise<void> {
  //no-op
}