import { ClientControllerContext, PlatformControllerContext } from '@utomik-app-monorepo/store';
import { isDevelopment } from '@utomik-app-monorepo/utils';
import React, { useEffect } from 'react';
export const useVisibilityChange = (videoTagId: string) => {
  const platformController = React.useContext(PlatformControllerContext);
  const clientController = React.useContext(ClientControllerContext);
  const isEnabled = !(isDevelopment || clientController?.user?.isAdmin || clientController?.user?.isCloudTester);
  useEffect(() => {
    //if (!isEnabled) return;
    // Learn more: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event

    // Android TV (Cordova) doesn't support visibilitychange event
    if (platformController.isAndroidTV) {
      document.addEventListener('pause', _disableTracks);
      document.addEventListener('resume', _enableTracks);
    } else {
      document.addEventListener('visibilitychange', onVisibilityChange);
    }
    return () => {
      if (platformController.isAndroidTV) {
        document.removeEventListener('pause', _disableTracks);
        document.addEventListener('resume', _enableTracks);
      } else {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      }
    };
  }, [isEnabled]);
  const onVisibilityChange = () => {
    if (document.hidden) {
      _disableTracks();
    } else {
      _enableTracks();
    }
  };
  const getMediaStreamTracks = (): MediaStreamTrack[] => {
    /**
     * Get the video element using document.getElementById due to no access to the video tag
     * */
    const videoEl = document.getElementById(videoTagId) as HTMLVideoElement;
    if (videoEl?.srcObject && 'getTracks' in videoEl.srcObject) {
      return videoEl.srcObject.getTracks();
    }
    return [];
  };
  const _disableTracks = () => {
    getMediaStreamTracks().forEach(track => {
      track.enabled = false;
    });
  };
  const _enableTracks = () => {
    getMediaStreamTracks().forEach(track => {
      track.enabled = true;
    });
  };
};