import { faCheck, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { NavigationControllerContext, PlatformControllerContext } from '@utomik-app-monorepo/store';
import { NavigationController } from '@utomik-app-monorepo/store';
import { PlatformController } from '@utomik-app-monorepo/store';
import { TizenWindowData } from '@utomik-app-monorepo/store';
import { DialogResult } from '@utomik-app-monorepo/store';
import { pathTv, repeatFnCall } from '@utomik-app-monorepo/utils';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { FocusableButton } from '../../focusable-button/focusable-button';
import './require-gamepad-dialog.scss';
type Props = {
  action: (action: DialogResult) => void;
  setFocusFromParent: (key?: string) => void;
};
export const RequireGamepadDialog: React.FC<Props> = observer(function RequireGamepadDialog({
  action,
  setFocusFromParent
}) {
  const platformController = useContext<PlatformController>(PlatformControllerContext);
  const navigationController = useContext<NavigationController>(NavigationControllerContext);
  const gamepadImage = pathTv + require('images/svg/gamepad.svg');
  const isTizen = platformController.instance instanceof TizenWindowData;
  const hasBluetoothSupport = platformController.instance?.hasBluetoothSupport;
  useEffect(() => {
    if (isTizen) {
      repeatFnCall(setFocusFromParent.bind(null, 'action-button'));
    } else {
      setFocusFromParent();
    }
    let timeoutId;
    if (navigationController.isGamepadConnected && !isTizen) {
      timeoutId = setTimeout(() => {
        action(DialogResult.OK);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [navigationController.isGamepadConnected]);
  const handleOpenSettings = async () => {
    if (isTizen) {
      await platformController.instance.quickPanel.launch();
    }
  };
  const controllerConnectText = !navigationController.isGamepadConnected ? t({
    context: 'Asks a user to connect a controller before stream start',
    message: 'Connect a controller to play'
  }) : t({
    context: 'Prompts a user that controller has been connected',
    message: 'Connected successfully'
  });
  return <div className={'require-gamepad-dialog__container'}>
      <p className={'require-gamepad-dialog__title'}>{controllerConnectText}</p>
      <img decoding={'sync'} className={'gamepad-image'} src={gamepadImage} />
      {Boolean(!isTizen && navigationController.isGamepadConnected) && <div className={'check-icon__container'}>
          <FontAwesomeIcon className={'check-icon'} icon={faCheck} />
        </div>}
      {Boolean(!isTizen && !navigationController.isGamepadConnected) && <div className={'check-icon__container--cross'}>
          <FontAwesomeIcon className={'check-icon'} icon={faGamepad} />
        </div>}
      {Boolean(isTizen && hasBluetoothSupport && !navigationController.isGamepadConnected) && <FocusableButton focusKey={'action-button'} type={'secondary'} onEnterPress={handleOpenSettings}>
          {t({
        context: 'Button title - Opens controller settings on Samsung TV',
        message: 'Open settings'
      })}
        </FocusableButton>}
      {Boolean(isTizen && navigationController.isGamepadConnected || platformController.isWeb) && <FocusableButton focusKey={'action-button'} type={'secondary'} onEnterPress={() => action(DialogResult.OK)}>
          {t({
        context: 'Button title - Redirect to the game stream',
        message: 'Continue'
      })}
        </FocusableButton>}
    </div>;
});
export default RequireGamepadDialog;