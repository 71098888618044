import { Routes } from '@utomik-app-monorepo/constants';
import { Application, ClientControllerContext, DialogFactoryContext, NavigationControllerContext } from '@utomik-app-monorepo/store';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
export const useAppFetch = (app: Application) => {
  const isMountedRef = useRef(false);
  const clientController = useContext(ClientControllerContext);
  const dialogFactory = useContext(DialogFactoryContext);
  const navigationController = useContext(NavigationControllerContext);
  const navigate = useNavigate();
  const fetchIfNeeded = useCallback(async () => {
    try {
      await app.fetch();
    } catch (e) {
      if (isMountedRef.current) {
        // Check if component is still mounted
        await dialogFactory.showGameNotAvailableDialog(Number(app.id));
        navigate('/');
      }
      return;
    }
    if (isMountedRef.current) {
      // Check if component is still mounted before further operations
      await app.applicationVersions.fetch();
      await app.stagingTiers.fetch();
      await app.appVersion?.achievements?.updateProgress();
    }
  }, [app]);
  useEffect(() => {
    isMountedRef.current = true;
    fetchIfNeeded();
    return () => {
      isMountedRef.current = false; // Set isMounted to false when component unmounts
      app.appVersion?.achievements?.clearNewAchievements();
      app.applicationVersions?.abortFetch();
      app.abortFetch();
      app.stagingTiers?.abortFetch();
      app.appVersion?.abortFetch();
      navigationController.setCurrentId(null);
      clientController.cameFrom = Routes.Home;
    };
  }, [app]);
  return null;
};