import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { DialogResult } from '@utomik-app-monorepo/store';
import { observer } from 'mobx-react';
import React from 'react';
import './secret-dialog.scss';
export const SecretDialog = withFocusable({
  blockNavigationOut: true
})<{
  action: (action: DialogResult, value?: unknown) => void;
}>(observer(function SecretDialog() {
  return <div className={'secret-dialog-modal'}>
        <p className={'title'}>SOME SECRET INFORMATION</p>
      </div>;
}));
export default SecretDialog;